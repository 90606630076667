import {
	CopyObjectGroupType,
	CopyRequestStatus,
	EngagementStatus,
	GroupAuditRequest
} from '../../../util/enumerations';
import React, { useEffect, useState } from 'react';
import {
	dateFormats,
	dateFormatsListItems,
	labels,
	standardStrings,
	urls
} from '../../../util/uiconstants';
import {
	getCopyRequest,
	getEngagementsToCopyTo,
	patchCopyRequest
} from '../../../actions/copyhubactions';
import { useDispatch, useSelector } from 'react-redux';

import EllipsesControl from '@ey/ellipses-control';
import { FormRadio } from '@ey/form';
import { FormStaticText } from '@ey/form';
import Importscot from './importscot';
import Loader from '@ey/loader';
import { Radio } from 'react-bootstrap';
import { SVGICONS } from '@ey/icons';
import env from '../../../util/env';
import { getNotifications } from '../../../actions/notificationsactions';
import moment from 'moment';
import { raiseJSValidationError } from '../../../actions/erroractions';
import styled from 'styled-components';
import { useDidMountEffect } from '../../../util/customHooks';
import { withRouter } from 'react-router-dom';

const SelectEngagementStyle = styled.section`
	& .selectanengagement {
		font-size: 1.4rem;
	}

	& .engagementgroup {
		padding-top: 1remx;
		font-size: 1.4rem;

		& .form-group .radio {
			vertical-align: middle;
			margin: 0 0 0.5rem 0;

			& .engagementstatus-detail {
				width: 100%;
				display: inline-block;
				float: left;
				cursor: default;

				& .ellipses {
					max-width: 80%;
					float: left;
					width: auto;

					& div {
						max-width: none;
						width: 100%;
					}
				}

				& .eng-status {
					color: ${(props) => props.theme.color[`grey400`]};
					margin-left: 0.5em;
					cursor: default;
				}
			}

			& label input[type='radio'] {
				margin: 0 0 0 -3rem;
			}
			& label {
				padding-left: 3rem;
				width: 95%;
				word-wrap: break-word;
			}
		}

		& > .form-group {
			margin: 0 0 1.5rem 0;
		}

		& .form-group label.control-label {
			display: inline;
		}
	}

	& .import-error {
		font-size: 1.2rem;
		line-height: normal;
		height: 2rem;
		padding-top: 1rem;
		padding-bottom: 2rem;

		& svg {
			fill: ${(props) => props.theme.color[`red600`]};
			vertical-align: text-top;
			margin-right: 0.5em;
		}
	}
`;

const SelectEngagement = (props) => {
	const copyRequest = useSelector((store) => store.copyHub.copyRequest || {});
	const engagements = useSelector((store) => store.copyHub.engagements || []);
	const linkRequest = useSelector((store) => store.copyHub.linkRequest || {});

	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [
		showEngagementIsRequiredValidation,
		setShowEngagementIsRequiredValidation
	] = useState(false);
	const [targetEngagementId, setTargetEngagementId] = useState(null);
	const [targetEngagementVersion, setTargetEngagementVersion] = useState('');

	let getEngagementsUrl = env.getURL('serviceUrl') + '/' + urls.HOME_URL;

	let updateCopyRequestUrl =
		env.getURL('serviceUrl') + '/' + urls.GROUPAUDITENGAGEMENTS_URL;

	let getNotificationsUrl =
		env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;

	let buildGetEngagementsUrlGA =
		getEngagementsUrl + '?phase=GetGroupAuditEngagements';

	let buildGetEngagementsUrl =
		getEngagementsUrl +
		'?phase=GetEngagementsToCopyEngagementSpecificData&filters=sourceEngagementId eq ' +
		copyRequest.sourceengagementid +
		",sourceEngagementVersion eq '" +
		copyRequest.sourceengagementversion +
		"'" +
		',copyobjectgrouptypeid eq ' +
		copyRequest.copyobjectgrouptypeid;

	const asyncGetEngagementsToCopyTo = async () => {
		await dispatch(
			getEngagementsToCopyTo(
				buildGetEngagementsUrl,
				props.getDependencyData('domainWorkspaceId')
			)
		).finally(() => {
			setIsLoading(false);
		});
	};

	const asyncGetEngagementsGAToCopyTo = async () => {
		await dispatch(
			getEngagementsToCopyTo(
				buildGetEngagementsUrlGA,
				props.getDependencyData('domainWorkspaceId')
			)
		).finally(() => {
			setIsLoading(false);
		});
	};

	useEffect(() => {
		setIsLoading(true);
		if (props.isGroupAudit) {
			asyncGetEngagementsGAToCopyTo();
		} else {
			asyncGetEngagementsToCopyTo();
		}
	}, []);

	useDidMountEffect(() => {
		if (props.isGroupAudit) {
			GAhandleFinishClickedAsync();
		} else {
			handleFinishClickedAsync();
		}
	}, [props.onFinishClick]);

	const GAhandleFinishClickedAsync = async () => {
		if (engagements.length > 0 && !targetEngagementId) {
			setShowEngagementIsRequiredValidation(true);
			return;
		}
		GAsave();
	};

	const GAsave = async () => {
		let buildGetGARequestUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.GROUPAUDITENGAGEMENTS_URL +
			'?phase=GALinkRequest&filters=GAComponentID eq ' +
			props.gaComponentID;
		setIsLoading(true);
		try {
			// fetch GA request before saving it, to run validation on the latest data
			await dispatch(getCopyRequest(buildGetGARequestUrl, props.gaComponentID));

			// continue to save if status of the GA request did not change
			if (
				linkRequest.galinkstatusid === GroupAuditRequest.Accepted ||
				linkRequest.galinkstatusid === GroupAuditRequest.Delegated
			) {
				if (!targetEngagementId || !targetEngagementVersion) {
					await dispatch(
						raiseJSValidationError(labels.engagementhasbeendeleted)
					);
				} else {
					await updateGARequest();
					await navigateToLandingPage();
				}
			} else {
				// concurrency, first in wins, do not update the record on second attempt
				await navigateToLandingPage();
			}
		} finally {
			setIsLoading(true);
		}
	};

	const updateGARequest = async () => {
		let buildUpdateGARequestUrl =
			updateCopyRequestUrl + '?id=' + linkRequest.id + '&phase=linkRequest';
		await dispatch(
			patchCopyRequest(buildUpdateGARequestUrl, {
				collections: {
					linknotifications: [
						{
							id: linkRequest.id,
							data: {
								operationtype: 'linkRequest',
								gacomponentengagementid: targetEngagementId,
								gacomponentengagementversion: targetEngagementVersion,
								primaryengagementid: linkRequest.primaryengagementid,
								concurrencytoken: linkRequest.concurrencytoken
							}
						}
					]
				}
			})
		);
	};

	const handleFinishClickedAsync = async () => {
		if (engagements.length > 0 && !targetEngagementId) {
			setShowEngagementIsRequiredValidation(true);
			return;
		}
		var lstAllowedTypes = [
			CopyObjectGroupType.SCOTs,
			CopyObjectGroupType.V2SCOTs,
			CopyObjectGroupType.V2Accounts,
			CopyObjectGroupType.CanvasForms,
			CopyObjectGroupType.V2Evidence,
			CopyObjectGroupType.V2ExternalEvidences,
			CopyObjectGroupType.KnowledgeDeliveredEvidence
		];

		if (
			targetEngagementId &&
			lstAllowedTypes.includes(copyRequest.copyobjectgrouptypeid)
		) {
			try {
				setIsLoading(true);
				setIsSaving(true);
				await save();
			} finally {
				setIsLoading(false);
				setIsSaving(false);
			}
		}
	};

	const save = async () => {
		let buildGetCopyRequestUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.GROUPAUDITENGAGEMENTS_URL +
			'?phase=ImportDataPackageRequest&filters=CopyRequestID eq ' +
			copyRequest.id;

		// fetch copy request before saving it, to run validation on the latest data
		await dispatch(getCopyRequest(buildGetCopyRequestUrl, copyRequest.id));

		// continue to save if status of the copy request did not change
		if (copyRequest.copyrequeststatusid === CopyRequestStatus.Received) {
			if (!targetEngagementId || !targetEngagementVersion) {
				await dispatch(raiseJSValidationError(labels.engagementhasbeendeleted));
			} else {
				await updateCopyRequest();
				await navigateToLandingPage();
			}
		} else {
			// concurrency, first in wins, do not update the record on second attempt
			await navigateToLandingPage();
		}
	};

	const updateCopyRequest = async () => {
		let buildUpdateCopyRequestUrl =
			updateCopyRequestUrl + '?id=' + copyRequest.id + '&phase=copyhubRequest';

		await dispatch(
			patchCopyRequest(buildUpdateCopyRequestUrl, {
				collections: {
					iepnotifications: [
						{
							id: copyRequest.id,
							data: {
								operationtype: 'acceptIEPRequest',
								selectedworkspaceid:
									props.getDependencyData('domainWorkspaceId'),
								targetengagementid: targetEngagementId,
								copyobjectgrouptypeid: copyRequest.copyobjectgrouptypeid
							}
						}
					]
				}
			})
		);
	};

	const navigateToLandingPage = async () => {
		await dispatch(getNotifications(getNotificationsUrl));
		props.history.push('');
	};

	const handleEngagementChange = (value, engversion) => {
		setTargetEngagementId(value);
		setTargetEngagementVersion(engversion);
		setShowEngagementIsRequiredValidation(false);

		//// In case of group audit or any other import, no need to get any details
		/// we are setting engagement id and version, so should be good

		// if (props.isGroupAudit) {
		// 	// await dispatch(
		// 	// 	getEngagement(
		// 	// 		buildGetEngagementsUrlGA,
		// 	// 		props.getDependencyData('domainWorkspaceId'),
		// 	// 		value
		// 	// 	)
		// 	// );
		// } else {
		// 	await dispatch(
		// 		getEngagement(
		// 			buildGetEngagementsUrl,
		// 			props.getDependencyData('domainWorkspaceId'),
		// 			value
		// 		)
		// 	);
		// }
	};

	const getHeaderForAction = () => {
		if (props.isGroupAudit) {
			let linkRequestData = linkRequest;
			return (
				<Importscot>
					<header>
						<h5>{labels.gaacceptselectengagementquestionlabel}</h5>
					</header>
					<section className="importscot">
						<section className="packagesourcedetail">
							<section className="packagedetailscolumn">
								<FormStaticText label={labels.sourceengagement + ':'}>
									<EllipsesControl
										id="sourceengagementname"
										content={linkRequestData.primaryengagementname}
										tooltip={linkRequestData.primaryengagementname}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.garegioncomponent + ':'}>
									<EllipsesControl
										id="regionComponent"
										content={linkRequestData.componentshortname}
										tooltip={linkRequestData.componentshortname}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.gascope + ':'}>
									<EllipsesControl
										id="Scope"
										content={linkRequestData.gascopename}
										tooltip={linkRequestData.gascopename}
										isTooltipAvailable
									/>
								</FormStaticText>
							</section>
							<section className="packagedetailscolumn">
								<FormStaticText label={labels.client + ':'}>
									<EllipsesControl
										id="clientname"
										content={props.getDependencyData('domainClientName')}
										tooltip={props.getDependencyData('domainClientName')}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.workspace + ':'}>
									<EllipsesControl
										id="workspacename"
										content={props.getDependencyData('domainWorkspaceName')}
										tooltip={props.getDependencyData('domainWorkspaceName')}
										isTooltipAvailable
									/>
								</FormStaticText>
							</section>
						</section>
					</section>
				</Importscot>
			);
		} else {
			return (
				<Importscot>
					<header>
						<h5>{labels.selectengagement}</h5>
					</header>
					<section className="importscot">
						<section className="packagesourcedetail">
							<section className="packagedetailscolumn">
								<FormStaticText label={labels.package + ':'}>
									<EllipsesControl
										id="packagename"
										content={copyRequest.copyname}
										tooltip={copyRequest.copyname}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.sourceengagement + ':'}>
									<EllipsesControl
										id="sourceengagementname"
										content={copyRequest.sourceengagementdescription}
										tooltip={copyRequest.sourceengagementdescription}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.sentby + ':'}>
									{copyRequest.id
										? copyRequest.sendername +
										' ' +
										labels.on +
										' ' +
										moment
											.utc(copyRequest.iepreceivedate)
											.format(dateFormatsListItems[dateFormats].label)
										: ''}
								</FormStaticText>
							</section>
							<section className="packagedetailscolumn">
								<FormStaticText label={labels.client + ':'}>
									<EllipsesControl
										id="clientname"
										content={props.getDependencyData('domainClientName')}
										tooltip={props.getDependencyData('domainClientName')}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.workspace + ':'}>
									<EllipsesControl
										id="workspacename"
										content={props.getDependencyData('domainWorkspaceName')}
										tooltip={props.getDependencyData('domainWorkspaceName')}
										isTooltipAvailable
									/>
								</FormStaticText>
							</section>
						</section>
					</section>
				</Importscot>
			);
		}
	};

	const getErrorMessage = () => {
		if (props.isGroupAudit) {
			return (
				<section className="import-error">
					{showEngagementIsRequiredValidation === true ? (
						<section>
							<SVGICONS styleName="small" id="icon" name="error" />
							<span>{labels.selectgaengagementmsg}</span>
						</section>
					) : (
						standardStrings.EMPTY
					)}
				</section>
			);
		} else {
			return (
				<section className="import-error">
					{showEngagementIsRequiredValidation === true ? (
						<section>
							<SVGICONS styleName="small" id="icon" name="error" />
							<span>{labels.selectengagementmsg}</span>
						</section>
					) : (
						standardStrings.EMPTY
					)}
				</section>
			);
		}
	};

	if (isLoading) {
		return <Loader view={isSaving ? 'fullscreen' : null} />;
	}

	return (
		<SelectEngagementStyle>
			<form>
				<section>
					{getHeaderForAction()}
					{getErrorMessage()}
					<section>
						{engagements.length > 0 ? (
							<section className="engagementgroup">
								<FormRadio name="radioStack" onChange={() => { }}>
									{engagements.map((engagement) => {
										let engagementStatus;

										switch (engagement.engagementstatus) {
											case EngagementStatus.Restored:
												engagementStatus = labels.engagementstatusrestored;
												break;
											case EngagementStatus.MarkedForDeletion:
												engagementStatus =
													labels.engagementstatusmarkedfordeletion;
												break;
											default:
												break;
										}

										let engagementName = engagement.engagementdesc;

										let radioAttributes = {};
										if (engagement.id === targetEngagementId) {
											radioAttributes.checked = true;
										}

										return (
											<Radio
												{...radioAttributes}
												value={engagement.id}
												key={engagement.id}
												name="radio"
												onClick={() =>
													handleEngagementChange(
														engagement.id,
														engagement.engagementversion
													)
												}
											>
												<section className="engagementstatus-detail">
													<EllipsesControl
														id="engagementName"
														content={engagementName}
														tooltip={engagementName}
														isTooltipAvailable
													/>
													<span className="pull-left eng-status">
														{engagementStatus
															? ' (' + engagementStatus + ')'
															: ''}
													</span>
												</section>
											</Radio>
										);
									})}
								</FormRadio>
							</section>
						) : (
							<section className="selectanengagement">
								{labels.noengagementstoimportto}
							</section>
						)}
					</section>
				</section>
			</form>
		</SelectEngagementStyle>
	);
};

export default withRouter(SelectEngagement);
