/**
 * DataExport.js
 * Created by calhosh on 12/17/2019.
 */
import React from 'react';
import {
	labels,
	currentResource,
	resourceFileNames
} from '../../util/uiconstants';
import {SVGICONS} from '@ey/icons';
import EllipsesControl from '@ey/ellipses-control';

const DataExport = (props) => {
	const {spotfireUri, engagementId, engagementVersion, language} = props;
	return (
		<section className="ReportingWrapper">
			<section className="ReportingHeader">
				<SVGICONS
					className="receiptIcon"
					hoverText={labels.dataExportLabel}
					name="receipt"
				/>
				{labels.dataExportLabel}
			</section>
			<ul className="ReportingLinks">
				{currentResource.dataExport.map((report, i) => {
					return (
						<li key={i + report + 'S'}>
							<a
								key={i}
								href={spotfireUri
									.replace('{0}', currentResource.dataExportReportNames[report])
									.replace('{1}', engagementId)
									.replace('{2}', engagementVersion)
									.replace('{3}', resourceFileNames[language])}
								target="_blank"
							>
								<EllipsesControl
									content={report}
									tooltip={report}
									isTooltipAvailable
								/>
							</a>
						</li>
					);
				})}{' '}
			</ul>
		</section>
	);
};

export default DataExport;
