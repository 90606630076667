/**
 * Remember: always add new reducers to the /reducers/index.js file!
 * Also, add your initialState to './initialstate'!
 */
import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export function UserSettingsReducer(
	//state = initialState.<%=names.camel%>,//TODO: uncomment this. Get from initialState.
	state = initialState.userSettings, //{Workspaces: {}},
	action
) {
	switch (action.type) {
		case types.USER_SETTINGS:
			/* Notice that this is simply returning the action payload, <%=names.camel%>.
			 * This is because the combineReducers() call wraps this reducer
			 * and passes it only the portion of the store state that it
			 * is concerned with. */
			console.log('action from usersettings reducer:', action);
			if (action.error) {
				return {...action.error, isError: true};
			} else {
				return action.response;
			}
		case types.PREF_TAB_USER_SETTINGS:
			return Object.assign({}, state, {
				...state,
				data: {
					...state.data,
					preferredlandingpagefilter: action.payload
				}
			});

		case types.PREF_GROUPING_USER_SETTINGS:
			return Object.assign({}, state, {
				...state,
				data: {
					...state.data,
					preferredlandingpageview: action.payload
				}
			});

		case types.PREF_PAGING_USER_SETTINGS:
			return Object.assign({}, state, {
				...state,
				data: {
					...state.data,
					preferrednumberofengagements: action.payload
				}
			});

		default:
			return state;
	}
}

export function UserPermissionsReducer(
	state = initialState.userPermissions,
	action
) {
	switch (action.type) {
		case types.USER_PERMISSIONS:
			state = action.response;
			return state;
		default:
			return state;
	}
}
