import React from 'react';
import {Window} from '@progress/kendo-react-dialogs';
import {MotifButton} from '@ey-xd/motif-react';
import styled, {ThemeProvider} from 'styled-components';
import {getTheme} from '@ey/styled-theme';

export default function KendoWindow(props) {
	const {
		children,
		closeBtnTitle,
		confirmBtnTitle,
		saveCreateBtnTitle,
		draggable,
		resizable,
		initialHeight,
		initialWidth,
		left,
		onClose,
		onSaveCreateClick,
		onOkClick,
		title,
		top,
		theme,
		className,
		isOkButtonDisabled,
		isSaveCreateButtonDisabled,
		isCancelButtonDisabled,
	} = props;

	return (
		<ThemeProvider theme={getTheme(theme)}>
			<StyledWindow
				draggable={draggable || false}
				resizable={resizable || false}
				onClose={onClose}
				open
				dragstart={() => {}}
				dragend={() => {}}
				title={title}
				modal
				initialHeight={initialHeight}
				initialWidth={initialWidth}
				left={left}
				minimizeButton={() => null}
				maximizeButton={() => null}
				top={top}
				theme
				className={className}
			>
				{children}
				{(confirmBtnTitle || closeBtnTitle || saveCreateBtnTitle) && (
					<section className="FooterSection">
						{confirmBtnTitle !== undefined ? (
							<MotifButton
								type="button"
								variant="primary-alt"
								title={confirmBtnTitle}
								onClick={onOkClick}
								disabled={isOkButtonDisabled}
							>
								{confirmBtnTitle}
							</MotifButton>
						) : (
							<> </>
						)}
						{saveCreateBtnTitle !== undefined ? (
							<MotifButton
								type="button"
								variant="secondary"
								title={saveCreateBtnTitle}
								onClick={onSaveCreateClick}
								disabled={isSaveCreateButtonDisabled}
							>
								{saveCreateBtnTitle}
							</MotifButton>
						) : (
							<> </>
						)}
						{closeBtnTitle !== undefined ? (
							<MotifButton
								type="button"
								variant="secondary"
								title={closeBtnTitle}
								onClick={onClose}
								disabled={isCancelButtonDisabled}
							>
								{closeBtnTitle}
							</MotifButton>
						) : (
							<> </>
						)}
					</section>
				)}
			</StyledWindow>
		</ThemeProvider>
	);
}

const StyledWindow = styled(Window)`
	border-radius: 0 !important;
	display: flex !important;
	.k-window-titlebar {
		&.k-dialog-titlebar {
			background: #2e2e38;
			color: #fff;
			padding: 0.6rem 2rem;
			min-height: 4.5rem;
			border-radius: 0;
			.k-window-title {
				padding: 0;
				margin: 0;
			}
			.k-window-actions {
				margin: 0;
				margin-inline-end: 0;
			}
		}
	}
	.k-window-content {
		padding: 1rem 0 0 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		.rich-text-kendo-editor {
			padding: 0;
			height: calc(100% - 4.5rem);
		}
		.FooterSection {
			display: flex;
			align-items: center;
			padding: 1rem 2rem;
			border-top: 0.1rem solid #e1e1e6;
			button {
				font-size: 1.4rem;
				min-width: 9rem;
				line-height: 2.2rem;
				& + button {
					margin-left: 1rem;
				}
			}
		}
	}
`;
