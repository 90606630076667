import React, {useEffect, useState} from 'react';
import {
	labels,
	engagementV2PageSizeOptions,
	engagementV2DefaultSkip,
	tabTypes,
	currentResource,
	engagementFilterTypes
} from '../../../util/uiconstants';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
	MotifPagination,
	MotifPaginationSelect,
	MotifPaginationSelectItem,
	MotifFormField,
	MotifSearch,
	MotifCard
} from '@ey-xd/motif-react';
import EngagementList from '../EngagementsV2/EngagementList';
import {getEngagementsV2} from '../../../actions/engagementsaction';
import styled from 'styled-components';
import FilterEngagements from '../FilterEngagements/FilterEngagements';
import Loader from '../../Common/Loaders/loadingindicator';
import {searchEngagementState} from '../../../actions/dashboardactions';
import {setToastError} from '../../../actions/engagementerroraction';
import Utility from '../../../util/utils';

const PortfolioView = (props) => {
	const {
		fullname,
		activeFilter,
		defaultTop,
		defaultSkip,
		isReviewEngagementAvailable
	} = props;

	const history = useHistory();
	const dispatch = useDispatch();
	const myEngagementsV2 = useSelector((store) => store.myEngagementsV2);
	const searchText = useSelector((store) => store.searchEngagementState || '');

	const checkActiveFilter = () => {
		switch (activeFilter) {
			case tabTypes.favoritesv2Filter:
				return engagementFilterTypes.MY_FAVORITES;
			case tabTypes.reviewengagementsv2Filter:
				return engagementFilterTypes.REVIEW_ENGAGEMENTS;
			default:
				return engagementFilterTypes.ALL;
		}
	};

	const [displayedEngagements, setDisplayedEngagements] = useState();
	const [skip, setSkip] = useState(defaultSkip);
	const [top, setTop] = useState(defaultTop);
	const [engagementType, setEngagmentType] = useState(() => checkActiveFilter());

	const [searchExpression, setSearchExpression] = useState(
		searchText?.searchText || ''
	);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getEngagementsAsync();
	}, [engagementType]);

	useEffect(() => {
		setDisplayedEngagements(myEngagementsV2);
	}, [myEngagementsV2]);

	useEffect(() => {
		if (skip > displayedEngagements?.pagerModel?.totalpages) {
			setSkip(engagementV2DefaultSkip);
			setUpdatedURL(engagementV2DefaultSkip, top);
		} else {
			setUpdatedURL(skip, top);
		}
	}, [displayedEngagements]);

	useEffect(() => {
		getEngagementsAsync();
		setUpdatedURL(skip, top);
	}, [skip, top]);

	const setUpdatedURL = (skipValue, topValue) => {
		let searchTypeRoute = '';

		switch (engagementType) {
			case engagementFilterTypes.ALL:
				searchTypeRoute = '/engagementsv2/allengagements';
				break;
			case engagementFilterTypes.MY_FAVORITES:
				searchTypeRoute = '/engagementsv2/favorites';
				break;
			case engagementFilterTypes.REVIEW_ENGAGEMENTS:
				if (isReviewEngagementAvailable) {
					searchTypeRoute = '/engagementsv2/reviewengagements';
				} else {
					return;
				}
				break;
			default:
				searchTypeRoute = '/engagementsv2/allengagements';
				break;
		}
		history.push(
			`${searchTypeRoute}?pagenumber=${skipValue}&pagesize=${topValue}`
		);
	};

	const getEngagementsAsync = async (isSearchTextEmpty = false) => {
		await loadEngagements(isSearchTextEmpty);
	};

	const loadEngagements = async (isSearchTextEmpty) => {
		setIsLoading(true);
		const params = {
			top: top,
			skip: skip,
			searchType: 3, // contains search
			searchKey: isSearchTextEmpty
				? ''
				: Utility.getSanitizedText(searchExpression, {
						encode: true
				  }),
			engagementType:	engagementType 
		};

		if (
			engagementType === engagementFilterTypes.REVIEW_ENGAGEMENTS &&
			!isReviewEngagementAvailable
		) {
			await dispatch(
				setToastError(labels.engv2Errors.noReviewEngagementsPermission)
			);
			setEngagmentType(engagementFilterTypes.ALL);
			return;
		}
		//new Zues API call here
		await dispatch(getEngagementsV2(params));
		setIsLoading(false);
	};

	const onSizeOfPageClick = (topValue) => {
		if (topValue !== top) {
			setTop(topValue);
			setSkip(1);
		}
	};

	const onNextPageClick = (value) => {
		setSkip(value);
	};

	const onChange = (event) => {
		if (event.target.value.length <= 100) {
			setSearchExpression(event.target.value);
			dispatch(searchEngagementState(event.target.value));
		}
		if (event.type !== 'change') {
			getEngagementsAsync(true);
		}
	};

	const onSearchHandler = () => {
		setSearchExpression(searchText?.searchText || '');
		if (skip === 1) getEngagementsAsync();
		else setSkip(1);
	};

	return (
		<PortfolioViewStyles className="portfolio-tab">
			<section className="portfolio-wrapper">
				<section className="portfolio-info">
					<span className="information-text">{labels.informationText}</span>
					<h2 className="portfolio-user">{fullname}</h2>
				</section>
				<section className="portfolioselect-wrapper">
					<FilterEngagements
						setSearchType={setEngagmentType}
						searchType={engagementType}
						isReviewEngagementAvailable={isReviewEngagementAvailable}
					/>
					<MotifFormField className="engsearch-wrapper">
						<MotifSearch
							className="engagement-search"
							value={searchExpression}
							id="search-engagements"
							onChange={(event) => {
								onChange(event);
							}}
							searchButtonTitle={labels.searchPlaceholder}
							placeholder={labels.searchEngagementsPlaceholder}
							onSearchButtonClick={() => {
								onSearchHandler();
							}}
							onEnter={() => {
								onSearchHandler();
							}}
							clearButtonTitle={labels.clearSearch}
						/>
					</MotifFormField>
				</section>
				<section className="engagements-wrapper">
					{isLoading ? (
						<Loader show view="blockView" />
					) : displayedEngagements &&
					  (engagementType === engagementFilterTypes.REVIEW_ENGAGEMENTS
							? isReviewEngagementAvailable
							: true) &&
					  displayedEngagements?.engagementReadModel?.length > 0 ? (
						<EngagementList
							engagements={displayedEngagements}
							refreshDashboard={loadEngagements}
							searchType={engagementType}
						/>
					) : (
						<MotifCard className="noengagements-template">
							<span className="noengagements-text">
								{searchExpression.length > 0
									? labels.nomatches +
									  ', ' +
									  labels.pleasetryagain.toLowerCase()
									: engagementType === engagementFilterTypes.ALL
									? labels.engv2noActiveEngagements
									: engagementType === engagementFilterTypes.MY_FAVORITES
									? labels.engv2noFavoriteEngagements
									: labels.engv2noReviewEngagements}
							</span>
						</MotifCard>
					)}
				</section>
				<section className="pagination-wrapper">
					<MotifPagination
						currentPage={skip}
						onPageChange={onNextPageClick}
						firstButtonProps={{
							title: currentResource.common.engv2Pagination.iconHover.first
						}}
						lastButtonProps={{
							title: currentResource.common.engv2Pagination.iconHover.last
						}}
						prevButtonProps={{
							title: currentResource.common.engv2Pagination.iconHover.previous
						}}
						nextButtonProps={{
							title: currentResource.common.engv2Pagination.iconHover.next
						}}
						max={
							displayedEngagements?.pagerModel
								? displayedEngagements?.pagerModel.totalPages
								: engagementV2DefaultSkip
						}
					>
						<MotifPaginationSelect>
							{engagementV2PageSizeOptions.map((option) => (
								<MotifPaginationSelectItem
									key={`pagination-item-${option}`}
									selected={option === top}
									onClick={() => onSizeOfPageClick(option)}
								>
									{currentResource.common.engv2Pagination.show} {option}
								</MotifPaginationSelectItem>
							))}
						</MotifPaginationSelect>
					</MotifPagination>
				</section>
			</section>
		</PortfolioViewStyles>
	);
};

export default PortfolioView;

const PortfolioViewStyles = styled.section`
	height: calc(100% - 6.6rem);
	.portfolio-wrapper {
		height: 100%;
		.portfolio-info {
			padding-left: 3.7rem;
			width: 45%;
			height: 13rem;
			@media only screen and (min-width: 992px) and (max-width: 1366px) {
				height: 9rem;
			}
			.portfolio-user {
				font-size: 4rem;
				margin-top: 1rem;
				line-height:normal;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 99%;
			}
			.information-text {
				display: block;
				font-size: 1.4rem;
			}
		}
		.portfolioselect-wrapper {
			padding-left: 3.7rem;
			.engsearch-wrapper {
				display: inline-block;
				margin-left: 1.5rem;
				width: 16%;
				vertical-align: top;
				.engagement-search {
					width: 100%;
					.motif-input {
						font-size: 1.4rem;
						height: 4rem;
						@media screen and (max-width: 1366px) {
							height: 4.7rem;
						}
						@media screen and (min-width: 1367px) and (max-width: 1600px) {
							height: 4.4rem;
						}
					}
					.motif-input::placeholder {
						font-size: 1.4rem;
					}
					.motif-search-input-icon,
					.motif-typeahead-close-button {
						top: 1.2rem;
					}
				}
			}
		}
	}
	.engagements-wrapper {
		height: calc(100% - 25rem);
		margin-bottom: 2rem;
		margin-top: 2rem;
		border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
		@media only screen and (min-width: 992px) and (max-width: 1366px) {
			height: calc(100% - 20.5rem);
		}
		.motif-card.noengagements-template {
			background: ${(props) => props.theme.color[`whiteGrey550`]};
			margin: 2rem 4rem;
			height: 18rem;
			width: calc(100% - 8rem);
			padding: 2.4rem 2.4rem 0 2.4rem;
			border-radius: 0.4rem;
			.noengagements-text {
				display: block;
				text-align: center;
				padding-top: 5.4rem;
				font-size: 1.6rem;
				font-weight: 300;
				color: ${(props) => props.theme.color[`grey400`]};
			}
		}
	}
	.pagination-wrapper {
		padding-right: 3.7rem;
		.motif-pagination {
			justify-content: flex-end;
			@media screen and (max-width: 1366px) {
				margin-top: -1.2rem;
			}
			@media screen and (min-width: 1367px) and (max-width: 1600px) {
				margin-top: -0.5rem;
			}
			.motif-icon-button.motif-pagination-button:disabled {
				background-color: transparent;
			}
			.motif-pagination-select-wrapper
				.motif-pagination-select
				.motif-dropdown-menu {
				margin-top: -15.1rem;
			}
			.motif-dropdown-trigger .motif-icon-button {
				font-size: 1.2rem;
				font-weight: 400;
			}
			.motif-pagination-count,
			.motif-pagination-current-page,
			.ref-input-elem {
				font-size: 1.4rem;
			}
		}
	}
	.empty-message {
		align-items: center;
		justify-content: center;
		height: calc(100vh - 275px);
		color: ${(props) => props.theme.color[`grey500`]};
		font-size: 1.4rem;
		text-align: center;
		font-weight: normal;
		display: flex;
		font-size: 1.4rem;
		margin: -0.4rem 0 0 1.7rem;

		& .norecords {
			margin-bottom: 10.5rem;
		}

		& p {
			float: left;
			width: 100%;
			margin: 0;
			line-height: 2.6rem;
			font-size: 1.4rem;
			color: ${(props) => props.theme.color[`grey400`]};

			&.try-message {
				font-size: 2rem;
			}
		}
	}
`;
