/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React, {useState} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {EYForm, FormComboBox} from '@ey/form';
import {labels} from '../../../util/uiconstants';
import {useDidMountEffect} from '../../../util/customHooks';

const SubServiceLineList = (props) => {
	const {
		subserviceline,
		isDisabled,
		subservicelineOption,
		tooltipClassName,
		onChange,
		showError
	} = props;

	const [triggerCnt, setTriggerCnt] = useState(0);

	useDidMountEffect(() => {
		setTriggerCnt((prev) => prev + 1);
	}, [showError]);

	const onSubServiceLineChange = (key, value) => {
    	onChange(key, value);
  	}

	return (
		<SubServiceLineContainer>
			<section>
				<EYForm
					id="subservicelineform"
					onChange={onSubServiceLineChange}
					onSubmit={(e) => {
						e.preventDefault();
					}}
					action="\"
					validationTriggerCounter={triggerCnt}
					key={9}
				>
					<FormComboBox
						id="subserviceline"
						name="subserviceline"
						value={subserviceline === null ? undefined : subserviceline}
						is_required
						help
						searchable
						disabled={isDisabled}
						placeholder={labels.subserviceline}
						options={
							subservicelineOption &&
							subservicelineOption.map((subserviceline) => {
								return {
									value: String(subserviceline.subservicelineid),
									label: String(subserviceline.subservicelinename)
								};
							})
						}
						key={10}
						errormessage={labels.subservicelinerequired}
						tooltipClassName={tooltipClassName}
						/>
				</EYForm>
			</section>
		</SubServiceLineContainer>
	);
};
export default SubServiceLineList;

const SubServiceLineContainer = styled.li`
	display: inline-flex;
	margin: 0;
`;

/**
 * This part of the Redux lifecycle comes right after the Reducers have
 * been called, passing the new "state" values into this function, where
 * we tell Redux what portion of our Redux state to expose to this
 * React component.
 * You can think of this function as injecting data from Redux into the
 * React component.
 * The name "state.forms" refers to the "forms" name we assigned
 * in the root reducer, located in reducers/index.js
 * @param {Object} storeState - The current Redux store state
 * //@param {Object} ownProps - reference to the component's own props, which
 *      can be useful for dealing with prop values like those coming from
 *      react-router.
 * @return {{forms: (Array|*|HTMLCollection)}} The object describing the
 *      piece of the state being exposed.
 */
/**
 * The mapDispatchToProps just chooses which actions you want to expose
 * directly as props, instead of having to refer to them using the
 * .dispatch(types.SOME_ACTION_NAME) syntax.
 * The signature for bindActionCreators is (Object, Function), so if
 * you need to bind multiple actions object, use Object.assign to create
 * a new Object containing all your actions.
 * @param {Function} dispatch - dispatch Redux function
 * @return {{actions: Object}} An object containing all the actions
 *  passed as parameters, plus the dispatch function.
 */
