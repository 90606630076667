import React from 'react';
import EditEngagement from './demoPOC/editengagement';
import RestoreEngagementModel from '../WorkflowWizards/RestoreWorkflow/engagement/restoremodel';
/*
	readyForFavClick : this props is used for avoiding quick subsequent clicks on favorites button ie. double click etc. 
	On click of favorite - onIconClick even will be gone for 300ms so no click can be performed. 
*/

const divStyle = {
	margin: '1rem'
};

const ht400 = {
	height: '80rem'
};

const noradius = {
	'border-radius': '0'
};

export default class SampleTest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showtimelinelarge: false,
			showtimelinesmall: false,

			domainChooseExisting: false,
			domainClientId: -1,
			domainClientCode: 'eyva',
			domainClientName: 'asdfads',
			domainWorkspaceId: 33333,
			domainWorkspaceName: 'wkkkk',
			domainClientCode: 2222,
			domainEngagementName: 'engnew',
			domainSelectedArcFileGuid: '01010-10001-00000-099988',

			domainOriginEngId: 10001,
			domainOriginWorkspaceId: 20001,
			domainOriginLocation: 111,

			domainOriginLanguageId: 1,
			domainOriginPrimoffId: 2,
			domainOriginServiceLnId: 1,
			domainOriginSubServiceLnId: 2,

			domainOriginEngCodeArr: [
				{
					data: {
						code: 'eyvanas',
						name: 'dummy dsfasdf',
						clientengagementid: 0,
						isprimary: true
					}
				},
				{
					data: {
						code: 'test',
						name: 'teset',
						clientengagementid: 0,
						isprimary: false
					}
				}
			],
			domainOriginEngYearEndDate: '01/01/2011',
			domainOriginEngEndDate: '04/12/2019',
			domainOriginAudReportDate: '09/08/2020'
		};
	}

	showtimelineLargeClick = () => {
		this.setState({
			showtimelinelarge: !this.state.showtimelinelarge,
			showtimelinesmall: false
		});
	};

	showtimelinesmallClick = () => {
		this.setState({
			showtimelinelarge: false,
			showtimelinesmall: !this.state.showtimelinesmall
		});
	};

	getDependencyData = (domainFieldName) => {
		return this.state[domainFieldName];
	};

	render() {
		return (
			<section>
				<div>hellllloooo</div>

				<div className="panel panel-primary" style={noradius}>
					<div className="panel-heading">
						Page for sample demos and proof of concepts
					</div>
					<div className="panel-body" style={ht400}>
						<div style={divStyle}>
							<button
								onClick={this.showtimelineLargeClick}
								type="button"
								className="btn btn-info"
							>
								{' '}
								Create Case
							</button>
							{this.state.showtimelinelarge && (
								<CallerC getDependencyData={this.getDependencyData} />
							)}
						</div>
						<div style={divStyle}>
							<button
								onClick={this.showtimelinesmallClick}
								type="button"
								className="btn btn-info"
							>
								{' '}
								Edit case
							</button>
							{this.state.showtimelinesmall && <EditEngagement />}
						</div>
					</div>
					<div className="panel-footer">Footer</div>
				</div>
			</section>
		);
	}
}
class CallerC extends React.Component {
	prepareRestoreModel = () => {
		var mdl = new RestoreEngagementModel();
		var wkModel = mdl.getWorkspaceModel();
		var isExistingWk = this.props.getDependencyData('domainChooseExisting');
		wkModel.data.operationtype = isExistingWk
			? 'RestoreToExistingWorkspace'
			: 'RestoreEngagement';

		wkModel.data.clientcode = this.props.getDependencyData('domainClientCode');
		wkModel.data.clientname = this.props.getDependencyData('domainClientName');
		wkModel.data.workspacedesc = this.props.getDependencyData(
			'domainWorkspaceName'
		);
		wkModel.data.workspaceid = this.props.getDependencyData(
			'domainWorkspaceId'
		);
		wkModel.data.isclientvalidated = isExistingWk ? false : true;
		wkModel.data.clientid = this.props.getDependencyData('domainClientId');
		wkModel.data.countryid = this.props.getDependencyData(
			'domainOriginLocation'
		);
		wkModel.data.countryname = this.props.getDependencyData(
			'domainOriginCountryName'
		); //TB
		wkModel.data.rfworkspaceid = this.props.getDependencyData(
			'domainOriginWorkspaceId'
		);
		wkModel.data.selectedarcfileguid = this.props.getDependencyData(
			'domainSelectedArcFileGuid'
		);

		wkModel.collections = {engagements: []};
		wkModel.collections.engagements.push(mdl.WorkspaceEngagements());
		let engDt = wkModel.collections.engagements[0].data;
		engDt.description = this.props.getDependencyData('domainEngagementName');
		engDt.primaryoffice = this.props.getDependencyData('domainOriginPrimoffId');
		engDt.languageid = this.props.getDependencyData('domainOriginLanguageId');
		engDt.yearenddate = this.props.getDependencyData(
			'domainOriginEngYearEndDate'
		);
		engDt.enddateperiod = this.props.getDependencyData(
			'domainOriginEngEndDate'
		);
		engDt.reportreleasedate = this.props.getDependencyData(
			'domainOriginAudReportDate'
		);
		engDt.archivalengagementid = this.props.getDependencyData(
			'domainOriginEngId'
		);
		engDt.servicelineid = this.props.getDependencyData(
			'domainOriginServiceLnId'
		);
		engDt.subservicelineid = this.props.getDependencyData(
			'domainOriginSubServiceLnId'
		);

		wkModel.collections.engagements[0].collections = {engagementcodes: []};
		let engCol = wkModel.collections.engagements[0].collections;

		//		this.state.engagementcodearray.map(
		this.props
			.getDependencyData('domainOriginEngCodeArr')
			.map((engcodeobject, index) => {
				let newCodeItem = {
					code: engcodeobject.data.code,
					name: engcodeobject.data.name,
					isprimary: engcodeobject.data.isprimary,
					clientengagementid: '' //TB
				};
				engCol.engagementcodes.push(newCodeItem);
			});

		return JSON.stringify(wkModel);
	};

	componentDidMount() {}

	render() {
		var jsn = this.prepareRestoreModel();
		console.log('xxxxxx xxxxxxxxxxx xxxxxx xxxxjson =');
		console.log(jsn);
		return <div>calllerrrrrrrrrrr</div>;
	}
}
