import * as types from '../actiontypes';

export function getEngagementTemplates(url) {
	return {
		type: types.GET_ALL_ENGAGEMENT_TEMPLATES,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function editTemplate(url, scope) {
	return {
		type: types.EDIT_ENGAGEMENT_TEMPLATE,
		meta: {
			api: {
				url: url,
				method: 'PUT',
				data: scope
			}
		}
	};
}

export function deleteTemplate(url) {
	return {
		type: types.DELETE_TEMPLATE,
		meta: {
			api: {
				url: url,
				method: 'DELETE'
			}
		}
	};
}
