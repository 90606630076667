import * as QueryString from 'query-string';

import React, {useEffect, useRef, useState} from 'react';
import {labels, standardStrings, urls} from '../../../util/uiconstants';

import ChooseAcceptDeclineDelegate from '../ImportDataPackageWorkflow/acceptdeclinedelegaterequest';
import DeclinePackage from '../ImportDataPackageWorkflow/declinepackage';
import DelegatePackage from '../ImportDataPackageWorkflow/delegatepackage';
import {SVGICONS} from '@ey/icons';
import SelectEngagement from '../ImportDataPackageWorkflow/selectengagement';
import SelectWorkspace from '../ImportDataPackageWorkflow/selectworkspace';
import WizardWrapper from '../Common/WizardWrapper';
import env from '../../../util/env';
import {getCopyRequest} from '../../../actions/copyhubactions';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import Loader from '@ey/loader';
import {useHistory} from 'react-router-dom';
import {usePrevious} from '../../../util/customHooks';

const GroupAuditLinkWizardLoader = (props) => {
	const {location} = props;
	const history = useHistory();

	//Redux states
	const dispatch = useDispatch();

	//custom hooks
	const locationRef = usePrevious(location);
	const mounted = useRef();

	const params = QueryString.parse(location.search);
	let locn = location;
	let oldPath = standardStrings.EMPTY;
	if (
		locn !== undefined &&
		locn !== null &&
		locn.state !== undefined &&
		locn.state !== null
	) {
		oldPath = locn.state.oldPath;
	}

	//Local states
	const [activeStepIndex, setActiveStepIndex] = useState(
		params?.engagementid ? 1 : 0
	);

	const [step2NextChng, setstep2NextChng] = useState(1);
	const [step2FinishChng, setstep2FinishChng] = useState(1);
	const [step3FinishChng, setstep3FinishChng] = useState(1);
	const [canGoToNextStep, setcanGoToNextStep] = useState(false);
	const [isLoading, setisLoading] = useState(false);
	const [wizLoaderName, setWizLoaderName] = useState('');
	const [domain, setDomain] = useState({
		domainAccept: true,
		domainDecline: false,
		domainDelegate: false,
		domainAcceptDelegateDecline: 0, //0:accept , 1: delegate , 2: decline
		domainWorkspaceId: -1,
		domainClientId: -1
	});

	const setThreeWizardSteps = [
		{
			stepName: labels.step01,
			stepDescription: labels.chooseaction,
			active: true
		},
		{
			stepName: labels.step02,
			stepDescription: domain.domainAccept
				? labels.selectworkspace
				: domain.domainDecline
				? labels.decline
				: labels.delegate,
			active: false
		},
		{
			stepName: labels.step03,
			stepDescription: labels.selectengagement,
			active: false
		}
	];

	const setTwoWizardSteps = [
		{
			stepName: labels.step01,
			stepDescription: labels.chooseaction,
			active: true
		},
		{
			stepName: labels.step02,
			stepDescription: domain.domainAccept
				? labels.selectworkspace
				: domain.domainDecline
				? labels.decline
				: labels.delegate,
			active: false
		}
	];

	const [wizardSteps, setWizardSteps] = useState(setThreeWizardSteps);

	const getGroupAuditRequesturl =
		env.getURL('serviceUrl') +
		'/' +
		urls.GROUPAUDITENGAGEMENTS_URL +
		'?phase=GALinkRequest&filters=GAComponentID eq ';

	const buttons = [
		{
			label: activeStepIndex == 0 ? labels.cancel : labels.back, // Change label based on active step.
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						//On first step, returns to Dashboard.
						navigateBack();
						break;
					default:
						setActiveStepIndex((prev) => prev - 1);
						break;
				}
			},
			isVisible: true,
			disabled: false
		},
		{
			label:
				activeStepIndex == wizardSteps.length - 1
					? labels.finish
					: labels.continue,
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						// Trigger the child component behavior to finish and close on step 1.
						setActiveStepIndex((prev) => prev + 1);
						resetDomainValues([
							'domainClientId',
							'domainClientName',
							'domainWorkspaceId',
							'domainWorkspaceName'
						]);
						break;
					case 1:
						if (wizardSteps.length == 3) {
							canGoToNextStep
								? setActiveStepIndex((prev) => prev + 1)
								: setstep2NextChng((prev) => prev + 1);
						} else {
							setstep2FinishChng((prev) => prev + 1);
						}
						break;
					case wizardSteps.length - 1:
						// Trigger the child component behavior to finish and close on step 2
						setstep3FinishChng((prev) => prev + 1);
						break;
				}
			},
			isVisible: true,
			disabled: false,
			icon: (
				<SVGICONS
					styleName="medium arrow-right"
					name="chevron-right"
					iconPositionRight
				/>
			)
		}
	];

	useEffect(() => {
		if (!mounted.current) {
			const params = QueryString.parse(location.search);
			getCopyRequestAsync(params.GAComponentID);
			mounted.current = true;
		} else {
			const prevGAComponentId = QueryString.parse(
				locationRef.search
			).GAComponentID;
			const gaComponentID = QueryString.parse(location.search).GAComponentID;

			if (prevGAComponentId !== gaComponentID) {
				getCopyRequestAsync(gaComponentID);
			}
		}
	});

	useEffect(() => {
		if (domain.domainAcceptDelegateDecline == 0) {
			setWizardSteps(setThreeWizardSteps);
		} else {
			setWizardSteps(setTwoWizardSteps);
		}
	}, [domain.domainAcceptDelegateDecline]);

	const getCopyRequestAsync = async (id) => {
		let wizLoaderName = labels.groupaudit;
		setisLoading(true);
		try {
			await dispatch(getCopyRequest(getGroupAuditRequesturl + id, id));
		} finally {
			setisLoading(false);
			setWizLoaderName(wizLoaderName);
		}
	};

	const resetDomainValues = (valuesArray) => {
		valuesArray.forEach((valueName) => {
			updateWizardData(valueName, undefined);
		});
	};

	// Step valid functions are triggered from inside components, thats how we manage canGoToNextStep local state.

	const isStep2Valid = (isComponentDataValid) => {
		if (activeStepIndex == 1) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};

	const isStep3Valid = (isComponentDataValid) => {
		if (activeStepIndex == 2) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};

	const navigateBack = () => {
		if (oldPath != '') {
			history.push(oldPath);
		} else {
			history.push('');
		}
	};

	const updateWizardData = (fieldName, fieldValue) => {
		//Adds or update a prop of the state object domain.
		setDomain((prev) => {
			return {
				...prev,
				[fieldName]: fieldValue
			};
		});
	};

	const getWizardData = (domainFieldName) => {
		return domain[domainFieldName];
	};

	const step1 = () => {
		return (
			<ChooseAcceptDeclineDelegate
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				isGroupAudit
			/>
		);
	};

	const step2 = (copyHubRequestID) => {
		return domain.domainAccept ? (
			<SelectWorkspace
				isComponentValid={isStep2Valid}
				updateWizardData={updateWizardData}
				nextClicked={step2NextChng}
				getDependencyData={getWizardData}
				closeWizard={navigateBack}
				isGroupAudit
			/>
		) : domain.domainDecline ? (
			<DeclinePackage
				isComponentValid={isStep2Valid}
				updateWizardData={updateWizardData}
				nextClicked={step2NextChng}
				getDependencyData={getWizardData}
				onFinishClick={step2FinishChng}
				closeWizard={navigateBack}
				isGroupAudit
			/>
		) : (
			<DelegatePackage
				copyRequestId={copyHubRequestID}
				isComponentValid={isStep2Valid}
				updateWizardData={updateWizardData}
				nextClicked={step2NextChng}
				getDependencyData={getWizardData}
				onFinishClick={step2FinishChng}
				closeWizard={navigateBack}
				isGroupAudit
			/>
		);
	};

	const step3 = () => {
		let gaID = QueryString.parse(location.search).GAComponentID;
		return (
			<SelectEngagement
				isComponentValid={isStep3Valid}
				updateWizardData={updateWizardData}
				onFinishClick={step3FinishChng}
				getDependencyData={getWizardData}
				isGroupAudit
				gaComponentID={gaID}
			/>
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader view="fullscreen" />
			) : (
				<StyledWizardWrapper>
					<WizardWrapper
						wizLoaderName={wizLoaderName}
						currentStepIndex={activeStepIndex}
						wizardSteps={wizardSteps}
						setWizardSteps={setWizardSteps}
						buttons={buttons}
						theme={
							document
								.getElementsByTagName('body')[0]
								.getAttribute('motif-theme') === 'light'
								? null
								: 'dark'
						}
						stepContent={[step1(), step2(params.copyRequestId), step3()]}
					/>
				</StyledWizardWrapper>
			)}
		</>
	);
};

export default GroupAuditLinkWizardLoader;

const StyledWizardWrapper = styled.section`
	width: 100%;
	padding: 0 4rem;
	header h5 {
		font-size: 2rem;
		font-weight: bold;
		width: 100%;
		padding: 1.5rem 0;
	}
	.componentBodyWrapper {
		height: calc(100vh - 28rem);
	}
`;
