import React from "react";

export default class ErrorBoundary extends React.Component {

	constructor(props) {
		super(props);
		this.state = { error: null };
	}

	static getDerivedStateFromError(error) {
		return { error };
	}

	componentDidCatch(error, errorInfo) {
		console.error(`Error from RemoteComponent #${this.props.id}:`, error);

		if (this.props.onError) {
			this.props.onError(error, errorInfo);
		}
	}

	render() {
		if (this.state.error) {
			if (this.props.fallback) {
				return this.props.fallback;
			} else {
				return <></>;
			}
		} else {
			return this.props.children;
		}
	}
}