import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {lightColor} from '@ey/styled-theme';
import {EYLogo} from '@ey/canvascoreservices';
import {isEmpty} from 'lodash';
import {setLanguage} from '../../util/uiconstants';
import {MotifDropdown} from '@ey-xd/motif-react';
import {labels} from '../../util/uiconstants';

const Nav = (props) => {
	const user = useSelector((store) => store.user);
	const meInfo = useSelector((store) => store.userPermissions);

	useEffect(() => {
		setLanguage('en-us');
	}, [user]);

	let validUser = false;
	if (user && user.hasOwnProperty('id') && !isEmpty(user.id)) {
		validUser = true;
	}

	return (
		<StyledNavbar className="styled-nav-bar">
			<section className="nav-bar">
				<section className="nav-bar__ey-logo">
					{validUser ? (
						<Link
							className="LogoLink"
							to={{
								pathname: '/admin',
								state: {
									doNotReload: false
								}
							}}
						>
							<EYLogo logoType="" />
							<h1 className="nav-bar__ey-logo__text">
								<b>{labels.adminTool}</b>
							</h1>
						</Link>
					) : (
						<span>
							<Link
								className="LogoLink"
								to={{
									pathname: '/admin'
								}}
							>
								<EYLogo logoType="" />
								<h1 className="nav-bar__ey-logo__text">
									<b>{labels.adminTool}</b>
								</h1>
							</Link>
						</span>
					)}
				</section>
				<section className="nav-bar_right-wrapper">
					<section className="UserNav-Item ">
						{meInfo?.userInitials && (
							<MotifDropdown
								placement="bottom-right"
								className="user-dropdown"
								trigger={
									<section className="user-initials" title={'User options'}>
										<span className="initial">{meInfo?.userInitials}</span>
									</section>
								}
							></MotifDropdown>
						)}
					</section>
				</section>
			</section>
		</StyledNavbar>
	);
};

export default Nav;

const StyledNavbar = styled.section`
	height: 6rem;
	width: 100%;
	border: none;
	background-color: ${lightColor[`grey500`]};
	display: flex;
	position: relative;

	.nav-bar {
		max-width: 2048px;
		margin: 0 auto;
		display: flex;
		width: calc(100vw - 44px);
	}

	.mainResponsiveWrapper {
		display: flex;
	}

	.delegate-btn {
		display: inherit;
		&.motif-icon-button {
			background: transparent !important;
			&:hover {
				background: transparent !important;
			}
		}
		.user-initials {
			border-radius: 50%;
			width: 3rem;
			height: 3rem;
			display: inline-block;
			vertical-align: middle;
			line-height: 3rem;
			text-align: center;
			background-color: ${(props) => props.theme.color[`green500`]};
			color: ${lightColor[`white`]};
			font-size: 1.2rem;
			font-weight: 400;
		}
	}

	.nav-bar__ey-logo {
		vertical-align: top;
		display: inline-block;
		user-select: none;
		.LogoLink{
			margin: 1.1rem 0 1.6rem;
			display: block;
			height: 3.3rem;
		}
		figure {
			width: 3.1rem;
			height: 3.3rem;
			margin-right: 0.8rem;
		}
		.nav-bar__ey-logo__text {
			font-size: 2.4rem;
			display: inline-block;
			font-weight: 300;
			margin: 1.5rem 0 0 0;
			color: ${lightColor[`white`]};
			b{
				font-weight: 300;
			}
		}
	}

	.nav-bar_right-wrapper {
		margin-left: auto;
		.nav-bar__user-profile {
			position: relative;
			display: inline-flex;
			max-width: 30rem;
			width: auto;
			font-size: 1.4rem;
			height: 100%;
			align-items: center;
			color: ${lightColor[`white`]};

			.motif-dropdown .motif-dropdown-menu {
				margin-right: 0.8rem;
				margin-top: 1.3rem;
				z-index: 9999;
			}

			.motif-dropdown .motif-dropdown-menu .motif-dropdown-item {
				font-weight: 400;
				font-size: 1.4rem;
				height: 4.2rem;
			}

			& > a {
				color: ${lightColor[`white`]};
				outline: none;
				display: flex;
				align-items: center;
				&:hover {
					text-decoration: none;
				}
				.nav-bar__user-profile__user-name {
					display: inline-block;
					max-width: 25rem;
					width: auto;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.caret {
					display: inline-block;
					width: 0;
					height: 0;
					vertical-align: middle;
					border-top: 0.4rem solid;
					border-right: 0.4rem solid transparent;
					border-left: 0.4rem solid transparent;
					margin: 1rem 1rem;
					transition: all 0.2s;
				}
			}
			&.nav-bar__user-profile--active {
				a .caret {
					transform: rotate(180deg);
				}
			}
		}

		.nav-bar__user-profile__menu {
			background-color: ${(props) => props.theme.color['grey225']};
			border: 0.1rem solid ${lightColor[`grey300`]};
			box-shadow: 0 0 2rem -1rem #222;
			width: 21rem;
			position: absolute;
			right: 4rem;
			top: 5rem;
			overflow: hidden;
			transition: all 0.3s;
			z-index: 9;
			font-size: 1.4rem;

			.nav-bar__user-profile__menu__links {
				display: block;
				padding: 1rem 1.5rem;
				color: ${lightColor[`grey500`]};
				border-top: 0.1rem solid #e5e5e5;
				cursor: pointer;
				text-decoration: none;
				:hover {
					text-decoration: underline;
				}
				&.userProfilePhoto {
					background: ${lightColor[`white`]};
					svg {
						width: 60%;
						height: 60%;
						max-width: 100%;
						max-height: 100%;
						margin: 0 auto;
						display: block;
					}
				}
			}
		}
	}

	.UserNav-Item {
		width: 50%;
		a {
			padding: 1.5rem 2rem 1rem 2rem;
			margin: 0px 0.2rem;
			font-size: 1.5rem;
			font-weight: bold;
			display: inline-block;
			text-decoration: none;
			&:hover {
				text-decoration: none;
				border-bottom: 0.4rem solid ${(props) => props.theme.color[`yellow400`]};
			}
			&.selected {
				color: ${(props) => props.theme.color[`yellow400`]};
				border-bottom: 0.4rem solid ${(props) => props.theme.color[`yellow400`]};
				background-color: ${(props) => props.theme.color[`grey600`]};
			}
			svg {
				fill: ${(props) => props.theme.color[`white`]};
				width: 3rem;
				height: 3rem;
				max-width: 3rem;
				max-height: 3rem;
				cursor: pointer;
				margin-top: 0.3rem;
			}
		}
		.user-initials {
			width: 2.5rem;
			height: 2.5rem;
			line-height: 2.5rem;
			border-radius: 50%;
			margin-top: 1.8rem;
			background: ${(props) => props.theme.color[`blue500`]};
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			color: ${(props) => props.theme.color[`white`]};
			.initial{
				color: ${(props) => props.theme.color[`white`]};
				font-weight: 700;
				text-align: center;
				font-size: 1rem;
				display: inline-block;
			}
		}
		.user-dropdown {
			padding-right: 0.1rem;
			.motif-dropdown-menu {
				border: none;
				margin-top: 1.6rem;
				margin-right: -1.9rem;
				@media screen and (max-width: 1366px) {
					margin-top: 1.1rem;
					margin-right: -1.8rem;
				}
				.motif-dropdown-item {
					font-size: 1.2rem;
				}
			}
		}
	}
`;
