/**
 * Scot.js
 * Created by zl952fc on 02/24/2020.
 */

import {ActionTypes, ArchiveOverrideStatus, EngagementStatus} from '../../../util/enumerations';
import React, {useEffect, useState} from 'react';
import {currentResource, dateFormatsListItems, labels, urls} from '../../../util/uiconstants';
import {useDispatch, useSelector} from 'react-redux';

import EllipsesControl from '@ey/ellipses-control';
import Moment from 'react-moment';
import {MotifButton} from '@ey-xd/motif-react';
import {SVGICONS} from '@ey/icons';
import ShowMoreLess from '../../Common/ShowMoreLess/ShowMoreLess';
import Utility from '../../../util/utils';
import env from '../../../util/env';
import {getSingleEngagementForUrls} from '../../../actions/engagementsaction';
import moment from 'moment';
import styled from 'styled-components';

export default function Notification(props) {
	const {notification} = props;
	const userAction = {
		accept: 1,
		reject: 2,
		error: 3
	};
	const engagementAccessType = {
		default: 1,
		independenceOnly: 2
	};

	const [isLoading, setisLoading] = useState(false);
	const userSettings = useSelector((state) => state.userSettings);
	const dispatch = useDispatch();

	const handleOnClickCompletition = async () => {
		let url =
			env.getURL('serviceUrl') +
			'/' +
			urls.HOME_URL +
			'?phase=MyNavigation&Top=1&Skip=1&filters=searchtypeid%20eq%204,displaytypeid%20eq%201&searchexpression=' +
			notification.engagementId;

		const singleEngagementData = await dispatch(getSingleEngagementForUrls(url));
		redirectToCompletition(singleEngagementData);
	};

	const redirectToCompletition = (redirectEngagement) => {
		if (
			redirectEngagement &&
			redirectEngagement.collections?.clients[0]?.collections?.workspaces[0]?.collections?.engagements[0]?.id ==
				notification.engagementId
		) {
			if (notification.actionType === ActionTypes.EngagementIndependenceIncomplete) {
				const url = '/v1redirect/index?';
				const independenceurlpage = Utility.getIncountryURL(url, redirectEngagement) + '&showindependence=true';
				window.location.href = independenceurlpage;
			} else {
				const url = '/v1redirect/engagementprofile?';
				const profilepage = Utility.getIncountryURL(url, redirectEngagement);
				window.location.href = profilepage;
			}
		}
	};

	useEffect(() => {
		if (props.isInviteAcceptedOrRejected) {
			setisLoading(false);
			props.setisInviteAcceptedOrRejectedToDone();
		}
	}, [props.isInviteAcceptedOrRejected]);

	useEffect(() => {
		if (props.isArchiveInviteAcceptedOrRejected) {
			setisLoading(false);
			props.setisArchiveInviteAcceptedOrRejectedToDone();
		}
	}, [props.isArchiveInviteAcceptedOrRejected]);

	//converting UTC date to local

	const localDate = moment.utc(notification.displayDate).local().format('YYYY-MM-DD HH:mm:ss');

	const getFirstButtonLabel = (notification) => {
		switch (notification.actionType) {
			case ActionTypes.EngagementInvitation:
			case ActionTypes.ArchiveAddition:
				return labels.accept;
			case ActionTypes.CreateError:
				return labels.retry;
			case ActionTypes.GroupAuditInvite:
			case ActionTypes.CopiesReceived:
				return labels.respond;
			case ActionTypes.GaAInstruction: {
				let label = '';
				if (notification.hasReceivedInstructions) {
					label = labels.viewinstructions;
				} else if (notification.hasReceivedInstructionsUpdate) {
					label = labels.viewinstructionsupdates;
				}
				return label;
			}
			case ActionTypes.ArchiveDeadlineDateOverride: {
				if (notification.otherId === ArchiveOverrideStatus.InProgress) {
					return '';
				} else {
					return labels.clear;
				}
			}
			case ActionTypes.DeletedEngagement:
			case ActionTypes.DeactivatedEngagements:
			case ActionTypes.ArchivedEngagement:
			case ActionTypes.DeniedAccess:
				return labels.clear;
			default:
				return '';
		}
	};

	const getSecondButtonLabel = (actionType) => {
		switch (actionType) {
			case ActionTypes.EngagementInvitation:
			case ActionTypes.ArchiveAddition:
				return labels.reject;
			case ActionTypes.GroupAuditInvite:
				return labels.delegate;
			case ActionTypes.CreateError:
				return labels.clear;
			default:
				return '';
		}
	};

	function onFirstButtonClick() {
		notification.isFromList = false;
		setisLoading(true);
		props.onFirstButtonClick(notification);
	}

	function onSecondButtonClick() {
		notification.isFromList = false;
		setisLoading(true);
		props.onSecondButtonClick(notification);
	}

	const getStatus = (actionType, otherId) => {
		switch (actionType) {
			case ActionTypes.EngagementInProgress:
				return currentResource.engagementStatuses[otherId];
			case ActionTypes.CreateError:
				return currentResource.engagementStatuses[otherId];
			default:
				return '';
		}
	};

	const getCopyType = (actionType, otherId) => {
		switch (actionType) {
			case ActionTypes.CopiesReceived:
				return currentResource.copyObjectGroupType[otherId];
			default:
				return '';
		}
	};

	const title1 = getFirstButtonLabel(notification),
		title2 = getSecondButtonLabel(notification.actionType),
		status = getStatus(notification.actionType, notification.otherId),
		copyType = getCopyType(notification.actionType, notification.otherId);

	return (
		<NotificationListItemContainer
			className={isLoading ? 'NotificationListItemContainer acceptreject-inprogress' : 'NotificationListItemContainer'}
		>
			{notification.actionType === ActionTypes.EngagementInvitation && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>

					{props.acceptedEngagementList.find((item) => item.id === notification.engagementId) &&
					props.acceptedEngagementList.find((item) => item.id === notification.engagementId).action ===
						userAction.accept ? (
							<section className="ButtonRightGroup">
								<span>{labels.accepted}</span>
							</section>
						) : props.acceptedEngagementList.find((item) => item.id === notification.engagementId) &&
					  props.acceptedEngagementList.find((item) => item.id === notification.engagementId).action ===
							userAction.reject ? (
								<section className="ButtonRightGroup">
									<span>{labels.rejected}</span>
								</section>
							) : (
								<section className="ButtonRightGroup">
									<button className="FirstButton" label={title1} onClick={onFirstButtonClick}>
										<span className="btnLabel">{title1}</span>
									</button>

									<button className="SecondButton" label={title2} onClick={onSecondButtonClick}>
										<span className="btnLabel">{title2}</span>
									</button>
								</section>
							)}
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.invited + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
						{' ' + labels.by + ' ' + notification.displayuserName}
					</section>

					{props.acceptedEngagementList.find((item) => item.id === notification.engagementId) ? (
						props.acceptedEngagementList.find((item) => item.id === notification.engagementId).action ===
						userAction.error ? (
								<section className="respond-error">
									<section>
										<SVGICONS styleName="small" id="icon" name="error" />
										<span>{labels.responderror}</span>
									</section>
								</section>
							) : null
					) : null}
				</>
			)}
			{notification.actionType === ActionTypes.EngagementProfileIncomplete && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="ButtonRightGroup">
						<MotifButton className="profile-link" onClick={handleOnClickCompletition} variant="text-alt">
							{labels.completeprofileheader}
						</MotifButton>
					</section>
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.acceptedOn + ' '}
						{moment
							.utc(notification.displayDate)
							.format(dateFormatsListItems[userSettings.data.userdatetimeformat].label)}
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.EngagementIndependenceIncomplete && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="ButtonRightGroup">
						<MotifButton className="profile-link" onClick={handleOnClickCompletition} variant="text-alt">
							{labels.completeindependenceheader}
						</MotifButton>
					</section>
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{(notification.otherId === engagementAccessType.independenceOnly ? labels.invitedOn + ':' : labels.acceptedOn) + ' '}
						{moment
							.utc(notification.displayDate)
							.format(dateFormatsListItems[userSettings.data.userdatetimeformat].label)}
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.EngagementAccepted && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{(notification.otherId === engagementAccessType.independenceOnly ? labels.invitedOn + ':' : labels.acceptedOn) + ' '}
						{moment
							.utc(notification.displayDate)
							.format(dateFormatsListItems[userSettings.data.userdatetimeformat].label)}
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.CreateError && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					{notification.otherId === EngagementStatus.CopyError ? (
						<>
							<section className="ButtonRightGroup">
								<button className="FirstButton" label={title1} onClick={onFirstButtonClick}>
									<span className="btnLabel">{title1}</span>
								</button>
								<button className="SecondButton" label={title2} onClick={onFirstButtonClick}>
									<span className="btnLabel">{title2}</span>
								</button>
							</section>
						</>
					) : (
						<section className="ButtonRightGroup">
							<button label={title2} onClick={onFirstButtonClick}>
								<span className="btnLabel">{title2}</span>
							</button>
						</section>
					)}
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.errorDate + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.GroupAuditInvite && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.otherText + '-' + notification.otherName}
							tooltip={notification.otherText + '-' + notification.otherName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="ButtonRightGroup">
						<button label={title1} onClick={onFirstButtonClick}>
							<span className="btnLabel">{title1}</span>
						</button>
					</section>
					<section className="DisplayDate">
						{labels.invited + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.GaAInstruction && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.otherText + '-' + notification.otherName}
							tooltip={notification.otherText + '-' + notification.otherName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="ButtonRightGroup">
						<button label={title1} onClick={onFirstButtonClick}>
							<span className="btnLabel">{title1}</span>
						</button>
					</section>
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.received + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.EngagementInProgress && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="Status">{labels.status + ': ' + status}</section>
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.statusChangeDate + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.DeletedEngagement && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="ButtonRightGroup">
						<button label={title1} onClick={onFirstButtonClick}>
							<span className="btnLabel">{title1}</span>
						</button>
					</section>
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.deletedBy + ' ' + notification.displayuserName + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.DeactivatedEngagements && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="ButtonRightGroup">
						<button label={title1} onClick={onFirstButtonClick}>
							<span className="btnLabel">{title1}</span>
						</button>
					</section>
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.deactivatedBy + ' ' + notification.displayuserName + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.ArchiveAddition && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					{props.acceptedArchiveEngagementList.find((item) => item.id === notification.id) &&
					props.acceptedArchiveEngagementList.find((item) => item.id === notification.id).action ===
						userAction.accept ? (
							<section className="ButtonRightGroup">
								<span>{labels.accepted}</span>
							</section>
						) : props.acceptedArchiveEngagementList.find((item) => item.id === notification.id) &&
					  props.acceptedArchiveEngagementList.find((item) => item.id === notification.id).action ===
							userAction.reject ? (
								<section className="ButtonRightGroup">
									<span>{labels.rejected}</span>
								</section>
							) : (
								<section className="ButtonRightGroup">
									<button className="FirstButton" label={title1} onClick={onFirstButtonClick}>
										<span className="btnLabel">{title1}</span>
									</button>

									<button className="SecondButton" label={title2} onClick={onSecondButtonClick}>
										<span className="btnLabel">{title2}</span>
									</button>
								</section>
							)}
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.invited + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
						{' ' + labels.by + ' ' + notification.displayuserName}
					</section>
					{props.acceptedArchiveEngagementList.find((item) => item.id === notification.engagementId) ? (
						props.acceptedArchiveEngagementList.find((item) => item.id === notification.engagementId).action ===
						userAction.error ? (
								<section className="respond-error">
									<section>
										<SVGICONS styleName="small" id="icon" name="error" />
										<span>{labels.responderror}</span>
									</section>
								</section>
							) : null
					) : null}
				</>
			)}
			{notification.actionType === ActionTypes.DeleteRequest && (
				<>
					<span className="NotificationLeftGroup">
						<section className="ClientName">
							<EllipsesControl
								id="ClientName"
								content={notification.clientName}
								tooltip={notification.clientName}
								isTooltipAvailable
							/>
						</section>
						<section className="Description">
							<EllipsesControl
								id="Description"
								content={notification.engagementDescription}
								tooltip={notification.engagementDescription}
								isTooltipAvailable
							/>
						</section>
						<section className="DisplayDate">
							{labels.id + ': ' + notification.engagementId + ' | '}
							{labels.deletedBy + ' ' + notification.displayuserName + ' '}
							<Moment local fromNow>
								{localDate}
							</Moment>
						</section>
					</span>
				</>
			)}
			{notification.actionType === ActionTypes.ArchivedEngagement && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="ButtonRightGroup">
						<button label={title1} onClick={onFirstButtonClick}>
							<span className="btnLabel">{title1}</span>
						</button>
					</section>
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.archiveDate + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.CopiesReceived && (
				<>
					<section className="EngagementType">
						<span className="LabelType">{labels.type + ':'}</span>
						<span className="LabelValue">{copyType}</span>
					</section>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.otherName}
							tooltip={notification.otherName}
							isTooltipAvailable
						/>
					</section>
					<section className="ButtonRightGroup">
						<button label={title1} onClick={onFirstButtonClick}>
							<span className="btnLabel">{title1}</span>
						</button>
					</section>
					<section className="DisplayDate">
						{labels.receivedFrom + ' ' + notification.displayuserName + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.SystemMessages && (
				<>
					<section className="WarningWrapper">
						<SVGICONS styleName="medium" name="info_outline" />
						<EllipsesControl
							id="MessageTitle"
							content={notification.otherName}
							tooltip={notification.otherName}
							isTooltipAvailable
						/>
					</section>
					<section className="MessageText">
						<ShowMoreLess>{Utility.createRichTextElement(notification.otherText)}</ShowMoreLess>
					</section>
					<section className="DisplayDate">
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.ArchiveDeadlineDateOverride && (
				<>
					<section className="archiveoverridelabelstatus">
						{notification.otherId === ArchiveOverrideStatus.InProgress
							? labels.archiveOverrideInProgress
							: notification.otherId === ArchiveOverrideStatus.Accepted
								? labels.archiveOverrideAccepted
								: labels.archiveOverrideRejected}
					</section>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="ButtonRightGroup">
						<button label={title1} onClick={onFirstButtonClick}>
							<span className="btnLabel">{title1}</span>
						</button>
					</section>
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.requestUserName + ' ' + notification.displayuserName + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
			{notification.actionType === ActionTypes.DeniedAccess && (
				<>
					<section className="ClientName">
						<EllipsesControl
							id="ClientName"
							content={notification.clientName}
							tooltip={notification.clientName}
							isTooltipAvailable
						/>
					</section>
					<section className="Description">
						<EllipsesControl
							id="Description"
							content={notification.engagementDescription}
							tooltip={notification.engagementDescription}
							isTooltipAvailable
						/>
					</section>
					<section className="ButtonRightGroup">
						<button label={title1} onClick={onFirstButtonClick}>
							<span className="btnLabel">{title1}</span>
						</button>
					</section>
					<section className="DisplayDate">
						{labels.id + ': ' + notification.engagementId + ' | '}
						{labels.deniedBy + ' ' + notification.displayuserName + ' '}
						<Moment local fromNow>
							{localDate}
						</Moment>
					</section>
				</>
			)}
		</NotificationListItemContainer>
	);
}

const NotificationListItemContainer = styled.li`
	list-style: none;
	padding: 1rem 1.5rem 1rem 4.5rem;
	border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
	font-size: 1.3rem;
	line-height: normal;
	color: ${(props) => props.theme.color[`grey500`]};
	margin-bottom: 0;

	& .archiveoverridelabelstatus {
		margin: 0 0 0.6rem 0;
		color: ${(props) => props.theme.color[`grey400`]};
	}

	&.acceptreject-inprogress {
		background: ${(props) => props.theme.color[`grey300`]};
		border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
		pointer-events: none;
		opacity: 0.5;
	}

	&:last-child {
		border-bottom: 0;
	}

	& .respond-error {
		font-size: 1.2rem;
		line-height: normal;
		height: 2rem;
		padding-top: 1rem;
		padding-bottom: 2rem;

		& svg {
			fill: ${(props) => props.theme.color[`red600`]};
			vertical-align: text-bottom;
			margin-right: 0.5em;
		}
	}

	.ClientName {
		display: inline-block;
		width: calc(100% - 19.3rem);
		padding-right: 2rem;
		line-height: 1.3rem;
		font-size: 1.2rem;
	}
	.EngagementType {
		display: inline-block;
		width: calc(100% - 19.3rem);
		padding: 0.5rem 2rem 0.5rem 0;
		.LabelType {
			font-weight: bold;
			display: inline-block;
		}
		.LabelValue {
			display: inline-block;
			margin-left: 0.5rem;
		}
	}
	.WarningWrapper {
		padding: 0;
		display: block;
		background-color: ${(props) => props.theme.color[`white`]};
		font-weight: bold;
		.warning-icon {
			background-position-x: 3px;
			background-position-y: 2px;
			border-radius: 1.5rem;
			padding: 1.2rem;
			display: inline-block;
			position: relative;
			top: 0.2rem;
		}
		svg {
			fill: ${(props) => props.theme.color[`grey500`]};
			vertical-align: baseline;
		}
		.ellipses {
			width: 94%;
			margin-left: 0.6rem;
		}
		.MessageTitle {
			font-weight: bold;
		}
	}
	.MessageText {
		margin-top: 0.5rem;
	}
	.Description {
		display: inline-block;
		width: calc(100% - 15.3rem);
		padding-right: 2rem;
		line-height: 1.3rem;
		font-size: 1.2rem;
	}
	.User {
		display: inline-block;
		width: calc(100% - 19.3rem);
		color: ${(props) => props.theme.color[`grey400`]};
		padding: 0.5rem 2rem 0.5rem 0;
	}
	.Status {
		display: inline-block;
		width: calc(100% - 19.3rem);
		color: ${(props) => props.theme.color[`grey400`]};
		padding: 0.5rem 2rem 0.5rem 0;
	}
	.DisplayDate {
		width: calc(100% - 19.3rem);
		color: ${(props) => props.theme.color[`grey400`]};
		padding-right: 2rem;
		font-size: 1.1rem;
		line-height: 1.3rem;
	}
	.ButtonRightGroup {
		display: inline-block;
		white-space: nowrap;
		vertical-align: top;
		padding: 0;
		width: 15rem;
		line-height: 1.3rem;
		font-size: 1.2rem;
		text-align: right;
		button {
			border: 0;
			background: transparent;
			color: ${(props) => props.theme.color[`blackyellow`]};
			&:hover {
				text-decoration: underline;
			}

			&.SecondButton {
				margin-left: 1rem;
				.btnLabel {
					border-left: 0.1rem solid ${(props) => props.theme.color[`grey500`]};
					padding-left: 1rem;
				}
			}
		}
		.profile-link {
			font-size: 1.2rem;
			font-weight: bold;
			color: ${(props) => props.theme.color[`blue500yellow400`]};
			&:hover {
				text-decoration: underline;
				color: ${(props) => props.theme.color[`blue500yellow400`]};
				border-color: ${(props) => props.theme.color[`blue500yellow400`]};
			}
		}
	}
`;
