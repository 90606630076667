/**
 * Modal.js
 * Created by dwarika on 03/21/2022.
 */
import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import theme from '@ey/styled-theme';
import PropTypes from 'prop-types';
import {
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifModalFooter,
	MotifButton,
	MotifIconButton
} from '@ey-xd/motif-react';
import {SVGICONS} from '@ey/icons';

function Modal(props) {
	const {
		show,
		onHide,
		onOkClick,
		onSaveCreateClick,
		title,
		children,
		isOkButtonDisabled,
		isCancelButtonDisabled,
		isSaveCreateButtonDisabled,
		showButtons,
		hideCloseButton,
		hideConfirmButton,
		hideCancelButton,
		confirmBtnTitle,
		closeBtnTitle,
		saveCreateBtnTitle,
		closeTitle,
		modalsize,
		modalbodystyle,
		modalContainerClass,
		modalonmodal,
		disableClose,
		footerInfo,
		bodyminheight,
		role
	} = props;

	return (
		<StyledMotifModal
			show={show}
			onClose={onHide}
			className={modalsize + ' ' + modalContainerClass}
			modalonmodal={modalonmodal}
			role={role}
		>
			<ThemeProvider theme={theme}>
				<StyledModalHeader
					visibility={hideCloseButton}
					closeModalButton={
						<MotifIconButton
							type="button"
							onClick={onHide}
							className="motif-modal--header-icon-button"
							title={closeTitle}
							disabled={disableClose}
						>
							<SVGICONS
								styleName="medium"
								name="close-outline"
								hoverText={closeTitle}
							/>
						</MotifIconButton>
					}
				>
					{title}
				</StyledModalHeader>
			</ThemeProvider>
			<StyledModalBody className={modalbodystyle} bodyminheight={bodyminheight}>
				{children}
			</StyledModalBody>
			{showButtons && (
				<StyledModalFooter>
					{!hideConfirmButton && (
						<MotifButton
							type="button"
							variant="primary-alt"
							onClick={onOkClick}
							disabled={isOkButtonDisabled}
						>
							{confirmBtnTitle}
						</MotifButton>
					)}
					{saveCreateBtnTitle && (
						<MotifButton
							type="button"
							variant="secondary"
							disabled={isSaveCreateButtonDisabled}
							onClick={onSaveCreateClick}
						>
							{saveCreateBtnTitle}
						</MotifButton>
					)}
					{!hideCancelButton && (
						<MotifButton
							type="button"
							variant="secondary"
							disabled={isCancelButtonDisabled}
							onClick={onHide}
						>
							{closeBtnTitle}
						</MotifButton>
					)}
					{footerInfo && (
						<section className="modal-footer-info">{footerInfo}</section>
					)}
				</StyledModalFooter>
			)}
		</StyledMotifModal>
	);
}

export default Modal;

Modal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	modalsize: PropTypes.string,
	confirmBtnTitle: PropTypes.string,
	closeBtnTitle: PropTypes.string,
	saveCreateBtnTitle: PropTypes.string,
	modalbodystyle: PropTypes.string,
	modalContainerClass: PropTypes.string,
	showButtons: PropTypes.bool,
	hideCloseButton: PropTypes.bool,
	hideConfirmButton: PropTypes.bool,
	hideCancelButton: PropTypes.bool,
	isOkButtonDisabled: PropTypes.bool,
	isCancelButtonDisabled: PropTypes.bool,
	isSaveCreateButtonDisabled: PropTypes.bool,
	disabled: PropTypes.bool,
	show: PropTypes.bool,
	bodyminheight: PropTypes.string,
	role: PropTypes.string
};

Modal.defaultProps = {
	bodyminheight: 'unset'
};

const StyledMotifModal = styled(MotifModal)`
	.motif-modal-overlay {
		padding: 1rem;
	}
	&.motif-modal-content {
		width: 100%;
		max-width: 100%;
		height: 100%;
		max-height: 100%;

		&.small {
			width: 50%;
			max-width: 50%;
			height: auto;
			max-height: auto;
		}

		&.x-small {
			width: 40%;
			max-width: 40%;
			height: auto;
			max-height: auto;
		}	

		&.medium {
			&.thin {
				width: 40%;
				max-width: 40%;
			}
			width: 72%;
			max-width: 72%;
			height: calc(100vh - 4rem);
			max-height: auto;
		}

		&.x-medium{
			width: 55%;
			max-width: 55%;
			height: auto;
			max-height: auto;
		}

		&.message {
			width: 30%;
			max-width: 30%;
			height: auto;
			max-height: auto;
		}
	}

	.motif-modal-body {
		padding: 0;
		position: relative;
		font-size: 1.2rem;
	}
`;

const StyledModalHeader = styled(MotifModalHeader)`
	&.motif-modal-header {
		background: ${(props) => props.theme.color[`grey500`]};
		padding: 1.2rem 2rem;
		height: auto;
		border: 0;

		.motif-modal-headline {
			color: ${(props) => props.theme.color[`white`]};
			font-weight: bold;
			font-size: 1.7rem;
			padding: 0;
		}
		.motif-modal--header-icon-button {
			${({visibility}) => visibility && `visibility: hidden;`}
			color: ${(props) => props.theme.color[`white`]};
			box-shadow: none;
			width: 2.4rem;
			height: 2.4rem;
			margin-top: 0.1rem;
			&:hover,
			&:focus,
			&:active {
				background: none;
				svg {
					background-color: transparent;
					box-shadow: none;
					path {
						fill: ${(props) => props.theme.color[`white`]};
					}
				}
			}
			svg {
				width: 2.4rem;
				height: 2.4rem;
			}
		}
	}
`;
const StyledModalBody = styled(MotifModalBody)`
	&.motif-modal-body {
		overflow: hidden;
		height: inherit;
		display: flex;
		flex-direction: column;
		min-height: ${({bodyminheight}) => bodyminheight};
		&.modalMinHeight {
			min-height: 20rem;
		}
	}
`;
const StyledModalFooter = styled(MotifModalFooter)`
	&.motif-modal-footer {
		padding: 1rem 2rem;
		button {
			font-size: 1.4rem;
			padding: 0.7rem;
			min-width: 8.8rem;
		}
	}
	.modal-footer-info {
		font-size: 1.2rem;
		display: flex;
		align-items: center;
		margin-left: 2rem;
	}
`;
