/**
 * @module TimelineTooltipSmall
 */

import React from 'react';

const TimelineTooltipSmall = (props) => {
	return (
		<section className="timelinetooltip-content">
			<span>{props.name}</span>
			<section className="PSO-CRAdate">
				<section className="pull-left">
					<span>{props.date}</span>
				</section>
			</section>
		</section>
	);
};

export default TimelineTooltipSmall;
