import {MotifDropdown, MotifDropdownItem} from '@ey-xd/motif-react';
import React, {useEffect, useState} from 'react';
import {currentResource, labels} from '../../../util/uiconstants';

import EngagementMetric from '../../Timeline/Metrics/engagementMetric';
import {SVGICONS} from '@ey/icons';
import _ from 'lodash';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';

const EngagementMetricsDetails = (props) => {
	const {metrics} = props;

	/* ----------------- State variables ----------------- */

	const [overdueTotals, setOverdueTotals] = useState([]),
		[showWhatsNewDropdown, setShowWhatsNewDropdown] = useState(false),
		[showComingDueDropdown, setShowComingDueDropdown] = useState(false),
		[comingDueTotals, setcomingDueTotals] = useState([]),
		[newTotals, setNewTotals] = useState([]),
		[metricsTotals, setMetricsTotals] = useState([]),
		[comingDueFilterValue, setComingDueFilterValue] = useState(5),
		[newFilterValue, setNewFilterValue] = useState(5),
		[comingDueOptionSelected, setComingDueOptionSelection] = useState(
			currentResource.metricFilterComingDue.find(function (item) {
				return item.isDefault;
			}).value
		),
		[whatsNewOptionSelected, setWhatsNewOptionSelection] = useState(
			currentResource.metricFilterWhatsNew.find(function (item) {
				return item.isDefault;
			}).value
		);

	/* ----------------- /State variables ----------------- */

	let overdueMetrics = [],
		comingDueMetrics = [],
		newMetrics = [];

	if (
		metrics &&
		metrics?.metrics?.engagementMetrics &&
		metrics?.metrics?.engagementMetrics.length > 0
	) {
		overdueMetrics = timeline.metrics.engagementMetrics.filter(
			(item) => item.category === 'Overdue'
		);
		comingDueMetrics = timeline.metrics.engagementMetrics.filter(
			(item) => item.category === 'ComingDue'
		);
		newMetrics = timeline.metrics.engagementMetrics.filter(
			(item) => item.category === 'New'
		);
	}

	// This will read the values sent down from Engagements V2
	if (metrics && metrics?.length > 0) {
		overdueMetrics = metrics?.filter((item) => item.category === 'Overdue');
		comingDueMetrics = metrics?.filter((item) => item.category === 'ComingDue');
		newMetrics = metrics?.filter((item) => item.category === 'New');
	}

	// Returns a boolean value indicating whether the metrics list has any non-zero metric counter
	// If a new metric is shown, add it to this function!!
	const haveMetricsToShow = (metricsList) => {
		let result = false;
		let metricsTotal = 0;

		if (metricsList) {
			for (let metricItem of metricsList) {
				metricsTotal =
					metricsTotal +
					(metricItem.preparer || 0) +
					(metricItem.forMyReview || 0) +
					(metricItem.upcomingReview || 0) +
					(metricItem.reviewNotesAssigned || 0) +
					(metricItem.reviewNotesAuthored || 0) +
					(metricItem.clientRequests || 0) +
					(metricItem.timephases || 0) +
					(metricItem.groupTasks || 0) +
					(metricItem.groupDeliverables || 0) +
					(metricItem.automationDocuments || 0);

				if (metricsTotal >= 0) {
					result = true;
					break;
				}
			}
		}

		return result;
	};

	// Returns a boolean value indicating whether the totals received as a parameter contains all 0 values or not.
	// true --> The metricTotals object has all its counters with a value of 0
	// false --> The metricTotals object has at least 1 non zero counter
	const isTotalEmpty = (metricTotals) => {
		let totalCount = 0;

		if (metricsTotals) {
			totalCount =
				(metricTotals.preparer || 0) +
				(metricTotals.forMyReview || 0) +
				(metricTotals.upcomingReview || 0) +
				(metricTotals.reviewNotesAssigned || 0) +
				(metricTotals.reviewNotesAuthored || 0) +
				(metricTotals.clientRequests || 0) +
				(metricTotals.timephases || 0) +
				(metricTotals.groupTasks || 0) +
				(metricTotals.groupDeliverables || 0) +
				(metricTotals.automationDocuments || 0);

			return totalCount === 0;
		} else {
			// null or undefined --> return true (emtpy object).
			return true;
		}
	};

	// This determines whether the API call returned metric values (any non 0) for each section
	let haveOverdueMetrics = haveMetricsToShow(overdueMetrics),
		haveComingDueMetrics = haveMetricsToShow(comingDueMetrics),
		haveNewMetrics = haveMetricsToShow(newMetrics);

	// Returns the total for each metric in the metricsList collection, applying the filter function if it's sent from the calling component
	// If a new metric is shown, add it to this function!!
	const calculateMetricsTotals = (metricsList, filterFunction, filterValue) => {
		// Apply filter selected from the dropdown on the metrics list
		const haveFilter = filterFunction && typeof filterFunction === 'function';

		let metricsTotal = {};

		for (let metric of metricsList) {
			if (!haveFilter || filterFunction(metric, filterValue)) {
				metricsTotal = {
					preparer: (metricsTotal.preparer || 0) + metric.preparer,
					forMyReview: (metricsTotal.forMyReview || 0) + metric.forMyReview,
					upcomingReview:
						(metricsTotal.upcomingReview || 0) + metric.upcomingReview,
					reviewNotesAssigned:
						(metricsTotal.reviewNotesAssigned || 0) +
						metric.reviewNotesAssigned,
					reviewNotesAuthored:
						(metricsTotal.reviewNotesAuthored || 0) +
						metric.reviewNotesAuthored,
					clientRequests:
						(metricsTotal.clientRequests || 0) + metric.clientRequests,
					timephases: (metricsTotal.timephases || 0) + metric.timephases,
					groupTasks: (metricsTotal.groupTasks || 0) + metric.groupTasks,
					groupDeliverables:
						(metricsTotal.groupDeliverables || 0) + metric.groupDeliverables,
					automationDocuments:
						(metricsTotal.automationDocuments || 0) + metric.automationDocuments
					// groupDocumentReturned: (metricsTotal.groupDocumentReturned || 0) + metric.groupDocumentReturned,
				};
			}
		}

		metricsTotal = metricsTotal || {
			preparer: 0,
			forMyReview: 0,
			upcomingReview: 0,
			reviewNotesAssigned: 0,
			reviewNotesAuthored: 0,
			clientRequests: 0,
			timephases: 0,
			groupTasks: 0,
			groupDeliverables: 0,
			automationDocuments: 0
			// groupDocumentReturned: 0,
		};

		return metricsTotal;
	};

	const mergeMetricsTotals = () => {
		const metricTitles = currentResource.engagementMetricNames.map((metric) => {
			return metric.value;
		});

		const mergedTotals = _.zip(
			metricTitles,
			haveOverdueMetrics ? Object.values(overdueTotals) : 0,
			haveComingDueMetrics ? Object.values(comingDueTotals) : 0,
			haveNewMetrics ? Object.values(newTotals) : 0
		);

		// This is where the columns that are empty are filtered out.
		// The first position of the "columnItem" array is skipped because it's the title of the column, and it should never be empty.
		// That's why the counter variable declared in the for cycle is initialized as 1.
		const filteredTotals = mergedTotals.filter((columnItem) => {
			let notEmpty = false;
			for (let i = 1; i < columnItem.length; ++i) {
				if (typeof columnItem[i] !== 'undefined' && columnItem[i] > 0) {
					notEmpty = true;
				}
			}
			return notEmpty;
		});

		setMetricsTotals(filteredTotals);
	};

	const comingDueFilter = (element, days) => {
		let result;

		result = element.age <= days;

		return result;
	};

	const newFilter = (element, days) => {
		let result;

		result = element.age <= days;

		return result;
	};

	// Executed when the user selects a filter from the "Coming due" drop-down
	const onComingDueFilterChange = (option) => {
		setShowComingDueDropdown(false);
		setComingDueOptionSelection(option.value);
		option && setComingDueFilterValue(option.value);
	};

	// Executed when the user selects a filter from the "What's New" drop-down
	const onWhatsNewFilterChange = (value) => {
		setShowWhatsNewDropdown(false);
		setWhatsNewOptionSelection(value.value);
		value && setNewFilterValue(value.value);
	};

	useEffect(() => {
		setOverdueTotals(calculateMetricsTotals(overdueMetrics));

		setcomingDueTotals(
			calculateMetricsTotals(
				comingDueMetrics,
				comingDueFilter,
				comingDueFilterValue
			)
		);

		setNewTotals(calculateMetricsTotals(newMetrics, newFilter, newFilterValue));
	}, []);

	useEffect(() => {
		mergeMetricsTotals();
	}, [overdueTotals, comingDueTotals, newTotals]);

	// Coming due filter
	useEffect(() => {
		let filteredTotals = calculateMetricsTotals(
			comingDueMetrics,
			comingDueFilter,
			comingDueFilterValue
		);
		setcomingDueTotals(filteredTotals);
	}, [comingDueFilterValue, showComingDueDropdown]);

	// What's new filter
	useEffect(() => {
		let filteredTotals = calculateMetricsTotals(
			newMetrics,
			newFilter,
			newFilterValue
		);
		setNewTotals(filteredTotals);
	}, [newFilterValue]);

	const handleComingDueDropdownToggle = (value) => {
		let toggle = showComingDueDropdown ? false : true;
		setShowComingDueDropdown(toggle);
	};
	const setClickOutsideComingDue = () => {
		setShowComingDueDropdown(false);
	};
	const setClickOutsideWhatsNew = () => {
		setShowWhatsNewDropdown(false);
	};
	const handleWhatsNewToggle = () => {
		let toggle = showWhatsNewDropdown ? false : true;
		setShowWhatsNewDropdown(toggle);
	};
	// The API call returned no metrics --> Hide the whole section
	if (!haveOverdueMetrics && !haveComingDueMetrics && !haveNewMetrics) {
		return null;
	} else {
		return (
			<StyledMetricsDetails>
				<section className="metrics-filters">
					{
						<span className={`filter-title`}>
							<span className="emitemHeader">{labels.overdue}</span>
						</span>
					}
					{
						<span className={`filter-title`}>
							<span className="emitemHeader" title={labels.comingDue}>
								{labels.comingDue}
							</span>
							<MotifDropdown
								open={showComingDueDropdown}
								placement="bottom-right"
								className="engagement-metrics-dropdown"
								handleClickOutside={() => setClickOutsideComingDue()}
								trigger={
									<span
										role="button"
										onClick={() => handleComingDueDropdownToggle()}
										title={labels.comingDue}
									>
										<SVGICONS name="more-dots" styleName="medium" />
									</span>
								}
							>
								{currentResource.metricFilterComingDue.map((option, index) => {
									return (
										<MotifDropdownItem
											onClick={() => onComingDueFilterChange(option)}
											title={option.label}
											key={index}
											className={
												option.value === comingDueOptionSelected
													? 'selected'
													: ''
											}
										>
											{option.label}
										</MotifDropdownItem>
									);
								})}
							</MotifDropdown>
						</span>
					}
					{
						<span className={`filter-title`}>
							<span className="emitemHeader" title={labels.whatsNew}>
								{labels.whatsNew}
							</span>
							<MotifDropdown
								open={showWhatsNewDropdown}
								placement="bottom-right"
								className="engagement-metrics-dropdown"
								handleClickOutside={() => setClickOutsideWhatsNew()}
								trigger={
									<span
										role="button"
										onClick={() => handleWhatsNewToggle()}
										title={labels.whatsNew}
									>
										<SVGICONS name="more-dots" styleName="medium" />
									</span>
								}
							>
								{currentResource.metricFilterWhatsNew.map((option, index) => {
									return (
										<MotifDropdownItem
											onClick={() => onWhatsNewFilterChange(option)}
											title={option.label}
											key={index}
											className={
												option.value === whatsNewOptionSelected
													? 'selected'
													: ''
											}
										>
											{option.label}
										</MotifDropdownItem>
									);
								})}
							</MotifDropdown>
						</span>
					}
				</section>
				{/* Metrics counters */}
				<section className="metrics-counters-wrapper">
					<section className="customScrollbar metrics-item">
						{metricsTotals &&
							metricsTotals.map((metric) => {
								return (
									<EngagementMetric
										key={uuidv4()}
										metricName={metric[0]}
										metricValues={metric.slice(1)}
										haveComingDueMetrics={haveComingDueMetrics}
										haveNewMetrics={haveNewMetrics}
										haveOverdueMetrics={haveOverdueMetrics}
									/>
								);
							})}
					</section>
				</section>
			</StyledMetricsDetails>
		);
	}
};

export default EngagementMetricsDetails;

const StyledMetricsDetails = styled.section`
	font-size: 1.3rem;
	padding: 1rem 2rem;
	.metrics-filters {
		display: flex;
		width: 100%;
		font-weight: normal;
		padding-left: 40%;
		line-height: 2.4rem;
		.filter-title {
			width: 33.3%;
			display: flex;
			position: relative;
			.emitemHeader {
				max-width: calc(100% - 48px);
				width: auto;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.engagement-metrics-dropdown {
				display: flex;
				align-items: center;
				.motif-dropdown-trigger {
					span {
						display: flex;
					}
				}
				&.motif-dropdown {
					.motif-dropdown-trigger {
						&:hover,
						&:active {
							border-color: ${(props) => props.theme.color[`white`]};
						}
					}
					.motif-dropdown-menu[x-placement^='bottom-right'] {
						right: -2rem;
						top: 1.5rem;
						max-width: 20rem;
						&:before {
							top: -0.6rem;
							right: 2ren;
						}
					}
					.motif-dropdown-item {
						font-weight: 300;
						font-size: 1.2rem;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						display: block;
						padding: 1rem;
						&.selected {
							background: ${(props) => props.theme.color[`grey100`]};
						}
						&:focus {
							width: 100%;
							margin-left: 0;
							border-color: transparent;
						}
					}
					.motif-dropdown-item:first-child {
						&:focus {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
	.metrics-counters-wrapper {
		padding: 0;
		display: flex;
		flex-direction: column;
		.metrics-item {
			height: 20rem;
			overflow-y: auto;
		}
	}
`;
