/**
 * Scot.js
 * Created by zl952fc on 02/24/2020.
 */

import React from 'react';
import {SVGICONS} from '@ey/icons';
import styled from 'styled-components';
import {labels} from '../../../util/uiconstants';
import NotificationList from './NotificationList';

export default function NotificationGroups(props) {
	const {
		notifications,
		onFirstButtonClick,
		onSecondButtonClick,
		setCloseNotification
	} = props;

	const closeNotification = () => {
		props.closeNotification();
	};

	return (
		<NotificationsGroup className="NotificationsGroupContainer">
			<section className="NotificationGroupHeader">
				<span className="NotificationGroupTitle">{labels.notifcenter}</span>
				<button
					title={labels.closeNotificationCenter}
					className="NotificationCloseBtn"
					onClick={closeNotification}
				>
					<SVGICONS
						hoverText="Close notification center"
						styleName="medium"
						name="close-outline"
						alt="Close notification center"
					/>
				</button>
			</section>
			{notifications && notifications.length > 0 ? (
				notifications.map((notification) => {
					return (
						<NotificationList
							key={notification.actionType}
							close={notifications.close}
							notification={notification}
							setCloseNotification={setCloseNotification}
							onFirstButtonClick={onFirstButtonClick}
							onSecondButtonClick={onSecondButtonClick}
							acceptedEngagementList={props.acceptedEngagementList}
							acceptedArchiveEngagementList={
								props.acceptedArchiveEngagementList
							}
							isInviteAcceptedOrRejected={props.isInviteAcceptedOrRejected}
							isArchiveInviteAcceptedOrRejected={
								props.isArchiveInviteAcceptedOrRejected
							}
							setisInviteAcceptedOrRejectedToDone={
								props.setisInviteAcceptedOrRejectedToDone
							}
							setisArchiveInviteAcceptedOrRejectedToDone={
								props.setisArchiveInviteAcceptedOrRejectedToDone
							}
						/>
					);
				})
			) : (
				<>
					{(!notifications ||
						(notifications && notifications.length === 0)) && (
						<section className="emptyContainer">
							{labels.noNotifications}
						</section>
					)}
				</>
			)}
		</NotificationsGroup>
	);
}

const NotificationsGroup = styled.section`
	line-height: 3.3rem;

	.NotificationGroupHeader {
		display: flex;
		padding: 1rem 1.5rem;
		.NotificationGroupTitle {
			font-size: 1.6rem;
			font-weight: bold;
			flex-grow: 1;
			color: ${(props) => props.theme.color[`grey500`]};
		}
		.NotificationCloseBtn {
			background: transparent;
			border: none;
			svg {
				fill: ${(props) => props.theme.color[`grey500`]} !important;
				margin-top: 0.5rem;
			}
		}
	}
	.emptyContainer {
		border-top: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
		padding: 1rem 1.5rem;
		text-align: center;
		color: ${(props) => props.theme.color[`grey400`]};
		font-size: 1.4rem;
	}
`;
