import React, {useEffect, useState} from 'react';
import {labels} from '../../../../util/uiconstants';
import {useSelector} from 'react-redux';
import AccessDeniedMessage from '../../../Common/AccessDeniedMessage/AccessDeniedMessage';

function SelectedEngagementError() {
	const store = useSelector((store) => store.errors);
	const [status, setStatus] = useState(null);
	const [message, setMessage] = useState(null);

	useEffect(() => {
		setStatus(store.response?.status);
	}, []);

	useEffect(() => {
		if (status == 500) {
			setMessage(labels.serverErrorMessage);
		} else if (status == 401 || status == 403) {
			setMessage(labels.engagementDetailsNoPermission);
		} else if (status == 400) {
			setMessage(labels.engagementNotFound);
		} else if (status !== 200) {
			setMessage(labels.pageNotFound);
		}
	}, [status]);

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh'
				}}
			>
				{message === labels?.engagementDetailsNoPermission ? (
					<AccessDeniedMessage
						permissionMesage={labels?.engagementDetailsNoPermission}
					/>
				) : (
					<h1> {message}</h1>
				)}
			</div>
		</>
	);
}
export default SelectedEngagementError;
