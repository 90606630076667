import React from 'react';
import { MotifMenuItem } from '@ey-xd/motif-react';
import { SVGICONS } from '@ey/icons';
import { MotifIcon } from '@ey-xd/motif-react';
import {
	communicationIcLiveHelp24px,
	actionIcSettings24px,
	editorIcMonetizationOn24px
} from '@ey-xd/motif-react/assets/icons';
import { labels } from '../../util/uiconstants';
import env from '../../util/env';
import { useHistory } from 'react-router-dom';
import Utility from '../../util/utils';



const MoreMenuOptions = (props) => {
	const { featureConfiguration } = props;
	const { userSettings } = props;
	const history = useHistory();
	const onUserSettingsClick = () => {
		window.dispatchEvent(new CustomEvent('closeMenu', {}));
		history.push("/usersettings");
	}

	let paansConfiguration = env.getPaansConfiguration();
	if (paansConfiguration) {
		const userLanguageCode = Utility.GetUserLanguageCode(userSettings.userlanguageid);
		paansConfiguration.paansUrl = paansConfiguration.paansUrl + `&l=${userLanguageCode}`;
	}

	return (
		<>
			{featureConfiguration?.iseycanvaseconomicsenabled && (
				<MotifMenuItem>
					<a
						href={featureConfiguration?.eycanvaseconomicsurl || ''}
						className="economics"
					>
						<MotifIcon src={editorIcMonetizationOn24px} />
						<span>{labels.eyCanvasEconomicsTooltip}</span>
					</a>
				</MotifMenuItem>
			)}
			{featureConfiguration?.iseyoversightenabled && (
				<MotifMenuItem>
					<a
						href={featureConfiguration?.eycanvasoversighturl || ''}
						className="oversight"
					>
						<SVGICONS
							styleName="large"
							name="oversight"
							alt=""
							hoverText={labels.eyOversightTooltip}
						/>
						<span>{labels.eyOversightTooltip}</span>
					</a>
				</MotifMenuItem>
			)}
			<MotifMenuItem>
				<a href={env.getHelpWebUrl()} className="general-help" target="_blank">
					<MotifIcon src={communicationIcLiveHelp24px} />
					<span>{labels.eyGeneralHelp}</span>
				</a>
			</MotifMenuItem>
			<MotifMenuItem>
				<a
					onClick={onUserSettingsClick}
					className="user-settings"
				>
					<MotifIcon src={actionIcSettings24px} />
					<span>{labels.usersettings}</span>
				</a>
			</MotifMenuItem>
			<section className="privacy-wrap">
				{paansConfiguration?.paansEnabled && (
					<>
						{paansConfiguration.privacyNoticePolicyTypeId && (
							<>
								<section className="privacy-noticeitem">
									<a
										href={paansConfiguration.paansUrl + `&pt=${paansConfiguration?.privacyNoticePolicyTypeId}`}
										className="privacy-notice"
										target="_blank"
									>
										<span>{labels.eyPrivacyNoticeTooltip}</span>
									</a>
								</section>
							</>
						)}
						{paansConfiguration?.termsOfServicePolicyTypeId && (
							<>
								{(paansConfiguration?.termsOfServicePolicyTypeId &&
									paansConfiguration?.privacyNoticePolicyTypeId) && (
										<>
											<section className="privacy-separator">|</section>
										</>
									)}
								<section className="terms-serviceitem">
									<a
										href={paansConfiguration.paansUrl + `&pt=${paansConfiguration?.termsOfServicePolicyTypeId}`}
										className="terms-service"
										target="_blank"
									>
										<span>{labels.eyTermsOfServiceTooltip}</span>
									</a>
								</section>
							</>
						)}
					</>
				)}
			</section >
		</>
	);
};

export default MoreMenuOptions;
