/**
 * Created by calhosh on 7/13/2017.
 */
import React from 'react';
import { Ellipse } from '@ey/canvascoreservices';
import styled from 'styled-components';
import {MotifCheckbox} from '@ey-xd/motif-react';

export default function CheckBox(props) {
	const {
		label,
		name,
		title,
		value,
		checked,
		disabled,
		dataAutoID,
		ellipse,
		inline,
		noOfLines,
		styleName,
		className,
		customElement,
		statusLabel,
		hidetitletip,
		id,
		onChange,
		indeterminate,
		ariaLabel
	} = props;
	return (
		<Wrapper className={styleName ? styleName : ''}>
			<MotifCheckbox
				id={id}
				name={name}
				inline={inline}
				value={value}
				title={title}
				onChange={onChange}
				checked={checked}
				disabled={disabled}
				className={className}
				data-autoid={dataAutoID}
				indeterminate={indeterminate}
				aria-label={ariaLabel}
			>
				{customElement && customElement}
				{ellipse ? (
					<Ellipse
						content={label}
						id={name}
						tooltip={label}
						displayId={props.displayId}
						noOfLines={noOfLines}
					/>
				) : (
					<span
						htmlFor={id}
						title={hidetitletip ? '' : label}
						className="checkboxLabel"
					>
						{label}
					</span>
				)}
				{statusLabel && <span className="statusLabel">{statusLabel}</span>}
			</MotifCheckbox>
		</Wrapper>
	);
}

const Wrapper = styled.span`
	display: inline-block;
	position: relative;
	vertical-align: top;
	font-size: 1.4rem;
	.motif-checkbox {
		.motif-checkbox-input {
			width: 1.6rem;
			height: 1.6rem;
			z-index: 999;
			left: 0;
			position: absolute;
			margin: 0;
			border: none;
			cursor: pointer;

			&:focus{
				outline: none;
			}
		}
		.motif-checkbox-custom {
			width: 1.6rem;
			height: 1.6rem;
			border-width: 0.1rem;
			&:hover {
				border-width: 0.1rem;
				outline: none;
			}
		}
		.motif-checkbox-label {
			font-size: 1.3rem;
			font-weight: normal;
			width: 75%;
		}
		&.motif-checkbox-disabled {
			cursor: default;
			.motif-checkbox-custom {
				border: 0.1rem solid ${(props) => props.theme.color[`grey300`]}!important;
			}
		}
	}
	.motif-checkbox-label-wrap {
		cursor: pointer;
		margin-bottom: 0;
		&.motif-checkbox-checked {
			&.partialChecked {
				.motif-checkbox-custom {
					.motif-checkbox-checked-icon {
						background: ${(props) => props.theme.color[`grey500`]};
						padding: 0.1rem;
						fill: ${(props) => props.theme.color[`grey500`]};
						margin: 0.1rem;
						border: 0.2rem solid ${(props) => props.theme.color[`white`]};
						display: none;
					}
					.motif-checkbox-indeterminate-icon {
						display: block;
					}
				}
			}
		}
	}
`;
