import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

export default function SearchEngagementReducer(
	state = initialState.searchEngagement,
	action
) {
	switch (action.type) {
		case types.SEARCH_ENGAGEMENT:
			return Object.assign({}, state, {
				searchText: action.payload
			});
		default:
			return state;
	}
}
