import React, {useState} from 'react';
import SelectDropdown from '../../../Common/SelectDropdown/SelectDropdown';
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
	MotifFormField,
	MotifToggleSwitch,
	MotifButton,
	MotifDatePicker,
	MotifLabel,
	MotifTooltip,
	MotifTypeahead,
	MotifErrorMessage
} from '@ey-xd/motif-react';
import Loader from '@ey/loader';
import env from '../../../../util/env';
import {getLocalizedAPIError} from '../../common/util';
import {labels} from '../../../../util/uiconstants';
import { Ellipse } from '@ey/canvascoreservices';
import {
	dateAdminFormats,
	guidCheckerRegex
} from '../../../../util/uiconstants';
import ReactMoment from 'react-moment';
import {
	getEngagementDetail,
	saveEngagement,
	triggerArchiveRetry
} from '../../../../actions/admin/engagement/engagementActions';
import {setEngagementUpdateSuccessMessage} from '../../../../actions/admin/successmessageactions';
import {EngagementStatus} from '../../../../util/enumerations';
import TicketModal from '../../common/ticketmodal';
import Toast from '../../../Common/Toast/Toast';
import { MotifIcon } from '@ey-xd/motif-react';
import { contentIcArchive24px } from '@ey-xd/motif-react/assets/icons';

function SelectedEngagementTableRow(props) {
	const [profileComplete, setProfileComplete] = useState(false);
	const [disable, setDisable] = useState(!props?.editPermission);
	const [currentEngagementStatus, setCurrentEngagementStatus] = useState();
	const [engagementDeleteDate, setEngagementDeleteDate] = useState(null);
	const [archivalGuid, setArchivalGuid] = useState('');
	const [archiveLogId, setArchiveLogId] = useState('');
	const [archiveFileGuid, setArchiveFileGuid] = useState('');
	const [
		independenceTemplateAvailable,
		setIndependenceTemplateAvailable
	] = useState(false);
	const [isAnyFieldUpdated, setIsAnyFieldUpdated] = useState(false);
	const [datePickerCounter, setDatePickerCounter] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [engagementStatusOptions, setEngagementStatusOptions] = useState([]);
	const [showArchGuidErr, setShowArchGuidErr] = useState(false);
	const [showArchFileGuidErr, setShowArchFileGuidErr] = useState(false);
	const [showArchLogIdErr, setShowArchLogIdErr] = useState(false);
	const [showEngagementTicketModal, setShowEngagementTicketModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [isArchiveRetry, setIsArchiveRetry] = useState(false);
	const [archiveRetryClassName, setArchiveRetryClassName] = useState("");

	const userPreferences = useSelector((state) => state.userPreferences);
	const dispatch = useDispatch();

	useEffect(() => {
		setEngagementStatusvalue();
	}, [props?.engagementStatusesDDOptions]);

	useEffect(() => {
		if (props && props?.engagementRowData) {
			var row = props?.engagementRowData;
			var engDeleteDate = (row?.engagementDeleteDate != null) ? new Date(row?.engagementDeleteDate) : null;
			setArchivalGuid(row?.archivalGUID ? row?.archivalGUID : '');
			setArchiveLogId(row?.archiveLogID ? row?.archiveLogID?.toString() : '');
			setArchiveFileGuid(row?.archiveFileGUID ? row?.archiveFileGUID : '');
			setEngagementDeleteDate(engDeleteDate);
			setDatePickerCounter(datePickerCounter + 1);
			setProfileComplete(row?.isProfileComplete);
			setIndependenceTemplateAvailable(row?.isIndependenceTemplateAvailable);
			setEngagementStatusvalue();
			var disableArchiveRetry = row?.engagementStatus === EngagementStatus.ArchiveInProgress || !props.retryArchivePermission ? "disabled" : "";
			setArchiveRetryClassName(disableArchiveRetry);
		}
	}, [props?.engagementRowData]);

	const setEngagementStatusvalue = () => {
		let statuses = props?.engagementStatusesDDOptions;
		let engagementStatusId = props?.engagementRowData?.engagementStatus;
		if (statuses && engagementStatusId) {
			let filteredStatus = statuses.filter(
				(ee) => ee.value == engagementStatusId
			)[0];
			if (filteredStatus) setCurrentEngagementStatus(filteredStatus);
   
			let engStatusArray = [
				EngagementStatus.Active, 
				EngagementStatus.Deleted,
				EngagementStatus.Restored,
				EngagementStatus.MarkedForDeletion,
				EngagementStatus.ArchiveError,
				EngagementStatus.CanvasExternalAccessPortalV2
			];
			let engStatus = props?.engagementStatusesDDOptions?.filter(
				(status) => engStatusArray.includes(status.value)
			);
			setEngagementStatusOptions(engStatus);
		}
	};

	const formatOptionLabel = ({ value, label }) => (
		<Ellipse content={label} tooltip={label} noOfLines={1} />
	);

	const handleProfileToggle = () => {
		setProfileComplete(!profileComplete);
		setIsAnyFieldUpdated(true);
	};

	const handleIndependenceToggle = () => {
		setIndependenceTemplateAvailable(!independenceTemplateAvailable);
		setIsAnyFieldUpdated(true);
	};

	const onSelectStatus = (c) => {
		setCurrentEngagementStatus(c);
		setIsAnyFieldUpdated(true);
	};

	const countryid = new URLSearchParams(location.search).get('countryid');
	const ssl = new URLSearchParams(location.search).get('ssl');
	const engagementUrl =
		env.getURL('serviceUrlV2') +
		`admin/subservicelines/${ssl}/countries/${countryid}/Engagements/${props.engagementRowData?.engagementId}`;

	const validateEngagementData = () => {
		if (currentEngagementStatus.value <= 0) {
			return false;
		}
		return true;
	};

	const saveEngagementData = (enteredTicketNumber) => {
		if (!(enteredTicketNumber?.length > 0)) {
			setErrorMessage(true);
		} else {
			if (validateEngagementData()) {
				var engagementDetails = {
					engagementStatusId: currentEngagementStatus.value,
					engagementDeleteDate: engagementDeleteDate,
					isProfileComplete: profileComplete,
					archivalGuid: archivalGuid.length > 0 ? archivalGuid : null,
					archivalLogId: archiveLogId.length > 0 ? archiveLogId : null,
					archivalFileGuid: archiveFileGuid.length > 0 ? archiveFileGuid : null,
					isIndependenceTemplateAvailable: independenceTemplateAvailable,
					ticketNumber: enteredTicketNumber
				};

				if(engagementDeleteDate !== null){
					let date = engagementDeleteDate;
					let dateInUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
					engagementDetails.engagementDeleteDate = dateInUTC;
				}

				setIsLoading(true);
				dispatch(saveEngagement(engagementUrl, engagementDetails))
					.then(() => {
						dispatch(
							setEngagementUpdateSuccessMessage(labels.engagementUpdateSuccess)
						).then(() => {
							dispatch(getEngagementDetail(engagementUrl))
								.then(() => setIsLoading(false))
								.catch(() => setIsLoading(false));
						});
						setIsAnyFieldUpdated(false);
					})
					.catch((error) => {
						var localError = getLocalizedAPIError(error);
						props.setErrorMessage(localError);
						setIsLoading(false);
					});
			}
			setErrorMessage(false);
		}
	};

	const ArchiveRetryAPICall = (enteredTicketNumber) => {
		if (!(enteredTicketNumber?.length > 0)) {
			setErrorMessage(true);
		} else {
			setIsLoading(true);
			const retryArchiveUrl = env.getURL('serviceUrlV2') +
				`admin/subservicelines/${ssl}/countries/${countryid}/Engagements/${props.engagementRowData?.engagementId}
				/archives?ticketNumber=${enteredTicketNumber}`;

			dispatch(triggerArchiveRetry(retryArchiveUrl))
				.then(() => {
					dispatch(
						setEngagementUpdateSuccessMessage(labels.archiveRetrySuccessMessage)
					).then(() => {
						dispatch(getEngagementDetail(engagementUrl))
							.then(() => setIsLoading(false))
							.catch(() => setIsLoading(false));
					});
				})
				.catch((error) => {
					var localError = getLocalizedAPIError(error);
					props.setErrorMessage(localError);
					setIsLoading(false);
				});

			setErrorMessage(false);
			setIsArchiveRetry(false);
		}
	}

	const unodEdit = () => {
		setIsLoading(true);
		dispatch(getEngagementDetail(engagementUrl))
			.then(() => {
				setIsLoading(false);
				setEngagementStatusvalue();
			})
			.finally(() => setIsLoading(false));
		setIsAnyFieldUpdated(false);
		setShowArchGuidErr(false);
		setShowArchFileGuidErr(false);
		setShowArchLogIdErr(false);
	};

	const onChangeArchivalGuid = (guid) => {
		setArchivalGuid(guid);
		if(guid.length > 0){
			setShowArchGuidErr(!guidCheckerRegex.test(guid));
			return;
		}
		setShowArchGuidErr(false);
	}

	const onChangeArchivalLogId = (logId) => {
		let numberCheckRegex = new RegExp('^[1-9][0-9]{0,9}$');
		setArchiveLogId(logId);
		if(logId.length > 0){
			if(!numberCheckRegex.test(logId) || parseInt(logId) > Math.pow(2,31)-1){
				setShowArchLogIdErr(true);
				return;
			}
		}
		setShowArchLogIdErr(false);
	}

	const onChangeArchivalFileGuid = (guid) => {
		setArchiveFileGuid(guid);
		if(guid.length > 0){
			setShowArchFileGuidErr(!guidCheckerRegex.test(guid));
			return;
		}
		setShowArchFileGuidErr(false);
	}

	const onConfirmClick = (enteredTicketNumber) => {
		if (isArchiveRetry){
			ArchiveRetryAPICall(enteredTicketNumber);
		}
		else{
			saveEngagementData(enteredTicketNumber);
		}
	};

	return (
		<>
			{isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle engagementrow" />
			) : (
				<section>
					<section
						className="toastMessage"
						style={{
							display: errorMessage ? 'block' : 'none'
						}}
					>
						<Toast variant="error" onClose={() => setErrorMessage(false)}>
							{labels.validTicketNumber}
						</Toast>
					</section>
					<section className={`manageaccessitemview`}>
						<section className="userlistitem grid yearend">
							{/* no elipse */}
							<ReactMoment
								format={
									userPreferences &&
									dateAdminFormats[userPreferences.dateFormat] &&
									dateAdminFormats[userPreferences.dateFormat].label
								}
							>
								{props.engagementRowData?.clientYearEnd}
							</ReactMoment>
						</section>

						<section className="userlistitem grid">
							<section>
								<MotifTooltip
									flip
									id={'editbButtonTooltipId'}
									placement={'top'}
									hide={props?.editPermission}
									trigger={
										<section>
											<MotifFormField>
												<MotifLabel>{labels.datePickerLabel}</MotifLabel>
												<MotifDatePicker
													key={datePickerCounter}
													value={engagementDeleteDate}
													onChange={(date) => {
														setEngagementDeleteDate(date);
														setIsAnyFieldUpdated(true);
													}}
													yearPlaceholder="YYYY"
													dayPlaceholder="DD"
													monthPlaceholder="MM"
													disabled={props?.engagementRowData?.engagementStatus === EngagementStatus.Archived ||disable}
												/>
											</MotifFormField>
										</section>
									}
								>
									<section>{labels.engagementUpdatePermissionToolTip}</section>
								</MotifTooltip>
							</section>
						</section>

						<section className="userlistitem grid">
							<MotifTooltip
								flip
								id={'editbButtonTooltipId'}
								placement={'top'}
								hide={props?.editPermission}
								trigger={
									<section>
										<MotifFormField>
											<MotifTypeahead
												value={archivalGuid}
												disabled={props?.engagementRowData?.engagementStatus === EngagementStatus.Archived ||disable}
												onChange={(event) => {
													onChangeArchivalGuid(event.target.value);
													setIsAnyFieldUpdated(true);
												}}
												onClear={() => {
													setArchivalGuid('');
													setIsAnyFieldUpdated(true);
												}}
											/>
											{ showArchGuidErr && (<MotifErrorMessage>
												{labels.invalidArchivalGuidMessage}
												</MotifErrorMessage>)
											}
										</MotifFormField>
									</section>
								}
							>
								<section>{labels.engagementUpdatePermissionToolTip}</section>
							</MotifTooltip>
						</section>

						<section className="userlistitem grid">
							<MotifTooltip
								flip
								id={'archiveLogIdTooltipId'}
								placement={'top'}
								hide={props?.editPermission}
								trigger={
									<section>
										<MotifFormField>
										<MotifTypeahead
												value={archiveLogId.toString()}
												disabled={props?.engagementRowData?.engagementStatus === EngagementStatus.Archived ||disable}
												onChange={(event) => {
													onChangeArchivalLogId(event.target.value);
													setIsAnyFieldUpdated(true);
												}}
												onClear={() => {
													setArchiveLogId('');
													setIsAnyFieldUpdated(true);
												}}
											/>
											{ showArchLogIdErr && (<MotifErrorMessage>
												{labels.invalidArchivalLogId}
												</MotifErrorMessage>)
											}
										</MotifFormField>
									</section>
								}
							>
								<section>{labels.engagementUpdatePermissionToolTip}</section>
							</MotifTooltip>
						</section>

						<section className="userlistitem grid">
							<MotifTooltip
								flip
								id={'archiveFileGuidTooltipId'}
								placement={'top'}
								hide={props?.editPermission}
								trigger={
									<section>
										<MotifFormField>
											<MotifTypeahead
												value={archiveFileGuid}
												disabled={props?.engagementRowData?.engagementStatus === EngagementStatus.Archived ||disable}
												onChange={(event) => {
													onChangeArchivalFileGuid(event.target.value);
													setIsAnyFieldUpdated(true);
												}}
												onClear={() => {
													setArchiveFileGuid('');
													setIsAnyFieldUpdated(true);
												}}
											/>
											{ showArchFileGuidErr && (<MotifErrorMessage>
												{labels.invalidArchivalFileGuidMessage}
												</MotifErrorMessage>)
											}
										</MotifFormField>
									</section>
								}
							>
								<section>{labels.engagementUpdatePermissionToolTip}</section>
							</MotifTooltip>
						</section>

						<section className="userlistitem grid status">
							<MotifTooltip
								flip
								id={'editbButtonTooltipId'}
								placement={'top'}
								hide={props?.editPermission}
								trigger={
									<section>
										<SelectDropdown
											className="e-country-dd"
											defaultValue={currentEngagementStatus}
											placeholder={'currentEngagementStatus'}
											options={engagementStatusOptions}
											onChange={onSelectStatus}
											formatOptionLabel={formatOptionLabel}
											value={currentEngagementStatus}
											isDisabled={props?.engagementRowData?.engagementStatus === EngagementStatus.Archived || disable}
										></SelectDropdown>
									</section>
								}
							>
								<section>{labels.engagementUpdatePermissionToolTip}</section>
							</MotifTooltip>
						</section>

						<section className="userlistitem grid isprofilecomplete">
							<MotifTooltip
								flip
								id={'editbButtonTooltipId'}
								placement={'top'}
								hide={props?.editPermission}
								trigger={
									<section>
										<MotifFormField>
											<MotifToggleSwitch
												checked={profileComplete}
												onChange={handleProfileToggle}
												disabled={props?.engagementRowData?.engagementStatus === EngagementStatus.Archived ||disable}
												hideLabels={true}
												id="exampleToggle1"
											/>
										</MotifFormField>
									</section>
								}
							>
								<section>{labels.engagementUpdatePermissionToolTip}</section>
							</MotifTooltip>
						</section>
						<section className="userlistitem grid independence">
							<MotifTooltip
								flip
								id={'editbButtonTooltipId'}
								placement={'top'}
								hide={props?.editPermission}
								trigger={
									<section>
										<MotifFormField>
											<MotifToggleSwitch
												checked={independenceTemplateAvailable}
												onChange={handleIndependenceToggle}
												disabled={props?.engagementRowData?.engagementStatus === EngagementStatus.Archived ||disable}
												hideLabels={true}
												id="exampleToggle2"
											/>
										</MotifFormField>
									</section>
								}
							>
								<section>{labels.engagementUpdatePermissionToolTip}</section>
							</MotifTooltip>
						</section>
						
						<section className="userlistitem grid archiveretry">
							{props?.engagementRowData?.engagementStatus === EngagementStatus.ArchiveError ||
							props?.engagementRowData?.engagementStatus === EngagementStatus.ArchiveInProgress ?
							<MotifTooltip
								flip
								contentClassName="archiveretry"
								id={'editbButtonTooltipId'}
								placement={'top'}
								hide={props?.retryArchivePermission}
								trigger={
									<section className={archiveRetryClassName}>
										<MotifIcon
											src={contentIcArchive24px}
											title={labels.archiveRetry}
											onClick={() => {
												setIsArchiveRetry(true);
												setShowEngagementTicketModal(true);
											}}
										/>
									</section>
								}>
								<section>{labels.engagementRetryArchivePermissionToolTip}</section>
							</MotifTooltip> : ''}
						</section>
					</section>
				</section>
			)}

                            <section className="showTicketModal">
									{showEngagementTicketModal ? (
										<TicketModal
										onHide={() => {
											setShowEngagementTicketModal(false);
											setIsArchiveRetry(false);
										}}
										onConfirmClick={onConfirmClick}
										modalHeaderLabel={
											isArchiveRetry ?
											labels.archiveRetry : labels.updateEngagement}
									></TicketModal>
									) : ('')}
							</section>

			<section className="savereset">
				<section>
					<MotifButton
						aria-label="Add user"
						role="button"
						type="button"
						variant="primary-alt"
						disabled={disable || showArchGuidErr || showArchFileGuidErr || showArchLogIdErr || !isAnyFieldUpdated}
						onClick={() => {
							setShowEngagementTicketModal(true);
						}}
					>
						{labels.saveEngagement}
					</MotifButton>
				</section>
				<section>
					<MotifButton
						aria-label="Add user"
						role="button"
						type="button"
						variant="secondary"
						disabled={disable || !isAnyFieldUpdated}
						onClick={() => {
							unodEdit();
						}}
					>
						{labels.undoEditEngagement}
					</MotifButton>
				</section>
			</section>
		</>
	);
}

export default SelectedEngagementTableRow;
