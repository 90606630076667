import initialState from '../../initialstate';
import * as types from '../../../actions/actiontypes';
import {HttpStatusCodes} from '../../../util/uiconstants';
import env from '../../../util/env';

export function engagementsReducer(state = initialState.engagements, action) {
	switch (action.type) {
		case types.GET_ALL_ENGAGEMENTS: {
			return action.response;
		}

		case types.RESET_ENGAGEMENT_DATA: {
			return initialState.engagements;
		}
		default:
			return state;
	}
}

export function engagementDetailsReducer(
	state = initialState.engagementDetails,
	action
) {
	switch (action.type) {
		case types.GET_ENGAGEMENT_BY_ID:
			return action.response;
		default:
			return state;
	}
}
export function engagementUsersReducer(
	state = initialState.engagementUsers,
	action
) {
	switch (action.type) {
		case types.GET_ALL_ENGAGEMENT_USERS:
			return action.response;
		default:
			return state;
	}
}
export function engagementStatusReducer(state = initialState.status, action) {
	switch (action.type) {
		case types.GET_STATUS:
			return action.response;
		default:
			return state;
	}
}

export function engagementUserStatusesReducer(
	state = initialState.engagementUserStatuses,
	action
) {
	switch (action.type) {
		case types.GET_ENGAGEMENT_USER_STATUSES:
			return action.response;
		default:
			return state;
	}
}

export function independenceStatusesReducer(
	state = initialState.independenceStatuses,
	action
) {
	switch (action.type) {
		case types.GET_INDEPENDENCE_STATUSES:
			return action.response;
		default:
			return state;
	}
}

export function getEmployeeADStatusReducer(
	state = initialState.employeeADStatuses,
	action
) {
	switch (action.type) {
		case types.GET_EMPLOYEE_AD_STATUS:
			let eyEmplStatusExtension = env.getEYEmplADStatusExtension();
			const wasFound = action.responseObj?.status === HttpStatusCodes.Ok;
			let employeeStatuses = [];
			if (state.statuses) {
				employeeStatuses = [...state.statuses];
			}
			if (wasFound && action.response) {
				var employeeStatus = action.response?.[eyEmplStatusExtension];
				if (!employeeStatus?.length) {
					employeeStatus = 'A';
				}
				employeeStatuses[action.userEmailAddress] = employeeStatus;
			} else {
				employeeStatuses[action.userEmailAddress] = 'error';
			}
			return {
				...state,
				statuses: employeeStatuses
			};
		default:
			return state;
	}
}

export function rbacUserRolesReducer(state = initialState.rbacRoles, action) {
	switch (action.type) {
		case types.GET_ALL_RBAC_USER_ROLES:
			return action.response;
		default:
			return state;
	}
}

export function engagementSearchParametersReducer(
	state = initialState.engagementSearchParameters,
	action
) {
	switch (action.type) {
		case types.SET_ENGAGEMENT_SEARCH_PARAMETERS: {
			var updatedState = {
				...state,
				engagementId: action?.parameters?.engagementId,
				engagementName: action?.parameters?.engagementName,
				clientNameCode: action?.parameters?.clientNameCode,
				codeOrCodeName: action?.parameters?.codeOrCodeName,
				userGUIEmailUserId: action?.parameters?.userGUIEmailUserId,
				countryId: action?.parameters?.countryId
			};
			return updatedState;
		}
		default:
			return state;
	}
}