import styled from 'styled-components';

//documenttablestyle main page
const Documenttablestyle = styled.section`
	// common for table UI start

    .noresult{
        position: relative;
        overflow: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        height: calc(100vh - 33rem);
        color: ${(props) => props.theme.color[`grey400`]};
        border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
    }

	.documentwrapper {
		display: inline-block;
		width: 100%;

		.documentitemview {
			width: 100%;
			font-size: 1.4rem;
			display: flex;
			border-collapse: collapse;
			align-items: center;
			justify-content: center;
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};

			&.inactive mgt-person {
				color: ${(props) => props.theme.color[`grey300`]};
			}

			&:not(.inactive) .userName {
				color: ${(props) => props.theme.color[`grey500`]};
			}

			.grid {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-gap: 0 10px;
			}

			&:last-child {
				border-bottom: none;
				padding-bottom: 1rem;
			}
		}

		.document-header {
			width: 100%;
			display: inline-block;
			.documentheader {
				width: 100%;
				font-size: 1.4rem;
				color: ${(props) => props.theme.color[`grey450`]};
				font-weight: bold;
				display: flex;
				border-collapse: collapse;
				background: ${(props) => props.theme.color[`grey100`]};
				border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};

				.documentitem:last-child {
					// text-align: center;
					// width: 1%;
				}
			}
		}

		.document-body {
			overflow: auto;
			height: calc(100vh - 35.5rem);
			display: inline-block;
			width: 100%;
			border: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
			@media screen and (max-width: 1366px){
				height: calc(100vh - 37rem);
			}
			.documentholder {			
				width: 100%;
				display: inline-block;
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
			}
		}

		.document-header,
		.document-body {
			.documentitem {
				padding: 1rem;
				flex: 1 1 auto;
				width: 20%;
				vertical-align: middle;
				display: block;

				.description {
					display: flex;

					svg.delete {
						cursor: pointer;
					}
				}

				.ellipses {
					vertical-align: middle;
					line-height: normal;
				}

                &.uri{
                    width: 30%;
					.ellipses {
						vertical-align: middle;
						line-height: normal;
					}
                }
				
				&.version,
                &.filesize,
                &.fileexist {
					width: 10%;
					.ellipses {
						vertical-align: middle;
						line-height: normal;
					}
				}

                .engname {
					width: 100%;
					display: inline-grid;

					.ellipses {
						line-height: normal;
					}
				}
			}
		}
	}

	// common for table UI end
`;

export default Documenttablestyle;
