import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

export default function SelectTemplateReducer(
	state = initialState.selectTemplate,
	action
) {
	switch (action.type) {
		case types.GET_SELECTTEMPLATE:
			return action.response;
		default:
			return state;
	}
}
