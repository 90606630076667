import axios from 'axios';
import defs from '@ey/osg-style/src/images/common/svgdefs.svg';
import localDefs from '../../assets/icons/svgdefs.svg';
import {sanitize} from 'dompurify';

function loadSVG(defs) {
	axios
		.get(defs, {
			responseType: 'text'
		})
		.then(({data}) => {
			let parser = new DOMParser();
			let doc = parser.parseFromString(sanitize(data), 'application/xml');
			document.body.appendChild(doc.documentElement);
		});
}

console.log('Loading SVG');
loadSVG(defs);
loadSVG(localDefs);
