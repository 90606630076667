import React from 'react';

const engroot = {
	height: '10rem',
	width: '43rem',
	paddingLeft: '3rem',
	display: 'block',
	paddingTop: '3rem'
};
const txt = {
	marginLeft: '1rem'
};
const dr = {
	marginLeft: '1rem',
	display: 'inline-block',
	width: '35.7rem',
	paddingLeft: '1rem'
};

export default class Engagement extends React.Component {
	constructor(props) {
		super(props);

		if (this.props.x)
			this.state = {
				x: this.props.x,
				y: this.props.y,
				showRest: true
			};
		else {
			this.state = {
				x: '',
				y: '0',
				showRest: false
			};
		}
	}

	render() {
		//return <section style={notifIn}>{this.props.title}</section>;
		return (
			<section style={engroot}>
				{this.props.showDrop ? (
					<section style={dr}>
						<select
							onChange={(e) => {
								this.setState({showRest: true, x: e.target.value});
							}}
						>
							<option value="Cola">Cola</option>
							<option value="Shoes">Shoes</option>
							<option value="NIce">Nile</option>
							<option value="audi">audi</option>
						</select>
					</section>
				) : null}
				{this.state.showRest ? (
					<section>
						<input type="text" style={txt} value={this.state.x} />
						<input type="text" style={txt} value={this.state.y} />
					</section>
				) : null}
			</section>
		);
	}
}
