import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../Common/LoadingIndicator/LoadingIndicator';
import { displayGroupings, labels, searchTypes } from '../../util/uiconstants';
import { getMyEngagements } from '../../actions/engagementsaction';
import styled from 'styled-components';
import Env from '../../util/env';
import WorkspaceGroupAudit from './workspaceGroupAudit';
import EllipsesControl from '@ey/ellipses-control';
import { engagementUserStatus } from '../../util/enumerations';
import { patchCallAPI } from '../../actions/engagementuseractions';
import { urls } from '../../util/uiconstants';
import { raiseJSValidationError } from '../../actions/erroractions';
import { getNotifications } from '../../actions/notificationsactions';
import { useHistory, useLocation } from 'react-router-dom';
import { setSuccessfulToastMessage } from '../../actions/engagementsaction';

const IndependenceOnlyRejectInvite = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const search = useLocation().search.toLowerCase();

    const engagements = useSelector((store) => store.myEngagements);

    const [engagement, setEngagement] = useState({
        id: 0,
        data: '',
        clientName: '',
        workspace: ''
    });

    const urlSettings = {
        top: 10,
        skip: 10,
        searchTypeId: searchTypes.EXCLUDE_WORKSPACE,
        displaytypeid: displayGroupings.LIST
    };

    let mainUrl = Env.getURL('serviceUrl') +
        '/home.json?phase=MyNavigation&Top=' +
        urlSettings.top +
        '&Skip=' +
        urlSettings.skip +
        '&filters=searchtypeid eq ' +
        urlSettings.searchTypeId +
        ',displaytypeid eq ' +
        urlSettings.displaytypeid +
        '&SearchExpression=' +
        parseInt(new URLSearchParams(search).get('engagementid'));

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const searchEngagement = async () => {
            await dispatch(
                getMyEngagements(mainUrl, mainUrl.split('/api/v1')[0])
            ).catch(() => {
                dispatch(raiseJSValidationError(labels.IndependenceOnlyRejectInvite.engagementIsNotAvailable));
            });
        };
        setIsLoading(true);
        searchEngagement();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(engagements)) {
            if (!_.isEmpty(engagements.allengagements)) {
                setEngagement({
                    id: engagements.allengagements[0].engagements.id,
                    data: engagements.allengagements[0].engagements.data,
                    clientName: engagements.allengagements[0].client.data.clientname,
                    workspace: engagements.allengagements[0].workspace
                });
            } else {
                setIsLoading(false);
                dispatch(raiseJSValidationError(labels.independenceOnlyRejectInvite.engagementIsNotAvailable));
            }
        }
    }, [engagements]);

    useEffect(() => {
        if (engagement.id > 0) {
            rejectInvitation();
        }
    }, [engagement]);

    const getEngInviteModel = () => {
        let inviteModel = {};
        let inviteEngagementData = {
            id: engagement.id,
            engagementUserStatusId: engagementUserStatus.Rejected.toString(),
            countryId: engagement?.workspace?.countryid,
            engagementId: engagement.id
        };

        inviteModel.data = {
            countryid: engagement?.workspace?.countryid
        };
        inviteModel.collections = {
            engagements: [
                {
                    engagementId: engagement.id,
                    data: inviteEngagementData,
                    id: engagement.id
                }
            ]
        };

        return inviteModel;
    };

    const returnToDashboard = () => {
        const url = Env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;
        dispatch(getNotifications(url));
        history.push('/engagements');
    };

    const rejectInvitation = () => {
        dispatch(
            patchCallAPI(
                Env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
                JSON.stringify(getEngInviteModel())
            )
        ).then(() => {
            dispatch(setSuccessfulToastMessage(labels.independenceOnlyRejectInvite.succesfulRejection));
            returnToDashboard();
        })
        .catch(() => {
            dispatch(raiseJSValidationError(labels.independenceOnlyRejectInvite.failedToRejectInvite));
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <StyledRejectIndependenceOnly>
            {isLoading && (
                <section className="loader-wrapper">
                    <section className="loader-text">{labels.independenceOnlyRejectInvite.loadingLabel}</section>
                    <LoadingIndicator show />
                </section>
            )}
            {engagement.id > 0 && (
                <section className="reject-independenceview">
                    <section className="engagement-detailswrapper">
                        <section className="engagement-details">
                            <section className="engagement-information">
                                <section className="clientname" title={engagement?.clientName}>
                                    {engagement?.clientName}
                                </section>
                                <WorkspaceGroupAudit workspace={engagement?.workspace} engagement={engagement?.data} />
                            </section>
                            <section className="engagement-id">ID: {engagement?.id}</section>
                        </section>
                        <section className="engagement-description" data-autoid={engagement?.id}>
                            <EllipsesControl
                                content={
                                    engagement?.data?.engagementdesc
                                }
                                tooltip={
                                    engagement?.data?.engagementdesc
                                }
                                isTooltipAvailable
                            />
                        </section>
                    </section>
                </section>
            )}
        </StyledRejectIndependenceOnly>
    );
};

export default IndependenceOnlyRejectInvite;

const StyledRejectIndependenceOnly = styled.section`
	width: 100%;
    height: 100%;
	.loader-wrapper {
		display: flex;
		width: 100%;
		height:100%;
		align-items: center;
		justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
		.loader-text {
			margin-top: -5rem;
			font-size: 1.8rem;
			font-weight: 400;
			color:${(props) => props.theme.color[`black50`]};
		}
	}
	.reject-independenceview {
		display: block;
		height: calc(-6.5rem + 100vh);
		width:100%;
		overflow: auto;
		max-width: 2048px;
		margin: 0px auto;
		.rejectindependence-toast {
			.motif-toast-fixed-top {
				top: 6.2rem;
				left: -0.5rem !important;
				margin: 0 auto !important;
				padding: 0 !important;
				z-index: 1000 !important;
				max-width: 2048px;
				width: calc(100vw - 8.5rem) !important;
				.motif-toast-text-container {
					font-size: 1.4rem;
				}
			}	
		}
		.engagement-detailswrapper {
			padding: 4rem 6rem;
			.engagement-details {
				display: flex;
				.engagement-information {
					display: flex;
					font-size:1.4rem;
					font-weight:300;
					color:${(props) => props.theme.color[`grey400`]};			
					.workspace-name,
					.engagement-type,
					.engagement-typelinked {
						padding-left:0.5rem;
					}
					.clientname {

					}
				}
				.engagement-id {
					display: flex;
					margin-left:auto;
					font-size:1.4rem;
					font-weight:300;
					color:${(props) => props.theme.color[`grey400`]};
				}
			}
			.engagement-description {
				display: flex;
				margin-left:auto;
				font-size:1.6rem;
				font-weight:600;
				color:${(props) => props.theme.color[`grey500`]};
			}
		}
	}
`;
