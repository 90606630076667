import React, {useState} from 'react';
import {useEffect} from 'react';
import env from '../../.././util/env';
import {getPermissionGroups, resetPermissionGroup} from '../../../actions/admin/permissiongroupsactions';
import {pagingOptions} from '../../../../src/util/uiconstants';
import Pagination from '@ey/pager';
import {useSelector, useDispatch} from 'react-redux';
import {MotifTable} from '@ey-xd/motif-react';
import EllipsesControl from '@ey/ellipses-control';
import Loader from '../../Common/Loaders/loadingindicator';
import {Link} from 'react-router-dom';
import {labels} from '../../../util/uiconstants';
import AccessDeniedMessage from '../../Common/AccessDeniedMessage/AccessDeniedMessage';
import Wrapper from '../permissiongroups/styles/permissiongroupsbasestyle';
import {clearErrors} from '../../../actions/erroractions';
import {setIsModalOpen} from '../../../actions/modalstateactions';

function PermissionGroup() {
	const columnDefs = [
		{
			minWidth: 450,
			width: 450,
			headerName: labels.permissionGroupName,
			field: 'groupname',
			cellRenderer: 'groupNameCellRenderer',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 450,
			width: 450,
			headerName: labels.permissionGroupDescription,
			field: 'groudescription',
			cellRenderer: 'groupDecriptionCellRenderer',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 450,
			width: 450,
			headerName: labels.activeMembers,
			field: 'activemembers',
			cellRenderer: 'activeMembersCellRenderer',
			lockPosition: true,
			suppressSizeToFit: true
		}
	];

	const groupNameCellRenderer = (e) => {
		return (
			<>
				<section className="roleType">
					<Link
						className="groupname"
						to={{
							pathname:
								'/admin/permissiongroups/' + e.data?.activeDirectoryGroupID,
							state: {customLabel: e.data?.activeDirectoryGroupName}
						}}
					>
						<section>
							<EllipsesControl
								content={e.data?.activeDirectoryGroupName}
								tooltip={e.data?.activeDirectoryGroupName}
								displayId={''}
								noOfLines={1}
								ellipsisClass="columnName"
							/>
						</section>
					</Link>
				</section>
			</>
		);
	};

	const groupDecriptionCellRenderer = (e) => {
		return (
			<>
				<section className="roleType">
					<section>
						<EllipsesControl
							content={e.data?.activeDirectoryGroupDescription}
							tooltip={e.data?.activeDirectoryGroupDescription}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</>
		);
	};

	const activeMembersCellRenderer = (e) => {
		return (
			<>
				<section className="roleType">
					<section>{e.data?.activeUserCount}</section>
				</section>
			</>
		);
	};

	const permissionGroup = useSelector((store) => store.permissionGroup);
	const userPermissions = useSelector((store) => store.userPermissions);
	const [optionPerPage, setOptionPerPage] = useState(pagingOptions.options[0]);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState();
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		setLoading(true);
		dispatch(resetPermissionGroup());
		let permissionGroupUrl =
			env.getURL('serviceUrlV2') + 'admin/PermissionGroups';
		dispatch(getPermissionGroups(permissionGroupUrl))
			.then(() => {
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}, []);

	// Use common error clearing framework to clear axios errors
	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	useEffect(()=>{
		clearError(true);
	},[])

	useEffect(() => {
		var pages =
			permissionGroup.length % optionPerPage == 0
				? permissionGroup.length / optionPerPage
				: (permissionGroup.length - (permissionGroup.length % optionPerPage)) /
						optionPerPage +
				  1;
		setTotalPages(pages);
	}, [optionPerPage, permissionGroup]);

	const paginationHandler = (value) => {
		if (page !== value && page > 0) {
			setPage(value);
		}
	};

	const handlerOptionPage = (value) => {
		if (value !== optionPerPage) {
			setOptionPerPage(value);
			setPage(1);
		}
	};

	function adminPermissions() {
		let permissionGroupPermission = userPermissions?.permissions;
		return (
			permissionGroupPermission &&
			(permissionGroupPermission.canReadPermissionsGroups ||
				permissionGroupPermission.canSyncPermissionsGroups ||
				permissionGroupPermission.canCreateUpdatePermissionsGroups)
		);
	}

	return (
		<Wrapper>
			{loading == true ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<section className="mainwrapper">
					{adminPermissions() ? (
						permissionGroup ? (
							<section>
								<section className="permissiongroup mainResponsiveWrapper">
									<section className="managepermissionTable">
										<MotifTable
											defaultColDef={{
												flex: 1,
												sortable: false,
												filter: false,
												resizable: false,
												minWidth: 180,
												lockPosition: true
											}}
											onGridReady={(params) => {
												params.api.sizeColumnsToFit();
											}}
											enableCellTextSelection
											suppressRowClickSelection
											suppressCellSelection
											columnDefs={columnDefs}
											rowSelection="multiple"
											rowData={Array.from(permissionGroup).slice(
												(page - 1) * optionPerPage,
												page * optionPerPage
											)}
											rowBuffer={200}
											frameworkComponents={{
												groupNameCellRenderer: groupNameCellRenderer,
												groupDecriptionCellRenderer: groupDecriptionCellRenderer,
												activeMembersCellRenderer: activeMembersCellRenderer
											}}
										/>
									</section>
								</section>
							</section>
						) : (
							<section className="message">
								<h5>{labels.serverErrorMessage}</h5>
							</section>
						)
					) : (
						<section className="message">
							<AccessDeniedMessage
								permissionMesage={labels.insufficientPermission}
							/>
						</section>
					)}
				</section>
			)}

			<section className="pagerholder">
				<Pagination
					styleName="permissiongroup-paging mainResponsiveWrapper"
					options_per_page={pagingOptions.options}
					default_dropdown_value={optionPerPage}
					currentPage={page}
					TotalPages={totalPages}
					dropup
					onInputChange={(e) => {
						let inputValue = Number.parseInt(e.target.value);
						paginationHandler(inputValue);
					}}
					onDropdownChange={(e) => {
						handlerOptionPage(e.value);
					}}
					onFirstPageClick={() => {
						paginationHandler(1);
					}}
					onPrevPageClick={() => {
						paginationHandler(page - 1);
					}}
					onNextPageClick={() => {
						paginationHandler(page + 1);
					}}
					onLastPageClick={() => {
						paginationHandler(totalPages);
					}}
					dropdown_position="left"
				/>
			</section>
		</Wrapper>
	);
}

export default PermissionGroup;
