/**
 * Remember: always add new reducers to the /reducers/index.js file!
 * Also, add your initialState to './initialstate'!
 */
import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export default function ViewAccessListReducer(
	//state = initialState.<%=names.camel%>,//TODO: uncomment this. Get from initialState.
	state = initialState.viewAccessList, //{Workspaces: {}},
	action
) {
	switch (action.type) {
		case types.LOAD_VIEW_ACCESS_LIST:
			/* Notice that this is simply returning the action payload, <%=names.camel%>.
			 * This is because the combineReducers() call wraps this reducer
			 * and passes it only the portion of the store state that it
			 * is concerned with. */
			console.log('action from viewaccesslist reducer:', action);

			return action.response;
		default:
			return state;
	}
}
