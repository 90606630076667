/**
 * Scot.js
 * Created by zl952fc on 02/24/2020.
 */

import React from 'react';
import styled from 'styled-components';
import Notification from './Notification';

export default function Notifications(props) {
	const {notifications, onFirstButtonClick, onSecondButtonClick} = props;
	return (
		<NotificationListGroupContainer className="NotificationListGroupContainer">
			{notifications &&
				notifications.length > 0 &&
				notifications.map((notification) => {
					return (
						<Notification
							key={notification.id}
							notification={notification}
							onFirstButtonClick={onFirstButtonClick}
							onSecondButtonClick={onSecondButtonClick}
							acceptedEngagementList={props.acceptedEngagementList}
							acceptedArchiveEngagementList={
								props.acceptedArchiveEngagementList
							}
							isInviteAcceptedOrRejected={props.isInviteAcceptedOrRejected}
							isArchiveInviteAcceptedOrRejected={
								props.isArchiveInviteAcceptedOrRejected
							}
							setisInviteAcceptedOrRejectedToDone={
								props.setisInviteAcceptedOrRejectedToDone
							}
							setisArchiveInviteAcceptedOrRejectedToDone={
								props.setisArchiveInviteAcceptedOrRejectedToDone
							}
						/>
					);
				})}
		</NotificationListGroupContainer>
	);
}

const NotificationListGroupContainer = styled.ul`
	background-color: ${(props) => props.theme.color[`white`]};
`;
