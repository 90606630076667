/**
 * loadingindicator.js
 * Created by calhosh on 11/6/2019.
 */
import React from 'react';
import { LoadingIndicator as Loader } from '@ey/canvascoreservices';

export default function LoadingIndicator(props) {
	const { show, fullscreen, view, styleName} = props;
	return (
		<>
			{show ?
				(<Loader
					fullscreen={fullscreen}
					view={view}
					styleName={styleName}
					theme={document.getElementsByTagName('body')[0].getAttribute('motif-theme') === 'light' ? null : 'dark'}
				/>
				)
			: []
			}
		</>
	);
}
