/* eslint-disable react/no-did-mount-set-state */
/**
 * @module UserSettings
 * Container Component UserSettings
 */

import {EYForm, FormComboBox, FormStaticText, FormTextInput} from '@ey/form';
import {MotifButton, MotifContentSwitcher} from '@ey-xd/motif-react';
import React, {useEffect, useState} from 'react';
import {
	createUserSettings,
	getUserSettings,
	setUserSettings
} from '../../actions/usersettingsaction';
import {
	currentResource,
	dateFormatsListItems,
	displayLanguageListItems,
	labels,
	negativeNumberFormatsListItems,
	numberFormatsListItems,
	pagerOptions,
	preferredLandingPageFilterSwitcher,
	preferredLandingPageViewSwitcher,
	preferredThemeSwitcher,
	setDateFormat,
	setLanguage,
	settingsPreferences,
	standardStrings,
	urls
} from '../../util/uiconstants';
import {useDispatch, useSelector} from 'react-redux';

import Button from '@ey/button';
import env from '../../util/env';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import LoadingIndicator from '../Common/LoadingIndicator/LoadingIndicator';

const UserSettings = (props) => {
	// Params
	const dispatch = useDispatch();
	const userSettings = useSelector((state) => state.userSettings);
	const history = useHistory();
	const roleList = currentResource.rolelistitems;
	const userSettingsUrl = env.getURL('serviceUrl') + '/' + urls.USERS_URL;
	//State
	const [isLoading, setIsLoading] = useState(true);
	const [userId, setUserId] = useState(
		userSettings.id ? userSettings.id : standardStrings.EMPTY
	);
	const [userfname, setUserfname] = useState(
		userSettings.data.userfname
			? userSettings.data.userfname
			: standardStrings.EMPTY
	);
	const [userlname, setUserlname] = useState(
		userSettings.data.userlname
			? userSettings.data.userlname
			: standardStrings.EMPTY
	);
	const [userinitials, setUserinitials] = useState(
		userSettings.data.userinitials
			? userSettings.data.userinitials
			: standardStrings.EMPTY
	);
	const [userprimaryoffice, setUserprimaryoffice] = useState(
		userSettings.data.userprimaryoffice
			? userSettings.data.userprimaryoffice
			: standardStrings.EMPTY
	);
	const [userpreferredcontactphone, setUserpreferredcontactphone] = useState(
		userSettings.data.userpreferredcontactphone
			? userSettings.data.userpreferredcontactphone
			: standardStrings.EMPTY
	);
	const [userroleid, setUserroleid] = useState(
		userSettings.data.userroleid ? userSettings.data.userroleid : null
	);
	const [userlanguageid, setUserlanguageid] = useState(
		userSettings.data.userlanguageid && userSettings.data.userlanguageid > 0
			? userSettings.data.userlanguageid
			: 1
	);
	const [userdatetimeformat, setUserdatetimeformat] = useState(
		userSettings.data.userdatetimeformat &&
			userSettings.data.userdatetimeformat > -1
			? userSettings.data.userdatetimeformat
			: 0
	);
	const [usernumberformat, setUsernumberformat] = useState(
		userSettings.data.usernumberformat &&
			userSettings.data.usernumberformat > -1
			? userSettings.data.usernumberformat
			: 0
	);
	const [usernegativenumberformat, setUsernegativenumberformat] = useState(
		userSettings.data.usernegativenumberformat &&
			userSettings.data.usernegativenumberformat > -1
			? userSettings.data.usernegativenumberformat
			: 0
	);
	const [validationTriggerCounter, setValidationTriggerCounter] = useState(0);
	const [preferredlandingpagefilter, setPreferredlandingpagefilter] = useState(
		userSettings.data.preferredlandingpagefilter
			? userSettings.data.preferredlandingpagefilter
			: ''
	);
	const [preferredlandingpageview, setPreferredlandingpageview] = useState(
		userSettings.data.preferredlandingpageview
			? userSettings.data.preferredlandingpageview
			: ''
	);
	const [preferrednumberofengagements, setPreferrednumberofengagements] =
		useState(
			userSettings.data.preferrednumberofengagements
				? userSettings.data.preferrednumberofengagements
				: null
		);
	const [preferredtheme, setPreferredtheme] = useState(
		userSettings.data.preferredtheme ? userSettings.data.preferredtheme : ''
	);

	const [activeIndexPrefLandingPageView, setActiveIndexPrefLandingPageView] =
		useState(
			userSettings.data.preferredlandingpageview &&
				userSettings.data.preferredlandingpageview ===
					settingsPreferences.WorkspaceList
				? preferredLandingPageViewSwitcher.WorkspaceList
				: preferredLandingPageViewSwitcher.EngagementList
		);
	const [
		activeIndexPrefLandingPageFilter,
		setActiveIndexPrefLandingPageFilter
	] = useState(
		userSettings.data.preferredlandingpagefilter &&
			userSettings.data.preferredlandingpagefilter ===
				settingsPreferences.FavoriteEngagements
			? preferredLandingPageFilterSwitcher.FavoriteEngagements
			: preferredLandingPageFilterSwitcher.AllEngagements
	);
	const [activeIndexPrefTheme, setActiveIndexPrefTheme] = useState(
		userSettings.data.preferredtheme &&
			userSettings.data.preferredtheme === settingsPreferences.DarkTheme
			? preferredThemeSwitcher.Dark
			: preferredThemeSwitcher.Light
	);

	useEffect(() => {
		setIsLoading(true);
		const loadUserSettings = async () => {
			await dispatch(getUserSettings(userSettingsUrl)).finally(() =>
				setIsLoading(false)
			);
		};
		loadUserSettings();
	}, []);

	const isFirstTimeUser = () => {
		const _isFirstTimeUser = !(
			userSettings.data &&
			userSettings.data.id &&
			userSettings.data.id !== '00000000-0000-0000-0000-000000000000' &&
			userSettings.data.userlanguageid > 0 &&
			userSettings.data.userdatetimeformat > -1 &&
			userSettings.data.usernumberformat > -1
		);
		return _isFirstTimeUser;
	};

	// Guest user does not have certain option available on the
	// UI, so hide it.
	const isGuestUser = () => {
		if (
			userSettings.data &&
			userSettings.data.iseyuser !== null &&
			userSettings.data.iseyuser !== undefined
		) {
			return !userSettings.data.iseyuser;
		}

		return true;
	};

	const saveUserDetails = async () => {
		setIsLoading(true);

		let model = {
			collections: {
				userscollection: [
					{
						data: {
							userpreferredcontactphone: userpreferredcontactphone,
							userinitials: userinitials,
							userroleid: userroleid,
							userlanguageid: userlanguageid,
							userfname: userfname,
							userlname: userlname,
							userprimaryoffice: userprimaryoffice,
							userdatetimeformat: userdatetimeformat,
							usernumberformat: usernumberformat,
							usernegativenumberformat: usernegativenumberformat,
							preferredlandingpageview: preferredlandingpageview,
							preferredlandingpagefilter: preferredlandingpagefilter,
							preferrednumberofengagements: preferrednumberofengagements,
							preferredtheme: preferredtheme
						}
					}
				]
			}
		};

		// Existing User
		if (userId && userId !== '00000000-0000-0000-0000-000000000000') {
			await dispatch(setUserSettings(userSettingsUrl + '/' + userId, model));
		} else {
			// New User
			await dispatch(createUserSettings(userSettingsUrl, model));
		}
	};

	const isFormValid = () => {
		const isValid =
			(userfname || standardStrings.EMPTY).length > 0 &&
			(userlname || standardStrings.EMPTY).length > 0 &&
			(userinitials || standardStrings.EMPTY).length > 0 &&
			(userprimaryoffice || standardStrings.EMPTY).length > 0 &&
			(userpreferredcontactphone || standardStrings.EMPTY).length > 0 &&
			!Number.isNaN(Number.parseInt(userroleid)) &&
			!Number.isNaN(Number.parseInt(userlanguageid)) &&
			!Number.isNaN(Number.parseInt(userdatetimeformat)) &&
			!Number.isNaN(Number.parseInt(usernumberformat)) &&
			!Number.isNaN(Number.parseInt(usernegativenumberformat));

		return isValid;
	};

	const handleSave = async () => {
		if (isFormValid()) {
			await saveUserDetails();

			// update redux state
			await dispatch(getUserSettings(userSettingsUrl));

			setLanguage(userlanguageid);
			setDateFormat(userdatetimeformat);
			setIsLoading(false);
			redirectToPreferredLandingPage();
		} else {
			setValidationTriggerCounter(validationTriggerCounter + 1);
		}
	};

	const handleCancel = () => {
		history.goBack();
	};

	const handleActiveClassPrefLandingPageView = (index) => {
		return activeIndexPrefLandingPageView === index ? 'motif-active' : '';
	};

	const handleActiveClassPrefLandingPageFilter = (index) => {
		return activeIndexPrefLandingPageFilter === index ? 'motif-active' : '';
	};

	const handleActiveClassPrefTheme = (index) => {
		return activeIndexPrefTheme === index ? 'motif-active' : '';
	};

	const handlePrefLandingPageViewClick = (event, indexSwitcher) => {
		setActiveIndexPrefLandingPageView(indexSwitcher);

		if (indexSwitcher === preferredLandingPageViewSwitcher.WorkspaceList)
			setPreferredlandingpageview(settingsPreferences.WorkspaceList);
		else setPreferredlandingpageview(settingsPreferences.EngagementList);

		event.preventDefault();
	};

	const handlePrefLandingPageFilterClick = (event, indexSwitcher) => {
		setActiveIndexPrefLandingPageFilter(indexSwitcher);

		if (
			indexSwitcher === preferredLandingPageFilterSwitcher.FavoriteEngagements
		)
			setPreferredlandingpagefilter(settingsPreferences.FavoriteEngagements);
		else setPreferredlandingpagefilter(settingsPreferences.AllEngagements);

		event.preventDefault();
	};

	const handlePrefThemeClick = (event, indexSwitcher) => {
		setActiveIndexPrefTheme(indexSwitcher);

		if (indexSwitcher === preferredThemeSwitcher.Dark)
			setPreferredtheme(settingsPreferences.DarkTheme);
		else setPreferredtheme(settingsPreferences.LightTheme);

		event.preventDefault();
	};

	const redirectToPreferredLandingPage = () => {
		let path = '',
			searchPath = '';

		preferredlandingpagefilter === settingsPreferences.AllEngagements
			? (path = '/engagements')
			: (path = '/favorites');

		preferredlandingpageview === settingsPreferences.EngagementList
			? (searchPath = '')
			: (searchPath = '?groupby=clients');

		history.push({
			pathname: path,
			search: searchPath
		});
	};

	const handleInputChange = (key, value) => {
		switch (key) {
			case 'userfname':
				setUserfname(value);
				break;
			case 'userlname':
				setUserlname(value);
				break;
			case 'userinitials':
				setUserinitials(value);
				break;
			case 'userprimaryoffice':
				setUserprimaryoffice(value);
				break;
			case 'userroleid':
				setUserroleid(value);
				break;
			case 'userpreferredcontactphone':
				setUserpreferredcontactphone(value);
				break;
			case 'userlanguageid':
				setUserlanguageid(value);
				break;
			case 'userdatetimeformat':
				setUserdatetimeformat(value);
				break;
			case 'usernumberformat':
				setUsernumberformat(value);
				break;
			case 'usernegativenumberformat':
				setUsernegativenumberformat(value);
				break;
			case 'preferrednumberofengagements':
				setPreferrednumberofengagements(value);
				break;
		}
	};

	const GetUserLanguages = () => {
		let collection = [];

		// check if russia is enabled
		// enabled => show Russia language in user option
		// Disabled => If user has current russai selection keep it
		// otherwise remove it
		Object.keys(displayLanguageListItems).map((displayLanguageId) => {
			if (
				displayLanguageId != 13 ||
				env.getRussiaLanguageEnabled() ||
				(displayLanguageId == 13 && userlanguageid == 13)
			) {
				collection.push({
					value: String(displayLanguageId),
					label: displayLanguageListItems[displayLanguageId]
				});
			}
		});

		return collection;
	};

	return (
		<>
			{isLoading ? (
				<section className="loader-wrapper">
					<LoadingIndicator show fullscreen />
				</section>
			) : !isLoading && userSettings && userSettings.data ? (
				<UserSettingsWrapper className="customScrollbar">
					<header>
						<h5>{labels.usersettings}</h5>
					</header>
					<form className="userfields">
						<EYForm
							id="userSettingsForm"
							onChange={handleInputChange}
							onSubmit={(e) => {
								e.preventDefault();
							}}
							action="\"
							validationTriggerCounter={validationTriggerCounter}
						>
							<section className="form-title">
								<FormStaticText label={labels.engagementsettings}>
									{labels.usersettingsnewengagements}
								</FormStaticText>
							</section>
							<FormTextInput
								placeholder={labels.enterfirstname}
								name="userfname"
								value={userfname}
								rules={[]}
								is_required
								help
								maxLength="255"
								errormessage={labels.isRequired.replace(
									'{0}',
									labels.firstname
								)}
							/>
							<FormTextInput
								placeholder={labels.enterlastname}
								name="userlname"
								value={userlname}
								rules={[]}
								is_required
								help
								maxLength="255"
								errormessage={labels.isRequired.replace('{0}', labels.lastname)}
							/>
							<FormTextInput
								placeholder={labels.signoffinitials}
								name="userinitials"
								value={userinitials}
								rules={[]}
								is_required
								help
								maxLength="3"
								errormessage={labels.isRequired.replace(
									'{0}',
									labels.signoffinitials
								)}
							/>
							<FormTextInput
								placeholder={labels.primaryoffice}
								name="userprimaryoffice"
								value={userprimaryoffice}
								rules={[]}
								is_required
								help
								maxLength="255"
								errormessage={labels.isRequired.replace(
									'{0}',
									labels.primaryoffice
								)}
							/>
							<FormComboBox
								id="userroleid"
								name="userroleid"
								value={String(userroleid)}
								is_required
								help
								placeholder={labels.roles}
								options={roleList.map((role) => {
									return {
										value: String(role.id),
										label: role.label
									};
								})}
								errormessage={labels.isRequired.replace('{0}', labels.roles)}
								clearable={false}
							/>
							<FormTextInput
								placeholder={labels.phonenumber}
								name="userpreferredcontactphone"
								value={userpreferredcontactphone}
								rules={[]}
								is_required
								help
								maxLength="20"
								errormessage={labels.isRequired.replace(
									'{0}',
									labels.phonenumber
								)}
							/>
							<section className="form-title">
								<FormStaticText label={labels.theme} />
							</section>
							<label className="usersettings-label">
								{labels.chooseDisplayTheme}
							</label>
							<MotifContentSwitcher className="pref-theme">
								<MotifButton
									variant="secondary"
									title={labels.light}
									onClick={(e) => {
										handlePrefThemeClick(e, preferredThemeSwitcher.Light);
									}}
									className={handleActiveClassPrefTheme(
										preferredThemeSwitcher.Light
									)}
								>
									{labels.light}
								</MotifButton>
								<MotifButton
									variant="secondary"
									title={labels.dark}
									onClick={(e) => {
										handlePrefThemeClick(e, preferredThemeSwitcher.Dark);
									}}
									className={handleActiveClassPrefTheme(
										preferredThemeSwitcher.Dark
									)}
								>
									{labels.dark}
								</MotifButton>
							</MotifContentSwitcher>
							<section className="form-title">
								<FormStaticText label={labels.globalapplicationsettings}>
									{labels.usersettingsallengagements}
								</FormStaticText>
							</section>
							<FormComboBox
								id="userlanguageid"
								name="userlanguageid"
								value={String(userlanguageid)}
								is_required
								help
								placeholder={labels.displaylanguage}
								options={GetUserLanguages()}
								errormessage={labels.isRequired.replace(
									'{0}',
									labels.displaylanguage
								)}
								clearable={false}
							/>
							<FormComboBox
								id="userdatetimeformat"
								name="userdatetimeformat"
								value={String(userdatetimeformat)}
								is_required
								help
								placeholder={labels.preferreddateformat}
								options={dateFormatsListItems.map((dateFormat) => {
									return {
										value: String(dateFormat.id),
										label: dateFormat.label
									};
								})}
								errormessage={labels.isRequired.replace(
									'{0}',
									labels.preferreddateformat
								)}
								clearable={false}
							/>
							<FormComboBox
								id="usernumberformat"
								name="usernumberformat"
								value={String(usernumberformat)}
								is_required
								help
								placeholder={labels.preferrednumberformat}
								options={numberFormatsListItems.map((numberFormat) => {
									return {
										value: String(numberFormat.id),
										label: numberFormat.label
									};
								})}
								errormessage={labels.isRequired.replace(
									'{0}',
									labels.preferrednumberformat
								)}
								clearable={false}
							/>
							<FormComboBox
								id="usernegativenumberformat"
								name="usernegativenumberformat"
								value={String(usernegativenumberformat || 0)}
								is_required
								help
								placeholder={labels.preferrednegativenumberformat}
								options={negativeNumberFormatsListItems.map((numberFormat) => {
									return {
										value: String(numberFormat.id),
										label: numberFormat.label
									};
								})}
								errormessage={labels.isRequired.replace(
									'{0}',
									labels.preferrednegativenumberformat
								)}
								clearable={false}
							/>
							<label className="usersettings-label">
								{labels.preferredlandingpageview}
							</label>
							<MotifContentSwitcher className="prefland-pageview">
								<MotifButton
									variant="secondary"
									title={labels.engagementlist}
									onClick={(e) => {
										handlePrefLandingPageViewClick(
											e,
											preferredLandingPageViewSwitcher.EngagementList
										);
									}}
									className={handleActiveClassPrefLandingPageView(
										preferredLandingPageViewSwitcher.EngagementList
									)}
								>
									{labels.engagementlist}
								</MotifButton>
								<MotifButton
									variant="secondary"
									title={labels.workspacelistusersettings}
									onClick={(e) => {
										handlePrefLandingPageViewClick(
											e,
											preferredLandingPageViewSwitcher.WorkspaceList
										);
									}}
									className={handleActiveClassPrefLandingPageView(
										preferredLandingPageViewSwitcher.WorkspaceList
									)}
								>
									{labels.workspacelistusersettings}
								</MotifButton>
							</MotifContentSwitcher>

							{!isGuestUser() ? (
								<React.Fragment>
									<label className="usersettings-label">
										{labels.preferredlandingpagefilter}
									</label>
									<MotifContentSwitcher className="prefland-pagefilter">
										<MotifButton
											variant="secondary"
											title={labels.allengagements}
											onClick={(e) => {
												handlePrefLandingPageFilterClick(
													e,
													preferredLandingPageFilterSwitcher.AllEngagements
												);
											}}
											className={handleActiveClassPrefLandingPageFilter(
												preferredLandingPageFilterSwitcher.AllEngagements
											)}
										>
											{labels.allengagements}
										</MotifButton>
										<MotifButton
											variant="secondary"
											title={labels.favoriteengagements}
											onClick={(e) => {
												handlePrefLandingPageFilterClick(
													e,
													preferredLandingPageFilterSwitcher.FavoriteEngagements
												);
											}}
											className={handleActiveClassPrefLandingPageFilter(
												preferredLandingPageFilterSwitcher.FavoriteEngagements
											)}
										>
											{labels.favoriteengagements}
										</MotifButton>
									</MotifContentSwitcher>
								</React.Fragment>
							) : (
								<div />
							)}

							<label className="usersettings-label engnum-select">
								{labels.engagementsclientstoload}
							</label>
							{
								<FormComboBox
									id="preferrednumberofengagements"
									className="pref-numeng"
									name="preferrednumberofengagements"
									value={String(preferrednumberofengagements)}
									is_required
									help
									placeholder=""
									options={pagerOptions.map((pagerOption) => {
										return {
											value: String(pagerOption),
											label: pagerOption
										};
									})}
									errormessage={labels.isRequired.replace(
										'{0}',
										labels.preferrednumberofengagements
									)}
									clearable={false}
								/>
							}

							<section className="usersettingbtn">
								<Button
									type="button"
									value="save"
									onClick={handleSave}
									buttonType="primary"
									name="formSaveButton"
								>
									{labels.save}
								</Button>

								{isFirstTimeUser() ? (
									<div />
								) : (
									<Button
										type="button"
										value="cancel"
										onClick={handleCancel}
										buttonType="secondary"
										name="formCancelButton"
									>
										{labels.cancel}
									</Button>
								)}
							</section>
						</EYForm>
					</form>
				</UserSettingsWrapper>
			) : null}
		</>
	);
};

export default UserSettings;

const UserSettingsWrapper = styled.section`
	height: calc(100vh - 6.5rem);
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: auto;
	background: ${(props) => props.theme.color[`white`]};
	& .loader {
		display: flex;
		align-items: center;
		margin: 0 50%;
	}

	& > header {
		width: 100%;

		& h5 {
			font-size: 2rem;
			font-weight: bold;
			margin: 1.1rem auto;
			width: 100%;
			padding: 0 0 0 4rem;
			max-width: 2048px;
		}
	}

	& .usersettingbtn {
		padding: 2rem 0;
		margin-left: -4rem;
		margin-right: -4rem;
		padding-left: 4rem;
		border-top: 0.1rem solid ${(props) => props.theme.color[`grey500`]};
		& button {
			margin: 0 0.8rem 0 0;
		}
	}

	& .userfields {
		margin: 0 auto;
		max-width: 2048px;
		width: 100%;
		background: ${(props) => props.theme.color[`white`]};
		padding: 2rem 4rem 0 4rem;
		height: 100vh;
		.engnum-select + .form-group {
			//margin-bottom: 5rem;
			@media screen and (max-width: 1366px) {
				//	margin-bottom: 9rem;
			}
		}
		& .form-title {
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey500`]};
			display: inline-block;
			width: 100%;
			margin-bottom: 3rem;

			& .form-group {
				margin: 0;
				padding: 0;
				display: inline;
			}

			& .form-group label.control-label {
				display: inline;
				vertical-align: top;
				color: ${(props) => props.theme.color[`grey500`]};
			}

			& > .form-group * {
				font-size: 1.6rem;
				line-height: normal;
				padding: 0;
				margin: 0;
			}

			& .form-control-static {
				float: right;
				line-height: normal;
				font-size: 1.4rem;
				min-height: auto;
			}
		}

		& div.form-group:nth-child(2) {
			width: auto;
		}

		& div.form-group:nth-child(3) {
			width: auto;
			margin-left: 2rem;
		}

		& div.form-group {
			display: inline-flex;
			width: 100%;
			font-size: 1.4rem;
			height: 8.1rem;
			margin: 0 0 0.2rem 0;
			@media screen and (max-width: 1366px) {
				margin: 0 0 1.5rem 0;
			}

			& .input-group .input-label {
				line-height: normal;
			}

			// & label.control-label {
			// 	width: 20%;
			// 	display: inline-block;
			// 	float: left;
			// 	padding: 0;
			// 	font-weight: bold;
			// 	line-height: 4.8rem;
			// }

			& span.input-group {
				width: 40rem;
				float: left;

				& input.form-control {
					margin: 0 2rem0;
					vertical-align: middle;
					border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
					font-size: 1.4rem;
				}
			}

			& > section {
				width: 40rem;
				float: left;

				& .select-label {
					line-height: normal;
					color: ${(props) => props.theme.color[`grey500`]};
				}

				& .Select-control {
					margin: 0 2rem 0 0;
					vertical-align: middle;
					border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
					line-height: 4.6rem;
				}
			}
			.Select-input {
				background-color: var(--form-element-bg-color) !important;
			}
			.Select-arrow {
				border-color: ${(props) => props.theme.color[`grey500`]} !important;
			}
			& .select-value {
				width: 40rem;
				float: left;
				.Select-value-label {
					color: ${(props) => props.theme.color[`grey500`]} !important;
				}
				& .Select-control {
					border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
					background-color: var(--form-element-bg-color) !important;
				}
			}
		}
	}
	.usersettings-label {
		font-size: 1.4rem;
		font-weight: normal;
	}
	.prefland-pageview,
	.prefland-pagefilter,
	.pref-theme {
		margin-bottom: 3.3rem;
		button {
			font-size: 1.4rem;
			border-radius: 0;
			min-width: auto;
			padding: 0.3rem 3rem;
			display: block;
			max-width: 25rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&.motif-content-switcher .motif-button-secondary:hover {
			border-color: var(--content-switcher-btn-border-color);
			background-color: var(--content-switcher-hover-bg-color);
			color: var(--content-switcher-hover-font-color);
		}
		&.prefdashboard-settings {
			margin-bottom: 4.8rem;
		}
	}
	.pref-numeng {
		.Select-control {
			.Select-value-label {
				margin-top: 5px;
			}
			.Select-arrow-zone {
				.Select-arrow {
					top: -3px;
					@media screen and (max-width: 1366px) {
						top: -2px;
					}
				}
			}
		}
	}
`;
