import * as types from '../../actions/actiontypes';
import env from '../../util/env';


export function getMeCall() {
    return {
        type: types.USER_PERMISSIONS,
        meta: {
            api: {
                url: env.getURL('serviceUrlV2') + 'admin/Users/Me',
                method: 'GET'
            }
        }
    };
}