import React from 'react';
import EllipsesControl from '@ey/ellipses-control';
import {currentResource} from '../../util/uiconstants';

const ViewAccessItem = (props) => {
	let firstName = props.data && props.data.firstName;
	let lastName = props.data && props.data.lastName;
	let userName = firstName + ' ' + lastName;
	let userRoleId = (props.data && props.data.role) || 24;
	return (
		<tr className="teammembers-body">
			<td className="user_name list-title">
				<EllipsesControl
					id="teammembername"
					content={userName}
					tooltip={userName}
					isTooltipAvailable
				/>
			</td>
			<td className="user_initial list-title">
				{props.data && props.data.userIntial}{' '}
			</td>
			<td className="user_role list-title">
				{currentResource.roleType[userRoleId]}{' '}
			</td>
			<td className="user_email list-title">
				<EllipsesControl
					id="emailaddress"
					content={props.data && props.data.email}
					tooltip={props.data && props.data.email}
					isTooltipAvailable
				/>
			</td>
			<td className="user_contact list-title">
				{props.data && props.data.contactNumber}
			</td>
		</tr>
	);
};

export default ViewAccessItem;
