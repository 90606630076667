import axios from 'axios';
import {MsalService} from '@ey/voltron-core';

/**
 * Redux Middleware for getting login hint cookie from server
 * This middleware will be used to get/set login hint cookie (if available on server) and set user context state
 * This context used by authentication middleware in order to perform silent SSO
 */
const loginContextMiddleware = (config) => (store) => (next) => (action) => {
	if (action.type != 'HOME_LOGIN') {
		return next(action);
	}

	//skip and pass action to next middleware if user is logged in already
	const authService = new MsalService(config);
	if (authService.isLoggedIn()) {
		return next(action);
	}

	return axios
		.get('/getloginHint')
		.then(function (response) {
			console.log(
				'$loginContextMiddleware - Get login hint cookie',
				response.data
			);
			if (response.data) {
				authService.setLoginHint(response.data);
			}
		})
		.catch((error) => {
			console.log(
				'$loginContextMiddleware - Error in getting login hint cookie',
				error
			);
		})
		.finally(() => {
			return next(action);
		});
};

export default loginContextMiddleware;
