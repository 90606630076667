import React from 'react';
import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
	MotifFormField,
	MotifSearch,
	MotifButton,
	MotifErrorMessage
} from '@ey-xd/motif-react';
import {pagingOptions, reportFormateType} from '../../../util/uiconstants';
import {labels} from '../../../util/uiconstants';
import AccessDeniedMessage from '../../Common/AccessDeniedMessage/AccessDeniedMessage';
import DocumentsTable from './documentstable/documentstable';
import {getAdminEngagementDocuments, resetAdminEngagementDocuments} from '../../../actions/admin/documentsactions';
import Pagination from '@ey/pager';
import {getMeCall} from '../../../actions/admin/mepermissionsactions';
import styled from 'styled-components';
import env from '../../../util/env';
import Loader from '../../Common/Loaders/loadingindicator';
import {downloadReport} from '../../../actions/admin/reportaction';
import moment from 'moment';
import {SVGICONS} from '@ey/icons';
import Toast from '../../Common/Toast/Toast';
import {getLocalizedAPIError} from '../common/util';
import {clearErrors} from '../../../actions/erroractions';
import {setIsModalOpen} from '../../../actions/modalstateactions';

const Wrapper=styled.section`
margin-top: 5rem;

.toastMessage {
	width: 100% !important;
	position: relative;
	.motif-toast {
		position: absolute;
		z-index: 9999;
	}
	.motif-toast-text-container {
		font-size: 1.2rem;
	}
}

.loadingstyle{
	height: calc(100vh - 22rem) !important;
}

.documentaddon{
	width: 100%;
	margin: 3rem 0 1rem 0;
	display: flex;
	
	.search{
		display: inline-block;
		margin-right: 1rem;
		.canvas-core-search {
			width: 25rem;
			.motif-input{
				font-size: 1.4rem;
				&::placeholder{
					font-size: 1.4rem;
				}
			}
		}
	}	
}

.totaldocument{
	font-size: 1.4rem;
	margin-right: auto;
	display: inline-block;
	label{
		margin-right: 0.5rem;
	}
}

.export{
	display: inline-block;
	a{
		font-size: 1.4rem;
		font-weight: bold;
		vertical-align: text-bottom;
		color: ${(props) => props.theme.color[`blue600yellow400`]};
		&:hover{
			text-decoration: underline;
			cursor: pointer;
		}
	}
	svg{
		width: auto;
		height: 2rem;	
		margin-right: 0.2rem;
		fill: ${(props) => props.theme.color[`black`]};
	}
}

.fieldholder{
	width: 100%;
	margin-top: 1rem;
    display: flex;
	.engid{		
		width: 100%;	
		margin-top: 2rem;
		display: flex;

		.wrapper{
			width: 35rem;
			display: inline-block;
			margin-right: 2rem;

			.optional{
				font-size: 1.2rem;
				font-weight: normal;
			}
		}

		.motif-form-field{
			margin-bottom: 0;
		}

		.motif-search-input-wrapper {
			.motif-input{
				font-size: 1.4rem;
				height: 4.5rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				&::placeholder{
					font-size: 1.4rem;
				}
			}

			.motif-search-input-icon{
				display: none;
			}

			.motif-typeahead-close-button{
				margin-top: 0.6rem;
			}
		}

		.motif-error-message {
			font-size: 1.4rem;
			margin-top: 0.5rem;
			svg{
				width: 1.8rem;
				height: 1.8rem;
			}
		}

		h5{
			width: 100%;
			font-size: 1.4rem;
			font-weight: bold;
		}
	}
	.getdocuments {
		padding-top: 3.6rem;

		.motif-button{
			display: inline-block;
			height: 4.5rem;
			font-size: 1.4rem;
			margin-right: 2rem;
			padding: 0.5rem 1rem;
		}
	}
}

.pagerholder {
	margin: 0 auto;
	width: calc(100vw - 0.5rem);
	position: absolute;
	bottom: 0rem;
	background: ${(props) => props.theme.color[`white`]};

	.document-paging {
		font-size: 1.4rem;
		justify-content: flex-end;
		background: ${(props) => props.theme.color[`white`]};
		border-top: 1px solid ${(props) => props.theme.color[`grey200`]};

		.Dropdown-arrow {
			margin-top: 0.3rem;
		}
	}
}
`;

export default function DocumentsBase() {
	const userPermissions = useSelector((store) => store.userPermissions);
	const documents = useSelector((store) => store.adminEngagementDocumentsList);
	const [hasClickedSearch, setHasClickedSearch] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isMeCallSent, setIsMeCallSent] = useState(false);
	const [showRequiredValidationMessage, setShowRequiredEngIdValidationMessage] = useState(false);
	const [showInvalidEngIdValidationMessage, setShowInvalidEngIdValidationMessage] = useState(false);
	const [totalPages, setTotalPages] = useState();
	const [pageAndPageSize, setPageAndPageSize] = useState({
		page: 1,
		pageSize: pagingOptions.options[0] 
	});
	const [engIdSearchText, setEngIdSearchText] = useState('');
    const [docIdSearchText, setDocIdSearchText] = useState('');
    const [engIdToSearch, setEngIdToSearch] = useState('');
    const [docIdToSearch, setDocIdToSearch] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);

	const dispatch = useDispatch();
	
	useEffect(()=>{
		if (engIdSearchText.trim().length <= 0) {
			setDocIdSearchText('');
		}
	},[engIdSearchText])
	
	useEffect(() => {
		dispatch(resetAdminEngagementDocuments()).then(() => dispatch(getMeCall()).then(() => setIsMeCallSent(true)));
	}, []);

	useEffect(() => {
		if (hasClickedSearch) {
            setShowRequiredEngIdValidationMessage(true);
		}
	}, [hasClickedSearch]);

    useEffect(() => {
        if (hasClickedSearch) {
			getDocumentsList();
        }
    }, [pageAndPageSize, engIdToSearch, docIdToSearch, isMeCallSent]);

	useEffect(() => {
		if (documents) {
			setTotalPages(documents.pages)
		}
	}, [documents]);

	const downloadDocuments = () => {
		let engId = engIdSearchText?.trim();
		if (!isValidEngId(engId)) {
			return;
		}

		setIsLoading(true);
		let documentReportUrl = env.getURL('serviceUrlV2') +
			`admin/engagements/${engId}/documents?all=true`;

		var dateTime = moment(new Date()).format("MM-DD-YYYY-HH-mm-ss");
		dispatch(downloadReport(documentReportUrl, `DocumentDetails_${engId}_${dateTime}.${reportFormateType.xlsx}`)).finally(() => {setIsLoading(false)});
	}

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	useEffect(()=>{
		clearError(true);
	},[])
	const getDocumentsList = () => {
        let docIdQuerySearch = '';
		let docId = docIdSearchText?.trim();
        if (docId?.length > 0) {
            docIdQuerySearch = `&documentId=${docId}`
        }

		let engId = engIdSearchText?.trim();
		if (!isValidEngId(engId)) {
			return;
		}
		
		setIsLoading(true);
		let engDocumentsListUrl = env.getURL('serviceUrlV2') +
			`admin/engagements/${engId}/documents?page=${pageAndPageSize.page}&pageSize=${pageAndPageSize.pageSize}${docIdQuerySearch}`;

		dispatch(getAdminEngagementDocuments(engDocumentsListUrl))
            .catch((e) => {setErrorMessage(getLocalizedAPIError(e));
				setHasClickedSearch(false);
				clearError(true);
				})
			.finally(() => {
				clearError(true);
				setIsLoading(false);
			});
	};

	const isValidEngId = (engId) => {
		if (!engId || engId.length <= 0) {
			setHasClickedSearch(false);
			setShowRequiredEngIdValidationMessage(true);
			return false;
		}

		if (!Number(engId)) {
			setHasClickedSearch(false);
			setShowInvalidEngIdValidationMessage(true);
			return false;
		}

		return true;
	}

	useEffect(() => {
	}, [engIdSearchText]);

	function onGetResultButton() {
		setPageAndPageSize({
			page: 1,
			pageSize: pagingOptions.options[0]
		});
        setHasClickedSearch(true);
		setEngIdToSearch(engIdSearchText);
	}

    const resetAllData = () => {
		setHasClickedSearch(false);
		setShowInvalidEngIdValidationMessage(false);
		setShowRequiredEngIdValidationMessage(false);
		setEngIdSearchText('');
        setDocIdSearchText('');
		setEngIdToSearch('');
		setDocIdToSearch('');
		setPageAndPageSize({
			page: 1,
			pageSize: pagingOptions.options[0]
		});
        dispatch(resetAdminEngagementDocuments());
    }

	function hasAdminDocumentsPermission() {
		let documentsPermission = userPermissions?.permissions;
		return documentsPermission && documentsPermission?.canAccessDocuments;
	}

    const onDocIdSearchClick = () => {
		setPageAndPageSize({
			page: 1,
			pageSize: pagingOptions.options[0]
		});
        setHasClickedSearch(true);
        setDocIdToSearch(docIdSearchText);
    }

	const handlerOptionPage = (value) => {
		if (value !== pageAndPageSize.pageSize) {
			setPageAndPageSize({
				page: 1,
				pageSize: value
			});
		}
	};

	const paginationHandler = (value) => {
		if (pageAndPageSize.page !== value) {
			setPageAndPageSize((prevValue) => ({
				page: value,
				pageSize: prevValue.pageSize
			}));
		}
	};

	return (
		<Wrapper className="wrapper">
			<section className="mainResponsiveWrapper">
				<section
				className="toastMessage"
				style={{
					display:
						errorMessage && errorMessage.length > 0
							? 'block'
							: 'none'
				}}
				>
				<Toast
					variant="error"
					onClose={() => setErrorMessage(false)}
				>
					{errorMessage}
				</Toast>
				</section>
			</section>
			{hasAdminDocumentsPermission() ? (
				<section>
					<section className="mainResponsiveWrapper">
						<section className="fieldholder">
							<section className="engid">
								<section className="wrapper">
									<h5>{labels.engagementID}</h5>
									<MotifFormField>
										<MotifSearch
											value={engIdSearchText}
											title={engIdSearchText}
											clearButtonTitle={labels.clear}
											onChange={(event) => {
												setEngIdSearchText(event.target.value);
												setShowInvalidEngIdValidationMessage(false);
											}}
											onEnter={onGetResultButton}
											placeholder={labels.engagementID}
										/>
										{engIdSearchText.trim().length == 0 && showRequiredValidationMessage ? (
											<MotifErrorMessage>{labels.requiredField}</MotifErrorMessage>
										) : <></>}
										{showInvalidEngIdValidationMessage ? (
											<MotifErrorMessage>{labels.adminModuleinvalidEngagementId}</MotifErrorMessage>
										) : <></>}
									</MotifFormField>
								</section>
								<section className="wrapper">
									<h5>{labels.docListDocIdSearchLabel}<span className="optional"> {labels.optional}</span></h5>
									<MotifFormField>
										<MotifSearch
											placeholder={labels.docListDocIdSearchLabel}
											value={docIdSearchText}
											onChange={(e) => setDocIdSearchText(e.target.value)}
											onSearchButtonClick={onDocIdSearchClick}
											onClear={()=> setDocIdSearchText('')}
											onEnter={onDocIdSearchClick}
											clearHoverText={labels.clear}
											disabled={engIdSearchText?.trim().length <= 0}
											clearButtonTitle={labels.clear}
										/>
									</MotifFormField>
								</section>
								<section className="getdocuments button">
									<MotifButton
										aria-label="getdocuments"
										role="button"
										type="button"
										variant="primary-alt"
										onClick={onGetResultButton}
									>
										{labels.getDocuments}
									</MotifButton>
									<MotifButton
										aria-label="cleardocuments"
										role="button"
										type="button"
										variant="secondary"
										disabled={!(documents.totalEntityCount)}
										onClick={resetAllData}
									>
										{labels.clearResults}
									</MotifButton>
							</section>
						</section>
					</section>
						{ isLoading ? <Loader show view="blockView" styleName="loadingstyle" />
						: <section>
							{ documents?.data ?
								<section className="documentaddon">
									<section className="totaldocument">
										<section><label>{labels.docListTotalDocuments} </label><span>{documents.totalEntityCount}</span></section>
									</section>
									<section className="export">
										<SVGICONS
											role="button"
											styleName="medium"
											name="import-export-icon"
											hoverText={labels.docListExportBtn}
											onIconClick={downloadDocuments}
										/>
										<a onClick={downloadDocuments}>{labels.docListExportBtn}</a>
									</section>
								</section>
							: <></> }
							<section className="tableholder">
								{documents?.data ? (
									documents.totalEntityCount > 0 ? (
										<DocumentsTable
											data={documents.data}
										/>
									) : (<section className="norecord">{labels.noResultsFound}</section>)
								) : ( <></>
								)}
							</section>
						</section>}
					</section>
					<section className="pagerholder">
							<Pagination
								styleName="document-paging mainResponsiveWrapper"
								options_per_page={pagingOptions.options}
								default_dropdown_value={pageAndPageSize.pageSize}
								// showText={currentResource.common.pagination.show}
								currentPage={pageAndPageSize.page < 1 ? 1 : pageAndPageSize.page}
								TotalPages={totalPages < 1 ? 1 : totalPages}
								dropup
								onInputChange={(e) => {
									let inputValue = Number.parseInt(e.target.value);
									if (inputValue != pageAndPageSize.page && inputValue > 0) {
										paginationHandler(inputValue);
									}
								}}
								onDropdownChange={(e) => {
									handlerOptionPage(e.value);
								}}
								onFirstPageClick={() => {
									paginationHandler(1);
								}}
								onPrevPageClick={() => {
									paginationHandler(pageAndPageSize.page - 1);
								}}
								onNextPageClick={() => {
									paginationHandler(pageAndPageSize.page + 1);
								}}
								onLastPageClick={() => {
									paginationHandler(totalPages);
								}}
								dropdown_position="left"
							// iconHover={currentResource.common.pagination.iconHover}
							/>
					</section>
				</section>
			) : (
				<section className="message mainResponsiveWrapper">
					<AccessDeniedMessage
						permissionMesage={labels.insufficientPermission}
					/>
				</section>
			)}
		</Wrapper>
	);
}
