/**
 * spotfiredatacenterurlreducer.js
 * Created by calhosh on 12/17/2019.
 */

import * as types from '../actions/actiontypes';
import initialstate from './initialstate';

export default function spotfireDataCenterUrlReducer(
	state = initialstate.spotfireDataCenter,
	action
) {
	switch (action.type) {
		case types.GET_SPOTFIRE_DATACENTER_URL:
			return action.response;
		default:
			return state;
	}
}

export function formatSpotfireDataCenter(rawResponse) {
	let response = {};
	if (rawResponse && rawResponse.data) {
		response.engagementVersion = rawResponse.data.engagementversion;
		response.dataCenterUris = rawResponse.collections.dcuri;
	}

	return response;
}
