import {
	START_IDLE_TIMER,
	START_LOGOUT,
	SHOW_IDLETIME_POPUP,
	RESET_IDLE_TIMER,
	LOGOUT_USER
} from './actiontypes';

export function startIdleTimer() {
	return {
		type: START_IDLE_TIMER
	};
}

export function startLogout() {
	return {
		type: START_LOGOUT
	};
}

export function logoutUser() {
	return {
		type: LOGOUT_USER
	};
}

export function showIdletimePopup() {
	return {
		type: SHOW_IDLETIME_POPUP
	};
}

export function resetIdleTimer() {
	return {
		type: RESET_IDLE_TIMER
	};
}
