/**
 * Remember: always add new reducers to the /reducers/index.js file!
 * Also, add your initialState to './initialstate'!
 */
import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export function MyEngagementsV2Reducer(state = initialState.myEngagementsV2, action) {
    switch (action.type) {
        case types.LOAD_ENGAGEMENTS_V2:
            return action.response;
        default:
            return state;
    }
}
