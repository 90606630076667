import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

export default function CreateEditWorkspaceReducer(state = {}, action) {
	switch (action.type) {
		case types.CREATE_WORKSPACE:
			return action.response;
		default:
			return state;
	}
}
