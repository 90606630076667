import React, {useState} from 'react';
import EditWorkspace from './createeditworkspace';
import EditEngagement from './editengagement';
import {labels, standardStrings} from '../../../util/uiconstants';
import {useHistory} from 'react-router-dom';
import WizardWrapper from '../Common/WizardWrapper';
import * as QueryString from 'query-string';
import styled from 'styled-components';

const EditEngagementWizardLoader = (props) => {
	const history = useHistory();

	const params = QueryString.parse(location.search);

	const [activeStepIndex, setActiveStepIndex] = useState(
		params.engagementid ? 1 : 0
	);
	const [step1NextChng, setstep1NextChng] = useState(1);
	const [step1FinishChng, setstep1FinishChng] = useState(1);
	const [step2FinishChng, setstep2FinishChng] = useState(1);
	const [domain, setDomain] = useState({
		domainChooseExisting: false,
		domainWorkspaceId: params.workspaceid,
		domainClientId: standardStrings.EMPTY,
		domainWorkspaceName:
			(location.state && location.state.workspaceName) || standardStrings.EMPTY,
		domainLocation: null,
		domainClientName: standardStrings.EMPTY,
		domainClientCode: standardStrings.EMPTY,
		domainEngagementId: params.engagementid || -1
	});
	const [wizardSteps, setWizardSteps] = useState([
		{
			stepName: labels.step01,
			stepDescription: labels.editworkspacewizheader,
			active: true
		},
		{
			stepName: labels.step02,
			stepDescription: labels.editengagementwizheader,
			active: false
		}
	]);

	let oldPath = props.location?.state?.oldPath
		? props.location?.state?.oldPath
		: '';
	const wizLoaderName = labels.editwizheader;

	const mode = {
		create: 'create',
		edit: 'edit'
	};

	const buttons = [
		{
			label: activeStepIndex == 0 ? labels.cancel : labels.back,
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						navigateBack();
						break;
					case wizardSteps.length - 1:
						setActiveStepIndex((prev) => prev - 1);
						break;
				}
			},
			isVisible: true,
			disabled: false
		},
		{
			label: labels.saveclose,
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						setstep1FinishChng((prev) => prev + 1);
						break;
					case wizardSteps.length - 1:
						setstep2FinishChng((prev) => prev + 1);
						break;
				}
			},
			isVisible: true,
			disabled: false
		},
		{
			label: labels.savecontinue,
			onClick: () => {
				setstep1NextChng((prev) => prev + 1);
			},
			isVisible: activeStepIndex == 0 ? true : false,
			disabled: activeStepIndex == 0 ? false : true
		}
	];

	const navigateBack = () => {
		history.push(oldPath);
	};

	const updateWizardData = (fieldName, fieldValue) => {
		setDomain((prev) => {
			return {
				...prev,
				[fieldName]: fieldValue
			};
		});
	};

	const getWizardData = (domainFieldName) => {
		return domain[domainFieldName];
	};

	const advanceToNextStep = () => {
		setActiveStepIndex((prev) => prev + 1);
	};

	const step1 = () => {
		return (
			<EditWorkspace
				isComponentValid={() => {}}
				updateWizardData={updateWizardData}
				nextClicked={step1NextChng}
				onFinishClick={step1FinishChng}
				getDependencyData={getWizardData}
				mode={mode.edit}
				advanceToNextStep={advanceToNextStep}
				closeWizard={navigateBack}
			/>
		);
	};

	const step2 = () => {
		return (
			<EditEngagement
				isComponentValid={() => {}}
				updateWizardData={updateWizardData}
				onFinishClick={step2FinishChng}
				getDependencyData={getWizardData}
				mode={mode.edit}
				closeWizard={navigateBack}
			/>
		);
	};

	return (
		<>
			<StyledWizardWrapper>
				<WizardWrapper
					wizLoaderName={wizLoaderName}
					currentStepIndex={activeStepIndex}
					wizardSteps={wizardSteps}
					setWizardSteps={setWizardSteps}
					buttons={buttons}
					theme={
						document
							.getElementsByTagName('body')[0]
							.getAttribute('motif-theme') === 'light'
							? null
							: 'dark'
					}
					stepContent={[step1(), step2()]}
				/>
			</StyledWizardWrapper>
		</>
	);
};

export default EditEngagementWizardLoader;

const StyledWizardWrapper = styled.section`
	width: 100%;
	padding: 0 4rem;
	header h5 {
		font-size: 2rem;
		font-weight: bold;
		width: 100%;
		padding: 1.5rem 0;
	}
	.componentBodyWrapper {
		height: calc(100vh - 28rem);
	}
`;
