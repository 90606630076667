export const EngUserModelPatchFavorites = function(engId, isFavorite, opType) {
	return {
		collections: {
			engagements: [
				{
					data: {
						id: engId,
						engagementId: engId
					},
					id: engId
				}
			]
		},
		data: {
			isfavorite: isFavorite,
			operationType: opType
		}
	};
};
