import React from 'react';
import ManageRolesHeader from './managerolesheader';
import RoleList from './rolelist';
import {labels} from '../../../../../util/uiconstants';
import styled from 'styled-components';
import Loader from '@ey/loader';

function ManageRolesTable(props) {
	return (
		<Headerbody className="managerolepageview mainResponsiveWrapper">
			{props.isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<section className="userlistwrapper manageaccessheader">
					<ManageRolesHeader />
					{props.data?.length > 0 ? (
						<RoleList list={props.data} onDeleteClick={props.onDeleteClick} />
					) : (
						<section className="contentHistoryPlaceHolderText norecord">
							{props.rolesExist
								? labels.noRoleResults
								: labels.manageAccessNoRolesAdded}
						</section>
					)}
				</section>
			)}
		</Headerbody>
	);
}

const Headerbody = styled.section`
	// common for table UI start

	.userlistwrapper {
		display: inline-block;
		width: 100%;

		&.manageaccessheader {
			.userlist-header {
				.userlistitem {
					width: 24%;
				}
				.delete {
					width: 5%;
					text-align: center;
				}
			}
			.userlist-body {
				height: calc(100vh - 27rem);
			}
		}

		.contentHistoryPlaceHolderText {
			border: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
		}

		.manageaccessitemview {
			width: 100%;
			font-size: 1.4rem;
			display: flex;
			border-collapse: collapse;
			align-items: center;
			justify-content: center;
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};

			&.inactive mgt-person {
				color: ${(props) => props.theme.color[`grey300`]};
			}

			&:not(.inactive) .userName {
				color: ${(props) => props.theme.color[`grey500`]};
			}

			.userlistitem {
				width: 24% !important;
			}

			.grid {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-gap: 0 10px;

				&.delete {
					text-align: center;
					width: 5% !important;
					.description {
						display: inline-flex;
					}
				}
			}

			&:last-child {
				border-bottom: none;
				padding-bottom: 1rem;
			}
		}

		.userlist-header {
			width: 100%;
			display: inline-block;
			.userlistheader {
				width: 100%;
				font-size: 1.4rem;
				color: ${(props) => props.theme.color[`grey450`]};
				font-weight: bold;
				display: flex;
				border-collapse: collapse;
				background: ${(props) => props.theme.color[`grey100`]};
				border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};

				.userlistitem:last-child {
					// text-align: center;
					// width: 1%;
				}
			}
		}

		.userlist-body {
			overflow: auto;
			display: inline-block;
			width: 100%;
			border: 0.1rem solid ${(props) => props.theme.color[`grey200`]};

			.userlistholder {
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
			}
		}

		.userlist-header,
		.userlist-body {
			.userlistitem {
				padding: 1rem;
				flex: 1 1 auto;
				width: 15%;
				vertical-align: middle;
				display: block;

				.description {
					display: flex;

					svg.delete {
						cursor: pointer;
					}
				}

				.ellipses {
					vertical-align: middle;
					line-height: normal;
				}

				&.date,
				&.status {
					width: 6%;

					.ellipses {
						vertical-align: middle;
						line-height: normal;
					}
				}

				.engname {
					width: 100%;
					display: inline-grid;

					.ellipses {
						line-height: normal;
					}
				}
			}
		}
	}

	// common for table UI end
`;

export default ManageRolesTable;
