import * as types from './actiontypes';

export function getTemplateEngagementDetailsForImport(url, resource) {
	return {
		type: types.GET_ENG_DETAILS_FOR_IMPORT,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}
