import React, {useRef, useEffect} from 'react';

export const usePrevious = (value) => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export const useDidMountEffect = (func, deps) => {
	const didMount = useRef(false);
	useEffect(() => {
		if (didMount.current) {
			func();
		} else {
			didMount.current = true;
		}
	}, deps);
};
