import React from 'react';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';
const Wrapper = styled.section`
	display: inline-flex;
	margin-left: auto;
	line-height: 4.8rem;

	h3 {
		margin: auto;
	}

	svg {
		height: 4.8rem;
		margin-right: 0.5rem;
	}
`;
function PageHeader(props) {
	return (
		<Wrapper classname="title">
			<SVGICONS styleName="large" name={props.iconName} />
			<h3>{props.moduleName}</h3>
		</Wrapper>
	);
}

export default PageHeader;
