import React from 'react';
import EngagementList from '../Engagements/engagementlist';
import EllipsesControl from '@ey/ellipses-control';
import {SVGICONS} from '@ey/icons';
import Loader from '@ey/loader';
import {labels} from '../../util/uiconstants';
import ContextMenu from '../Common/contextMenu';
import {EngagementAccessType} from '../../util/enumerations';

class WorkSpaceItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {open: false};
		this.togglePanel = this.togglePanel.bind(this);
	}

	togglePanel(e) {
		this.setState({open: !this.state.open});
	}

	render() {
		const workspaceName = this.props.data && this.props.data.workspacedesc;
		const archivedEngagementList = this.props.archivedEngagementList;
		const isWorkspaceEditWizardDisabled =
			this.props.collections.engagements.every(
				(engagement) =>
					engagement.data.engagementaccesstypeid ===
					EngagementAccessType.IndependenceOnly
			);
		return (
			<section>
				<section
					className="workspace-list"
					onClick={(e) => this.togglePanel(e)}
				>
					<span title={!this.state.open ? labels.expand : labels.collapse}>
						<SVGICONS
							hoverText={!this.state.open ? labels.expand : labels.collapse}
							styleName="large"
							id="expandicon"
							name={!this.state.open ? 'plus' : 'minus'}
						/>
					</span>
					<h6>
						<EllipsesControl
							id=""
							content={workspaceName}
							tooltip={workspaceName}
							isTooltipAvailable
						/>
					</h6>
					<section className="workspaceedit">
						{!isWorkspaceEditWizardDisabled &&
						!archivedEngagementList &&
						!this.props.isReviewWorksapces ? (
							<ContextMenu
								iconHover={labels.settings}
								iconName={'settings'}
								iconstyleName={'large'}
								menuItems={() => [
									{
										linkTo: {
											pathname: '/editworkspace',
											search: '?workspaceid=' + this.props.id
										},
										label: labels.contextMenuEdit
									}
									//,
									//{linkTo: '', label: labels.contextMenuDelete}
								]}
							/>
						) : (
							''
						)}
					</section>
				</section>
				{this.state.open ? (
					<section className="eng-list workspacelist-view">
						{this.props.collections.engagements ? (
							this.props.collections.engagements.map((engagement) => (
								<EngagementList
									workspaceID={this.props.id}
									workspace={this.props.data}
									engagements={engagement}
									clientId={this.props.clientId}
									clientName={this.props.clientName}
									clientCode={this.props.clientCode}
									refreshDashboard={this.props.refreshDashboard}
									archiveretryshortcutkey={this.props.archiveretryshortcutkey}
								/>
							))
						) : (
							<Loader />
						)}
					</section>
				) : null}
			</section>
		);
	}
}

export default WorkSpaceItem;
