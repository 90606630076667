import React, {useEffect, useState} from 'react';
import {
	MotifSelect,
	MotifOption,
	MotifLabel,
	MotifFormField
} from '@ey-xd/motif-react';
import {labels, engagementFilterTypes} from '../../../util/uiconstants';
import styled from 'styled-components';

const FilterEngagements = (props) => {
	const {setSearchType, searchType, isReviewEngagementAvailable} = props;
	const [selectedFilter, setSelectedFilter] = useState(
		searchType ? searchType : engagementFilterTypes.ALL
	);

	const getFilters = () => {
		let filters = [
			{
				value: engagementFilterTypes.ALL,
				label: labels.filterEngagements.all
			},
			{
				value: engagementFilterTypes.MY_FAVORITES,
				label: labels.filterEngagements.favorite
			}
		];

		if (isReviewEngagementAvailable) {
			filters.push({
				value: engagementFilterTypes.REVIEW_ENGAGEMENTS,
				label: labels.filterEngagements.review
			});
		}

		return filters;
	};

	const filterOptions = getFilters();

	useEffect(() => {
		setSelectedFilter(searchType);
	},[searchType])

	const handleFilterChange = (newFilter) => {
        if(newFilter){
            setSelectedFilter(newFilter);
            setSearchType(newFilter);
        }
    }
    
    return (
		<>
			<StyledFilterEngagements>
				<MotifFormField>
					<MotifLabel>{labels.filterEngagementsLabel}</MotifLabel>
					<MotifSelect
						value={selectedFilter}
						onChange={(newFilterSelected) =>
							handleFilterChange(newFilterSelected)
						}
						key={selectedFilter}
					>
						{filterOptions.map((filterItem) => (
							<MotifOption value={filterItem.value}>
								{filterItem.label}
							</MotifOption>
						))}
					</MotifSelect>
				</MotifFormField>
			</StyledFilterEngagements>
		</>
	);
};

export default FilterEngagements;

const StyledFilterEngagements = styled.section`
	width: 16%;
	display: inline-block;
	.motif-form-field-select-input .motif-label {
		font-size: 1.2rem;
		font-weight: 100;
		line-height: 0.5rem;
	}
	.motif-select-input {
		font-size: 1.4rem;
		font-weight: 400;
		svg {
			width: 2rem;
			height: 2rem;
		}
	}
	.motif-option {
		font-size: 1.2rem;
	}
`;
