import styled from 'styled-components';
import {color} from '@ey/styled-theme';

const Createeditstyle = styled.section`
       width: 100%;

       & .createeditengagement .clientworkspaceDetails {
              padding: 0 0 1rem 0;
              border-bottom: 0.1rem solid ${(props) =>
								props.theme.color[`grey235`]};
              width: 100%;

              & > div.form-group {
                      line-height: normal;
                      height: auto;
                      display: inline-block;
                      width: 100%;
                      margin:0;
              }

              & > div.form-group p{
                     width:80%;
              }

              & > div.form-group p,
              & > div.form-group label {
                     padding: 0;
                     margin: 0;
                     min-height: auto;
                     line-height: normal;
                     margin-right: 0.5rem;
                     float: left;
              }
       }

       & .createeditengagement .addengagement {
              width: 95%;
              border-bottom: 0.1rem solid ${(props) =>
								props.theme.color[`grey235`]};
              margin-bottom: 2rem;

              & .btn {
                      margin: 0 0 2rem 0;
                      font-weight:bold;

                      &:active{
                             box-shadow:none;
                      }
              }
       }

       & .createeditengagement {
              display: inline-block;
              width: 100%;
        
              & .createengagement {
                    
                      padding-top:2rem;

                      & div.form-group:nth-child(1) {
                             & span.input-group {
                                    width: 60rem;
                             }
                      }

                      & .addnewengagement {
                             display: flex;
                             & input[type='radio'] {
                                    vertical-align: middle;
                                    margin: 0.5rem;
                             }
                      }

                      & .addnewengagement div.form-group:nth-child(1) {
                             float: left;
                             & span.input-group {
                                    width: 40rem;
                             }
                      }

                      & .addnewengagement .clientname div.form-group:nth-child(1) {
                             float: left;
                             & span.input-group {
                                    width: 60rem;
                                    margin-left: 2rem;
                             }
                      }

              & .text-input{
                      display: flex;
                      width:40rem;
                      margin:0 3.5rem 2rem 0;
                      .form-group {
                             width: 100%;
                      }
              }

              & .motif-form-field {
                      display:inline-block;
                      width:40rem;
                      margin-right:3.5rem;
                      margin-bottom:2rem;

                      & .motif-date-picker{
                             border:0.1rem solid ${(props) =>
																props.theme.color[`grey300`]};
                             padding:1.8rem 1rem 0.8rem 1rem;
                      }

                      & .motif-label.motif-label-for-text{
                             color:${(props) => props.theme.color[`grey500`]};
                             font-weight:normal;
                      }
              }
              
              }

              

              

              & .form-group {
                      margin: 0;
                      padding: 0;
                      display: inline;
              }

              & .form-group label.control-label {
                      vertical-align: bottom;
                      display: inline;
              }

              & > .form-group * {
                      font-size: 1.6rem;
                      line-height: normal;
                      padding: 0;
                      margin: 0;
              }

              & .form-control-static {
                      float: right;
                      line-height: 4.8rem;
                      font-size: 1.4rem;
              }

              & div.form-group {
                      display: inline-flex;
                      font-size: 1.4rem;
                      height: 7.5rem;
                      margin: 0 1em 1em 0;

                      & .input-label {
                             line-height: normal;
                      }

                      & span.input-group {
                             float: left;
							 display:inline-block;
                             & input.form-control {
                                    margin: 0 2rem 0 0;
                                    vertical-align: middle;
                                    border: 0.1rem solid ${(props) =>
																			props.theme.color[`grey300`]};
                                    font-size: 1.4rem;

                                    &[disabled]:not([type='radio']):not([type='checkbox']){
                                           opacity:1;
                                           color: ${(props) =>
																							props.theme.color[`grey250`]};
                                           border:0.1rem solid ${color(
																							`grey235`
																						)};
                                    }
                             }
                      }

                      & > section {
                             width: 60rem;
                             float: left;
                             margin-right: 2rem;

                             & .select-label {
                                    line-height: normal;
                                    color: ${(props) =>
																			props.theme.color[`grey500`]};
                             }

                             & .Select-control {
                                    margin: 0 2rem 0 0;
                                    vertical-align: middle;
                                    border: 0.1rem solid ${(props) =>
																			props.theme.color[`grey300`]};
                                    line-height: 4.6rem;
                             }
                      }

                      & > section {
                             width: 40rem;
                             float: left;

                             & .Select-control {
                                    border: 0.1rem solid ${(props) =>
																			props.theme.color[`grey300`]};
                             }
                      }
              }

              /*datepicker start*/
              & .zeus-datepicker{
				& .text-input{
					display:inline-block;
					width:40rem;
					margin:0 3.5rem 4.1rem 0;
					height:4.8rem;
					vertical-align:middle;
					&.archivedeadlinedate{
						& label.control-label{
								display :none;
						}
						input.form-control {
							@media screen and (max-width: 1366px) {
								padding: 12px 10px 0 10px !important;
							}						
						}
						& .input-group input.form-control[disabled]:not([type='radio']):not([type='checkbox']),
						& .input-group input.form-control:not([type='radio']):not([type='checkbox'])
						{
								border:0;
						}
					}
                }	
              }
              /*datepicker start*/
       }
`;

export default Createeditstyle;
