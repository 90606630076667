import initialstate from '../../initialstate';
import * as types from '../../../actions/actiontypes';

export function messagingReducer(state = initialstate.messaging, action) {
	switch (action.type) {
	    case types.GET_ALL_MESSAGES:
	        state = action.response;
	        return state;
	    default:
	        return state;
	}
};

export function getMessageByIdReducer(state = initialstate.messageById, action) {
	switch (action.type) {
		case types.GET_MESSAGE_BY_ID: {
			state = action.response;
			return state;
		}
		case types.RESET_MESSAGE_DETAILS: {
			return initialstate.messageById;
		}
		default:
			return state;
	}
};