import * as types from './actiontypes';

export function createCRP(url, resource) {
	return {
		type: types.POST_CRP,
		meta: {
			api: {
				url,
				method: 'POST',
				data: resource
			}
		}
	};
}

export function patchCRP(url, resource) {
	return {
		type: types.PUT_CRP,
		meta: {
			api: {
				url,
				method: 'PUT',
				data: resource
			}
		}
	};
}

export function getCRP(url, resource) {
	return {
		type: types.GET_CRP,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}
