import * as types from './actiontypes';

export function getRollForwardEngagements(url, resource) {
	return {
		type: types.GET_ROLLFORWARDENGAGEMENTS,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}

export function setRollForwardEngagementData(engagementData) {
	return {
		type: types.SET_ROLLFORWARD_ENGAGEMENT_DATA,
		payload: engagementData
	};
}
