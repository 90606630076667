/**
 * @module <%=name%>
 * Action creator for <%=name%> object
 */

import * as types from './actiontypes'; //TODO: uncomment

export function setCurrentActiveTabInMyEngSection(newlySelectedTabValue) {
	return {
		type: types.SET_CURRENT_ACTIVETAB_IN_MYENG,
		payload: newlySelectedTabValue
	};
}

export function setCurrentGroupingTypeInMyEngSection(
	newlySelectedGroupingType
) {
	return {
		type: types.SET_CURRENT_GROUPING_TYPE_IN_MYENG,
		payload: newlySelectedGroupingType
	};
}
export function searchEngagementState(searchText) {
	return {
		type: types.SEARCH_ENGAGEMENT,
		payload: searchText
	};
}
export function refreshDashboardGlobal(
	refreshType
	// 0 : just refresh - retain all params as is
	// 1 : reset pagination and search
) {
	return {
		type: types.REFRESH_DASHBOARD,
		payload: refreshType
	};
}
