import React from 'react';
import EngagementUserItem from './engagementuseritem';

function EngagementUserList(props) {
	return (
		<section id="tablebodycontainer" className="engagement-userlist-body">
			<section className="userlistholder">
				{props?.dataItems &&
					props?.dataItems.map((item, index) => (
						<EngagementUserItem
							key={index + '' + item.userId}
							data={item}
							userStatusesOptions={props?.userStatusesDDOptions}
							independenceStatuseOptions={props?.independenceStatusesDDOptions}
							permissions={props?.editPermission}
							setItemToEditUserPermissions={props.setItemToEditUserPermissions}
							setShowEditUserPermissions={props?.setShowEditUserPermissions}
							engagementStatusId={props?.engagementStatusId}
						/>
					))}
			</section>
		</section>
	);
}

export default EngagementUserList;
