import * as types from '../actions/actiontypes';
import initialState from './initialstate';

export default function singleEngagementReducer(
	state = initialState.singleEngagement,
	action
) {
	switch (action.type) {
		case types.GET_ENGAGEMENT:
			return {
				...action.response,
				gacomponentguid: action.meta.api.data.gacomponentguid,
				viewinstruction: action.meta.api.data.viewinstruction,
				isCompletitionNeeded: false
			};
		case types.GET_ENGAGEMENT_FOR_COMPLETITION_URL:
			return {
				...action.response,
				isCompletitionNeeded: true
			};
		default:
			return state;
	}
}
