import * as types from './actiontypes';

/**
 * make a post call for request access
 */
export function postRequestAccess(url, resource) {
	return {
		type: types.POST_REQUEST_ACCESS,
		meta: {
			api: {
				url,
				method: 'POST',
				data: resource
			}
		},
		resource: resource
	};
}
