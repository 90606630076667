import styled from 'styled-components';

//engagement main page
const Headerbody = styled.section`
	// common for table UI start

	.userlistwrapper {
		display: inline-block;
		width: 100%;

		.contentHistoryPlaceHolderText {
			border: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
		}

		.permissionitemview {
			width: 100%;
			font-size: 1.4rem;
			display: flex;
			border-collapse: collapse;
			align-items: center;
			justify-content: center;
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};

			&.inactive mgt-person {
				color: ${(props) => props.theme.color[`grey300`]};
			}

			&:not(.inactive) .userName {
				color: ${(props) => props.theme.color[`grey500`]};
			}

			.grid {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-gap: 0 10px;

				&:last-child {
					text-align: center;
					.description {
						display: inline-flex;
					}
				}
			}

			&:last-child {
				border-bottom: none;
				padding-bottom: 1rem;
			}
		}

		.userlist-header {
			width: 100%;
			display: inline-block;
			.userlistheader {
				width: 100%;
				font-size: 1.4rem;
				color: ${(props) => props.theme.color[`grey450`]};
				font-weight: bold;
				display: flex;
				border-collapse: collapse;
				background: ${(props) => props.theme.color[`grey100`]};
				border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};

				.userlistitem:last-child {
					// text-align: center;
					// width: 1%;
				}
			}
		}

		.userlist-body {
			overflow: auto;
			height: calc(100vh - 26.5rem);
			display: inline-block;
			width: 100%;
			border: 0.1rem solid ${(props) => props.theme.color[`grey200`]};

			.userlistholder {
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
			}
		}

		.userlist-header,
		.userlist-body {
			.userlistitem {
				padding: 1rem;
				flex: 1 1 auto;
				width: 12%;
				vertical-align: middle;
				display: block;

				&.header{
					display: inline-block;
					white-space:nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}

				.description {
					display: flex;
				}

				.ellipses {
					vertical-align: middle;
					line-height: normal;
				}

				&.delete {
					width: 4% !important;

					.editicon-button{
						button.edit-user-edit-btn{
							border: none;
							background: transparent;
							cursor: pointer!important;
							outline: none;
							transition: 0.2s ease-in-out opacity;
				
							svg{
								fill: ${(props) => props.theme.color[`black`]};
							}
				
							&[disabled] {
								opacity: 0.2;
								pointer-events: none;
							}
						}
				
						.deleteicon{
							margin: -0.2rem 0 0 0;
							cursor: pointer;
						}	
					}
				}

				&.email{
					width: 18%;
				}

				&.userid{
					width: 22%;
				}

				&.status {
					width: 7%;
				}

				&.gui{
					width: 14%;
				}

				&.title{
					width: 12%;

					// .ellipses {
					// 	vertical-align: middle;
					// 	line-height: normal;
					// }
				}

				.detail {
					width: 100%;
					display: inline-grid;

					.delete {
						cursor: pointer;
					}

					.ellipses {
						line-height: normal;
					}
				}
			}
		}
	}

	// common for table UI end
`;

export default Headerbody;
