import React from 'react';
import {labels} from '../../../../util/uiconstants';

function PermissionGroupUsersHeader() {
	return (
		<section className="userlist-header">
			<section className="userlistheader">
				<section className="userlistitem header">{labels.firstname}</section>
				<section className="userlistitem header">{labels.lastname}</section>
				<section className="userlistitem title header">
					{labels.permissionGroupMembersTitle}
				</section>
				<section className="userlistitem email header">{labels.email}</section>
				{/* <section className="userlistitem">
					{labels.permissionGroupMembersPhone}
				</section> */}
				<section className="userlistitem gui header">
					{labels.permissionGroupMembersGui}
				</section>
				<section className="userlistitem userid header">
					{labels.permissionGroupMembersUserId}
				</section>
				<section className="userlistitem status header">
					{labels.permissionGroupMembersAdStatus}
				</section>
				<section className="userlistitem delete header">{''}</section>
			</section>
		</section>
	);
}

export default PermissionGroupUsersHeader;
