import * as types from './actiontypes';

export function getEYUsersList(url, resource, engagementid) {
	return {
		type: types.GET_EYUSERS,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				},
				headers: {EngagementId: engagementid}
			}
		}
	};
}
