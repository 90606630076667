/**
 * ReportingHub.js
 * Created by calhosh on 12/10/2019.
 */

import React, {useState, useEffect} from 'react';
import ReactSelect from '@ey/react-select';
import {labels, reporting, urls} from '../../util/uiconstants';
import {useSelector, useDispatch} from 'react-redux';
import env from '../../util/env';
import {
	getEngagementMetaData,
	getSpotfireDataCenter
} from '../../actions/engagementsaction';
import {raiseJSValidationError} from '../../actions/erroractions';
import {formatEngagementsForReportingHubEngDropDown} from '../../reducers/engagementsreducer';
import {formatSpotfireDataCenter} from '../../reducers/spotfiredatacenterurlreducer';
import SummaryScreens from './SummaryScreens';
import DataExport from './DataExport';
import Loader from '@ey/loader';
import queryString from 'query-string';
import styled from 'styled-components';
import ProjectManagement from './ProjectManagement';
import { MotifIcon } from '@ey-xd/motif-react';
import { actionIcInfo24px } from '@ey-xd/motif-react/assets/icons';


const ReportingHub = (props) => {
	const {location, history} = props;

	const search =
		location && location.search ? location.search : window.location.search;

	const parsed = queryString.parse(search.toLowerCase());
	let engagementId =
		parsed && parsed.engagementid ? parsed.engagementid : undefined;

	const dispatch = useDispatch();

	const user = useSelector((state) => state.userSettings);
	const engagements = useSelector((state) => {
		return formatEngagementsForReportingHubEngDropDown(
			state.engagementMetaData
		);
	});
	const dataCenters = useSelector((state) => {
		return formatSpotfireDataCenter(state.spotfireDataCenter);
	});
	const language = useSelector((state) =>
		state.userSettings.data ? state.userSettings.data.userlanguageid : 1
	);
	const featureConfiguration = useSelector(
		(state) => state.featureConfiguration
	);

	const [loadReportViews, loadViews] = useState(false);
	const [selectedEngagement, setSelectedEngagement] = useState(engagementId);
	const [spotfireUri, setSpotfireUri] = useState(undefined);
	const [engagementVersion, setEngagementVersion] = useState(undefined);
	const [isInterim, setInterim] = useState(false);
	const [isGroupAudit, setGroupAudit] = useState(false);
	const [loading, setLoading] = useState(true);

	const loadEngagements = async () => {
		setLoading(true);
		if (user && user.id) {
			await dispatch(
				getEngagementMetaData(
					env.getURL(reporting.serviceURL) +
						'/' +
						`${urls.ENGAGEMENTMETADATA_URL}?filters=queryuserid eq ${user.id}` +
						",engagementstatusid eq '1,7,8'",
					{}
				)
			);
		}
		setLoading(false);
	};

	const engagementChange = async (value) => {
		setLoading(true);
		if (value && value.value && engagements && engagements.length > 0) {
			const currentEngagement = _.find(engagements, (eng) => {
				if (Number(value.value) === Number(eng.value)) {
					return eng;
				}
			});
			setSelectedEngagement(currentEngagement);
			if (currentEngagement) {
				setInterim(currentEngagement.isInterim);
				setGroupAudit(currentEngagement.isGroupAudit);
				loadViews(false);
				const query =
					env.getURL(reporting.serviceURL) +
					'/' +
					`${urls.LOCATIONS_URL}?filters=engagementid eq ${value.value}`;
				await dispatch(getSpotfireDataCenter(query));
			} else {
				dispatch(raiseJSValidationError(reporting.INVALID_ENG_ID));
				loadViews(false);
			}
		} else if (!value) {
			/*User clicked clear*/
			setSelectedEngagement(undefined);
			loadViews(false);
		}
		setLoading(false);
	};

	const showSelectedReports = () => {
		// make call to show report only if we have selected engagement
		if (selectedEngagement) {
			const found = _.find(dataCenters.dataCenterUris, (uri) => {
				return uri.data.locator_uritype === reporting.SPOTFIRE_URI_TYPE;
			});
			setEngagementVersion(dataCenters.engagementVersion);
			if (!found) {
				dispatch(raiseJSValidationError(reporting.REPORTING_NOT_AVAILABLE));
			} else {
				setSpotfireUri(found.data.locator_uri);
				loadViews(true);

				/*update url to show selectedEngagement*/
				if (Number(engagementId) !== selectedEngagement.value) {
					parsed.engagementid = selectedEngagement.value;
					history.push({
						search: queryString.stringify(parsed)
					});
				}
			}
		}
	};

	const loadDefaultEngagement = _.find(engagements, (eng) => {
		if (selectedEngagement) {
			if (Number(selectedEngagement.value) === Number(eng.value)) {
				return eng;
			}
		}
	});

	useEffect(() => {
		document.title = labels.reportingHubTitle;
			loadEngagements();
	}, []);

	useEffect(() => {
		if (dataCenters && dataCenters.dataCenterUris) {
			showSelectedReports();
		}
	}, [dataCenters]);

	useEffect(() => {
		if (engagements.length > 0) {
			if (dataCenters.dataCenterUris === undefined) {
				const urlEngagementId = {value: engagementId};
				engagementChange(urlEngagementId);
			}
		}
	}, [engagements]);

	useEffect(() => {
		if (selectedEngagement) {
			setLoading(true);
		}
	}, [selectedEngagement]);

	const accountOptionLabel = ({label}) => (
		<section className="selectOption">
			<section title={label} className="selectText">
				{label}
			</section>
		</section>
	);

	return (
		<StyledReportingHubContainer className="StyledReportingHubContainer">
			<section className="reportinghub-disablecontainer">
				<MotifIcon src={actionIcInfo24px} />
				<span className="reportinghub-disablemsg">{labels.reportinghubDisableMessage}</span>
			</section>


			{engagements && engagements.length > 0 && (
				<section className="StyledSelect">
					<ReactSelect
						id="engagementlistdd"
						name="engagementlistdd"
						defaultValue={loadDefaultEngagement}
						options={engagements}
						onChange={engagementChange}
						formatOptionLabel={accountOptionLabel}
						formLabel={labels.searchByEngagementNameOrClientId}
						isSearchable
						value={selectedEngagement}
						isClearable
						customClearIcon
						clearAllValuesLabel={labels.clearSearch}
						className="engagementlist-dropdown"
					/>
				</section>
			)}
			<span className="noteMessage">{labels.connectVPN}</span>
			<section className="ReportingContainer">
				{loading ? (
					<section className="loader">
						<Loader />
					</section>
				) : (
					<>
						{loadReportViews ? (
							<>
								<SummaryScreens
									key={selectedEngagement.value + 'C'}
									engagementId={selectedEngagement.value}
									spotfireUri={spotfireUri}
									language={language}
									engagementVersion={engagementVersion}
									isInterim={isInterim}
									isGroupAudit={isGroupAudit}
								/>
								<DataExport
									key={selectedEngagement.value + 'DE'}
									engagementId={selectedEngagement.value}
									spotfireUri={spotfireUri}
									language={language}
									engagementVersion={engagementVersion}
									isInterim={isInterim}
									isGroupAudit={isGroupAudit}
								/>
								<ProjectManagement
									key={selectedEngagement.value + 'PM'}
									engagementId={selectedEngagement.value}
									spotfireUri={spotfireUri}
									language={language}
									engagementVersion={engagementVersion}
									isInterim={isInterim}
									isGroupAudit={isGroupAudit}
									featureConfiguration={featureConfiguration}
								/>
							</>
						) : (
							loading !== true &&
							(selectedEngagement && selectedEngagement.value > 0 ? (
								<section className="DefaultText">
									{labels.reportsNotLoaded}
								</section>
							) : (
								<section className="DefaultText">
									{labels.selectEngagement}
								</section>
							))
						)}
					</>
				)}
			</section>
		</StyledReportingHubContainer>
	);
};

export default ReportingHub;

const StyledReportingHubContainer = styled.div`
	margin: 1rem 4rem;
	width: calc(100vw - 8rem);
	display: flex;
	flex: 1;
	overflow: hidden;
	flex-direction: column;
	padding: 0 4rem;
	margin: 0 auto;
	max-width: 2048px;
	.reportinghub-disablecontainer {
		display: flex;
		width: 100%;
		padding: 1rem;
		align-items: flex-start;
		gap: 0.6rem;
		background: ${(props) => props.theme.color[`white`]};
		box-shadow: 0px 0px 8px 0px ${(props) => props.theme.color[`grey250`]};
		margin-top: 2rem;
		.motif-icon  {
			width:2.4rem;
			height:2.4rem;
			color: ${(props) => props.theme.color[`grey400`]};
			svg {
				width:2.4rem;
				height:2.4rem;
				margin-top: -0.3rem;
			}
		}
		.reportinghub-disablemsg {
			font-size: 1.4rem;
			font-weight:300;
			line-height: 1.8rem;
		}
	}
	.StyledSelect {
		display: inline-block;
		width: 35%;
		margin-right: 2rem;
		margin-top: 2rem;
		.engagementlist-dropdown {
			.react-select-container .react-select__control {
				min-height: 4.5rem;
				background: ${(props) => props.theme.color[`white`]};
				border-color: ${(props) => props.theme.color[`grey500`]};
				&:hover {
					background: ${(props) => props.theme.color[`grey250`]};
				}
			}
			.react-select-container
				.react-select__control
				.react-select__dropdown-indicator {
				padding-top: 0;
				svg {
					width: 1.6rem;
					height: 1.6rem;
				}
			}
			.react-select-container
				.react-select__control
				.react-select__single-value {
				font-size: 1.4rem;
			}
			.react-select-container .react-select__menu .react-select__option {
				color: ${(props) => props.theme.color[`black`]};
			}
			.react-select-container .react-select__menu .react-select__option:hover,
			.react-select-container .react-select__menu .react-select__option:active,
			.react-select-container
				.react-select__menu
				.react-select__menu-list
				.react-select__option--is-focused,
			.react-select-container
				.react-select__menu
				.react-select__menu-list
				.react-select__option--is-selected {
				background: ${(props) => props.theme.color[`grey250`]};
			}
		}
		.engagementlist-dropdown {
			label {
				font-size: 1.2rem;
				font-weight: bold;
				top: 0.8rem;
			}
			&.floating-label {
				label {
					font-size: 0.85714rem;
					font-weight: bold;
					top: 0.07143rem;
				}
			}
		}
	}
	.StyledSelected {
		max-height: 4rem;
		display: flex;
		flex: 1;
		flex-direction: row;
		width: 35vw;
		margin-top: 2em;
		.form-group {
			width: 50vw;
		}
	}
	.noteMessage {
		text-align: right;
		font-size: 1.4rem;
		color: ${(props) => props.theme.color[`grey400`]};
	}
	
		
	.ReportingContainer {
		width: 100%;
		border: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
		display: flex;
		flex: 1;
		flex-direction: row;
		overflow: hidden;
		.loader {
			display: flex;
			align-items: center;
			margin: 0 50%;
		}
		.DefaultText {
			font-size: 1.4rem;
			color: ${(props) => props.theme.color[`grey400`]};
			text-align: center;
			display: flex;
			align-items: center;
			margin: 0 auto;
		}
		.ReportingWrapper {
			width: 33%;
			display: inline-block;
			vertical-align: top;
			border-right: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
			display: flex;
			flex: 1;
			flex-direction: column;
			overflow: auto;
			&:last-child {
				border: 0 none;
			}
			.ReportingHeader {
				font-weight: bold;
				padding: 1rem 2rem;
				height: 5.2rem;
				font-size: 1.6rem;
				svg {
					height: 2.4rem;
					width: 2.4rem;
					vertical-align: middle;
					margin-right: 0.5rem;
				}
			}
			.ReportingLinks {
				padding: 0 2rem 0 2.3rem;
				margin-bottom: 2rem;
				display: flex;
				flex: 1;
				flex-direction: column;
				overflow: auto;
				li {
					list-style: none;
					font-size: 1.4rem;
					a {
						display: block !important;
						line-height: initial;
						div {
							cursor: pointer;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
	.selectOption {
		display: flex;
		width: 100%;
		align-items: center;
		.selectText {
			display: inline-block;
			text-overflow: ellipsis;
			width: calc(100% - 2rem);
			overflow: hidden;
			margin-right: 1rem;
		}
		.doneInOptions {
			fill: ${(props) => props.theme.color[`grey300`]};
			margin-left: auto;
		}
	}
	.react-select__control {
		.selectText {
			color: ${(props) => props.theme.color[`grey500`]};
		}
	}
	.react-select-container .react-select__control .react-select__value-container:not(.react-select__value-container--is-multi) > div .react-select__input input {
		color: ${(props) => props.theme.color[`grey500`]} !important;
	}
`;
