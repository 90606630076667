import initialstate from "./initialstate";

export function userContextReducer(state = initialstate.userContext, action) {
    switch (action.type) {
        case 'SET_USERCONTEXT':
            return {
							...state,
							loginHint: action.loginHint,
						}; 
        default:
            return state;
    }
}