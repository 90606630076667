import React from 'react';
import PermissionGroupUserRow from './permissiongroupuserrow';

function PermissionGroupUsersList(props) {
	return (
		<section id="tablebodycontainer" className="customScrollbar userlist-body">
			<section className="userlistholder">
				{props.list.map((item, index) => (
					<PermissionGroupUserRow
						key={index + item}
						data={item}
						onDeleteClick={props.onDeleteClick}
					/>
				))}
			</section>
		</section>
	);
}

export default PermissionGroupUsersList;
