/**
 * @module CreateEditWorkspace
 * Component CreateEditWorkspace
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import { getCreateWorkspace, getCountriesDetails } from '../../../actions/createworkspaceaction';
import { patchCopyRequest } from '../../../actions/copyhubactions';
import { getNotifications } from '../../../actions/notificationsactions';
import env from '../../../util/env';
import Loader from '@ey/loader';
import {EYForm, FormTextInput, FormComboBox} from '@ey/form';
import {
	labels,
	standardStrings,
	urls
} from '../../../util/uiconstants';
import {useDidMountEffect} from '../../../util/customHooks';
import Utility from '../../../util/utils';

const Createeditworkspaceholder = styled.section`
	width: 100%;
	& .createeditworkspacewrapper {
		display: inline-block;
		width: 100%;
		& .editworkspacefooter {
			position: absolute;
			bottom: 10rem;

			& h6 {
				font-size: 1.4rem;
				font-weight: bold;
			}
		}

		& .createnewworkspace {
			& .form-group .select-value .select-label {
				font-size: 1rem;
			}

			& .clientName {
				height: 4.8rem;
				border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
				display: inline;
				padding: 0.6rem 0 0 1rem;
				color: ${(props) => props.theme.color[`grey500`]};
				opacity: 0.35;
			}
		}

		& .form-group {
			margin: 0;
			padding: 0;
			display: inline;
		}

		& .form-group label.control-label {
			vertical-align: bottom;
			display: inline;
		}

		& > .form-group * {
			font-size: 1.6rem;
			line-height: normal;
			padding: 0;
			margin: 0;
		}

		& .form-control-static {
			float: right;
			line-height: 4.8rem;
			font-size: 1.4rem;
		}

		& div.form-group:nth-child(1) {
			width: auto;
		}

		& div.form-group:nth-child(2) {
			width: auto;
			margin-left: 2rem;
		}

		& div.form-group:nth-child(3) .input-group {
			width: 82rem;
		}

		& div.form-group {
			display: inline-flex;
			width: 100%;
			font-size: 1.4rem;
			height: 7.5rem;
			margin: 0 0 1em 0;

			& .input-group .input-label {
				line-height: normal;
			}

			& span.input-group {
				width: 40rem;
				float: left;

				& input.form-control {
					margin: 0 2rem 0 0;
					vertical-align: middle;
					border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
					font-size: 1.4rem;
				}
			}

			& > section {
				width: 40rem;
				float: left;

				& .select-label {
					line-height: normal;
					color: ${(props) => props.theme.color[`grey500`]};
				}

				& .Select-control {
					margin: 0 2rem 0 0;
					vertical-align: middle;
					border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
					line-height: 4.6rem;
				}
			}

			& .select-value {
				width: 40rem;
				float: left;

				& .Select-control {
					border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
				}
			}
		}
	}
`;

const CreateEditWorkspace = (props) => {
	const { updateWizardData, isComponentValid, onFinishClick, isChkImportForReviewOnly, closeWizard } = props;
	const dispatch = useDispatch();
	const updatedClientName = useSelector(state => state.createEditWorkspace)
	const countriesDetailsData = useSelector(state => state.countriesDetails);
	const copyRequest = useSelector(state => state.copyHub.copyRequest || {}) 
	const featureConfiguration = useSelector(
		(state) => state.featureConfiguration
	);
	const userPermissions = useSelector((state) => state.userPermissions);
	const INVALID_CLIENT_ID = null;
	const workspaceId = 0;

	const countriesUrl =
		env.getURL('serviceUrl') +
		'/' +
		urls.COUNTRIES_URL +
		'?filters=languageid eq 1,defaultlanguage eq english';

	const clientsUrl = env.getURL('serviceUrl') + '/' + urls.CLIENTS_URL;

	const getNotificationsUrl =
		env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;

	const [isLoading, setIsLoading] = useState(false);
	const [clientCode, setClientCode] = useState(standardStrings.EMPTY);
	const [clientId, setClientId] = useState(INVALID_CLIENT_ID);
	const [clientName, setClientName] = useState(labels.workspacecodenameplaceholder);
	const [countryId, setCountryId] = useState(null);
	const [workspaceName, setWorkspaceName] = useState(standardStrings.EMPTY);
	const [validationTriggerCounter, setValidationTriggerCounter] = useState(0);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		const asyncGetDetails = async () => {
			await dispatch(getCountriesDetails(countriesUrl));
		};
		try {
			setIsLoading(true);
			asyncGetDetails();
		} finally {
			setIsLoading(false);
			updateWizardFromComponent();
		}
	},[]);

	useDidMountEffect(() => {
		if (updatedClientName && updatedClientName.data) {
			setClientId(updatedClientName.id)
			setClientName(updatedClientName.data.clientname)
		} else {
			setClientId(INVALID_CLIENT_ID)
			setClientName(labels.invalidclientcode)
		}
	},[updatedClientName])

	useDidMountEffect(() => {
		 const asyncFinishClick = async() => {
		 	try{
		 		setIsLoading(true);
		 		setIsSaving(true);
		 		await dispatch(patchCopyRequest(env.getURL('serviceUrl') +
		 		'/' +
		 		urls.GROUPAUDITENGAGEMENTS_URL +
		 		'?id=' +
		 		copyRequest.id +
		 		'&phase=copyhubRequest',
		 		{
		 			collections: {
		 				iepnotifications: [
		 					{
		 						id: copyRequest.id,
		 						data: {
		 							operationtype: 'acceptIEPRequest',
		 							selectedworkspaceid: 0,
		 							targetengagementid: 0,
		 							copyobjectgrouptypeid: copyRequest.copyobjectgrouptypeid,
		 							targetengagementdescription: '',
		 							clientcode: clientCode,
		 							clientname: clientName,
		 							workspacename: workspaceName,
		 							countryid: countryId,
		 							isclientidvalidated: !Number.isNaN(
		 								Number.parseInt(clientId)
		 							),
		 							istrainingworkspace: false,
		 							clientid: clientId,
		 							isimportforreview: isChkImportForReviewOnly
		 						}
		 					}
		 				]
		 			}
		 		}))
		 		.then(async () => {
		 			await dispatch(getNotifications(getNotificationsUrl))
		 			closeWizard();
		 		});
		 	}finally{
		 		setIsLoading(false);
		 		isComponentValid(true);
		 	}
		 }
		 if (isValid()) {
		 	asyncFinishClick();
		 } else {
		 	setValidationTriggerCounter(validationTriggerCounter + 1);
		 }
	},[onFinishClick]);

	const getClientDetailsByClientCodeAsync = async (value) => {
		if (value) {
			await getClientAsync(value);
		} else {
			setClientId(INVALID_CLIENT_ID);
			setClientName(labels.workspacecodenameplaceholder);
		}
	}

	const getClientAsync = async (value) => {
		let url =
			clientsUrl +
			'?filters=clientcode eq ' +
			"'" +
			value +
			"'";

		await dispatch(getCreateWorkspace(url));
	}

	const updateWizardFromComponent = () => {
		updateWizardData('domainClientId', clientId);
		updateWizardData('domainClientCode', clientCode);
		updateWizardData('domainClientName', clientName);
		updateWizardData('domainWorkspaceName', workspaceName);
		updateWizardData('domainWorkspaceId', workspaceId);
		updateWizardData('domainLocation', countryId);
		isComponentValid(isValid());
	}

	const isValid = () => {
		// Note: do not issue http requests from this method,
		// instead validate component's this.state here.
		return (
			(clientCode || standardStrings.EMPTY).length > 0 &&
			!Number.isNaN(Number.parseInt(countryId)) &&
			(workspaceName || standardStrings.EMPTY).length > 0
		);
	}

	const formChangeHandler = (key, value) => {
		switch(key){
			case 'clientCode':
				setClientCode(value);
				updateWizardData('domainClientCode', value);
				break;
			case 'clientName':
				setClientName(value);
				updateWizardData('domainClientName', value);
				break;
			case 'workspaceName':
				setWorkspaceName(value);
				updateWizardData('domainWorkspaceName', value);
				break;
			case 'countryId':
				setCountryId(value);
				updateWizardData('domainClientId', value);
				break;
			default:
				break;
		}
	}
	
	const ClientName = (props) => {
		/* workaround for broken data binding */
		return (
			<FormTextInput
				onChange={() => {}}
				placeholder={labels.clientname}
				name="clientName"
				value={props.clientName}
				rules={[]}
				help
				maxLength="50"
				disabled={true}
			/>
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader view={isSaving ? 'fullscreen' : null} />
			) : (
				<Createeditworkspaceholder>
					<section className="createeditworkspacewrapper">
						<p>
							<h5>{labels.importtonewworkspace}</h5>
						</p>
						<section className="createnewworkspace">
							<EYForm
								id="createworkspaceform"
								onChange={formChangeHandler}
								onSubmit={(e) => {
									e.preventDefault();
								}}
								action="\"
								validationTriggerCounter={validationTriggerCounter}
							>
								<FormTextInput
									placeholder={labels.clientcode}
									name="clientCode"
									value={clientCode}
									rules={[]}
									is_required
									help
									onBlur={(e) => {
										getClientDetailsByClientCodeAsync(e.currentTarget.value);
									}}
									maxLength="50"
									errormessage={labels.isRequired.replace(
										'{0}',
										labels.clientcode
									)}
									clearable="true"
								/>
								<ClientName clientName={clientName}></ClientName>
								<FormTextInput
									placeholder={labels.workspacename}
									name="workspaceName"
									value={workspaceName}
									rules={[]}
									is_required
									onChange={formChangeHandler}
									help
									maxLength="255"
									errormessage={labels.isRequired.replace(
										'{0}',
										labels.workspacename
									)}
									clearable="true"
								/>
								<FormComboBox
									id="countryId"
									name="countryId"
									value={countryId === null ? undefined : countryId}
									is_required
									help
									searchable
									placeholder={labels.location}
									options={Utility.getCountriesByGroupPermission(countriesDetailsData, userPermissions, featureConfiguration)}
									errormessage={labels.isRequired.replace(
										'{0}',
										labels.location
									)}
									clearable={false}
								/>
							</EYForm>
						</section>
					</section>
				</Createeditworkspaceholder>
			)}
		</>
	);
}

export default CreateEditWorkspace;