/**
 * @module AddNewEngagementCode
 */

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import env from '../../../util/env';
import {getWorkspaceDetailsByID} from '../../../actions/workspacesactions';
import {labels, urls} from '../../../util/uiconstants';
import Loader from '@ey/loader';
import CreateEngagement from './createeditengagement';
import {EYForm, FormComboBox, FormStaticText} from '@ey/form';
import Createeditstyle from './createeditstyle';
import {EngagementStatus} from '../../../util/enumerations';
import EllipsesControl from '@ey/ellipses-control';
import uuidv4 from 'uuid/v4';

const EditEngagement = (props) => {
	const dispatch = useDispatch();

	const {getDependencyData, updateWizardData, mode} = props;

	const workspaceData = useSelector((store) => store.individualWorkspaceData);

	const [engagementid, setEngagementid] = useState(
		getDependencyData('domainEngagementId')
	);
	const [isLoading, setIsLoading] = useState(false);
	const [isCreateEngagementLoading, setIsCreateEngagementLoading] =
		useState(true);
	const [showcreateengagementform, setShowcreateengagementform] =
		useState(false);
	const [engagementObject, setEngagementObject] = useState({
		engagementName: '',
		engagementStatus: '',
		userPrimaryOfficeId: 0,
		userLanguage: 0,
		serviceLine: '',
		subServiceLine: '',
		clientEndDate: '',
		engagementEndDate: '',
		auditorsReportDate: '',
		engCodeArray: [],
		engagementData: ''
	});

	const engagementListOption = (engagement) => {
		return {
			value: engagement && engagement.id,
			label: engagement && engagement.data && engagement.data.description
		};
	};

	let subervicelinedisabled =
		Number.parseInt(engagementObject.engagementStatus) == EngagementStatus.Restored
			? true
			: false;
	let addengagementcodebuttondisabled =
		Number.parseInt(engagementObject.engagementStatus) == EngagementStatus.Restored
			? true
			: false;

	useEffect(() => {
		setIsLoading(true);
		let getEngagementsUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.WORKSPACES_URL +
			'/' +
			Number.parseInt(getDependencyData('domainWorkspaceId'));

		dispatch(getWorkspaceDetailsByID(getEngagementsUrl));
	}, []);

	useEffect(() => {
		subervicelinedisabled =
			Number.parseInt(engagementObject.engagementStatus) ==
			EngagementStatus.Restored
				? true
				: false;
		addengagementcodebuttondisabled =
			Number.parseInt(engagementObject.engagementStatus) ==
			EngagementStatus.Restored
				? true
				: false;
	}, [engagementObject]);

	useEffect(() => {
		
		workspaceData &&
		workspaceData.collections &&
		workspaceData.collections.engagements.forEach(function (engagement) {
			if (engagement.id == engagementid) {
				let engcodearray_edit = [];
					engagement &&
					engagement.collections &&
						engagement.collections.engagementcodes &&
						engagement.collections.engagementcodes.forEach((engcode) => {
							engcodearray_edit.push({
								code: engcode.data.code,
								name: engcode.data.clientengagementid
								? engcode.data.name
								: labels.invalidengagementcode,
								clientengagementid: engcode.data.clientengagementid,
								isprimary: engcode.data.isprimary,
								cross: '',
								invalidengagementcodeerror:
								engcode.data.invalidengagementcodeerror,
								hidedeletebutton: '',
								canremove: false,
								clientcodevalid: true,
								engagementcode: engcode.data.code,
								clientcodeid: workspaceData?.data?.clientcode,
								clientid: workspaceData?.data?.clientid,
								engcodename: engcode.data.clientengagementid
								? engcode.data.name
									: labels.invalidengagementcode,
								id: engcode.id,
								engagementCodeId: uuidv4()
							});
						});
						setEngagementObject((prev) => ({
							...prev,
							engagementName: engagement.data.description,
							engagementStatus: engagement.data.engagementstatusid,
							userPrimaryOfficeId: engagement.data.primaryoffice,
							userLanguage: engagement.data.languageid,
							serviceLine: engagement.data.servicelineid,
							subServiceLine: engagement.data.subservicelineid,
							clientEndDate: engagement.data.yearenddate,
							engagementEndDate: engagement.data.enddateperiod,
							auditorsReportDate: engagement.data.reportreleasedate,
							engCodeArray: engcodearray_edit,
							engagementData: engagement.data
						}));
						updateWizardFromComponent();
					}
					
			if (getDependencyData('domainEngagementId') > 0) {
				setEngagementid(getDependencyData('domainEngagementId'));
				setShowcreateengagementform(true);
				setIsLoading(false);
				updateWizardData('domainEngagementId', 0);
			} else {
				setIsLoading(false);
			}
			});
	}, [workspaceData, engagementid]);

	const updateWizardFromComponent = () => {
		let workspacedata = workspaceData && workspaceData.data;
		updateWizardData('domainClientId', workspacedata && workspacedata.clientid);
		updateWizardData(
			'domainClientCode',
			workspacedata && workspacedata.clientcode
			);
		updateWizardData(
			'domainClientName',
			workspacedata && workspacedata.clientname
		);
		updateWizardData(
			'domainWorkspaceName',
			workspacedata && workspacedata.workspacedesc
		);
		updateWizardData(
			'domainLocation',
			workspacedata && workspacedata.countryid
		);
	};

	const updateState = (key, value) => {
		if (key == 'engagementlist' && value > 0) {
			setEngagementid(value);
			setIsCreateEngagementLoading(true);
			setShowcreateengagementform(true);
		}
	};

	const setLoaderOff_OnFinish = () => {
		setIsLoading(false);
	};
	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<Createeditstyle>
					<section className="createeditengagement">
						<section>
							<header>
								<h5>{labels.editengagementquestionlabel}</h5>
							</header>
							<section className="clientworkspaceDetails">
								<FormStaticText label={labels.client + ':'}>
									<EllipsesControl
										id=""
										content={getDependencyData('domainClientName')}
										tooltip={getDependencyData('domainClientName')}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.workspace + ':'}>
									<EllipsesControl
										id=""
										content={getDependencyData('domainWorkspaceName')}
										tooltip={getDependencyData('domainWorkspaceName')}
										isTooltipAvailable
									/>
								</FormStaticText>
							</section>
							<Createeditwrapper>
								<div className="editengagement" key="editengagement">
									<form>
										<EYForm
											id="createEngagementForm"
											className="createEngagementForm"
											onChange={updateState}
											onSubmit={() => {}}
											action="\"
										>
											<FormComboBox
												id="engagementlist"
												clearable={false}
												name="engagementlist"
												value={engagementid}
												is_required
												help
												placeholder={labels.engagementlistplaceholder}
												defaultValue={{label: 'Select', value: 0}}
												searchable={false}
												options={
													workspaceData &&
													workspaceData.collections &&
													workspaceData.collections.engagements.map(
														engagementListOption
													)
												}
												errormessage={labels.engagementrequired}
											/>
										</EYForm>
									</form>
								</div>

								{showcreateengagementform &&
								workspaceData?.data &&
								getDependencyData('domainLocation') != null ? (
									<CreateEngagement
										engagementname={engagementObject.engagementName}
										userprimaryofficeid={engagementObject.userPrimaryOfficeId}
										userlanguage={engagementObject.userLanguage}
										serviceline={engagementObject.serviceLine}
										subserviceline={engagementObject.subServiceLine}
										iscreateengagementloading={isCreateEngagementLoading}
										engagementcodearray={engagementObject.engCodeArray}
										clientenddate={engagementObject.clientEndDate}
										engagementenddate={engagementObject.engagementEndDate}
										auditorsreportdate={engagementObject.auditorsReportDate}
										isSubServiceLineDisabled={subervicelinedisabled}
										isAddEngagementCodeDisabled={
											addengagementcodebuttondisabled
										}
										engagementid={engagementid}
										engagementdata={engagementObject.engagementData || {}}
										mode={mode.edit}
										engagementstatus={Number.parseInt(
											engagementObject.engagementStatus
										)}
										setParentLoaderOffOnFinish={setLoaderOff_OnFinish}
										getDependencyData={getDependencyData}
										{...props}
									/>
								) : null}
							</Createeditwrapper>
						</section>
					</section>
				</Createeditstyle>
			)}
		</>
	);
};
export default EditEngagement;

const Createeditwrapper = styled.section`
	padding-top: 2rem;

	& .editengagement div.form-group section {
		width: 60rem;
	}

	& .editengagement div.form-group .select-value {
		width: 60rem;

		& .Select-value-label {
			width: 52rem;
			display: inline-block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	& .createeditengagement .createengagement {
		height: auto;
		padding-top: 0;
		overflow: hidden;
	}
`;
