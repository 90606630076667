import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import {
	MotifButton
} from '@ey-xd/motif-react';
import {
	labels,
	EnterKeyCode,
	pagingOptions
} from '../../../../util/uiconstants';
import EYMotifSearch from '../../../Common/Search/MotifSearch';
import AssignRole from './assignrole';
import Pagination from '@ey/pager';
import {setIsModalOpen} from '../../../../actions/modalstateactions';
import {clearErrors} from '../../../../actions/erroractions';
import {
	getAdminUserRoles
} from '../../../../actions/admin/manageaccessactions';
import env from '../../../../util/env';
import {toInteger} from 'lodash';
import {getMeCall} from '../../../../actions/admin/mepermissionsactions';
import AccessDeniedMessage from '../../../Common/AccessDeniedMessage/AccessDeniedMessage';
import ManageRolesTable from './managerolestable/managerolestable';
import DeleteRole from './managerolestable/deleterole';
import ModalControl from '../../../Common/Modal/Modal';

function ManageRoles() {
	const adminUserRoles = useSelector((store) => store.adminUserRoles);
	const userPermissions = useSelector((store) => store.userPermissions);

	const history = useHistory();
	const dispatch = useDispatch();

	const [showAssignRoleModal, setShowAssignRoleModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [pageSize, setPageSize] = useState(pagingOptions.options[0]);
	const [errorMessage, setErrorMessage] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isMeCallSent, setIsMeCallSent] = useState(false);
	const [rolePageItems, setRolePageItems] = useState([]);
	const [sortedRoles, setSortedRoles] = useState([]);
	const [filteredRoles, setFilteredRoles] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [roleToDelete, setRoleToDelete] = useState(undefined);
	
	useEffect(() => {
		dispatch(getMeCall()).then(() => setIsMeCallSent(true));
	}, []);

	useEffect(() => {
		if (isMeCallSent && userHasManageAccessPerm()) {
			getUserRoles();
		}
	}, [userPermissions, isMeCallSent]);

	useEffect(() => {
		setPage(1);
		let roleCount = filteredRoles.length;
		let tp = 0;
		if (roleCount % pageSize == 0) {
			tp = roleCount / pageSize;
			setTotalPages(tp);
		} else {
			tp = toInteger(roleCount / pageSize) + 1;
			setTotalPages(tp);
		}
		setupPagination(tp);
	}, [filteredRoles, pageSize]);

	useEffect(() => {
		if (sortedRoles && sortedRoles.length && search && search.trim().length) {
			const timer = setTimeout(() => {
				let s = search.trim().toLowerCase();
				let filtered = sortedRoles.filter((role) => {
					if (role.moduleName.toLowerCase().includes(s)) {
						return true;
					} else if (role.roleName.toLowerCase().includes(s)) {
						return true;
					} else if (role.subServiceLineName?.toLowerCase().includes(s)) {
						return true;
					} else if (
						role.localizedCountries?.some((countryItem) =>
							countryItem.name.toLowerCase().includes(s)
						)
					) {
						return true;
					}
				});
				setFilteredRoles(filtered);
			}, 1000);
			return () => clearTimeout(timer);
		} else {
			setFilteredRoles(sortedRoles);
		}
	}, [sortedRoles, search]);

	useEffect(() => {
		let sortedRoles = sortRoles(adminUserRoles);
		setSortedRoles(sortedRoles);
	}, [adminUserRoles]);

	useEffect(() => {
		setIsSubmitted(false);
		if (userHasManageAccessPerm() && isSubmitted && !showAssignRoleModal) {
			getUserRoles();
		}
	}, [isSubmitted]);

	useEffect(() => {
		if (history.location.hash.toLowerCase() == '#assignrole') {
			setShowAssignRoleModal(true);
		}
	}, [history.location]);

	useEffect(() => {
		if (showAssignRoleModal == true) {
			dispatch(clearErrors()).then(function () {
				dispatch(setIsModalOpen(true));
			});
		} else {
			dispatch(clearErrors());
			dispatch(setIsModalOpen(false));
		}
	}, [showAssignRoleModal]);

	const setAssignRoleModalFromChild = (isShow) => {
		setShowAssignRoleModal(isShow);
	};

	const getUserRoles = () => {
		setIsLoading(true);
		let userId = location.pathname.split('/').at(-1);
		let getRolesUrl =
			env.getURL('serviceUrlV2') + 'admin/Users/' + userId + '/roles';
		dispatch(getAdminUserRoles(getRolesUrl))
			.then(() => {})
			.catch((e) => {
				setErrorMessage(e.toString());
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const sortRoles = (roles) => {
		return Array.from(roles)?.sort((role1, role2) => {
			if (role1.moduleName < role2.moduleName) {
				return -1;
			} else if (role1.moduleName > role2.moduleName) {
				return 1;
			} else if (role1.roleName < role2.roleName) {
				return -1;
			} else if (role1.roleName > role2.roleName) {
				return 1;
			} else if (role1.subServiceLineName < role2.subServiceLineName) {
				return -1;
			} else if (role1.subServiceLineName > role2.subServiceLineName) {
				return 1;
			}
			return 0;
		});
	};

	const setupPagination = (totalPages) => {
		setTotalPages(totalPages);
		let rolePages = {};
		for (let i = 0; i < totalPages; i++) {
			rolePages[i + 1] = filteredRoles.slice(i * pageSize, (i + 1) * pageSize);
		}
		setRolePageItems(rolePages);
	};

	const userHasManageAccessPerm = () =>
		userPermissions?.permissions &&
		(userPermissions.permissions.canCreateUpdateManageAccess ||
			userPermissions.permissions.canReadManageAccess);

	const onDeleteClick = (role) => {
		setShowDeleteModal(true);
		setRoleToDelete(role);
	};

	const onSearchChange = (e) => {};

	const onClearClick = () => {
		setSearch('');
	};

	const handlerOptionPage = (value) => {
		if (value !== pageSize) {
			setPageSize(value);
			setPage(1);
		}
	};

	const paginationHandler = (value) => {
		if (page !== value) {
			setPage(value);
		}
	};

	const openAssignRoleModal = () => {
		history.replace({
			pathname: history.location.pathname,
			state: {customLabel: history?.location?.state?.customLabel},
			hash: 'assignrole'
		});

		setShowAssignRoleModal(true);
	};

	const onModalClose = (isDeletedSuccess) => {
		setShowDeleteModal(false);
		setRoleToDelete(undefined);
		if (isDeletedSuccess) getUserRoles();
	}

	return !userHasManageAccessPerm() ? (
		<AccessDeniedMessage permissionMesage={labels.insufficientPermission} />
	) : (
		<Mainwrapper>
			<section className="manageaccess mainResponsiveWrapper">
				<section
					className="toastMessage"
					style={{display: errorMessage ? 'block' : 'none'}}
				></section>
				<section className="add-on">
					<section className="search">
						<EYMotifSearch
							placeholder={labels.searchUserText}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							onSearchButtonClick={onSearchChange}
							onClear={onClearClick}
							onKeyDown={(e) => {
								if (
									e &&
									(e.which === EnterKeyCode || e.keyCode === EnterKeyCode)
								) {
									onSearchChange(e);
								}
							}}
						/>
					</section>
					<section className="adduser">
						<MotifButton
							aria-label="Assign role"
							role="button"
							type="button"
							variant="primary-alt"
							disabled={false}
							onClick={() => {
								openAssignRoleModal();
							}}
						>
							{labels.manageAccessAssignRole}
						</MotifButton>
					</section>
				</section>
				<ManageRolesTable
					isLoading={isLoading}
					onDeleteClick={onDeleteClick}
					data={rolePageItems[page]}
					rolesExist={sortedRoles?.length}
				/>
				{!isLoading && showAssignRoleModal ? (
					<AssignRole
						setAssignRoleModalFromChild={setAssignRoleModalFromChild}
						showAssignRoleModal={showAssignRoleModal}
						setIsSubmitted={setIsSubmitted}
						customLabel={history?.location?.state?.customLabel}
					/>
				) : (
					''
				)}
			</section>
			<section className="pagerholder">
				<Pagination
					styleName="manageaccess-paging mainResponsiveWrapper"
					options_per_page={pagingOptions.options}
					default_dropdown_value={pageSize}
					// showText={currentResource.common.pagination.show}
					currentPage={page < 1 ? 1 : page}
					TotalPages={totalPages < 1 ? 1 : totalPages}
					dropup
					onInputChange={(e) => {
						let inputValue = Number.parseInt(e.target.value);
						if (inputValue != page && inputValue > 0) {
							paginationHandler(inputValue);
						}
					}}
					onDropdownChange={(e) => {
						handlerOptionPage(e.value);
					}}
					onFirstPageClick={() => {
						paginationHandler(1);
					}}
					onPrevPageClick={() => {
						paginationHandler(page - 1);
					}}
					onNextPageClick={() => {
						paginationHandler(page + 1);
					}}
					onLastPageClick={() => {
						paginationHandler(totalPages);
					}}
					dropdown_position="left"
					// iconHover={currentResource.common.pagination.iconHover}
				/>
			</section>
			<section className="showTicketModal">
				<ModalControl
					className=""
					title={labels.manageAccessDeleteRoleModalHeader}
					onOkClick={() => { }}
					onHide={() => setShowDeleteModal(false)}
					isOkButtonDisabled={false}
					show={showDeleteModal}
					modalsize="x-small"
					showButtons={false}
					closeTitle={labels.close}
					role="dialog"
				>
					<DeleteRole
						roleToDelete={roleToDelete}
						onModalClose={onModalClose}
					/>
				</ModalControl>
			</section>
		</Mainwrapper>
	);
}

const Mainwrapper = styled.section`
	overflow: hidden;
	margin-top: 5rem;

	//heading
	.manageaccess {
		.pageheading {
			display: flex;
			padding: 2rem 0;
			svg {
				min-width: 2.5rem !important;
				min-height: 2.5rem !important;
				margin-right: 1rem;
			}

			h3 {
				margin: 0;
				line-height: 2.5rem;
			}
		}
	}

	//addon

	.add-on {
		display: flex;
		margin-right: auto;
		padding: 2rem 0 2rem 0;

		.adduser {
			.motif-button {
				height: 4rem;
				font-size: 1.2rem;
				width: 10rem;
			}
		}

		.search {
			width: 16%;
			line-height: 4rem;
			margin-right: auto;

			.motif-icon-button{
                top: 0.5rem;
            }

			.motif-typeahead-close-button {
				top: 0.4rem;
				&:hover{
					background: none!important;
				}
			}

			.motif-input {
				font-size: 1.4rem;
				font-weight: normal;
				height: 4rem;
				&::placeholder {
					font-size: 1.4rem;
					font-weight: normal;
				}
			}
		}
	}

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: 0rem;
	}

	.manageaccess-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: 1.4rem;
		background: ${(props) => props.theme.color[`white`]};
		border-top: 1px solid ${(props) => props.theme.color[`grey200`]};
	}

	.norecord {
		height: calc(100vh - 27rem);
		display: inline-block;
		width: 100%;
		display: flex;
		justify-content: center;
		flex: 0 auto;
		align-items: center;
		font-size: 1.6rem;
		color: ${(props) => props.theme.color[`grey400`]};
	}
`;

export default ManageRoles;
