/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import env from '../../util/env';
import {SVGICONS} from '@ey/icons';
import FavButton from './favoritesbutton';
import {currentResource, tabTypes, urls, labels} from '../../util/uiconstants';
import {EngagementSource, EngagementStatus} from '../../util/enumerations';
import {EngUserModelPatchFavorites} from '../../util/preparemodel';
import CogWheel from './cogwheel';
import EllipsesControl from '@ey/ellipses-control';
import EngagementRequestDeleteModal from './engagementRequestDeleteModal';
import EngagementInvalidModal from './engagementInvalidModal';
import Moment from 'react-moment';
import Timeline from '../Timeline/timeline';
import RestoredEngagementTimeline from '../Timeline/RestoredEngagementTimeline';
import {markUnmarkFavorite} from '../../actions/engagementuseractions';
import {getNotifications} from '../../actions/notificationsactions';
import {MotifTooltip, MotifButton, MotifIcon} from '@ey-xd/motif-react';
import {contentIcLink24px} from '@ey-xd/motif-react/assets/icons';
import WorkspaceGroupAudit from './workspaceGroupAudit';
import ExpiryDateLabel from '../Common/ExpiryDateLabel';

const EngagementItem = (props) => {
	const dispatch = useDispatch();

	const {id, clientName, workspace, refreshDashboard, data} = props;
	const {
		isfavorite,
		incountryuri,
		hasengagementpassedalloweddays,
		isengagementcodevalid,
		isclientcodevalid,
		isclientengagementidvalidated,
		herculesappuri,
		engagementdesc,
		engagementstatus,
		incountryaudienceuri,
		incountryappuri,
		engagementisprofilecomplete,
		independencestatusid,
		isindependencetemplateavailable,
		expectedreportreleasedate,
		archivaldeadlinedate,
		garoletypeid
	} = data;

	const selectedTab = useSelector((store) => store.dashboard.activeTab);
	const timeline = useSelector((store) => store.timeline);
	const apponlinestate = useSelector((store) => store.apponlinestate);
	const notifications = useSelector((store) => store.notifications);
	const userPermissions = useSelector((store) => store.userPermissions);

	const [
		showRequestDeleteModalState,
		setShowRequestDeleteModalState
	] = useState(false);
	const [
		showInvalidEngagementModalState,
		setShowInvalidEngagementModalState
	] = useState(false);
	const [isFavInProgress, setIsFavInProgress] = useState(false);
	const [fav, setFav] = useState(isfavorite);
	const [readyForFavClick, setReadyForFavClick] = useState(true);
	const [notice, setNotice] = useState(null);
	const [helixProjects, setHelixProjects] = useState([]);
	const [hasCountdown, setHasCountdown] = useState(false);

	let hideComponent = false;
	let engagementAlertsStatus = 'eng-listholder arcstatus-wrapper';
	let classNameFavProgress = 'arrow-right';
	let engagementurl = incountryuri + '/dashboard?' + 'engagementid=' + id;

	let bindEngagementURL = false;
	let timelineAlertsStyle = 'engagementUpdates no-engagementalets';

	useEffect(() => {
		getNotice();
		if (selectedTab === tabTypes.FavoritesTab && fav === false) {
			hideComponent = true;
		}
	}, []);

	useEffect(() => {
		if (
			timeline?.timeline?.length > 0 &&
			timeline?.timeline[0].data.id !== undefined
		) {
			timelineAlertsStyle = 'engagementUpdates';
		}
	}, [timeline]);

	const getNotice = async () => {
		if (data && engagementstatus != EngagementStatus.MarkedForDeletion) {
			return;
		}
		let url = env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;
		await dispatch(getNotifications(url));

		if (notifications && notifications.notificationdetails) {
			for (const notice of notifications.notificationdetails) {
				if (notice.actionType == 4 && notice.engagementId == id) {
					setNotice(notice);
					break;
				}
			}
		}
	};

	const showValidEngagementLink = () => {
		if (!hasengagementpassedalloweddays) {
			bindEngagementURL = true;
		} else if (
			isengagementcodevalid &&
			isclientcodevalid &&
			!isclientengagementidvalidated &&
			hasengagementpassedalloweddays
		) {
			bindEngagementURL = true;
		} else if (
			isclientcodevalid &&
			isengagementcodevalid &&
			isclientengagementidvalidated &&
			hasengagementpassedalloweddays
		) {
			bindEngagementURL = true;
		} else if (!isclientcodevalid && hasengagementpassedalloweddays) {
			bindEngagementURL = false;
		} else if (!isengagementcodevalid && hasengagementpassedalloweddays) {
			bindEngagementURL = false;
		}

		// set the engagement link with href if valid otherwise with on click if invalid
		if (bindEngagementURL) {
			return (
				<a
					href={
						!apponlinestate && herculesappuri
							? herculesappuri + '?engagementid=' + id
							: engagementurl
					}
				>
					<EllipsesControl
						content={engagementdesc || <Skeleton width={250} height={20} />}
						tooltip={engagementdesc || <Skeleton width={250} height={20} />}
						isTooltipAvailable
					/>
				</a>
			);
		} else {
			return (
				<a onClick={showInvalidEngagementModal}>
					<EllipsesControl
						content={engagementdesc || <Skeleton width={250} height={20} />}
						tooltip={engagementdesc || <Skeleton width={250} height={20} />}
						isTooltipAvailable
					/>
				</a>
			);
		}
	};

	const showInvalidEngagementModal = () => {
		if (!hasengagementpassedalloweddays) {
			redirectToEngagement();
		} else if (
			isengagementcodevalid &&
			isclientcodevalid &&
			!isclientengagementidvalidated &&
			hasengagementpassedalloweddays
		) {
			redirectToEngagement();
		} else if (
			isclientcodevalid &&
			isengagementcodevalid &&
			isclientengagementidvalidated &&
			hasengagementpassedalloweddays
		) {
			redirectToEngagement();
		} else if (!isclientcodevalid && hasengagementpassedalloweddays) {
			setShowInvalidEngagementModalState(true);
		} else if (!isengagementcodevalid && hasengagementpassedalloweddays) {
			setShowInvalidEngagementModalState(true);
		}
	};

	const redirectToEngagement = () => {
		let url =
			!apponlinestate && herculesappuri
				? herculesappuri + '?engagementid=' + id
				: engagementurl;

		window.location.href = url;
	};

	const dblClick = () => {};

	const showTimeline = () => {
		return IsRestoredEngagement() || !apponlinestate ? false : true;
	};

	const getAlertMetricsStatus = () => {
		let status = false;

		timeline?.timeline?.filter((x) => {
			if (
				x &&
				x.data?.metrics?.engagementAlerts &&
				x.data?.id?.toString() === id
			) {
				let entries = Object.entries(x.data.metrics.engagementAlerts);
				entries.forEach((property) => {
					if (
						currentResource.type1Alerts.indexOf(property[0]) >= 0 &&
						((typeof property[1] == 'boolean' && property[1] == false) ||
							(typeof property[1] == 'number' && property[1] > 0))
					) {
						status = true;
						return;
					}
				});
			}
		});

		return status;
	};

	const IsRestoredEngagement = () => {
		if (
			data?.engagementsourceid === EngagementSource.Restore ||
			data?.engagementsourceid === EngagementSource.RestoreException
		) {
			return true;
		}
		return false;
	};

	const showRequestDeleteModal = () => {
		setShowRequestDeleteModalState(true);
	};

	const hideRequestDeleteModal = () => {
		setShowRequestDeleteModalState(false);
	};

	const onFavClick = async () => {
		setReadyForFavClick(false);
		setIsFavInProgress(true);
		let isFavTab = selectedTab === tabTypes.FavoritesTab;
		// Add favourite-inprogress class to arrow-right container when api call is in progress
		try {
			await dispatch(
				markUnmarkFavorite(
					env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
					EngUserModelPatchFavorites(id, !fav, 'markunmarkfavoriteengagement')
				)
			);
			setIsFavInProgress(false);
			setFav(!fav);
			setReadyForFavClick(true);
		} finally {
			if (isFavTab) {
				doRefreshDashboard();
			}
		}
	};

	const hideInvalidEngagementModal = () => {
		setShowInvalidEngagementModalState(false);
	};

	const doRefreshDashboard = () => {
		refreshDashboard();
	};

	const getLinkedHelixProjects = (linkedHelixProjects) => {
		setHelixProjects(linkedHelixProjects);
	};

	const renderLinkedHelixProjects = () => {
		let linkedProjectNames = [];
		helixProjects.forEach((project) => {
			linkedProjectNames.push(
				<li>
					<EllipsesControl
						content={project.projectName}
						tooltip={project.projectName}
						isTooltipAvailable
					></EllipsesControl>
				</li>
			);
		});

		return (
			<span className="helixlinked-tag">
				<MotifTooltip
					allowHover={false}
					trigger={
						<MotifButton variant="text">
							<MotifIcon src={contentIcLink24px} />
							{labels.linkedHelixProjects}
						</MotifButton>
					}
					contentClassName="tooltip-helixlinked"
				>
					<ul>{linkedProjectNames}</ul>
				</MotifTooltip>
			</span>
		);
	};

	return (
		<>
			{!hideComponent && (
				<EngagementItemContainer
					className={
						notice ? 'eng-listwrapper deletion-reqsubmited' : 'eng-listwrapper'
					}
					onDoubleClick={dblClick}
				>
					<EngagementItemDetails
						className={
							getAlertMetricsStatus()
								? 'engagementerror-indicator'
								: IsRestoredEngagement()
								? hasCountdown
									? 'restored-myengagement-with-countdown'
									: 'restored-myengagement'
								: engagementAlertsStatus
						}
					>
						{IsRestoredEngagement() && data?.engagementdeletedate && (
							<ExpiryDateLabel
								expiryDate={data?.engagementdeletedate}
								setHasCountdown={setHasCountdown}
							/>
						)}

						{notice && (
							<MarkedDeleted className="deletion-status">
								<section className="deletionmsg">
									<SVGICONS hoverText="" styleName="large" name="delete" />
									<section className="deletionmsgholder">
										{labels.deleterequestbannertext1}
										{' ' + notice.displayuserName + ' '}
										<Moment local utc fromNow ago>
											{notice.displayDate}
										</Moment>
										{' ' + labels.deleterequestbannertext2}
										<a
											className="clickrespond"
											onClick={showRequestDeleteModal}
										>
											{labels.respond}
										</a>
									</section>
								</section>
							</MarkedDeleted>
						)}
						<section className="engagementInfo">
							<WorkspaceContent>
								<section className="clientname" title={clientName}>
									{(clientName === '!!!'
										? labels.invalidclientcode
										: clientName) || <Skeleton width={250} height={20} />}
								</section>
								<WorkspaceGroupAudit workspace={workspace} engagement={data} />
								{helixProjects.length > 0 ? renderLinkedHelixProjects() : null}
							</WorkspaceContent>

							{apponlinestate && (
								<Workspacemoreinfo className="rightmenu-container">
									<section className="pull-right">
										<section className="eng-rightmenu">
											<section className="engagementid">
												ID: {id || <Skeleton width={250} height={20} />}
											</section>
										</section>
										{showTimeline() ? (
											<Timelinelegend>
												<section className="legendname">
													<SVGICONS
														hoverText=""
														styleName="small pull-left milestone-icon"
														name={'circle90'}
													/>
													<label>{labels.milestones}</label>
												</section>
												<section className="legendname">
													<SVGICONS
														hoverText=""
														styleName="small pull-left"
														name={'key-events'}
													/>
													<label>{labels.keyevents}</label>
												</section>
											</Timelinelegend>
										) : null}
										<WSEdit>
											<CogWheel
												doRefreshDashboard={doRefreshDashboard}
												userPermissions={userPermissions}
												{...props}
											/>
										</WSEdit>
									</section>
								</Workspacemoreinfo>
							)}

							<EngagementDetails className="engagementname">
								<h4>
									<section className="anchor" data-autoid={id}>
										{showValidEngagementLink()}
									</section>
								</h4>
							</EngagementDetails>
						</section>
						{ apponlinestate ? (IsRestoredEngagement()  ? (
							<RestoredEngagementTimeline
								engagementId={id}
								AudienceUri={incountryaudienceuri}
								AppUri={incountryappuri}
								engagementisprofilecomplete={engagementisprofilecomplete}
								engagementstatus={engagementstatus}
								independencestatusid={independencestatusid}
								isindependencetemplateavailable={isindependencetemplateavailable}
								engagementuserstatusid={EngagementStatus.Active}
								getHelixProjects={getLinkedHelixProjects}
								expectedreportreleasedate={expectedreportreleasedate}
								garoletypeid={garoletypeid}
							/>
						) : (
							<section className="timelinecontrol">
								{
									<Timeline
										engagementId={id}
										AudienceUri={incountryaudienceuri}
										AppUri={incountryappuri}
										engagementisprofilecomplete={engagementisprofilecomplete}
										engagementstatus={engagementstatus}
										independencestatusid={independencestatusid}
										isindependencetemplateavailable={isindependencetemplateavailable}
										engagementuserstatusid={EngagementStatus.Active}
										getHelixProjects={getLinkedHelixProjects}
										expectedreportreleasedate={expectedreportreleasedate}
										archivaldeadlinedate={archivaldeadlinedate}
										garoletypeid={garoletypeid}
									/>
								}
							</section>
						)): []}
						{apponlinestate && (
							<section className={timelineAlertsStyle}>
								<section className="eng-actions">
									<section className="restored-eng">
										{IsRestoredEngagement() ? labels.restoredengagement : ''}
									</section>
								</section>
								<section className="faviconholder" />
								<section
									className={
										isFavInProgress
											? classNameFavProgress + ' ' + 'favourite-inprogress'
											: classNameFavProgress
									}
								/>
								<FavButton
									isFav={fav}
									onFavClick={onFavClick}
									readyForFavClick={readyForFavClick}
								/>
							</section>
						)}
					</EngagementItemDetails>
					<EngagementRequestDeleteModal
						key={notice}
						notification={notice}
						show={showRequestDeleteModalState}
						hide={hideRequestDeleteModal}
						{...props}
					/>
					<EngagementInvalidModal
						notification={notice}
						show={showInvalidEngagementModalState}
						hide={hideInvalidEngagementModal}
						{...props}
					/>
				</EngagementItemContainer>
			)}
		</>
	);
};
export default EngagementItem;

const EngagementItemDetails = styled.section`
	box-shadow: 0px 4px 8px 0px ${(props) => props.theme.color[`grey250`]},
		1px -1px 8px 0px ${(props) => props.theme.color[`grey250`]};
	background-color: ${(props) => props.theme.color[`grey100`]};
	display: inline-block;
	border-left: 0.5rem solid ${(props) => props.theme.color[`blue300`]};
	width: 100%;
	position: relative;
	height: 273px;
	@media screen and (max-width: 1366px) {
		height: 236px;
	}
	&.engagementerror-indicator {
		border-left: 0.5rem solid ${(props) => props.theme.color[`red600`]};
	}
	&.restored-myengagement {
		height: 129px;
		@media screen and (max-width: 1366px) {
			height: 106px;
		}
	}
	&.restored-myengagement-with-countdown {
		height: 176px;
		@media screen and (max-width: 1366px) {
			height: 183px;
		}
		.restore-expiry {
			padding: 1.5rem 2rem 0.5rem 2rem;
		}
		.rightmenu-container {
			margin-top: -3.5rem;
		}
		.engagementname {
			margin-top: 0.5rem;
		}
	}
	& .modal-wrapper {
		height: 300px !important;
		width: 675px !important;
	}

	& .editList {
		& ul {
			background-color: ${(props) => props.theme.color[`white`]};
			padding: 0;
			margin: 0;
			line-height: normal;

			& li {
				width: 100%;
				margin: 0;
				padding: 0;
				display: inline-block;
				font-size: 1.4rem;
				line-height: normal;

				& a,
				& span {
					text-decoration: none;
					color: ${(props) => props.theme.color[`grey500`]};
					width: 100%;
					display: inline-block;
					outline: none;
					font-weight: normal;
					padding: 0.8rem 1.6rem;
				}

				&:hover {
					background-color: ${(props) => props.theme.color[`grey250`]};
					cursor: pointer;
				}
			}
		}
	}
	.deletion-status ~ .timelinecontrol .err-msg {
		margin-top: 0;
	}
	& .engagementInfo {
		float: left;
		width: 100%;
		padding: 1rem 0 1rem 0;
		position: relative;
	}
	.motif-timeline-content
		.motif-milestone
		.motif-milestone-content
		.motif-progress-bar-circle {
		top: -28px;
	}

	.motif-timeline-content {
		width: 100%;
	}

	& .timelinecontrol {
		float: left;
		width: 100%;
		font-size: 1.4rem;
		color: ${(props) => props.theme.color[`grey400`]};

		.timelineloader {
			width: 20%;
			margin: 0 auto;
			padding-bottom: 3rem;

			.motif-progress {
				background-color: ${(props) => props.theme.color[`blue300`]};
			}

			.loadinglabel {
				margin-bottom: 0.5rem;
				color: ${(props) => props.theme.color[`grey400`]}!important;
			}
		}

		.timelineerrorlabel {
			padding: 0 3rem 3rem 3rem;
		}

		.motif-timeline {
			margin: 0 5rem 6.5rem 4.3rem;
			padding: 0;
			background: ${(props) => props.theme.color[`grey100`]};
			@media screen and (max-width: 1366px) {
				margin-bottom: 6.5rem;
			}
		}
	}

	&.arcstatus-wrapper {
		& .arcstatus {
			background-color: ${(props) => props.theme.color[`red600`]};
			color: ${(props) => props.theme.color[`white`]};
			padding: 0.2rem 0.5rem;
			font-size: 1.4rem;

			& svg {
				fill: ${(props) => props.theme.color[`white`]};
				vertical-align: top;
				margin: 0.2rem 1rem 0 0;
			}

			& .arcmsg {
				width: 75%;
				display: inline-block;
				line-height: 2.8rem;
			}

			& .arcaction {
				float: right;
				& a {
					text-decoration: none;
					cursor: pointer;
					margin-right: 4rem;
					font-weight: bold;
					margin-top: 0.5rem;
				}
				& a:hover {
					text-decoration: underline;
					color: #337ab7;
				}
			}
		}
	}

	& .engagementUpdates {
		box-shadow: inset 0px 2px 4px 0px ${(props) => props.theme.color[`grey300`]};
		float: left;
		width: 100%;
		position: absolute;
		bottom: 0;
		right: 0;
		background: ${(props) => props.theme.color[`grey100`]};
		&.no-engagementalets {
			width: 100%;
			box-shadow: inset 0px 2px 4px 0px
				${(props) => props.theme.color[`grey300`]};
			background: ${(props) => props.theme.color[`grey100`]};
		}
		& .faviconholder {
			float: right;
			position: relative;
			display: block;
		}
		& .eng-actions {
			display: inline-block;
			margin-top: 1rem;
			margin-left: 1rem;
			.restored-eng {
				color: ${(props) => props.theme.color[`grey400`]};
				font-size: 1.4rem;
				font-weight: bold;
				margin: 0px 0px 0px 2rem;
			}
		}
		& .arrow-right {
			width: 0;
			height: 0;
			border-top: 4.5rem solid transparent;
			border-bottom: 0 solid transparent;
			border-right: 4.5rem solid ${(props) => props.theme.color[`grey500`]};
			float: right;
			margin-right: -0.1rem;
			margin-bottom: -0.1rem;
			box-shadow: 3px 3px 5px 0px ${(props) => props.theme.color[`grey300`]};
			&.favourite-inprogress {
				border-top: 4.5rem solid transparent;
				border-bottom: 0 solid transparent;
				border-right: 4.5rem solid ${(props) => props.theme.color[`grey400`]};
				pointer-events: none;
				opacity: 0.5;
				+ section svg {
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
`;

const Timelinelegend = styled.section`
	display: inline-block;
	color: ${(props) => props.theme.color[`grey400`]};
	vertical-align: top;
	& .legendname {
		margin-right: 1em;
		display: inline-block;

		& svg {
			fill: ${(props) => props.theme.color[`blue300`]};
			margin-right: 0.5rem;

			&.milestone-icon {
				transform: rotate(-20deg);
			}
		}

		& label {
			vertical-align: top;
			line-height: normal;
			font-weight: normal;
			font-size: 1.4rem;
			-webkit-user-select: none;
			-moz-user-select: none;
			user-select: none;
		}
	}
`;

const EngagementDetails = styled.section`
	width: 100%;
	display: inline-block;
	vertical-align: super;
	& svg {
		vertical-align: middle;
		cursor: pointer;
		fill: ${(props) => props.theme.color[`grey400`]};
	}

	& h4 {
		display: inline-block;
		font-size: 1.6rem;
		line-height: normal;
		vertical-align: top;
		margin-top: -1rem;
		font-weight: bold;
		color: ${(props) => props.theme.color[`grey500`]};
		padding-left: 3rem;
		width: 100%;

		& section.anchor {
			color: ${(props) => props.theme.color[`grey500`]};
			vertical-align: middle;
			width: 70%;
			display: inline-flex;
			line-height: 4rem;
			max-width: 70%;

			& a {
				display: inline-flex;
				color: ${(props) => props.theme.color[`grey500`]};
				max-width: 100%;
			}
		}

		& .eng-rightmenu {
			line-height: 4rem;
			width: 35%;
			& .restored-eng {
				color: ${(props) => props.theme.color[`grey300`]};
				font-size: 1.4rem;
				font-weight: bold;
			}

			& > section {
				display: inline-block;
			}
		}

		& .ellipses {
			width: auto;
			max-width: none;
			display: inline-flex;
			color: ${(props) => props.theme.color[`grey500`]};
			& div {
				max-width: none;
			}
		}
	}

	& .engagementid {
		display: inline-block;
		font-size: 1.4rem;
		line-height: 4rem;
		margin: 0 5rem 0 2rem;
		color: ${(props) => props.theme.color[`grey400`]};
		vertical-align: text-top;
		font-weight: normal;
		float: right;
		width: 11rem;
	}
`;

const WorkspaceContent = styled.div`
	padding-left: 3rem;
	width: 67%;
	float: left;
	@media screen and (max-width: 1366px) {
		width: 61%;
	}
	@media screen and (min-width: 1367px) and (max-width: 1600px) {
		width: 61%;
	}
	.clientname,
	.workspace-name,
	.engagement-type,
	.engagement-typelinked {
		display: inline-block;
		font-size: 1.4rem;
		color: ${(props) => props.theme.color[`grey400`]};
		line-height: normal;
		max-width: 28%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: middle;
		padding-right: 0.3rem;
	}
	.workspace-name {
		max-width: 28%;
		padding-left: 0.3rem;
	}
	.engagement-type {
		max-width: 19%;
		padding-left: 0.3rem;
	}
	.engagement-typelinked {
		display: inline-block;
		max-width: 14%;
		padding-left: 0.3rem;
		.motif-icon svg {
			margin-top: -0.3rem;
			vertical-align: middle;
			margin-right: 0.3rem;
		}
		.engagementtype-linkedtxt {
			font-size: 1.4rem;
			color: ${(props) => props.theme.color[`grey400`]};
			line-height: normal;
		}
	}
	.helixlinked-tag {
		display: inline-flex;
		border: 1px solid ${(props) => props.theme.color[`teal600`]};
		border-radius: 3.2rem;
		max-width: 18%;
		margin-left: 1rem;
		padding: 0.1rem 1rem 0 1rem;
		vertical-align: middle;
		button {
			font-size: 1.2rem;
			font-weight: normal;
			color: ${(props) => props.theme.color[`teal600`]};
			border: none;
			box-shadow: none;
			svg {
				width: 2.4rem !important;
				height: 1.6rem !important;
				top: 0.15rem;
			}
			&:hover {
				border: none;
				margin-bottom: 0;
			}
		}
		.motif-text-button:hover {
			border: none;
			margin-bottom: 0;
		}
		.motif-tooltip-wrapper {
			display: flex;
			align-items: center;
		}
	}
`;

const Workspacemoreinfo = styled.section`
	padding-right: 1rem;
	padding-top: 0.6rem;
	width: 32%;
	float: right;
	line-height: normal;
	@media screen and (max-width: 1366px) {
		width: 38%;
	}
	@media screen and (min-width: 1367px) and (max-width: 1600px) {
		width: 38%;
	}
	& .eng-rightmenu {
		display: inline-block;
		& .restored-eng {
			color: ${(props) => props.theme.color[`grey300`]};
			font-size: 1.4rem;
			font-weight: bold;
		}

		& > section {
			display: inline-block;
		}
	}
	& .engagementid {
		display: inline-block;
		font-size: 1.4rem;
		margin: 0 5rem 0 1rem;
		color: ${(props) => props.theme.color[`grey400`]};
		font-weight: normal;
		float: right;
		width: 11rem;
		text-align: right;
	}
`;

const WSEdit = styled.div`
	flex-grow: 1;
	display: inline-block;
	float: right;
	margin-right: 1rem;
	svg {
		margin-top: -0.4rem;
		transform: rotate(90deg);
	}
`;

const MarkedDeleted = styled.section`
	& {
		padding: 0.2rem 0.5rem;
		font-size: 1.4rem;
		box-shadow: -1px 0px 6px 0px ${(props) => props.theme.color[`grey300`]};
	}

	& .deletionmsg {
		width: 95%;
		display: inline-block;
		line-height: 2rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		.deletionmsgholder {
			display: inline;
			vertical-align: text-top;
			font-weight: bold;
		}

		svg {
			fill: ${(props) => props.theme.color[`red600`]} !important;
			vertical-align: middle;
			margin: 0 0.5rem 0 0;
			width: 2rem;
			height: 2rem;
		}

		.clickrespond {
			font-weight: bold;
			color: ${(props) => props.theme.color[`blue500yellow400`]};
			margin-left: 0.4rem;
			cursor: pointer;
		}
	}
`;

const EngagementItemContainer = styled.section`
	margin: 1.5rem 0;
	&.eng-listwrapper.deletion-reqsubmited > section:first-child {
		height: 319px;
		.deletion-status ~ .timelinecontrol .err-msg {
			margin-top: 1.5rem;
		}
		@media screen and (max-width: 1366px) {
			height: 272px;
			.deletion-status ~ .timelinecontrol .err-msg {
				margin-top: 2.1rem;
			}
		}
	}
`;
