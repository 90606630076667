import React, {Suspense, useEffect, useState} from 'react';
import {useRegisteredComponent} from '@ey/voltron-core';
import Env from '../../util/env';
import ErrorBoundary from './ErrorBoundary';

export default function RemoteComponent({
	id,
	loadingFallback,
	errorFallback,
	runtimeErrorFallback,
	onRuntimeError,
	adConfig,
	...props
}) {
	const {Component, errorLoading} = useRegisteredComponent(id);
	const [isServer, setIsServer] = useState(true);

	useEffect(() => setIsServer(false), []);

	return isServer ? (
		loadingFallback
	) : (
		<ErrorBoundary
			id={id}
			fallback={runtimeErrorFallback || errorFallback}
			onError={onRuntimeError}
		>
			<Suspense fallback={loadingFallback}>
				{errorLoading ? (
					errorFallback
				) : Component ? (
					<Component
						loadingFallback={loadingFallback}
						federatedHostName={Env.getFederatedHostName()}
						adConfig={adConfig}
						telemetry={false}
						runtimeErrorFallback={runtimeErrorFallback || errorFallback}
						onRuntimeError={onRuntimeError}
						{...props}
					/>
				) : (
					loadingFallback
				)}
			</Suspense>
		</ErrorBoundary>
	);
}
