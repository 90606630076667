import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Pagination from '@ey/pager';
import {
	MotifTable,
	MotifSearch,
	MotifBadge,
	MotifTooltip,
	MotifToast,
	MotifFormField
} from '@ey-xd/motif-react';
import EllipsesControl from '@ey/ellipses-control';
import moment from 'moment';
import Loader from '../../../Common/Loaders/loadingindicator';
import {
	pagingOptions,
	labels,
	dateFormatsListItems,
	dateFormats,
	successMessageDisplayDuration
} from '../../../../util/uiconstants';
import env from '../../../../util/env';
import { getEngagementTemplates } from '../../../../actions/admin/templatemanagementaction';
import AccessDeniedMessage from '../../../Common/AccessDeniedMessage/AccessDeniedMessage';
import TemplateDetails from '../templatedetails/templatedetails';
import ModalControl from '../../../Common/Modal/Modal';
import Toast from '../../../Common/Toast/Toast';
import { clearErrors } from '../../../../actions/erroractions';
import { setIsModalOpen } from '../../../../actions/modalstateactions';
import { SVGICONS } from '@ey/icons';
import TemplateDelete from '../templatedelete/templatedelete';

function TemplateManagement() {
	// hooks
	const engagementTemplate = useSelector((store) => store.engagementTemplates);
	const userPermissions = useSelector((store) => store.userPermissions);
	const dispatch = useDispatch();
	const history = useHistory();

	//states
	const [searchInputValue, setSearchInputValue] = useState('');
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(engagementTemplate?.pages);
	const [pageSize, setPageSize] = useState(20);
	const [isLoading, setIsLoading] = useState(true);
	const [isTemplateEdited, setIsTemplateEdited] = useState(false);
	const [isTemplateDeleted, setIsTemplateDeleted] = useState(false);
	const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);
	const [editTemplateData, seteditTemplateData] = useState(null);
	const [showdeleteTemplateData, setShowDeleteTemplateData] = useState(false);
	const [editTemplateSuccessMessage, setEditTemplateSuccessMessage] = useState(
		''
	);
	const [deleteTemplateData, setDeleteTemplateData] = useState(null);

	const duration = successMessageDisplayDuration;

	useEffect(() => {
		loadEngagementTemplates(searchInputValue);
	}, [page, pageSize]);

	useEffect(() => {
		if (isTemplateEdited) {
			loadEngagementTemplates(searchInputValue);
			setIsTemplateEdited(false);
		}
	}, [isTemplateEdited]);

	useEffect(() => {
		if (isTemplateDeleted) {
			loadEngagementTemplates(searchInputValue);
			setIsTemplateDeleted(false);
		}
	}, [isTemplateDeleted]);

	useEffect(() => {
		if (engagementTemplate) {
			setTotalPages(engagementTemplate?.pages);
		}
	}, [engagementTemplate]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setEditTemplateSuccessMessage('');
		}, duration);
		return () => clearTimeout(timer);
	});

	useEffect(() => {
		if (editTemplateData == true) {
			dispatch(clearErrors()).then(function () {
				dispatch(setIsModalOpen(true));
			});
		} else {
			dispatch(clearErrors());
			dispatch(setIsModalOpen(false));
		}
	}, [editTemplateData]);

	// Use common error clearing framework to clear axios errors
	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	useEffect(()=>{
		clearError(true);
	},[])

	const closeEditTemplateModal = () => {
		setShowEditTemplateModal(false);
		setShowDeleteTemplateData(false);
		seteditTemplateData(null);
		setDeleteTemplateData(null);
		let path = history.location.pathname + history.location.search;
		if (history.location.hash && history.location.hash.length > 0) {
			history.push(path);
		}
	};

	const loadEngagementTemplates = (searchText) => {
		setIsLoading(true);
		let adminUserUrl =
			env.getURL('serviceUrlV2') +
			`admin/EngagementTemplates?page=${page}&pageSize=${pageSize}`;

		if (searchText) {
			adminUserUrl += `&searchString=${searchText}`;
		}

		dispatch(getEngagementTemplates(adminUserUrl))
			.then(() => {
				setIsLoading(false);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onSearchChange = () => {
		if (searchInputValue != undefined) {
			setPage(1);
			loadEngagementTemplates(searchInputValue);
		}
	};

	const onClearClick = () => {
		setSearchInputValue('');
		loadEngagementTemplates('');
	};

	const handlerOptionPage = (value) => {
		if (value !== pageSize) {
			setPageSize(value);
			setPage(1);
		}
	};

	const paginationHandler = (value) => {
		if (page !== value) {
			setPage(value);
		}
	};

	const columnDefs = [
		{
			minWidth: 410,
			width: 410,
			headerName: labels.templateName,
			field: 'templateName',
			cellRenderer: 'cellTemplateNameRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 385,
			width: 385,
			headerName: labels.templateAreaRegion,
			field: 'area',
			cellRenderer: 'cellAreaRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 160,
			width: 160,
			headerName: labels.templateSize,
			field: 'filesize',
			cellRenderer: 'cellTemplateSizeRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 160,
			width: 160,
			headerName: labels.templateStatus,
			field: 'isPublished',
			cellRenderer: 'cellTemplateStatusRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 260,
			width: 260,
			headerName: labels.templateCreatedBy,
			field: 'createdUserName',
			cellRenderer: 'cellCreatedUserRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 160,
			width: 160,
			headerName: labels.templateCreatedDate,
			field: 'createDate',
			cellRenderer: 'createDateCellRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 140,
			width: 140,
			headerName: labels.templateCountOfEngagement,
			field: 'successfulTemplateImportCount',
			cellRenderer: 'cellCountOfEngagementRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 150,
			width: 150,
			headerName: '',
			field: 'actions',
			cellRenderer: 'actionsRenderer',
			lockPosition: true,
			suppressSizeToFit: true
		}
	];

	const ellipsesControlRender = (text) => {
		return (
			<section className="roleType">
				<EllipsesControl
					content={text}
					tooltip={text}
					displayId={''}
					noOfLines={1}
					ellipsisClass="columnName"
				/>
			</section>
		);
	};

	const onTemplateDetailslick = (data) => {
		setShowEditTemplateModal(true);
		seteditTemplateData(data);
	};

	const cellTemplateNameRender = (e) => {
		return (
			<section className="roleType">
				<a
					variant="primary-alt"
					name="invite"
					value="back"
					onClick={() => onTemplateDetailslick(e.data)}
					className=""
				>
					{ellipsesControlRender(e.data.templateName)}
				</a>
			</section>
		);
	};

	const cellAreaRender = (e) => {
		let region = '';
		if (e.data?.regions != undefined && e.data?.regions?.length > 0) {
			region =
				e.data?.regions?.length > 1
					? (region = e.data?.regions?.map((item) => {
						return item?.regionName;
					}).join(", "))
					: e.data?.regions[0].regionName;
		}

		return <>{ellipsesControlRender(region)}</>;
	};

	const cellTemplateStatusRender = (e) => {
		let status = e.data.isPublished ? (
			<MotifBadge value={labels.templatePublished} />
		) : (
			<></>
		);
		return <>{ellipsesControlRender(status)}</>;
	};

	const cellTemplateSizeRender = (e) => {
		return <>{e.data.filesize}</>;
	};

	const cellCreatedUserRender = (e) => {
		return <>{ellipsesControlRender(e.data.createdUserName)}</>;
	};

	const createDateCellRender = (e) => {
		let createDate = e.data.createDate
			? moment
				.utc(e.data.createDate)
				.format(dateFormatsListItems[dateFormats].label)
			: '';
		return <>{ellipsesControlRender(createDate)}</>;
	};

	const cellCountOfEngagementRender = (e) => {
		return <>{e.data.successfulTemplateImportCount}</>;
	};

	const onTemplateDelete = (data) => {
		setShowDeleteTemplateData(true);
		setDeleteTemplateData(data);
	};
	const actionsRenderer = (e) => {
		return (
			<section className="iconbutton">
				<section className="editicon-button">
					<MotifTooltip
						flip
						id={'editButtonTooltipId'}
						placement={'top'}
						contentClassName="editicon"
						hide={userPermissions?.permissions?.canUpdateTemplates}
						trigger={
							<section>
								<button
									role="button"
									aria-label="edit-icon"
									disabled={!userPermissions?.permissions?.canUpdateTemplates}
									onClick={() => {
										onTemplateDetailslick(e.data);
									}}
									className="edit-user-edit-btn"
								>
									<SVGICONS
										role="button"
										styleName="medium editicon"
										name="edit"
										hoverText={'Edit'}
									/>
								</button>
							</section>
						}
					>
						<MotifToast variant="error">
							{labels.insufficientPermission}
						</MotifToast>
					</MotifTooltip>
				</section>
				<section className="deleteicon-button">
					<MotifTooltip
						flip
						id={'deleteButtonTooltipId'}
						placement={'top'}
						contentClassName="deleteicon"
						hide={userPermissions?.permissions?.canDeleteTemplates}
						trigger={
							<section>
								<button
									role="button"
									aria-label="delete-icon"
									disabled={!userPermissions?.permissions?.canDeleteTemplates}
									onClick={() => {
										onTemplateDelete(e.data);
									}}
									className="delete-user-delete-btn"
								>
									<SVGICONS
										role="button"
										styleName="medium deleteicon"
										name="delete"
										hoverText={'Delete'}
									/>
								</button>
							</section>
						}
					>
						<MotifToast variant="error">
							{labels.insufficientPermission}
						</MotifToast>
					</MotifTooltip>
				</section>
			</section>
		);
	};

	function adminPermissions() {
		let permissionGroupPermission = userPermissions?.permissions;
		return (
			permissionGroupPermission &&
			(permissionGroupPermission.canReadTemplates ||
				permissionGroupPermission.canUpdateTemplates ||
				permissionGroupPermission.canDeleteTemplates)
		);
	}

	return (
		<Mainwrapper>
			{adminPermissions() ? (
				<section>
					<section className="templatemanagement mainResponsiveWrapper">
						<section
							className={
								'toastMessage ' +
								(editTemplateSuccessMessage.length > 0 ? 'block' : 'none')
							}
						>
							<Toast
								variant="success"
								duration={duration}
								onClose={() => setEditTemplateSuccessMessage('')}
							>
								{editTemplateSuccessMessage}
							</Toast>
						</section>
						<section className="add-on">
							<section className="search">
								<MotifFormField>
									<MotifSearch
										placeholder={labels.searchUserText}
										value={searchInputValue}
										onChange={(e) => setSearchInputValue(e.target.value)}
										onSearchButtonClick={onSearchChange}
										onClear={onClearClick}
										onEnter={onSearchChange}
										clearHoverText={labels.clear}
									/>
								</MotifFormField>
							</section>
						</section>
					</section>

					{isLoading ? (
						<Loader show view="blockView" styleName="loadingstyle" />
					) : engagementTemplate?.totalEntityCount > 0 ? (
						<section className="templatemanagementTable mainResponsiveWrapper">
							<MotifTable
								defaultColDef={{
									flex: 1,
									sortable: false,
									filter: false,
									resizable: false,
									minWidth: 180,
									lockPosition: true
								}}
								enableCellTextSelection
								suppressRowClickSelection
								suppressCellSelection
								columnDefs={columnDefs}
								onGridReady={(params) => {
									params.api.sizeColumnsToFit();
								}}
								rowSelection="multiple"
								rowData={engagementTemplate?.data}
								rowBuffer={200}
								frameworkComponents={{
									cellTemplateNameRender: cellTemplateNameRender,
									cellCreatedUserRender: cellCreatedUserRender,
									cellAreaRender: cellAreaRender,
									cellCountOfEngagementRender: cellCountOfEngagementRender,
									cellTemplateSizeRender: cellTemplateSizeRender,
									createDateCellRender: createDateCellRender,
									cellTemplateStatusRender: cellTemplateStatusRender,
									actionsRenderer: actionsRenderer
								}}
							/>
						</section>
					) : (
						<section className="norecord">{labels.noResultsFound}</section>
					)}

					<section className="pagerholder">
						<Pagination
							styleName="templatemanagement-paging mainResponsiveWrapper"
							options_per_page={pagingOptions.options}
							default_dropdown_value={pageSize}
							currentPage={page}
							TotalPages={totalPages}
							dropup
							onInputChange={(e) => {
								let inputValue = Number.parseInt(e.target.value);
								if (inputValue != page && inputValue > 0) {
									paginationHandler(inputValue);
								}
							}}
							onDropdownChange={(e) => {
								handlerOptionPage(e.value);
							}}
							onFirstPageClick={() => {
								paginationHandler(1);
							}}
							onPrevPageClick={() => {
								paginationHandler(page - 1);
							}}
							onNextPageClick={() => {
								paginationHandler(page + 1);
							}}
							onLastPageClick={() => {
								paginationHandler(totalPages);
							}}
							dropdown_position="left"
						/>
					</section>

					<ModalControl
						className="edit-template"
						title={labels.templateEdit}
						onOkClick={() => { }}
						onHide={() => closeEditTemplateModal(false)}
						isOkButtonDisabled={false}
						show={showEditTemplateModal}
						modalsize="message"
						showButtons={false}
						closeTitle={labels.close}
						modalContainerClass="edit-template"
						role="dialog"
					>
						<TemplateDetails
							setIsTemplateEdited={setIsTemplateEdited}
							closeEditTemplateModal={closeEditTemplateModal}
							editTemplateData={editTemplateData}
							setEditTemplateSuccessMessage={setEditTemplateSuccessMessage}
						/>
					</ModalControl>

					<section className="deletetemplate">
						<ModalControl
							className="delete-template"
							title={labels.templateDelete}
							onOkClick={() => { }}
							onHide={() => closeEditTemplateModal(false)}
							isOkButtonDisabled={true}
							show={showdeleteTemplateData}
							modalsize="message"
							showButtons={false}
							closeTitle={labels.close}
							modalContainerClass="delete-template"
							role="dialog"
						>
							<TemplateDelete
								deleteTemplateData={deleteTemplateData}
								closeEditTemplateModal={closeEditTemplateModal}
								setIsTemplateDeleted={setIsTemplateDeleted}
							/>
						</ModalControl>
					</section>
				</section>
			) : (
				<AccessDeniedMessage permissionMesage={labels.insufficientPermission} />
			)}
		</Mainwrapper>
	);
}

const Mainwrapper = styled.section`
	overflow: hidden;
	margin-top: 5rem;

	.iconbutton{
		width: 100%;
		display: inline;
		text-align: right;
	}

	.editicon-button{
		display: inline-block;
		button.edit-user-edit-btn{
			border: none;
			background: transparent;
			cursor: pointer!important;
			outline: none;
			transition: 0.2s ease-in-out opacity;

			svg{
				fill: ${(props) => props.theme.color[`black`]};
			}

			&[disabled] {
				opacity: 0.2;
				pointer-events: none;
			}
		}

		.editicon{
			margin: 0 0 0 1rem;
			cursor: pointer;
		}
	}

	.deleteicon-button{
		display: inline-block;
		button.delete-user-delete-btn{
			border: none;
			background: transparent;
			cursor: pointer!important;
			outline: none;
			transition: 0.2s ease-in-out opacity;

			svg{
				fill: ${(props) => props.theme.color[`black`]};
			}

			&[disabled] {
				opacity: 0.2;
				pointer-events: none;
			}
		}

		.deleteicon{
			margin: 0 0 0 1rem;
			cursor: pointer;
		}	
	}

	.loadingstyle {
		height: calc(100vh - 25rem) !important;
	}
	
	//heading
	.templatemanagement {
		.toastMessage {
			.motif-toast-text-container {
				font-size: 1.4rem;
			}

			&.block {
				display: block;
			}

			&.none {
				display: none;
			}
		}
		.pageheading {
			display: flex;
			padding: 2rem 0;
			svg {
				min-width: 2.5rem !important;
				min-height: 2.5rem !important;
				margin-right: 1rem;
			}

			h3 {
				margin: 0;
				line-height: 2.5rem;
			}
		}
	}

	//addon

	.add-on {
		display: flex;
		margin-right: auto;
		padding: 2rem 0 2rem 0;

		.adduser {
			.motif-button {
				height: 4rem;
				font-size: 1.2rem;
				width: 10rem;
			}
		}

		.search {
			width: 16%;
			line-height: 4rem;
			margin-right: auto;

			.motif-icon-button{
                top: 0.5rem;
            }
			
			.motif-typeahead-close-button {
				top: 0.5rem;
				&:hover{
					background: none!important;
				}
			}

			.motif-input {
				font-size: 1.4rem;
				font-weight: normal;
				height: 4rem;
				padding-right: 3rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				&::placeholder {
					font-size: 1.4rem;
					font-weight: normal;
				}
			}
		}
	}

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: 0rem;
	}

	.templatemanagement-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: 1.4rem;
		background: ${(props) => props.theme.color[`white`]};
		border-top: 1px solid ${(props) => props.theme.color[`grey200`]};
	}

    .norecord{
        height: calc(100vh - 25rem);
        display: inline-block;
        width: 100%;
        display: flex;
        justify-content: center;
        flex: 0 auto;
        align-items: center;
        font-size: 1.6rem;
        color: ${(props) => props.theme.color[`grey400`]};
    }

    .templatemanagementTable {
        height: 100%;
        overflow-y: auto;
        .motif-table {
            height: calc(100vh - 24rem);
    
            &.ag-theme-material .ag-checkbox-input-wrapper:focus-within {
                box-shadow: none;
            }
    
            .ag-header-cell {
                color: ${(props) => props.theme.color[`grey450`]};
                &:hover,
                    &.ag-header-cell-moving{
                        background: transparent!important;
                    }
            }
    
            .ag-header-cell .ag-header-select-all{
                visibility: hidden;
            }
    
            .ag-body-viewport{
                height: calc(100vh - 30rem);
                border: 0.1rem solid ${(props) =>
		props.theme.color['grey200']} !important;
    
                &:after{
                    content: none;
                }
            }
    
            .ag-header-viewport {
                background: ${(props) => props.theme.color[`grey100`]};
                font-size: 1.4rem;
            }
            .ag-root.ag-layout-normal {
                overflow-y: auto;
                width: 100%;
            }
    
            .ag-root.ag-layout-auto-height {
                display: block;
            }
    
            .ag-center-cols-viewport {
                overflow-x: hidden;
            }
    
            .ag-row-hover{
                border-width: 0 0 0.1rem 0 !important;		
                border-style: solid !important;
                border-color: ${(props) =>
		props.theme.color[`grey250`]} !important;
            }
    
            .ag-row{
                border-width: 0 0 0.1rem 0 !important;		
                border-style: solid !important;
                border-color: ${(props) =>
		props.theme.color[`grey250`]} !important;
                color: ${(props) => props.theme.color[`grey400`]};
    
                .ag-cell-value{
                    font-weight: 100;
                }
            }
    
            .ag-row,
            .ag-row-hover {
                background: ${(props) => props.theme.color[`white`]} !important;
                font-weight: normal;
    

				.ag-cell-wrapper{
					width: 100%;
					display: grid;
				}
				
                .ag-column-hover {
                    background: ${(props) => props.theme.color[`white`]} !important;
                }
            }
    
            .ag-header-row .ag-header-cell{
                &:hover{
                    background: ${(props) => props.theme.color[`grey100`]}
                }
            }

			.ag-cell {
				background: ${(props) => props.theme.color[`white`]} !important;
			}
    
            .ag-cell,
            .ag-header-cell {
                font-size: 1.4rem;
                padding: 0 2rem;
                display: block;
			 
                .ag-react-container {
                    [role='button'] {
                        cursor: default;
                    }
                }
    
                .ag-header-cell-text{
                    font-size: 1.4rem;
                }
            }
    
            .ag-header-cell:last-child,
            .ag-cell:last-child {
                border-right: none;
				text-align: right;
                display: flex;

				.ag-react-container{
					display: inline-block;
				}
            }
    
            .roleType.disabled,
            .roleType.disabled *{
                color: ${(props) => props.theme.color[`grey300`]}!important;
                fill: ${(props) => props.theme.color[`grey300`]};
            }
            .noptr{
                pointer-events: none;
            }
            .disabled{
                color: ${(props) => props.theme.color[`grey300`]}!important;
                fill: ${(props) => props.theme.color[`grey300`]};
            }
    
            .roleType {
                display: inline-block;
                width: 100%;
                align-items: center;
				cursor: default;

				.ellipses{
					margin: 0!important;
					padding: 0!important;
					display: inline-flex;
				}

				a {
					width: 100%;
					color: ${(props) => props.theme.color[`blue600`]};
					.ellipses{
						color: ${(props) => props.theme.color[`blue600`]};
					}
				}

				.motif-badge{
					width: 12rem;
					padding: 0.5rem 2rem;
					font-size: 1.4rem;
					border-radius: 2rem;
					vertical-align: middle;
				}
    
                &.enable.icon{
                    fill: ${(props) => props.theme.color[`black`]}!important;
                }
                .columnName{
                    font-size: 1.4rem;
                    color: ${(props) => props.theme.color[`grey500`]};
				}
                }
            }
    
            .errorRow {
                color: ${(props) => props.theme.color[`red600`]} !important;
            }
        }
    
        .ag-body-horizontal-scroll {
			overflow: auto;
        }
    
        .ag-layout-normal {
            &::-webkit-scrollbar {
                width: 0.6rem;
            }
            &::-webkit-scrollbar-track {
                background: ${(props) => props.theme.color[`grey225`]};
                width: 0.6rem;
            }
            &::-webkit-scrollbar-thumb {
                background: ${(props) => props.theme.color[`grey300`]};
                width: 0.6rem;
                cursor: pointer;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: ${(props) => props.theme.color[`grey400`]};
            }
        }
    
    }

	`;

export default TemplateManagement;
