import initialstate from '../../initialstate';
import * as types from '../../../actions/actiontypes';

export default function engagementTemplatesReducer(state = initialstate.engagementTemplates, action) {
	switch (action.type) {
		case types.GET_ALL_ENGAGEMENT_TEMPLATES:
			state = action.response;
			return state;
		default:
			return state;
	}
}