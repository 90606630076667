import React from 'react';
import Headerbody from '../Styles/headerbodystyle';
import AdminUserItem from './adminuseritem';
import {labels} from '../../../../util/uiconstants';

function AdminUserItemList(props) {
	return (
		<Headerbody className="manageaccesspageview mainResponsiveWrapper">
			<section className="userlistwrapper">
				<section className="userlist-header">
					<section className="userlistheader">
						<section className="userlistitem userdetail">
							{labels.nameHeader}
						</section>
						<section className="userlistitem useremail">
							{labels.emailHeader}
						</section>
						<section className="userlistitem contextmenu">
							{/* {labels.actionHeader} */}
						</section>
					</section>
				</section>
				<section
					id="tablebodycontainer"
					className="customScrollbar userlist-body"
				>
					<section className="userlistholder">
						{props.data?.map((item) => (
							<AdminUserItem data={item} onDeactivate={props.onDeactivate} />
						))}
					</section>
				</section>
			</section>
		</Headerbody>
	);
}

export default AdminUserItemList;
