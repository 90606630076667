import * as types from '../actiontypes';

export function downloadReport(url, documentName) {
	return {
		type: types.DOWNLOAD_REPORT,
		resolve: true,
		docName: documentName,
		meta: {
			api: {
				url: url,
				method: 'GET',
				responseType: 'arraybuffer'
			}
		}
	};
}