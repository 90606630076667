/**
 * @module Timelinetooltiplarge
 */

import React from 'react';
import _ from 'lodash';
import {SVGICONS} from '@ey/icons';
import {labels} from '../../util/uiconstants';

const TimelineTooltipLarge = (props) => {
	return (
		<section className="timelinetooltip-content">
			<span>{props.name}</span>
			<span className="task-status">{labels.taskstatus}</span>
			<section className="timeline-status">
				<section>
					<span className="timeline-countstatus">{props.openCount}</span>
					<span title={labels.notstarted}>
						<SVGICONS
							styleName="large"
							className="small"
							hoverText={labels.notstarted}
							name="open"
						/>
					</span>
				</section>
				<section>
					<span className="timeline-countstatus">{props.inprogressCount}</span>
					<span title={labels.inprogress}>
						<SVGICONS
							styleName="large"
							className="small"
							hoverText={labels.inprogress}
							name="progress"
						/>
					</span>
				</section>
				<section>
					<span className="timeline-countstatus">{props.reviewCount}</span>
					<span title={labels.inreview}>
						<SVGICONS
							styleName="large"
							className="small"
							hoverText={labels.inreview}
							name="review"
						/>
					</span>
				</section>
				<section>
					<span className="timeline-countstatus">{props.completedCount}</span>
					<span title={labels.complete}>
						<SVGICONS
							styleName="large"
							className="small"
							hoverText={labels.complete}
							name="completed"
						/>
					</span>
				</section>
			</section>
			<section className="timeline-startenddate">
				<section className="pull-left">
					{labels.started} <span>{props.startDate}</span>
				</section>
				<section className="pull-right">
					{labels.end} <span>{props.endDate}</span>
				</section>
			</section>
			<section className="timeline-completion">
				<span>{props.progress}%</span> {labels.complete}
			</section>
		</section>
	);
};

export default TimelineTooltipLarge;
