import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import {
	labels,
	dateFormatsListItems,
	displayGroupings,
	dateFormats,
	settingsPreferences
} from '../../util/uiconstants';
import {EngagementStatus} from '../../util/enumerations.js';
import moment from 'moment';
import {setCurrentGroupingTypeInMyEngSection} from '../../actions/dashboardactions';
import {updateGroupPrefUserSettings} from '../../actions/usersettingsaction';
import CogWheelReview from './cogwheelreview';
import WorkspaceGroupAudit from './workspaceGroupAudit';

const ReviewEngagementItem = (props) => {
	const dispatch = useDispatch();

	const {data, id, clientName, workspace, refreshDashboard} = props;
	const {
		incountryuri,
		createdate,
		engagementdeletedate,
		engagementdesc,
		engagementstatus
	} = data;

	const currentGrouping = useSelector((store) => store.dashboard.groupingType);
	const preferredlandingpageview = useSelector(
		(store) => store?.userSettings?.data?.preferredlandingpageview
	);
	const isEyUser = useSelector((store) => store?.userSettings?.data?.iseyuser);

	const engagementurl = incountryuri + '/dashboard?' + 'engagementid=' + id;

	let defaultGroupingUserSetting =
		preferredlandingpageview === settingsPreferences.WorkspaceList
			? displayGroupings.MODULE
			: displayGroupings.LIST;
	let groupingUserSetting =
		defaultGroupingUserSetting === displayGroupings.MODULE
			? settingsPreferences.WorkspaceList
			: settingsPreferences.EngagementList;
	let createDate = createdate
		? moment.utc(createdate).format(dateFormatsListItems[dateFormats].label)
		: '';

	let expiresOnDate = engagementdeletedate
		? moment
				.utc(engagementdeletedate)
				.format(dateFormatsListItems[dateFormats].label)
		: '';

	useEffect(() => {
		dispatch(setCurrentGroupingTypeInMyEngSection(defaultGroupingUserSetting));
		dispatch(updateGroupPrefUserSettings(groupingUserSetting));
	}, []);

	useEffect(() => {
		dispatch(setCurrentGroupingTypeInMyEngSection(currentGrouping));
	}, [currentGrouping]);

	const isGuestUser = () => {
		return isEyUser !== null && isEyUser !== undefined ? !isEyUser : true;
	};

	const dblClick = () => {};

	return (
		<EngagementItemContainer
			className="eng-listwrapper"
			onDoubleClick={dblClick}
		>
			<EngagementItemDetails className="eng-listholder arcstatus-wrapper">
				<section className="engagementInfo">
					<WorkspaceContent>
						<section className="workspacecontent-wrap">
							<section className="clientname" title={clientName}>
								{(clientName === '!!!'
									? labels.invalidclientcode
									: clientName) || <Skeleton width={250} height={20} />}
							</section>
							<WorkspaceGroupAudit workspace={workspace} engagement={data} />
						</section>
						<section className="pull-right review-menu">
							<section className="pull-right">
								<section className="engagementid">
									{labels.id}: {id || <Skeleton width={250} height={20} />}
								</section>
								{!isGuestUser() && (
									<WSEdit>
										<CogWheelReview
											doRefreshDashboard={refreshDashboard}
											{...props}
										/>
									</WSEdit>
								)}
							</section>
							<section className="pull-right dates-menu">
								<section className="createdate">
									{labels.createdOn}: <b>{createDate}</b>
								</section>
								{!isGuestUser() && (
									<section className="expiredate">
										{labels.expiresOn}: <b>{expiresOnDate}</b>
									</section>
								)}
							</section>
						</section>
					</WorkspaceContent>
					<EngagementDetails className="engagementname">
						<h4>
							<section className="anchor" data-autoid={id}>
								<a
									title={engagementdesc}
									className={
										engagementstatus ===
										EngagementStatus.CanvasExternalAccessPortalV2
											? 'engagementname-status33' // class name for allowing access
											: 'engagementname-status27' // no need to access / disable it
									}
									href={
										engagementstatus ===
										EngagementStatus.CanvasExternalAccessPortalV2
											? engagementurl
											: '#'
									}
								>
									{engagementdesc}
								</a>
							</section>
						</h4>
					</EngagementDetails>
				</section>
			</EngagementItemDetails>
		</EngagementItemContainer>
	);
};
export default ReviewEngagementItem;

const WSEdit = styled.div`
	display: inline-block;
	margin-right: 1rem;
	svg {
		margin-top: -0.4rem;
		transform: rotate(90deg);
	}
`;

const EngagementItemDetails = styled.section`
	box-shadow: 0px 4px 8px 0px ${(props) => props.theme.color[`grey250`]},
		1px -1px 8px 0px ${(props) => props.theme.color[`grey250`]};
	background-color: ${(props) => props.theme.color[`grey100`]};
	display: inline-block;
	border-left: 0.5rem solid ${(props) => props.theme.color[`blue300`]};
	width: 100%;
	&.engagementerror-indicator {
		border-left: 0.5rem solid ${(props) => props.theme.color[`red600`]};
	}
	&.engagementextuser-indicator {
		border-left: 0.5rem solid ${(props) => props.theme.color[`grey500`]};
	}
	& .modal-wrapper {
		height: 300px !important;
		width: 675px !important;
	}

	& .editList {
		& ul {
			background-color: ${(props) => props.theme.color[`white`]};
			padding: 0;
			margin: 0;
			line-height: normal;
		}

		& li {
			width: 100%;
			margin: 0;
			padding: 0;
			display: inline-block;
			font-size: 1.4rem;
			line-height: normal;

			& a,
			& span {
				text-decoration: none;
				color: ${(props) => props.theme.color[`grey500`]};
				width: 100%;
				display: inline-block;
				outline: none;
				font-weight: normal;
				padding: 0.8rem 1.6rem;
			}

			&:hover {
				background-color: ${(props) => props.theme.color[`grey250`]};
				cursor: pointer;
			}
		}
	}

	& .engagementInfo {
		float: left;
		width: 100%;
		padding: 1rem 0 1rem 0;
		position: relative;

		& .engagementname .anchor.newengagement {
			color: ${(props) => props.theme.color[`grey300`]};
			pointer-events: none;
			cursor: default;
		}
	}

	& .timelinecontrol {
		float: left;
		width: 100%;
		padding-bottom: 1.5rem;
	}

	& .engagementUpdates {
		float: left;
		width: 100%;
		position: relative;
		& .faviconholder {
			float: right;
			position: relative;
			display: block;
		}

		& .arrow-right {
			width: 0;
			height: 0;
			border-top: 4.5rem solid transparent;
			border-bottom: 0 solid transparent;
			border-right: 4.5rem solid ${(props) => props.theme.color[`grey500`]};
			float: right;
			margin-right: -0.2rem;
			margin-bottom: -0.2rem;
			box-shadow: 3px 3px 5px 0px ${(props) => props.theme.color[`grey300`]};
			&.favourite-inprogress {
				border-bottom: 0 solid transparent;
				border-right: 4.5rem solid ${(props) => props.theme.color[`grey400`]};
				pointer-events: none;
				opacity: 0.5;
				+ section svg {
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
`;

const EngagementDetails = styled.section`
	width: 100%;
	display: inline-block;
	vertical-align: super;
	& svg {
		vertical-align: middle;
		cursor: pointer;
	}

	& h4 {
		display: inline-block;
		font-size: 1.6rem;
		line-height: normal;
		vertical-align: top;
		margin-top: -1rem;
		font-weight: bold;
		color: ${(props) => props.theme.color[`grey500`]};
		padding-left: 3rem;
		width: 100%;
		margin-bottom: 0;

		& section.anchor {
			color: ${(props) => props.theme.color[`grey500`]};
			vertical-align: middle;
			width: 45%;
			display: inline-flex;
			line-height: 4rem;
			max-width: 52%;
			pointer-events: auto;

			& a {
				display: inline-block;
				color: ${(props) => props.theme.color[`grey300`]};
				max-width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				&.engagementname-status33 {
					color: ${(props) => props.theme.color[`grey500`]};
				}
				&.engagementname-status27 {
					text-decoration: none;
					cursor: default;
				}
			}
		}

		& .eng-rightmenu {
			line-height: 4rem;
			width: 35%;
			& .restored-eng {
				color: ${(props) => props.theme.color[`grey300`]};
				font-size: 1.6rem;
			}

			& > section {
				display: inline-block;
			}
		}

		& .ellipses {
			width: auto;
			max-width: none;
			display: inline-flex;
			color: ${(props) => props.theme.color[`grey500`]};
			& div {
				max-width: none;
			}
		}
	}

	& h4 .eng-rightmenu .engagementid {
		color: ${(props) => props.theme.color[`grey400`]};
		width: 11rem;
	}

	& .engagementid,
	& .createdate,
	& .expiredate {
		display: inline-block;
		font-size: 1.4rem;
		line-height: 4rem;
		margin: 0 3rem;
		color: ${(props) => props.theme.color[`grey500`]};
		font-weight: normal;
	}
`;

const WorkspaceContent = styled.div`
	padding-left: 3rem;
	width: 100%;
	.workspacecontent-wrap {
		width: 59%;
		float: left;
		@media screen and (max-width: 1366px) {
			width: 52%;
		}
	}
	.clientname,
	.workspace-name,
	.engagement-type,
	.engagement-typelinked {
		display: inline-block;
		font-size: 1.4rem;
		color: ${(props) => props.theme.color[`grey400`]};
		line-height: normal;
		max-width: 35%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: middle;
		padding-right: 0.3rem;
	}
	.workspace-name {
		max-width: 35%;
		padding-left: 0.3rem;
	}
	.engagement-type {
		max-width: 19%;
		padding-left: 0.3rem;
	}
	.engagement-typelinked {
		display: inline-block;
		max-width: 15%;
		padding-left: 0.3rem;
		.motif-icon svg {
			margin-top: -0.3rem;
			vertical-align: middle;
			margin-right: 0.3rem;
		}
		.engagementtype-linkedtxt {
			font-size: 1.4rem;
			color: ${(props) => props.theme.color[`grey400`]};
			line-height: normal;
		}
	}

	.review-menu {
		display: inline-block;
		padding-right: 1rem;
		width: 40%;
		float: right;
		line-height: normal;
		padding-top: 0.5rem;
		@media screen and (max-width: 1366px) {
			width: 47%;
		}
		.engagementid {
			display: inline-block;
			font-size: 1.4rem;
			margin: 0 5rem 0 1rem;
			width: 11rem;
			color: ${(props) => props.theme.color[`grey500`]};
			font-weight: normal;
			vertical-align: top;
			text-align: right;
		}
		.icon svg {
			width: 2rem;
			height: 2rem;
			vertical-align: top;
		}
		.dates-menu {
			display: inline-block;
			line-height: 2rem;
			.createdate,
			.expiredate {
				display: inline-block;
				font-size: 1.4rem;
				margin: 0 3rem;
				color: ${(props) => props.theme.color[`grey500`]};
				font-weight: normal;
			}
		}
	}
`;

const EngagementItemContainer = styled.section`
	margin: 1.5rem 0;
`;
