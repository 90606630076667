import initialState from '../../../reducers/initialstate';
import * as types from '../../../actions/actiontypes';

export function permissionGroupReducer(
	state = initialState.permissionGroup,
	action
) {
	switch (action.type) {
		case types.PERMISSION_GROUP:
			return action.response;
		case types.RESET_PERMISSION_GROUP:
			return initialState.permissionGroup;
		default:
			return state;
	}
}

export function permissionGroupUsersReducer(
	state = initialState.permissionGroupUsers,
	action
) {
	switch (action.type) {
		case types.PERMISSION_GROUP_USER:
			return action.response;
		default:
			return state;
	}
}
