import * as types from '../../../actions/actiontypes';

export function downloadReportReducer(state = {}, action) {
	switch (action.type) {
		case types.DOWNLOAD_REPORT: {
			if (action.error == null) {
				const link = document.createElement('a');
				link.download = action.docName;
				link.href = window.URL.createObjectURL(new Blob([action.response]));
				link.click();
			}
			return state;
		}
		default:
			return state;
	}
}
