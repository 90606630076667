import * as types from '../../actions/actiontypes';

export function getPermissionGroups(url, resource) {
	return {
		type: types.PERMISSION_GROUP,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data: {resource: resource}
			}
		}
	};
}
export function getPermissionGroupUsers(url, resource) {
	return {
		type: types.PERMISSION_GROUP_USER,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data: {resource: resource}
			}
		}
	};
}
export function addPermissionGroupUsers(url, users) {
	return {
		type: types.ADD_PERMISSION_GROUP_USERS,
		meta: {
			api: {
				url: url,
				method: 'POST',
				data: users
			}
		}
	};
}

export function deletePermissionGroupUsers(url) {
	return {
		type: types.DELETE_PERMISSION_GROUP_USERS,
		meta: {
			api: {
				url: url,
				method: 'DELETE'
			}
		}
	};
}

export function resetPermissionGroup() {
	return {
		type: types.RESET_PERMISSION_GROUP
	};
}
