import React, {useState} from 'react';
import styled from 'styled-components';
import {MotifTextLink} from '@ey-xd/motif-react';
import {labels} from '../../util/uiconstants';
import {EngagementStatus, EngagementReasonType} from '../../util/enumerations';
import env from '../../util/env';
import ModalControl from '@ey/modal-control';
import {SVGICONS} from '@ey/icons';
import {useDispatch} from 'react-redux';
import {
	DeleteEngagement,
	PatchEngagement
} from '../../actions/engagementsaction';
import {
	MotifFormField,
	MotifLabel,
	MotifRadioButton,
	MotifRadioButtonGroup
} from '@ey-xd/motif-react';
import LoadingIndicator from '../Common/LoadingIndicator/LoadingIndicator';
import _ from 'lodash';

const EngagementDeleteModal = (props) => {
	const dispatch = useDispatch();

	const {show, id, hide, quit, refreshDashboard} = props;
	const {engagementstatus, workspacecountryid, activeusercount, isldcupgraded} = props.data;
	
	const [badTryNoReasonEntered, setBadTryNoReasonEntered] = useState(false);
	const [badTryNoCommentReasonEntered, setBadTryNoCommentReasonEntered] =
		useState(false);
	const [isActing, setIsActing] = useState(false);
	const [deleteCommentText, setDeleteCommentText] = useState(undefined);
	const [deleteReasonTypeID, setDeleteReasonTypeID] = useState(0);

	let canDeleteMsg = labels.deleteengagementconfirmationmessage;

	const getDeleteActiveUserCount = () => {
		let itemEngagementStatus = engagementstatus;
		let count = 1;
		if (
			!(
				itemEngagementStatus === EngagementStatus.GAMXMigrationError ||
				itemEngagementStatus === EngagementStatus.RollForwardError ||
				itemEngagementStatus === EngagementStatus.RestoreError ||
				itemEngagementStatus === EngagementStatus.ImportError ||
				itemEngagementStatus === EngagementStatus.ImportTemplateError
			)
		) {
			count = activeusercount;
		}

		return count;
	};

	const deleteActiveUserCount = getDeleteActiveUserCount();

	const OKtitle =
		deleteActiveUserCount == 1 ? labels.confirmlabel : labels.submitlabel;
	if (deleteActiveUserCount == 1) {
		canDeleteMsg = labels.deleteengagementconfirmationmessageoneuser;
	}

	const applyClass =
		badTryNoCommentReasonEntered || badTryNoReasonEntered ? 'red' : '';

	const handleDeleteConfirm = async () => {
		if (_.isEmpty(deleteReasonTypeID) || parseInt(deleteReasonTypeID) === 0) {
			setBadTryNoReasonEntered(true);
			return;
		}

		if (
			parseInt(deleteReasonTypeID) === EngagementReasonType.Other &&
			_.isEmpty(deleteCommentText)
		) {
			setBadTryNoCommentReasonEntered(true);
			return;
		}

		setIsActing(true);

		try {
			if (engagementstatus === EngagementStatus.ExternalReviewEngagement) {
				await dispatch(
					DeleteEngagement(
						env.getURL('serviceUrl') + '/engagements.json/' + id,
						null
					)
				);
			} else {
				await dispatch(
					PatchEngagement(
						env.getURL('serviceUrl') + '/engagements.json/' + id,
						JSON.stringify(modelDelete())
					)
				);
			}
		} finally {
			await refreshDashboard();
			closeModal();
		}
	};

	const modelDelete = () => {
		return {
			id: id,
			data: {
				countryid: workspacecountryid,
				engagementstatusid:
					getDeleteActiveUserCount() > 1
						? EngagementStatus.MarkedForDeletion
						: EngagementStatus.Deleted,
				deleteinitiatorcomments:
					deleteReasonTypeID == EngagementReasonType.Other
						? deleteCommentText
						: null,
				ispatchfromuniversal: true,
				deleteReasonTypeID: deleteReasonTypeID
			}
		};
	};

	const closeModal = () => {
		setBadTryNoReasonEntered(false);
		setBadTryNoCommentReasonEntered(false);
		setDeleteCommentText(undefined);
		setDeleteReasonTypeID(0);
		hide();
	};

	const cancelModal = () => {
		setBadTryNoReasonEntered(false);
		setBadTryNoCommentReasonEntered(false);
		setDeleteCommentText(undefined);
		setDeleteReasonTypeID(0);
		quit();
	};

	const handleDeleteCommentChange = (event) => {
		let text = event.target.value;

		if (text.trimLeft().length <= 4000) {
			setDeleteCommentText(text.trimLeft());
			setBadTryNoCommentReasonEntered(false);
		} else {
			event.target.value = deleteCommentText;
		}
	};

	const handleRationalRadioButtonChange = (event) => {
		setDeleteReasonTypeID(event.target.value);
		setBadTryNoReasonEntered(false);
	};

	return (
		<div>
			{isActing ? (
				<section className="loader-wrapper">
					<LoadingIndicator show fullscreen />
				</section>
			) : (
				<ModalControl
					title={labels.deleteengagement}
					modalsize="small"
					onOkClick={() => {
						handleDeleteConfirm();
					}}
					confirmBtnTitle={OKtitle}
					closeTitle={labels.close}
					closeBtnTitle={labels.cancel}
					onHide={cancelModal}
					show={show}
					backdrop="static"
				>
					<DeleteEngagementStyle>
						<div id="message" className="delmsg-header">
							<span className="del-msg">{canDeleteMsg}</span>
							<section className="del-msgdescp">
								<span className="del-msg1">
									{labels.deleteengagementconfirmationmessageoneuser1}
									<MotifTextLink
										variant="alt"
										href={env.getDocArcDocumentationUrl()}
										target="_blank"
									>
										{labels.deleteengagementconfirmationmessageoneuser2}
									</MotifTextLink>
								</span>
								<span className="del-msg1">
									{labels.deleteengagementconfirmationmessageoneuser3}
								</span>
							</section>
						</div>
						<section className="deleng-radiogrp">
							<MotifFormField>
								<MotifLabel>{labels.deleteengagementreasonquestion}</MotifLabel>
								<MotifRadioButtonGroup>
									<MotifRadioButton
										name="trainingengagement"
										id="trainingengagement"
										value={EngagementReasonType.TrainingEngagement.toString()}
										key={EngagementReasonType.TrainingEngagement}
										checked={
											deleteReasonTypeID ==
											EngagementReasonType.TrainingEngagement
										}
										onChange={handleRationalRadioButtonChange}
									>
										{labels.deletereasontrainingengagementlabel}
									</MotifRadioButton>
									<MotifRadioButton
										name="restoredorcopied"
										id="restoredorcopied"
										value={EngagementReasonType.RestoredOrCopiedEngagement.toString()}
										key={EngagementReasonType.RestoredOrCopiedEngagement}
										checked={
											deleteReasonTypeID ==
											EngagementReasonType.RestoredOrCopiedEngagement
										}
										onChange={handleRationalRadioButtonChange}
									>
										{labels.deletereasonrestoredorcopiedlabel}
									</MotifRadioButton>
									<MotifRadioButton
										name="createdbymistake"
										id="createdbymistake"
										value={EngagementReasonType.CreatedByMistake.toString()}
										key={EngagementReasonType.CreatedByMistake}
										checked={
											deleteReasonTypeID ==
											EngagementReasonType.CreatedByMistake
										}
										onChange={handleRationalRadioButtonChange}
									>
										{labels.deletereasoncreatedbymistakelabel}
									</MotifRadioButton>
									{ isldcupgraded  && 
										<MotifRadioButton
											name="terminedbyclient"
											id="terminedbyclient"
											value={EngagementReasonType.TerminatedByClient.toString()}
											key={EngagementReasonType.TerminatedByClient}
											checked={deleteReasonTypeID == EngagementReasonType.TerminatedByClient}
											onChange={handleRationalRadioButtonChange}
										>									
											{labels.deletereasonterminedbyclientlabel}
										</MotifRadioButton>
									}
									<MotifRadioButton
										name="other"
										id="other"
										value={EngagementReasonType.Other.toString()}
										key={EngagementReasonType.Other}
										checked={deleteReasonTypeID == EngagementReasonType.Other}
										onChange={handleRationalRadioButtonChange}
									>
										{labels.deletereasonotherlabel}
									</MotifRadioButton>
								</MotifRadioButtonGroup>
							</MotifFormField>
						</section>

						{deleteReasonTypeID == EngagementReasonType.Other && (
							<section className="commentwrapper">
								<textarea
									placeholder={labels.deleteengagementreasonlabel}
									onChange={handleDeleteCommentChange}
									className={applyClass}
									value={deleteCommentText}
								/>
								{badTryNoCommentReasonEntered && (
									<section className="submit-error">
										<section>
											<SVGICONS styleName="small" id="icon" name="error" />
											<span>{labels.deleteengagementreasonrequired}</span>
										</section>
									</section>
								)}
							</section>
						)}

						{badTryNoReasonEntered && (
							<section className="commentwrapper">
								<section className="submit-error">
									<section>
										<SVGICONS styleName="small" id="icon" name="error" />
										<span>{labels.deleteengagementcommentreasonrequired}</span>
									</section>
								</section>
							</section>
						)}
					</DeleteEngagementStyle>
				</ModalControl>
			)}
		</div>
	);
};

export default EngagementDeleteModal;

const DeleteEngagementStyle = styled.section`
	padding: 2rem 0;
	.delmsg-header {
		line-height: normal;
		font-size: 1.4rem;
		.del-msg {
			font-weight: bold;
		}
		.del-msgdescp {
			margin-top: 2.6rem;
			.del-msg1 {
				font-size: 1.4rem;
			}
			.motif-text-link {
				font-size: 1.4rem;
				border: none !important;
				color: ${(props) => props.theme.color[`blue500`]};
			}
		}
	}
	.deleng-radiogrp {
		margin-top: 4rem;
		.motif-form-field {
			margin-bottom: 0;
		}
		.motif-label {
			font-size: 1.4rem;
			font-weight: bold;
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
			width: 100%;
			margin-bottom: 1.5rem;
			padding-bottom: 0.5rem;
		}
		.motif-radio-button-group {
			svg {
				width: 1.4rem;
				height: 1.4rem;
				margin-top: -0.3rem;
			}
			.motif-radio-button-label {
				font-size: 1.4rem;
				font-weight: normal;
			}
		}
	}
	& .commentwrapper {
		margin-top: 3rem;
		textarea {
			font-size: 1.4rem;
			background: ${(props) => props.theme.color[`grey100`]};
			border: none;
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey400`]};
			display: block;
			height: 12rem;
			width: 100%;
			max-width: 100%;
			margin-bottom: 1rem;
			resize: none;
			padding: 1rem;
			line-height: 1.7rem;
			&::placeholder {
				margin-left: 1rem;
			}
			&:hover:not(:disabled) {
				background: ${(props) => props.theme.color[`grey250`]};
			}
			&:focus:not(:disabled) {
				background: ${(props) => props.theme.color[`grey250`]};
				border-bottom-width: 0.2rem;
			}
			&.red {
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`red600`]};
			}
		}

		& .submit-error {
			font-size: 1.2rem;
			line-height: normal;
			height: 2rem;
			padding-bottom: 2rem;
			color: ${(props) => props.theme.color[`red600`]};

			& svg {
				fill: ${(props) => props.theme.color[`red600`]};
				vertical-align: text-bottom;
				margin-right: 0.5rem;
			}
		}
	}
`;
