import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {getLocalizedAPIError} from '../../common/util';
import Toast from '../../../Common/Toast/Toast';
import env from '../../../../util/env';
import {labels} from '../../../../util/uiconstants';
import * as manageaccessactions from '../../../../actions/admin/manageaccessactions';
import Loader from '../../../Common/Loaders/loadingindicator';
import {MotifButton} from '@ey-xd/motif-react';
import EllipsesControl from '@ey/ellipses-control';
import TicketField from '../../common/ticketfield';

function DeactivateUser(props) {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const [deactivateUserErrorMessage, setDeactivateUserErrorMessage] = useState(
		''
	);
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	const userToDeactivate = props.userToDeactivate;

	const onTicketChanged = (e) => {
		setTicketNumber(e.target.value);
	}

	const deactivateUser = () => {
		setIsSubmitClicked(true);
		if (ticketNumber.trim().length <= 0) {
			return;
		}

		setIsLoading(true);
		let data = {
			isActive: false
		};

        let uriTicketNumber = encodeURIComponent(ticketNumber.trim());

		let deactivateUrl =
			env.getURL('serviceUrlV2') +
			`admin/Users/${userToDeactivate.userId}/isactive?ticketNumber=${uriTicketNumber}`;

		dispatch(manageaccessactions.deactivateAdminUser(deactivateUrl, data))
			.then(() => {
				setIsLoading(false);
				props.onDeactivateModalClose(true);
			})
			.catch((result) => {
				setDeactivateUserErrorMessage(getLocalizedAPIError(result));
			})
			.finally(() => {
				setIsLoading(false);
				setIsSubmitClicked(false);
			});
	};

	return (
		<DeactivateUserWrapper className="adduser">
			<section
				className="toastMessage"
				style={{
					display:
						deactivateUserErrorMessage && deactivateUserErrorMessage.length > 0
							? 'block'
							: 'none'
				}}
			>
				<Toast
					variant="error"
					onClose={() => setDeactivateUserErrorMessage(false)}
				>
					{deactivateUserErrorMessage}
				</Toast>
			</section>
			{isLoading ?  (<section className="delete-role-loader">
				<Loader show view="blockView" styleName="loadingstyle" /></section>
			) : (
				<section>
					<section className="userSearch customScrollbar">
						<section className="result">
							<h5>{labels.deactivateWarning}</h5>
							<ul className="userlist">
								<li>
									<span className="nameemail">
										<EllipsesControl
											content={
												userToDeactivate.displayName +
												' ' +
												'(' +
												userToDeactivate.email +
												')'
											}
											tooltip={
												userToDeactivate.displayName +
												' ' +
												'(' +
												userToDeactivate.email +
												')'
											}
											displayId={''}
											noOfLines={1}
											ellipsisClass=""
										/>
									</span>
								</li>
							</ul>
						</section>
					</section>
					<TicketField
						ticketNumber={ticketNumber}
						onTicketChanged={onTicketChanged}
						showValidation={isSubmitClicked}
					/>
					<section className="modal-footer">
						<MotifButton
							type="button"
							variant="primary-alt"
							disabled={ticketNumber.trim().length <= 0}
							onClick={() => {
								deactivateUser();
							}}
						>
							{labels.deactivateButton}
						</MotifButton>
						<MotifButton
							type="button"
							variant="secondary"
							onClick={() => {
								props.onDeactivateModalClose(false);
							}}
						>
							{labels.cancel}
						</MotifButton>
					</section>
				</section>
			)}
		</DeactivateUserWrapper>
	);
}

const DeactivateUserWrapper = styled.section`
//ticketstart
		.ticketdetail{
			margin: 0 2rem 2rem 2rem;
			padding-bottom: 2rem;
			h5{
				font-size: 1.4rem;
				font-weight: normal;
				color: ${(props) => props.theme.color[`grey500`]};
				border: 0!important;
			}

			.motif-form-field{
				width: 29rem;
			}

			.motif-form-field:not(.motif-form-field-has-value),
			.motif-form-field:not(.motif-form-field-focused){
				.motif-label{
					font-size: 1.4rem;
					font-weight:normal;
					margin-top: 0.6rem;
					color: ${(props) => props.theme.color[`black`]};
				}
			}

			.motif-form-field-has-value,
			.motif-form-field-focused{
				.motif-label{
					font-size: 1.2rem!important;
					font-weight: normal!important;
					margin-top: 0.2rem!important;
					color: ${(props) => props.theme.color[`black`]};
				}
			}
			
			
			.motif-input {
				font-size: 1.4rem;
				line-height: 3rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				color: ${(props) => props.theme.color[`black`]};
				&::placeholder {
					font-size: 1.4rem;
				}
			}
		}
		//ticketend
	.modal-footer {
		padding: 2rem;

		.motif-toast-close-button,
		.motif-toast-close-button:focus {
			outline: 0 !important;

			* {
				outline: 0 !important;
			}

			svg {
				outline: 0 !important;
			}

			*:focus {
				outline: 0 !important;
			}

			svg:focus {
				outline: 0 !important;
			}
		}
	}
	.delete-role-loader{
		padding: 2rem !important;
		.loadingstyle {
			height: calc(100vh - 66rem) !important;
		}
	}
	// .motif-loader {
	// 	height: 18rem !important;
	// }

	.userSearch {
		display: flex;
		width: 100%;
		font-size: 1.4rem;

		.react-select__menu-list {
			height: 32rem;
			min-height: 32rem;
		}

		.result {
			padding: 1.6rem 2rem;
			display: inline-block;
			width: 100%;

			h5 {
				font-size: 1.4rem;
				margin: 0;
				padding: 0 0 2rem 0;
			}

			li {
				margin: 0;
				list-style-type: none;
				.nameemail {
					width: 100%;
					display: inline-block;
					.ellipses {
						width: 100%;
						display: inline-flex;
					}
				}
			}

			.userlist {
				width: 100%;
				display: inline-block;
			}
		}

		.user-selectbox .select-group {
			width: 100%;
		}
	}
	.modal-footer {
		display: inline-flex;
		width: 100%;

		button {
			font-size: 1.4rem;
			padding: 1.2rem 1.5rem;
			margin-right: 1rem;
			line-height: normal;
		}
	}
`;

export default DeactivateUser;
