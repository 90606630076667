import initialstate from '../../initialstate';
import * as types from '../../../actions/actiontypes';
import {currentResource} from '../../../util/uiconstants';

export function adminUserReducer(state = initialstate.adminUsers, action) {
	switch (action.type) {
		case types.GET_ALL_ADMIN_USERS:
			state = action.response;
			return state;
		default:
			return state;
	}
}

export function adminUserRolesReducer(
	state = initialstate.adminUserRoles,
	action
) {
	switch (action.type) {
		case types.GET_ALL_ADMINUSER_ROLES:
			if (action.response && action.response.roleModels) {
				state = getLocalizedUserRoles(action.response.roleModels);
			}
			return state;
		default:
			return state;
	}
}

function getLocalizedUserRoles(roleModels) {
	const localizedCountries = currentResource.countries;
	const localizedSubServiceLines = currentResource.subServiceLines;
	const localizedRoles = currentResource.roles;
	const localizedModules = currentResource.modules;

	let localizedRoleModels = [];
	roleModels.forEach((role) => {
		if (role && role.roleKey && role.moduleId) {
			let localizedRole = role;

			let localizedRoleItem = localizedRoles.find((x) => x.id == role.roleKey);
			localizedRole.roleName = localizedRoleItem?.name;

			let localizedModuleItem = localizedModules.find(
				(x) => x.id == role.moduleId
			);
			localizedRole.moduleName = localizedModuleItem?.name;

			if (role.subServiceLineId) {
				let localSubServiceLineItem = localizedSubServiceLines.find(
					(x) => x.subservicelineid == role.subServiceLineId
				);
				localizedRole.subServiceLineName =
					localSubServiceLineItem?.subservicelinename;
			}

			if (role.countryIds && role.countryIds.length) {
				localizedRole.localizedCountries = [];
				role.countryIds.forEach((cId) => {
					let localizedCountryName = localizedCountries[cId];
					let localizedCountry = {};
					if (localizedCountryName != undefined) {
						localizedCountry = {
							id: cId,
							name: localizedCountryName
						};
					} else {
						localizedCountry = {
							id: cId,
							name: `${currentResource.labels.UnknownCountry} (${cId})`
						};
						// localizedRole.isMissingCountryLocal = true;
					}
					localizedRole.localizedCountries.push(localizedCountry);
				});
			}

			localizedRoleModels.push(localizedRole);
		}
	});
	return localizedRoleModels;
}
