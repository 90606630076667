/**
 * Created by Wes on 8/15/2016.
 */
import React from 'react';
import {render, hydrate} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import Routes from './components/Routes';

import configureStore from './store/configurestore';
import {tabTypes, displayGroupings} from './util/uiconstants';

/* this below import of svg appears unused, but it's required so
 * that Webpack will include it in the main package, enabling
 * SVG support in the UI. */
import './util/svg';
import env from './util/env';
import {getAppInsights} from './components/Common/AppInsight/TelemetryService';
import TelemetryProvider from './components/Common/AppInsight/TelemetryProvider';

document.addEventListener('DOMContentLoaded', () => {
	//config.serverURL = env.getServerUrl().split('api/v1')[0];
	/* Check that the user is logged in through Azure AD before rendering the UI */
	/*checkLoggedIn(config)
		.then(() => {
			console.log('checkLoggedIn success'); //eslint-disable-line
		})
		.catch((err) => {
			console.info(`checkLoggedIn failed - ${err}`); //eslint-disable-line
		});
		*/
	const store = configureStore(
		/* pass initial app state here, if needed */
		{
			myEngagements: {},
			myWorkspaces: {},
			myEngDashboard: {
				activeTab: tabTypes.AllEngagementsTab,
				groupingType: displayGroupings.LIST,
				refreshDashBoard: 0,
				refreshDashBoardPagnReset: 0
			},
			userSettings: {},
			userPermissions: {},
			archives: {},
			errors: {},
			viewAccessList: {},
			requestaccess: {},
			favoriteEngagements: {},
			favoriteWorkspaces: {},
			reviewEngagements: {},
			reviewWorkspaces: {},
			primaryoffices: {},
			servicelines: {},
			clientengagement: {},
			saveEngagement: {},
			spotfireDataCenter: '',
			knowledgeTemplate: {},
			selectTemplate: {},
			eyusers: {},
			singleEngagement: {},
			languages: {},
			arcFiles: {}
		}
	);
	store.dispatch({type: 'HOME_LOGIN'}).then(() => {
		//const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;
		//TODO: Please keep it in this way, do not use hydrate. Srujan is aware about it.
		const renderMethod = render;
		// process.env.NODE_ENV !== 'production' ? render : hydrate;
		let appInsights = null;
		let instrumentation_Key = env.getInstrumentationKey();
		console.log(instrumentation_Key);
		renderMethod(
			/*Provider component attaches the store to React components*/
			<Provider store={store}>
				<BrowserRouter>
					<TelemetryProvider
						instrumentationKey={instrumentation_Key}
						after={() => {
							appInsights = getAppInsights();
						}}
					>
						<Routes />
					</TelemetryProvider>
				</BrowserRouter>
			</Provider>,
			document.getElementById('mount')
		);
	});
});
