import * as types from './actiontypes';

export function setToastError(message) {
	return {
		type: types.SET_ERROR,
		toastMessage: message
	};
}

export function clearToastError() {
	return {
		type: types.CLEAR_ERROR
	};
}