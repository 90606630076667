import * as types from '../../actiontypes';
import {MSGraphURL} from '../../../util/uiconstants';

export function getEngagements(url) {
	return {
		type: types.GET_ALL_ENGAGEMENTS,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function saveEngagement(url, engagementData) {
	return {
		type: types.EDIT_ENGAGEMENT,
		meta: {
			api: {
				url: url,
				method: 'PUT',
				data: engagementData
			}
		}
	};
}

export function resetEngagementData() {
	return {
		type: types.RESET_ENGAGEMENT_DATA
	};
}

export function getEngagementUsers(url, resource) {
	return {
		type: types.GET_ALL_ENGAGEMENT_USERS,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function getEngagementDetail(url) {
	return {
		type: types.GET_ENGAGEMENT_BY_ID,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}
export function getEngagementstatus(url) {
	return {
		type: types.GET_STATUS,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function getServiceLines(url) {
	return {
		type: types.GET_SERVICE_LINE,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function getEngagementUserStatuses(url) {
	return {
		type: types.GET_ENGAGEMENT_USER_STATUSES,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function getIndependenceStatuses(url) {
	return {
		type: types.GET_INDEPENDENCE_STATUSES,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function getEmployeeADStatus(userEmailAddress) {
	var url = MSGraphURL?.replace('{0}', userEmailAddress);
	return {
		type: types.GET_EMPLOYEE_AD_STATUS,
		userEmailAddress,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function getRbacRoles(url, resource) {
	return {
		type: types.GET_ALL_RBAC_USER_ROLES,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data: {resource: resource}
			}
		}
	};
}

export function updateInternalUser(url, data) {
	return {
		type: types.UPDATE_INTERNAL_USER,
		meta: {
			api: {
				url: url,
				method: 'PUT',
				data: data
			}
		}
	};
}

export function inviteEngagementMember(url, data) {
	return {
		type: types.INVITE_ENGAGEMENT_MEMBERS,
		meta: {
			api: {
				url: url,
				method: 'POST',
				data: data
			}
		}
	};
}

export function triggerArchiveRetry(url) {
	return {
		type: types.TRIGGER_ARCHIVE_RETRY,
		meta: {
			api: {
				url: url,
				method: 'POST',
			}
		}
	};
}

export function setEngagementSearchParameters(parameters) {
	return {
		type: types.SET_ENGAGEMENT_SEARCH_PARAMETERS,
		parameters: parameters
	};
}
