import React from 'react';
import DocumentRow from './documentrow';

function DocumentsList(props) {
    return (
        <section id="" className="documentholder">
                {props.list.map((item, index) => (
                    <DocumentRow
                        key={index + item}
                        data={item}
                    />
                ))}
        </section>
    );
}

export default DocumentsList;
