/**
 * Created by rastwe on 3/1/2017.
 */
import {createStore, applyMiddleware, compose} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import rootReducer from '../reducers';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import {
	isOnlineMiddleware,
	// apiMiddleware,
	idbMiddleware,
	workerMiddleware,
	authenticationMiddleware
} from '@ey/voltron-core';
import apiMiddleware from '../middlewares/api-middleware';
import config from '../auth/config';
import offlineMiddleware from '../middlewares/offline-middleware';
import versionCheck from '../middlewares/version-check';
import idleLogoutMiddleware from '../middlewares/idleLogout-middleware';
import loginContextMiddleware from '../middlewares/loginContext-middleware';
/**
 * Configures and creates a Redux store that includes all reducers and
 * the initial state of the application.
 * The devToolsExtension reference in the compose() call is the Chrome
 * implementation of the Redux development tool. Refer to:
 * https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
 * Note that the reduxImmutableStateInvariant middleware is needed only
 * in dev mode to prevent developers from modifying the state directly.
 * Doing so will produce an error.
 * Thunk is here to use for async operations like Ajax.
 * @param {Object} initialState - The initial state of your application.
 *      Particularly useful for server side rendering.
 * @return {*} A Redux Store instance
 */
export default function configureStore(initialState) {
	return createStore(
		rootReducer,
		initialState,
		// Initiate redux dev tools
		composeWithDevTools(
			applyMiddleware(
				isOnlineMiddleware(config),
				idleLogoutMiddleware(config),
				loginContextMiddleware(config),
				authenticationMiddleware(config),
				apiMiddleware(config),
				versionCheck(config),
				offlineMiddleware(config),
				workerMiddleware(config),
				idbMiddleware(config),
				typeof window !== 'undefined' && reduxImmutableStateInvariant()
			)
		)
	);
}
