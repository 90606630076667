export const EngagementStatus = {
	Undefined: 0,
	Active: 1,
	Suspended: 2,
	Archived: 3,
	Deleted: 4,
	ArchiveInProgress: 5,
	Reactivated: 6,
	Restored: 7,
	MarkedForDeletion: 8,
	ArchiveError: 9,
	RollForwardError: 10,
	ReactivateError: 11,
	RestoreError: 12,
	RollForwardInProgress: 13,
	ReactivateInProgress: 14,
	RestoreInProgress: 15,
	GamxMigrationInProgress: 16,
	GamxMigrationError: 17,
	CreateTransactionPending: 18,
	CreateTransactionFailed: 19,
	UpdateTransactionPending: 20,
	UpdateTransactionFailed: 21,
	CopyInProgress: 22,
	CopyError: 23,
	CopyTemplateError: 24,
	ExternalReviewEngagementInProgress: 25,
	ExternalReviewEngagementError: 26,
	ExternalReviewEngagement: 27,
	LocalizedtoIsolatedUniversal: 28,
	LocalizedtoIsolatedUniversalArchived: 29,
	ContentDeliveryInProgress: 30,
	ContentDeliveryError: 31,
	ContentDeliveryFail: 32,
	CanvasExternalAccessPortalV2: 33,
	CanvasExternalAccessPortalVInProgress: 34,
	CanvasExternalAccessPortalVError: 35,
	DatacenterSplit: 100
};

export const EngagementReasonType = {
	TrainingEngagement: 1,
	RestoredOrCopiedEngagement: 2,
	CreatedByMistake: 3,
	Other: 5,
	TerminatedByClient: 6
};

export const IndependenceStatus = {
	Incomplete: 1,
	NoMattersIdentified: 2,
	MatterIdentifiedPendingAction: 3,
	MatterResolvedDeniedAccess: 4,
	MatterResolvedGrantedAccess: 5,
	NotApplicable: 6,
	Restored: 7,
	Override: 8,
	PriorNoMattersIdentified: 9,
	PriorMatterIdentifiedPendingAction: 10,
	PriorMatterResolvedGrantedAccess: 11,
	PriorMatterResolvedDeniedAccess: 12
};

export const CopyObjectGroupType = {
	Engagement: 1,
	Accounts: 2,
	SCOTs: 3,
	ITApplicationsServiceOrganizations: 4,
	Requests: 5,
	Evidence: 6,
	Numbers: 7,
	RequestsandExternalTasks: 8,
	TemporaryFiles: 9,
	PriorPeriodEvidence: 10,
	RecentlyDeletedFiles: 11,
	HistoryFiles: 12,
	EngagementTemplate: 13,
	GCOCopyTemplate: 14,
	ExternalReview: 15,
	ActiveEngagementExternalReview: 16,
	CanvasForms: 17,
	V2Evidence: 20,
	V2Engagement: 21,
	V2Accounts: 22,
	V2SCOTs: 23,
	V2EngagementRollforwardCopy: 24,
	V2ExternalEvidences: 27,
	KnowledgeDeliveredEvidence: 28
};

export const engagementUserStatus = {
	Undefined: 0,
	Active: 1,
	Pending: 2,
	Rejected: 3,
	DeActivated: 4,
	RequestForArchiveAccess: 5,
	RejectArchiveAccess: 6,
	CopyEngagementInactive: 7,
	UnlinkedInactive: 8,
	TemplateInactive: 9,
	RollForwardInactive: 10,
	LocalizedToIsolatedUniversal: 11,
	InvitationInProgress: 12,
	ScheduledInactive: 13
};

export const CopyRequestStatus = {
	Ready: 1,
	Sent: 2,
	Received: 3
};

export const GroupAuditRequest = {
	Accepted: 3,
	Delegated: 8
};

export const ActionTypes = {
	SystemMessages: 1,
	EngagementInvitation: 2,
	DeniedAccess: 3,
	DeleteRequest: 4,
	CreateError: 5,
	ArchiveAddition: 6,
	DeletedEngagement: 7,
	ArchivedEngagement: 8,
	DeactivatedEngagements: 9,
	ArchiveDeadlineDateOverride: 10,
	GroupAuditInvite: 11,
	GaAInstruction: 12,
	CopiesReceived: 13,
	EngagementInProgress: 14,
	EngagementProfileIncomplete: 15,
	EngagementIndependenceIncomplete: 16,
	EngagementAccepted: 17
};

export const ArchiveOverrideStatus = {
	InProgress: 1,
	Accepted: 2,
	NotAllowedForCountry: 3,
	OverrideDaysGreaterThanDefaultValue: 4,
	OverrideDaysInvalid: 5,
	Failed: 6
};

export const EngagementSource = {
	RollForward: 1,
	Restore: 2,
	CopyEngagement: 3,
	CreateNew: 4,
	EngagementTemplate: 5,
	ExternalReviewEngagement: 6,
	GCOEngagement: 7,
	RestoreException: 8,
	CeapV2: 9,
	CeapV2Protected: 10
};

export const UserAcknowledgementType = {
	Archived: 1,
	Deleted: 2,
	DeActivated: 3,
	ArchiveOverride: 4,
	MatterResolvedDeniedAccess: 5
};

export const EngagementAccessType = {
	Default: 1,
	IndependenceOnly: 2
};
