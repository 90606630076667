import * as types from '../actions/actiontypes';
import initialState from './initialstate';
import $ from 'jquery';
/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export default function TimelineReducer(state = initialState.timeline, action) {
	switch (action.type) {
		case types.GET_ENGAGEMENT_TIMELINE:
			return {
				timeline: AddOrReplaceItem(state.timeline, {
					id: action.response.id,
					data: action.response
				})
			};
		default:
			return state;
	}
}

function AddOrReplaceItem(array, item) {
	if (Array.isArray(array)) {
		const itemIndex = array.findIndex((element) => element.id === item.id);

		if (itemIndex > -1) {
			return [
				...array.slice(0, itemIndex),
				item,
				...array.slice(itemIndex + 1)
			];
		} else {
			const newArray = array.slice();
			newArray.push(item);
			return newArray;
		}
	}

	return [item];
}
