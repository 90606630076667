import initialState from './initialstate';
import _ from 'lodash';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export default function AppOnlineStateReducer(
	state = initialState.myWorkspaces,
	action
) {
	switch (action.type) {
		case 'UPDATE_APP_ONLINE_STATE':
			/* Notice that this is simply returning the action payload, <%=names.camel%>.
			 * This is because the combineReducers() call wraps this reducer
			 * and passes it only the portion of the store state that it
			 * is concerned with. */
			console.log(`[${action.type}]: appOnlineState reducer -`, action);
			if (_.isBoolean(action.isOnline)) {
				return action.isOnline;
			}
			return action.response.isOnline;
		default:
			return state;
	}
}
