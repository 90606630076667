import styled from 'styled-components';

const Wrapper = styled.section`
	.loadingstyle {
		height: calc(100vh - 25rem) !important;
		
		& > section {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			margin: 0 auto;
			display: flex;
		}
	}

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: 0rem;

		.pagin-btn[disabled] {
			svg {
				fill: ${(props) => props.theme.color[`grey400`]};
			}
		}
	}

	.permissiongroup-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: 1.4rem;
		background: ${(props) => props.theme.color[`white`]};
		border-top: 1px solid ${(props) => props.theme.color[`grey200`]};
	}

	.mainwrapper {
		overflow: hidden;
		padding-top: 5rem;

		.toastMessage {
			.motif-toast-text-container {
				font-size: 1.4rem;
			}

			&.block {
				display: block;
			}

			&.none {
				display: none;
			}
		}

		.message {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: calc(100vh - 18rem);

			&h5 {
				color: ${(props) => props.theme.color[`grey500`]};
			}
		}

		//heading

		.headerdetail {
			display: flex;
			padding: 1.2rem 0;

			.title {
				margin-right: auto;
				.activediretory {
					width: 100%;
					display: inline-block;

					&.title {
						font-weight: bold;
					}
				}
			}

			.addmember .motif-button {
				font-size: 1.2rem;
				width: 10rem;
				height: 4rem;
				margin-top: 0.5rem;

				&[disabled] {
					pointer-events: none;
				}
			}

			.title,
			.addmember.button {
				display: block;
				font-size: 1.4rem;
			}
		}

		.permissiongroup {
			.pageheading {
				display: flex;
				padding: 2rem 0;
				svg {
					min-width: 2.5rem !important;
					min-height: 2.5rem !important;
					margin-right: 1rem;
				}

				h3 {
					margin: 0;
					line-height: 2.5rem;
				}
			}
		}

		//addon

		.add-on {
			display: flex;
			margin-right: auto;
			padding: 0 0 2rem 0;

			.adduser {
				.motif-button {
					height: 4rem;
					font-size: 1.2rem;
					width: 10rem;
				}
			}

			.search {
				width: 16%;
				line-height: 4rem;
				margin-right: auto;

				.motif-icon-button{
					top: 0.5rem;
				}

				.motif-typeahead-close-button {
					top: 0.4rem;
					&:hover{
						background: none!important;
					}
				}

				.motif-input {
					font-size: 1.4rem;
					font-weight: normal;
					height: 4rem;
					&::placeholder {
						font-size: 1.4rem;
						font-weight: normal;
					}
				}
			}
		}

		.norecord {
			height: calc(100vh - 30rem);
			display: inline-block;
			width: 100%;
			display: flex;
			justify-content: center;
			flex: 0 auto;
			align-items: center;
			font-size: 1.6rem;
			color: ${(props) => props.theme.color[`grey400`]};
		}

		.managepermissionTable {
			height: 100%;
			overflow-y: auto;
			user-select: none;
			.errorRow {
				color: ${(props) => props.theme.color[`red600`]} !important;
			}
		}
	}
`;
export default Wrapper;
