import React, { useState} from 'react';
import { MotifButton, MotifInput, MotifErrorMessage, MotifFormField, MotifLabel } from '@ey-xd/motif-react';
import { labels } from '../../../util/uiconstants';
import Loader from '../../Common/Loaders/loadingindicator';
import styled from 'styled-components';
import ModalControl from '../../Common/Modal/Modal';



const TicketInputWrapper = styled.section`
.ticketdetail{
    .loadingstyle {
		height: calc(100vh - 78rem) !important;
	}

	.modal-center{
        .message{
            padding: 2rem 2rem 8rem;
            h5{
                font-size: 1.4rem;
                color: ${(props) => props.theme.color[`grey500`]};
            }
        }

		.confirmationmessage {
			h5 {
				font-size: 1.4rem;
				font-weight: normal;

			}	
		}

		.motif-form-field{
			width: 29rem;
		}

		.motif-form-field:not(.motif-form-field-has-value),
		.motif-form-field:not(.motif-form-field-focused){
			.motif-label{
				font-size: 1.4rem;
				font-weight:normal;
				margin-top: 0.6rem;
				color: ${(props) => props.theme.color[`black`]};
			}
		}

		.motif-form-field-has-value,
		.motif-form-field-focused{
			.motif-label{
				font-size: 1.2rem!important;
				font-weight: normal!important;
				margin-top: 0.2rem!important;
				color: ${(props) => props.theme.color[`black`]};
			}
		}
		
		.motif-input {
			font-size: 1.4rem;
			line-height: 3rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			color: ${(props) => props.theme.color[`black`]};
			&::placeholder {
				font-size: 1.4rem;
			}
		}

		.modal-footer {
			width: 100%;
			padding: 2rem !important;
			display: inline-flex;
			button {
				font-size: 1.4rem;
				padding: 1.2rem 1.5rem;
				margin-right: 1rem;
				line-height: normal;
			}
		}

        .motif-error-message {
            font-size: 1.2rem;
        }
        }
	} 


`;

function TicketInput(props) {
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	const closeTicketModal = () => {
		props.onHide(false);
	};

	const onConfirmClick = () => {
		if (ticketNumber.trim().length == 0) {
			setIsSubmitClicked(true);
		} else {
			props.onConfirmClick(ticketNumber.trim());
			props.onHide(false);
		}
	};

	return (
		<TicketInputWrapper>
         <section className="ticketdetail">
				<section className="modal-center">
					<section className="message">
						<section className="confirmationmessage" aria-label="message">
							<h5>
								{props.confirmationMessage
									? props.confirmationMessage
									: labels.confirmationMessage}
							</h5>
						</section>
						<section>
							<section>
								<MotifFormField>
									<MotifLabel>{labels.ticketNumber}</MotifLabel>
									<MotifInput
										value={ticketNumber}
										maxLength={100}
										clearButtonTitle={labels.clear}
										onChange={(event) => {
											setTicketNumber(event.target.value);
										}}
									></MotifInput>
									{ticketNumber.trim().length == 0 && isSubmitClicked && (
										<MotifErrorMessage>
											{labels.validationMessage}
										</MotifErrorMessage>
									)}
								</MotifFormField>
							</section>
						</section>
					</section>
					<section className="modal-footer">
						<MotifButton
							type="button"
							variant="primary-alt"
							disabled={ticketNumber.trim().length == 0 ? true : false}
							onClick={() => {
								onConfirmClick();
							}}
						>
							{props.confirmButtonLabel
								? props.confirmButtonLabel
								: labels.save}
						</MotifButton>
						<MotifButton
							type="button"
							variant="secondary"
							onClick={() => closeTicketModal()}
						>
							{props.cancelButtonLabel
								? props.cancelButtonLabel
								: labels.cancelButtonLabel}
						</MotifButton>
					</section>
				</section>
            </section>
		</TicketInputWrapper>
	);}


function TicketModal(props) {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<>
			{isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<ModalControl
					title={props.modalHeaderLabel}
					onOkClick={() => {}}
					onHide={() => props.onHide(false)}
					isOkButtonDisabled={true}
					show={true}
					modalsize="message"
					showButtons={false}
					closeTitle={labels.close}
					role="dialog"
				>
					<TicketInput
						onHide={props.onHide}
						confirmationMessage={props.confirmationMessage}
						confirmButtonLabel={props.confirmButtonLabel}
						cancelButtonLabel={props.cancelButtonLabel}
						onConfirmClick={props.onConfirmClick}/>
				</ModalControl>
			)}
		</>
	);
}
export default TicketModal;