import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { rbacRoleKeys, rbacSections } from '../../../util/uiconstants';
import { currentResource } from '../../../util/uiconstants';
import CheckBox from '../../Common/Checkbox/CheckBox';

function Permissions(props) {
	const [rbacRoles, setRbacRoles] = useState([]);
	const [canEditPermission, setCanEditPermission] = useState(
		props?.canEditPermission
	);
	const [isEditMode, setisEditMode] = useState(props?.isEditMode);

	// Use effect to construct and check uncheck (edit scenario) roles list.
	useEffect(() => {
		if (props?.rbacRoleList && props?.rbacRoleList.length > 0) {
			let roles = props?.userRoles ? [...props.userRoles] : [];
			setRbacRoles(constructPermissions(roles));
		}
	}, [props?.rbacRoleList, props?.userRoles]);

	const constructPermissions = (userRoles) => {
		let internalUserRoles = currentResource.internalUserRoles;

		//Removing confidentiality roles from display
		internalUserRoles = _.filter(
			internalUserRoles,
			function (internalUserRole) {
				return internalUserRole.sectionId != 1;
			}
		);

		if (props.isThirdPartyUser) {
			internalUserRoles = _.filter(
				internalUserRoles,
				function (internalUserRole) {
					return (
						internalUserRole.roleKey !== rbacRoleKeys.InternalReviewOnlyRole
					);
				}
			);
		} else {
			internalUserRoles = _.filter(
				internalUserRoles,
				function (internalUserRole) {
					return (
						internalUserRole.roleKey !== rbacRoleKeys.ThirdPartyUserReadOnly
					);
				}
			);
		}
		let thirdPartyUserReadOnlyKeyValue = rbacRoleKeys.ThirdPartyUserReadOnly;
		let mergedLocalizedList = _.map(
			internalUserRoles,
			function (localizedRole) {
				return _.extend(
					localizedRole,
					_.find(props?.rbacRoleList, function (rbacRole) {
						localizedRole.checked = false;
						localizedRole.disabled = false;

						if (_.find(userRoles, { roleKey: localizedRole.roleKey })) {
							localizedRole.checked = true;
						} else if (
							props.isThirdPartyUser &&
							!isEditMode &&
							localizedRole.roleKey === thirdPartyUserReadOnlyKeyValue
						) {
							localizedRole.checked = true;
						}

						return rbacRole.roleKey === localizedRole.roleKey;
					})
				);
			}
		);

		var sortedList = _.chain(mergedLocalizedList)
			.sortBy(function (rbacRoles) {
				return rbacRoles.roleDisplayOrder;
			})
			.sortBy(function (rbacRoles) {
				return rbacRoles.groupDisplayOrder;
			})
			.value();

		if (isEditMode && !props.isThirdPartyUser) {
			var reviewOnlyRoleSelected =
				sortedList.findIndex(
					(rbacRole) =>
						rbacRole.roleKey == rbacRoleKeys.InternalReviewOnlyRole &&
						rbacRole.checked
				) !== -1;
			reviewOnlyRole(true, sortedList, reviewOnlyRoleSelected);
		}
		props.updatedUserPermissions(sortedList);
		return sortedList;
	};

	const reviewOnlyRole = (isEditMode, rbacRoles, reviewOnlyRoleSelected) => {
		if (reviewOnlyRoleSelected) {
			_.forEach(rbacRoles, function (item) {
				if (item.roleKey !== rbacRoleKeys.InternalReviewOnlyRole) {
					item.disabled = true;
					item.checked = false;
				}
			});
		}

		if (!isEditMode && !reviewOnlyRoleSelected) {
			_.forEach(rbacRoles, function (item) {
				item.disabled = false;
			});
		}
	};

	const checkboxHandler = (element) => {
		if (element.id == rbacRoleKeys.InternalReviewOnlyRole) {
			var reviewOnlyRoleSelected =
				rbacRoles.findIndex(
					(rbacRole) =>
						rbacRole.roleKey == rbacRoleKeys.InternalReviewOnlyRole &&
						rbacRole.checked
				) !== -1;
			reviewOnlyRole(false, rbacRoles, !reviewOnlyRoleSelected);
		}

		let updateRoleList = [...rbacRoles];
		let index = updateRoleList.findIndex((item) => item.id === element.value);
		if (index >= 0) {
			updateRoleList[index].checked = !updateRoleList[index].checked;
		}

		//Independence only role should get deselected when any other role is selected
		updateRoleList = updateRoleList.map(role => ({
			...role,
			checked: role.roleKey === rbacRoleKeys.IndependenceOnlyRole ? false : role.checked,
		}));

		setRbacRoles(updateRoleList);
		props.updatedUserPermissions(updateRoleList);
	};

	let allGroups = rbacRoles.map((rbacRole) => {
		return { groupName: rbacRole.groupName, sectionId: rbacRole.sectionId };
	});

	let uniqueGroups = _.uniqWith(allGroups, _.isEqual);

	const isCheckBoxDisabled = (role) => {
		let isRoleDisabled = props.disabled || role.disabled || 
		    (role.roleKey === rbacRoleKeys.IndependenceOnlyRole) ||
			(props.isThirdPartyUser && role.sectionId !== rbacSections.Confidentiality);

		return (!canEditPermission || isRoleDisabled);
	}

	return (
		<div>
			{uniqueGroups.map((grp) => {
				return (
					<section className="checklist">
						<header className="headerchecklist">
							<h4>{grp.groupName}</h4>
						</header>
						<ul className="checkoption-list" key={grp.sectionId}>
							{rbacRoles.filter((role) => role.sectionId === grp.sectionId)
								.map((item) => {
									return (
										<li key={item.id}>
											<CheckBox
												styleName="checkoption-item"
												label={item.displayName}
												id={item.roleKey}
												value={item.id}
												name={'chk_' + item.id}
												onChange={(e) => { checkboxHandler(e.target) }}
												checked={item.checked}
												disabled={isCheckBoxDisabled(item)}
												hidetitletip
											/>

											<section className="infotooltip">
												<span className="tooltip-downarrow"></span>
												<span>{item.tooltipText}</span>
											</section>
										</li>
									);
								})}
						</ul>
					</section>
				);
			})}
		</div>
	);
}

export default Permissions;
