import React from 'react';
import EllipsesControl from '@ey/ellipses-control';

function DocumentRow(props) {
    return (
        <section className="documentitemview">
            {/* docid */}
            <section className="documentitem grid id">
                    <section className="description">
                    <EllipsesControl
                                content={props.data.documentId}
                                tooltip={props.data.documentId}
                                displayId={''}
                                noOfLines={1}
                                ellipsisClass=""
                            />                     
                    </section>
            </section>
            {/* doc version */}
            <section className="documentitem grid version">
                    <section className="description">
                    <EllipsesControl
                                content={props.data.documentVersion}
                                tooltip={props.data.documentVersion}
                                displayId={''}
                                noOfLines={1}
                                ellipsisClass=""
                            />                
                    </section>
            </section>
            {/* doc name */}
            <section className="documentitem grid description">
                     <section className="description">
                     <EllipsesControl
                                content={props.data.documentName}
                                tooltip={props.data.documentName}
                                displayId={''}
                                noOfLines={1}
                                ellipsisClass=""
                            />                     
                    </section>
            </section>
            {/* doc fileSize */}
            <section className="documentitem grid filesize">
                    <section className="description">
                    <EllipsesControl
                                content={props.data.fileSize}
                                tooltip={props.data.fileSize}
                                displayId={''}
                                noOfLines={1}
                                ellipsisClass=""
                            />
                    </section>
            </section>
            {/* doc contenturi */}
            <section className="documentitem grid uri">
                    <section className="description">
                        <EllipsesControl
                                content={props.data.contentUri}
                                tooltip={props.data.contentUri}
                                displayId={''}
                                noOfLines={1}
                                ellipsisClass=""
                            />
                    </section>
            </section>
            {/* doc file exists */}
            <section className="documentitem grid fileexist">
                    <section className="description">
                    <EllipsesControl
                                content= {'' + props.data.fileExists}
                                tooltip= {'' + props.data.fileExists}
                                displayId={''}
                                noOfLines={1}
                                ellipsisClass=""
                            />                
                    </section>
            </section>
        </section>
    );
}

export default DocumentRow;
