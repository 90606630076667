import _ from 'lodash';
import * as types from '../actions/actiontypes';
import initialState from './initialstate';

export default function engagementErrorReducer(
    state = initialState.engagementError,
    action
) {
    switch (action.type) {
        case types.SET_ERROR:
        if(action?.toastMessage){
            let newState = _.cloneDeep(state);
            newState.message = action.toastMessage;
            return newState;
        }
            return state;
        case types.CLEAR_ERROR:
            return {};
        default:
            return state;
    }
}