/**
 * SummaryScreens.js
 * Created by calhosh on 12/17/2019.
 */
import React from 'react';
import {
	labels,
	currentResource,
	resourceFileNames
} from '../../util/uiconstants';
import {SVGICONS} from '@ey/icons';
import EllipsesControl from '@ey/ellipses-control';

const SummaryScreens = (props) => {
	const {
		isInterim,
		isGroupAudit,
		spotfireUri,
		engagementId,
		engagementVersion,
		language
	} = props;

	return (
		<section className="ReportingWrapper">
			<section className="ReportingHeader">
				<SVGICONS
					className="receiptIcon"
					hoverText={labels.summaryScreensLabel}
					name="receipt"
				/>
				{labels.summaryScreensLabel}
			</section>
			{isInterim === true && isGroupAudit === true ? (
				<ul className="ReportingLinks">
					{currentResource.summaryScreenGroupAuditInterim.map((report, i) => {
						return (
							<li key={i}>
								<a
									key={i}
									href={spotfireUri
										.replace(
											'{0}',
											currentResource.summaryScreenGroupAuditInterimReportNames[
												report
											]
										)
										.replace('{1}', engagementId)
										.replace('{2}', engagementVersion)
										.replace('{3}', resourceFileNames[language])}
									target="_blank"
								>
									<EllipsesControl
										content={report}
										tooltip={report}
										isTooltipAvailable
									/>
								</a>
							</li>
						);
					})}
				</ul>
			) : (
				<>
					{isInterim === false && isGroupAudit === true ? (
						<ul className="ReportingLinks">
							{currentResource.summaryScreenGroupAudit.map((report, i) => {
								return (
									<li key={i}>
										<a
											key={i}
											href={spotfireUri
												.replace(
													'{0}',
													currentResource.summaryScreenGroupAuditReportNames[
														report
													]
												)
												.replace('{1}', engagementId)
												.replace('{2}', engagementVersion)
												.replace('{3}', resourceFileNames[language])}
											target="_blank"
										>
											<EllipsesControl
												content={report}
												tooltip={report}
												isTooltipAvailable
											/>
										</a>
									</li>
								);
							})}
						</ul>
					) : (
						<>
							{isInterim === true && isGroupAudit === false ? (
								<ul className="ReportingLinks">
									{currentResource.summaryScreenInterim.map((report, i) => {
										return (
											<li key={i}>
												<a
													key={i}
													href={spotfireUri
														.replace(
															'{0}',
															currentResource.summaryScreenInterimReportNames[
																report
															]
														)
														.replace('{1}', engagementId)
														.replace('{2}', engagementVersion)
														.replace('{3}', resourceFileNames[language])}
													target="_blank"
												>
													<EllipsesControl
														content={report}
														tooltip={report}
														isTooltipAvailable
													/>
												</a>
											</li>
										);
									})}
								</ul>
							) : (
								<ul className="ReportingLinks">
									{currentResource.summaryScreenDefaults.map((report, i) => {
										return (
											<li key={i}>
												<a
													key={i}
													href={spotfireUri
														.replace(
															'{0}',
															currentResource.summaryScreenDefaultsReportNames[
																report
															]
														)
														.replace('{1}', engagementId)
														.replace('{2}', engagementVersion)
														.replace('{3}', resourceFileNames[language])}
													target="_blank"
												>
													<EllipsesControl
														content={report}
														tooltip={report}
														isTooltipAvailable
													/>
												</a>
											</li>
										);
									})}
								</ul>
							)}
						</>
					)}
				</>
			)}
		</section>
	);
};

export default SummaryScreens;
