import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import {
	MotifToggleSwitch,
	MotifButton,
	MotifLabel,
	MotifFormField,
	MotifInput,
	MotifOption,
	MotifSelect
} from '@ey-xd/motif-react';
import {labels} from '../../../../util/uiconstants';
import {getRegion} from '../../../../actions/admin/masterdataactions';
import {editTemplate} from '../../../../actions/admin/templatemanagementaction';
import {clearErrors} from '../../../../actions/erroractions';
import {setIsModalOpen} from '../../../../actions/modalstateactions';
import Loader from '../../../Common/Loaders/loadingindicator';
import env from '../../../../util/env';
import Toast from '../../../Common/Toast/Toast';
import EllipsesControl from '@ey/ellipses-control';
import {getLocalizedAPIError} from '../../common/util';
import TicketField from '../../common/ticketfield';

function TemplateDetails(props) {
	// hooks
	var editTemplateData = props.editTemplateData;
	const [templateName, setTemplateName] = useState(
		editTemplateData?.templateName
	);
	const [status, setStatus] = useState(editTemplateData?.isPublished);
	const [selectedRegionIds, setRegionIds] = useState(
		editTemplateData?.regions?.map((x) => x.regionId.toString())
	);
	const [isAssignButonDisable, setIsAssignButonDisable] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const regions = useSelector((store) => store.regions);
	const [errorMessage, setErrorMessage] = useState(false);
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		clearError(true);
		if (Object.keys(regions).length === 0) {
			getRegions();
		}
	}, []);

	useEffect(() => {
		if (
			templateName == editTemplateData?.templateName &&
			selectedRegionIds.every((r) =>
				editTemplateData?.regions?.some((er) => er.regionId == r)
			) &&
			editTemplateData.regions.length == selectedRegionIds.length &&
			status == editTemplateData?.isPublished ||
			ticketNumber.trim().length <= 0
		) {
			setIsAssignButonDisable(true);
		} else if (
			templateName?.trim() &&
			selectedRegionIds &&
			selectedRegionIds?.length > 0
		) {
			setIsAssignButonDisable(false);
		} else {
			setIsAssignButonDisable(true);
		}
	}, [templateName, selectedRegionIds, status, ticketNumber]);

	const onTicketChanged = (e) => {
		setTicketNumber(e.target.value);
	}

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	const handlerOnEditTemplateName = (e) => {
		setTemplateName(e.target.value);
	};

	const saveTemplate = () => {
		setIsSubmitClicked(true);
		if (ticketNumber.trim().length <= 0) {
			return;
		}

		setIsLoading(true);
		const regionIds = selectedRegionIds?.map((regionId) => {
			return Number(regionId);
		});

		var templateDetails = {
			templateName: templateName,
			isTemplatePusblished: status,
			areaRegionList: regionIds ? regionIds : [],
			ticketNumber: ticketNumber
		};

		let editTemplateUrl =
			env.getURL('serviceUrlV2') +
			`admin/engagementtemplates/${editTemplateData.copyRequestId}`;
		dispatch(editTemplate(editTemplateUrl, templateDetails))
			.then(() => {
				props.setIsTemplateEdited(true);
				props.closeEditTemplateModal();
				props.setEditTemplateSuccessMessage(labels.templateEditSuccessMessage);
			})
			.catch((result) => {
				var localError = getLocalizedAPIError(result);
				setErrorMessage(localError);
			})
			.finally(() => {
				setIsLoading(false);
				setIsSubmitClicked(false);
			});
	};

	const formatOptionLabel = ({value, label}) => (
		<EllipsesControl content={label} tooltip={label} noOfLines={1} />
	);

	const dropDownOnChange = (option) => {
		setRegionIds(option);
	};

	const getRegions = () => {
		setIsLoading(true);
		let getRegionUrl = env.getURL('serviceUrlV2') + 'Regions';
		dispatch(getRegion(getRegionUrl))
			.then(() => {})
			.catch((e) => {
				setErrorMessage(e.toString());
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const getRegionsForDropDown = () => {
		let regionCollection = [];
		if (Object.keys(regions).length > 0) {
			_.forEach(regions, (item) => {
				regionCollection.push({
					label: item.regionName,
					value: item.regionID.toString()
				});
			});
		}
		return regionCollection;
	};

	const createDropDown = (collection) => {
		return (
			<MotifFormField>
				<MotifLabel id="select-label-search">
					{labels.templateAreaRegion}
				</MotifLabel>
				<MotifSelect
					id={'region'}
					onChange={(e) => dropDownOnChange(e)}
					filter
					arialabelledby="select-label-search"
					multiple={true}
					showselectallbutton={true}
					value={selectedRegionIds}
				>
					{collection?.map((item) => (
						<MotifOption
							key={item.value}
							value={item.value}
							label={item.label}
							formatOptionLabel={formatOptionLabel(item.value)}
						>
							{item.label}
						</MotifOption>
					))}
				</MotifSelect>
			</MotifFormField>
		);
	};

	const hideEditTemplateDrawer = () => {
		setTemplateName(null);
		setRegionIds(null);
		setErrorMessage(false);
		props.closeEditTemplateModal();
	};

	return (
		<Mainwrapper>
			{isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<section>
					<section
						className="toastMessage"
						style={{display: errorMessage ? 'block' : 'none'}}
					>
						<Toast
							variant="error"
							onClose={() => {
								setErrorMessage(false);
							}}
						>
							{errorMessage}
						</Toast>
					</section>
					<section className="edittemplate">
						<MotifFormField>
							<MotifLabel>{labels.templateName}</MotifLabel>
							<MotifInput
								value={templateName}
								maxLength={500}
								clearButtonTitle={labels.clear}
								onChange={handlerOnEditTemplateName}
							/>
						</MotifFormField>
						{createDropDown(getRegionsForDropDown())}
						<section className="status">
							<section className="showinactive">
								<MotifLabel className={!status ? 'bold' : ''}>
									{labels.templateUnpublished}
								</MotifLabel>
								<MotifToggleSwitch
									aria-describedby="showstatus"
									checked={status}
									onChange={(event) => {
										setStatus(event.target.checked);
									}}
									id="toggle1"
									hideLabels={true}
								/>
								<MotifLabel className={status ? 'bold' : ''}>
									{labels.templatePublished}
								</MotifLabel>
							</section>
						</section>
						<TicketField
							ticketNumber={ticketNumber}
							onTicketChanged={onTicketChanged}
							showValidation={isSubmitClicked}
						/>
					</section>
				</section>
			)}
			<section className="modal-footer">
				<MotifButton
					type="button"
					variant="primary-alt"
					onClick={() => saveTemplate()}
					disabled={isAssignButonDisable}
				>
					{labels.templateEditSave}
				</MotifButton>
				<MotifButton
					type="button"
					variant="secondary"
					onClick={() => hideEditTemplateDrawer()}
				>
					{labels.templateEditCancel}
				</MotifButton>
			</section>
		</Mainwrapper>
	);
}

const Mainwrapper = styled.section`
	.toastMessage {
		.motif-icon-button:hover,
		.motif-icon-button:focus,
		.motif-icon-button:focus svg {
			box-shadow: none;
		}
	}

	.edittemplate {
		padding: 2rem;
		height: 34rem;
		//ticketstart
		.ticketdetail{
			margin: 0;
			padding-bottom: 2rem;
			h5{
				font-size: 1.4rem;
				font-weight: normal;
				color: ${(props) => props.theme.color[`grey500`]};
				border: 0!important;
			}

			.motif-form-field{
				width: 29rem;
			}

			.motif-form-field:not(.motif-form-field-has-value),
			.motif-form-field:not(.motif-form-field-focused){
				.motif-label{
					font-size: 1.4rem;
					font-weight:normal;
					margin-top: 0.6rem;
					color: ${(props) => props.theme.color[`black`]};
				}
			}

			.motif-form-field-has-value,
			.motif-form-field-focused{
				.motif-label{
					font-size: 1.2rem!important;
					font-weight: normal!important;
					margin-top: 0.2rem!important;
					color: ${(props) => props.theme.color[`black`]};
				}
			}
			
			
			.motif-input {
				font-size: 1.4rem;
				line-height: 3rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				color: ${(props) => props.theme.color[`black`]};
				&::placeholder {
					font-size: 1.4rem;
				}
			}
		}
		//ticketend

		.motif-input-clear-button:hover {
			box-shadow: none !important;
		}

		& > * {
			font-size: 1.4rem;
		}

		.status {
			display: flex;
			padding: 0 0 2rem 0;

			.motif-label {
				margin-top: 0.8rem;
			}

			& > .showinactive .motif-label:not(.bold){
				font-weight: normal;
			}

			& > .showinactive > * {
				font-size: 1.2rem;
				&:not(.motif-toggle-switch-wrapper) {
					margin-top: 0.8rem;
				}
			}

			& > .showinactive,
			& > .motif-label {
				display: inline-flex;
				font-size: 1.4rem;
			}
		}

		.bold {
			font-weight: bold;
			font-size: 1.4rem;
		}

		//modal field
		.motif-form-field-select-input .motif-select-field-list {
			overflow: hidden !important;
			.motif-select-list-options {
				max-height: 16rem !important;
			}
		}

		.labeltext {
			font-size: 1.4rem;
			margin: 0;
			padding: 0 0 2rem 0;
		}
		.motif-form-field {
			font-size: 1.4rem !important;
			padding-bottom: 1rem;

			.motif-select-input{
				height: 4rem;
			}

			.motif-select-wrapper-options{
				.motif-select-search-input{
					font-size: 1.4rem;
				}
			}

			.motif-input-component {
				input.motif-input {
					font-size: 1.2rem;
					height: 4rem;
					padding: 2.5rem 3rem 1.5rem 1rem;
				}
			}

			.motif-error-message {
				font-size: 1.4rem;
				margin-top: 0.5rem;
				svg {
					width: 1.8rem;
					height: 1.8rem;
				}
			}

			.motif-select-field {
				.motif-checkbox-label-wrap {
					margin: 0;
				}

				.motif-inner-search {
					font-size: 1.4rem;
				}

				button {
					font-size: 1.4rem !important;
					height: 4rem;
					padding-top: 1.5rem;

					svg.motif-checkbox-checked-icon {
						margin-top: 0;
					}

					svg {
						margin-top: 0.5rem;
					}
				}
			}

			.motif-label {
				font-size: 1.2rem !important;
				margin-top: 0.2rem;
			}
		}
	}

	.modal-footer {
		display: inline-flex;
		width: 100%;
		padding: 2rem !important;
		button {
			font-size: 1.4rem;
			margin-right: 1rem;
			padding: 1.2rem 1.5rem;
			line-height: normal;
		}
	}

	.loadingstyle {
		height: 34rem !important;
	}

	//heading
	.templatemanagement {
		.pageheading {
			display: flex;
			padding: 2rem 0;
			svg {
				min-width: 2.5rem !important;
				min-height: 2.5rem !important;
				margin-right: 1rem;
			}

			h3 {
				margin: 0;
				line-height: 2.5rem;
			}
		}
	}

	.errorRow {
		color: ${(props) => props.theme.color[`red600`]} !important;
	}
`;

export default TemplateDetails;
