import React from 'react';
import PermissionGroupUsersHeader from './permissiongroupusersheader';
import PermissionGroupUsersList from './permissiongroupuserslist';
import Headerbody from '../styles/permissiongroupuserliststyle';
import {labels} from '../../../../util/uiconstants';
import Loader from '../../../Common/Loaders/loadingindicator';

function PermissionGroupUsersTable(props) {
	return (
		<Headerbody className="">
			{props.isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<section className="userlistwrapper permissionuserheader ">
					<PermissionGroupUsersHeader />
					{props.data?.length > 0 ? (
						<PermissionGroupUsersList
							list={props.data}
							onDeleteClick={props.onDeleteClick}
						/>
					) : (
						<section className="norecord">{labels.noResultsFound}</section>
					)}
				</section>
			)}
		</Headerbody>
	);
}

export default PermissionGroupUsersTable;
