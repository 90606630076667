import * as types from '../../actions/actiontypes';

export function getAdminUsers(url) {
	return {
		type: types.GET_ALL_ADMIN_USERS,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function addAdminUsers(url, users) {
	return {
		type: types.ADD_ADMIN_USERS,
		meta: {
			api: {
				url: url,
				method: 'POST',
				data: users
			}
		}
	};
}

export function getAdminUserRoles(url) {
	return {
		type: types.GET_ALL_ADMINUSER_ROLES,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function addRole(url, scope) {
	return {
		type: types.ADD_ADMIN_ROLE,
		meta: {
			api: {
				url: url,
				method: 'PUT',
				data: scope
			}
		}
	};
}

export function deleteAdminUserRole(url) {
	return {
		type: types.DELETE_ADMINUSER_ROLE,
		meta: {
			api: {
				url: url,
				method: 'DELETE'
			}
		}
	};
}

export function deactivateAdminUser(url, data) {
	return {
		type: types.PUT_DEACTIVATE_USER,
		meta: {
			api: {
				url: url,
				method: 'PUT',
				data: data
			}
		}
	};
}
