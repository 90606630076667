import * as types from '../actions/actiontypes';

import initialState from './initialstate';

export default function engagementOverviewReducer(
	state = initialState.engagementOverview,
	action
) {
	switch (action.type) {
		case types.GET_ENGAGEMENT_OVERVIEW:
			return [...state, action.response];
		default:
			return state;
	}
}
