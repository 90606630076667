import React from 'react';
import PropTypes from 'prop-types';
import {labels} from '../../../util/uiconstants';
import styled from 'styled-components';
import {MotifFormField, MotifDatePicker, MotifLabel} from '@ey-xd/motif-react';
import {SVGICONS} from '@ey/icons';

const Datepickerwrapper = styled.section`
	& .field-error {
		font-size: 12px;
		line-height: normal;
		height: 2rem;
		padding-top: 1rem;
		padding-bottom: 2rem;
		font-family: NotoSans, Arial Unicode MS Regular, sans-serif !important;
		& svg {
			fill: ${(props) => props.theme.color[`red600`]};
			vertical-align: bottom;
			margin-right: 0.5rem;
			width: 16px;
			height: 16px;
		}
	}
	.motif-label.motif-label-for-text {
		font-size: 1.1rem;
	}
	.motif-date-picker-wrapper {
		cursor: pointer;
		width: auto;
		button,
		input {
			cursor: pointer;
		}
	}
	.motif-date-picker-wrapper .motif-date-picker.react-date-picker--disabled,
	.motif-date-picker-wrapper
		.motif-date-picker.react-daterange-picker--disabled,
	.motif-date-picker-wrapper button[disabled],
	.motif-date-picker-wrapper input[disabled] {
		background: ${(props) => props.theme.color[`grey100`]} !important;
		cursor: default;
	}

	.motif-date-picker-wrapper
		.motif-date-picker
		.react-date-picker__inputGroup__leadingZero,
	.motif-date-picker-wrapper
		.motif-date-picker
		.react-daterange-picker__inputGroup__leadingZero {
		padding-top: 2rem;
	}
	.motif-date-picker-wrapper
		.motif-date-picker
		.react-daterange-picker__inputGroup
		+ .react-date-picker__button,
	.motif-date-picker-wrapper
		.motif-date-picker
		.react-date-picker__inputGroup
		+ .react-date-picker__button {
		margin-right: 0.5rem;
	}
`;

class Calendar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value || null,
			triggerError: false,
			isMandatory: props.isMandatory || false
		};
		this.changeDate = this.changeDate.bind(this);
	}

	componentDidUpdate(prevProp, prevState) {
		if (this.props.validationTriggerCounter)
			if (
				prevProp.validationTriggerCounter != this.props.validationTriggerCounter
			) {
				this.setState({triggerError: true});
			}
	}

	showValidation = () => {
		return (
			this.state.isMandatory &&
			this.state.triggerError &&
			(!this.state.value || this.state.value === '')
		);
	};

	changeDate(date) {
		this.setState({
			value: date
		});
		this.props.onChange({name: this.props.name, value: date});
	}
	render() {
		let {
			label,
			name,
			optionalText,
			placeholder,
			title,
			value,
			disabled,
			dateFormat,
			userLocale,
			errorMessage
		} = this.props;
		return (
			<Datepickerwrapper data-autoid={name} className="text-input">
				<MotifFormField className="form-group">
					<MotifLabel htmlFor={name}>
						{label}
						<span data-toggle="tooltip" title={title}>
							&nbsp;
						</span>
						{optionalText && optionalText.trim() !== '' && (
							<span className="optional-tag">
								{optionalText || labels.optional}
								<span
									className=""
									data-toggle="tooltip"
									title={optionalText || labels.optional}
								>
									&nbsp;
								</span>
							</span>
						)}
					</MotifLabel>
					<StyledMotifDatePicker>
						<MotifDatePicker
							id="date-picker"
							onChange={this.changeDate}
							value={this.state.value ? new Date(value) : ''}
							disabled={disabled}
							format={dateFormat}
							locale={userLocale}
							placeholder={placeholder}
							dayPlaceholder={'DD'}
							monthPlaceholder={'MM'}
							yearPlaceholder={'YYYY'}
						/>
					</StyledMotifDatePicker>
					{this.showValidation() && (
						<section className="field-error">
							<section>
								<SVGICONS styleName="smalll" id="icon" name="error" />
								<span>{errorMessage}</span>
							</section>
						</section>
					)}
				</MotifFormField>
			</Datepickerwrapper>
		);
	}
}
Object.assign(Calendar, {
	propTypes: {
		label: PropTypes.string.isRequired,
		maxLength: PropTypes.number,
		name: PropTypes.string.isRequired,
		optional: PropTypes.bool,
		optionalText: PropTypes.string,
		placeholder: PropTypes.string,
		required: PropTypes.bool,
		title: PropTypes.string,
		value: PropTypes.string,
		todayDate: PropTypes.string
	},
	defaultProps: {
		disableText: false,
		maxLength: 100,
		placeholder: '',
		required: false,
		title: '',
		value: '',
		label: '',
		name: '',
		todayDate: new Date().toISOString()
	}
});
export default Calendar;

const StyledMotifDatePicker = styled.section`
	.motif-form-field {
		width: 100%;
		.motif-label-for-text {
			font-size: 1.3rem;
			padding-top: 0.3rem;
		}
	}
	.motif-label.motif-label-for-text.motif-has-content {
		padding-top: 0.5rem;
		font-size: 1.3rem;
	}
	.motif-date-picker-wrapper
		.motif-date-picker
		.react-date-picker__inputGroup__year,
	.motif-date-picker-wrapper
		.motif-date-picker
		.react-daterange-picker__inputGroup__year {
		//width: 3.5rem !important;
	}
	.motif-date-picker-wrapper
		.motif-date-picker
		.react-date-picker__inputGroup__input {
		font-size: 1.4rem;
		padding-top: 2rem;
	}
	.motif-date-picker-wrapper
		.motif-date-picker
		.react-date-picker__inputGroup__divider {
		padding-top: 2rem;
	}
	.motif-date-picker-wrapper .motif-calendar .react-calendar__tile,
	.motif-date-picker-wrapper .motif-calendar .react-calendar__tile:enabled,
	.motif-date-picker-wrapper
		.motif-calendar
		.react-calendar__tile:enabled:hover,
	.motif-date-picker-wrapper
		.motif-calendar
		.react-calendar__tile:enabled:focus {
		height: 3.8rem;
		font-size: 1.2rem;
	}
	.motif-date-picker-wrapper
		.motif-calendar
		.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
		font-size: 1.3rem;
	}
	.motif-date-picker-wrapper {
		.motif-date-picker {
			background-color: ${(props) => props.theme.color['white']};
			border: 0.1rem solid ${(props) => props.theme.color['grey400']};
			height: 4.8rem;
			padding: 0 !important;
			margin-left: 0 !important;
			.motif-calendar-icon {
				width: 2.4rem;
				height: 2.4rem;
			}
			.react-date-picker__calendar {
				width: 25.5rem;
				.motif-calendar {
					width: 100%;
				}
			}
		}
		.motif-date-picker:hover:not(.react-date-picker--disabled):not(.react-daterange-picker--disabled),
		.motif-date-picker.react-date-picker--open,
		.motif-date-picker.react-daterange-picker--open {
			background-color: ${(props) => props.theme.color['white']} !important;
			border: 0.1rem solid ${(props) => props.theme.color['grey400']} !important;
		}
		.react-date-picker__inputGroup {
			font-size: 1.4rem;
			align-items: center;
			display: flex;
			padding: 0.57143rem 0.64286rem !important;
		}
		.react-date-picker__calendar-button {
			padding-right: 0.5rem;
		}
		.react-calendar {
			position: relative;
			left: -0.1rem;
			margin-top: 0;
			border: none;
			box-shadow: ${(props) => props.theme.color['grey100']} 4px 4px 4px;
			&::before {
				display: none;
			}
			.react-calendar__month-view__days {
				border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
				border-top: none;
			}
			.react-calendar__month-view__weekdays {
				border: 0;
			}
			.react-calendar__navigation__label {
				font-size: 1.2rem;
			}
			.react-calendar__month-view__weekdays__weekday {
				font-weight: bold;
				font-size: 1.2rem;
			}
		}
	}
	.datePicker-closeIcon {
		background-color: ${(props) => props.theme.color['grey225']};
		padding: 0.3rem 0.6rem;
		font-size: 1.4rem;
		border: 0.1rem solid ${(props) => props.theme.color['grey400']};
		display: inline-flex;
		margin-bottom: 16px;
		align-items: center;
	}
`;
