import initialState from '../../initialstate';
// import * as types from '../../actions/actiontypes';

const mockData = {
	dateFormat: 0
};

//This reducer function handles user preferences.
export function UserPreferencesReducer(state = initialState.userPreferences) {
	// switch (action.type) {
	//     case types.GET_USER_PREFERENCES: {

	// let userPreferences = action.response;
	// userPreferences.userConfidentialityTypeId = 0;

	// if (userPreferences.permissions.hasLowConfidentiality) {
	//     userPreferences.userConfidentialityTypeId = confidentialityTypes.LOW;
	// }

	// if (userPreferences.permissions.hasModerateConfidentiality) {
	//     userPreferences.userConfidentialityTypeId = confidentialityTypes.MODERATE;
	// }

	// if (userPreferences.permissions.hasHighConfidentiality) {
	//     userPreferences.userConfidentialityTypeId = confidentialityTypes.HIGH;
	// }

	// if (userPreferences.permissions.hasConfidentialConfidentiality) {
	//     userPreferences.userConfidentialityTypeId = confidentialityTypes.CONFIDENTIAL;
	// }

	// userPreferences.isPreferencesLoaded = true;
	return mockData;
	// }
	// // TODO: REMOVE
	// case 'SET_USER_THEME': {
	//     let result = { ...state };
	//     result.userPreferencesJson = action.isDark ? '{"theme":"dark"}' : '{"theme":"light"}';
	//     return result;
	// }
	// default:
	//     return state;
}
