import React, {useState, useEffect} from 'react';
import {MotifIcon} from '@ey-xd/motif-react';
import {imageIcTimer24px} from '@ey-xd/motif-react/assets/icons';
import moment from 'moment';
import {labels} from '../../util/uiconstants';
import styled from 'styled-components';
import {expiryDaysTopCount} from '../../util/uiconstants';
import ReactHtmlParser from 'react-html-parser';

const EngagementArchiveCountdownIndicator = (props) => {
	const {expectedreportreleasedate, archivaldeadlinedate} = props;

	const [expiryDays, setExpiryDays] = useState(-1);
	const [type, settype] = useState('reportReleaseDate'); // archivalDeadlinedate

	const getexpectedreportreleasedateCount = (expectedreportreleasedate) => {
		if (expectedreportreleasedate) {
			let startDate = moment(expectedreportreleasedate.split('T')[0]).startOf(
				'day'
			);
			let currentDate = moment(new Date()).startOf('day');
			let diff = startDate.diff(currentDate, 'days');
			return diff;
		}

		return -1;
	};

	useEffect(() => {
		let archivalDeadlinedate =
			getexpectedreportreleasedateCount(archivaldeadlinedate);
		let reportReleaseDate = getexpectedreportreleasedateCount(
			expectedreportreleasedate
		);

		if (archivalDeadlinedate <= expiryDaysTopCount) {
			if (archivalDeadlinedate <= reportReleaseDate || reportReleaseDate < 0) {
				// archival deadline date is earlier than report deadline then show archivaldead line
				setExpiryDays(archivalDeadlinedate);
				settype('archivalDeadlinedate');
				return;
			}
		}

		if (reportReleaseDate > -1 && reportReleaseDate <= expiryDaysTopCount) {
			setExpiryDays(reportReleaseDate);
			settype('reportReleaseDate');
		}
	}, []);

	const getCountDownLabel = () => {
		// need to find out its release date or archive date countdown
		let label = '';
		if (expiryDays === 0) {
			label =
				type === 'reportReleaseDate'
					? labels.EngagementArchiveCountdownIndicator.reportreleasedate
					: labels.EngagementArchiveCountdownIndicator.archivedeadlinedate;
			label = label.replace(
				'{0}',
				labels.EngagementArchiveCountdownIndicator.today
			);
		} else if (expiryDays === 1) {
			label =
				type === 'reportReleaseDate'
					? labels.EngagementArchiveCountdownIndicator.reportreleasedate
					: labels.EngagementArchiveCountdownIndicator.archivedeadlinedate;
			label = label.replace(
				'{0}',
				labels.EngagementArchiveCountdownIndicator.tommorow
			);
		} else if (expiryDays < 0) {
			label =
				type === 'reportReleaseDate'
					? labels.EngagementArchiveCountdownIndicator.reportreleasedate
					: labels.EngagementArchiveCountdownIndicator.archivedeadlinedate;
			label = label.replace(
				'{0}',
				labels.EngagementArchiveCountdownIndicator.overdue
			);
		} else {
			label =
				type === 'reportReleaseDate'
					? labels.EngagementArchiveCountdownIndicator.reportreleasedays
					: labels.EngagementArchiveCountdownIndicator.archivedeadlinedays;
			label = label.replace('{0}', expiryDays);
		}

		return label;
	};

	return (
		<DeadllineIndicatorStyle>
			{(expiryDays > -1 || type === 'archivalDeadlinedate') && (
				<div className="expiry-label">
					<MotifIcon
						className={
							type === 'reportReleaseDate'
								? 'releasedate-icon'
								: 'deadlinedate-icon'
						}
						src={imageIcTimer24px}
					/>
					{ReactHtmlParser(getCountDownLabel())}
				</div>
			)}
		</DeadllineIndicatorStyle>
	);
};

export default EngagementArchiveCountdownIndicator;

const DeadllineIndicatorStyle = styled.section`
	padding-right: 3rem;
	display: inline-block;
	float: right;
	.expiry-label {
		line-height: normal;
		font-size: 1.4rem;
		@media screen and (max-width: 1366px) {
			margin-top: 0.4rem;
		}
		.motif-icon {
			color: ${(props) => props.theme.color[`red600`]};
			&.releasedate-icon {
				color: ${(props) => props.theme.color[`blue600`]};
			}
			svg {
				width: 2.1rem;
				height: 2.1rem;
				vertical-align: sub;
				margin-right: 0.5rem;
			}
		}
		.expiry-days-label {
			font-weight: bold;
		}
	}
`;
