import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

export default function KnowledgeTemplateReducer(
	state = initialState.knowledgeTemplate,
	action
) {
	switch (action.type) {
		case types.GET_KNOWLEDGETEMPLATE:
			return action.response;
		default:
			return state;
	}
}
