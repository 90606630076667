/**
 * Scot.js
 * Created by zl952fc on 03/03/2020.
 */

import React from 'react';
import { settingsPreferences, permissionGroups } from './uiconstants';
import { ActionTypes } from './enumerations';
import dompurify from 'dompurify';

export default class Utility extends Object {
	/**
	 * Creates a rich text element to show rich text. Eg. (Bold, Italic, Underline...)
	 * rastwe: TODO: need some justification for having this here. Kendo editor?
	 * @param {string} text - the text to display
	 * @returns {Object} a rich text element to display
	 * */
	static createRichTextElement(text) {
		// we don't want to unescape text to prevent XSS
		// text = _.unescape(text);
		return <span dangerouslySetInnerHTML={Utility.createMarkup(text)} />;
	}

	/**
	 * Private function to return html required for setting dangerous inner html
	 * @param {string} text - the text to display
	 * @returns {Object} an object with html set to the text passed in
	 * */
	static createMarkup(text) {
		const sanitizedtext = Utility.getSanitizedText(text, { encode: false });
		return { __html: sanitizedtext };
	}

	/**
	 * Returns a boolean wether the user preferred theme is Dark or not
	 * @param {Object} userSettings - the user settings
	 * @returns {Boolean} a boolean that indicates if user preferred theme is dark or not
	 * */
	static isUserThemeDarkMode = (userSettings) => {
		if (userSettings && userSettings.preferredtheme) {
			return userSettings.preferredtheme === settingsPreferences.DarkTheme;
		}
	};

	/**
	 * Sets the motif theme of the app
	 * @param {string} themeName - the name of the theme (dark or light)
	 * */
	static setMotifTheme = (themeName) => {
		document
			.getElementsByTagName('body')[0]
			.setAttribute('motif-theme', themeName);
	};

	/**
	 * Get a sanitized text after parsing input for XSS.
	 * @param {string} text - the input text
	 * @returns {string} sanitized text
	 * */
	static getSanitizedText(text, { encode = false }) {
		const sanitizer = dompurify.sanitize;
		let sanitizedText = _.unescape(sanitizer(text));
		if (encode) {
			sanitizedText = encodeURIComponent(sanitizedText);
		}
		return sanitizedText;
	}

	static getIncountryURL = (url, engagementData) => {
		let client = engagementData.collections.clients[0];
		let workspace = client.collections.workspaces[0];
		let engagement = workspace.collections.engagements[0];

		return (
			engagement.data.incountryuri +
			url +
			'countryid=' +
			workspace.id +
			'&engagementid=' +
			engagement.id +
			'&engagementdesc=' +
			encodeURIComponent(engagement.data.engagementdesc.substring(0, 50)) +
			'&userPrefLang=en-us&workspacedesc=' +
			encodeURIComponent(workspace.data.workspacedesc.trim()) +
			'&workspaceid=' +
			workspace.id +
			'&engagementversion=' +
			Math.floor(Number.parseInt(engagement.data.engagementversion)) +
			'&engagementversionfull=' +
			engagement.data.engagementversion
		);
	};

	static groupdNotificationsByActionType(notifications) {
		let notificationsGroupedByRiskTypes = undefined;
		if (
			notifications &&
			notifications.notificationdetails &&
			notifications.notificationdetails.length > 0
		) {
			const localNotifications = JSON.parse(
				JSON.stringify(notifications.notificationdetails)
			);

			// grouping array by action Type
			notificationsGroupedByRiskTypes = _.chain(localNotifications)
				// Group the notifications of based on `action Type` property
				.groupBy('actionType')
				// `key` is group's name (action Type), `value` is the array of objects (notifications)
				.map((value, key) => ({
					actionType: Number(key),
					isFromList: true,
					notifications: value,
					uidisplayorder: value[0].uidisplayorder
				}))
				.value();

			let engagementInvitesNotifications = notificationsGroupedByRiskTypes
				.filter(
					(group) =>
						group.actionType === ActionTypes.EngagementInvitation ||
						group.actionType === ActionTypes.EngagementProfileIncomplete ||
						group.actionType === ActionTypes.EngagementAccepted ||
						group.actionType === ActionTypes.EngagementIndependenceIncomplete
				)
				.flatMap((group) => group.notifications);

			// Remove previous grouped notifications related to engagement invites
			notificationsGroupedByRiskTypes = notificationsGroupedByRiskTypes.filter(
				(group) =>
					group.actionType != ActionTypes.EngagementInvitation &&
					group.actionType != ActionTypes.EngagementProfileIncomplete &&
					group.actionType != ActionTypes.EngagementAccepted &&
					group.actionType != ActionTypes.EngagementIndependenceIncomplete
			);

			if (engagementInvitesNotifications.length > 0) {
				notificationsGroupedByRiskTypes.push({
					actionType: ActionTypes.EngagementInvitation,
					isFromList: true,
					notifications: engagementInvitesNotifications,
					uidisplayorder: engagementInvitesNotifications[0].uidisplayorder
				});
			}

			// sorting array based on UI Display order
			notificationsGroupedByRiskTypes.sort((a, b) =>
				a.uidisplayorder > b.uidisplayorder ? 1 : -1
			);

			//setting count to array length
			notificationsGroupedByRiskTypes.count =
				notifications.notificationdetails.length;

			notificationsGroupedByRiskTypes.close = notifications.close;
		}
		return notificationsGroupedByRiskTypes;
	}

	static getCountriesByGroupPermission(countriesDetailsData, userPermissions, featureConfiguration) {
		const userHasPermissions = () => {
			if (userPermissions?.userpermissions) {
				var item = userPermissions?.userpermissions.find(
					(x) => x.groupname === permissionGroups.CanvasPilotEnvironmentEMEIA
				);
				return item != null ? true : false;
			}
		};

		// Get all countries from store
		let countries = [];
		countriesDetailsData &&
			countriesDetailsData.collections &&
			countriesDetailsData.collections.countriescollection.map(({ id, data }) => {
				countries.push({
					value: id,
					label: data.name
				});
			});

		// Filter countries in case of not having the specific permission
		if (!userHasPermissions() && featureConfiguration.pilotcountrylist !== '') {
			let notAllowedCountries =
				featureConfiguration.pilotcountrylist.split(',');
			countries = countries.filter((country) => {
				return !notAllowedCountries.includes(country.value.toString());
			});
		}
		return countries;
	}

	static GetUserLanguageCode(userLanguageId) {
		let languageCodeArray = [
			{ key: 1, value: 'en' },
			{ key: 2, value: 'cs' },
			{ key: 3, value: 'de' },						
			{ key: 4, value: 'fr' },
			{ key: 5, value: 'es' },
			{ key: 6, value: 'it' },			
			{ key: 7, value: 'pt' },
			{ key: 8, value: 'ja' },
			{ key: 9, value: 'zh' },
			{ key: 10, value: 'zh' },
			{ key: 11, value: 'iw' },
			{ key: 12, value: 'ko' },
			{ key: 13, value: 'ru' },
			{ key: 14, value: 'ar' }
		];

		let userLanguageCode = languageCodeArray.find((x) => x.key === userLanguageId)?.value

		if (!userLanguageCode) {
			return 'en';
		}
		return userLanguageCode;
	}
}
