import * as types from '../actiontypes';

export function getMessages(url) {
	return {
		type: types.GET_ALL_MESSAGES,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function saveMessage(url, payload) {
	return {
		type: types.SAVE_MESSAGE,
		meta: {
			api: {
				url: url,
				method: 'POST',
				data: payload
			}
		}
	};
}

export function deleteMessage(url) {
	return {
		type: types.DELETE_ADMIN_MESSAGE,
		meta: {
			api: {
				url: url,
				method: 'DELETE'
			}
		}
	};
}

export function getMessageById(url) {
	return {
		type: types.GET_MESSAGE_BY_ID,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function resetMessageDetails() {
	return {
		type: types.RESET_MESSAGE_DETAILS
	};
}

export function editMessage(url, payload) {
	return {
		type: types.EDIT_MESSAGE,
		meta: {
			api: {
				url: url,
				method: 'PUT',
				data: payload
			}
		}
	};
}
