import React from 'react';
import styled from 'styled-components';
import EngagementMetricsDetails from './engagementMetricsDetails';
import {labels} from '../../../util/uiconstants';
import EngagementAlerts from './EngagementAlerts/engagementAlerts';
import {MotifTooltip, MotifButton} from '@ey-xd/motif-react';
import {SVGICONS} from '@ey/icons';
import EngagementArchiveCountdownIndicator from '../../Common/EngagementArchiveCountdownIndicator';

const EngagementMetrics = (props) => {
	const {
		engagementId,
		timeline,
		expectedreportreleasedate,
		archivaldeadlinedate
	} = props;

	return (
		<EngagementMetricsContainer>
			<section className="engMetricBorder">
				<MotifTooltip
					allowHover={false}
					trigger={
						<MotifButton variant="text">
							{labels.engagementMetrics}
							<SVGICONS
								hoverText={labels.engagementMetrics}
								styleName="small pull-left"
								name={'add_chart'}
							/>
						</MotifButton>
					}
					contentClassName="auditmetrics-tooltip"
					placement="auto"
				>
					<section className="auditmetrics-container">
						<h3 className="auditmetrics-title">{labels.engagementMetrics}</h3>
						<EngagementMetricsDetails
							engagementId={engagementId}
							timeline={timeline}
						/>
					</section>
				</MotifTooltip>

				<EngagementArchiveCountdownIndicator
					// belwo dates can be used for testing purpose
					// archivaldeadlinedate={'2022-08-05'}
					// expectedreportreleasedate={'2022-06-19'}
					archivaldeadlinedate={archivaldeadlinedate}
					expectedreportreleasedate={expectedreportreleasedate}
				/>
			</section>

			{timeline && timeline.metrics && timeline.metrics.engagementAlerts && (
				<section className="engagementalerts-wrapper">
					<EngagementAlerts
						engagementAlerts={timeline.metrics.engagementAlerts}
					/>
				</section>
			)}
		</EngagementMetricsContainer>
	);
};

export default EngagementMetrics;

const EngagementMetricsContainer = styled.section`
	margin: 0;
	.engMetricBorder {
		padding-left: 3rem;
		padding-bottom: 3rem;
		@media screen and (max-width: 1366px) {
			padding-bottom: 3.4rem;
		}
		.motif-text-button {
			font-weight: bold;
			font-size: 1.6rem;
			&:focus {
				box-shadow: none;
			}
			svg {
				margin-left: 0.3rem;
			}
		}
	}
`;
