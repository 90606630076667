import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';

/*
 * Redux Middleware for handling API calls
 * On successful api response, the response and responseObj are attached to the action and passed to next middleware
 * On Error and error object is attached to the action and API_ERROR action is dispatched
 * Injection of config into the middleware to use it for authentication
 * config can be passed as a parameter to the middleware in applyMiddleware method when creating the store
 *
 */
const apiMiddleware = (config) => (store) => (next) => (action) => {
	const __NAME = 'API Middleware',
		GET_ONLINE_STATE = 'GET_ONLINE_STATE',
		API_ERRORS = 'API_ERRORS';

	// skip to next middleware for API_ERROR action
	if (action.type === API_ERRORS) {
		next(action);
		return Promise.reject(action.error);
	}

	// Check if action is to be managed by upload middleware
	if (action.meta && action.meta.upload) {
		next(action);
		return Promise.resolve(
			`[${__NAME}] - action.type:`,
			action.type,
			'Skipping upload request'
		);
	}

	// Check if the app is online
	// If application is not online skip authentication and go to next middleware
	// returning promise.resolve so that action.dispatch can be chained with then()
	if (!action.isOnline && !(action.meta && action.meta.docHelper)) {
		next(action);
		return Promise.resolve(
			`[${__NAME}] - action.type:`,
			action.type,
			'Skipping Authentication as app is offline'
		);
	}

	/* Existence of the meta.api object is the trigger to treat this
	 * action as an API call. */
	if (!(action.meta && action.meta.api)) {
		next(action);
		return Promise.resolve(
			`[${__NAME}] - action.type:`,
			action.type,
			'Skipping API call since API meta not available'
		);
	}

	let {
			method,
			url,
			data,
			headers,
			timeout,
			trackWithArea,
			responseType
		} = action.meta.api,
		auth = action.meta.auth;

	// Check for auth error and skip api call incase of auth error;
	if (!(action.meta && action.meta.docHelper)) {
		if (auth.error && !auth.token) {
			next(action);
			return Promise.reject(auth.error);
			//return Promise.resolve('Skipping API call since token is unavailable');
		}
	}

	const promise = new Promise((resolve, reject) => {
		if (!config) {
			const configMsg = `
			Config unavailable.
			The AD package requires config to get the authorization token for the api call.`;
			console.log(`[${__NAME}] - action.type:`, action.type, configMsg);
		}

		/*
		 * If config is not passed as parameter to the middleware
		 * the below function checkLoggedIn from AD package will skip authentication
		 */

		const needsJSON = ['post', 'put', 'patch', 'delete'].includes(
			method.toLowerCase()
		);
		let customHeaders = headers || {};
		if (!(action.meta && action.meta.docHelper)) {
			if (auth.token) {
				customHeaders.Authorization = 'Bearer ' + auth.token;
			}
		}
		if (method.toLowerCase() != 'get') {
			customHeaders['Content-Type'] =
				method.toLowerCase() == 'patch'
					? 'application/json-patch+json'
					: needsJSON
					? 'application/json'
					: '';
		}

		axios({
			method,
			url,
			data,
			timeout: timeout || 0,
			headers: customHeaders,
			responseType: responseType || ''
		})
			.then((response) => {
				action.response = response.data;
				action.responseObj = response;
				next(action);

				// This is required ONLY for returning respose data to Voltron components
				// Do not use this to intercept Api data instead of store use
				let value;
				if (action.resolve) {
					value = response.data;
				}

				if (responseType === 'blob') {
					value = response;
				}

				return resolve(value);
			})
			.catch((error) => {
				var apponlinestate = store.getState().apponlinestate;
				// Error incase of IP address change for the user.
				if (
					error &&
					error.response &&
					error.response.headers &&
					error.response.headers['www-authenticate'] &&
					error.response.headers['www-authenticate']
						.toLowerCase()
						.split('invalid ipaddress claim').length > 1
				) {
					localStorage.clear();
					sessionStorage.clear();
					// TODO: update the below error object creation
					// recreating the error object in order to make it compatible with Errors.js
					error.response.data = {};
					error.response.data.errors = {};
					error.response.data.errors.Error = [
						JSON.stringify({ErrorCode: 401000})
					];
					return next({type: 'API_ERRORS', error});
				}
				// console.log(
				// 	`[${__NAME}] - action.type:`,
				// 	action.type,
				// 	' In order to verify if its a legit discconect, we call [GET_ONLINE_STATE] when api call recieves an error'
				// );
				store.dispatch({type: GET_ONLINE_STATE});
				error.requestUrl = action.meta.api.url;
				action.error = error;
				action.response = {};
				action.responseObj = {};
				//next(action);

				next({type: API_ERRORS, error, action});

				return reject(error);
			});
	});

	return !_.isEmpty(trackWithArea) && _.isString(trackWithArea)
		? trackPromise(promise, trackWithArea)
		: promise;
};

export default apiMiddleware;
