/**
 * Remember: always add new reducers to the /reducers/index.js file!
 * Also, add your initialState to './initialstate'!
 */
import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export default function CopyHubReducer(
	//state = initialState.<%=names.camel%>,//TODO: uncomment this. Get from initialState.
	state = initialState.copyHub, //{Workspaces: {}},
	action
) {
	switch (action.type) {
		// eslint-disable-next-line no-case-declarations
		case types.GET_COPY_REQUEST:
			/* Notice that this is simply returning the action payload, <%=names.camel%>.
			 * This is because the combineReducers() call wraps this reducer
			 * and passes it only the portion of the store state that it
			 * is concerned with. */
			let copyRequest;
			let linkRequest;
			if (
				action.response &&
				action.response.collections &&
				Array.isArray(action.response.collections.iepnotifications)
			) {
				copyRequest = action.response.collections.iepnotifications.find(
					(cr) => {
						return (
							typeof cr.id === 'string' &&
							typeof action.copyRequestId === 'string' &&
							cr.id.toUpperCase() === action.copyRequestId.toUpperCase()
						);
					}
				);
			} else if (
				action.response &&
				action.response.collections &&
				Array.isArray(action.response.collections.linknotifications)
			) {
				linkRequest = action.response.collections.linknotifications.find(
					(cr) => {
						return typeof cr.id === 'string';
					}
				);
			}

			return {
				...state,
				copyRequest: copyRequest
					? {id: copyRequest.id, ...copyRequest.data}
					: copyRequest,
				linkRequest: linkRequest
					? {id: linkRequest.id, ...linkRequest.data}
					: linkRequest
			};

		case types.GET_ENGAGEMENTS_TO_COPY_TO:
			return {
				...state,
				engagements: getEngagementsFromActionResponse(action)
			};

		case types.PATCH_COPY_REQUEST:
			return state;

		case types.GET_ENGAGEMENT_FOR_COPY_REQUEST:
			var engagements = getEngagementsFromActionResponse(action);

			var engagement = engagements.find((e) => {
				return String(e.id) === String(action.engagementId);
			});

			return {
				...state,
				engagement: engagement ? {...engagement} : engagement
			};

		default:
			return state;
	}
}

function getEngagementsFromActionResponse(action) {
	var engagements = [];
	if (
		action.response &&
		action.response.collections &&
		Array.isArray(action.response.collections.clients)
	) {
		action.response.collections.clients.some((client) => {
			if (client.collections && Array.isArray(client.collections.workspaces)) {
				let workspace = client.collections.workspaces.find((w) => {
					return (
						typeof w.id === 'string' &&
						typeof action.workspaceId === 'string' &&
						w.id.toUpperCase() === action.workspaceId.toUpperCase()
					);
				});
				if (
					workspace &&
					workspace.collections &&
					Array.isArray(workspace.collections.engagements)
				) {
					workspace.collections.engagements.forEach((engagement) => {
						engagements.push({id: engagement.id, ...engagement.data});
					});
					return true;
				}
			}
		});
	}

	return engagements;
}
