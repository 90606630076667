import React, {useState} from 'react';
import styled from 'styled-components';
import {labels} from '../../util/uiconstants';
import ModalControl from '@ey/modal-control';

const EngagementProfileUpdateModal = (props) => {
	const {show, editConfirmation} = props;

	const [showState, setShowState] = useState(show);

	const handleConfirm = () => {
		editConfirmation();
	};

	const handleCancel = () => {
		setShowState(false);
	};

	return (
		<ModalControl
			title={labels.titleUpdateProfile}
			modalsize="small"
			onOkClick={handleConfirm}
			confirmBtnTitle={labels.continue}
			closeTitle={labels.close}
			closeBtnTitle={labels.cancel}
			onHide={handleCancel}
			show={showState}
			backdrop="static"
		>
			<UpdateProfileEngagementStyle>
				<section id="message">
					<p className="msg1 update-modal-confimation">
						{labels.msgUpdateProfileLn1}
					</p>
				</section>
			</UpdateProfileEngagementStyle>
		</ModalControl>
	);
};
export default EngagementProfileUpdateModal;

const UpdateProfileEngagementStyle = styled.section`
	padding: 2.5rem 2.4rem;
	.msg1 {
		font-size: 1.4rem;
		font-weight: normal;
		color: ${(props) => props.theme.color[`grey500`]};
		margin-bottom: 0;
	}

	.msg2 {
		font-size: 1.4rem;
		font-weight: normal;
		color: #747480;
		margin-bottom: 1rem;
	}
	.msg3 {
		font-size: 1.6rem;
		font-weight: bold;
		color: #2e2e38;
		margin-bottom: 0;
	}
`;
