import {currentResource} from '../../../util/uiconstants';

const messages = {
	/*messages should be the localized language values for tooltips when mousing over the tools*/
	alignLeft: currentResource.kendoLabels.alignLeft,
	alignCenter: currentResource.kendoLabels.alignCenter,
	alignRight: currentResource.kendoLabels.alignRight,
	alignJustify: currentResource.kendoLabels.alignFull,
	addCommentButtonTooltip: currentResource.kendoLabels.addComment,
	addColumnBefore: currentResource.kendoLabels.addColumnBefore,
	addColumnRight: currentResource.kendoLabels.addColumnAfter,
	addRowAbove: currentResource.kendoLabels.addRowAbove,
	addRowBelow: currentResource.kendoLabels.addRowBelow,
	bulletList: currentResource.kendoLabels.bulletList,
	bold: currentResource.kendoLabels.bold,
	backColor: currentResource.kendoLabels.backColor,
	createLink: currentResource.kendoLabels.createLink,
	createTable: currentResource.kendoLabels.createTable,
	cleanFormatting: currentResource.kendoLabels.cleanFormatting,
	deleteRow: currentResource.kendoLabels.deleteRow,
	deleteColumn: currentResource.kendoLabels.deleteColumn,
	fontSizeInherit: currentResource.kendoLabels.fontSizeInherit,
	foreColor: currentResource.kendoLabels.foreColor,
	format: currentResource.kendoLabels.format,
	formatOptions: currentResource.kendoFormatOptions,
	fontSize: currentResource.kendoLabels.fontSize,
	fontSizeOptions: currentResource.kendoFontSize,
	hyperlink: currentResource.kendoLabels.hyperlink,
	italic: currentResource.kendoLabels.italic,
	indent: currentResource.kendoLabels.indent,
	insertTableHint: currentResource.kendoLabels.insertTableHint,
	'hyperlink-dialog-content-address': currentResource.kendoLabels['hyperlink-dialog-content-address'],
	'hyperlink-dialog-title': currentResource.kendoLabels['hyperlink-dialog-title'],
	'hyperlink-dialog-content-title': currentResource.kendoLabels['hyperlink-dialog-content-title'],
	'hyperlink-dialog-content-newwindow': currentResource.kendoLabels['hyperlink-dialog-content-newwindow'],
	'hyperlink-dialog-cancel': currentResource.kendoLabels['hyperlink-dialog-cancel'],
	'hyperlink-dialog-insert': currentResource.kendoLabels['hyperlink-dialog-insert'],
	outdent: currentResource.kendoLabels.outdent,
	orderedList: currentResource.kendoLabels.orderedList,
	print: currentResource.kendoLabels.print,
	pdf: currentResource.kendoLabels.pdf,
	redo: currentResource.kendoLabels.redo,
	strikethrough: currentResource.kendoLabels.strikethrough,
	subscript: currentResource.kendoLabels.subscript,
	superscript: currentResource.kendoLabels.superscript,
	underline: currentResource.kendoLabels.underline,
	undo: currentResource.kendoLabels.undo
};
export default messages;
