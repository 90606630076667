import * as types from '../actions/actiontypes';
import initialState from './initialstate';

export function RestoreEngagementReducer(
	state = initialState.restoreEngagement,
	action
) {
	switch (action.type) {
		case types.GET_RESTOREENGAGEMENTS:
			return {
				...state,
				data: action.response
			};
		case types.SET_ARC_ENG_DETAILS_FOR_RESTORE:
		   // reset the flag so - old restore will be cleaned up
		   let newState = {};
			return {
				...newState,
				restoreEngagementData: action.payload
			};
		default:
			return state;
	}
}

export function ArcEngagementInfoReducer(state = {}, action) {
	switch (action.type) {
		case types.GET_ARC_ENG_DETAILS_FOR_RESTORE:
			return action.response;
		default:
			return state;
	}
}

export function TemplateEngagementInfoReducer(state = {}, action) {
	switch (action.type) {
		case types.GET_ENG_DETAILS_FOR_IMPORT:
			return action.response;
		default:
			return state;
	}
}
