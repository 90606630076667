import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Radio} from 'react-bootstrap';
import {labels, urls, standardStrings} from '../../../util/uiconstants';
import {FormRadio} from '@ey/form';
import {getTemplateData} from '../../../actions/importaction';
import env from '../../../util/env';
import _ from 'lodash';
import {useDidMountEffect} from '../../../util/customHooks';
import Loader from '@ey/loader';
import {SVGICONS} from '@ey/icons';

const SelectRegion = (props) => {
	const {
		updateWizardData,
		onComponentDisplayed,
		nextClicked,
		isComponentValid,
		getDependencyData
	} = props;

	const [isLoading, setIsLoading] = useState(false);
	const [isDataValid, setIsDataValid] = useState(false);
	const [isStepValid, setIsStepValid] = useState(false);
	const [showValidation, setShowValidation] = useState(false);

	const templateData = useSelector((store) => store.knowledgeTemplate);
	const dispatch = useDispatch();

	let templateurl = env.getURL('serviceUrl') + '/' + urls.KNOWLEDGETEMPLATE_URL;

	useEffect(() => {
		fetchData();
	}, []);

	useDidMountEffect(() => {
		!isStepValid && setShowValidation(true);
	}, [nextClicked]);

	useEffect(() => {
		setComponentValid(getDependencyData('domainSelectedRegion')?.key > 0);
	}, [onComponentDisplayed]);

	const fetchData = async () => {
		try {
			setIsLoading(true);
			await dispatch(getTemplateData(templateurl));
			setIsDataValid(true);
		} catch {
			setIsDataValid(false);
		} finally {
			setIsLoading(false);
		}
	};

	const updateWizardFromComponent = (region) => {
		updateWizardData('domainSelectedRegion', region);
		setComponentValid(true);
	};

	const setComponentValid = (stepValid = false) => {
		if (stepValid) {
			isComponentValid(true);
		} else {
			isComponentValid(false);
		}
	};

	const radioButtonClicked = (key, value) => {
		setIsStepValid(true);
		setShowValidation(false);
		updateWizardFromComponent({key: key, value: value});
	};

	const getRegionList = () => {
		let data =
			templateData &&
			templateData.collections &&
			templateData.collections.regions;

		let result = [];
		if (data) {
			data.map(({id, data}) => {
				result.push(
					<Radio
						value={data.name}
						key={id}
						name="radio"
						onClick={() => radioButtonClicked(id, data.name)}
						checked={getDependencyData('domainSelectedRegion')?.key == id}
					>
						<span>{data.name}</span>
					</Radio>
				);
			});
		}
		return result;
	};

	return (
		<>
			{isLoading ? (
				<Loader view="fullscreen" />
			) : !isDataValid ? (
				<div />
			) : (
				<SelectRegionStyles>
					<form>
						<header className="import-header">
							<h5>{labels.importtemplateheader}</h5>
						</header>

						<section className="import-error">
							{showValidation === true ? (
								<section>
									<SVGICONS styleName="small" id="icon" name="error" />
									<span>{labels.importtemplatemsg}</span>
								</section>
							) : (
								standardStrings.EMPTY
							)}
						</section>

						<section className="archiveuserlist">
							<FormRadio name="regionRadioList" onChange={() => {}}>
								{getRegionList()}
							</FormRadio>
						</section>
					</form>
				</SelectRegionStyles>
			)}
		</>
	);
};

export default SelectRegion;

const SelectRegionStyles = styled.section`
	& .import-error {
		font-size: 1.2rem;
		line-height: normal;
		height: 2.4rem;

		& svg {
			fill: ${(props) => props.theme.color[`red600`]};
			vertical-align: text-top;
			margin-right: 0.5em;
		}
	}

	& .archiveuserlist {
		margin-top: 1rem;

		& > .form-group {
			margin: 0 0 1.5rem 0;
		}

		& .form-group label.control-label {
			display: inline;
		}
	}

	& .archiveuserlist div.form-group .radio {
		vertical-align: middle;
		margin: 0 0 1.5rem 0;

		& label input[type='radio'] {
			margin: 0 0 0 -3rem;
		}
		& label {
			padding-left: 3rem;
			font-weight: normal;
		}
	}
`;
