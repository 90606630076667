/**
 * Created by calhosh on 4/14/2017.
 */

import env from '../util/env';
import resources from '../resources/resources';

/* rastwe: 5/22/17 - I'm renaming all the id/name pairs to id/label, to
 * be in sync with the requirements of the Bootstrap SelectList control. */

let lang = env.getLanguage();
export let labels = resources(lang).labels;
/*this will need to eventually come from the user object and user settings*/
export let currentResource = resources(lang);

const userlocale = {
	1: 'en-us',
	4: 'fr-ca',
	5: 'es-mx',
	7: 'pt-br',
	8: 'ja-jp',
	9: 'zh-sp',
	10: 'zh-tr',
	11: 'he',
	12: 'ko',
	13: 'ru',
	14: 'ar-ms'
};

export let currentLocale = userlocale[1];

export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export function setLanguage(language) {
	if (language) {
		currentResource = resources(language);
		labels = currentResource.labels;
		currentLocale = userlocale[language];
	}
}

export let dateFormats = 0;
export function setDateFormat(dateFormat) {
	if (dateFormat) {
		dateFormats = dateFormat;
	}
}

export const reporting = {
	serviceURL: 'serviceUrl',
	SPOTFIRE_URI_TYPE: 7,
	REPORTING_NOT_AVAILABLE: 1021,
	INVALID_ENG_ID: 1013
};

export const EnterKeyCode = 13;
export const successMessageDisplayDuration = 3000;

export const urls = {
	HOME_URL: 'home.json',
	ENGAGEMENTUSERS_URL: 'EngagementUsers.json',
	COUNTRY_OFFICE_URL: 'countryofficeregions.json',
	LOCATIONS_URL: 'locations.json',
	WORKSPACES_URL: 'workspaces.json',
	SERVICE_LINES_URL: 'servicelines.json',
	CLIENTENGAGEMENT_URL: 'ClientEngagement.json',
	TIMELINE_URL: 'timeline.json',
	CLIENTS_URL: 'clients.json',
	COUNTRIES_URL: 'countries.json',
	ENGAGEMENTS_URL: 'engagements.json',
	KNOWLEDGETEMPLATE_URL: 'knowledgetemplate.json',
	USERS_URL: 'users.json',
	USERS_PERMISSION_URL: 'userpermissions.json',
	COPYHUB_URL: 'copyhub.json',
	GROUPAUDITENGAGEMENTS_URL: 'groupauditengagements.json',
	LANDINGPAGENOTIFICATION_URL: 'landingpagenotification.json',
	USERACKNOWLEDGEMENT_URL: 'useracknowledgement.json',
	LANGUAGES_URL: 'Languages.json',
	ENGAGEMENTMETADATA_URL: 'engagementmetadata.json',
	CRP_URL: 'engagementsnapshot.json',
	FEATURECONFIGURATION_URL: 'featureconfiguration.json',
	EYUSERS_URL: 'EYUsers.json',
};

export const urlsHash = {
	USER_SETTINGS: 'USERSETTINGS',
	ENGAGEMENT: 'ENGAGEMENTS',
	FAVORITES: 'FAVORITES',
	WORKSPACE: 'GROUPBY=CLIENTS'
};

export const workspaceOptions = {
	MYWORKSPACES: 1,
	MYARCHIVES: 2,
	ALLARCHIVES: 3
};

export const apiPhases = {
	MYARCHIVES: 'MyArchives',
	ALLARCHIVES: 'AllArchives',
	MYNAVIGATION: 'MyNavigation',
};

export const standardStrings = {
	EMPTY: '',
	HASH: '#',
	DOLLAR: '$',
	PCT: '%'
};

export const searchTypes = {
	ALL: '3',
	EXCLUDE_WORKSPACE: '4',
	MY_FAVORITES: '100',
	REVIEW_ENGAGEMENTS: '27'
};

export const engagementFilterTypes = {
	ALL: '1',
	//EXCLUDE_WORKSPACE: '4',
	MY_FAVORITES: '2',
	REVIEW_ENGAGEMENTS: '3'
};

export const displayGroupings = {
	LIST: 'list',
	MODULE: 'module'
};
export const pagerOptions = [5, 10, 20, 50];
export const yesNoToggle = [
	{value: 0, label: currentResource.yes},
	{value: 1, label: currentResource.no}
];

export const dashboardConstants = {
	ENGAGEMENT_USER_STATUS_ID: 2,
	UNINVITED_ENGAGEMENT_USER_STATUS_ID: 1,
	CLIENT_STATE_COLLAPSED: 0,
	CLIENT_STATE_EXPANDED_LEVEL1: 1,
	CLIENT_STATE_EXPANDED_LEVEL2: 2,
	WORKSPACE_STATE_COLLAPSED: 0,
	WORKSPACE_STATE_EXPANDED: 1,
	WORKSPACE_INVITED_LIST: 0,
	MARKED_FOR_DELETION: 9,
	SUSPENDED_ENGAGEMENT_STATUS_ID: 2,
	ENGAGEMENT_NAME_DISABLED: 2,
	ENGAGEMENT_STATUS_ROLLFORWARD_ERROR: 1,
	ENGAGEMENT_STATUS_ROLLFORWARD_IN_PROGRESS: 2,
	ENGAGEMENT_STATUS_ROLLFORWARD_COMPLETE: 3,
	ENGAGEMENT_STATUS_ARCHIVE_INPROGRESS: 5,
	ENGAGEMENT_STATUS_GAMX_MIGRATION_IN_PROGRESS: 6,
	ENGAGEMENT_STATUS_GAMX_MIGRATION_ERROR: 7,
	ENGAGEMENT_STATUS_ARCHIVE_ERROR: 9,
	ENGAGEMENT_STATUS_RESTORE_ERROR: 10,
	ENGAGEMENT_STATUS_RESTORE_IN_PROGRESS: 11,
	ENGAGEMENT_STATUS_IMPORT_IN_PROGRESS: 12,
	ENGAGEMENT_STATUS_IMPORT_ERROR: 13,
	ENGAGEMENT_STATUS_COPY_ERROR: 23,
	ENGAGEMENT_STATUS_COPY_TEMPLATE_ERROR: 24,
	NO_ARCHIVE_ACCESS: 0,
	REQUEST_FOR_ARCHIVE_ACCESS: 5,
	REQUEST_REJECT_FOR_ARCHIVE_ACCESS: 6,
	ENGAGEMENT_ARCHIVAL_REQUESTED: 5,
	ENGAGEMENT_ARCHIVAL_REJECTED: 6,
	ENGAGEMENT_ARCHIVAL_NOACCESS: 0,
	ENGAGEMENT_DIAL_VALUE: 100,
	ENGAGEMENT_DEFAULT_DIAL_VALUE: 0,
	ENGAGEMENT_STATUS_ACTIVE: 1,
	AUTO_REFRESH_TIME: 126000
};

export const noYesToggle = [
	{value: 0, label: currentResource.no},
	{value: 1, label: currentResource.yes}
];

export const localizedLanguages = [
	{value: 1, label: currentResource.english},
	{value: 2, label: currentResource.spanish},
	{value: 3, label: currentResource.french},
	{value: 4, label: currentResource.dutch},
	{value: 5, label: currentResource.croatian},
	{value: 6, label: currentResource.czech},
	{value: 7, label: currentResource.danish},
	{value: 8, label: currentResource.finnish},
	{value: 9, label: currentResource.german},
	{value: 10, label: currentResource.hungarian},
	{value: 11, label: currentResource.italian},
	{value: 12, label: currentResource.japanese},
	{value: 13, label: currentResource.norwegian},
	{value: 14, label: currentResource.polish},
	{value: 15, label: currentResource.slovak},
	{value: 16, label: currentResource.slovenian},
	{value: 17, label: currentResource.swedish},
	{value: 18, label: currentResource.arabic},
	{value: 19, label: currentResource.simplifiedChinese},
	{value: 20, label: currentResource.traditionalChinese},
	{value: 21, label: currentResource.greek},
	{value: 22, label: currentResource.hebrew},
	{value: 23, label: currentResource.indonesian},
	{value: 24, label: currentResource.korean},
	{value: 25, label: currentResource.portuguese},
	{value: 26, label: currentResource.romanian},
	{value: 27, label: currentResource.russian},
	{value: 28, label: currentResource.thai},
	{value: 29, label: currentResource.turkish},
	{value: 30, label: currentResource.vietnamese}
];

export const localizedChannels = [
	{value: 1, label: currentResource.global},
	{value: 2, label: currentResource.americas},
	{value: 3, label: currentResource.unitedStates},
	{value: 4, label: currentResource.japan},
	{value: 5, label: currentResource.canada},
	{value: 6, label: currentResource.argentina},
	{value: 7, label: currentResource.brazil},
	{value: 8, label: currentResource.chile},
	{value: 9, label: currentResource.israel},
	{value: 10, label: currentResource.otherAmericas},
	{value: 11, label: currentResource.asiaPac},
	{value: 12, label: currentResource.thailand},
	{value: 13, label: currentResource.vietnam},
	{value: 14, label: currentResource.singapore},
	{value: 15, label: currentResource.sriLanka},
	{value: 16, label: currentResource.australia},
	{value: 17, label: currentResource.newZealand},
	{value: 18, label: currentResource.republicOfKorea},
	{value: 19, label: currentResource.malaysia},
	{value: 20, label: currentResource.philippines},
	{value: 21, label: currentResource.taiwan},
	{value: 22, label: currentResource.indonesia},
	{value: 23, label: currentResource.china},
	{value: 24, label: currentResource.hongKong},
	{value: 25, label: currentResource.emeia},
	{value: 26, label: currentResource.netherlands},
	{value: 27, label: currentResource.switzerland},
	{value: 28, label: currentResource.cis},
	{value: 29, label: currentResource.ukAndI},
	{value: 30, label: currentResource.finland},
	{value: 31, label: currentResource.denmark},
	{value: 32, label: currentResource.sweden},
	{value: 33, label: currentResource.norway},
	{value: 34, label: currentResource.belgium},
	{value: 35, label: currentResource.luxembourg},
	{value: 36, label: currentResource.italy},
	{value: 37, label: currentResource.spain},
	{value: 38, label: currentResource.portugal},
	{value: 39, label: currentResource.france},
	{value: 40, label: currentResource.india},
	{value: 41, label: currentResource.africaSubArea},
	{value: 42, label: currentResource.middleEast},
	{value: 43, label: currentResource.cse},
	{value: 44, label: currentResource.germany},
	{value: 45, label: currentResource.austria}
];

export const apiCallDateFormat = 'YYYY/MM/DD';
export const messagingDateFormat = 'MM/DD/YYYY hh:mm A';

export const dateFormatsListItems = [
	{
		// 07/22/2014
		id: 0,
		label: 'MM/DD/YYYY',
		format: 'MM/dd/yyyy',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	},
	{
		// 22/07/2014
		id: 1,
		label: 'DD/MM/YYYY',
		format: 'dd/MM/yyyy',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	},
	{
		// 2014/07/22
		id: 2,
		label: 'YYYY/MM/DD',
		format: 'yyyy/MM/dd',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	}
];

export const numberFormatsListItems = [
	{
		id: 0,
		locale: 'en-au',
		label: '1,234,567,890'
	},
	{
		id: 1,
		locale: 'en-in',
		label: '1,23,45,67,890'
	},
	{
		id: 2,
		locale: 'en-za',
		label: '1 234 567 890'
	},
	{
		id: 3,
		locale: 'pt-br',
		label: '1.234.567.890'
	}
];

export const negativeNumberFormatsListItems = [
	{
		id: 0,
		label: '(123.00)'
	},
	{
		id: 1,
		label: '-123.00'
	},
	{
		id: 2,
		label: '- 123.00'
	},
	{
		id: 3,
		label: '123.00-'
	},
	{
		id: 4,
		label: '123.00 -'
	}
];

export const displayLanguageListItems = {
	1: 'English', // EnglishLANDING
	4: 'Français (Canada)', // French (Canadian)
	5: 'Español', // Spanish (Mexican)
	7: 'Português', // Portuguese (Brazilian)
	8: '日本語', // Japanese
	9: '简体中文', // Chinese (Simplified)
	10: '繁體中文', // Chinese (Traditional)
	11: 'עברית', // Hebrew
	12: '한국어', // Korean
	13: 'Pусский' // Russian
	//14: 'عربى' // Arabic
};

export const tabTypes = {
	FavoritesTab: 'myFavEng',
	AllEngagementsTab: 'myengtabs',
	MYARCHIVESTAB: 'MyArchives',
	ALLARCHIVESTAB: 'AllArchives',
	ReviewengagementsTab: 'myReveng ',
	portfolioViewTab: 'portView',
	favoritesv2Filter: 'favoritesv2',
	engagementsv2Filter: 'engagementsv2',
	reviewengagementsv2Filter: 'reviewv2',
	engagementsv1Tab: 'engv1'
};

export const mode = {
	rollForwardMode: 'rollForward',
	restoreMode: 'restore'
};

export const actionTypes = {
	systemMessages: 1,
	engagementInvitation: 2,
	deniedAccess: 3,
	deleteRequest: 4,
	createError: 5,
	archiveAddition: 6,
	deletedEngagement: 7,
	archivedEngagement: 8,
	deactivatedEngagements: 9,
	archiveOverrideRejected: 10,
	groupAuditInvite: 11,
	gaAInstruction: 12,
	copiesReceived: 13,
	engagementInProgress: 14
};

export const showLinks = {
	HideLink: 0,
	AddMember: 1, // Obsolute - not being used with 2.4 version
	ProfileSubmit: 2,
	CompleteIndependence: 3
};

export const applicationErrorCode = {
	EngagementUserNotActive: '7821',
	UserDoesNotExist: '1020'
};

export const resourceFileNames = {
	1: 'en-us',
	2: 'en-us',
	3: 'en-us',
	4: 'fr-ca',
	5: 'es-mx',
	6: 'en-us',
	7: 'pt-br',
	8: 'ja-jp',
	9: 'zh-sp',
	10: 'zh-tr',
	11: 'he',
	12: 'ko',
	13: 'ru',
	14: 'ar-ms'
};

export const permissionGroups = {
	Global_ReviewPortal: 'Global\\ReviewPortal',
	Global_RestoreException: 'Global\\RestoreException',
	Global_GCOCreateGCOEngagements: 'Global\\GCO Create GCO Engagements',
	CanvasPilotEnvironmentEMEIA: 'Canvas Pilot Environment - EMEIA'
};

export const ERPUserType = {
	InternalUser: 1,
	ExternalUser: 2
};

export function getDateformat(dateFormat, date) {
	if (dateFormat === 1) {
		var dateParts = date.split('/');
		// month is 0-based, that's why we need dataParts[1] - 1
		return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
	}

	return new Date(date);
}

export const settingsPreferences = {
	EngagementList: 'engagements',
	WorkspaceList: 'workspace',
	AllEngagements: 'allengagements',
	FavoriteEngagements: 'favoriteengagements',
	LightTheme: 'light',
	DarkTheme: 'dark'
};

export const preferredLandingPageViewSwitcher = {
	EngagementList: 1,
	WorkspaceList: 2
};

export const preferredLandingPageFilterSwitcher = {
	AllEngagements: 1,
	FavoriteEngagements: 2
};

export const preferredThemeSwitcher = {
	Light: 1,
	Dark: 2
};

export const gaRoleTypeConstants = {
	Primary: 1,
	Region: 2,
	Component: 3
};

export function getTheme() {
	return document
		.getElementsByTagName('body')[0]
		.getAttribute('motif-theme') === 'light'
		? null
		: 'dark';
}

export const pagingOptions = {
	options: [20, 50, 100]
};

export const mobilePagingOption = {
	options: [10]
}

export const HttpStatusCodes = {
	Ok: 200,
	Created: 201,
	NoContent: 204,
	Unauthorized: 401,
	Forbidden: 403,
	NotFound: 404,
	NotAcceptable: 406,
	Conflict: 409,
	PreConditionFailed: 412,
	RequestEntityTooLarge: 413,
	RequestUriTooLong: 414,
	BadRequest: 400,
	InternalServerError: 500
};

export const dateAdminFormats = [
	{
		// 07/22/2014
		id: 0,
		label: 'MM/DD/YYYY',
		format: 'MM/dd/yyyy',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	},
	{
		// 22/07/2014
		id: 1,
		label: 'DD/MM/YYYY',
		format: 'dd/MM/yyyy',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	},
	{
		// 2014/07/22
		id: 2,
		label: 'YYYY/MM/DD',
		format: 'yyyy/MM/dd',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	}
];

export const confidentialityTypes = {
	DEFAULT: 0,
	LOW: 1,
	MODERATE: 2,
	HIGH: 3
};

export const MSGraphURL =
	'https://graph.microsoft.com/v1.0/users/{0}?$select=extension_2cc2f842b72044dcaf51a3fc59fc27c8_EYEmplStatus';

export const UserLookupType = {
	NotSpecified: 0,
	Internal: 1,
	External: 2,
	Admin: 3,
	ThirdPartyUser: 4
};

export const UserERPTypeId = {
	NotSpecified: 0,
	Internal: 1,
	External: 2,
	Both: 3,
	ThirdPartyUser: 4
};

export const rbacSections = {
	NotSpecified: 0,
	Confidentiality: 1,
	Permissions: 2,
	Restrictions: 3
};

export const meCallPermissions = {
	hasEngagementDefaultRole: 'hasEngagementDefaultRole',
	canReadEngagement: 'canReadEngagement',
	canUpdateEngagement: 'canUpdateEngagement',
	canInviteEngagementMember: 'canInviteEngagementMember',
	canUpdateEngagementUser: 'canUpdateEngagementUser',
	canReadTemplates: 'canReadTemplates',
	canUpdateTemplates: 'canUpdateTemplates',
	canDeleteTemplates: 'canDeleteTemplates',
	canReadPermissionsGroups: 'canReadPermissionsGroups',
	canCreateUpdatePermissionsGroups: 'canCreateUpdatePermissionsGroups',
	canSyncPermissionsGroups: 'canSyncPermissionsGroups',
	canCreateUpdateMessaging: 'canCreateUpdateMessaging',
	canDeleteMessaging: 'canDeleteMessaging',
	canReadMessaging: 'canReadMessaging',
	canReadManageAccess: 'canReadManageAccess',
	canCreateUpdateManageAccess: 'canCreateUpdateManageAccess',
	canReadDeleteCache: 'canReadDeleteCache',
	canSyncDatabaseLocator: 'canSyncDatabaseLocator',
	canAccessDocuments: 'canAccessDocuments'
};

export const rbacRoleKeys = {
	NotSpecified: 0,
	defaultRole: 1,
	adminRole: 4,
	ThirdPartyUserReadOnly: 14,
	LowConfidentialDocumentsRole: 10,
	ModerateConfidentialDocumentsRole: 12,
	HighConfidentialDocumentsRole: 11,
	StrictlyConfidentialDocumentsRole: 13,
	InternalReviewOnlyRole: 16,
	IndependenceOnlyRole: 37
};

export const moduleType = {
	ManageAccess: 1,
	Engagements: 2,
	PermissionGroups: 3,
	Templates: 4,
	Messaging: 5,
	Cache: 6,
	Documents: 8
};
export const employmentStatuses = ['A', 'P', 'L'];
export const employmentStatusTypes = {
	DefaultStatus: 'DefaultStatus',
	Active: 'Active',
	InActive: 'InActive'
};

export const modules = [
	{
		id: 1,
		name: 'Manage access'
	},
	{
		id: 2,
		name: 'Engagements'
	},
	{
		id: 3,
		name: 'Permission Groups'
	},
	{
		id: 4,
		name: 'Templates'
	},
	{
		id: 5,
		name: 'Messaging'
	},
	{
		id: 6,
		name: 'Cache'
	},
	{
		id: 7,
		name: 'Database Locator'
	},
	{
		id: 8,
		name: 'Documents'
	}
];

export const engagementsSearchRetainFlag = {
	IsRetainEngagementsSearchResult: 'IsRetainEngagementsSearchResult'
};

export const expiryDaysTopCount = 45;

export const guidCheckerRegex = new RegExp(
	'^[a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}$'
);

export const cacheKeyRegex = new RegExp(
	'^([A-Za-z0-9_\\-=&#@$%\\[\\].]+)$'
);

export const reportFormateType = {
	xlsx: 'xlsx'
};

export const reportType = {
	Validation: 1
};

export const defaultMaxCharCountKendo = 4000;

// Engagement v2 constants.
export const engagementV2DefaultSkip = 1;
export const engagementV2DefaultTop = 6;
export const engagementV2PageSizeOptions = [6, 12, 24, 48];

export const invalidClientCode = '!!!';

export const auditPhaseEnum = {
	scopeAndStrategy: 1,
	execution: 2,
	conclusion: 3
};

export const mobileLabelParams = {
	searchTypeIdLabel : 'searchtypeid eq ',
	displayTypeIdLabel : ',displaytypeid eq 1'
}

export const userStatusEnum = {
	undefined: 0,
	active: 1,
	pending: 2,
	rejected: 3,
	deActivated: 4,
	requestForArchiveAccess: 5,
	rejectArchiveAccess: 6,
	copyEngagementInactive: 7,
	unlinkedInactive: 8,
	templateInactive: 9,
	rollForwardInactive: 10,
	localizedToIsolatedUniversal: 11,
	invitationinProgress: 12,
	scheduledInactive: 13
};

export const engagementAccessTypeEnum = {
	default: 1,
	independenceOnly: 2
};