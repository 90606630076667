/**
 * Redux Middleware handling offline mode.
 */
const offlineEngagements = (config) => (store) => (next) => (action) => {
	// Check if the action has meta property and meta.api property
	// HOME_LOGIN type will be allowed for verification
	// HOME_LOGIN is the initial azure ad login for the client id
	// HOME_LOGIN will not have any api meta in the action and does not require any reducers

	if (action.isOnline) {
		next(action);
		return Promise.resolve(
			'AppOnlineState Middleware: any action in online should continue to next middleware'
		);
	}

	if (action.type !== 'LOAD_MY_ENGAGEMENTS') {
		next(action);
		return Promise.resolve(
			'AppOnlineState Middleware: any action other than LOAD_MY_ENGAGEMENTS should continue to next middleware'
		);
	}

	//Check for config availability
	if (!config) {
		const configMsg = `
        Config unavailable.
        The AD package requires config to get the authorization token for the api call.`;
		console.log(configMsg);
	}

	if (!action.meta) {
		action.meta = {};
		action.meta.api = {};
	}

	action.meta.api.url = 'offline_engagements';
	next(action);

	return Promise.resolve(
		'get all offline engagements with details for offline'
	);
};

export default offlineEngagements;
