/**
 * Created by calhosh on 7/13/2017.
 */
import React from 'react';
import styled from 'styled-components';
import {MotifSearch, MotifFormField} from '@ey-xd/motif-react';
import {getTheme} from '../../../util/uiconstants';
export default function EYMotifSearch(props) {
	const {
		value,
		placeholder,
		onChange,
		onKeyDown,
		onEnter,
		onSearchButtonClick,
		onClear,
		styleName,
		darkBg,
		clearHoverText,
		searchHoverText,
		hideClearButton,
		maxlength
	} = props;
	return (
		<Wrapper className={`${styleName} ${darkBg ? 'darkBgSearch' : ''}`}>
			<MotifFormField>
				<MotifSearch
					aria-label="Search"
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					onClear={onClear}
					onKeyDown={onKeyDown}
					onEnter={onEnter}
					maxlength={maxlength}
					onSearchButtonClick={onSearchButtonClick}
					clearButtonTitle={clearHoverText}
					searchButtonTitle={searchHoverText}
					hideClearButton={hideClearButton}
					theme={getTheme()}
				/>
			</MotifFormField>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	.motif-form-field {
		margin-bottom: 0;
		width: 100%;
	}
	.motif-search-input-wrapper {
		width: 100%;
		background-color: ${(props) => props.theme.color[`grey100grey550`]};
		display: flex;
		.motif-input-component {
			width: 100%;
		}
		.motif-input-component {
			.motif-input:hover:not(:disabled),
			.motif-input:focus:not(:disabled) {
				//background-color: transparent;
				//border-bottom: 0.1rem solid transparent;
				margin-bottom: 0;
			}
		}
		.motif-search-input-icon {
			top: 0;
			box-shadow: none;
			height: 100%;
		}
		.motif-typeahead .motif-typeahead-close-button {
			top: 0.2rem;
			box-shadow: none;
			height: 100%;
		}
		input {
			color: ${(props) => props.theme.color[`grey500`]};
			// background-color: transparent;
			padding: 0.2rem 0.5rem;
			font-size: 1.1rem;
			height: 100%;
			font-weight: normal;
			// border-bottom: 0.1rem solid transparent;
			&::placeholder {
				opacity: 1;
			}
		}
		.motif-select-open {
			display: none;
		}
	}
	&.darkBgSearch {
		input {
			color: ${(props) => props.theme.color[`white`]};
			border-bottom-color: ${(props) => props.theme.color[`white`]};
		}
		input::-ms-input-placeholder,
		input::placeholder {
			color: ${(props) => props.theme.color[`white`]};
		}
		.motif-typeahead .motif-typeahead-close-button {
			fill: ${(props) => props.theme.color[`white`]};
		}
		.motif-input-component {
			.motif-input:hover:not(:disabled),
			.motif-input:focus:not(:disabled) {
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`white`]};
			}
		}
		.motif-search-input-wrapper {
			.motif-icon-button path {
				fill: ${(props) => props.theme.color[`white`]};
			}
		}
	}
`;
