import React from 'react';
import styled from 'styled-components';
import {labels, urls} from '../../util/uiconstants';
import env from '../../util/env';
import ModalControl from '@ey/modal-control';
import EllipsesControl from '@ey/ellipses-control';
import {useDispatch} from 'react-redux';
import {DeleteEngagement} from '../../actions/engagementsaction';

const CrpDeleteModal = (props) => {
	const {show, quit, hide, id, doRefreshDashboard} = props;
	const {engagementdesc} = props.data;

	const dispatch = useDispatch();

	const url = env.getURL('serviceUrl') + '/' + urls.ENGAGEMENTS_URL;
	const titleText = labels.deleteReviewCopy;
	const popupMsg = labels.deleteReviewCopyMsg;

	const handleConfirm = async () => {
		try {
			closeModal();
			await dispatch(DeleteEngagement(url + '/' + id, null));
		} finally {
			doRefreshDashboard();
		}
	};

	const closeModal = () => {
		// This will refresh dashboard
		hide();
	};

	return (
		<ModalControl
			title={titleText}
			closeTitle={labels.close}
			confirmBtnTitle={labels.confirm}
			closeBtnTitle={labels.cancel}
			modalsize="small"
			onOkClick={handleConfirm}
			show={show}
			onHide={quit}
			isOkButtonDisabled={false}
		>
			<RemoveEngagementStyle>
				<div id="message">
					<h6>{popupMsg}</h6>
				</div>
				<h6 className="engagement-name">
					<EllipsesControl
						id=""
						content={engagementdesc}
						tooltip={engagementdesc}
						isTooltipAvailable
					/>
				</h6>
			</RemoveEngagementStyle>
		</ModalControl>
	);
};
export default CrpDeleteModal;

const RemoveEngagementStyle = styled.section`
	h6 {
		font-size: 1.4rem;
		line-height: normal;
		word-break: break-word;
		margin: 2rem;
	}

	& .engagement-name {
		width: calc(100% - 4rem);
		padding: 0;
		display: inline-block;
		margin: 0 2rem 2rem 2rem;
		& .ellipses {
			font-weight: bold;
		}
	}
`;
