import * as types from '../../actions/actiontypes';

export function getUserSearch(url, resource) {
	return {
		type: types.GET_USER_SEARCH,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data: {resource: resource}
			}
		}
	};
}
