/**
 * Created by calhosh on 7/16/2017.
 */

import React from 'react';
import NotificationSystem from 'react-notification-system';
import connect from 'react-redux-connect';
import {bindActionCreators} from 'redux';
import {labels, currentResource} from '../../../util/uiconstants';
import * as errorActions from '../../../actions/erroractions';
import * as loaderActions from '../../../actions/loaderactions';
import {findDOMNode} from 'react-dom';
import $ from 'jquery';
import _ from 'lodash';
import styled from 'styled-components';

const StyledErrors = styled.div`
	.notifications-wrapper {
		.notifications-tc {
			width: calc(100vw - 8.5rem) !important;
			top: 6rem !important;
			left: 4rem !important;
			margin: 0 auto !important;
			padding: 0 !important;
			z-index: 1000 !important;
			max-width: 2048px;
			@media only screen and (min-width: 2048px) {
				width: calc(100vw - 17rem) !important;
				left: 8.2rem !important;
			}
			.notification {
				margin: 0 !important;
				padding: 0 !important;
				border: 0.1rem solid ${(props) => props.theme.color[`grey300`]} !important;
				height: auto !important;
				margin-bottom: 0.2rem !important;
				box-shadow: 0.1rem 0.1rem 0.4rem
					${(props) => props.theme.color[`grey300`]} !important;
				background: ${(props) => props.theme.color[`white`]} !important;

				&.notification-error,
				&.notification-success {
					background: ${(props) => props.theme.color[`white`]} !important;
				}

				.notification-title {
					display: none;
				}

				.notification-message {
					padding: 0 !important;
					max-height: 60vh;
					overflow: auto;
					color: ${(props) => props.theme.color[`grey500`]} !important;
					display: table;
					width: calc(100% - 3rem);
					font-size: 1.2rem;
					line-height: 1.8rem;

					.error-message {
						display: table-cell;
						padding: 0.9rem 1rem 0.5rem;
						color: ${(props) => props.theme.color[`grey500`]};
						background: ${(props) => props.theme.color[`white`]} !important;
					}
				}

				.notification-dismiss {
					background-color: transparent !important;
					top: 1.2rem !important;
					right: 1.4rem !important;
					color: ${(props) => props.theme.color[`grey500`]} !important;
					font-size: 2rem !important;
					font-weight: normal !important;
				}
			}
		}
	}
`;

class Errors extends React.Component {
	static mapStateToProps(store) {
		let errors = store.errors,
			isModalOpen = store.isModalOpen;
		return {
			errors,
			isModalOpen
		};
	}

	static mapDispatchToProps(dispatch) {
		return {
			actions: bindActionCreators(
				Object.assign({}, errorActions, loaderActions),
				dispatch
			)
		};
	}
	static getErrorMessagesByCode(errorCodes) {
		let errorMessages = [];

		errorCodes.map((errorCode) => {
			const message = currentResource.errors[errorCode];
			errorMessages.push(
				message ? '<strong>' + errorCode + '</strong>: ' + message : errorCode
			);
		});

		return errorMessages;
	}

	static errorCountDisplay(errors) {
		let errorCount = 0;
		let error;

		if (typeof errors == 'string') {
			try {
				error = JSON.parse(errors);
			} catch (err) {}
		}

		if (Array.isArray(error)) {
			errorCount = error.length;
		} else {
			errorCount = 1;
		}

		return labels.errorBanner
			? labels.errorBanner.replace('{0}', errorCount)
			: '';
	}

	constructor(props) {
		super(props);
		this.state = {
			style: {
				//custom notification styles
				NotificationItem: {
					//override the notification item
					DefaultStyle: {
						margin: '1rem 0.5rem',
						padding: '0'
					}
				}
			},
			allowHTML: true
		};

		_.bindAll(this, ['clearErrors']);
	}

	componentDidMount() {
		this.notificationSystem = this.refs.notificationSystem;
		if (this.props.errors && this.props.errors.length > 0) {
			//this.props.actions.hideLoader();
			let level = this.props.errors.level ? this.props.errors.level : 'error';
			let message = this.props.errors.message
				? this.props.errors.message
				: this.errorMessages(this.props.errors);
			this.notificationSystem.addNotification({
				message:
					'<span class="' +
					level +
					'-icon"></span> <span class="error-message">' +
					message +
					'</span>',
				level: level,
				position: 'tc', //top center
				autoDismiss: 0, //dont autodismiss
				onRemove: this.clearErrors,
				dismissible: 'button'
			});
		}
	}

	componentDidUpdate() {
		this.notificationSystem = this.refs.notificationSystem;
		if (this.notificationSystem) {
			this.props.actions.hideLoader();

			let closeObj = findDOMNode(this.notificationSystem);
			let close = $(closeObj).find('.notification-dismiss');
			if (close.length > 0) {
				close.attr('title', labels.close);
			}
		}
	}

	shouldComponentUpdate(nextprops, nextstate) {
		if (nextprops.isModalOpen) return false;
		else return true;
	}

	componentWillReceiveProps(newProps) {
		this.notificationSystem = this.refs.notificationSystem;
		if (!newProps.isModalOpen) {
			if (_.keys(newProps.errors).length > 0) {
				let level = this.props.errors.level ? this.props.errors.level : 'error';
				let newMessage = newProps.errors.message
					? newProps.errors.message
					: this.errorMessages(newProps.errors);
				this.notificationSystem.addNotification({
					message:
						'<span class="' +
						level +
						'-icon"></span> <span class="error-message">' +
						newMessage +
						'</span>',
					level: 'error',
					title: Errors.errorCountDisplay(newProps.errors),
					position: 'tc', //top center
					autoDismiss: 0, //dont autodismiss
					onRemove: this.clearErrors,
					dismissible: 'button'
				});
			}
		}
		if (this.notificationSystem) {
			//this.props.actions.hideLoader();

			let closeObj = findDOMNode(this.notificationSystem);
			let close = $(closeObj).find('.notification-dismiss');
			if (close.length > 0) {
				close.attr('title', labels.close);
			}
		}
	}

	errorMessages(errors) {
		let errorCodes = [];
		let errorMessages = [];
		let error;
		let message = '';
		let errorMessage = '';

		if (typeof errors == 'string') {
			try {
				error = JSON.parse(errors);
			} catch (err) {
				message = errors;
			}
		} else {
			error = errors;
		}

		if (error) {
			if (Array.isArray(error)) {
				error.map((error) => {
					if (error.ErrorCode) {
						errorCodes.push(error.ErrorCode);
					} else {
						errorMessages.push(error.Message);
					}
				});
			} else {
				errorCodes.push(error.ErrorCode || error);
			}

			if (errorCodes.length !== 0) {
				errorMessages = Errors.getErrorMessagesByCode(errorCodes);
				if (errorMessages.length > 0) {
					errorMessages.map((message) => {
						errorMessage += '<div>' + message + '</div>';
					});
					return errorMessage;
				}
			}

			if (errorMessages.length > 0) {
				errorMessages.map((message) => {
					errorMessage += '<div>' + message + '</div>';
				});
				return errorMessage;
			}
		} else {
			return message;
		}
	}

	clearErrors() {
		//this.props.actions.clearErrors();
	}

	render() {
		console.log('error state', this.props.errors);
		return (
			<StyledErrors>
				<NotificationSystem ref="notificationSystem" allowHTML />
			</StyledErrors>
		);
	}
}

export default connect(Errors);
