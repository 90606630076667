import * as types from '../actiontypes';

export function getCache(url) {
	return {
		type: types.GET_CACHE,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function clearResult() {
	return {
		type: types.CLEAR_CACHE_RESULT
	};
}

export function deleteCache(url) {
	return {
		type: types.DELETE_CACHE,
		meta: {
			api: {
				url: url,
				method: 'DELETE'
			}
		}
	};
}
