/**
 * Scot.js
 * Created by zl952fc on 02/24/2020.
 */

import React, {useState, useEffect} from 'react';
import {SVGICONS} from '@ey/icons';
import {labels, currentResource} from '../../../util/uiconstants';
import {ActionTypes} from '../../../util/enumerations';
import Notifications from './Notifications';
import styled from 'styled-components';

export default function NotificationList(props) {
	const {notification, setCloseNotification, close} = props;
	const [isExpanded, setIsExpanded] = useState(false);
	useEffect(() => {
		if (
			notification.actionType == ActionTypes.SystemMessages &&
			notification.notifications.length > 0 &&
			(close == undefined || close == false)
		) {
			setIsExpanded(true);
		}
	}, [close]);

	// useEffect(() => {
	// 	if (
	// 		notification.actionType == ActionTypes.SystemMessages &&
	// 		notification.notifications.length > 0
	// 	) {
	// 		setIsExpanded(true);
	// 	}
	// }, [notification]);

	const onNotifyClick = (e) => {
		setCloseNotification(true);
		setIsExpanded(!isExpanded);
	};

	const getFirstButtonLabel = (actionType) => {
		switch (actionType) {
			case ActionTypes.DeletedEngagement:
			case ActionTypes.DeactivatedEngagements:
			case ActionTypes.ArchivedEngagement:
			case ActionTypes.ArchiveDeadlineDateOverride:
				return labels.clearAll;
			default:
				return '';
		}
	};

	const getSecondButtonLabel = (actionType) => {
		switch (actionType) {
			case ActionTypes.EngagementInvitation:
				return labels.rejectall;
			default:
				return '';
		}
	};

	const onFirstButtonClick = () => {
		notification.isFromList = true;
		props.onFirstButtonClick(notification);
	};

	const onSecondButtonClick = () => {
		notification.isFromList = true;
		props.onSecondButtonClick(notification);
	};

	const title1 = getFirstButtonLabel(notification.actionType),
		title2 = getSecondButtonLabel(notification.actionType);

	return (
		<NotificationListWrapper className="NotificationListContainer">
			<section
				className={`${isExpanded ? 'active' : ''} NotificatonListHeader`}
			>
				<span onClick={onNotifyClick} className="NotificationListLeftGroup">
					<span
						className="PlusMinusIcon"
						title={isExpanded ? labels.collapse : labels.expand}
					>
						<SVGICONS
							hoverText={isExpanded ? labels.collapse : labels.expand}
							styleName="medium"
							name={isExpanded ? 'minus' : 'plus'}
							alt="collapsed|expanded"
						/>
					</span>
					<span className="NotificatonListHeaderName">
						{currentResource.actionTypes[notification.actionType]}
					</span>
				</span>
				<span className="Count">{notification.notifications.length}</span>
				{(notification.actionType === ActionTypes.DeactivatedEngagements ||
					notification.actionType === ActionTypes.ArchivedEngagement ||
					notification.actionType ===
						ActionTypes.ArchiveDeadlineDateOverride) && (
					<span className="ButtonsGroup">
						<button title={title1} label={title1} onClick={onFirstButtonClick}>
							<span className="btnLabel">{title1}</span>
						</button>
					</span>
				)}
			</section>
			{isExpanded &&
				notification &&
				notification.notifications &&
				notification.notifications.length > 0 && (
					<>
						<section className="PlaceholderText">
							{notification.actionType === ActionTypes.DeletedEngagement && (
								<span className="WarningWrapper">
									<SVGICONS
										hoverText="Warning"
										styleName="medium"
										name="report_problem"
										alt="Warning"
									/>
									<>
										<span className="warning-message2">
											{labels.deletedEngagementWarning}
										</span>
										<span className="ButtonsGroup2">
											<button
												title={title1}
												label={title1}
												onClick={onFirstButtonClick}
											>
												<span className="btnLabel">{title1}</span>
											</button>
										</span>
									</>
								</span>
							)}
							{notification.actionType === ActionTypes.DeleteRequest && (
								<span className="WarningWrapper">
									<SVGICONS
										hoverText="Warning"
										styleName="medium"
										name="report_problem"
										alt="Warning"
									/>
									<span className="warning-message">
										{labels.searchEngagementWarning}
									</span>
								</span>
							)}
							{notification.actionType === ActionTypes.CreateError && (
								<span className="WarningWrapper">
									<SVGICONS
										hoverText="Warning"
										styleName="medium"
										name="report_problem"
										alt="Warning"
									/>
									<span className="warning-message">
										{labels.engCreationFailedWarning}
									</span>
								</span>
							)}
						</section>
						<Notifications
							notifications={notification.notifications}
							onFirstButtonClick={props.onFirstButtonClick}
							onSecondButtonClick={props.onSecondButtonClick}
							acceptedEngagementList={props.acceptedEngagementList}
							acceptedArchiveEngagementList={
								props.acceptedArchiveEngagementList
							}
							isInviteAcceptedOrRejected={props.isInviteAcceptedOrRejected}
							isArchiveInviteAcceptedOrRejected={
								props.isArchiveInviteAcceptedOrRejected
							}
							setisInviteAcceptedOrRejectedToDone={
								props.setisInviteAcceptedOrRejectedToDone
							}
							setisArchiveInviteAcceptedOrRejectedToDone={
								props.setisArchiveInviteAcceptedOrRejectedToDone
							}
						/>
					</>
				)}
		</NotificationListWrapper>
	);
}

const NotificationListWrapper = styled.section`
	background-color: ${(props) => props.theme.color[`grey100`]};
	border-top: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
	&:last-child {
		border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
	}
	.NotificatonListHeader {
		padding: 1rem 1.5rem;

		& > * {
			line-height: 3.2rem;
		}

		&.active {
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
			.NotificatonListHeaderName {
				font-weight: bold;
			}
		}
		.NotificationListLeftGroup {
			display: inline-block;
			width: calc(100% - 24rem);
			font-size: 1.4rem;
			.PlusMinusIcon {
				display: inline-block;
				margin-right: 1rem;
				cursor: pointer;
				vertical-align: top;
				svg {
					fill: ${(props) => props.theme.color[`grey500`]} !important;  
					vertical-align: middle;
				}
			}
			.NotificatonListHeaderName {
				display: inline-block;
				color: ${(props) => props.theme.color[`grey500`]};
				width: calc(100% - 4rem);
				cursor: pointer;
			}
		}
		.Count {
			display: inline-block;
			float: right;
			border-radius: 1rem;
			color: ${(props) => props.theme.color[`white`]};
			background-color: ${(props) => props.theme.color[`grey500`]};
			padding: 0.2rem 0.6rem;
			cursor: pointer;
			line-height: initial;
			vertical-align: middle;
			text-align: center;
			margin: 0.7rem 0 0 2rem;
			font-size: 1.2rem;
			min-width: 3.2rem;
		}
		.ButtonsGroup {
			display: inline-block;
			float: right;
			font-size: 1.4rem;
			vertical-align: middle;
			button {
				border: 0;
				margin-right: 1rem;
				font-weight: bold;
				background: transparent;
				color: ${(props) => props.theme.color[`blackyellow`]};
				&:hover {
					text-decoration: underline;
				}
			}
			button:last-child {
				margin-right: 0;
			}
		}
	}
	.PlaceholderText {
		.WarningWrapper {
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
			padding: 0.5rem 1.5rem;
			display: block;
			background-color: ${(props) => props.theme.color[`white`]};
			svg {
				fill:  ${(props) => props.theme.color[`orange200`]};
				margin-top: 0.6rem;
			}
		}
		.warning-message {
			display: inline-block;
			margin-left: 0.9rem;
			font-weight: bold;
			width: 90%;
			line-height: 1.5rem;
			vertical-align: middle;
			font-size: 1.3rem;
			padding: 0.5rem 0;
			color: ${(props) => props.theme.color[`grey500`]};
		}
	}
	.ButtonsGroup2 {
		display: inline-block;
		float: right;
		font-size: 1.4rem;
		vertical-align: middle;
		button {
			border: 0;
			margin-right: 1rem;
			font-weight: bold;
			background: transparent;
			color: ${(props) => props.theme.color[`blackyellow`]};
			&:hover {
				text-decoration: underline;
			}
		}
		button:last-child {
			margin-right: 0;
		}
	}
	.warning-message2 {
		display: inline-block;
		margin-left: 0.9rem;
		font-weight: bold;
		line-height: 1.5rem;
		vertical-align: middle;
		font-size: 1.3rem;
		padding: 0.5rem 0;
		color: ${(props) => props.theme.color[`grey500`]};
	}
}

`;
