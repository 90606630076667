import styled from 'styled-components';

//enagement userlist page style
const Headerbody = styled.section`
	.toastmessage {
		// .motif-toast{
		// 	position: absolute;
		// 	top: 11rem;
		// 	width: calc(100% - 8rem);
		// }
		.motif-toast-text-container {
			font-size: 1.2rem;
		}
	}
	// common for table UI start
	.editPermissions-modal {
		width: 30%;
		max-width: 30%;
		height: auto;
	}

	.pageheading {
		display: flex;
		padding: 2rem 0 2.2rem 0;

		.motif-tooltip-wrapper {
			.motif-button {
				font-size: 1.2rem;
				padding: 1rem;

				&[disabled] {
					pointer-events: none;
				}
			}
		}
	}

	.e-page-title {
		margin: 0;
		margin-right: auto;
		line-height: 4rem;
	}

	.pagerholder {
		margin: 0 auto;
		width: calc(100vw - 0.5rem);
		position: absolute;
		bottom: 0rem;
		background: ${(props) => props.theme.color[`white`]};

		.engagement-paging {
			font-size: 1.4rem;
			justify-content: flex-end;
			background: ${(props) => props.theme.color[`white`]};
			border-top: 1px solid ${(props) => props.theme.color[`grey200`]};

			.Dropdown-arrow {
				margin-top: 0.3rem;
			}
		}
	}

	.userlistwrapper {
		width: 100%;
		margin-bottom: 4rem;
		display: inline-block;

		.manageaccessitemview {
			width: 100%;
			font-size: 1.4rem;
			display: flex;
			border-collapse: collapse;
			align-items: center;
			justify-content: center;
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};

			&.inactive mgt-person {
				color: ${(props) => props.theme.color[`grey300`]};
			}

			&:not(.inactive) .userName {
				color: ${(props) => props.theme.color[`grey500`]};
			}

			.grid {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-gap: 0 10px;
			}

			&:last-child {
				border-bottom: none;
				// padding-bottom: 1rem;
			}
		}

		.userlist-header {
			width: 100%;
			display: inline-block;
			.editicon {
				width: 4%;
				padding: 1rem;
				vertical-align: middle;
			}
			.userlistheader {
				width: 100%;
				font-size: 1.4rem;
				color: ${(props) => props.theme.color[`grey450`]};
				font-weight: bold;
				display: flex;
				border-collapse: collapse;
				background: ${(props) => props.theme.color[`grey100`]};
				border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
			}
		}

		.engagement-userlist-body {
			width: 100%;
			// overflow: auto;
			// height: calc(100vh - 55rem);
			display: inline-block;
			border: 0.1rem solid ${(props) => props.theme.color[`grey200`]};

			.manageaccessitemview {
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
			}

			.editicon-button {
				width: 4%;
				.motif-tooltip-wrapper {
					width: auto !important;
				}
			}

			.userlistholder {
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
				.motif-tooltip-wrapper {
					width: 100%;
					.motif-tooltip-trigger-wrap {
						width: 100%;
						display: inline-block;

						.react-select__single-value {
							padding: 0;
						}
					}
				}
			}
		}

		.userlist-header,
		.engagement-userlist-body {
			.userlistitem {
				padding: 1rem;
				flex: 1 1 auto;
				width: 10%;
				vertical-align: middle;
				&.date,
				&.status {
					width: 6%;
				}

				&.status{

					&.postarchiveactive {
						.ellipses{
							width: auto;
							max-width: 48%;
						}
					}

					&.nopostarchiveactive{
						.postarchive{
							display: none;
						}
					}
					
					.ellipses{
						display: inline-flex;
						width: 100%;				
						margin-right: 1rem;						
					}
					
					.postarchive{
						display: inline-flex;
						.motif-badge{		
							color: ${(props) => props.theme.color[`orange500`]};;
							border: 0.1rem solid ${(props) => props.theme.color[`orange500`]};
							border-radius: 6rem;
							.motif-badge-number{	
								line-height: normal;
							}
						}
					}
				}

				&.adstatus,
				&.gui{
					width: 8%;
				}

				&.status,
				&.indstatus {
					width: 12%;
				}

				&.permission{
					width: 16%;

					.pic{
						margin: 0 1.1rem 0 0.6rem;
						padding: 1rem 0 0 0;
						line-height: normal;
						display: inline-flex;
						.picflag{
							font-weight: bold;
							font-size: 1.2rem;
							color: ${(props) => props.theme.color[`green500`]};
						}
					}		
				}

				.e-country-dd .react-select__control {
					min-height: 4rem;
				}

				.permission-list {
					button {
						border: 0;
						background: none;

						.motif-badge {
							padding: 0 0.5rem;

							svg {
								vertical-align: text-top;
							}
						}
					}
					.show-pop-content {
						&.left{
							left: 0;
						}
						&.right{
							right: 0;
						}
						.checklist {
							ul li {
								margin-bottom: 0;
								a {
									width: 100%;
									color: ${(props) => props.theme.color[`grey500`]};
									display: inline-block;
									&:hover {
										text-decoration: none;
									}
								}
							}
						}
					}
				}
				.motif-badge {
					padding: 0.2rem 0.8rem;
					border-radius: 6rem;
					font-size: 1.2rem;
					line-height: 2rem;
					margin-right: 0.5rem;
				}
			}
		}
	}

	// common for table UI end
`;

export default Headerbody;
