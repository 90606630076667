import initialstate from './initialstate';
import {
	START_IDLE_TIMER,
	SHOW_IDLETIME_POPUP,
	START_LOGOUT,
	RESET_IDLE_TIMER
} from '../actions/actiontypes';

export function timeoutReducer(state = initialstate.idleLogout, action) {
	switch (action.type) {
		case START_IDLE_TIMER:
			return {
				...state,
				timeoutID: action.timeoutID,
				startTime: action.startTime
			};
		case SHOW_IDLETIME_POPUP:
			return {
				...state,
				showPopup: action.showPopup,
				startLogout: action.startLogout,
				logoutTimeoutID: action.logoutTimeoutID,
				logoutTimestamp: action.logoutTimestamp
			};
		case START_LOGOUT:
			return {
				...state,
				showPopup: action.showPopup,
				startLogout: action.startLogout
			};
		case RESET_IDLE_TIMER:
			return {
				...state,
				timeoutID: action.timeoutID,
				startTime: action.startTime,
				showPopup: action.showPopup,
				startLogout: action.startLogout
			};
		default:
			return state;
	}
}
