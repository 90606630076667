import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Loader from '../../Common/Loaders/loadingindicator';
import _ from 'lodash';
import env from '../../../util/env';
import {UserLookupType} from '../../../util/uiconstants';
import {labels} from '../../../util/uiconstants';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';
import ReactSelect from '../SelectDropdown/SelectDropdown';
import {getUserSearch} from '../../../actions/admin/usersearchactions';
import {lightColor} from '@ey/styled-theme';

/*
documentation:
This component can be used for searching internal or external users.
Once user types a name of a user and hits enter, he is presented with list of users.
On seleting user, caller component received the data via 
onUserSelectionChange.
*/

function UserSearch(props) {
	const userSearcheResults = useSelector((state) => state.userSearcheResults);

	const dispatch = useDispatch();

	const [selectedUser, setSelectedUser] = useState('');
	const [userOptions, setUserOptions] = useState([]);
	const [isUserOptionsOpen, setIsUserOptionsOpen] = useState(false);
	const [defaultValue, setDefaultValue] = useState('');
	const [searchDisabled, setSearchDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [startShowingResults, setStartShowingResults] = useState(false);
	const [callFailed, setCallFailed] = useState(false);
	const [failureError, setFailureError] = useState('');
	const [showValidations, setShowValidations] = useState(false);
	const [ignoreFirst, setIgnoreFirst] = useState(true);

	useEffect(() => {
		if (props.enableClearText != undefined && props.enableClearText) {
			clearText();
		}
		if (props.enableUserSearch != undefined) {
			setSearchDisabled(props.enableUserSearch);
		}
	}, [props]);

	useEffect(() => {
		if (startShowingResults) {
			setIsUserOptionsOpen(true);
			if (userSearcheResults) {
				let users = _.map(userSearcheResults, getSingleUserOption);
				setUserOptions(users);
			} else setUserOptions([]);
		}
	}, [userSearcheResults]);

	useEffect(() => {
		if (!ignoreFirst) showValidationsForSearch();
		else setIgnoreFirst(false);
	}, [props.showValidations]);

	const showValidationsForSearch = () => {
		if (selectedUser && selectedUser != 'default') setShowValidations(false);
		else setShowValidations(true);
	};

	const enterKeyPress = (e) => {
		setStartShowingResults(true);
		if (e.target.value == '') {
			setUserOptions([]);
		} else {
			if (
				(props.lookupType == UserLookupType.ThirdPartyUser ||
					props.lookupType == UserLookupType.External) &&
				!validateEmail(e.target.value)
			) {
				setShowValidations(true);
				return;
			}
			getUserSearchResults(e.target.value, props.lookupType || 1);
		}
	};

	const formatOptionLabel = ({label}) => (
		<section>
			<section className="form-search-label">{label}</section>
		</section>
	);

	function validateEmail(emailField) {
		var reg = /^([A-Za-z0-9_\-\.\'])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,63})$/;
		return reg.test(emailField) ? true : false;
	}

	const keyPress = (e) => {
		setShowValidations(false); //clear validations
		if (e.keyCode == 13 && !isUserOptionsOpen) {
			//enter press
			enterKeyPress(e);
		} else if (e.keyCode != 38 && e.keyCode != 40) {
			setIsUserOptionsOpen(false);
			setUserOptions([]);
		}
	};

	const clearText = () => {
		if (!props.enableUserSearch) setSearchDisabled(false);
		setCallFailed(false);
		setFailureError('');
		setDefaultValue('');
		//Copyhub-Create Package
		//setSelectedUser('default');
		setSelectedUser('');
		//End-Copyhub-Create Package
		setIsUserOptionsOpen(false);
		if (props.onUserClear) {
			props.onUserClear();
		}
	};

	const getSingleUserOption = (user) => {
		return {
			label: `${user.displayName} (${user.email})`,
			value: user.email,
			allProps: user
		};
	};

	const getUserSearchResults = (searchKey, userLookUpType) => {
		setCallFailed(false);
		setFailureError('');

		const requestsUrl =
			env.getURL('serviceUrlV2') +
			'Users?searchString=' +
			searchKey +
			'&userLookupType=' +
			userLookUpType;
		setIsLoading(true);
		dispatch(getUserSearch(requestsUrl))
			.then(() => {
				setIsLoading(false);
				setSearchDisabled(false);
				setUserSearchFocus();
			})
			.catch((e) => {
				setFailureError('Failure:' + e);
				setCallFailed(true);
				setIsLoading(false);
				setSearchDisabled(false);
				setUserOptions([]);
			});
	};

	const setUserSearchFocus = () => {
		let ddl = document.querySelector('#usesearch');
		ddl.querySelector('.react-select__input').children[0].focus();
	};

	return (
		<SearchBoxWrapper className="userfield-row nameandemail">
			{callFailed ? (
				<section className="error"> {failureError} </section>
			) : null}
			<section className="user-selectbox" role="listbox">
				<StyledSelectBox
					className={
						'select-group pull-left' + (searchDisabled ? ' inputDisabled' : '')
					}
					formLabel={labels.searchUsers}
					noOptionsMessage={() => labels.noResultsFound}
					defaultValue={defaultValue}
					options={userOptions}
					isSearchable
					searchInLabelOrValue={true}
					isClearable={false}
					id="usesearch"
					errorMessage={labels.seachInputRequired}
					showErrorMessage={showValidations}
					onLableClickSetFocus={true}
					onChange={(option) => {
						if (option == null) {
							setShowValidations(false);
							setDefaultValue('');
							setSearchDisabled(false);
						} else {
							let opt = {
								label: '',
								value: option.value,
								allProps: option.allProps
							};
							setIsUserOptionsOpen(false);
							setDefaultValue(opt.label);
							setSearchDisabled(true);
							setUserOptions([]);
							setSelectedUser(opt);
							if (props.onUserSelectionChange) {
								props.onUserSelectionChange(opt);
							}
						}
					}}
					isDisabled={searchDisabled}
					value={selectedUser}
					onInputKeyDown={keyPress}
					menuIsOpen={isUserOptionsOpen}
					formatOptionLabel={formatOptionLabel}
					onBlur={() => setIsUserOptionsOpen(false)}
				/>
				{!props.isHideClearAllIconButton && (
					<span
						title={labels.clearAllValues}
						className="pull-left clearall"
						onClick={() => {
							clearText();
						}}
					>
						<SVGICONS
							styleName="medium"
							name={'close'}
							hoverText={labels.clearAllValues}
							alt="Clear"
						/>
					</span>
				)}
				<section className="enterdetail-loading">
					{isLoading ? (
						<Loader show view="inlineView" styleName="nameemailloader" />
					) : null}
				</section>
			</section>
		</SearchBoxWrapper>
	);
}

export default UserSearch;

const SearchBoxWrapper = styled.section`
	width: 100%;
	display: inline-block;
	.error {
		color: red;
	}

	.user-selectbox {
		position: relative;
		display: inline-block;
		width: 100%;

		.react-select__indicators {
			display: none;
		}

		.react-select__menu-notice--no-options {
			justify-content: center;
			align-items: center;
			display: flex;
			height: 19rem;
			font-size: 1.4rem !important;
			color: ${(props) => props.theme.color[`grey400`]}!important;
		}

		.react-select__input {
			color: ${(props) => props.theme.color[`black`]};
		}

		.clearall {
			line-height: 4.5rem;
			margin: 0 1.5rem;

			svg {
				vertical-align: middle;
				fill: ${(props) => props.theme.color[`black`]};
			}
		}

		.enterdetail-loading {
			position: absolute;
			right: 50%;
			height: 4.5rem;

			.motif-loader-container.nameemailloader {
				padding-top: 1.6rem;
				height: auto !important;
			}
		}
	}
	.enterdetail-description {
		display: inline-block;
		width: 100%;
		font-size: 1.2rem;
		color: ${(props) => props.theme.color[`grey400`]};
	}
`;

const StyledSelectBox = styled(ReactSelect)`
	width: 45%;
	font-size: 1.4rem;
	color: ${(props) => props.theme.color[`grey400`]};

	label {
		font-size: 1.4rem;
	}

	.form-search-label {
		font-size: small;
	}

	&.inputDisabled > *,
	&.inputDisabled .react-select__single-value {
		color: ${lightColor[`grey400`]}!important;
	}

	&.floating-label.inputDisabled.hideInput label {
		color: ${lightColor[`grey400`]}!important;
	}
`;
