import * as types from '../actions/actiontypes';

import {ActionTypes} from '../util/enumerations';
import initialState from './initialstate';
import {object} from 'prop-types';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of notifications objects
 */
export default function notificationsReducer(
	state = initialState.notifications,
	action
) {
	switch (action.type) {
		case types.GET_NOTIFICATIONS:
			return action.response;
		case types.SET_CLOSE_NOTIFICATION:
			return Object.assign({}, state, {close: action.resource});
		case types.REMOVE_NOTIFICATION:
			return  {
				notificationdetails: [
					...state?.notificationdetails.filter((notification) => {
						if (
							notification.engagementId === action.engagementId &&
							notification.actionType === ActionTypes.DeleteRequest
						) {
							return;
						}
						return notification;
					})
				]
			};
		default:
			return state;
	}
}
