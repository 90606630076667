import * as types from '../actions/actiontypes';
import initialState from './initialstate';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export default function RequestAccessReducer(
	state = initialState.requestaccess,
	action
) {
	switch (action.type) {
		case types.POST_REQUEST_ACCESS:
			return {
				...state,
				data: action.payload
			};
		default:
			return state;
	}
}
