import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';
import {MotifButton} from '@ey-xd/motif-react';
import { Ellipse } from '@ey/canvascoreservices';
import {labels} from '../../../util/uiconstants';
import UserSearch from '../../Common/Search/UserSearch';
import Loader from '../../Common/Loaders/loadingindicator';
import Toast from '../../Common/Toast/Toast';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import TicketField from '../../Admin/common/ticketfield';

function AddUser(props) {
	const dispatch = useDispatch();

	const [refreshChildCompKey, setRefreshChildCompKey] = useState(0);
	const [showUserSeachValidations, setShowUserSearchValidations] = useState(0);
	const [isClearSearchText, setIsClearSearchText] = useState(true);
	const [isMultipleSearchUserAllowed, {}] = useState(true);
	const [isSearchDisable, setIsSearchDisable] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [addUserErrorMessage, setAddUserErrorMessage] = useState([]);
	const [currentRole, setCurrentRole] = useState();
	const [showRolesError, setShowRolesError] = useState(false);
	const [engagementRolesOptions, setEngagementRolesOptions] = useState([]);
	const showRolesDropDown = props?.showRolesDropDown ? true : false;
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	useEffect(() => {
		if (props?.engagementRoles?.length > 0) {
			let options = [];
			props?.engagementRoles.forEach((role) => {
				options.push({
					value: role.roleId,
					label: role.roleName
				});
			});
			setEngagementRolesOptions(options);
		}
	}, []);

	const emailChecker = (email) => {
		if (email.includes("'")) {
			email = email.replaceAll("'", "''");
		}
		return email;
	};

	//Send to textbox change event
	const onUserSelectionChange = (user) => {
		var tempSentToItems = selectedUsers;
		let userItem = user && user.allProps ? user.allProps : null;
		if (userItem) {
			let email = emailChecker(userItem.email);
			let userSelectedFilter = tempSentToItems.filter(
				(ee) =>
					ee.emailAddress.trim().toLowerCase() == email.trim().toLowerCase()
			);
			if (userSelectedFilter.length === 0) {
				tempSentToItems.push({
					id: userItem.objectId,
					displayName: userItem.displayName,
					emailAddress: email,
					firstName: userItem.firstName,
					lastName: userItem.lastName
				});
				if (showRolesDropDown) {
					tempSentToItems[tempSentToItems.length - 1]['role'] = {};
					setShowRolesError(false);
				}
			}
			if (!isMultipleSearchUserAllowed) {
				setIsSearchDisable(true);
			}
			//clear user search textbox
			setIsClearSearchText(true);
		}
		setSelectedUsers(tempSentToItems);
		setRefreshChildCompKey((prevState) => prevState + 1);
		setIsSubmitClicked(false);
	};

	//Send to textbox clear event
	const onUserClear = () => {
		if (!isMultipleSearchUserAllowed) {
			if (!selectedUsers) setIsSearchDisable(true);
		}
	};

	const removeSelection = (user) => {
		var updatedSelection = selectedUsers.filter((usr) => usr.id !== user.id);
		setSelectedUsers(updatedSelection);
	};

	useEffect(() => {
		setAddUserErrorMessage(props?.addUserErrorMessage);
	}, [props?.addUserErrorMessage]);

	const closeToast = (index) => {
		setAddUserErrorMessage([
			...addUserErrorMessage.slice(0, index),
			...addUserErrorMessage.slice(index + 1, addUserErrorMessage.length)
		]);
	};

	useEffect(() => {
		setCurrentRole();
	}, [currentRole]);

	const onSelectRole = (role, index) => {
		let users = selectedUsers;
		users[index].role = role;
		setSelectedUsers(users);
		setCurrentRole(role);
	};

	const saveUsers = () => {
		if(ticketNumber.trim().length > 0)
		{
			if (showRolesDropDown) {
				for (let user of selectedUsers) {
					if (user.role.value === undefined) {
						setShowRolesError(true);
						return;
					}
				}
			}
			props.onSaveUsersClick(selectedUsers, ticketNumber.trim());
		}
		else{
			setIsSubmitClicked(true);
		}
	};

	function handlerOnTicketEntered(e) {
		setTicketNumber(e.target.value);
	}

	return (
		<AddUserWrapper
			className={'adduser ' + (showRolesDropDown ? 'role' : 'withoutrole')}
		>
			<section
				className="toastMessage"
				style={{
					display:
						addUserErrorMessage && addUserErrorMessage?.length > 0
							? 'block'
							: 'none'
				}}
			>
				{addUserErrorMessage?.map((errorMessage, index) => (
					<Toast
						variant="error"
						key={index}
						onClose={() => {
							closeToast(index);
						}}
					>
						{errorMessage}
					</Toast>
				))}
			</section>
			{props?.addUserLoading ? (
				<Loader show fullscreen view="blockView" styleName="loadingstyle"/>
			) : (
				<section>
					<section className="userSearch customScrollbar">
						<section className="wrapper">
							<h5 className="headerText">{props.addUserDescription}</h5>
							<section className="adduserlist">
								<UserSearch
									key={refreshChildCompKey}
									showValidations={showUserSeachValidations}
									onUserClear={onUserClear}
									onUserSelectionChange={onUserSelectionChange}
									lookupType={props.userLookupType}
									enableClearText={isClearSearchText}
									enableUserSearch={isSearchDisable}
									isHideClearAllIconButton={true}
								/>
								{selectedUsers?.length > 0 ? (
									<>
									<section className="result">
										<h5>{`${labels.usersToBeAdded} (${selectedUsers.length})`}</h5>
										<section className="userlistrecord">
											{selectedUsers.map((user, index) => {
												return (
													<ul key={'selecteduser' + index} className="userlist">
														<li>
															<span className="nameemail">
																<Ellipse
																	content={
																		user.displayName +
																		' ' +
																		'(' +
																		user.emailAddress +
																		')'
																	}
																	tooltip={
																		user.displayName +
																		' ' +
																		'(' +
																		user.emailAddress +
																		')'
																	}
																	displayId={''}
																	noOfLines={1}
																	ellipsisClass=""
																/>
															</span>
															{showRolesDropDown && (
																<span className="role">
																	<SelectDropdown
																		formLabel={labels.engagementRoles}
																		value={
																			user.role.value === undefined
																				? ''
																				: user.role
																		}
																		options={engagementRolesOptions}
																		onChange={(role) =>
																			onSelectRole(role, index)
																		}
																		errorMessage={''}
																		showErrorMessage={
																			showRolesError &&
																			user.role.value === undefined
																		}
																	></SelectDropdown>
																</span>
															)}
															<span
																className="remove"
																onClick={() => {
																	removeSelection(user);
																}}
															>
																<SVGICONS
																	role="button"
																	styleName="medium"
																	name="close-outline"
																	hoverText="Remove user"
																/>
															</span>
														</li>
													</ul>
												);
											})}
										</section>
									</section>
									</>
								) : null}
								<TicketField
									ticketNumber={ticketNumber}
									onTicketChanged={handlerOnTicketEntered}
									showValidation={isSubmitClicked}
								/>
							</section>
						</section>
					</section>

					<section className="modal-footer">
						<MotifButton
							type="button"
							variant="primary-alt"
							disabled={(selectedUsers.length <= 0 || ticketNumber.trim().length <= 0) ? true : false }
							onClick={saveUsers}
						>
							{labels.add}
						</MotifButton>
						<MotifButton
							type="button"
							variant="secondary"
							onClick={() => {
								props.onNewUserModalClose(false);
							}}
						>
							{labels.cancel}
						</MotifButton>
					</section>
				</section>
			)}
		</AddUserWrapper>
	);
}

const AddUserWrapper = styled.section`

	.loadingstyle {
		height: calc(100vh - 52rem) !important;
	}

	&.withoutrole {
		.nameemail {
			width: 90% !important;
		}
		
		.userlistrecord{
			max-height: 22rem;
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: 0.6rem;
			}
		
			&::-webkit-scrollbar-track {
				background: #E7E7EA;
				width: 0.6rem;
			}
		
			&::-webkit-scrollbar-thumb {
				background: #c4c4cd;
				width: 0.6rem;
				cursor: pointer;
			}
		
			&::-webkit-scrollbar-thumb:hover {
				background: #747480;
			}
		}
	}

	&.role{
		.nameemail{
			line-height: 5rem;
		}

		.remove{
			line-height: 4.5rem;
		}
	}

	.toastMessage {
		.motif-toast-text-container {
			font-size: 1.2rem;
		}
	}

	.modal-footer {
		padding: 2rem;
	}

	.motif-loader {
		height: 48.5rem !important;
	}

	.userSearch {
		display: flex;
		width: 100%;
		font-size: 1.4rem;
		padding-bottom: 2rem;
		min-height: 27rem;
		max-height: calc(100vh - 26rem);

		.adduserlist {
			width: 100%;
			display: block;
		}

		.react-select__menu-list {
			height: auto;
			max-height: 19rem;

			.react-select__option {
				section {
					section:first-child {
						font-size: 1.4rem !important;
						line-height: 2.2rem;
					}
					section:last-child {
						font-size: 1.5rem !important;
						line-height: 2.2rem;
					}
				}
			}
		}

		.wrapper {
			padding: 1.6rem 2rem;
			display: inline-block;
			width: 100%;

			.headerText {
				font-size: 1.4rem;
				margin: 0;
				padding: 0 0 1rem 0;
			}

			//ticketstart
			.ticketdetail{
				margin: 0 0 8rem 0;
				h5{
					font-size: 1.4rem;
					font-weight: normal;
					color: ${(props) => props.theme.color[`grey500`]};
					border: 0!important;
				}

				.motif-form-field{
					width: 29rem;
				}

				.motif-form-field:not(.motif-form-field-has-value),
				.motif-form-field:not(.motif-form-field-focused){
					.motif-label{
						font-size: 1.4rem;
						font-weight:normal;
						margin-top: 0.6rem;
						color: ${(props) => props.theme.color[`black`]};
					}
				}
		
				.motif-form-field-has-value,
				.motif-form-field-focused{
					.motif-label{
						font-size: 1.2rem!important;
						font-weight: normal!important;
						margin-top: 0.2rem!important;
						 color: ${(props) => props.theme.color[`black`]};
					}
				}
				
				
				.motif-input {
					font-size: 1.4rem;
					line-height: 3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					color: ${(props) => props.theme.color[`black`]};
					&::placeholder {
						font-size: 1.4rem;
					}
				}
			}
			//ticketend

			.result {
				padding: 0 0 1rem 0;
				width: 100%;
				display: inline-block;

				h5 {
					font-size: 1.6rem;
					padding-bottom: 1rem;
					margin-bottom: 0.5rem;
					border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
				}

				.userlist{ 
					width: 100%;     
					display: flex;
					border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
				}

				li {
					width: 100%;
					display: block;
					margin-bottom: 0.2rem;
					list-style-type: none;

					.nameemail {
						width: 66%;
						display: inline-block;
						line-height: 4rem;

						.ellipses {
							width: 95%;
						}
					}

					.role {
						width: 26%;
						display: inline-block;
						vertical-align: top;
						position: relative;

						.react-select-container .react-select__control{
							min-height: auto;
						}

						.react-select__single-value{
							margin-left: 0.1rem;
						}

						.show {
							font-size: 1.2rem;
							color: ${(props) => props.theme.color[`red600`]};
							display: inline !important;
							position: absolute;
							left: -2rem;
							top: 0.6rem;
							svg {
								width: 1.5rem;
								height: 1.5rem;
								margin-right: 0.2rem;
								vertical-align: text-bottom;
								fill: ${(props) => props.theme.color[`red600`]};
							}
						}

						.react-select-container .react-select__menu {
							height: 12rem;
							display: block;
							margin-bottom: 2rem;
							padding-bottom: 2rem;
							.react-select__menu-list {
								height: 12rem;
								margin-bottom: 2rem;
								padding-bottom: 2rem;
							}
						}
					}

					.remove {
						padding: 0 1.5rem;
						text-align: right;
						display: inline-block;
						line-height: 4rem;
						width: 8%;
						svg {
							cursor: pointer;
							vertical-align: middle;
						}
					}
				}
			}
		}

		.user-selectbox .select-group {
			width: 100%;
		}
	}
	.modal-footer {
		display: inline-flex;
		width: 100%;

		button {
			font-size: 1.4rem;
			padding: 1.2rem 1.5rem;
			margin-right: 1rem;
			line-height: normal;
		}
	}
`;

export default AddUser;
