import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from "styled-components";
import {deleteAdminUserRole} from '../../../../../actions/admin/manageaccessactions';
import TicketField from '../../../common/ticketfield';
import env from '../../../../../util/env';
import Loader from '../../../../Common/Loaders/loadingindicator';
import {labels} from '../../../../../util/uiconstants';
import {MotifButton} from '@ey-xd/motif-react';
import {getLocalizedAPIError} from '../../../common/util';
import Toast from '../../../../Common/Toast/Toast';

function DeleteRole(props) {
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ticketNumber, setTicketNumber] = useState('');
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const dispatch = useDispatch();

    const onTicketChanged = (e) => {
        setTicketNumber(e.target.value);
    }

    const onConfirmDeleteClick = () => {
        setIsSubmitClicked(true);
        if (ticketNumber.trim().length <= 0) {
            return;
        }

        const roleToDelete = props.roleToDelete;
        setIsLoading(true);
        let userId = location.pathname.split('/').at(-1);
        let deleteRoleUrl = '';
        let uriTicketNumber = encodeURIComponent(ticketNumber.trim());
        if (roleToDelete.countryIds && roleToDelete.countryIds.length && roleToDelete.subServiceLineId) {
            deleteRoleUrl =
                env.getURL('serviceUrlV2') +
                'admin/Users/' +
                userId +
                '/roles/' +
                roleToDelete.rbacRoleId +
                '/subservicelines/' +
                roleToDelete.subServiceLineId +
                '?ticketnumber=' +
                uriTicketNumber;
        } else {
            deleteRoleUrl =
                env.getURL('serviceUrlV2') +
                'admin/Users/' +
                userId +
                '/roles/' +
                roleToDelete.rbacRoleId +
                '?ticketnumber=' +
                uriTicketNumber;
        }

        dispatch(deleteAdminUserRole(deleteRoleUrl))
            .then(() => {
                props.onModalClose(true);
                setIsSubmitClicked(false);
            })
            .catch((e) => {
                setErrorMessage(getLocalizedAPIError(e));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <DeleteRoleWrapper>
            <section
                className="toastMessage"
                style={{
                    display:
                        errorMessage && errorMessage.length > 0
                            ? 'block'
                            : 'none'
                }}
            >
                <Toast
                    variant="error"
                    onClose={() => setErrorMessage(false)}
                >
                    {errorMessage}
                </Toast>
            </section>
            {isLoading ? (<section className="delete-role-loader">
                <Loader show view="blockView" styleName="loadingstyle" /></section>
            ): (
                <section>
                    <section>
                        <section className="title" aria-label="">
                            <h5>{props.roleToDelete.moduleName + ' ' + props.roleToDelete.roleName}</h5>
                        </section>
                    </section>
                    <TicketField
                        ticketNumber={ticketNumber}
                        onTicketChanged={onTicketChanged}
                        showValidation={isSubmitClicked}
                    />
                    <section className="modal-footer">
                        <MotifButton
                            type="button"
                            variant="primary-alt"
                            disabled={ticketNumber.trim().length <= 0}
                            onClick={onConfirmDeleteClick}
                        >
                            {labels.manageAccessDeleteRoleButton}
                        </MotifButton>
                        <MotifButton
                            type="button"
                            variant="secondary"
                            onClick={() => {
                                props.onModalClose(false);
                            }}
                        >
                            {labels.cancel}
                        </MotifButton>
                    </section>
                </section>
            )}
        </DeleteRoleWrapper>
    );
}

const DeleteRoleWrapper = styled.section`
.title h5{
    font-size: 1.4rem;
    font-weight: normal;
    color: ${(props) => props.theme.color[`grey500`]};
    margin: 2rem 0 2rem 2rem;
}
.delete-role-loader{
    padding: 2rem !important;
    .loadingstyle {
        height: calc(100vh - 72rem) !important;
    }
}
//ticketstart
		.ticketdetail{
			margin: 0 2rem 2rem 2rem;
			padding-bottom: 2rem;
			h5{
				font-size: 1.4rem;
				font-weight: normal;
				color: ${(props) => props.theme.color[`grey500`]};
				border: 0!important;
			}

			.motif-form-field{
				width: 29rem;
			}

			.motif-form-field:not(.motif-form-field-has-value),
			.motif-form-field:not(.motif-form-field-focused){
				.motif-label{
					font-size: 1.4rem;
					font-weight:normal;
					margin-top: 0.6rem;
					color: ${(props) => props.theme.color[`black`]};
				}
			}

			.motif-form-field-has-value,
			.motif-form-field-focused{
				.motif-label{
					font-size: 1.2rem!important;
					font-weight: normal!important;
					margin-top: 0.2rem!important;
					color: ${(props) => props.theme.color[`black`]};
				}
			}
			
			
			.motif-input {
				font-size: 1.4rem;
				line-height: 3rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				color: ${(props) => props.theme.color[`black`]};
				&::placeholder {
					font-size: 1.4rem;
				}
			}
		}
		//ticketend
	.modal-footer {
		padding: 2rem;

		.motif-toast-close-button,
		.motif-toast-close-button:focus {
			outline: 0 !important;

			* {
				outline: 0 !important;
			}

			svg {
				outline: 0 !important;
			}

			*:focus {
				outline: 0 !important;
			}

			svg:focus {
				outline: 0 !important;
			}
		}
	}

	.motif-loader {
		height: 18rem !important;
	}

    .modal-footer {
		display: inline-flex;
		width: 100%;

		button {
			font-size: 1.4rem;
			padding: 1.2rem 1.5rem;
			margin-right: 1rem;
			line-height: normal;
		}
	}
`;


export default DeleteRole;