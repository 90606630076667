import {auditPhaseEnum, labels} from '../../../util/uiconstants';

import {MotifProgressBar} from '@ey-xd/motif-react';
import PropTypes from 'prop-types';
import React from 'react';

const EngagementProgressStatus = ({engagementId, engagementOverview}) => {
	const getProgressLabel = (value, auditPhaseId) => {
		let auditPhaseLabel;

		switch (auditPhaseId) {
			case auditPhaseEnum.scopeAndStrategy:
				auditPhaseLabel = labels.engagementProgressStrategyPhaseLabel;
				break;
			case auditPhaseEnum.execution:
				auditPhaseLabel = labels.engagementProgressExecutionPhaseLabel;
				break;
			case auditPhaseEnum.conclusion:
				auditPhaseLabel = labels.engagementProgressConclusionPhaseLabel;
				break;
			default:
				break;
		}
		return (
			<section className="engprogress-available">
				<span className="engprogress-availabletxt">{auditPhaseLabel}</span>
				{value < 100 ? (
					<span>
						<span className="engprogress-value">{`${value}%`}</span>
						<span className="engprogress-completion">
							{labels.engagementProgressCompletionPercentage}
						</span>
					</span>
				) : (
					<span className="engprogress-complete">
						{labels.engagementProgressCompleted}
					</span>
				)}
			</section>
		);
	};

	return (
		<>
			{engagementOverview === undefined ? (
				<>
					<section className="engprogress-unavailable">
						<span className="engprogress-unavailabletxt">
							{labels.engagementProgressNotAvailable}
						</span>
					</section>
				</>
			) : (
				<>
					{engagementOverview.map((overview) => (
						<MotifProgressBar
							key={`engId${engagementId}_auditPhase${overview.auditPhaseID}`}
							value={overview.completionPercentage}
							className="engagement-progress"
							labelComponent={getProgressLabel(
								overview.completionPercentage,
								overview.auditPhaseID
							)}
						/>
					))}
				</>
			)}
		</>
	);
};

EngagementProgressStatus.propTypes = {
	engagementId: PropTypes.number,
	engagementOverview: PropTypes.shape({
		engagementId: PropTypes.number,
		helixProjects: PropTypes.arrayOf({
			projectId: PropTypes.string,
			projectName: PropTypes.string
		}),
		independenceStatusId: PropTypes.number,
		isProfileComplete: PropTypes.bool,
		engagementStatusId: PropTypes.number,
		engagementUserStatusId: PropTypes.number,
		alerts: PropTypes.arrayOf({
			category: PropTypes.string,
			hasPartnerInvited: PropTypes.bool,
			hasEQRInvited: PropTypes.bool,
			hasValidEngagementCode: PropTypes.bool,
			overdueArchiveDeadline: PropTypes.number,
			upcomingArchiveDeadline: PropTypes.number,
			hasContentDeliveryInProgress: PropTypes.bool,
			overdueMilestones: PropTypes.number,
			overduePartnerTasks: PropTypes.number,
			overdueEQRTasks: PropTypes.number
		}),
		metrics: PropTypes.arrayOf({
			category: PropTypes.string,
			age: PropTypes.number,
			dates: PropTypes.string,
			reviewNotesAssigned: PropTypes.number,
			reviewNotesAuthored: PropTypes.number,
			forMyReview: PropTypes.number,
			upcomingReview: PropTypes.number,
			clientRequests: PropTypes.number,
			groupTasks: PropTypes.number,
			groupDeliverables: PropTypes.number,
			timephases: PropTypes.number,
			automationDocuments: PropTypes.number,
			eqrTasks: PropTypes.number,
			partnerTasks: PropTypes.number
		}),
		tasksProgress: PropTypes.arrayOf({
			auditPhaseId: PropTypes.number,
			completionPercentage: PropTypes.number
		})
	})
};

export default EngagementProgressStatus;
