/**
 * Created by calhosh on 4/14/2017.
 */

import * as Arabic from './ar-ms';
import * as English from './en-us';
import * as SpanishMexican from './es-mx';
import * as FrenchCanadian from './fr-ca';
import * as Hebrew from './he';
import * as Japanese from './ja-jp';
import * as Korean from './ko';
import * as PortugueseBrazilian from './pt-br';
import * as Russian from './ru';
import * as ChineseSimplified from './zh-sp';
import * as ChineseTraditional from './zh-tr';

const languages = {
	1: English,
	4: FrenchCanadian,
	5: SpanishMexican,
	7: PortugueseBrazilian,
	8: Japanese,
	9: ChineseSimplified,
	10: ChineseTraditional,
	11: Hebrew,
	12: Korean,
	13: Russian,
	14: Arabic
};

export default function(languageId) {
	return Object.assign({}, languages[1], languages[languageId]);
}
