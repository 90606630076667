/**
 * @module <%=name%>
 * Action creator for <%=name%> object
 */

import * as types from './actiontypes'; //TODO: uncomment

export function markUnmarkFavorite(url, resource) {
	return {
		type: types.PATCH_ENGUSER,
		meta: {
			api: {
				url,
				method: 'PATCH',
				data: resource
			}
		}
	};
}

export function patchCallAPI(url, resource) {
	return {
		type: types.PATCH_ENGUSER,
		meta: {
			api: {
				url,
				method: 'PATCH',
				data: resource
			}
		}
	};
}
