import initialState from './initialstate';
import * as types from '../actions/actiontypes';

export default function modalStateReducer(
	state = initialState.isModalOpen,
	action
) {
	switch (action.type) {
		case types.SET_IS_MODAL_OPEN: {
			return action.isModalOpen;
		}
		default:
			return state;
	}
}
