import initialstate from '../../initialstate';
import * as types from '../../../actions/actiontypes';
import {currentResource} from '../../../util/uiconstants';

export function adminEngagementDocumentsReducer(state = initialstate.adminEngagementDocumentsList, action) {
    switch (action.type) {
        case types.GET_ADMIN_ENGAGEMENT_DOCUMENTS_LIST:
            return action.response;

        case types.RESET_ADMIN_ENGAGEMENT_DOCUMENTS_LIST:
            return initialstate.adminEngagementDocumentsList;

        default:
            return state;
    }
}