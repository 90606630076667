import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	pagingOptions,
	labels,
	dateFormatsListItems,
	messagingDateFormat,
	dateFormats,
	successMessageDisplayDuration,
	currentResource
} from '../../../util/uiconstants';
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../../Common/Loaders/loadingindicator';
import {
	MotifTable,
	MotifButton,
	MotifTooltip,
	MotifToast,
	MotifSearch,
	MotifFormField
} from '@ey-xd/motif-react';
import Pagination from '@ey/pager';
import env from '../../../util/env';
import {
	deleteMessage,
	getMessages,
	saveMessage,
	resetMessageDetails,
	editMessage
} from '../../../actions/admin/messagingactions';
import EllipsesControl from '@ey/ellipses-control';
import AccessDeniedMessage from '../../Common/AccessDeniedMessage/AccessDeniedMessage';
import ModalControl from '../../Common/Modal/Modal';
import CreateEditMessage from './createeditmessagemodal';
import DeleteMessage from './deletemessage';
import { SVGICONS } from '@ey/icons';
import Toast from '../../Common/Toast/Toast';
import { getLocalizedAPIError } from '../common/util';
import KendoWindow from '../../Common/KendoWindow/kendowindow';

function Messaging() {
	const messaging = useSelector((store) => store.messaging);
	const userPermissions = useSelector((store) => store.userPermissions);
	const dispatch = useDispatch();
	const history = useHistory();

	const [isEditMessage, setIsEditMessage] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(messaging?.pages);
	const [pageSize, setPageSize] = useState(20);
	const [searchInputValue, setSearchInputValue] = useState('');
	const [showCreateEditMessageModal, setShowCreateEditMessageModal] = useState(false);
	const [showDeleteMessageModal, setShowDeleteMessageModal] = useState(false);
	const [messageToDelete, setMessageToDelete] = useState(false);
	const [successMessage, setSuccessMessage] = useState(false);

	const duration = successMessageDisplayDuration;
	const [createEditMessageLoading, setCreateEditMessageLoading] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [createEditErrorMessage, setCreateEditErrorMessage] = useState('');
	const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [messageId, setMessageId] = useState(0);

	useEffect(() => {
		loadMessages(searchInputValue);
	}, [page, pageSize]);

	useEffect(() => {
		if (messaging) {
			setTotalPages(messaging?.pages);
		}
	}, [messaging]);

	useEffect(() => {
		if (history.location && history.location.hash.toLowerCase() == '#editmessage') {
			var queryParams = new URLSearchParams(history.location.search);
			if (queryParams.has('messageid')) {
				setMessageId(parseInt(queryParams.get('messageid')));
				setIsEditMessage(true);
				setShowCreateEditMessageModal(true);
			}
		}
	}, [history.location]);

	useEffect(() => {
		if (successMessage) {
			var timer = setTimeout(() => {
				setSuccessMessage('');
			}, duration);

			return () => clearTimeout(timer);
		}
	}, [successMessage]);

	const loadMessages = (searchText) => {
		setIsLoading(true);
		let messageUrl = env.getURL('serviceUrlV2') + `admin/Messages?page=${page}&pageSize=${pageSize}`;

		if (searchText) {
			messageUrl += `&searchString=${searchText}`;
		}

		dispatch(getMessages(messageUrl))
			.then(() => {
				setIsLoading(false);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const openCreateMessageModal = () => {
		history.replace({
			pathname: history.location.pathname,
			hash: 'createmessage'
		});

		setShowCreateEditMessageModal(!showCreateEditMessageModal);
	};

	const saveMessageAPICall = (payload) => {
		setCreateEditMessageLoading(true);
		setButtonDisable(true);
		let saveMessageUrl = env.getURL('serviceUrlV2') + `admin/messages`;
		dispatch(saveMessage(saveMessageUrl, payload))
			.then(() => {
				setCreateEditMessageLoading(false);
				setButtonDisable(false);
				setSuccessMessage(labels.createMessageSuccess);
				closeCreateEditMessageModal(true);
			})
			.catch((error) => {
				setCreateEditErrorMessage(getLocalizedAPIError(error));
				setIsLoading(false);
			})
			.finally(() => {
				setCreateEditMessageLoading(false);
				setButtonDisable(false);
			})
	}

	const editMessageAPICall = (payload) => {
		setCreateEditMessageLoading(true);
		setButtonDisable(true);
		let editMessageUrl = env.getURL('serviceUrlV2') + `admin/messages/${messageId}`;
		dispatch(editMessage(editMessageUrl, payload))
			.then(() => {
				setCreateEditMessageLoading(false);
				setButtonDisable(false);
				setSuccessMessage(labels.editMessageSuccess);
				closeCreateEditMessageModal(true);
			})
			.catch((error) => {
				setCreateEditErrorMessage(getLocalizedAPIError(error));
				setIsLoading(false);
			})
			.finally(() => {
				setCreateEditMessageLoading(false);
				setButtonDisable(false);
			})
	}

	const onDeleteConfirmClick = (enteredTicketNumber) => {
		if (enteredTicketNumber?.length > 0) {
			setIsDeleteLoading(true);
			let deleteMessageUrl =
				env.getURL('serviceUrlV2') +
				'admin/messages/' +
				messageToDelete?.appAdminMessageId +
				'?ticketnumber=' +  encodeURIComponent(enteredTicketNumber);
			dispatch(deleteMessage(deleteMessageUrl))
				.then(() => {
					setShowDeleteMessageModal(false);
					setSuccessMessage(labels.messagingModalDeletedSuccessfully);
					loadMessages();
				})
				.catch((error) => {
					let e = getLocalizedAPIError(error);
					setDeleteErrorMessage(e);
				})
				.finally(() => {
					setIsDeleteLoading(false);
				});
		}
	};

	const closeCreateEditMessageModal = (shallRefresh) => {
		setShowCreateEditMessageModal(false);
		setCreateEditErrorMessage('');
		setMessageId(0);
		setIsEditMessage(false);
		let path = history.location.pathname;
		if (history.location.hash && history.location.hash.length > 0) {
			history.push(path);
		}
		if (shallRefresh) {
			loadMessages(searchInputValue);
		}
		dispatch(resetMessageDetails());
	};

	const columnDefs = [
		{
			flex: 3,
			minWidth: 0,
			headerName: labels.messagingMessageTitle,
			field: 'messagetitle',
			cellRenderer: 'messageTitleRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			flex: 2,
			minWidth: 0,
			headerName: labels.messagingStartsOn,
			field: 'startson',
			cellRenderer: 'startsOnRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			flex: 2,
			minWidth: 0,
			headerName: labels.messagingEndsOn,
			field: 'endson',
			cellRenderer: 'endsOnRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			flex: 2,
			minWidth: 0,
			headerName: labels.messagingAffectedDataCenters,
			field: 'affecteddatacenters',
			cellRenderer: 'affectedDataCentersRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			flex: 1,
			minWidth: 0,
			headerName: labels.messagingStatus,
			field: 'status',
			cellRenderer: 'statusRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			flex: 1.5,
			minWidth: 0,
			headerName: labels.messagingCreatedBy,
			field: 'createdby',
			cellRenderer: 'createdByRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			flex: 1.5,
			minWidth: 0,
			headerName: labels.messagingCreatedDate,
			field: 'createddate',
			cellRenderer: 'createdDateRender',
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			flex: 1.2,
			minWidth: 0,
			headerName: '',
			field: 'actions',
			cellRenderer: 'actionsRenderer',
			lockPosition: true,
			suppressSizeToFit: true
		}
	];

	const ellipsesControlRender = (text) => {
		return (
			<section className="roleType">
				<EllipsesControl
					content={text}
					tooltip={text}
					displayId={''}
					noOfLines={1}
					ellipsisClass="columnName"
				/>
			</section>
		);
	};

	const messageTitleRender = (e) => {
		return <>
			<section className="roleType">
				<a
					variant="primary-alt"
					name="invite"
					value="back"
					// onClick={}
					className=""
				>
					{ellipsesControlRender(e.data.messageTitle)}
				</a>
			</section></>
	};

	const startsOnRender = (e) => {
		let startsOnDateTime = e.data.releaseOnDate ? moment.utc(e.data.releaseOnDate).format(messagingDateFormat) : '';
		return <>{ellipsesControlRender(startsOnDateTime)}</>;
	};

	const endsOnRender = (e) => {
		let endsOnDateTime = e.data.expireOnDate ? moment.utc(e.data.expireOnDate).format(messagingDateFormat) : '';
		return <>{ellipsesControlRender(endsOnDateTime)}</>;
	};

	const affectedDataCentersRender = (e) => {
		let affectedDataCenter = '';
		if (e.data.isSystemWide) {
			affectedDataCenter = labels.messagingDataCenterSystemWide;
		}
		else if (e.data.dataCenterList.length == 1) {
			affectedDataCenter = e.data.dataCenterList[0].dataCenterName;
		}
		else if (e.data.dataCenterList.length > 1) {
			affectedDataCenter = Array.prototype.map.call(e.data.dataCenterList, function (item) {
				return item.dataCenterName;
			}).join(", ");
		}

		return <>{ellipsesControlRender(affectedDataCenter)}</>;
	};

	const statusRender = (e) => {
		let statusrender = <>{currentResource.messageStatus[e.data.statusId]}</>;
		return <>{ellipsesControlRender(statusrender)}</>;
	};

	const createdByRender = (e) => {
		return <>{ellipsesControlRender(e.data.createUser)}</>;
	};

	const createdDateRender = (e) => {
		let createDate = e.data.createDate
			? moment
				.utc(e.data.createDate)
				.format(dateFormatsListItems[dateFormats].label)
			: '';
		return <>{ellipsesControlRender(createDate)}</>;
	};

	const actionsRenderer = (e) => {
		return <section className="iconbutton">
			<section className="editicon-button">
				<MotifTooltip
					flip
					id={'edit'}
					placement={'top'}
					contentClassName="editicon"
					hide={userPermissions?.permissions?.canCreateUpdateMessaging}
					trigger={
						<section>
							<button
								role="button"
								aria-label="edit-icon"
								disabled={!userPermissions?.permissions?.canCreateUpdateMessaging}
								onClick={() => {
									history.replace({
										pathname: history.location.pathname,
										search: history.location.search.toLowerCase() + '?messageid=' + e.data.appAdminMessageId,
										hash: 'editmessage'
									});

									onEditClick(e.data);
								}}
								className="edit-user-edit-btn"
							>
								<SVGICONS
									role="button"
									styleName="medium editicon"
									name="edit"
									hoverText={labels.editHoverText}
									onIconClick={() => {
										onEditClick(e.data);
									}}
								/>
							</button>
						</section>
					}
				>
					<MotifToast variant="error">
						{labels.insufficientPermission}
					</MotifToast>
				</MotifTooltip>

			</section>
			<section className="deleteicon-button">
				<MotifTooltip
					flip
					id={'editbButtonTooltipId'}
					placement={'top'}
					contentClassName="deleteicon"
					hide={userPermissions?.permissions?.canDeleteMessaging}
					trigger={
						<section>
							<button
								role="button"
								aria-label="delete-icon"
								disabled={!userPermissions?.permissions?.canDeleteMessaging}
								onClick={() => {
									onDeleteMessageClick(e.data);
								}}
								className="delete-user-delete-btn"
							>
								<SVGICONS
									role="button"
									styleName="medium deleteicon"
									name="delete"
									hoverText={labels.messagingModalDeleteButton}
									onIconClick={() => {
										onDeleteMessageClick(e.data);
									}}
								/>
							</button>
						</section>
					}
				>
					<MotifToast variant="error">
						{labels.insufficientPermission}
					</MotifToast>
				</MotifTooltip>
			</section>
		</section>
	}

	const onDeleteMessageClick = (data) => {
		setShowDeleteMessageModal(true);
		setMessageToDelete(data);
	}

	const onEditClick = (data) => {
		setMessageId(data.appAdminMessageId);
		setIsEditMessage(true);
		setShowCreateEditMessageModal(!showCreateEditMessageModal);
	}

	const onSearchChange = () => {
		if (searchInputValue != undefined) {
			setPage(1);
			loadMessages(searchInputValue);
		}
	};

	const onClearClick = () => {
		setSearchInputValue('');
		loadMessages('');
	};

	const handlerOptionPage = (value) => {
		if (value !== pageSize) {
			setPageSize(value);
			setPage(1);
		}
	};

	const paginationHandler = (value) => {
		if (page !== value) {
			setPage(value);
		}
	};

	function hasAdminMessagePermissions() {
		let messagingPermission = userPermissions?.permissions;
		return (
			messagingPermission &&
			(messagingPermission.canReadMessaging ||
				messagingPermission.canCreateUpdateMessaging ||
				messagingPermission.canDeleteMessaging)
		);
	}

	return (
		<Mainwrapper>
			{hasAdminMessagePermissions() ? (
				<section>
					<section className="messagingmanagement mainResponsiveWrapper">
						<section
							className="toastMessage"
							style={{
								display: successMessage.length > 0 ? 'block' : 'none'
							}}
						>
							<Toast
								variant="success"
								duration={duration}
								onClose={() => setSuccessMessage('')}
							>
								{successMessage}
							</Toast>
						</section>
						<section className="add-on">
							<section className="search">
								<MotifFormField>
									<MotifSearch
										placeholder={labels.searchUserText}
										value={searchInputValue}
										onChange={(e) => setSearchInputValue(e.target.value)}
										onSearchButtonClick={onSearchChange}
										onClear={onClearClick}
										onEnter={onSearchChange}
										clearHoverText={labels.clear}
									/>
								</MotifFormField>
							</section>
							<MotifTooltip
								placement={'bottom'}
								hide={userPermissions?.permissions?.canCreateUpdateMessaging}
								trigger={
									<section className="createmessage">
										<MotifButton
											aria-label="Create message"
											role="button"
											type="button"
											variant="primary-alt"
											disabled={!userPermissions?.permissions?.canCreateUpdateMessaging}
											onClick={() => {
												openCreateMessageModal();
											}}
										>
											{labels.createMessage}
										</MotifButton>
									</section>
								}>
								{labels.createMessagePermissions}
							</MotifTooltip>

						</section>
					</section>

					{isLoading ? (
						<Loader show view="blockView" styleName="loadingstyle" />
					) : messaging?.totalEntityCount > 0 ? (
						<section className="messagingmanagementTable mainResponsiveWrapper">
							<MotifTable
								defaultColDef={{
									flex: 1,
									sortable: false,
									filter: false,
									resizable: false,
									minWidth: 180,
									lockPosition: true
								}}
								enableCellTextSelection
								suppressRowClickSelection
								suppressCellSelection
								columnDefs={columnDefs}
								rowSelection="multiple"
								rowData={messaging?.data}
								rowBuffer={200}
								onGridReady={(params) => {
									params.api.sizeColumnsToFit();
								}}
								frameworkComponents={{
									messageTitleRender: messageTitleRender,
									startsOnRender: startsOnRender,
									endsOnRender: endsOnRender,
									affectedDataCentersRender: affectedDataCentersRender,
									statusRender: statusRender,
									createdByRender: createdByRender,
									createdDateRender: createdDateRender,
									actionsRenderer: actionsRenderer
								}}
							/>
						</section>
					) : (
						<section className="norecord">{labels.noResultsFound}</section>
					)}

					<section className="pagerholder">
						<Pagination
							styleName="messaging-paging mainResponsiveWrapper"
							options_per_page={pagingOptions.options}
							default_dropdown_value={pageSize}
							currentPage={page}
							TotalPages={totalPages}
							dropup
							onInputChange={(e) => {
								let inputValue = Number.parseInt(e.target.value);
								if (inputValue != page && inputValue > 0) {
									paginationHandler(inputValue);
								}
							}}
							onDropdownChange={(e) => {
								handlerOptionPage(e.value);
							}}
							onFirstPageClick={() => {
								paginationHandler(1);
							}}
							onPrevPageClick={() => {
								paginationHandler(page - 1);
							}}
							onNextPageClick={() => {
								paginationHandler(page + 1);
							}}
							onLastPageClick={() => {
								paginationHandler(totalPages);
							}}
							dropdown_position="left"
						/>
					</section>
					<section>
						{showCreateEditMessageModal === true && (
							<section>
							<KendoWindow
								title={isEditMessage ? labels.editMessage : labels.createMessage}
								onClose={() => closeCreateEditMessageModal(false)}
								isOkButtonDisabled={false}
								isCancelButtonDisabled={false}
								className="kendowrapper"
								initialWidth={925}
								initialHeight={788}
							>
								<CreateEditMessage
									isEditMessage={isEditMessage}
									messageId={messageId}
									onCreateEditMessageModalClose={closeCreateEditMessageModal}
									onSaveMessageClick={saveMessageAPICall}
									onEditMessageAPICall={editMessageAPICall}
									createEditMessageLoading={createEditMessageLoading}
									buttonDisable={buttonDisable}
									setCreateEditErrorMessage={setCreateEditErrorMessage}
									createEditErrorMessage={createEditErrorMessage}
								/>

							</KendoWindow>
							</section>
						)}    
					</section>

					{/* <ModalControl
						className="createmessage-modal"
						title={isEditMessage ? labels.editMessage : labels.createMessage}
						onOkClick={() => { }}
						onHide={() => closeCreateEditMessageModal(false)}
						isOkButtonDisabled={false}
						show={showCreateEditMessageModal}
						modalsize="x-medium"
						showButtons={false}
						closeTitle={labels.close}
						role="dialog"
					>
						<CreateEditMessage
							isEditMessage={isEditMessage}
							messageId={messageId}
							onCreateEditMessageModalClose={closeCreateEditMessageModal}
							onSaveMessageClick={saveMessageAPICall}
							onEditMessageAPICall={editMessageAPICall}
							createEditMessageLoading={createEditMessageLoading}
							buttonDisable={buttonDisable}
							setCreateEditErrorMessage={setCreateEditErrorMessage}
							createEditErrorMessage={createEditErrorMessage}
						/>
					</ModalControl> */}

					<section className="">
						<ModalControl
							className=""
							title={labels.messagingModalDeleteTitle}
							onOkClick={() => { }}
							onHide={() => { setShowDeleteMessageModal(false); }}
							isOkButtonDisabled={true}
							show={showDeleteMessageModal}
							modalsize="message"
							showButtons={false}
							closeTitle={labels.close}
							modalContainerClass=""
							role="dialog"
						>
							<DeleteMessage
								messageToDelete={messageToDelete}
								onDeleteClick={onDeleteConfirmClick}
								onCancelClick={() => { setShowDeleteMessageModal(false); }}
								clearErrorMessage={() => { setDeleteErrorMessage(''); }}
								errorMessage={deleteErrorMessage}
								isLoading={isDeleteLoading}
							/>
						</ModalControl>
					</section>
				</section>
			) : (
				<AccessDeniedMessage permissionMesage={labels.insufficientPermission} />
			)}
		</Mainwrapper>
	);
}

const Mainwrapper = styled.section`
	overflow: hidden;
	margin-top: 5rem;

	.iconbutton{
		width: 100%;
		display: inline;
		text-align: right;
	}

	.editicon-button{
		display: inline-block;
		button.edit-user-edit-btn{
			border: none;
			background: transparent;
			cursor: pointer!important;
			outline: none;
			transition: 0.2s ease-in-out opacity;

			svg{
				fill: ${(props) => props.theme.color[`black`]};
			}

			&[disabled] {
				opacity: 0.2;
				pointer-events: none;
			}
		}

		.editicon{
			margin: 0 0 0 1rem;
			cursor: pointer;
		}
	}
	
	.deleteicon-button{
		display: inline-block;
		button.delete-user-delete-btn{
			border: none;
			background: transparent;
			cursor: pointer!important;
			outline: none;
			transition: 0.2s ease-in-out opacity;

			svg{
				fill: ${(props) => props.theme.color[`black`]};
			}

			&[disabled] {
				opacity: 0.2;
				pointer-events: none;
			}
		}

		.deleteicon{
			margin: 0 0 0 1rem;
			cursor: pointer;
		}	
	}	

	.loadingstyle {
		height: calc(100vh - 25rem) !important;
	}
	
	//heading
	.messagingemanagement {
		.toastMessage {
			.motif-toast-text-container {
				font-size: 1.4rem;
			}

			&.block {
				display: block;
			}

			&.none {
				display: none;
			}
		}
		.pageheading {
			display: flex;
			padding: 2rem 0;
			svg {
				min-width: 2.5rem !important;
				min-height: 2.5rem !important;
				margin-right: 1rem;
			}

			h3 {
				margin: 0;
				line-height: 2.5rem;
			}
		}
	}

	//addon

	.add-on {
		display: flex;
		margin-right: auto;
		padding: 2rem 0 2rem 0;

		.createmessage {
			.motif-button {
				height: 4rem;
				font-size: 1.2rem;
				width: 15rem;
				&.motif-button-primary-alt:focus{
					background-color: #2e2e38;
					border-color: #2e2e38;
					color: #fff;
				}
			}
		}

		.search {
			width: 16%;
			line-height: 4rem;
			margin-right: auto;

			.motif-icon-button{
                top: 0.5rem;
            }

			.motif-typeahead-close-button {
				top: 0.5rem;
				&:hover{
					background: none!important;
				}
			}

			.motif-input {
				font-size: 1.4rem;
				font-weight: normal;
				height: 4rem;
				padding-right: 3rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				&::placeholder {
					font-size: 1.4rem;
					font-weight: normal;
				}
			}
		}
	}

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: 0rem;
	}

	.messaging-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: 1.4rem;
		background: ${(props) => props.theme.color[`white`]};
		border-top: 1px solid ${(props) => props.theme.color[`grey200`]};
	}

	.norecord {
		height: calc(100vh - 25rem);
		display: inline-block;
		width: 100%;
		display: flex;
		justify-content: center;
		flex: 0 auto;
		align-items: center;
		font-size: 1.6rem;
		color: ${(props) => props.theme.color[`grey400`]};
	}

	.messagingmanagementTable {
		height: 100%;
		overflow-y: auto;
		.motif-table {
			height: calc(100vh - 24rem);

			&.ag-theme-material .ag-checkbox-input-wrapper:focus-within {
				box-shadow: none;
			}

			.ag-header-cell {
				color: ${(props) => props.theme.color[`grey450`]};
				&:hover,
				&.ag-header-cell-moving {
					background: transparent !important;
				}
			}

			.ag-header-cell .ag-header-select-all {
				visibility: hidden;
			}

			.ag-body-viewport {
				height: calc(100vh - 30rem);
				border: 0.1rem solid ${(props) => props.theme.color['grey200']} !important;

				&:after {
					content: none;
				}
			}

			.ag-header-viewport {
				background: ${(props) => props.theme.color[`grey100`]};
				font-size: 1.4rem;
			}
			.ag-root.ag-layout-normal {
				overflow-y: auto;
				width: 100%;
			}

			.ag-root.ag-layout-auto-height {
				display: block;
			}

			.ag-center-cols-viewport {
				overflow-x: hidden;
			}

			.ag-row-hover {
				border-width: 0 0 0.1rem 0 !important;
				border-style: solid !important;
				border-color: ${(props) => props.theme.color[`grey250`]} !important;
			}

			.ag-row {
				border-width: 0 0 0.1rem 0 !important;
				border-style: solid !important;
				border-color: ${(props) => props.theme.color[`grey250`]} !important;
				color: ${(props) => props.theme.color[`grey400`]};

				.ag-cell-value {
					font-weight: 100;
				}
			}

			.ag-row,
			.ag-row-hover {
				background: ${(props) => props.theme.color[`white`]} !important;
				font-weight: normal;
				
				.ag-cell-wrapper{
					width: 100%;
					display: grid;
				}										
				
				.ag-column-hover {
					background: ${(props) => props.theme.color[`white`]} !important;
				}
			}

			.ag-header-row .ag-header-cell {
				&:hover {
					background: ${(props) => props.theme.color[`grey100`]};
				}
			}

			.ag-cell {
				background: ${(props) => props.theme.color[`white`]} !important;
			}

			.ag-cell,
			.ag-header-cell {
				font-size: 1.4rem;
				padding: 0 2rem;
				display: block;

				.ag-react-container {
					[role='button'] {
						cursor: default;
					}
				}

				.ag-header-cell-text {
					font-size: 1.4rem;
				}
			}

			.roleType.disabled,
            .roleType.disabled *{
                color: ${(props) => props.theme.color[`grey300`]}!important;
                fill: ${(props) => props.theme.color[`grey300`]};
            }
            .noptr{
                pointer-events: none;
            }
            .disabled{
                color: ${(props) => props.theme.color[`grey300`]}!important;
                fill: ${(props) => props.theme.color[`grey300`]};
            }
    
            .roleType {
                display: inline-block;
                width: 100%;
                align-items: center;
				cursor: pointer;

				.ellipses{
					margin: 0!important;
					padding: 0!important;
					display: inline-flex;
				}

				a {
					width: 100%;
					color: ${(props) => props.theme.color[`blue600`]};
					.ellipses{
						color: ${(props) => props.theme.color[`blue600`]};
					}
				}

				.motif-badge{
					width: 12rem;
					padding: 0.5rem 2rem;
					font-size: 1.4rem;
					border-radius: 2rem;
				}
    
                &.enable.icon{
                    fill: ${(props) => props.theme.color[`black`]}!important;
                }
                .columnName{
                    font-size: 1.4rem;
                    color: ${(props) => props.theme.color[`grey500`]};
				}
                }
            }
    

			.errorRow {
				color: ${(props) => props.theme.color[`red600`]} !important;
			}
		}

		.ag-body-horizontal-scroll {
			overflow: auto;
		}

		.ag-layout-normal {
			&::-webkit-scrollbar {
				width: 0.6rem;
			}
			&::-webkit-scrollbar-track {
				background: ${(props) => props.theme.color[`grey225`]};
				width: 0.6rem;
			}
			&::-webkit-scrollbar-thumb {
				background: ${(props) => props.theme.color[`grey300`]};
				width: 0.6rem;
				cursor: pointer;
			}
			&::-webkit-scrollbar-thumb:hover {
				background: ${(props) => props.theme.color[`grey400`]};
			}
		}
	}
`;

export default Messaging;
