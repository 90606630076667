import * as types from '../actions/actiontypes';
import {dashboardConstants} from '../util/uiconstants';
import initialState from './initialstate';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export default function ArchivesReducer(state = initialState.archives, action) {
	switch (action.type) {
		case types.GET_ARCHIVES:
			console.log('action from my archive reducer:', action);
			return action.response;
		case types.POST_REQUEST_ACCESS:
			if (action.responseObj.status === 200) {
				const {workspaceId} = action.resource.data;
				const {engagements} = action.resource.collections;
				
				//mapping through the clients and workspaces to find the correct workspaceId and then update the enguserstatus.
				const updatedClients = state.collections.clients.map((client) => ({
					...client,
					collections: {
						...client.collections,
						workspaces: client.collections.workspaces.map((workspace) => ({
							...workspace,
							collections: {
								...workspace.collections,
								engagements:
									workspace.id === workspaceId
										? workspace.collections.engagements.map((engagement) => ({
												...engagement,
												data:
													engagement.id === engagements[0]?.id
														? {
																...engagement.data,
																enguserstatus:
																	dashboardConstants.ENGAGEMENT_ARCHIVAL_REQUESTED
														  }
														: engagement.data
										  }))
										: workspace.collections.engagements
							}
						}))
					}
				}));

				return {
					...state,
					collections: {...state.collections, clients: updatedClients}
				};
			}

			return state;
		case types.CLEAR_ALL_ARCHIVES:
			return {};
		default:
			return state;
	}
}
