/**
 * @module <%=name%>
 * Action creator for <%=name%> object
 */

import * as types from './actiontypes'; //TODO: uncomment

/**
 * I've marked TODO items in this file that you'll need to rework to
 * match your new actioncreator. I've done this so your tests will not
 * fail as soon as you create new files--helpful when you're creating
 * multiple new files at once.
 *
 * Note that the second parameter here is simply "payload", and not
 * payload: payload. This is because ES6 allows you to omit the right
 * hand side of an object property assignment if it's the same
 * as the left side. Either version works, but this way
 * requires less typing.
 * @param {String} url - url as string to fetch the workspaces
 * @param {Object} resource - resource (object) to pass required data as part of fetch call
 * @return {{type: String, payload: Object}} The action object, including
 *      type (a String constant from actiontypes.js) and form data.
 */
export function getCopyRequest(url, copyRequestId) {
	return {
		type: types.GET_COPY_REQUEST,
		meta: {
			api: {
				url,
				method: 'GET'
			}
		},
		copyRequestId: copyRequestId
	};
}

export function getEngagementsToCopyTo(url, workspaceId) {
	return {
		type: types.GET_ENGAGEMENTS_TO_COPY_TO,
		meta: {
			api: {
				url,
				method: 'GET'
			}
		},
		workspaceId: workspaceId
	};
}

export function getEngagement(url, workspaceId, engagementId) {
	return {
		type: types.GET_ENGAGEMENT_FOR_COPY_REQUEST,
		meta: {
			api: {
				url,
				method: 'GET'
			}
		},
		workspaceId: workspaceId,
		engagementId: engagementId
	};
}

export function patchCopyRequest(url, resource) {
	return {
		type: types.PATCH_COPY_REQUEST,
		meta: {
			api: {
				url,
				method: 'PATCH',
				data: resource
			}
		}
	};
}
