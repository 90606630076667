/**
 * Created by rastwe on 1/13/2017.
 */

import * as TimeoutAction from '../../actions/timeoutactions';

import {Link, withRouter} from 'react-router-dom';
import {MotifDropdown, MotifDropdownItem} from '@ey-xd/motif-react';
import React, {useEffect, useState} from 'react';
import styled, {ThemeProvider} from 'styled-components';
import theme, {color} from '@ey/styled-theme';

import MoreMenuOptions from './MoreMenuOptions';
import {NavigationHeader} from '@ey/canvascoreservices';
import NotificationsCenter from '../Notifications/NotificationsCenter';
import {SVGICONS} from '@ey/icons';
import _ from 'lodash';
import {connect} from 'react-redux';
import {getSingleEngagement} from '../../actions/engagementsaction';
import {labels} from '../../util/uiconstants';
import * as QueryString from 'query-string';

function TopNav(props) {
	const {userSettings} = props;
	const [showDropdown, setShowDropdown] = useState(false);
	const [triggerNotification, setNotificationStatus] = useState(true);
	const [showIcons, setShowIcons] = useState(true);
	const params = QueryString.parse(window.location.search);

	useEffect(() => {
		let path = window.location.pathname;
		if (path === '/rejectIndependenceOnlyEngagementInvite') {
			setShowIcons(false);
		} else {
			if (!_.isEmpty(userSettings)) {
				setShowIcons(userSettings.data && Object.keys(userSettings?.data)?.length > 0 && (params.showNavIcons === 'true' || params.showNavIcons === undefined));
			}
		}
	}, [userSettings]);

	const isActiveSection = (elevatedSection) => {
		// let path = (location && location.pathname) || '';
		let path = window.location.pathname;
		if (
			path === '/' ||
			path === '/engagements' ||
			path === '/favorites' ||
			path === '/portfolioview' ||
			path === '/reviewengagements'
		) {
			path = '/';
		}
		if (path.toUpperCase() === elevatedSection.toUpperCase()) {
			return 'selected';
		} else if (elevatedSection.toUpperCase() === 'NOTIFICATIONS') {
			//return 'selected';
		}
	};

	const getMenuItems = () => {
		let items = [];

		items.push(getUserSettingsLink());
		if (!props.userSettings?.data?.iseyuser) {
			items.push(getLogoutLink());
		}

		return items;
	};

	const getUserSettingsLink = () => {
		let ret = {
			linkTo: {
				pathname: '/usersettings'
			},
			label: labels.usersettings
		};

		return ret;
	};
	const getLogoutLink = () => {
		let ret = {
			linkTo: {
				pathname: ''
			},
			label: labels.logout
		};

		return ret;
	};

	const setClickOutside = () => {
		setShowDropdown(false);
	};

	const handleToggle = () => {
		let toggle = showDropdown ? false : true;
		setShowDropdown(toggle);
		setNotificationStatus(false);
	};
	const handleUserOptionsChange = (value) => {
		setShowDropdown(false);
		if (value.label == labels.logout) {
			props.logoutUserAction();
		} else {
			props.history.push(value.linkTo.pathname);
		}
	};

	// Check if greater than 3 then trim it.
	const handleTrim = (userinitials) => {
		if (userinitials && userinitials.length > 2) {
			// skip middle charactor
			return userinitials[0] + userinitials[2];
		}

		return userinitials;
	};

	//Will be done after team integration

	// const userProfile = useMemo(() => {
	// 	return <UserPresence {...props} />;
	// });

	const getTopNavEyUserLinks = () => {
		const isTimeTrackingEnabled = props.featureConfiguration?.istimetrackingenabled;
		const isReportingHubEnabled = props.featureConfiguration?.isreportinghubenabled;
		const isServiceGatewayBaseUri = !_.isEmpty(props.featureConfiguration?.servicegatewaybaseuri);
		const serviceGatewayBaseUri = props.featureConfiguration?.servicegatewaybaseuri;
	    let topNavEyUserLinks = [];

		topNavEyUserLinks.push(
			{
				key: 'topNavEyUserEngagements',
				isLink: false,
				children: (
					<Link
						to="/"
						title={labels.engagements}
						onClick={() => {
							setNotificationStatus(false);
						}}
						className="engagements"
						activeClass={isActiveSection('/')}
					>
						<SVGICONS
							styleName="large"
							name="home"
							hoverText={labels.engagements}
							alt=""
						/>
					</Link>
				)
			},
			{
				key: 'topNavEyUserArchives',
				isLink: false,
				children: (
					<Link
						to="/archives"
						className="archives"
						title={labels.archives}
						activeClass={isActiveSection('/archives')}
					>
						<SVGICONS
							styleName="large"
							name="dns"
							hoverText={labels.archives}
							alt=""
						/>
					</Link>
				)
			}
		 );
	 		  
		if (isTimeTrackingEnabled) {
			topNavEyUserLinks.push(
				{
					key: 'topNavEyUserTimeTracking',
					isLink: false,
					children: (
						<Link
							to="/timesheet"
							className="timetracking"
							title={labels.timeTracking}
							activeClass={isActiveSection('/timesheet')}
						>
							<SVGICONS
								styleName="large"
								name="time-tracking"
								hoverText={labels.timeTracking}
								alt=""
							/>
						</Link>
					)
				}
			);
		  }
		
		  if (isReportingHubEnabled) {
		        topNavEyUserLinks.push(
				{
					key: 'topNavEyUserReportingHub',
					isLink: false,
					children: (
						<Link
							to="/reportingHub"
							className="reporting"
							title={labels.reportingHub}
							activeClass={isActiveSection('/reportingHub')}
						>
							<SVGICONS
								styleName="large"
								name="assessment"
								hoverText={labels.reportingHub}
								alt=""
							/>
						</Link>
					)
				}
			);
		 }

		 if (isServiceGatewayBaseUri) {
			topNavEyUserLinks.push(
				{
					key: 'topNavEyUserServiceGateway',
					isLink: false,
					children: (
						<a
							size="medium"
							href={serviceGatewayBaseUri}
							className="servicegateway"
						>
							<SVGICONS
								styleName="large"
								name="nextwave"
								hoverText={labels.serviceGateway}
								alt=""
							/>
						</a>
					)
				}
			);
	    }
		
		topNavEyUserLinks.push(
			{
				key: 'topNavEyUserNotifications',
				isLink: false,
				borderNotRequired: true,
				children: (
					<section
						title={labels.notifications}
						className="notifications"
						activeClass={isActiveSection('notifications')}
					>
						{
							<NotificationsCenter
								key="topNavEyUserNotificationsCenter"
								id="topNavEyUserNotificationsCenter"
								{...props}
								triggerNotification={triggerNotification}
								setNotificationStatus={setNotificationStatus}
								theme={
									document
										.getElementsByTagName('body')[0]
										.getAttribute('motif-theme') === 'light'
										? null
										: 'dark'
								}
							/>
						}
					</section>
				)
			}
		);
		return topNavEyUserLinks;
	}

	let topNavGuestUserLinks = [
		{
			key: 'topNavGuestUserEngagements',
			isLink: false,
			children: (
				<Link
					to="/"
					title={labels.engagements}
					onClick={() => {
						setNotificationStatus(false);
					}}
					className="engagements"
					activeclass={isActiveSection('/')}
				>
					<SVGICONS
						styleName="large"
						name="home"
						hoverText={labels.engagements}
						alt=""
					/>
				</Link>
			)
		},
		{
			key: 'topNavGuestUserUserInitials',
			isLink: false,
			borderNotRequired: true,
			children: (
				<section className="UserNav-Item ">
					<MotifDropdown
						open={showDropdown}
						placement="bottom-right"
						className="user-dropdown"
						handleClickOutside={() => setClickOutside()}
						trigger={
							<span
								className="user-initials"
								onClick={(e) => {
									//e.stopPropagation();
									handleToggle();
								}}
								title={labels.userInitialsTitle}
							>
								{handleTrim(props.userSettings?.data?.userinitials)}
							</span>
						}
					>
						{getMenuItems().map((option, index) => {
							return (
								<MotifDropdownItem
									onClick={() => handleUserOptionsChange(option)}
									title={option.label}
									key={index}
								>
									{option.label}
								</MotifDropdownItem>
							);
						})}
					</MotifDropdown>
				</section>
			)
		}
	];

	const topNavErrorLinks = [];
	const isEyUser = userSettings?.data?.iseyuser;

	const filtertopNavLinks = () => {
		if(!showIcons) {
			return [];
		}
		if (
			isEyUser !== undefined && isEyUser !== null
		)
			if (isEyUser && props.featureConfiguration?.istimetrackingenabled != undefined) {
				 return getTopNavEyUserLinks();
			} else {
				return topNavGuestUserLinks;
			}
		else {
			return topNavErrorLinks;
		}
	};

	return (	
		<ThemeProvider theme={theme}>
			<StyledNavbar className="transparent-oldnav">
				<>	
					<NavigationHeader
						offlinePrep={''}
						useMotifMenu={showIcons}
						disableEngagementSelector
						clickForOptionsLabel={labels.clickForOptions}
						hideOffline
						connectivitystate={''}
						getBookmarkAction={() => {}}
						apponlinestate
						topNavLinks={filtertopNavLinks()}
						zeusUrl="/"
					>				
						{showIcons && (
							<MoreMenuOptions
								featureConfiguration={props.featureConfiguration}
								userSettings={props.userSettings?.data}
							/>
						)}
					</NavigationHeader>
				</>
			</StyledNavbar>
		</ThemeProvider>
	);
}

function mapState(storeState) {
	return {
		apponlinestate: storeState.apponlinestate,
		userSettings: storeState.userSettings,
		featureConfiguration: storeState.featureConfiguration
	};
}

function mapDispatch(dispatch) {
	return {
		getSingleEngagement: (requestUrl) =>
			dispatch(getSingleEngagement(requestUrl)),
		logoutUserAction: () => dispatch(TimeoutAction.logoutUser())
	};
}

export default withRouter(connect(mapState, mapDispatch)(TopNav));

const StyledNavbar = styled.section`
	height: 6rem;
	width: 100%;
	margin: 0 auto;
	border: none;
	background-color: ${(props) => props.theme.color[`grey500`]};
	&.transparent-nav .NavigationBar {	
		    background-color: transparent !important;
			box-shadow: none;
	}
	.navChild,
	.navAnchor {
		padding: 1.8rem 2rem 1.1rem 2rem;
	}
	//.navChild:hover,
	//.navAnchor:hover {
	//	border-bottom: 0.4rem solid ${(props) =>
		props.theme.color[`grey500`]} !important;
	//}
	//.navChild.noBorder:hover,
	//.navAnchor.noBorder:hover {
	//	border: none;
	//}
	//.navChild.selected,
	//.navAnchor.selected {
	//	background-color:transparent !important;
	//	border-bottom: 0.4rem solid ${(props) =>
		props.theme.color[`grey500`]} !important;
	//	.engagements,
	//	.archives,
	//	.reporting,
	//	.usersettings {
	//		svg {
	//			fill: ${(props) => props.theme.color[`grey500`]} !important;
	//		}
	//	}
	//	.notificationButton {
	//		svg {
	//			fill: ${(props) => props.theme.color[`grey500`]} !important;
	//		}
	//	}
	//}
	a.selected {
		color: ${(props) => props.theme.color[`yellow400`]};
		border-bottom: ${(props) => props.theme.color[`yellow400`]};
		background-color: ${(props) => props.theme.color[`grey600`]};
	}
	.UserNav-Item {
		width: 50%;		
		a {
			padding: 1.5rem 2rem 1rem 2rem;
			margin: 0px 0.2rem;
			font-size: 1.5rem;
			font-weight: bold;
			display: inline-block;
			text-decoration: none;
			&:hover {
				text-decoration: none;
				border-bottom: 0.4rem solid ${(props) => props.theme.color[`yellow400`]};
			}
			&.selected {
				color: ${(props) => props.theme.color[`yellow400`]};
				border-bottom: 0.4rem solid ${(props) => props.theme.color[`yellow400`]};
				background-color: ${(props) => props.theme.color[`grey600`]};
			}
			svg {
				fill: ${(props) => props.theme.color[`white`]};
				width: 3rem;
				height: 3rem;
				max-width: 3rem;
				max-height: 3rem;
				cursor: pointer;
				margin-top: 0.3rem;
			}
		}
		.user-initials {
			border-radius: 50%;
			width: 2.5rem;
			height: 2.5rem;
			background: ${(props) => props.theme.color[`blue300`]};
			display: inline-block;
			vertical-align: middle;
			line-height: 2.5rem;
			text-align: center;
			color: ${(props) => props.theme.color[`white`]};
			position: absolute;
			top: -1.4rem;
			font-size: 1.2rem;
			@media screen and (max-width: 1366px) {
				top: -1.7rem;
			}
		}
		.user-dropdown {
			padding-right: 0.1rem;
			.motif-dropdown-menu {
				border: none;
				margin-top: 1.6rem;
				margin-right: -1.9rem;
				@media screen and (max-width: 1366px) {
					margin-top: 1.1rem;
					margin-right: -1.8rem;
				}
				.motif-dropdown-item {
					font-size: 1.2rem;
				}
			}
		}
	}
	/* Logo */
	.EYHeader {
		max-width: 2048px;
		margin: 0 auto;
		display: flex;
	}
	.userimghold {
		display: flex;
		font-size: 1.3rem;
		align-items: center;
		.userimg {
			border-radius: 10rem;
			background: ${(props) => props.theme.color[`green500`]};
			margin-right: 0.8em;
			color: ${(props) => props.theme.color[`white`]};
			width: 5rem;
			height: 5rem;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.EYLogo {
		margin-left:4rem;
	}
	/* NavigationLinks */
	.NavigationBarShadow {
		height: 6.1rem;
	}
	.EYNavigation {
		position: relative;
		width: 86%;
		top: 0;
		margin-right:2.2rem;
		.engagements,
		.archives,
		.timetracking,
		.servicegateway,
		.reporting,
		.usersettings {
			svg {
				fill: ${(props) => props.theme.color[`white`]} !important;
				margin-top: 0.3rem;
			}
		}
		.servicegateway svg {
			width: 2rem !important;
		}
		.notificationButton {
			svg {
				margin-top: 0.6rem;
				width: 2rem;
				height: 2rem;
			}
			.btnCount {
				top:0.4rem !important;
			}
		}
		.navChild,
		.navAnchor,
		.navChild.selected,
		.navAnchor.selected {
			padding: 1.8rem 1.6rem 1.1rem 1.6rem !important;
			//@media screen and (max-width: 1366px) {
			//	padding: 1.7rem 2rem 0.9rem 2rem;
			//}
		}
		.DefaultMenuContainer {
			right: 5.8rem;
		}
	}

	.EYHeader .EYNavigation .timetracking svg {
		fill: ${(props) => props.theme.color[`grey500`]} !important;
		path {
			fill: ${(props) => props.theme.color[`grey500`]} !important;
		}
		path:last-child {
			fill: ${(props) => props.theme.color[`white`]} !important;
		}
	}
	.MoreMenuContainer {
		.EYMoreLinksIcon {			
			 svg {
				fill: ${(props) => props.theme.color[`white`]};
				width: 2.4rem;
				height: 2.4rem;
				cursor:pointer;
			}
		}
		.motif-menu-overlay {
			&.motif-menu-overlay-open {
				background: rgba(46, 46, 56, 0.2);
			}
			.motif-menu.motif-menu-open {
				box-shadow: 1px 11px 11px 8px rgb(0 0 0 / 38%);
				max-width: 34rem;
			}
			.motif-menu.motif-menu-alt,
			.motif-menu-nav .motif-menu-list,
			.motif-menu-nav .motif-menu-list {
				//background: ${(props) => props.theme.color[`white`]};
				//color: ${(props) => props.theme.color[`grey500`]};
			}
			.motif-menu-nav .motif-menu-list {
				padding-top: 3.2rem;
				.MotifMenuListWrapper {
					height: calc(100vh - 14.7rem);
					position:relative;
					.privacy-wrap {
						position:absolute;
						bottom:0;
						width:100%;
						display:flex;
						justify-content: center;
						padding-left:2rem;
						padding-bottom:0.5rem;
						.privacy-noticeitem .privacy-notice {
							font-size:1rem;
							font-weight:300;
							color: ${(props) => props.theme.color[`white`]};
						}
						.privacy-separator {
							font-size:1rem;
							font-weight:700;
							color: ${(props) => props.theme.color[`white`]};
							padding: 0 0.5rem;
						}
						.terms-serviceitem .terms-service {
							font-size:1rem;
							font-weight:300;
							color: ${(props) => props.theme.color[`white`]};
						}										
					}
				}
				.MotifMenuListWrapper .motif-menu-list-item {
					border-color: ${(props) => props.theme.color[`grey500`]};
					&:hover {
						border-color: ${(props) => props.theme.color[`yellow500`]} !important;
					}
					a {
						color: ${(props) => props.theme.color[`white`]};
						font-size: 1.6rem;
						font-weight:300;
						padding: 0 1rem 0 0.75rem;
						.motif-icon {
							width:auto;
							height:2.4rem;
							@media screen and (max-width: 1366px) {
								height:3rem;
							}
						}
						svg {
							width:2rem;
							height:2rem;
							fill: ${(props) => props.theme.color[`white`]};
							margin-right:1rem;
							vertical-align: text-bottom;
						}
						&.oversight svg path {
							fill: ${(props) => props.theme.color[`white`]};

						}
					}
				}
				.FooterWrapper {
					background-color: ${(props) => props.theme.color[`black`]};
					.CopyrightContainer {
						font-size: 1.2rem;
						font-weight:700;
						margin:0;
						color: ${(props) => props.theme.color[`grey400`]};
					}
				}
		}
	}
`;
