import React, { useState } from 'react';
import Toast from '../Common/Toast/Toast';
import { currentResource } from '../../util/uiconstants';

const TimeTrackerErrorPlaceholder = (props) => {
	const errorCode = 336699;
	const errorMessage = currentResource.errors[errorCode];

	const [closeToast, setCloseToast] = useState(false);

	return (
		<>
			{closeToast ? (
				<></>
			) : (
				<Toast
					variant="error"
					key={'toastKey_TimeTracking'}
					onClose={() => {
						setCloseToast(true);
					}}
				>
					{errorCode + ' : ' + errorMessage}
				</Toast>
			)}
		</>
	);
}
export default TimeTrackerErrorPlaceholder;
