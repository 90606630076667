/**
 * Remember: always add new reducers to the /reducers/index.js file!
 * Also, add your initialState to './initialstate'!
 */
import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export default function DashboardReducer(
	state = initialState.myEngDashboard, //{Workspaces: {}},
	action
) {
	switch (action.type) {
		case types.SET_CURRENT_ACTIVETAB_IN_MYENG:
			return Object.assign({}, state, {
				activeTab: action.payload
			});
			break;
		case types.SET_CURRENT_GROUPING_TYPE_IN_MYENG:
			return Object.assign({}, state, {
				groupingType: action.payload
			});
			break;
		case types.REFRESH_DASHBOARD:
			if (action.payload == 0)
				return Object.assign({}, state, {
					refreshDashBoard: state.refreshDashBoard + 1
				});

			if (action.payload == 1)
				return Object.assign({}, state, {
					refreshDashBoardPagnReset: state.refreshDashBoardPagnReset + 1
				});
			break;
		default:
			return state;
	}
}
