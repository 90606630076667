import {EYForm, FormComboBox, FormStaticText, FormTextInput} from '@ey/form';
import {
	MotifFormField,
	MotifLabel,
	MotifOption,
	MotifSelect
} from '@ey-xd/motif-react';
import React, {useEffect, useState} from 'react';
import {
	currentResource,
	dateFormats,
	dateFormatsListItems,
	labels,
	urls
} from '../../../../util/uiconstants';
import {useDispatch, useSelector} from 'react-redux';

import AddNewEngagementCode from './addNewEngagementCode';
import Button from '@ey/button';
import Calendar from '../../.././Common/MotifDatePicker/datepicker';
import Createeditstyle from './restorewrapperstyle';
import EllipsesControl from '@ey/ellipses-control';
import Loader from '@ey/loader';
import RestoreEngagementModel from './restoremodel';
import SubServiceLineList from './subserviceline';
import _ from 'lodash';
import env from '../../../../util/env';
import {getLanguages} from '../../../../actions/languageactions';
import {getNotifications} from '../../../../actions/notificationsactions';
import {getPrimaryoffices} from '../../../../actions/primaryofficesaction';
import {getServiceLines} from '../../../../actions/servicelinesaction';
import {saveNewEngagement} from '../../../../actions/workspacesactions';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useDidMountEffect} from '../../../../util/customHooks';

const RestoreEngagement = ({
	getDependencyData,
	onFinishClick,
	updateWizardData
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	// Redux States.
	const primaryOffices = useSelector((state) => state.primaryoffices);
	const serviceLines = useSelector((state) => state.servicelines);
	const languages = useSelector((state) => state.languages);
	// Local States.
	const [engagementName, setEngagementName] = useState('');
	const [primaryOfficesOptions, setPrimaryOfficesOptions] = useState([]);
	const [availableLanguages, setAvailableLanguages] = useState([]);
	const [serviceLineOptions, setServiceLineOptions] = useState([]);
	const [subServiceLineID, setSubServiceLineID] = useState(
		getDependencyData('domainOriginSubServiceLnId')
	);
	const [subServiceLineOptions, setSubServiceLineOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [validationTriggerCounter, setValidationTriggerCounter] = useState(0);
	const [expiryDate, setExpiryDate] = useState(3);

	//Local Variables
	const userPrimaryOfficeID = getDependencyData('domainOriginPrimoffId');
	const userLanguageID = getDependencyData('domainOriginLanguageId');
	const serviceLineID = getDependencyData('domainOriginServiceLnId');
	const engagementCodeArray = getDependencyData('domainOriginEngCodeArr');
	const engagementYearEndDate = getDependencyData('domainOriginEngYearEndDate');
	const engagementEndDate = getDependencyData('domainOriginEngEndDate');
	const auditorsReportDate = getDependencyData('domainOriginAudReportDate');

	const URLs = {
		notificationsURL: `${env.getURL('serviceUrl')}/${
			urls.LANDINGPAGENOTIFICATION_URL
		}`,
		primaryOfficesURL: `${env.getURL('serviceUrl')}/${urls.COUNTRY_OFFICE_URL}`,
		languagesURL: `${env.getURL('serviceUrl')}/${urls.LANGUAGES_URL}`,
		serviceLinesURL: `${env.getURL('serviceUrl')}/${urls.SERVICE_LINES_URL}`,
		workSpacesURL: `${env.getURL('serviceUrl')}/${urls.WORKSPACES_URL}`
	};

	useEffect(() => {
		configureFormFields();
	}, []);

	useDidMountEffect(() => {
		setValidationTriggerCounter((prevState) => (prevState += 1));
		if (isComponentValid()) {
			restoreEngagementAsync();
		}
	}, [onFinishClick]);

	const configureFormFields = async () => {
		try {
			setIsLoading(true);
			await loadPrimaryOfficesAsync();
			await loadLanguagesAsync();
			await loadServiceLinesAsync();
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		updateWizardData('domainEngagementName', engagementName);
	}, [engagementName]);

	useEffect(() => {
		if (
			serviceLines &&
			serviceLines?.collections &&
			serviceLines?.collections?.servicelines
		) {
			setServiceLineOptions(
				serviceLines?.collections?.servicelines.map((serviceLine) => {
					return {
						label: serviceLine.data.servicelinename,
						value: serviceLine.data.servicelineid
					};
				})
			);
			setSubServiceLineOptions(loadSubServiceLine());
		}
	}, [serviceLines]);

	useEffect(() => {
		if (
			primaryOffices &&
			primaryOffices?.collections &&
			primaryOffices?.collections?.countryofficeregioncollection
		) {
			setPrimaryOfficesOptions(
				primaryOffices?.collections?.countryofficeregioncollection.map(
					(primaryOffice) => {
						return {
							value: primaryOffice.id,
							label: primaryOffice.data.name
						};
					}
				)
			);
		}
	}, [primaryOffices]);

	useEffect(() => {
		if (
			languages &&
			languages?.collections &&
			languages?.collections?.languagecollection
		) {
			setAvailableLanguages(
				languages?.collections?.languagecollection.map((language) => {
					return {
						value: language.id,
						label: language.data.name
					};
				})
			);
		}
	}, [languages]);

	const updateState = (key, value) => {
		switch (key) {
			case 'engagementname':
				setEngagementName(value);
				break;
			case 'subserviceline':
				setSubServiceLineID(value);
				break;
			default:
				break;
		}
	};

	const loadPrimaryOfficesAsync = async () => {
		const fetchPrimaryOfficesUrl = `${
			URLs.primaryOfficesURL
		}/?filters=languageid eq 1,countryid eq ${parseInt(
			getDependencyData('domainOriginLocation')
		)},defaultlanguage eq english`;
		await dispatch(
			getPrimaryoffices(
				fetchPrimaryOfficesUrl,
				fetchPrimaryOfficesUrl.split('/api/v1')[0]
			)
		);
	};

	const loadLanguagesAsync = async () => {
		const fetchLanguagesUrl = `${URLs.languagesURL}/?filters=isactive eq true`;
		await dispatch(
			getLanguages(fetchLanguagesUrl, fetchLanguagesUrl.split('/api/v1')[0])
		);
	};

	const loadServiceLinesAsync = async () => {
		const fetchServiceLinesUrl = `${URLs.serviceLinesURL}/?phase=RestoreEngagement` + `&countryid=` + getDependencyData('domainOriginLocation');
		await dispatch(
			getServiceLines(
				fetchServiceLinesUrl,
				fetchServiceLinesUrl.split('/api/v1')[0]
			)
		);
	};

	const loadSubServiceLine = () => {
		const subservicelinelist = [];
		if (
			serviceLines &&
			serviceLines?.collections &&
			Array.isArray(serviceLines?.collections?.servicelines)
		) {
			serviceLines.collections.servicelines.find((serviceline) => {
				if (serviceline.id === serviceLineID) {
					subservicelinelist.push(
						...serviceline.collections.subservicelinesdata
					);
				}
			});
		}
		return subservicelinelist;
	};
	const isComponentValid = () => {
		return engagementName.trim().length > 0;
	};
	const restoreEngagementAsync = async () => {
		await postAPICall(prepareRestoreModel());
	};
	const prepareRestoreModel = () => {
		let mdl = new RestoreEngagementModel();
		let wkModel = mdl.getWorkspaceModel();
		let isExistingWk = getDependencyData('domainChooseExisting');
		let opt = isExistingWk ? 'RestoreToExistingWorkspace' : 'RestoreEngagement';
		let isClientValid = !Number.isNaN(
			Number.parseInt(getDependencyData('domainClientId'))
		);
		wkModel.id = Number.parseInt(getDependencyData('domainWorkspaceId')) || 0;
		wkModel.data.operationtype = opt;
		wkModel.data.clientcode = getDependencyData('domainClientCode') || '';
		wkModel.data.clientname = getDependencyData('domainClientName');
		wkModel.data.workspacedesc = getDependencyData('domainWorkspaceName');
		wkModel.data.isclientvalidated = isClientValid;
		wkModel.data.clientid = getDependencyData('domainClientId');
		wkModel.data.countryid = getDependencyData('domainOriginLocation');
		wkModel.data.rfworkspaceid = isExistingWk
			? getDependencyData('domainWorkspaceId')
			: getDependencyData('domainOriginWorkspaceId');
		wkModel.data.selectedarcfileguid = getDependencyData(
			'domainSelectedArcFileGuid'
		);
		wkModel.collections = {engagements: []};
		wkModel.collections.engagements.push(mdl.WorkspaceEngagements());
		let engDt = wkModel.collections.engagements[0].data;
		engDt.description = getDependencyData('domainEngagementName');
		engDt.primaryoffice = getDependencyData('domainOriginPrimoffId');
		engDt.languageid = getDependencyData('domainOriginLanguageId');
		engDt.yearenddate = getDependencyData('domainOriginEngYearEndDate');
		engDt.enddateperiod = getDependencyData('domainOriginEngEndDate');
		engDt.reportreleasedate = getDependencyData('domainOriginAudReportDate');
		engDt.archivalengagementid = getDependencyData('domainOriginEngId');
		engDt.servicelineid = getDependencyData('domainOriginServiceLnId');
		engDt.subservicelineid = getDependencyData('domainOriginSubServiceLnId');
		engDt.engagementversion = getDependencyData('domainOriginEngVersion');
		engDt.engagementsourceid = getDependencyData('domainglobalexception')
			? 8
			: 2;
		engDt.expirydays = expiryDate;
		let engCodeArr =
			wkModel.collections.engagements[0].collections.engagementcodes;
		engagementCodeArray.map((engCode) => {
			engCodeArr.push({
				data: {
					code: engCode.data.code,
					name: engCode.data.name,
					isprimary: engCode.data.isprimary,
					clientengagementid: engCode.data.clientengagementid,
					invalidengagementcodeerror: engCode.data.invalidengagementcodeerror
				}
			});
		});
		return wkModel;
	};
	const postAPICall = async (workspaceModel) => {
		// https://canvas-app-dev1.cloudapp.eydev.net/api/v1/Workspaces.json
		try {
			setIsLoading(true);
			await dispatch(
				saveNewEngagement(
					`${URLs.workSpacesURL}`,
					JSON.stringify(workspaceModel)
				)
			);
			await dispatch(getNotifications(`${URLs.notificationsURL}`));
			history.push('/');
		} finally {
			setIsLoading(false);
		}
	};

	const renderEngagementCodes = () => {
		return (
			engagementCodeArray &&
			engagementCodeArray.map((engagementCode, index) =>
				engagementCode.length != 0 ? (
					<AddNewEngagementCode
						engCode={engagementCode.data.code}
						engCodeName={engagementCode.data.name}
						isPrimary={engagementCode.data.isprimary}
						engagementcodeindex={index}
						isDeletedDisabled={true}
						isEngagementCodeDisabled={true}
					/>
				) : null
			)
		);
	};
	return (
		<StyledCreateeditstyle>
			{isLoading && <Loader view="fullscreen" />}
			<Createeditstyle>
				<section className="createeditengagement">
					<section>
						<header>
							<h5>{labels.createengagementquestionlabel}</h5>
						</header>
						<section className="clientworkspaceDetails">
							<FormStaticText label={labels.client + ':'}>
								<EllipsesControl
									id=""
									content={getDependencyData('domainClientName')}
									tooltip={getDependencyData('domainClientName')}
									isTooltipAvailable
								/>
							</FormStaticText>
							<FormStaticText label={labels.workspace + ':'}>
								<EllipsesControl
									id=""
									content={getDependencyData('domainWorkspaceName')}
									tooltip={getDependencyData('domainWorkspaceName')}
									isTooltipAvailable
								/>
							</FormStaticText>
						</section>
					</section>
					<form
						className="createengagement"
						onClick={(e) => {
							e.preventDefault();
						}}
					>
						<EYForm
							id="createEngagementForm"
							onChange={updateState}
							onSubmit={() => {}}
							action="\"
							validationTriggerCounter={validationTriggerCounter}
						>
							<FormTextInput
								placeholder={labels.engagementname}
								name="engagementname"
								value={engagementName}
								is_required
								help
								maxLength="255"
								errormessage={labels.engagementnamerequired}
								clearable={true}
								className="abc"
							/>
							{renderEngagementCodes()}
							<section className="addengagement">
								<Button
									name="addNewEngagementCode"
									value="addNewEngagementCode"
									buttonType="secondary"
									disabled={true}
									label={labels.addengagementcode}
									onClick={(e) => {}}
								/>
							</section>
							<FormComboBox
								id="userprimaryofficeid"
								name="userprimaryofficeid"
								value={userPrimaryOfficeID}
								help
								placeholder={labels.primaryoffice}
								disabled={true}
								defaultValue={{label: 'Select', value: 0}}
								options={primaryOfficesOptions}
								errormessage={labels.primaryofficerequired}
							/>
							<FormComboBox
								id="userlanguage"
								name="userlanguage"
								value={userLanguageID}
								help
								placeholder={labels.language}
								disabled={true}
								options={availableLanguages}
								errormessage={labels.languagerequired}
							/>
							<br />
							<FormComboBox
								id="serviceline"
								name="serviceline"
								value={serviceLineID}
								is_required
								help
								placeholder={labels.serviceline}
								searchable
								disabled={true}
								options={serviceLineOptions}
								errormessage={labels.servicelinerequired}
								clearable={true}
							/>
							<SubServiceLineList
								subserviceline={subServiceLineID}
								isDisabled={true}
								subservicelineOption={subServiceLineOptions}
								onChange={updateState}
							/>
							<br />
						</EYForm>
						<section className="zeus-datepicker">
							<Calendar
								name="clientenddate"
								value={engagementYearEndDate}
								label={labels.engagementstartdate}
								dateFormat={dateFormatsListItems[dateFormats].format}
								disabled={true}
								onChange={(e) => {}}
							/>
							<Calendar
								name="engagementenddate"
								value={engagementEndDate}
								label={labels.engagementenddate}
								dateFormat={dateFormatsListItems[dateFormats].format}
								disabled={true}
								onChange={(e) => {}}
							/>
							<br />
							<Calendar
								name="auditorsreportdate"
								value={auditorsReportDate}
								label={labels.auditorsreportdate}
								dateFormat={dateFormatsListItems[dateFormats].format}
								disabled={true}
								onChange={(e) => {}}
							/>
							<MotifFormField className="restore-expirydate">
								<MotifLabel id="select-label-visible-options">
									{labels.deleteafter}
								</MotifLabel>
								<MotifSelect
									id="expirydate"
									name="expirydate"
									value={expiryDate}
									onChange={(e) => {
										setExpiryDate(e);
									}}
									arialabelledby="select-label-visible-options"
								>
									{currentResource.restoreengagementexpirydates.map(
										(expirydate) => (
											<MotifOption
												key={expirydate.value}
												value={expirydate.value}
											>
												{expirydate.label}
											</MotifOption>
										)
									)}
								</MotifSelect>
							</MotifFormField>
						</section>
					</form>
				</section>
			</Createeditstyle>
		</StyledCreateeditstyle>
	);
};
RestoreEngagement.propTypes = {};
export default RestoreEngagement;

const StyledCreateeditstyle = styled.section`
	.restore-expirydate {
		vertical-align: top;
		&.motif-form-field .motif-label {
			top: 0.6rem !important;
			font-size: 1rem !important;
		}
		.motif-select-input {
			height: 4.8rem;
			font-size: 1.4rem;
			padding-top: 2.3rem;
			@media screen and (max-width: 1366px) {
				padding-top: 2rem;
			}
			.motif-select-view-value,
			svg {
				margin-top: 0.8rem;
			}
		}
		.motif-select-field-list .motif-option-text {
			font-size: 1.4rem;
		}
	}
`;
