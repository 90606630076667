import React from 'react';
import PropTypes from 'prop-types';
import {MotifProgressBar, MotifIcon} from '@ey-xd/motif-react';
import '../_timeline.scss';
import {
	notificationIcPriorityHigh12px,
	navigationIcCheck24px
} from '@ey-xd/motif-react/assets/icons';

const Milestone = React.memo(
	({progress, past}) => {
		const progressValue = progress === null && past ? 100 : progress;

		return (
			<React.Fragment>
				<MotifProgressBar value={progressValue} circle />
				{progressValue === 100 && (
					<div
						className="motif-timeline-checkmark"
						aria-label="complete checkmark"
					>
						<MotifIcon src={navigationIcCheck24px} />
					</div>
				)}
				{progressValue < 100 && (
					<React.Fragment>
						{!past && (
							<div
								className="motif-timeline-no-checkmark"
								aria-label="completed no checkmark"
							/>
						)}
						{past && (
							<div
								className="motif-timeline-x"
								aria-label="not completed crossmark"
							>
								<MotifIcon src={notificationIcPriorityHigh12px} />
							</div>
						)}
					</React.Fragment>
				)}
			</React.Fragment>
		);
	},
	(prevProps, nextProps) =>
		prevProps.progress === nextProps.progress &&
		prevProps.past === nextProps.past
);

Milestone.defaultProps = {
	progress: null,
	past: false
};

Milestone.propTypes = {
	progress: PropTypes.number,
	past: PropTypes.bool
};

export default Milestone;
