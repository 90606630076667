import React from 'react';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';

const EngagementMetric = (props) => {
	const {metricName, metricValues} = props;

	return (
		<StyledMetric>
			<section className="metric-counter-title">
				<span title={metricName}>{metricName}</span>
			</section>

			{metricValues &&
				metricValues.map((metric) => {
					const metricCount = metric === 0 ? '-' : metric;
					return (
						<section key={uuidv4()} className={`metric-badge-wrapper`}>
							{<span className="metric-badge">{metricCount}</span>}
						</section>
					);
				})}
		</StyledMetric>
	);
};

export default EngagementMetric;

const StyledMetric = styled.section`
	display: flex;
	width: 100%;
	margin: 1rem 0px 0px;
	.metric-counter-title {
		font-weight: normal;
		width: 40%;
		padding-right: 10%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		line-height: 2.4rem;
	}
	.metric-badge-wrapper {
		width: 20%;
		display: inherit;
		.metric-badge {
			border: 0.1rem solid ${(props) => props.theme.color[`grey500`]};
			border-radius: 0.4rem;
			min-width: 6rem;
			text-align: center;
			background: ${(props) => props.theme.color[`grey500`]};
			color: ${(props) => props.theme.color[`white`]};
			line-height: 3rem;
			padding: 0 1rem;
		}
	}
`;
