import * as types from './actiontypes';

export function getTemplateData(url, resource) {
	return {
		type: types.GET_KNOWLEDGETEMPLATE,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}
