import {MotifCheckbox, MotifFormField} from '@ey-xd/motif-react';
import React, {useState} from 'react';
import {
	currentResource,
	dateFormats,
	dateFormatsListItems,
	labels,
	urls
} from '../../../util/uiconstants';
import {useDispatch, useSelector} from 'react-redux';

import EllipsesControl from '@ey/ellipses-control';
import {FormStaticText} from '@ey/form';
import Importscot from './importscot';
import Loader from '@ey/loader';
import {SVGICONS} from '@ey/icons';
import env from '../../../util/env';
import {getNotifications} from '../../../actions/notificationsactions';
import moment from 'moment';
import {patchCopyRequest} from '../../../actions/copyhubactions';
import styled from 'styled-components';
import {useDidMountEffect} from '../../../util/customHooks';
import {useHistory} from 'react-router-dom';

const TopLevelStyle = styled.section`
	& .importscotdata {
		& .import-error {
			font-size: 1.2rem;
			line-height: normal;
			height: 2rem;
			padding-top: 1rem;
			padding-bottom: 2rem;

			& svg {
				fill: ${(props) => props.theme.color[`red600`]};
				vertical-align: text-top;
				margin-right: 0.5em;
			}
		}

		& .engagementname-confirmcheck {
			& > h5 {
				font-size: 1.4rem;
				line-height: normal;
			}

			.confirmbox {
				display: block;
				position: relative;
				cursor: pointer;
				font-size: 1.4rem;
				line-height: normal;
				margin-top: 1rem;

				& > label {
					font-weight: normal;
					margin-left: 2rem;
					line-height: normal;
				}
			}

			/* Hide the default checkbox */
			input[type='checkbox'] {
				visibility: hidden;
			}

			/* Creating a custom checkboxbased on demand */
			.geekmark {
				position: absolute;
				top: 0;
				left: 0;
				height: 2rem;
				width: 2rem;
				border: 0.1rem solid ${(props) => props.theme.color[`grey500`]};
			}

			/* Specify the background color to beshown when hovering over checkbox */
			/*.confirmbox:hover input ~ .geekmark {
            background-color: yellow;
        	} */

			/* Specify the background color to be shown when checkbox is active */
			/*.confirmbox input:active ~ .geekmark {
            background-color: red;
        	}
        */

			/* Specify the background color to be shown when checkbox is checked */
			.confirmbox input:checked ~ .geekmark {
				background-color: grey500;
			}

			.confirmbox input.red ~ .geekmark {
				border: 0.1rem solid red;
			}

			/* Checkmark to be shown in checkbox */
			/* It is not be shown when not checked */
			.geekmark:after {
				content: '';
				position: absolute;
				display: none;
			}

			/* Display checkmark when checked */
			.confirmbox input:checked ~ .geekmark:after {
				display: block;
			}

			/* Styling the checkmark using webkit */
			/* Rotated the rectangle by 45 degree and showing only two border to make it look like a tickmark */
			.confirmbox .geekmark:after {
				left: 0.6rem;
				bottom: 0.6rem;
				width: 0.6rem;
				height: 1.2rem;
				border: solid white;
				border-width: 0 0.2rem 0.2rem 0;
				-webkit-transform: rotate(40deg);
				-ms-transform: rotate(36deg);
				transform: rotate(36deg);
			}
			.confirmation-checkbox {
				margin-left: 0 !important;
				&.red {
					.motif-checkbox-custom {
						border-color: ${(props) => props.theme.color[`red600`]};
						&:hover {
							border-color: ${(props) => props.theme.color[`red600`]};
						}
					}
				}
				.motif-checkbox-custom {
					width: 2rem;
					height: 2rem;
				}
				.motif-checkbox-label {
					font-size: 1.4rem;
					padding-top: 0.3rem;
					font-weight: 400;
				}
			}
		}
	}
`;

const DeclinePackage = (props) => {
	const {onFinishClick, isGroupAudit, closeWizard} = props;

	const dispatch = useDispatch();
	const history = useHistory();
	const copyRequest = useSelector((state) => state.copyHub.copyRequest);
	const linkRequest = useSelector((state) => state.copyHub.linkRequest);

	const getNotificationsUrl =
		env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;
	const [isValid, setIsValid] = useState(false);
	const [initLoad, setInitLoad] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	useDidMountEffect(() => {
		if (onFinishClick) {
			const isGroupAuditAsync = async () => {
				if (isGroupAudit) {
					await declineGaLink();
				} else {
					await declinePackage();
				}
			};

			setInitLoad(false);
			if (isValid) {
				isGroupAuditAsync();
			}
		}
	}, [onFinishClick]);

	const declineGaLink = async () => {
		try {
			setIsLoading(true);
			var GALinkModelData = createGALinkModelData();
			let GALinkDeclineRequestUrl =
				env.getURL('serviceUrl') +
				'/' +
				urls.GROUPAUDITENGAGEMENTS_URL +
				'?id=' +
				linkRequest.id +
				'&phase=declinegalinkrequest';

			await dispatch(
				patchCopyRequest(
					GALinkDeclineRequestUrl,
					JSON.stringify(GALinkModelData)
				)
			);

			await dispatch(getNotifications(getNotificationsUrl));
			history.push('');
		} finally {
			setIsLoading(false);
		}
	};

	const declinePackage = async () => {
		try {
			setIsLoading(true);
			var copyHubModelData = createCopyHubModelData();
			let copyHubRequestUrl =
				env.getURL('serviceUrl') +
				'/' +
				urls.GROUPAUDITENGAGEMENTS_URL +
				'?id=' +
				copyRequest.id +
				'&phase=declinecopyrequest';

			await dispatch(
				patchCopyRequest(copyHubRequestUrl, JSON.stringify(copyHubModelData))
			);

			await dispatch(getNotifications(getNotificationsUrl));
			closeWizard();
		} finally {
			setIsLoading(false);
		}
	};

	const createCopyHubModelData = () => {
		var copyhubModel = {
			collections: {
				iepnotifications: [
					{
						id: copyRequest.id,
						data: {
							targetcomment: '',
							targetpoctext: ''
						}
					}
				]
			}
		};

		return copyhubModel;
	};

	const createGALinkModelData = () => {
		var GALinkModel = {
			collections: {
				linknotifications: [
					{
						id: linkRequest.id,
						data: {
							linkcomment: 'Yes',
							pocgui: null,
							poctext: '',
							concurrencytoken: linkRequest.concurrencytoken
						}
					}
				]
			}
		};

		return GALinkModel;
	};

	const handleCheck = (e) => {
		setIsValid(e);
		setInitLoad(false);
	};

	const getHeaderForAction = () => {
		const packageRecieveDate = moment
			.utc(copyRequest?.iepreceivedate)
			.format(dateFormatsListItems[dateFormats].label);

		if (isGroupAudit) {
			return (
				<Importscot>
					<header>
						<h5>{labels.gadeclinequestionlabel}</h5>
					</header>
					<section className="importscot">
						<section className="packagesourcedetail">
							<section className="packagedetailscolumn">
								<FormStaticText label={labels.sourceengagement + ':'}>
									<EllipsesControl
										id="sourceengagementname"
										content={linkRequest.primaryengagementname}
										tooltip={linkRequest.primaryengagementname}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.garegioncomponent + ':'}>
									<EllipsesControl
										id="regionComponent"
										content={linkRequest.componentshortname}
										tooltip={linkRequest.componentshortname}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.gascope + ':'}>
									<EllipsesControl
										id="Scope"
										content={linkRequest.gascopename}
										tooltip={linkRequest.gascopename}
										isTooltipAvailable
									/>
								</FormStaticText>
							</section>
						</section>
					</section>
				</Importscot>
			);
		} else {
			return (
				<Importscot>
					<header>
						<h5>
							{labels.decline +
								' ' +
								currentResource.copyObjectGroupType[
									copyRequest.copyobjectgrouptypeid
								]}
						</h5>
					</header>
					<section className="importscot">
						<section className="packagesourcedetail">
							<FormStaticText label={labels.package + ':'}>
								<EllipsesControl
									id="packagename"
									content={copyRequest.copyname}
									tooltip={copyRequest.copyname}
									isTooltipAvailable
								/>
							</FormStaticText>
							<FormStaticText label={labels.sourceengagement + ':'}>
								<EllipsesControl
									id="sourceengagementname"
									content={copyRequest.sourceengagementdescription}
									tooltip={copyRequest.sourceengagementdescription}
									isTooltipAvailable
								/>
							</FormStaticText>
							<section className="sentbydetails">
								<FormStaticText label={labels.sentby + ':'}>
									{copyRequest.id
										? copyRequest.sendername +
										  ' ' +
										  labels.on +
										  ' ' +
										  packageRecieveDate
										: ''}
								</FormStaticText>
							</section>
						</section>
					</section>
				</Importscot>
			);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<form>
			<TopLevelStyle>
				{getHeaderForAction()}
				<section className="importscotdata">
					<section className="engagementname-confirmcheck">
						<MotifFormField className="confirmbox">
							<MotifCheckbox
								id="checkbox-example-0"
								value="checkbox-example"
								name="checkbox-example"
								checked={isValid}
								className="confirmation-checkbox"
								onChange={(event) => handleCheck(event.target.checked)}
							>
								{labels.confirmdecline}
							</MotifCheckbox>
						</MotifFormField>
						{(isValid ? '' : initLoad ? '' : 'red') && (
							<section className="import-error">
								<section>
									<SVGICONS styleName="small" id="icon" name="error" />
									<span>{labels.confirmdeclinevalidation}</span>
								</section>
							</section>
						)}
					</section>
				</section>
			</TopLevelStyle>
		</form>
	);
};

export default DeclinePackage;
