/* eslint-disable prettier */

import { Addon } from 'react-bootstrap/lib/InputGroup';

/**
 * Created by calhosh on 4/14/2017.
 * English resource file
 */
export const labels = {
	defaultDropdown: 'Dropdown Menu',
	status: 'Status',
	language: 'Language',
	channel: 'Channel',
	header: 'Header',
	headerName: 'Header name',
	sectionCustomLabelLabel: 'Custom Label',
	sectionNamePlaceHolder: 'Enter Section title',
	showRelatedObjectLabel: 'Show Related Objects',
	errorBanner: '{0} Error(s)',
	engagements: 'Engagements',
	selectedengagements: 'Engagement',
	addEngagement: 'Add engagement',
	archives: 'Archives',
	userInitialsTitle: "User options",
	clickForOptions: "Click for more options",
	comingDue: "Coming due",
	whatsNew: "What's new",
	auditMetrics: "Audit metrics",
	id: 'ID',
	archived: 'Archived',
	myarchives: 'My Archives',
	allarchives: 'All Archives',
	logout: "Log out",
	norecords: 'No records found',
	nomatches: 'No matches found',
	notemplates: 'No templates are available for the selected Area/Region',
	pleasetryagain: 'Please try again',
	firstpage: 'First Page',
	prevpage: 'Previous Page',
	nextpage: 'Next Page',
	lastpage: 'Last Page',
	allengagements: 'All engagements',
	searchPlaceholder: 'Search',
	searchEngagementsPlaceholder: 'Search engagements',
	clearSearch: 'Clear search',
	searchTextPlaceHolder: 'Search',
	show: 'Show',
	manageaccess: 'Manage access',
	restore: 'Restore',
	rollforward: 'Roll forward',
	viewaccesslist: 'View access list',
	teammembers: 'Team members',
	name: 'Name',
	initials: 'Initials',
	role: 'Role',
	usersettings: 'User settings',
	usersettingsnewengagements: 'Settings apply to newly created engagements',
	usersettingsallengagements:
		'Settings apply to all workspaces and engagements',
	enterfirstname: 'Display name (First)',
	enterlastname: 'Display name (Last)',
	firstname: 'First name',
	lastname: 'Last name',
	enter: 'Enter',
	select: 'Select...',
	email: 'Email address',
	contact: 'Phone number',
	accesslist: 'Access list',
	engagementsettings: 'Engagement settings',
	displayname: 'Display name',
	signoffinitials: 'Sign-off initials',
	primaryoffice: 'Primary office',
	roles: 'Role',
	phonenumber: 'Phone number',
	globalapplicationsettings: 'Global application settings',
	displaylanguage: 'Display language',
	preferreddateformat: 'Preferred date format',
	preferrednumberformat: 'Preferred number format',
	save: 'Save',
	cancel: 'Cancel',
	isRequired: '{0} is required',
	arcretrymessage: 'An error has occurred in the archive process. The date of record for the archive is currently {0}.  EY Canvas is automatically retrying your archive, please wait fifteen hours from the time this archive was submitted for the automatic recovery process to complete before contacting the Help Desk.  If the Help Desk can resolve without re-activating the engagement, the archive date will continue to be {0}.  If the Help Desk has to re-activate the engagement for the team to take corrective action, the archive date will be reset to when the team submits the archive again.  If this will cause a late archive, the team should include documentation in the engagement, referencing the Help Desk ticket number, the issue, and the steps taken to resolve in accordance with local archiving policy.',
	ArchiveRetryLink: 'Archive Retry',
	workspacelist: 'Workspace List',
	engagementslist: 'Engagements List',
	accessrequested: 'Access requested',
	noaccess: 'No access',
	independenceOnlyNoAccessMessage: "Your role in the engagement was 'Independence only'. Contact a member of the engagement to grant rights to restore or rollforward the engagement.",
	accessrejected: 'Rejected access',
	requestaccess: 'Request access',
	allarchiveinitialmsg:
		'Enter the Client name, Workspace name or Engagement name to get started',
	noengagementsforusermessage:
		'You do not have any active engagements. Click "Create engagement" to get started.',
	noengagementstoimportto: 'You do not have any engagements to import to.',
	favorites: 'Favorites',
	portfolioview: 'All Engagements v2',
	reviewengagement: 'Review engagements',
	noreviewengagementsmessage: 'You do not have any review engagements.',
	noreviewengagementsmessagecontact: 'You do not have any review engagements. Contact an engagement team member if you require access.',
	nofavoriteengagementsmsg1: 'You do not have any favorite engagements.',
	nofavoriteengagementsmsg2:
		'To add engagements to this view, navigate to All engagements ',
	nofavoriteengagementsmsg3:
		'and click the favorites icon on the engagement card.',
	markasfavorite: 'Mark as favorite',
	unmarkfromfavorites: 'Remove from favorites',
	selectworkspacenoclientworkspaceexists:
		'You do not have any existing workspaces. Click Go back and select "New workspace" from Step 1.',
	importpkgselectworkspacenoclientworkspaceexists:
		'You do not have any existing workspaces. Click back and select "Create new workspace" from the previous step.',
	expirydatelabel: 'This engagement will be deleted in',
	expirydatetodaytomorrowlabel: 'This engagement will be deleted',
	/*Reporting Hub*/
	reportingHub: 'Reporting',
	reportingHubTitle: 'EY Canvas Reporting',
	selectEngagement: 'Select an engagement to view reports',
	connectVPN:
		'Reports can only be accessed when connected to the EY Network or EY Remote Connect',
	reportsNotLoaded:
		'Reports could not be loaded.  Refresh the page and try again.  If the issue persists, contact the Help Desk.',
	searchByEngagementNameOrClientId: 'Select engagement',
	dataExportLabel: 'Data Export',
	summaryScreensLabel: 'Summary Screen',
	projectManagementLabel: 'Project Management',
	engagement: 'Engagement name',
	reportingNotAvailable:
		'Reporting is not available in the selected location. Contact your local EY Canvas Champion further details.',
	client: 'Client',
	workspace: 'Workspace',
	serviceline: 'Service line',
	subserviceline: 'Sub service line',
	engagementstartdate: 'Year end date',
	engagementenddate: 'End date of period covered',
	deleteafter: 'Delete after',
	auditorsreportdate: 'Report date',
	createengagementquestionlabel: 'Create an engagement',
	choosenewexistingquestionheader:
		'Create engagement in a new or existing workspace?',
	createengagementheader: 'Create engagement',
	restoreengagementheader: 'Restore engagement',
	createworkspacestepheader: 'Create new workspace',
	selectexistingworkspacestepheader: 'Select an existing workspace',
	workspacenotelibilefornewengagement: 'Workspace not eligible for new engagements',
	choosenewexistingstepheader: 'New or existing workspace',
	editengagementwizheader: 'Edit engagement',
	removeengagementwizheader: 'Remove engagement',
	editworkspacewizheader: 'Edit workspace',
	editworkspacestepfooter:
		'Click ‘Save & close’ to save changes to workspace or click ‘Save & continue’ to edit an engagement.',
	editwizheader: 'Edit',
	newworkspace: 'New workspace',
	existingworkspace: 'Existing workspace',
	back: 'Back',
	continue: 'Continue',
	saveclose: 'Save & close',
	savecontinue: 'Save & continue',
	finish: 'Finish',
	step01: 'Step 01',
	step02: 'Step 02',
	step03: 'Step 03',
	step04: 'Step 04',
	step05: 'Step 05',
	engagementname: 'Engagement name',
	confirmdecline: 'Confirm decline. This action cannot be undone.',
	confirmdeclinevalidation: 'Confirm that you want to decline.',
	responderror:
		'You have already responded to this invite, refresh the page and try again.',
	engagementcode: 'Engagement code',
	engagementcodename: 'Engagement code name',
	selectEngagementHeader: 'Select an existing workspace',

	selectworkspaceclient: 'Select client',
	selectclient: 'Select a client to view related workspaces',
	createnewworkspace: 'Create new workspace',
	editworkspace: 'Edit workspace',
	clientcode: 'Client code',
	clientname: 'Client name',
	invalidclientcode: 'Invalid client code',
	workspacename: 'Workspace name',
	location: 'Location',
	workspacecodenameplaceholder: 'Enter client code to look up client name',
	engagementcodenameplaceholder: 'Enter engagement code to look up code name',
	isprimary: 'Primary',
	addengagementcode: 'Add engagement code',
	engagementnamerequired: 'Engagement name is required',
	primaryofficerequired: 'Primary office is required',
	languagerequired: 'Language is required',
	servicelinerequired: 'Service line is required',
	subservicelinerequired: 'Sub service line is required',
	engagementcodevalidationmessage:
		'engagement code does not match the client code',
	engagementcoderequired: 'Engagement code is required',
	yearenddate: 'Year end date',
	periodenddate: 'Period end date',
	reportdate: 'Report date',
	complete: 'Complete',
	end: 'End',
	started: 'Started',
	notstarted: 'Not started',
	inprogress: 'In progress',
	inreview: 'In review',
	taskstatus: 'Task Status',
	confirmlabel: 'Confirm',
	submitlabel: 'Submit',
	editengagementquestionlabel: 'Edit engagement',
	removeengagementquestionlabel: 'Remove engagement',
	removeengaementconfirmationmessage1:
		'Are you sure that you want to remove the following engagement from your engagement list? If you remove the engagement you will no longer have access to it. ',
	removeengagementerrormessage:
		'Engagement cannot be removed from the workspace as you are the last active user. Add another team member or delete the engagement. ',
	deleteengagement: 'Delete engagement',
	deletreviewcopyoption: 'Delete',
	deleteengagementconfirmationmessage:
		'Are you sure that you want to submit a request to delete the following engagement? ',
	deleteengagementconfirmationmessage1:
		'For terminated engagements or those where we do not issue a report, engagement teams should consult with Professional Practice to determine whether an archive is required.',
	deleteengagementconfirmationmessageoneuser:
		'Are you sure that you want to delete the following Engagement? ',
	deleteengagementconfirmationmessageoneuser1:
		'The archiving requirements in ',
	deleteengagementconfirmationmessageoneuser2:
		'DOC+ARC 3.1',
	deleteengagementconfirmationmessageoneuser3:
		' should be considered prior to deletion. For example, terminated engagements or those where we do not issue a report may require consultation with Professional Practice to determine whether an archive is required.',
	deleteengagementmarkeddelete: 'Engagement already marked for deletion',
	deleteengagementoption: 'Delete',
	deleteengagementreasonlabel: 'Enter reason for deletion',
	deleteengagementreasonquestion: 'Why is this engagement being deleted?',
	deleteengagementreasonrequired: 'Rationale is required. Select an option or enter a reason above.',
	deleteengagementcommentreasonrequired: 'Rationale is required. Select a reason above.',
	deleteengagementcommentrequiredlabel: 'Please enter reason to delete',
	deletereasontrainingengagementlabel: 'Training engagement',
	deletereasonrestoredorcopiedlabel: 'Restored or copied engagement for reference only',
	deletereasoncreatedbymistakelabel: 'Created by mistake',
	deletereasonotherlabel: 'Other',
	deletereasonterminedbyclientlabel: 'Terminated engagement where no work was performed. If work was performed in this engagement, then it should be archived.',
	markeddeleteconfirm: 'Confirm deletion',
	deleterequestbannertext1: 'Deletion request from ',
	deleterequestbannertext2: ' ago',
	markeddeleteconfirmmessage:
		'Are you sure that you want to delete the following engagement? ',
	markeddeleteconfirmmessage1:
		'If you confirm deletion, this action cannot be undone.',
	markeddeletereasonlabel: 'Reason to delete',
	engagementlistplaceholder: 'Select engagement',
	engagementrequired: 'Engagement is requied',
	restoreconfirmationstepheader: 'Confirmation',
	restoreconfirmationheader:
		'Are you sure that you want to restore the engagement?',
	selectarchiveheader: 'Select archived file',
	restoreengagementstepheader: 'Restore engagement',
	chooserollfwdhdr: 'Roll forward to a new or existing workspace',
	rollfwdstephdrext: 'Roll forward to an existing workspace',
	rollfwdstephdrnew: 'Roll forward to a new workspace',
	rollfwdhdrqstn: 'Roll forward engagement into a new or existing workspace?',
	rollforwardengagementstepheader: 'Roll forward engagement',
	genericerror1:
		'Something went wrong, please try again or contact support team',
	restoreengagementmaxcopies:
		'The engagement has exceeded the maximum number of restored copies ({0}). Request access to an existing copy.',
	restoreengagementengcopies:
		'There are {0} restored copies of this engagement available:',
	restoreengagementengcopy:
		'There is {0} restored copy of this engagement available:',
	restoreengagementengnocopies:
		'There are no other restored copies of this engagement available.',
	globalexceptionrestoreengagementengcopies:
		'There are {0} restored copies of this engagement available. Check the box above to restore with global exception.',
	globalexception: 'Global exception',
	globalexceptioncheckerror: 'Check Global exception option to continue.',
	restoreengagementrestoredon: 'restored on',
	restoreengagementusers: 'users',
	restoreengagementuser: 'user',
	at: 'at',
	arcfileuserunavailable: 'User unavailable',

	restorehdrqstn: 'Restore engagement into a new or existing workspace?',
	chooserestorehdr: 'Restore to new or existing workspace',
	restorestephdrext: 'Restore to an existing workspace',
	restorestephdrnew: 'Restore to a new workspace',

	close: 'Close',
	confirm: 'Confirm',
	settings: 'Settings',
	expand: 'Expand',
	collapse: 'Collapse',
	contextMenuEdit: 'Edit',
	contextMenuEditEngagement: 'Edit engagement',
	contextMenuRemoveEngagement: 'Remove engagement',

	contextMenuDeleteEngagement: 'Delete engagement',
	contextMenuEditWorkspace: 'Edit workspace',
	contextMenuDelete: 'Delete',
	contextMenuOverrideArchiveDate: 'Override archive deadline date',
	na: 'N/A',
	archivesubmittedby: 'Submitted by',
	archivedateandtime: 'Date and time',
	notifications: 'Notifications',
	nonotificationmsg: 'You do not have any notifications.',
	notifcenter: 'Notification center',
	notifitemspendingact: 'items pending action',
	accept: 'Accept',
	reject: 'Reject',
	acceptall: 'Accept All',
	rejectall: 'Reject All',
	notifEngInvite: 'Engagement invites',
	notifengaccesreq: 'Engagement acess request',
	keyevents: 'Key Events',
	milestones: 'Milestones',
	importselecttemplateheader:
		'Please select the template you would like to use',
	importtemplateheader:
		'Please select an area/region you would like to import from',
	import: 'Import',
	importregionstepheader: 'Select Area/Region',
	importtemplatestepheader: 'Select a template',
	importtemplatemsg: 'Select an area/region to continue',
	importselecttemplatemsg: 'Select a template to continue',
	importengagementheader: 'Import template',
	importhdrqstn: 'Import to a new workspace or existing workspace',
	importstephdrext: 'Import to an existing workspace',
	importstephdrnew: 'Import to a new workspace',
	chooseimporthdr: 'Import to a new or existing workspace',
	selectexistingworkspacemsg: 'Select a workspace to continue',
	selectaclienttocontinuemsg: 'Select a client to continue',
	selectengagementmsg: 'Select an engagement to accept the data package',
	selectgaengagementmsg:
		'You must select an engagement to accept the link request',
	arearegion: 'Area/Region',
	importdatapackagequestionlabel: 'What would you like to do?',
	sentby: 'Sent by',
	on: 'on',
	by: 'by',
	view: 'View',
	sourceengagement: 'Source engagement',
	package: 'Package',
	decline: 'Decline',
	delegate: 'Delegate',
	chooseaction: 'Choose an action',
	selectworkspace: 'Select a workspace',
	declinepackage: 'Decline request',
	delegatepackage: 'Delegate package',
	selectengagement: 'Select an engagement',
	datapackagerequest: 'Data package request',
	respond: 'click here to respond',
	deletionrequestfor: 'Deletion request received for',
	invalidengagementcode: 'Invalid engagement code',
	noNotifications: 'No notifications available',
	bellIconTitle: 'Notification center',
	restoredengagement: 'Restored engagement',
	profiledeliveryinprogress: 'Profile delivery in progress',
	completeprofileheader: 'Complete Profile',
	completeindependenceheader: 'Complete Independence',
	independencecompletedheader: 'Independence completed',
	retry: 'Retry',
	errorDate: 'Error',
	invitationDate: 'Date of invitation',
	engCreationFailedWarning:
		'Engagement creation failed.Refresh the page and try again.If the issue persists, contact the Help Desk.',
	deletedEngagementWarning:
		'If the engagement was in use and deleted in error, contact the Help Desk immediately.',
	searchEngagementWarning:
		'Search for these engagements to take further action.',
	archiveOverrideWarning:
		'Archive override period selected is not permitted by ARC. The archive deadline date has been reverted to the default value for your location.',
	archiveOverrideInProgress: 'Archive override pending ARC approval',
	archiveOverrideAccepted: 'Archive override is complete',
	archiveOverrideRejected:
		'Archive override period selected is not permitted by ARC. The archive deadline date has been reverted to the default value for your location.',
	scopeName: 'Scope Name',
	shortName: 'Short Name',
	invited: 'Invited',
	invitedOn: 'Invited on',
	instructions: 'instructions',
	updates: 'updates',
	viewinstructions: 'View instructions',
	viewinstructionsupdates: 'View updates',
	more: 'More',
	addmembers: 'Add Members',
	statusChangeDate: 'Status changed',
	deletedBy: 'Deleted by ',
	deletedDate: 'Deleted',
	deactivatedBy: 'Deactivated by',
	deactivatedDate: 'Deactivated',
	requestUserName: 'Requested by',
	requestDate: 'Requested',
	archiveDate: 'Archived',
	receivedFrom: 'Received from',
	received: 'Received',
	clear: 'Clear',
	clearAll: 'Clear all',
	type: 'Type',
	engagementhasbeendeleted:
		'Selected engagement has been deleted by another user. Refresh the page and try again.',
	independenceOnlyRejectInvite: {
		engagementIsNotAvailable: 'Engagement is not available. Refresh the page and try again. If the issue persists, contact Help Desk.',
		failedToRejectInvite: 'Failed to reject the invite. Refresh the page and try again. If the issue persists, contact Help Desk.',
		succesfulRejection: 'Invite successfully rejected.',
		loadingLabel: "Rejecting invitation..."
	},
	manageTeam: 'Manage team',
	childinprogress: 'Engagement set up in progress',
	// SeeMore component
	showMore: 'Show more',
	showLess: 'Show less',
	showMoreEllipsis: 'Show more...',
	showLessEllipsis: 'Show less...',
	accepted: 'Accepted',
	acceptedOn: 'Accepted on:',
	rejected: 'Rejected',
	deniedBy: 'Denied by ',
	engagementstatusrestored: 'Restored',
	engagementstatusmarkedfordeletion: 'Marked for deletion',
	delegaterequired: 'Delegate required',
	username: 'User name',
	noresults: 'No results found, please try again',
	importdatapackageconcurrencymessage:
		'Status of copy request has changed. Refresh the page and try again.',
	selectdelegateuser: 'Select a delegate',
	eyusersearchplaceholder: 'Enter name or email to search',
	optional: '(Optional)',
	engagementAlerts: 'Engagement alerts',
	engagementMetrics: 'Engagement metrics',
	overdue: 'Overdue',
	/*day labels*/
	mon: 'Mon',
	tues: 'Tues',
	wed: 'Wed',
	thurs: 'Thu',
	fri: 'Fri',
	sat: 'Sat',
	sun: 'Sun',
	canvas: 'EY Canvas',
	overrideArchiveDateModalHeader: 'Override archive deadline date',
	overrideArchiveDateModalMessage:
		'Are you sure that you want to override the archive deadline date?',
	overrideArchiveDateModalComboPlaceholder: 'Override archive deadline date',
	createdOn: 'Created on',
	expiresOn: 'Expires on',
	archiveDeadlineDate: 'Archive deadline date',
	defaultOverriden: 'Default overriden',
	selectexistingworkspace: 'Select existing workspace',
	importtonewworkspace: 'Import to a new workspace',
	groupaudit: 'Group audit link request',
	gaacceptdeclinedelegatequestionlabel:
		'What do you want to do with this link request?',
	gaacceptselectengagementquestionlabel: 'Select an engagement to link',
	gadelegatequestionlabel: 'Delegate group audit link request',
	gadeclinequestionlabel: 'Decline group audit link request',
	garegioncomponent: 'Region/Component',
	gascope: 'Scope',
	gamxTransfer: 'Transfer from GAMx',
	closeNotificationCenter: 'Close notification center',
	contextMenucrp: 'Create review copy',
	workingoffline: 'Working Offline',
	trainingoffline: 'Working offline (training)',
	training: 'Training',
	crpdescription: 'Create a copy of the engagement for external reviewers.',
	crpeditdescritpion: 'Edit engagement name or expiry. Expired engagements will be deleted automatically.',
	expiry: 'Expiry',
	enteremail: 'Enter email address',
	create: 'Create',
	metricsPlaceholderText: 'No data available for the selected time-frame',
	deleteReviewCopy: 'Delete review copy',
	deleteReviewCopyMsg:
		'Are you sure that you want to delete this review copy? Once deleted, the engagement will no longer be available and cannot be recovered.',
	crpNotAvailable:
		'Review copy cannot be created for this engagement because the Canvas External Access Portal is not available at this time. Contact the help desk for further information.',
	externalmember: 'External Members',
	internalmember: 'Internal Members',
	add: 'Add',
	crpEditMembers: 'Edit',
	invalidEmail: 'Enter a valid email address',
	emailNotFound: 'Email address not found',
	dupExternalUser: 'External member already added',
	noExternalUser: 'No external members have been added.',
	expiresIn: 'Expires in',
	days: 'days',
	today: 'today',
	tomorrow: 'tomorrow',
	reviewengagementname: 'Review engagement name',
	enternameoremail: 'Enter name or email address',
	options: 'Options',
	userNotFound: 'User not found',
	remove: 'Remove',
	crpEditHeader: 'Edit review copy',
	globalexceptionrestore: 'Global exception restore',
	expired: 'Expired',
	softwareUpdate: 'Software updates',
	updateNow: "Update now",
	updateMsg: "Software updates are available for EY Canvas. Select Update Now to download and install the updates. The page will be refreshed.",
	preferredlandingpageview: 'Preferred landing page view',
	preferredlandingpagefilter: 'Preferred landing page filter',
	engagementlist: 'Engagement list',
	workspacelistusersettings: 'Workspace list',
	engagementsclientstoload: 'Number of engagements/clients to load each time you access the application',
	favoriteengagements: 'Favorite engagements',
	preferredEngagementDashboard: 'Engagement dashboard settings',
	preferredEngagementDashboardTeamMembers: 'Team members',
	preferredEngagementDashboardExecutives: 'Executives',
	linkedHelixProjects: "Linked to EY Helix",
	loadingLabel: "Loading...",
	loadingErrorText: "An error occurred loading the Timeline. Refresh the page and try again.",
	selectYear: "Select year (required)",
	clientNameOrId: "Client name or ID",
	engagementNameOrId: "Engagement name or ID",
	engagementCodeOrCodeName: "Engagement code or code name",
	searchArchives: "Search",
	clearSearchArchives: "Clear search",
	searchArchivesMessage: "To get started, select the year that the engagement was archived. Then, search by one or more options presented below:",
	titleUpdateProfile: "Information",
	msgUpdateProfileLn1: "You have updated the engagement language. In order to receive content in this language, go to Engagement content updates and select Global content update.",
	sessionTimerMsg1: 'Due to inactivity, your current work session is about to expire.',
	sessionTimerMsg2: 'For your security, EY Canvas sessions end automatically after 30 minutes of inactivity.',
	sessionTimerMsg3: 'Your current session will expire in {0} minutes.',
	sessionTimerMsg4: 'If you wish to remain logged in, please click',
	sessionTimerHeader: 'Automatic logout for your protection',
	theme: 'Theme',
	light: 'Light',
	dark: 'Dark',
	chooseDisplayTheme: 'Choose your default display theme.',
	searchByCountry: 'Select country',
	primaryEngagementLabel: 'Primary engagement',
	componentEngagementLabel: 'Component engagement',
	regionalEngagementLabel: 'Regional engagement',
	linkedLabel: 'Linked',
	eyCanvasEconomicsTooltip: 'EY Canvas Economics',
	eyOversightTooltip: 'EY Canvas Oversight',
	eyPrivacyNoticeTooltip: 'Privacy notice',
	eyTermsOfServiceTooltip: 'Terms of service',
	eyGeneralHelp: 'General Help',

	preferrednegativenumberformat: 'Preferred negative number format',

	protectedEngagementMessage: 'Prevent documents from being printed, copied, or otherwise reproduced',
	documentRrestriction: 'Document restriction: ',
	on: 'On ',
	off: 'Off ',
	protectedEngagementMessageOn: '- Documents in this engagement cannot be printed, copied, or otherwise reproduced',
	protectedEngagementMessageOff: '- Documents in this engagement can be printed, copied, or otherwise reproduced',

	/* Dashboard labels*/
	homeLabel: 'Home',
	manageAccessHeader: 'Manage access',
	showInactiveUserslabel: 'Show inactive users',
	addUserButtonLabel: 'Add user',
	searchUserText: "Search",
	nameHeader: 'Name',
	emailHeader: 'Email',
	actionHeader: 'Action',
	manageRolesLabel: 'Manage roles',
	deactivateUserlabel: 'Deactivate user',
	manageRolesTableModule: 'Module',
	manageRolesTableRoles: 'Roles',
	manageRolesTableServiceLine: 'Service line',
	manageRolesTableCountries: 'Countries',
	manageAccessAssignRole: 'Assign role',
	manageAccessNoRolesAdded: 'No roles added',
	manageAccessDeleteRoleModalHeader: 'Delete role',
	manageAccessDeleteRoleButton: 'Delete',
	noRoleResults: 'No results',
	confirmAssignRoleButton: 'Assign',
	permissionGroupName: 'Group name',
	permissionGroupDescription: 'Group description',
	activeMembers: 'Active members',
	permissionGroupMembersTitle: 'Title',
	permissionGroupMembersPhone: 'Phone',
	permissionGroupMembersGui: 'GUI',
	permissionGroupMembersUserId: 'User ID',
	permissionGroupMembersAdStatus: 'AD status',
	permissionGroupAddMember: 'Add member',
	permissionGroupUserDeleteLabel: 'Delete',
	permissionGroupUserDeleteHeader: 'Delete member',
	permissionGroupDetails: 'Permission groups details',
	cache: 'Cache key',
	cacheSelection: 'Select cache location',
	engagementID: 'Engagement ID',
	cacheKey: 'Cache key',
	validationMessage: 'Required field',
	getCache: 'Get cache',
	clearResult: 'Clear results',
	removeCache: 'Remove cache',
	requiredField: 'Required Field',
	ticketNumber: 'Ticket number (Required)',
	confirmationMessage: 'Please enter a ticket number for this action',
	confirmButtonLabel:'Confirm',
	cancelButtonLabel:'Cancel',
	modalHeaderLabel:'Remove cache',
	adminModuleinvalidEngagementId: 'Invalid engagement ID',
	adminModuleInvalidCacheKey: 'Cache key contains invalid characters',
	clearResults: 'Clear results',
	getDocuments: 'Get documents',
	documentsHeader: 'Documents',
	validTicketNumber: 'Please enter a valid ticketNumber',

	egagementsPageDescription: "Search for engagements based on one or multiple criteria to update engagement data, invite new team members, update existing team member status and permissions.",
	engagementSearchCriteria: "Country or Engagement ID are required to perform the search",
	engagementsHeader: 'Engagement',
	engagementIdSearchPlaceholder: "Engagement ID",
	engagementNameSearchPlaceholder: "Engagement name",
	workSpaceSearchPlaceholder: "Workspace name or ID",
	clientSearchPlaceholder: "Client name or code",
	engagementCodeSearchPlaceholder: "Engagement code or code name",
	userSearchPlaceholder: "User GUI, email or UserID",
	countrySearchPlaceholder: "Country",
	engagementTableHeader: "Engagement",
	nameTableHeader: "Name",
	workspaceIdTableHeader: "Workspace",
	clientTableHeader: "Client",
	engagementCodeTableHeader: "Engagement code",
	yearEndTableHeader: "Year end",
	engagementdeleteDate: "Engagement delete date",
	archivalguid: "Archival GUID",
	archivalLogId: "Archival log ID",
	archivalFileguid: "Archival file GUID",
	profile: "Profile complete",
	independence: "Independence",
	archiveRetry: "Archive retry",
	engagementdetails: "Engagement details",
	engagementsdetails: 'Engagments details',
	statusTableHeader: "Status",
	attributesTableHeader: "Attributes",
	attributesProfileComplete: "Profile Complete",
	attributesIndpendenceApplicable: "Independence applicability",
	engagementPagePlaceholder: "Search for engagements above, results will display here",
	noEngagementFound: "No Engagement found",
	emptySearchFieldError: "At least one search field should have a value.",
	largeCountrySelectionError: "Please select 10 or less countries.",
	saveEngagement: "Save",
	undoEditEngagement: "Reset",
	engagementUpdateSuccess: "Changes have been saved successfully",
	archiveRetrySuccessMessage: "Archive has been successfully initiated for this engagement. Please check status later to validate if the Archiving was successful.",
	welcome: "Welcome to ",
	welcomeadmin: "Admin Tool",
	engagementCard: "Engagements",
	messagingCard: "Messaging",
	templatesCard: "Templates",
	permissionGroupCard: "Permission groups",
	manageAccessCard: "Manage access",
	databaseLocatorCard: "Sync database locator",
	importForReviewTooltip: "Engagement will be optimized for review and some features and functionalities will not be available.",
	importForReviewChecboxText: "Import for review purposes only",
	clientCode: "Client code",
	primaryEngagementCode: "Primary engagement code",
	invalidEngagementTitle: "Invalid client or engagement code",
	invalidEngagementMessage: "Client code and/or engagement code need to be validated before you can access this engagement. Click Continue to update and validate the codes.",
	insufficientPermission: "Insufficient permissions for this module",
	noPermission: "No Permission",
	noAdminRights: "You do not have rights to this tool.",
	//Engagement Users Lables
	engmtUserTableUserNameHeader: "User name",
	engmtUserTableEmailAddressHeader: "Email address",
	engmtUserTableRoleHeader: "Role",
	engmtUserTableGUIeHeader: "GUI",
	engmtUserTableStatuseHeader: "Status",
	engmtUserTableIndependenceStatuseHeader: "Independence",
	engmtUserTableConfidentialityHeader: "Confidentiality",
	engmtUserTableADStatusHeader: "AD status",
	engmtUserTablePermissionsHeader: "Permissions",
	engmtUserTableActionHeader: "Action",
	engagementUsersTitle: "Engagement users",
	engagementInviteMember: "Invite member",
	engagementInviteUser: "Invite user",
	engagementRoles: "Role",
	engagementRolesRequired: "Engagement role required",
	engagementInviteMemberSuccessMessage: "Member has been invited to the engagement successfully",
	engagementInviteMemberDescription: "Search for users by first name, last name, or email address above. Results will display here.",
	engagementUsersTitleEdit: "Edit",
	engagementUpdatePermissionToolTip: "You have insufficient permissions to update the engagement.",
	engagementRetryArchivePermissionToolTip: "You have insufficient permissions to retry archive",
	usersEditPermissionToolTip: "You do not have permission to take this action. Contact Administrator to request permission.",
	usersPermissionToolTip: "You have insufficient permissions to update the engagement user.",
	invalidArchivalGuidMessage: "Invalid archival GUID",
	invalidArchivalFileGuidMessage: "Invalid archival file GUID",
	invalidArchivalLogId: "Invalid archival log id",
	datePickerLabel: "Select a date",
	updateEngagement: "Update Engagement",

	invalidEngagementMessage: "Client code and/or engagement code need to be validated before you can access this engagement. Click Continue to update and validate the codes.",
	invalidEngagementId: "Engagement Id is invalid",
	serverErrorMessage: "Server error, come back later",
	engagementDetailsNoPermission: "You have insufficient permission to view the engagement details.",
	engagementNotFound: "Engagement details not found",
	pageNotFound: "Page Not found",
	assignRoleModalMessage: "Select a module and specific function to be enabled for the user",
	selectModule: "Select Module",
	selectRole: "Select Role",
	serviceLine: "Service Line",
	countries: "Countries",
	confirmAssignRoleButton: 'Assign',
	addUser: "Add user",
	addAdminUserHeader: "Enter first name, last name or email address of user and press enter to search",
	addMemberDescription: "Search for users by first name, last name, or email address above. Results will display here.",
	seachInputRequired: 'Search input required',
	searchUsers: 'Search users',
	noResultsFound: 'No results found',
	usersToBeAdded: 'Users to be added',
	addUserSuccessMessage: 'Admin user added successfully.',
	addMemberSuccessMessage: 'Member has been added to the group successfully',
	deactivateWarning: 'Are you sure you want to deactivate the following user?',
	deactivateButton: 'Deactivate',
	deactivateModalTitle: 'Deactivate user',
	generateReport: 'Generate report',
	generateValidationReport: 'Generate Validation Report',

	templateManagement: 'Templates',
	templateName: 'Template name',
	templateAreaRegion: 'Area/region',
	templateSize: 'Size',
	templateCreatedBy: 'Created by',
	templateCreatedDate: 'Created date',
	templateCountOfEngagement: 'Engagements',
	templateStatus: 'Status',
	templatePublished: 'Published',
	templateUnpublished: 'Unpublished',
	templateSearchText: 'Input Search text and press Enter',
	templateInsufficientPermissions: 'Insufficient permissions to this module',
	templateMultipleArea: 'Multiple',
	templateEdit: 'Edit template',
	templateEditSave: 'Save',
	templateEditCancel: 'Cancel',
	templateEditSuccessMessage: 'Template has been successfully updated.',
	templateDeleteConfirmationMessage: 'Are you sure that you want to delete the Template?',
	templateDelete: 'Delete template',
	templateEditDelete: 'Delete',

	messagingMessageTitle: 'Message title',
	messagingDescriptionTitle: 'Message description',
	messagingStartsOn: 'Starts on (UTC)',
	messagingEndsOn: 'Ends on (UTC)',
	messagingAffectedDataCenters: 'Affected data centers',
	messagingStatus: 'Status',
	messagingCreatedBy: 'Created by',
	messagingCreatedDate: 'Created date',
	messagingDataCenterSystemWide: 'System wide',
	messagingDataCenterMultiple: 'Multiple',
	messagingDataCenterSingle: 'Single',
	messageName: 'Message title',
	messageStartsOn: 'Starts on',
	messageEndsOn: 'Ends on',
	messageDateTime: 'Select date and time',
	messageAllTimeInUTC: 'All times are in UTC',
	messageTargetAudience: 'Target audience',
	messageDataCenters: 'Data centers',
	messageSelectADate: 'Select a Date',
	messageAllDataCenters: 'All data centers',
	messageKendoDescription: 'Use of formatting will reduce the maximum description length of 4,000 characters',
	messageSelectDataCenter: 'Select a data center',
	messageFieldRequired: 'Required field',
	messageStartTimeError: 'Start date and time cannot be less than current time',
	messageEndTimeError1: 'End date and time should not be before current date and time',
	messageEndTimeError2: 'End date and time should be greater than start date and time',
	messageDataCenterError: 'Select at least one data center',
	messageSubmitValidationError: 'Missing required fields. Please scroll and ensure all required fields are populated.',
	messageChangesValidationError: 'No changes have been made, change at least one field in order to Save.',
	currentutc: 'Current UTC date and time :',
	messageCurrentUTCTime: ' {0} (in 24 hours format)',
	createMessage: 'Create message',
	createMessagePermissions: 'Insufficient permissions for this module',
	createMessageSuccess: 'Message created successfully',
	editMessage: 'Edit message',
	editMessageSuccess: 'Message edited successfully',
	editHoverText: "Edit",

	messagingModalDeleteTitle: 'Delete message',
	messagingModalDeleteMessage: 'Are you sure you want to delete the message?',
	messagingModalDeleteButton: 'Delete',
	messagingModalDeleteConfirm: 'Confirm',
	messagingModalDeleteCancel: 'Cancel',
	messagingModalDeletedSuccessfully: 'Message deleted successfully',

	// databaselocator
	databaseLocatorSuccessMessage: 'Database locator sync has been queued',

	// documents module
	docListHeaderDocId: 'Document ID',
	docListHeaderDocVersion: 'Version',
	docListHeaderDocName: 'Document name',
	docListHeaderFileSize: 'File size',
	docListHeaderContentUri: 'Content URI',
	docListHeaderFileExists: 'File exists',
	docListNoResults: 'No documents found',
	docListTotalDocuments: 'Total documents:',
	docListDocIdSearchLabel: 'Document ID',
	docListExportBtn: 'Export',

	// Internal user roles abbr
	picRole: 'PIC',
	adminRole: 'Admin',
	readOnlyRoleForThirdPartyUser: 'Read-only',
	legacy: 'Unrestricted',
	permissionLabel: 'Permission',
	permission: "Permissions",
	active: "Active",
	inactive: "Inactive",
	EngagementUserStatus: "Status",
	IndependenceStatus: "Independence",
	Confidentiality: "Confidentiality",
	EditUserPermissionsMainTitle: "You can edit your permissions",
	EditUserPermissionsInstructions: "It will always be editing other people permissions not yours.",
	PermissionSaveButtonText: "Save",
	PermissionCancelButtonText: "Cancel",
	UnknownCountry: "Unknown",
	engagementNoPermissionMesage: "You have insufficient permissions to the engagement module.",
	cacheRemoveSuccessMessage: "Cache has been successfully removed",
	cacheKeyNotFound: "No result found for the cache key",

	// Portfolio labels
	informationText: "Welcome back,",
	filterEngagements: {
		all: 'All engagements',
		favorite: 'Favorite engagements',
		review: 'Review engagements'
	},
	filterEngagementsLabel: 'Filter engagements',
	engv2noActiveEngagements: 'You do not have any active engagements. Click on the "Add engagement" button to get started.',
	engv2noFavoriteEngagements: 'You do not have any favorite engagements. To add engagements to this view, navigate to All engagements and click the favorites icon on the bottom right corner of the engagement cards.',
	engv2noReviewEngagements: 'You do not have any review engagements. Contact an engagement team member if you require access.',

	// Engagement Progress Labels
	engagementProgressStrategyPhaseLabel: 'Scope & strategy phase',
	engagementProgressExecutionPhaseLabel: 'Execution phase',
	engagementProgressConclusionPhaseLabel: 'Conclusion phase',
	engagementProgressCompletionPercentage: 'complete',
	engagementProgressCompleted: 'Complete',
	engagementProgressNotAvailable: 'Engagement progress will be available when your local datacenter is upgraded',

	// Engagement archive countdown indicator
	EngagementArchiveCountdownIndicator: {
		reportreleasedate: 'Report release date is <b>{0}</b>',
		archivedeadlinedate: 'Archive deadline date is <b>{0}</b>',
		today: 'today',
		tommorow: 'tomorrow',
		overdue: 'overdue',
		reportreleasedays: '<b>{0} days</b> until Report release date',
		archivedeadlinedays: '<b>{0} days</b> until Archive deadline date',
	},
	postArchive: 'Post archive',

	adminTool: 'Admin Tool',
	characters: 'characters',
	placeholder: 'Placeholder',
	ofLabel: 'of',
	kendoTextLimitMessage: 'Maximum characters limit exceeded',
	kendoRequiredMessage: 'Required',
	engv2Errors: {
		noReviewEngagementsPermission: 'You do not have permission to review engagements. Contact an engagement team member if you require access.'
	},
	timeTracking: 'Time Tracker',
	timeTrackingToolMessage: 'This optional tool was specifically created to help you better manage your time as you work on your engagements.',
	timeTrackingToolMessage1: 'Once you start logging time, you can find a summary of collected data for up to 14 days. Data older than 14 days will automatically be removed.',
	timeTrackingToolUpdatedMessage: 'This optional tool helps record how you spend your time. Time logged is retained for a maximum of two weeks and will not be archived.',
	timeTrackerErrorFallBackMessage: 'Time summary cannot be loaded. Refresh the page and try again. If the issue persists, contact the Help Desk.',
	serviceGateway: 'Service Gateway',
	reportinghubDisableMessage: 'Spotfire will be de-commissioned in June 2024. The new EY Canvas reports are accessible from your EY Canvas engagement. After 2024 Release 1, it can be accessed from the More menu. Make sure to update any custom reporting or automations that are dependent on these reports to the new version before June 2024.',
	mobileVersionLabels : {
		canvas : 'Canvas',
		noEngagementFound : 'You do not have any active engagements.</br></br>Access Canvas on your computer to get started.',
		searchEngagementPlaceholder : 'Search...',
		search : 'Search',
		independenceIncomplete: 'Incomplete Independence',
		profileIncomplete: 'Incomplete Profile',
		allEngagements:'All engagements',
		noMatchesFound : 'No matches found</br></br>Please try again',
		inValidUserLogin : 'Access your computer to complete the user settings',
		pagination: {
			pageLabel: "Page",
			ofLabel: "of"
		}
	},
	independenceOnlyStatus: 'Independence only',
	// Adobe Analytics
	aaCookieConsentTitle: 'Welcome to',
	aaCookieContentPrompt: 'Do you want to allow cookies?',
	aaCookieConsentExplanation:
		'<p>In addition to cookies that are strictly necessary to operate this website, we use the following types of cookies to improve your experience and our services: <strong>Functional cookies</strong> to enhance your experience (e.g. remember settings), <strong>Performance cookies</strong> to measure the website\'s performance and improve your experience, <strong>Advertising/Targeting cookies</strong>, which are set by third parties with whom we execute advertising campaigns and allow us to provide you with advertisements relevant to you.</p><p>Review our <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">cookie policy</a> for more information.</p>',
	aaCookieConsentExplanationWithDoNotTrack:
		'<p>In addition to cookies that are strictly necessary to operate this website, we use the following types of cookies to improve your experience and our services: <strong>Functional cookies</strong> to enhance your experience (e.g. remember settings), <strong>Performance cookies</strong> to measure the website\'s performance and improve your experience, <strong>Advertising/Targeting cookies</strong>, which are set by third parties with whom we execute advertising campaigns and allow us to provide you with advertisements relevant to you.</p><p>We have detected that you have enabled the Do Not Track setting in your browser; as a result, Advertising/Targeting cookies are automatically disabled.</p><p>Review our <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">cookie policy</a> for more information.</p>',
	aaCookieConsentDeclineOptionalAction: 'I decline optional cookies',
	aaCookieConsentAcceptAllAction: 'I accept all cookies',
	aaCookieConsentCustomizeAction: 'Customize cookies',
	aaCookieConsentCustomizeURL: 'https://www.ey.com/en_us/cookie-settings',

	// Cookie Settings
	cookieSettings: {
		title: 'Cookie Settings',
		explanation:
			'Please provide your consent for cookie usage on ey.com and the My EY platform. Select one or more of the cookie types listed below, and then save your selection(s). Refer to the listing below for details on the types of cookies and their purpose.',
		emptyCookieListNotice:
			'Cookies from this category are not used in this app',
		nameTableHeader: 'Name of cookie',
		providerTableHeader: 'Cookie provider',
		purposeTableHeader: 'Purpose of cookie',
		typeTableHeader: 'Type of cookie',
		durationTableHeader: 'Duration of cookie',
		formSubmit: 'Save my selection',
		requiredCookieListTitle: 'Required Cookies',
		functionalCookieListTitle: 'Functional Cookies',
		functionalCookieAcceptance: 'I accept the functional cookies below',
		functionalCookieExplanation:
			'Functionality cookies, which allow us to enhance your experience (for example by remembering any settings you may have selected).',
		performanceCookieListTitle: 'Performance Cookies',
		performanceCookieAcceptance: 'I accept the performance cookies below',
		performanceCookieExplanation:
			'Performance cookies, which help us measure the website’s performance and improve your experience. In using performance cookies we do not store any personal data, and only use the information collected through these cookies in aggregated and anonymised form.',
		advertisingCookieListTitle: 'Targeting Cookies',
		advertisingCookieAcceptance:
			'I accept the advertising/targeting cookies below',
		advertisingCookieExplanation:
			'Advertising/targeting cookies, which we use to track user activity and sessions so that we can deliver a more personalized service, and (in the case of advertising cookies) which are set by the third parties with whom we execute advertising campaigns and allow us to provide advertisements relevant to you.',
		doNotTrackNotice:
			'We have detected that you have enabled the Do Not Track setting in your browser; as a result, advertising/targeting cookies are automatically disabled.'
	}, 
};

export const errors = {
	// GenericErrors Codes
	'0005': 'Transaction Pending',
	'0006': 'Transaction Failed',
	'0008': 'Description cannot contain any of the following characters: \\',
	'0010': 'Arc Call Failed',

	0: 'Request failed',
	928: 'Unauthorized Access - You do not have access to the selected option. Contact the Help Desk for instructions on how to gain access, if authorized.',

	1009: 'Data server not found',
	1010: 'Data center not found',
	1011: 'Data center for this country has not been configured',
	1013: 'Country ID or Engagement ID not found',
	1014: 'Country data center not found',
	1016: 'Database locator mapping failed',
	1017: 'oDataQuery cannot be null or empty',
	1018: 'oDataQuery does not Contain any Fields',
	1019: 'Universal datacenter not found',
	1020: 'User does not exist in the system',
	1021: 'Reporting is not available in the selected location. Contact your local EY Canvas Champion further details.',

	// Scope & Strategy 1021 - 1200
	1026: 'Row not found',
	1030: 'Client name not found',

	// Restore Engagement
	1101: "Unable to restore engagement to the country selected. Refresh the page and try again.",

	// Execution   1201-1400
	1219: 'Method not found',
	1234: 'Role ID cannot be null or empty',
	1260: 'The item you are working on has been deleted by another user.',
	1300: 'Invalid request object',
	1301: 'Invalid engagement role',
	1302: 'Invalid user language',
	1303: 'Engagement user details not found',
	1304: 'Invalid location',
	1305: 'Engagement name not found',
	1306: 'Engagement name exceeds the maximum length (255 characters)',
	1307: 'End date of period covered not found',
	1308: 'Invalid date',
	1309: "Auditors' report date not found",
	1310: 'Engagement archival deadline date not found',
	1311: "Engagement archive deadline date cannot be prior to today's date",
	1312: 'Engagement has duplicate engagement codes',
	1313: 'Location not found',
	1314: 'Workspace name not found',
	1315: 'Workspace name exceeds the maximum length (255 characters)',
	1316: 'Client code exceeds the maximum length (50 characters)',
	1317: 'Client name exceeds the maximum length (255 characters)',
	1318: 'Invalid value for the flag to indicate if workspace is for training',
	1319: 'Flag to indicate Workspace is for training not found',
	1320: 'Flag to indicate if client is validated not found',
	1321: 'Invalid value for the flag that indicates if client is validated',
	1322: 'Invalid Client Id',
	1323: 'Engagement codes not found',
	1324: 'Engagement Id not found',
	1325: 'Invalid primary office ID',
	1052: 'Invalid service line ID',
	1053: 'Invalid sub service line ID',
	1326: 'Country ID not found',
	1327: 'Invalid workspace id',
	1328: 'Invalid Engagement Team Member status',
	1329: 'Invalid engagement status',
	1330: 'Engagement has already been deleted by another Team Member',
	1331: 'Year end date not found',
	1339: 'Exceeded maximum number of allowed engagements',
	1340: 'Location details not found',
	1341: 'Engagement data for update not received properly',
	1342: 'No engagement details found for current engagement',
	1343: 'No engagement summary data found for current engagement',
	1344: 'Engagement does not exist for selected ID',
	1359: 'Data Center ID for one of the Active Engagements is invalid',
	1363: 'Creation of Engagement has failed in Universal',
	1364: 'Creation of Engagement has failed in LDC',
	1373: 'Select an existing Workspace or create a new Workspace',
	1374: 'Engagement name already exists. Please enter a new Engagement Name to import the template.',
	1379: 'The content is being refreshed. Try again later. If issue persists for extended time, contact IT Help Desk.',
	1380: 'Archive retry could not be completed. Refresh the page and try again. If the issue persists, contact the Help Desk.',
	1381: 'Create / RF / RS Engagement failed, due to mis-match in country Id in Workspace and engagement.',
	1382: 'Create / RF / RS Engagement failed, due to mis-match in primary office in Workspace and engagement.',
	1384: 'Restore or Roll Forward is already in progress for this engagement. Please try again later.',
	1386: 'Workspace not eligible for new engagements. Refresh the page and try again. If the issue persists contact the Help Desk.',
	1385: 'Engagement cannot be created in the selected location. Refresh the page and try again. If the issue persists, contact the Help Desk.',
	// Invite Team Members (1401 - 1500)
	1403: 'Engagement welcome message exceeds the maximum length (200 characters)',
	1406: 'No Engagement collections found',
	1424: 'Engagement or user details not found',
	1425: 'Engagement Details Not Found',

	// User details Error Codes (1426 - 1434)
	1426: 'First name is required',
	1427: 'Last name is required',
	1428: 'Initials are required',
	1429: 'Primary office is required',
	1430: 'Preferred phone number is required',
	1431: 'Language is required',
	1432: 'Preferred date format is required',
	1433: 'Preferred number format is required',
	1434: 'User details cannot be null or empty',
	1436: 'Conflict detected. If the issue persists, contact the Help Desk.',
	1438: 'Users collection cannot be empty.',
	1448: 'The selected country has not been updated to the latest version of EY Canvas. Consult EY Atlas for the deployment timeline and retry after the update has occurred. If the issue persists contact the Help Desk.',

	1502: 'Unable to complete the operation at this time. Refresh the page and try again. If the issue persists contact the Help Desk.',
	1504: 'Unable to complete the operation at this time. Refresh the page and try again. If the issue persists, contact the Help Desk.',
	1505: 'Invalid input',

	// Common control errors (1601 - 1700)
	1601: 'Invalid percentage',
	18000: 'ARC Processing Error: The requested operation cannot be performed since ARC is undergoing maintenance. Please try later.',
	18306: 'ARC Processing Error: ARC Form related to Canvas ArchivalGUID cannot be found',
	18307: 'ARC Processing Error: The engagement has been deleted in ARC. You cannot proceed with the selected option. Contact your ARC Admin if you need further assistance.',
	18309: 'ARC Processing Error: The requested operation cannot be performed since the archived engagement is under regulatory restrictions. Contact your ARC Admin if you need further assistance.',
	18320: 'ARC Processing Error: ARC Form is in Hold (Suspended) state and cannot be modified.',
	18327: 'ARC Processing Error: ARC Form is in Hold State for Delete status and cannot be modified.',
	18328: 'ARC Processing Error: The requested operation cannot be performed since the engagement is marked for deletion in ARC. Contact your ARC Admin if you need further assistance.',
	18329: 'ARC Processing Error: This engagement is not accessible to Partners. Contact your ARC Admin if you need further assistance.',
	18330: 'ARC Processing Error: The requested operation cannot be performed since the engagement is locked due to regulatory restrictions. Contact your ARC Admin if you need further assistance.',
	2027: 'Bad Request',
	2046: 'Unauthorized',
	2047: 'Forbidden',
	2048: 'Record not found',
	2049: 'Request entity too large',
	2052: 'Not acceptable',
	2053: 'An error occurred while retrieving data. Refresh the page and try again. If the issue persists, contact the Help Desk.',
	2056: 'Generic error occurred',
	2057: 'Service unavailable',

	// Roll Forward Error Codes (2301-2400)
	2301: 'During roll forward the archived file was not found',
	2302: 'During roll forward get archive reference call failed',
	2303: 'During roll forward the returned archived file has empty unique identifier',
	2304: 'During roll forward the returned archived file has empty file store URL',
	2305: 'During roll forward the returned archived file has empty hash value',
	2306: 'User is not authorized to perform this action',
	2307: 'The allowed number of restored copies has been exceeded',

	// Link Error Codes
	2568: 'The link is no longer available as you are not the current Point Of Contact. Please refresh the page.',
	3005: 'Primary team has already deleted the Component from the Group Engagement',
	2571: 'The link request has been updated. Please refresh the page.',

	// Error for v1 engagement not supported features
	4001: "The selected Engagement was created in an older version of EY Canvas and cannot be restored until the selected location's local data center has been upgraded to the newest version of EY Canvas. Contact your local Help Desk for further information.",
	4002: 'The selected Engagement was created in an older version of EY Canvas and request access is not available until your local data center has been upgraded to the newest version. Contact your local Help Desk for further information.',
	4214: 'Template cannot be imported to a workspace in the selected location. Select a different location and try again',

	// Common framework error
	4205: 'Copy Request Details Not Found',
	401000: 'Network change is detected and please reload the page to continue',

	// CopyHub concurrency scenarios
	4217: 'The status of the data package request has changed. Refresh the page and try again.',
	4219: 'Copies between different versions of EY Canvas are not supported this release due to enhancements made to the copy process. Review EY Atlas for details for the deployment schedule. After your data center has been upgraded, a copy can be made.',
	4220: 'The template version is not supported for this country.',
	4221: 'The template version is not supported for this country.',
	4222: 'Import cannot be completed until the destination location has been upgraded to the latest version of EY Canvas.  Select another location to continue.  Contact the Help Desk if the issue persists.',

	// Error for Mobility API
	4501: 'No tasks are available in the Engagement',
	4502: 'No To-do tasks are available in the Engagement',

	// Counrty API version error
	5001: "The selected location has an older version of EY Canvas and an engagement cannot be rolled forwarded to a new workspace until the selected location's local data center has been upgraded to the newest version of EY Canvas. Contact your local Help Desk for further information",

	//CRP checks
	5101: 'Review copy cannot be created for this engagement because the Canvas External Access Portal is not available at this time. Contact the help desk for further information.',
	5102: 'CRP copy is already in progress for this engagement. Please try again later.',
	5103: 'Review copy cannot be edited for this engagement because the Canvas External Access Portal is not available at this time. Contact the help desk for further information.',
	5104: 'Document restriction is not available for this engagement. Uncheck option and try again.',

	18100: 'Service Line not found.',
	18101: 'Sub service line not found.',

	//RBAC realted error messages.
	935: 'Insufficient permissions to perform this action. Contact an engagement administrator and request permission to take this action.',
	8001: 'Unable to complete the operation at this time.Refresh the page and try again.If the issue persists, contact the Help Desk.',
	336699: 'Time summary cannot be loaded. Refresh the page and try again. If the issue persists, contact the Help Desk.',

	//Concurrency errors
	7821:'You have already responded to this invite, refresh the page and try again.'
};

export const adminErrors = {
	1332: 'Error while triggering archive retry',
	1501: 'User is inactive. Refresh page and try again',
	156: 'Insufficient permissions to this module',
	70740: 'Changes cannot be saved. Engagement must have at least one active member who has permissions to administer engagement and has resolved independence. If the issue persists, contact the Help Desk.',
	70790: 'Domain is not allowed. Try again and if the issue persists, contact the Help Desk.',
	70800: 'Invalid user to invite third party User.',
	70810: 'Invalid/Inactive RBAC roles selected for third party user.',
	70820: 'Select valid RBAC role for third party user.',
	70830: 'Select read only role for third party user.',
};

export const actionTypes = {
	1: 'System messages',
	2: 'Engagement invites',
	3: 'Denied access',
	4: 'Engagement delete requests',
	5: 'Engagement creation error',
	6: 'Engagement access request',
	7: 'Deleted engagements',
	8: 'Archived engagements',
	9: 'Deactivated engagements',
	10: 'Archive deadline date override',
	11: 'Group link requests',
	12: 'Group instructions received',
	13: 'Copies received',
	14: 'Engagement in-progress'
};
export const timephaseType = {
	'-1': 'End date of period covered',
	'-2': 'Report date',
	'-3': 'Archive deadline date',
	'1': 'Scope',
	'2': 'Strategy',
	'3': 'Walkthroughs',
	'4': 'Interim Control Testing',
	'5': 'Interim Substantive Testing',
	'6': 'Walkthroughs (year-end processes)',
	'7': 'Year-end Control Testing',
	'8': 'Year-end Substantive Testing',
	'9': 'Conclusion',
	'10': 'N/A',
	'11': 'Execution',

	'12': 'Scope and strategy quality activities',

	'13': 'Other scope and strategy',
	'14': 'CRA',
	'15': 'Risk assessment and materiality',
	'16': 'Finalize scope and strategy',
	'17': 'Post-interim project management'
};
export const dataExport = [
	'Archive Form',
	'Canvas Forms',
	'Collaboration',
	'Control Forms',
	'Documents',
	'Tasks',
	'Team Members',
	'Trial Balance'
];
export const dataExportReportNames = {
	'Archive Form': 'Archive Form',
	'Canvas Forms': 'Canvas Form Report',
	'Collaboration': 'Collaboration',
	'Control Forms': 'Control Report',
	Documents: 'All Documents Report',
	Tasks: 'All Tasks Report',
	'Team Members': 'Team Members',
	'Trial Balance': 'Numbers Report'
};
export const projectManagement = [
	'Collaboration Details',
	'Digital scorecard',
	'Milestones',
	'Review Notes',
	'Validation Checks'
];
export const projectManagementReportNames = {
	'Collaboration Details': 'Client Collaboration Dashboard',
	Milestones: 'Milestone Report',
	'Review Notes': 'Review Notes Report',
	'Validation Checks': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	'Audit Plan Report',
	'Audit Plan with Related Evidence',
	'Controls Review Tool (CRT)',
	'CRA Summary',
	'Findings',
	'IT App & SO Summary',
	'SCOT Summary',
	'SEM'
];
export const summaryScreenDefaultsReportNames = {
	'Audit Plan Report': 'Audit Plan Report',
	'Audit Plan with Related Evidence': 'Audit Plan with Related Evidence',
	'Controls Review Tool (CRT)': 'Controls Review Tool (CRT)',
	'CRA Summary': 'CRA Summary',
	Findings: 'Findings',
	'IT App & SO Summary': 'ITSO CRT',
	'SCOT Summary': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenInterim = [
	'Work Plan Report',
	'Work Plan with Related Evidence'
];
export const summaryScreenInterimReportNames = {
	'Work Plan Report': 'Audit Plan Report',
	'Work Plan with Related Evidence': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	'Audit Plan Report',
	'Audit Plan with Related Evidence',
	'Controls Review Tool (CRT)',
	'CRA Summary',
	'Findings',
	'Group Instruction Summary',
	'Group Report',
	'Group Structure Summary',
	'IT App & SO Summary',
	'SCOT Summary',
	'SEM'
];
export const summaryScreenGroupAuditReportNames = {
	'Audit Plan Report': 'Audit Plan Report',
	'Audit Plan with Related Evidence': 'Audit Plan with Related Evidence',
	'Controls Review Tool (CRT)': 'Controls Review Tool (CRT)',
	'CRA Summary': 'CRA Summary',
	Findings: 'Findings',
	'Group Instruction Summary': 'Group Instruction Summary',
	'Group Report': 'Group Report',
	'Group Structure Summary': 'Group Structure Summary',
	'IT App & SO Summary': 'ITSO CRT',
	'SCOT Summary': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenGroupAuditInterim = [
	'Group Instruction Summary',
	'Group Structure Summary',
	'Work Plan Report',
	'Work Plan with Related Evidence'
];
export const summaryScreenGroupAuditInterimReportNames = {
	'Group Instruction Summary': 'Group Instruction Summary',
	'Group Structure Summary': 'Group Structure Summary',
	'Work Plan Report': 'Audit Plan Report',
	'Work Plan with Related Evidence': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [
	{
		id: 8,
		label: 'Staff'
	},
	{
		id: 7,
		label: 'Senior'
	},
	{
		id: 6,
		label: 'Manager'
	},
	{
		id: 5,
		label: 'Senior Manager'
	},
	{
		id: 3,
		label: 'Executive Director'
	},
	{
		id: 4,
		label: 'Principal'
	},
	{
		id: 11,
		label: 'Other Partner'
	},
	{
		id: 2,
		label: 'Engagement Partner'
	},
	{
		id: 1,
		label: 'Partner in Charge'
	},
	{
		id: 10,
		label: 'Engagement Quality Reviewer'
	},
	{
		id: 17,
		label: 'Audit Quality Reviewer'
	},
	{
		id: 16,
		label: 'General Counsel'
	},
	{
		id: 12,
		label: 'GDS - Staff'
	},
	{
		id: 30,
		label: 'GDS - Senior'
	},
	{
		id: 31,
		label: 'GDS - Manager'
	}
];
export const roleType = {
	'11': 'Other Partner',
	'24': 'Other (e.g. due diligence review)',
	'15': 'Executive Support Services',
	'18': 'ML Component Team',
	'17': 'Audit Quality Reviewer',
	'1': 'Partner in Charge',
	'2': 'Engagement Partner',
	'9': 'Intern',
	'13': 'Advisory (FAIT, TAS, Human Capital or Other)',
	'14': 'Tax',
	'27': 'Industry',
	'10': 'Engagement Quality Reviewer',
	'25': 'Office',
	'8': 'Staff',
	'20': 'Client Staff',
	'19': 'Client Supervisor',
	'5': 'Senior Manager',
	'16': 'General Counsel',
	'26': 'Area',
	'23': 'Regulator',
	'22': 'Internal Audit Staff',
	'3': 'Executive Director',
	'21': 'Internal Audit Supervisor',
	'6': 'Manager',
	'4': 'Principal',
	'7': 'Senior',
	'12': 'Global Talent Hub',
	'28': 'National',
	'29': 'Global',
	'12': 'GDS - Staff',
	'30': 'GDS - Senior',
	'31': 'GDS - Manager'
};
export const copyObjectGroupType = {
	1: 'Engagement',
	2: 'Accounts',
	3: 'SCOTs',
	4: 'IT Applications & Service Organizations',
	5: 'Requests',
	6: 'Evidence',
	7: 'Numbers',
	8: 'Requests and External Tasks',
	9: 'Temporary Files',
	10: 'Prior Period Evidence',
	11: 'Recently Deleted Files',
	12: 'History Files',
	13: 'Engagement Template',
	14: 'Restore GCO Copy',
	15: 'External Review Copy',
	16: 'Active Engagement External Review',
	17: 'Canvas Forms',
	20: 'Evidence',
	21: 'Engagement',
	22: 'Accounts',
	23: 'SCOTs',
	24: 'Engagement roll forward',
	27: 'External Evidences',
	28: 'Guided Workflows',
};
export const EngagementStatusText = [
	'Undefined',
	'Active',
	'Suspended',
	'Archived',
	'Deleted',
	'ArchiveInProgress',
	'Reactivated',
	'Restored',
	'MarkedForDeletion',
	'ArchiveError',
	'RollForwardError',
	'ReactivateError',
	'RestoreError',
	'RollForwardInProgress',
	'ReactivateInProgress',
	'RestoreInProgress',
	'GamxMigrationInProgress',
	'GamxMigrationError',
	'CreateTransactionPending',
	'CreateTransactionFailed',
	'UpdateTransactionPending',
	'UpdateTransactionFailed',
	'CopyInProgress',
	'CopyError',
	'CopyTemplateError',
	'ExternalReviewEngagementInProgress',
	'ExternalReviewEngagementError',
	'ExternalReviewEngagement'
];
export const overdueIssues = {
	partnerInvitationPending: 'Partner Invitation pending',
	eqrInvitationPending: 'EQR Invitation pending',
	invalidEngagementCode: 'Invalid engagement code',
	overdueArchiveDeadline: 'Archive deadline date overdue',
	upcomingArchiveDeadline: 'Upcoming archive deadline',
	contentDeliveryInProgress: 'Content delivery in progress',
	overdueMilestones: 'Team milestones overdue',
	overduePartnerTasks: 'Partner tasks overdue',
	overdueEqrTasks: 'EQR Tasks overdue'
};
export const engagementMetricNames = [
	{ propName: 'preparer', value: 'To prepare' },
	{ propName: 'forMyReview', value: 'To review' },
	{ propName: 'upcomingReview', value: 'Upcoming review' },
	{ propName: 'reviewNotesAssigned', value: 'Notes for me' },
	{ propName: 'reviewNotesAuthored', value: 'Notes by me' },
	{ propName: 'clientRequests', value: 'Requests' },
	{ propName: 'timephases', value: 'Time phases' },
	{ propName: 'groupTasks', value: 'Group tasks' },
	{ propName: 'groupDeliverables', value: 'Group deliverables' },
	{ propName: 'automationDocuments', value: 'Automation' }
];
export const metricFilterComingDue = [
	{ value: 1, label: 'Coming due tomorrow' },
	{ value: 3, label: 'Coming due next 3 days' },
	{ value: 5, label: 'Coming due next 5 days', isDefault: true },
	{ value: 10, label: 'Coming due next 10 days' },
	{ value: 30, label: 'Coming due next 30 days', isMax: true }
];
export const metricFilterWhatsNew = [
	{ value: 1, label: "What's new yesterday" },
	{ value: 3, label: "What's new last 3 days" },
	{ value: 5, label: "What's new last 5 days", isDefault: true },
	{ value: 10, label: "What's new last 10 days", isMax: true }
];

export const type1Alerts = [
	"hasPartnerInvited",
	"hasEqrInvited",
	"hasValidEngagementCode",
	"overdueArchiveDeadline"
];

export const engagementStatuses = {
	1: 'Active',
	2: 'Suspended',
	3: 'Archived',
	4: 'Deleted',
	5: 'Archive in-progress',
	6: 'Reactivated',
	7: 'Restored',
	8: 'Marked for Deletion',
	9: 'Archive Error',
	10: 'Roll Forward Error',
	11: 'Reactivate Error',
	12: 'Restore Error',
	13: 'Roll forward in-progress',
	14: 'Reactivate in-progress',
	15: 'Restore in-progress',
	16: 'GAMX Migration in-progress',
	17: 'GAMX Migration Error',
	18: 'Create Transaction Pending',
	19: 'Create Transaction Failed',
	20: 'Update Transaction Pending',
	21: 'Update Transaction Failed',
	22: 'Copy in-progress',
	23: 'Copy Error',
	24: 'Copy Template Error',
	25: 'External Review Engagement in-progress',
	26: 'External Review Engagement Error',
	27: 'External Review Engagement',
	28: 'Localized to Isolated Universal',
	29: 'Localized to Isolated Universal Archived',
	30: 'Content Delivery in-progress',
	31: 'Content Delivery Error',
	32: 'Content Delivery Fail',
	33: 'Canvas External Access Portal V2',
	34: 'Canvas External Access Portal V2 in-progress',
	35: 'Canvas External Access Portal V2 Error',
	100: 'Datacenter Split'
};
export const overrideArchiveDeadlineDateOptions = [
	{ value: 0, label: 'Default' },
	{ value: 10, label: '10 Days' },
	{ value: 14, label: '14 Days' },
	{ value: 20, label: '20 Days' },
	{ value: 21, label: '21 Days' },
	{ value: 25, label: '25 Days' },
	{ value: 30, label: '30 Days' },
	{ value: 45, label: '45 Days' }
];
export const ExternalReviewCopyExpiryDays = [
	{ value: 30, label: '30 days' },
	{ value: 60, label: '60 days' },
	{ value: 90, label: '90 days' },
	{ value: 180, label: '180 days' }
];

export const serviceLines = [
	{
		servicelineid: 1,
		servicelinename: 'Assurance'
	},
	{
		servicelineid: 3,
		servicelinename: 'Strategy and Transactions'
	},
	{
		servicelineid: 2,
		servicelinename: 'Consulting'
	},
	{
		servicelineid: 4,
		servicelinename: 'Tax'
	},
	{
		servicelineid: 5,
		servicelinename: 'GCO'
	}
];

export const subServiceLines = [
	{
		subservicelineid: 1,
		servicelineid: 1,
		subservicelinename: 'Audit'
	},
	{
		subservicelineid: 2,
		servicelineid: 1,
		subservicelinename: 'FIDS'
	},
	{
		subservicelineid: 3,
		servicelineid: 2,
		subservicelinename: 'ITRA'
	},
	{
		subservicelineid: 9,
		servicelineid: 1,
		subservicelinename: 'FAAS'
	},
	{
		subservicelineid: 10,
		servicelineid: 1,
		subservicelinename: 'ACR'
	},
	{
		subservicelineid: 11,
		servicelineid: 1,
		subservicelinename: 'CCaSS'
	},
	{
		subservicelineid: 4,
		servicelineid: 3,
		subservicelinename: 'Capital Transformation'
	},
	{
		subservicelineid: 5,
		servicelineid: 3,
		subservicelinename: 'Transaction Support'
	},
	{
		subservicelineid: 6,
		servicelineid: 2,
		subservicelinename: 'Performance Improvement'
	},
	{
		subservicelineid: 7,
		servicelineid: 2,
		subservicelinename: 'Risk'
	},
	{
		subservicelineid: 7,
		servicelineid: 23,
		subservicelinename: 'Business Consulting'
	},
	{
		subservicelineid: 24,
		servicelineid: 6,
		subservicelinename: 'Other'
	},
	{
		subservicelineid: 8,
		servicelineid: 2,
		subservicelinename: 'Other'
	},
	{
		subservicelineid: 12,
		servicelineid: 4,
		subservicelinename: 'BTS'
	},
	{
		subservicelineid: 13,
		servicelineid: 4,
		subservicelinename: 'Human Capital'
	},
	{
		subservicelineid: 14,
		servicelineid: 4,
		subservicelinename: 'Law'
	},
	{
		subservicelineid: 15,
		servicelineid: 4,
		subservicelinename: 'Indirect Tax'
	},
	{
		subservicelineid: 16,
		servicelineid: 4,
		subservicelinename: 'GCR'
	},
	{
		subservicelineid: 17,
		servicelineid: 4,
		subservicelinename: 'Transaction Tax'
	},
	{
		subservicelineid: 18,
		servicelineid: 4,
		subservicelinename: 'ITS'
	},
	{
		subservicelineid: 19,
		servicelineid: 5,
		subservicelinename: 'GCO'
	},
	{
		subservicelineid: 20,
		servicelineid: 2,
		subservicelinename: 'Business Consulting'
	},
	{
		subservicelineid: 21,
		servicelineid: 2,
		subservicelinename: 'Technology Consulting'
	},
	{
		subservicelineid: 22,
		servicelineid: 3,
		subservicelinename: 'Transactions and Corporate Finance'
	},
	{
		subservicelineid: 23,
		servicelineid: 3,
		subservicelinename: 'EY Parthenon'
	},
	{
		subservicelineid: 25,
		servicelineid: 1,
		subservicelinename: 'Technology Risk'
	}
];

export const modules = [
	{
		id: 1,
		name: 'Manage access'
	},
	{
		id: 2,
		name: 'Engagements'
	},
	{
		id: 3,
		name: 'Permission Groups'
	},
	{
		id: 4,
		name: 'Templates'
	},
	{
		id: 5,
		name: 'Messaging'
	},
	{
		id: '6',
		name: 'Cache'
	},
	{
		id: 7,
		name: 'Database Locator'
	},
	{
		id: 8,
		name: 'Documents'
	}
];

export const roles = [
	{
		id: 17,
		ModuleId: 1,
		name: 'Read only'
	},
	{
		id: 18,
		ModuleId: 1,
		name: 'Add/remove user and permissions'
	},
	{
		id: 19,
		ModuleId: 2,
		name: 'Read only'
	},
	{
		id: 20,
		ModuleId: 2,
		name: 'Update engagement'
	},
	{
		id: 21,
		ModuleId: 2,
		name: 'Invite team member'
	},
	{
		id: 22,
		ModuleId: 2,
		name: 'Update engagement user'
	},
	{
		id: 23,
		ModuleId: 3,
		name: 'Read only'
	},
	{
		id: 24,
		ModuleId: 3,
		name: 'Add/remove member'
	},
	{
		id: 25,
		ModuleId: 3,
		name: 'Sync DatabaseLocator'
	},
	{
		id: 26,
		ModuleId: 4,
		name: 'Read only'
	},
	{
		id: 27,
		ModuleId: 4,
		name: 'Update template'
	},
	{
		id: 28,
		ModuleId: 4,
		name: 'Delete template'
	},
	{
		id: 29,
		ModuleId: 5,
		name: 'Read only'
	},
	{
		id: 30,
		ModuleId: 5,
		name: 'Create/update message'
	},
	{
		id: 31,
		ModuleId: 5,
		name: 'Delete message'
	},
	{
		id: 32,
		ModuleId: 6,
		name: 'Read and Delete Cache'
	},
	{
		id: 33,
		ModuleId: 8,
		name: 'Access Documents'
	},
	{
		id: 34,
		ModuleId: 2,
		name: 'Archive retry'
	}

];

export const countries = {
	1: "Mayotte",
	2: "British Virgin Islands",
	3: "Spain",
	4: "Belize",
	5: "Peru",
	6: "Slovakia",
	7: "Venezuela",
	8: "Norway",
	9: "Falkland Islands (Malvinas)",
	10: "Mozambique",
	11: "Mainland China",
	12: "Sudan",
	13: "Israel",
	14: "Belgium",
	15: "Saudi Arabia",
	16: "Gibraltar",
	17: "Guam",
	18: "Norfolk Islands",
	19: "Zambia",
	20: "Reunion",
	21: "Azerbaijan",
	22: "Saint Helena",
	23: "Iran",
	24: "Monaco",
	25: "Saint Pierre and Miquelon",
	26: "New Zealand",
	27: "Cook Islands",
	28: "Saint Lucia",
	29: "Zimbabwe",
	30: "Iraq",
	31: "Tonga",
	32: "American Samoa",
	33: "Maldives",
	34: "Morocco",
	35: "International Standards on Auditing (ISA)",
	36: "Albania",
	37: "Afghanistan",
	38: "Gambia",
	39: "Burkina Faso",
	40: "Tokelau",
	41: "Libya",
	42: "Canada",
	43: "United Arab Emirates",
	44: "Korea, Democratic People's Republic of",
	45: "Montserrat",
	46: "Greenland",
	47: "Rwanda",
	48: "Fiji",
	49: "Djibouti",
	50: "Botswana",
	51: "Kuwait",
	52: "Madagascar",
	53: "Isle of Man",
	54: "Hungary",
	55: "Namibia",
	56: "Malta",
	57: "Jersey",
	58: "Thailand",
	59: "Saint Kitts and Nevis",
	60: "Bhutan",
	61: "Panama",
	62: "Somalia",
	63: "Bahrain",
	64: "Bosnia and Herzegovina",
	65: "France",
	66: "Korea, Republic of",
	67: "Iceland",
	68: "Portugal",
	69: "Tunisia",
	70: "Ghana",
	71: "Cameroon",
	72: "Greece",
	73: "French Southern Territories",
	74: "Heard and McDonald Islands",
	75: "Andorra",
	76: "Luxembourg",
	77: "Samoa",
	78: "Anguilla",
	79: "Netherlands",
	80: "Guinea-Bissau",
	81: "Nicaragua",
	82: "Paraguay",
	83: "Antigua and Barbuda",
	84: "International Financial Reporting Standard (IFRS)",
	85: "Niger",
	86: "Egypt",
	87: "Vatican City State",
	88: "Latvia",
	89: "Cyprus",
	90: "US Minor Outlying Islands",
	91: "Russia Other Legal Entities",
	92: "Saint Vincent and the Grenadines",
	93: "Guernsey",
	94: "Burundi",
	95: "Cuba",
	96: "Equatorial Guinea",
	97: "British Indian Ocean Territory",
	98: "Sweden",
	99: "Uganda",
	100: "North Macedonia",
	101: "Swaziland",
	102: "El Salvador",
	103: "Kyrgyzstan",
	104: "Ireland",
	105: "Kazakhstan",
	106: "Honduras",
	107: "Uruguay",
	108: "Georgia",
	109: "Trinidad and Tobago",
	110: "Palestinian Authority",
	111: "Martinique",
	112: "Aland Islands",
	113: "French Polynesia",
	114: "Ivory Coast",
	115: "Montenegro",
	116: "South Africa",
	117: "South Georgia and the South Sandwich Islands",
	118: "Yemen",
	119: "Hong Kong",
	120: "Kenya",
	121: "Chad",
	122: "Colombia",
	123: "Costa Rica",
	124: "Angola",
	125: "Lithuania",
	126: "Syria",
	127: "Malaysia",
	128: "Sierra Leone",
	129: "Serbia",
	130: "Poland",
	131: "Suriname",
	132: "Haiti",
	133: "Nauru",
	134: "Sao Tome and Principe",
	135: "Svalbard and Jan Mayen",
	136: "Singapore",
	137: "Moldova",
	138: "Taiwan",
	139: "Senegal",
	140: "Gabon",
	141: "Mexico",
	142: "Seychelles",
	143: "Micronesia, Federated States of",
	144: "Algeria",
	145: "Italy",
	146: "San Marino",
	147: "Liberia",
	148: "Brazil",
	149: "Croatia",
	150: "Faroe Islands",
	151: "Palau",
	152: "Finland",
	153: "Philippines",
	154: "Jamaica",
	155: "French Guiana",
	156: "Cape Verde",
	157: "Myanmar",
	158: "Lesotho",
	159: "US Virgin Islands",
	160: "Cayman Islands",
	161: "Niue",
	162: "Togo",
	163: "Belarus",
	164: "Dominica",
	165: "Indonesia",
	166: "Uzbekistan",
	167: "Nigeria",
	168: "Wallis and Futuna",
	169: "Barbados",
	170: "Sri Lanka",
	171: "United Kingdom",
	172: "Ecuador",
	173: "Guadeloupe",
	174: "Laos",
	175: "Jordan",
	176: "Solomon Islands",
	177: "East Timor",
	178: "Lebanon",
	179: "Central African Republic",
	180: "India",
	181: "Christmas Island",
	182: "Vanuatu",
	183: "Brunei",
	184: "Bangladesh",
	185: "Antarctica",
	186: "Bolivia",
	187: "Turkey",
	188: "Bahamas",
	189: "Comoros",
	190: "Western Sahara",
	191: "Czech Republic",
	192: "Ukraine",
	193: "Estonia",
	194: "Bulgaria",
	195: "Mauritania",
	196: "Congo, The Democratic Republic of the",
	197: "Liechtenstein",
	198: "Pitcairn",
	199: "Denmark",
	200: "Marshall Islands",
	201: "Japan",
	202: "Austria",
	203: "Oman",
	204: "Mongolia",
	205: "Tajikistan",
	206: "Switzerland",
	207: "Guatemala",
	208: "Eritrea",
	209: "Nepal",
	210: "Mali",
	211: "Slovenia",
	212: "Northern Mariana Islands",
	213: "(Not Applicable)",
	214: "Aruba",
	215: "Congo",
	216: "Qatar",
	217: "Guinea",
	218: "United States",
	219: "Ethiopia",
	220: "Other",
	221: "Guyana",
	222: "Germany",
	223: "Bermuda",
	224: "Turks and Caicos Islands",
	225: "Australia",
	226: "Kiribati",
	227: "Puerto Rico",
	228: "Pakistan",
	229: "Mauritius",
	230: "Malawi",
	231: "Turkmenistan",
	232: "Cambodia",
	233: "Chile",
	234: "New Caledonia",
	235: "Papua New Guinea",
	236: "Bouvet Island",
	237: "Tuvalu",
	238: "Curacao",
	239: "Dominican Republic",
	240: "Vietnam",
	241: "Cocos (Keeling) Islands",
	242: "Grenada",
	243: "Tanzania",
	244: "Argentina",
	245: "Macao",
	246: "Benin",
	247: "Romania",
	248: "Armenia",
	249: "global",
	250: "IFRS for SMEs",
	251: "US GAAP",
	252: "AICPA financial reporting framework for small and medium sized entities",
	253: "South Sudan",
	254: "Kosovo",
	255: "United States - Government",
	256: "Russia OOO EY & OOO EY Vneshaudit",
	257: "Armenia-RussiaDC",
	258: "Belarus-RussiaDC",
	259: "Uzbekistan-RussiaDC",
};

export const common = {
	selected: 'Selected',
	cancel: 'Cancel',
	checkbox: {
		selectAllOn: 'Select all',
		selectAllOff: 'Unselect all'
	},
	clear: 'Clear',
	close: 'Close',
	pagination: {
		show: 'Show',
		iconHover: {
			first: 'First Page',
			previous: 'Previous Page',
			next: 'Next Page',
			last: 'Last Page'
		}
	},
	engv2Pagination: {
		show: 'Show',
		iconHover: {
			first: 'First page',
			previous: 'Previous page',
			next: 'Next page',
			last: 'Last page'
		}
	},
	search: {
		clear: 'Clear search',
		buttonTitle: 'Search',
		placeholder: 'Search',
		noResultsPlaceholder: 'No results found'
	},
	table: {
		remove: 'Remove',
		noDataPlaceholder: 'No data available',
		noResultsPlaceholder: 'No results found'
	},
	toggleSwitch: {
		no: 'No',
		yes: 'Yes'
	},
	wizard: {
		next: 'Continue',
		back: 'Back',
		finish: 'Finish'
	}
};

export const ConfidentialityTypes = [{
	id: 0,
	Name: 'Default'
},
{
	id: 10,
	Name: 'Low'
},
{
	id: 12,
	Name: 'Moderate'
},
{
	id: 11,
	Name: 'High'
}
];

export const internalUserRoles = [{
	roleKey: 3,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Archive engagement",
	displayName: "Archive engagement",
	roleDisplayOrder: 2,
	tooltipText: "Ability to archive the engagement"
},
{
	roleKey: 4,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Administer engagement",
	displayName: "Administer engagement",
	roleDisplayOrder: 1,
	tooltipText: "Ability to invite internal and external members and assign permissions to internal members"
},
{
	roleKey: 5,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Update content",
	displayName: "Update content",
	roleDisplayOrder: 6,
	tooltipText: "Ability to edit the profile and accept a content update"
},
{
	roleKey: 7,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Create service request",
	displayName: "Manage service requests",
	roleDisplayOrder: 4,
	tooltipText: 'Ability to create service requests and mark service requests complete'
},
{
	roleKey: 8,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Manage group",
	displayName: "Manage group",
	roleDisplayOrder: 5,
	tooltipText: 'Accept or reject groups instructions received from the Primary or Regional team'
},
{
	roleKey: 9,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Copy, Roll-forward, Restore engagements",
	displayName: "Copy, Roll-forward, Restore engagements",
	roleDisplayOrder: 3,
	tooltipText: "Ability to copy the engagement, and to roll-forward and restore it's archive"
},
{
	roleKey: 0,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentiality",
	roleNameUI: "Default",
	displayName: "None (Default)",
	roleDisplayOrder: 1,
	tooltipText: 'Default Confidential Documents Role'
},
{
	roleKey: 10,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentiality",
	roleNameUI: "Low",
	displayName: "Low",
	roleDisplayOrder: 8,
	tooltipText: 'Low Confidential Documents Role'
},
{
	roleKey: 11,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentiality",
	roleNameUI: "High",
	displayName: "High",
	roleDisplayOrder: 10,
	tooltipText: 'High Confidential Documents Role'
},
{
	roleKey: 12,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentiality",
	roleNameUI: "Moderate",
	displayName: "Moderate",
	roleDisplayOrder: 9,
	tooltipText: 'Moderate Confidential Documents Role'
},
// {
// roleKey: 13,
// sectionId:1,
// groupDisplayOrder: 200,
// groupName:"Confidentiality",
// roleNameUI: "Confidential",
// displayName: "Confidential",
// roleDisplayOrder: 11,
// tooltipText: 'Strictly Confidential Documents Role'
// },
{
	roleKey: 14,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restrictions",
	roleNameUI: "Read-only",
	displayName: "Read-only",
	roleDisplayOrder: 12,
	tooltipText: 'This functionality should only be used to grant third-party reviewers to this EY Canvas engagement. Appropriate approvals and authorization should be acquired from Professional Practice.'
},
{
	roleKey: 16,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restrictions",
	roleNameUI: "Review only",
	displayName: "Review only",
	roleDisplayOrder: 13,
	tooltipText: 'Review only gives this user the ability to view information in the Canvas engagement, sign-off, and use the review note functionality. This should be used when other EY users need to review this engagement (e.g., Group Audit Primary Team) but not be able to make edits.'
},
{
	roleKey: 37,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restrictions",
	roleNameUI: "Independence only",
	displayName: "Independence only",
	roleDisplayOrder: 14,
	tooltipText: 'Independence only gives this user the ability to complete their independence form for the engagement, but will not allow user to fully access the EY Canvas engagement. This should be used when a user has performed work for the engagement, but does not need to have access to EY Canvas.'
},
{
	roleKey: 6,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Undo edits",
	displayName: "Roll-back documents",
	roleDisplayOrder: 7,
	tooltipText: "Ability to roll-back documents if changes were accidentally made after the report release date and remove them from the 440 Canvas Form"
},
{
	roleKey: 35,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Manage sharing of EY Canvas FIT enablement",
	displayName: "Administer Guided Workflows",
	roleDisplayOrder: 14,
	tooltipText: "Ability to share Guided Workflows between EY Canvas engagements"
},
{
	roleKey: 36,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Import EY Helix data",
	displayName: "Import EY Helix data",
	roleDisplayOrder: 15,
	tooltipText: "Ability to import data from EY Helix"
}
];

export const restoreengagementexpirydates = [
	{ value: 3, label: '3 months' },
	{ value: 6, label: '6 months' },
	{ value: 9, label: '9 months' },
	{ value: 12, label: '12 months' }
];

export const messageStatus = {
	0: "Current",
	1: "Scheduled",
	2: "Expired",
};

export const kendoLabels = {
	alignLeft: 'Justify left',
	alignRight: 'Justify right',
	alignCenter: 'Justify center',
	alignFull: 'Justify full',
	addComment: 'Add comment',
	addColumnBefore: 'Add column left',
	addColumnAfter: 'Add column right',
	addRowAbove: 'Add row above',
	addRowBelow: 'Add row below',
	bulletList: 'Insert unordered list',
	bold: 'Bold',
	backColor: 'Highlight',
	createLink: 'Insert Hyperlink',
	createTable: 'Create table',
	cleanFormatting: 'Clean formatting',
	deleteRow: 'Delete row',
	deleteColumn: 'Delete column',
	deleteTable: 'Delete table',
	fontSizeInherit: "Font size",
	foreColor: 'Color',
	format: 'Format',
	fontSize: 'Font size',
	hyperlink: 'Insert web link',
	italic: 'Italic',
	indent: 'Indent',
	insertTableHint: "Create a {0} by {1} table",
	"hyperlink-dialog-content-address": "Web Address",
	"hyperlink-dialog-title": "Insert hyperlink",
	"hyperlink-dialog-content-title": "Title",
	"hyperlink-dialog-content-newwindow": "Open link in new window",
	"hyperlink-dialog-cancel": "Cancel",
	"hyperlink-dialog-insert": "Insert",
	outdent: 'Outdent',
	orderedList: 'Insert ordered list',
	print: 'Print',
	pdf: 'export to pdf',
	redo: 'redo',
	strikethrough: 'Strikethrough',
	subscript: 'Subscript',
	superscript: 'Superscript',
	underline: 'Underline',
	undo: 'Undo',

};

export const kendoFormatOptions = [
	{ text: 'Paragraph', value: "p" },
	{ text: 'Heading 1', value: "h1" },
	{ text: 'Heading 2', value: "h2" },
	{ text: 'Heading 3', value: "h3" },
	{ text: 'Heading 4', value: "h4" },
	{ text: 'Heading 5', value: "h5" },
	{ text: 'Heading 6', value: "h6" }
];

export const kendoFontSize = [
	{ text: '8', value: '8px' },
	{ text: '9', value: '9px' },
	{ text: '10', value: '10px' },
	{ text: '11', value: '11px' },
	{ text: '12', value: '12px' },
	{ text: '14', value: '14px' },
	{ text: '16', value: '16px' },
	{ text: '18', value: '18px' },
	{ text: '20', value: '20px' },
	{ text: '22', value: '22px' },
	{ text: '24', value: '24px' },
	{ text: '26', value: '26px' },
	{ text: '28', value: '28px' },
	{ text: '36', value: '36px' },
	{ text: '48', value: '48px' },
	{ text: '72', value: '72px' }
];

export const cacheOptionValue = [
	{value: 1, label: 'LDC'},
	{value: 2, label: 'Universal'}
]
