import {EYForm, FormRadio} from '@ey/form';
import React, {useState} from 'react';
import {currentResource, labels, urls} from '../../util/uiconstants';
import {
	getWorkspaceDetailsByID,
	updateWorkspace
} from '../../actions/workspacesactions';
import {hideLoader, showLoader} from '../../actions/loaderactions';

import ModalControl from '@ey/modal-control';
import {Radio} from 'react-bootstrap';
import clonedeep from 'lodash/cloneDeep';
import env from '../../util/env';
import {getNotifications} from '../../actions/notificationsactions';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';

const OverrideArchiveDeadlineDateModal = (props) => {
	const {engagementId, refreshDashboard, show, closeModal, workspaceId} = props;

	const dispatch = useDispatch();

	const [archiveOverrideDays, setArchiveOverrideDays] = useState(null);

	const OPERATION_TYPE_EDIT_WORKSPACE = 'EditWorkspace';
	const workspacesUrl = env.getURL('serviceUrl') + '/' + urls.WORKSPACES_URL;
	const getNotificationsUrl =
		env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;

	const defaultOverrideArchiveDeadlineDate =
		(Array.isArray(currentResource.overrideArchiveDeadlineDateOptions) &&
			currentResource.overrideArchiveDeadlineDateOptions.length > 0 &&
			currentResource.overrideArchiveDeadlineDateOptions[0].value) ||
		0;

	const handleConfirm = async () => {
		closeModal(false);
		await dispatch(showLoader());
		try {
			var workSpaceData = await dispatch(
				getWorkspaceDetailsByID(workspacesUrl + '/' + workspaceId, null, true)
			);
			if (
				workSpaceData &&
				workSpaceData.collections &&
				Array.isArray(workSpaceData.collections.engagements)
			) {
				await update(workSpaceData);
			}
		} finally {
			dispatch(hideLoader());
			dispatch(getNotifications(getNotificationsUrl));
			refreshDashboard();
		}
	};

	const handleHide = () => {
		closeModal(false);
	};

	const update = async (workSpaceData) => {
		let engagement;
		engagement = workSpaceData.collections.engagements.find(
			({id}) => id == engagementId // strict equality (===) intentionally was not used
		);
		if (engagement && engagement.data) {
			const archiveOverrideDaysParsed = Number.parseInt(archiveOverrideDays);

			let engCopy = clonedeep(engagement);
			engCopy.data.archiveoverridedays = Number.isNaN(archiveOverrideDaysParsed)
				? defaultOverrideArchiveDeadlineDate
				: archiveOverrideDaysParsed;

			engCopy.data.archiveoverridechange = true;

			const workspaceDataModel = {
				id: workSpaceData.id,
				data: {
					...workSpaceData.data,
					operationtype: OPERATION_TYPE_EDIT_WORKSPACE
				},
				collections: {
					engagements: [engCopy]
				}
			};

			await dispatch(
				updateWorkspace(workspacesUrl + '/' + workspaceId, workspaceDataModel)
			);
		}
	};

	const handleChange = (key, value) => {
		switch (key) {
			case 'archiveoverridedays':
				setArchiveOverrideDays(value);
				break;
			default:
				break;
		}
	};

	return (
		<>
			{show && (
				<ModalControl
					title={labels.overrideArchiveDateModalHeader}
					closeTitle={labels.close}
					confirmBtnTitle={labels.confirm}
					closeBtnTitle={labels.cancel}
					modalsize="small"
					onOkClick={handleConfirm}
					show={show === true}
					onHide={handleHide}
					isOkButtonDisabled={false}
				>
					<WrapperStyle>
						<div>
							<h6>{labels.overrideArchiveDateModalMessage}</h6>
						</div>
						<section className="overridedate">
							<EYForm
								id="overrideArchiveDateForm"
								onChange={handleChange}
								onSubmit={(e) => {
									e.preventDefault();
								}}
								action="\"
							>
								<FormRadio label="" name="archiveoverridedays" help is_required>
									{currentResource.overrideArchiveDeadlineDateOptions.map(
										(dateOption) => {
											var radioAttributes =
												dateOption.value === defaultOverrideArchiveDeadlineDate
													? {defaultChecked: true}
													: {};
											return (
												<Radio
													value={dateOption.value}
													key={dateOption.value}
													{...radioAttributes}
												>
													{dateOption.label}
												</Radio>
											);
										}
									)}
								</FormRadio>
							</EYForm>
						</section>
					</WrapperStyle>
				</ModalControl>
			)}
		</>
	);
};
export default OverrideArchiveDeadlineDateModal;

const WrapperStyle = styled.section`
	height: 30rem;

	& h6 {
		font-size: 1.6rem;
		line-height: normal;
		word-break: break-word;
		margin: 2.4rem;
	}

	& .overridedate {
		width: calc(100% - 4.8rem);
		margin: 0 2.4rem;

		& .form-group {
			margin: 0 0 1.5rem 0;
		}

		& .form-group label.control-label {
			display: none;
		}

		& div.form-group .radio {
			vertical-align: middle;
			margin: 0 0 1.5rem 0;

			& label input[type='radio'] {
				margin: -0.2rem 0 0 -3rem;
			}
			& label {
				padding-left: 3rem;
				cursor: default;
			}
		}
	}
`;
