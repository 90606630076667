export const processPathname = (pathname) => {
	let parentPath = '';
	let childPath = '';
	const newPathname = pathname.startsWith('/') ? pathname.substring(1) : pathname;
	const pathLength = newPathname.split('/').length;
	if (pathLength === 1) {
		parentPath = newPathname;
		childPath = '';
	} else {
		parentPath = newPathname.split('/')[0];
        childPath = newPathname.substring(newPathname.split('/')[0].length + 1).split('/')[0];
	}

	const processedData = {
		length: pathLength,
		parent: parentPath.charAt(0).toUpperCase() + parentPath.slice(1),
		child: childPath.charAt(0).toUpperCase() + childPath.slice(1)
	};
	return processedData;
};

export const processHashPath = (pathname, childPath) => {
	let hashPath = pathname.replace('#', '');
	if (hashPath.trim().length === 0) {
		hashPath = childPath;
	} else {
		hashPath = hashPath.charAt(0).toUpperCase() + hashPath.slice(1);
	}
	return hashPath;
};

export const assignPageName = (parent, child, hash) => {
	const processedPageName = child?.trim().length > 0 ? child : hash;
	if (!processedPageName || processedPageName?.trim().length === 0) return parent;
	return processedPageName;
};
