import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';

function CustomTooltip(props) {
	const {
		containerClass,
		styleName,
		label,
		children,
		title,
		dueDate,
		userInitials,
		iconName,
		iconClassName,
		position,
		popupArrowColor,
		defaultLoad,
		disabled,
		count
	} = props;

	const [popupVisible, setPopupVisible] = useState(false);
	const [popupTopPosition, setPopupTopPosition] = useState(true);
	const [popupLeftPosition, setPopupLeftPosition] = useState(false);
	const node = useRef();

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [props.isAnyInviteAcceptedOrRejected]);

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [props.isAnyArchiveInviteAcceptedOrRejected]);

	useEffect(() => {
		setPopupVisible(props.popupVisible);
	}, [props.popupVisible]);

	const handleClick = (e) => {
		if (!popupVisible) {
			setPopupVisible(true);
			if (props.onPopupShow) {
				props.onPopupShow();
			}
		} else {
			setPopupVisible(false);
			if (props.onPopupHide) {
				props.onPopupHide();
			}
			if (props.isAnyInviteAcceptedOrRejected) {
				props.refreshDashboard();
			}
			if (props.isAnyArchiveInviteAcceptedOrRejected) {
				props.refreshDashboard();
			}
		}

		let bound = e.currentTarget.getBoundingClientRect();
		let winHeight = window.innerHeight;
		let rBottom = winHeight - bound.bottom;
		let winWidth = window.innerWidth;
		let rLeft = winWidth - bound.left;

		if (rBottom > 300) {
			setPopupTopPosition(true);
		}

		if (rLeft > 300) {
			setPopupLeftPosition(true);
		}
	};

	const handleDivClick = (e) => {};

	const handleOutsideClick = (e) => {
		if (node.current.contains(e.target)) {
			// inside click
			return;
		}
		// outside click
		setPopupVisible(false);
		if (props.onPopupHide) {
			props.onPopupHide();
		}
		if (props.isAnyInviteAcceptedOrRejected) {
			props.refreshDashboard();
		}
		if (props.isAnyArchiveInviteAcceptedOrRejected) {
			props.refreshDashboard();
		}
	};
	//const customModelActive = popupVisible ? popupVisible : count > 0;

	return (
		<StyledPopOver className={containerClass}>
			<span ref={node}>
				<button
					className={`${popupVisible ? 'active' : ''} ${styleName}`}
					title={title}
					label={label}
					disabled={disabled}
					onClick={handleClick}
				>
					<SVGICONS
						styleName={iconClassName}
						name={iconName}
						hoverText={title}
					/>
					<span className="btnLabel">{label}</span>
					{dueDate && <>{dueDate}</>}
					{userInitials && <>{userInitials}</>}
					{count > 0 && <span className="btnCount">{count}</span>}
				</button>
				{!defaultLoad && popupVisible && (
					<section
						className={`
							${'show-pop-content doNotClosePopup'}
							${popupTopPosition ? 'bottom' : 'top'}
							${popupLeftPosition ? 'right' : 'right'}
							${position ? position : ''}
						`}
					>
						<section className={`${popupArrowColor} show-popup-arrow`} />
						<section className="show-pop-content-body" onClick={handleDivClick}>
							{children}
						</section>
					</section>
				)}
				{defaultLoad && (
					<section
						className={`
							${'show-pop-content'}
							${popupTopPosition ? 'bottom' : 'top'}
							${popupLeftPosition ? 'right' : 'right'}
							${popupVisible ? 'showPopUp' : 'hidePopUp'}
							${position ? position : ''}
						`}
					>
						<section className={`${popupArrowColor} show-popup-arrow`} />
						<section
							className="show-pop-content-body"
							id="customToolTip"
							onClick={handleDivClick}
						>
							{children}
						</section>
					</section>
				)}
			</span>
		</StyledPopOver>
	);
}

export default CustomTooltip;

const StyledPopOver = styled.section`
	position: relative;
	&.NotifationContainerClass {
		.btnCount {
			background-color: ${(props) => props.theme.color[`white`]};
			border-radius: 1rem;
			padding: 0.1rem 0.5rem;
			position: absolute;
			left: 1.5rem;
			font-size: 1rem;
			line-height: initial;
			top: 0;
			font-weight: bold;
			border: 0.1rem solid ${(props) => props.theme.color[`grey500`]};
			color: ${(props) => props.theme.color[`grey500`]};
		}
	}
	button {
		padding: 0;
	}
	.ey-primary-button {
		padding: 0.9rem 1.2rem;
		border: 0.1rem solid ${(props) => props.theme.color[`gray600`]};
		font-size: 1.4rem;
		svg {
			&.right {
				float: right;
			}
			&.left {
				margin-right: 0.5rem;
			}
			&.deg90 {
				transform: rotate(90deg);
			}
		}
		&:hover {
			background: ${(props) => props.theme.color[`gray600`]};
			color: ${(props) => props.theme.color[`white`]};
			svg {
				fill: ${(props) => props.theme.color[`white`]};
			}
		}
		.btnLabel {
			line-height: 2rem;
		}
	}

	.ey-secondary-button {
		padding: 0.9rem 1.5rem;
		border: 0.1rem solid ${(props) => props.theme.color[`gray600`]};
		color: ${(props) => props.theme.color[`gray600`]};
		font-size: 1.4rem;
		svg {
			&.right {
				float: right;
			}
			&.left,
			&.center {
				margin-right: 0.5rem;
			}
			&.deg90 {
				transform: rotate(90deg);
			}
		}
		&:hover {
			background: ${(props) => props.theme.color[`gray600`]};
			color: ${(props) => props.theme.color[`white`]};
			svg {
				fill: ${(props) => props.theme.color[`white`]};
			}
		}
		.btnLabel {
			line-height: 2rem;
			margin-right: 0.5rem;
		}
	}

	.show-pop-content {
		background: ${(props) => props.theme.color[`white`]};
		border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
		width: auto;
		padding: 0;
		position: absolute;
		z-index: 1;
		box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.4);
		&.showPopUp {
			display: block;
		}
		&.hidePopUp {
			display: none;
		}

		&.bottom {
			&.left {
				left: -0.5rem;
				.show-popup-arrow {
					top: 0%;
					left: 1rem;
				}
			}

			&.center {
				left: -50%;
				.show-popup-arrow {
					left: 50%;
				}
			}
			&.right {
				right: -2rem;
				.show-popup-arrow {
					right: 3rem;
				}
			}
		}

		&.top {
			bottom: 3rem;
			.show-popup-arrow {
				bottom: 0;
				transform: rotate(45deg);
			}

			&.left {
				.show-popup-arrow {
					left: 10%;
					bottom: 0;
				}
			}

			&.center {
				left: -50%;
				.show-popup-arrow {
					left: 50%;
				}
			}

			&.right {
				right: -2rem;
				.show-popup-arrow {
					right: 2.5rem;
				}
			}
		}

		.show-popup-arrow {
			height: 1.4rem;
			width: 1.4rem;
			position: absolute;
			background: ${(props) => props.theme.color[`white`]};
			transform: rotate(225deg);
			margin: -0.6rem;
			z-index: -1;
			box-shadow: ${(props) => props.theme.color[`grey300`]} 0.1rem 0.1rem
				0.1rem;
		}

		h4 {
			margin: 0;
			padding: 1rem;
			background-color: ${(props) => props.theme.color[`grey100`]};
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
			font-weight: bold;
			font-size: 1.5rem;
		}
	}
`;
