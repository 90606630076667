/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React from 'react';
import _ from 'lodash';
import WorkspacesLoader from './workspacesloader';
import WorkspaceItem from './workspaceitem';

const WorkspaceList = (props) => {
	return (
		<ul className="Workspaceholder">
			{(props.workspaces &&
				props.workspaces.map((workspace) => (
					<WorkspaceItem
						key={workspace.id}
						clientId={props.clientId}
						clientName={props.clientName}
						clientCode={props.clientCode}
						{...workspace}
						refreshDashboard={props.refreshDashboard}
						archiveretryshortcutkey={props.archiveretryshortcutkey}
						archivedEngagementList={props.archivedEngagementList}
						isReviewWorksapces={props.isReviewWorksapces}
					/>
				))) || <WorkspacesLoader count={8} prefix="ws_loader_" />}
		</ul>
	);
};

export default WorkspaceList;
