/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React, {useEffect, useState} from 'react';
import {
	dateFormats,
	dateFormatsListItems,
	labels
} from '../../util/uiconstants';
import {useDispatch, useSelector} from 'react-redux';

import EllipsesControl from '@ey/ellipses-control';
import {EngUserModelPatchFavorites} from '../../util/preparemodel';
import FavButton from './favoritesbutton';
import {PostEngagement} from '../../actions/engagementsaction';
import {SVGICONS} from '@ey/icons';
import Skeleton from 'react-loading-skeleton';
import WorkspaceGroupAudit from './workspaceGroupAudit';
import _ from 'lodash';
import env from '../../util/env';
import {markUnmarkFavorite} from '../../actions/engagementuseractions';
import moment from 'moment';
import styled from 'styled-components';
import {tabTypes} from '../../util/uiconstants';

const EngagementErrorItem = (props) => {
	const dispatch = useDispatch();

	const {
		data,
		archiveretryshortcutkey,
		id,
		refreshDashboard,
		clientName,
		workspace
	} = props;
	const {isfavorite, archivalrequestdate, engagementdesc} = data;

	const selectedTab = useSelector((store) => store.dashboard.activeTab);

	const [isFavInProgress, setIsFavInProgress] = useState(false);
	const [fav, setFav] = useState(isfavorite);
	const [readyForFavClick, setReadyForFavClick] = useState(true);
	const [arcRetry, setArcRetry] = useState(false);
	const [keys, setKeys] = useState([]);
	const [arcmsgCls, setArcmsgCls] = useState('arcmsgmaxwidth');

	let hideComponent = false;
	let classNameFavProgress = 'arrow-right';
	const baseKeyCombo = [
		{
			name: 16,
			value: 0
		},
		{
			name: 17,
			value: 0
		},
		{
			name: 18,
			value: 0
		}
	];

	useEffect(() => {
		if (selectedTab === tabTypes.FavoritesTab && fav === false) {
			hideComponent = true;
		}

		if (keys.length == 0) {
			let keyCombo = archiveretryshortcutkey.split(','); // Get the key from props and split.
			/*
				Set the Keys State, with the keys needed to the Key Combo Command
					and the previous State key items.
			*/
			setKeys((prevState) => [
				...prevState,
				...keyCombo.map((key) => {
					return {name: key.charCodeAt(0), value: 0};
				}),
				...baseKeyCombo
			]);
			// Current key combo received from props: (a + t + l). Added shift(16) + ctrl(17) + alt(18) to unify work
		}
	}, []);

	useEffect(() => {
		if (keys.length > 0 && keys.every((key) => key.value)) {
			//
			setArcRetry(true);
			setArcmsgCls('arcmsgminwidth');
		}
	}, [keys]);

	const keyEvent = (e) => {
		e.persist();
		setKeys((prevState) => {
			const newState = [...prevState];
			prevState.forEach((key) => {
				if (key.name === e.keyCode) {
					/* 
					When matched keyCode, find the item in the original array
					and update the value with (keydown : 1, keyup : 0).
					*/
					newState[newState.findIndex((x) => x.name === key.name)].value =
						e.type === 'keydown' ? 1 : 0;
				}
			});
			return newState;
		});
	};

	const onArchiveRetryLink = () => {
		dispatch(
			PostEngagement(env.getURL('serviceUrl') + '/' + 'archives.json', {
				data: {
					requesttypeId: 7, //request type for retry archive
					engagementid: id
				}
			})
		).finally(() => {
			window.location.reload();
		})
	};

	const onFavClick = async () => {
		setReadyForFavClick(false);
		setIsFavInProgress(true);
		let isFavTab = selectedTab === tabTypes.FavoritesTab;
		// Add favourite-inprogress class to arrow-right container when api call is in progress
		try {
			await dispatch(
				markUnmarkFavorite(
					env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
					EngUserModelPatchFavorites(id, !fav, 'markunmarkfavoriteengagement')
				)
			);
			setIsFavInProgress(false);
			setFav(!fav);
			setReadyForFavClick(true);
		} finally {
			if (isFavTab) {
				refreshDashboard();
			}
		}
	};

	const dblClick = () => {};

	return (
		<EngagementItemContainer
			className="eng-listwrapper"
			onDoubleClick={dblClick}
			onKeyDown={(e) => {
				keyEvent(e);
			}}
			onKeyUp={(e) => {
				keyEvent(e);
			}}
			tabIndex="0"
		>
			<EngagementItemDetails className="eng-listholder arcstatus-wrapper engagementerror engagementerror-indicator">
				<section className="arcstatus">
					<SVGICONS hoverText={'info_outline'} styleName="large" name="error" />
					<span className={arcmsgCls}>
						{archivalrequestdate != null
							? labels.arcretrymessage.replaceAll(
									'{0}',
									moment
										.utc(archivalrequestdate)
										.format(dateFormatsListItems[dateFormats].label)
							  )
							: labels.arcretrymessage}
					</span>
					<span className="arcaction">
						{arcRetry ? (
							<a onClick={onArchiveRetryLink}>{labels.ArchiveRetryLink}</a>
						) : (
							<a />
						)}
					</span>
				</section>
				<section className="engagementInfo">
					<WorkspaceContent>
						<section className="clientname" title={clientName}>
							{(clientName === '!!!'
								? labels.invalidclientcode
								: clientName) || <Skeleton width={250} height={20} />}
						</section>
						<WorkspaceGroupAudit workspace={workspace} engagement={data} />
					</WorkspaceContent>
					<EngagementDetails className="engagementname">
						<h4>
							<section className="anchor" data-autoid={id}>
								<a>
									<EllipsesControl
										content={
											engagementdesc || <Skeleton width={250} height={20} />
										}
										tooltip={
											engagementdesc || <Skeleton width={250} height={20} />
										}
										isTooltipAvailable
									/>
								</a>
							</section>
							<section className="pull-right eng-rightmenu">
								<section className="engagementid">
									ID: {id || <Skeleton width={250} height={20} />}
								</section>
							</section>
						</h4>
					</EngagementDetails>
				</section>
				<section className="engagementUpdates">
					<section className="faviconholder" />
					<section
						className={
							isFavInProgress
								? classNameFavProgress + ' ' + 'favourite-inprogress'
								: classNameFavProgress
						}
					/>
					<FavButton
						isFav={fav}
						onFavClick={onFavClick}
						readyForFavClick={readyForFavClick}
					/>
				</section>
			</EngagementItemDetails>
		</EngagementItemContainer>
	);
};
export default EngagementErrorItem;

const EngagementItemDetails = styled.section`
	box-shadow: 0px 4px 8px 0px ${(props) => props.theme.color[`grey250`]},
		1px -1px 8px 0px ${(props) => props.theme.color[`grey250`]};
	background-color: ${(props) => props.theme.color[`grey100`]};
	display: inline-block;
	border-left: 0.5rem solid ${(props) => props.theme.color[`blue300`]};
	width: 100%;
	height: 129px;
	@media screen and (max-width: 1366px) {
		height: 106px;
	}
	&.arcstatus-wrapper.engagementerror {
		height: auto;
		@media screen and (max-width: 1366px) {
			height: auto;v
		}
	}
	&.engagementerror-indicator {
		border-left: 0.5rem solid ${(props) => props.theme.color[`red600`]};
	}
	& .engagementInfo {
		float: left;
		width: 100%;
		padding: 1rem 0 1rem 0;
		position: relative;
	}
	&.arcstatus-wrapper {
		&.engagementerror .engagementInfo {
			& .engagementname .anchor {
				color: ${(props) => props.theme.color[`grey300`]};
				pointer-events: none;
				cursor: default;
			}
		}

		& .arcstatus {
			padding: 0.2rem 0.5rem;
			font-size: 1.4rem;

			& svg {
				fill: ${(props) => props.theme.color[`red600`]};
				vertical-align: top;
				margin: 0.9rem 0.5rem 0 0;
				width: 2rem;
				height: 2rem;
			}

			& .arcmsgmaxwidth {
				width: 95%;
				display: inline-block;
				line-height: 2rem;
				margin-top: 1rem;
			}

			& .arcmsgminwidth {
				width: 76%;
				display: inline-block;
				line-height: 2rem;
				margin-top: 1rem;
			}

			& .arcaction {
				float: right;
				& a {
					text-decoration: none;
					cursor: pointer;
					margin-right: 4rem;
					font-weight: bold;
					margin-top: 0.5rem;
				}
				& a:hover {
					text-decoration: underline;
					color: #337ab7;
				}
			}
		}
	}

	& .engagementUpdates {
		box-shadow: inset 0px 2px 4px 0px ${(props) => props.theme.color[`grey300`]};
		background: ${(props) => props.theme.color[`grey100`]};
		float: left;
		width: 100%;
		position: relative;
		& .faviconholder {
			float: right;
			position: relative;
			display: block;
		}

		& .arrow-right {
			width: 0;
			height: 0;
			border-top: 4.5rem solid transparent;
			border-bottom: 0 solid transparent;
			border-right: 4.5rem solid ${(props) => props.theme.color[`grey500`]};
			float: right;
			margin-right: -0.2rem;
			margin-bottom: -0.2rem;
			box-shadow: 3px 3px 5px 0px ${(props) => props.theme.color[`grey300`]};
			&.favourite-inprogress {
				border-bottom: 0 solid transparent;
				border-right: 4.5rem solid ${(props) => props.theme.color[`grey400`]};
				pointer-events: none;
				opacity: 0.5;
				+ section svg {
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
`;

const EngagementDetails = styled.section`
	width: 100%;
	display: inline-block;
	vertical-align: super;
	& svg {
		vertical-align: middle;
		cursor: pointer;
		fill: ${(props) => props.theme.color[`grey400`]};
	}

	& h4 {
		display: inline-block;
		font-size: 1.6rem;
		line-height: normal;
		vertical-align: top;
		margin-top: -1.5rem;
		font-weight: bold;
		color: ${(props) => props.theme.color[`grey500`]};
		padding-left: 3rem;
		width: 100%;

		& section.anchor {
			color: ${(props) => props.theme.color[`grey500`]};
			vertical-align: middle;
			width: 70%;
			display: inline-flex;
			line-height: 4rem;
			max-width: 70%;

			& a {
				display: inline-flex;
				color: ${(props) => props.theme.color[`grey300`]};
				max-width: 100%;
			}
		}

		& .eng-rightmenu {
			line-height: 4rem;
			margin-top: -2rem;
			& .restored-eng {
				color: ${(props) => props.theme.color[`grey300`]};
				font-size: 1.4rem;
			}

			& > section {
				display: inline-block;
			}
		}

		& .ellipses {
			width: auto;
			max-width: none;
			display: inline-flex;
			color: ${(props) => props.theme.color[`grey500`]};
			& div {
				max-width: none;
			}
		}
	}

	& .engagementid {
		display: inline-block;
		font-size: 1.4rem;
		line-height: 4rem;
		margin: 0 5rem 0 2rem;
		color: ${(props) => props.theme.color[`grey400`]};
		vertical-align: text-top;
		font-weight: normal;
		float: right;
		width: 11rem;
	}
`;

const WorkspaceContent = styled.div`
	padding-left: 3rem;
	width: 69%;
	float: left;
	padding-bottom: 1rem;
	.clientname,
	.workspace-name,
	.engagement-type,
	.engagement-typelinked {
		display: inline-block;
		font-size: 1.4rem;
		color: ${(props) => props.theme.color[`grey400`]};
		line-height: normal;
		max-width: 28%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: middle;
		padding-right: 0.3rem;
	}
	.workspace-name {
		max-width: 28%;
		padding-left: 0.3rem;
	}
	.engagement-type {
		max-width: 19%;
		padding-left: 0.3rem;
	}
	.engagement-typelinked {
		display: inline-block;
		max-width: 14%;
		padding-left: 0.3rem;
		.motif-icon svg {
			margin-top: -0.3rem;
			vertical-align: middle;
			margin-right: 0.3rem;
		}
		.engagementtype-linkedtxt {
			font-size: 1.4rem;
			color: ${(props) => props.theme.color[`grey400`]};
			line-height: normal;
		}
	}
`;

const EngagementItemContainer = styled.section`
	margin: 1.5rem 0;
`;
