import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EllipsesControl from '@ey/ellipses-control';
import styled from 'styled-components';
import env from '../../util/env';
import moment from 'moment';
import {
	urls,
	labels,
	dateFormatsListItems,
	dateFormats,
	currentResource
} from '../../util/uiconstants';
import {
	getEngagementTimeline,
	syncEngagementandUserStatus
} from '../../actions/timelineaction';
import {MotifProgressBar} from '@ey-xd/motif-react';
import TimelineTooltip from '../Timeline/timelinetooltip';
import EngagementMetrics from './Metrics/engagementMetrics';
import _ from 'lodash';
import {MotifTimeline} from '../Common/CustomTimeline/timeline';
import {lightColor} from '@ey/styled-theme';

const Timeline = (props) => {
	const dispatch = useDispatch();
	const storeTimeline = useSelector((state) => state.timeline);

	const [timeline, setTimeline] = useState([]);
	const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
	const [isPostTimelineForEngagement, setIsPostTimelineForEngagement] = useState(true);

	useEffect(() => {
		const getEngagementTimelineData = async () => {
			try {
				let url =
					props.AppUri +
					'/api/v2/' +
					urls.TIMELINE_URL +
					'?engagementid=' +
					props.engagementId;
				setShowLoadingIndicator(true);
				await dispatch(
					getEngagementTimeline(
						url,
						url.split('/api/v2')[0],
						props.engagementId,
						props.AudienceUri
					)
				);
				setShowLoadingIndicator(false);
			} catch (e) {
				// Time line call failed
				// make a post call to sync the status
				setShowLoadingIndicator(false);
				if (e && !e.toString().includes('Network Error')) {
					// if not a network error
					// then do make sync call
					let universalurl =
						env.getURL('serviceUrl') +
						'/' +
						urls.TIMELINE_URL +
						'/' +
						props.engagementId;

					dispatch(
						syncEngagementandUserStatus(universalurl, props.engagementId)
					);
				}
			}
		};
		getEngagementTimelineData();
	}, []);

	useEffect(() => {
		if (storeTimeline && isPostTimelineForEngagement) {
			setTimeline(getTimeline(storeTimeline));
		}
	}, [storeTimeline]);

	/**
	 * prepare the timeline model
	 */
	const getTimeline = (_storeTimeline) => {
		let _timeline;

		if (
			_storeTimeline &&
			_storeTimeline.timeline &&
			_storeTimeline.timeline.length > 0
		) {
			var timelineData = _storeTimeline.timeline.find(
				(x) => x && x.id && x.id.toString() === props.engagementId
			);

			var dateFormat = dateFormatsListItems[dateFormats].label;

			if (timelineData && timelineData.data) {
				_timeline = {
					id: timelineData.id,
					startDate: moment
						.utc(timelineData.data.startDate)
						.format('MM/DD/YYYY'),
					endDate: moment.utc(timelineData.data.endDate).format('MM/DD/YYYY'),
					currentDate: timelineData.data.currentDate,
					dateFormat: dateFormat,
					dateMarkers: timelineData.data.dateMarkers,
					keyEventConfig: [],
					milestoneConfig: [],
					metrics: {},
					helixProjects: []
				};

				if (timelineData.data.milestoneConfig) {
					_timeline.milestoneConfig = getMilestoneTooltip(
						timelineData.data.milestoneConfig,
						dateFormat,
						timelineData.data.keyEventConfig
					);
				}

				if (timelineData.data.keyEventConfig) {
					_timeline.keyEventConfig = getKeyEventTooltip(
						timelineData.data.milestoneConfig,
						timelineData.data.keyEventConfig,
						dateFormat
					);
				}

				if (timelineData.data.metrics) {
					_timeline.metrics = timelineData.data.metrics;
				}

				// Check if 4 params matches
				// if not then => make a post call to Universal API
				// If matches then => then don't do any action and return
				if (
					timelineData.data.engagementstatusid !== props.engagementstatus ||
					timelineData.data.isprofilecomplete !== props.engagementisprofilecomplete ||
					timelineData.data.engagementuserstatusid !== props.engagementuserstatusid ||
					timelineData.data.independencestatusid !== props.independencestatusid ||
					timelineData.data.isindependencetemplateavailable !== props.isindependencetemplateavailable ||
					(timelineData.data.expectedReportReleaseDate // to beat versioning scenario - where gaRoleTypeID is not available in timeline data
						&& timelineData.data.gaRoleTypeID !== props.garoletypeid) ||
					 (timelineData.data.expectedReportReleaseDate && props.expectedreportreleasedate
					 	&& !moment(timelineData.data.expectedReportReleaseDate, 'YYYY/MM/DD').isSame(moment(props.expectedreportreleasedate, 'YYYY/MM/DD'), 'day'))
				) {
					setIsPostTimelineForEngagement(false);
					let universalurl =
						env.getURL('serviceUrl') +
						'/' +
						urls.TIMELINE_URL +
						'/' +
						props.engagementId;

					dispatch(
						syncEngagementandUserStatus(universalurl, props.engagementId)
					);
				}

				if (
					timelineData.data.helixProjects &&
					timelineData.data.helixProjects.length > 0
				) {
					_timeline.helixProjects = timelineData.data.helixProjects;
					props.getHelixProjects(timelineData.data.helixProjects);
				}
			}
		}
		return _timeline;
	};

	/**
	 * prepare the timeline keyevent config model
	 */
	const getKeyEventTooltip = (milestones, keyevents, dateFormat) => {
		var keyEventConfig = [];
		var tooltips;

		var events = _.groupBy(keyevents, 'date');
		_.each(_.toArray(events), function (ke) {
			tooltips = [];
			ke.map((k) => {
				var isSameMilestoneDate;
				milestones.map((ms) => {
					if (ms.milestoneTooltips && ms.milestoneTooltips.length > 0) {
						isSameMilestoneDate = _.find(ms.milestoneTooltips, {
							endDate: moment.utc(k.date).format('MM/DD/YYYY')
						});
					}
				});

				if (!isSameMilestoneDate) {
					var keyname =
						k.name != ''
							? k.name
							: currentResource.timephaseType[k.id.toString()];
					tooltips.push({
						typeid: 2,
						id: k.id,
						name: (
							<EllipsesControl
								content={keyname}
								tooltip={keyname}
								isTooltipAvailable
							/>
						),
						date: moment.utc(k.date).format(dateFormat)
					});
				}
			});

			if (tooltips.length > 0) {
				keyEventConfig.push({
					date: moment.utc(ke[0].date).format('MM/DD/YYYY'),
					tooltipPlacement: 'auto',
					tooltipComponent: <TimelineTooltip tooltips={tooltips} />
				});
			}
		});

		return keyEventConfig;
	};

	/**
	 * prepare the timeline milestone config model
	 */
	const getMilestoneTooltip = (milestones, dateFormat, keyevents) => {
		var milestoneConfig = [];
		var tooltips;
		milestones.map((ms) => {
			tooltips = [];
			if (ms.milestoneTooltips && ms.milestoneTooltips.length > 0) {
				ms.milestoneTooltips.map((mst) => {
					tooltips.push({
						typeid: 1,
						name: (
							<EllipsesControl
								content={currentResource.timephaseType[mst.id.toString()]}
								tooltip={currentResource.timephaseType[mst.id.toString()]}
								isTooltipAvailable
							/>
						),
						openCount: mst.milestoneTasks[0].count,
						inprogressCount: mst.milestoneTasks[1].count,
						reviewCount: mst.milestoneTasks[2].count,
						completedCount: mst.milestoneTasks[3].count,
						startDate: mst.startDate
							? moment.utc(mst.startDate).format(dateFormat)
							: labels.na,
						endDate: moment.utc(mst.endDate).format(dateFormat),
						progress: mst.progress
					});
				});
			}
			milestoneConfig.push({
				date: moment.utc(ms.date).format('MM/DD/YYYY'),
				progress: ms.progress,
				tooltipPlacement: 'auto',
				tooltipComponent: <TimelineTooltip tooltips={tooltips} />
			});
		});

		return milestoneConfig;
	};

	return (
		<section>
			{showLoadingIndicator ? (
				<LoadingIndicator>
					<MotifProgressBar
						isIndeterminate
						showLabel
						labelComponent={
							<span className="progressbar-text">{labels.loadingLabel}</span>
						}
					/>
				</LoadingIndicator>
			) : (
				<>
					{timeline && timeline.id === parseInt(props.engagementId) ? (
						<>
							<MotifTimeline
								dateFormat={timeline.dateFormat}
								startDate={timeline.startDate}
								endDate={timeline.endDate}
								currentDate={timeline.currentDate}
								milestoneConfig={timeline.milestoneConfig}
								dateMarkers={timeline.dateMarkers}
								keyEventConfig={timeline.keyEventConfig}
							/>
							<EngagementMetrics
								engagementId={props.engagementId}
								timeline={timeline}
								expectedreportreleasedate={props?.expectedreportreleasedate}
								archivaldeadlinedate={props?.archivaldeadlinedate}
							/>
						</>
					) : (
						<StyledTimelineError>
							<section className="timelinerrror-container">
								<span className="err-msg">{labels.loadingErrorText}</span>
								<section className="timelinerrror-icon" />
							</section>
						</StyledTimelineError>
					)}
				</>
			)}
		</section>
	);
};

export default Timeline;

const LoadingIndicator = styled.section`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 3rem;
	.motif-progress-bar-wrapper {
		width: 35rem;
		.motif-progress {
			background-color: ${lightColor[`blue300`]} !important;
		}
		.progressbar-text {
			font-size: 1.2rem;
		}
	}
`;

const StyledTimelineError = styled.section`
	margin: 0;
	box-shadow: 0px 4px 4px -2px ${(props) => props.theme.color[`grey300`]};
	padding-bottom: 5.8rem;
	@media screen and (max-width: 1366px) {
		padding-bottom: 7.1rem;
	}
	.timelinerrror-container {
		margin: 0 3rem;
		.timelinerrror-icon {
			width: 100%;
			height: 2.4rem;
			background: url('../img/timelineErrorv2_1920.svg') no-repeat top left;
			background-size: cover;
			@media screen and (max-width: 1366px) {
				width: 100%;
				height: 2.4rem;
				background: url('../img/timelineErrorv2_1280.svg') no-repeat top left;
				background-size: cover;
			}
		}
		.err-msg {
			display: block;
			color: ${(props) => props.theme.color[`grey400`]};
			font-size: 1.4rem;
			margin-bottom: 2rem;
			margin-top: 1.5rem;
			padding-left: 0;
		}
	}
`;
