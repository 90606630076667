import {isEmpty} from 'lodash';
import {stringify} from 'query-string';
import Env from './env';

export const HTTP_REQUEST_METHODS = {
	// The GET method requests a representation of the specified resource. Requests using GET should only retrieve data.
	GET: 'GET'
};

export const API_RESOURCES = {
	HOME: 'home',
	ENGAGEMENTS: 'engagements'
};

const ERRORS = {
	NO_RESOURCE_SPECIFIED: 'No resource was specified.'
};

const arrayFormat = 'bracket-separator';
const arrayFormatSeparator = '|';

/**
 * Builds an Api request Url based on the specified parameters.
 * @param {object} resource An object holding the Url's resource part information.
 * @param {object} queryString An object holding the Url's query string parameters.
 * @returns {string} The Url built using the specified object parameters.
 */
export function getUrl(resource, queryString = undefined) {
	let url = Env.getServerUrl();

	if (resource !== undefined && !isEmpty(resource)) {
		url += Object.entries(resource)
			.map((item) => {
				let path = item[0];
				if (path === API_RESOURCES.HOME) {
					path = '';
				}
				if (item[1] !== undefined && item[1] !== null) {
					path += `/${item[1]}`;
				}
				return path;
			})
			.join('/');
	} else {
		throw ERRORS.NO_RESOURCE_SPECIFIED;
	}

	const _queryString = Object.keys(queryString).map(key => {
		const value = queryString[key].toString();
			let tempValue=value.replace(/ /g, '%20');
			  return `${key}=${tempValue}`;
	  }).join('&');
	   url += `?${_queryString}`;
	return url;
}

/**
 * Creates an action with the specified parameters that will be handled by the api-middleware asynchronously.
 * @param {string} type The type of the created action.
 * @param {object} resource An object holding the Url's resource part information.
 * @param {object} queryString An object holding the Url's query string parameters.
 * @param {object} args An optional object with arguments to be available in the action object.
 * @param {boolean} isOnline Specifies whether the application has Api connectivity, defaults to true.
 * @param {string} trackWithArea area string
 * @returns {object} The new action object.
 */
export function createApiReadAction(
	type,
	resource,
	queryString = undefined,
	args = {},
	isOnline = true,
	trackWithArea = undefined
) {
	const url = getUrl(resource, queryString);
	return {
		type,
		...args,
		meta: {
			api: {
				url: url,
				method: HTTP_REQUEST_METHODS.GET,
				trackWithArea: trackWithArea,
				data: {
					resource: url.split('/api/v1')[0],
				}
			}
		},
		isOnline
	};
}
