import React from 'react';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';
import {labels} from '../../util/uiconstants';
/*
	readyForFavClick : this props is used for avoiding quick subsequent clicks on favorites button ie. double click etc. 
	On click of favorite - onIconClick even will be gone for 300ms so no click can be performed. 
*/
const FavoriteButton = (props) => {
	const {isFav, onFavClick, readyForFavClick} = props;

	const favIconName = isFav ? 'star' : 'star-outline';
	const hoverTxt = isFav ? labels.unmarkfromfavorites : labels.markasfavorite;

	const onFavClickEvent = (e) => {
		onFavClick();
	};

	return (
		<Toplevelcss>
			<span title={hoverTxt}>
				<SVGICONS
					hoverText={hoverTxt}
					styleName="x-small"
					name={favIconName}
					onIconClick={readyForFavClick ? onFavClickEvent : undefined}
				/>
			</span>
		</Toplevelcss>
	);
};

export default FavoriteButton;

const Toplevelcss = styled.section`
	cursor: pointer;
	float: right;
	& svg {
		fill: ${(props) => (props.isFav === true ? '#ffe600' : '#ffe600')};
		position: absolute;
		right: 0.6rem;
		top: 2.4rem;
		z-index: 1;
	}
`;
