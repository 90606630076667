/**
 * Scot.js
 * Created by zl952fc on 02/24/2020.
 */

import {ActionTypes, EngagementStatus, UserAcknowledgementType, engagementUserStatus} from '../../util/enumerations';
import React, {useEffect, useState} from 'react';
import {applicationErrorCode, labels, urls, urlsHash} from '../../util/uiconstants';
import {getNotifications, setCloseNotification} from '../../actions/notificationsactions';
import styled, {ThemeProvider} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import CustomTooltip from '../Common/ToolTip/CustomTooltip';
import NotificationGroups from './NotificationGroups/NotificationGroups';
import {PatchEngagement} from '../../actions/engagementsaction';
import Utility from '../../util/utils';
import env from '../../util/env';
import {getSingleEngagement} from '../../actions/engagementsaction';
import {getTheme} from '@ey/styled-theme';
import {lightColor} from '@ey/styled-theme';
import {patchCallAPI} from '../../actions/engagementuseractions';
import {postData} from '../../actions/genericactions';
import {refreshDashboardGlobal} from '../../actions/dashboardactions';

const Notifications = (props) => {
	const {theme, location, history, triggerNotification, setNotificationStatus} =
		props;
	const dispatch = useDispatch();

	//Redux
	const singleEngagement = useSelector((state) => state.singleEngagement);
	const notifications = useSelector((state) => Utility.groupdNotificationsByActionType(state.notifications));
	const userSettings = useSelector((state) => state.userSettings);

	const [hideNotification, setHideNotification] = useState(false),
		[popupVisible, setPopupVisible] = useState(false),
		[acceptedEngagementList, setacceptedEngagementList] = useState([]),
		[acceptedArchiveEngagementList, setacceptedArchiveEngagementList] = useState([]),
		[isInviteAcceptedOrRejected, setisInviteAcceptedOrRejected] = useState(false),
		[isArchiveInviteAcceptedOrRejected, setisArchiveInviteAcceptedOrRejected] = useState(false),
		[isAnyInviteAcceptedOrRejected, setisAnyInviteAcceptedOrRejected] = useState(false),
		[isAnyArchiveInviteAcceptedOrRejected, setisAnyArchiveInviteAcceptedOrRejected] = useState(false),
		[count, setCount] = useState(0),
		[closedNotification, setClosedNotification] = useState(false);

	const urlPath = location && location.pathname && location.pathname.substring(1).toUpperCase();

	const urlSearch = location && location.search && location.search.substring(1).toUpperCase();

	const userAction = {
		accept: 1,
		reject: 2,
		error: 3
	};

	const getAllNotifications = () => {
		const url = env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;
		dispatch(getNotifications(url));
	};

	const refreshDashboard = () => {
		dispatch(refreshDashboardGlobal(1));
	};

	useEffect(() => {
		getAllNotifications();
	}, []);

	useEffect(() => {
		if (singleEngagement && singleEngagement.data && !singleEngagement.isCompletitionNeeded) {
			navigateGroupInstructionsPage(singleEngagement);
		}
	}, [singleEngagement]);

	const closeNotification = () => {
		setNotificationStatus(false);
		setClosedNotification(true);
		setPopupVisible(false);
		refreshDashboardAndNotifications();
	};

	const showNotification = (show) => {
		// reset the flag show pop up will be visible
		if (show) {
			setisAnyInviteAcceptedOrRejected(false);
			setisAnyArchiveInviteAcceptedOrRejected(false);
			setClosedNotification(false);
		}

		if (popupVisible !== show && show) {
			setPopupVisible(show);
		}
	};

	useEffect(() => {
		// If user is new user or on user settings page, hide notifications
		if (urlPath === urlsHash.USER_SETTINGS) {
			if (!hideNotification) {
				setHideNotification(true);
			}
		} else {
			setHideNotification(false);
		}
	}, [urlPath]);

	useEffect(() => {
		setCount(notifications ? notifications.count : 0);
		if (!popupVisible) {
			// If URL is DashBoard or Engagement or Favorities and has system messages
			// then open the notifications
			if (closedNotification) {
				setPopupVisible(false);
			} else if (isAnyInviteAcceptedOrRejected || isAnyArchiveInviteAcceptedOrRejected) {
				// ignore this for as we do not want to reload the notification window
				// until next hard refresh
			} else if (
				(urlPath === '/' || urlPath === urlsHash.ENGAGEMENT || urlPath === urlsHash.FAVORITES) &&
				triggerNotification
			) {
				const found =
					notifications &&
					notifications.some((notification) => {
						return notification.actionType === ActionTypes.SystemMessages;
					});
				setPopupVisible(found);
			}
		}
	}, [notifications]);

	const onFirstButtonClick = async (notification) => {
		let acknowledmentModel = {
			collection: {
				engagementacknowledgement: []
			}
		};

		if (notification.isFromList) {
			// In case of clear all - iterate through list and
			// club together to make one api call
			let userAcknowledgementType = UserAcknowledgementType.Archived;

			switch (notification.actionType) {
				case ActionTypes.ArchiveDeadlineDateOverride:
					userAcknowledgementType = UserAcknowledgementType.ArchiveOverride;
					break;
				case ActionTypes.DeletedEngagement:
					userAcknowledgementType = UserAcknowledgementType.Deleted;
					break;
				case ActionTypes.ArchivedEngagement:
					userAcknowledgementType = UserAcknowledgementType.Archived;
					break;
				case ActionTypes.DeactivatedEngagements:
					userAcknowledgementType = UserAcknowledgementType.DeActivated;
					break;
				case ActionTypes.DeniedAccess:
					userAcknowledgementType = UserAcknowledgementType.MatterResolvedDeniedAccess;
					break;
				default:
					break;
			}

			notification.notifications.map((notification) => {
				acknowledmentModel.collection.engagementacknowledgement.push({
					id: userAcknowledgementType,
					engagementid: notification.engagementId
				});
			});

			await onUserAcknowledgement(acknowledmentModel);
		} else {
			switch (notification.actionType) {
				case ActionTypes.EngagementInvitation:
					onAcceptRejectInviteClick(notification, userAction.accept);
					break;
				case ActionTypes.CopiesReceived:
					onCopiesReceivedClick(notification);
					break;
				case ActionTypes.GroupAuditInvite:
					onGroupAuditClick(notification);
					break;
				case ActionTypes.CreateError:
					if (notification.otherId === EngagementStatus.CopyError) {
						await onCopyEngagementErrorRetry(notification);
					} else {
						await onCreateErrorClearClick(notification);
					}
					getAllNotifications();
					break;
				case ActionTypes.GaAInstruction:
					await gotoGroupInstructions(notification);
					break;
				case ActionTypes.ArchiveAddition:
					onArchiveAcceptRejectInviteClick(notification, userAction.accept);
					break;
				case ActionTypes.ArchiveDeadlineDateOverride:
					acknowledmentModel.collection.engagementacknowledgement.push({
						id: UserAcknowledgementType.ArchiveOverride,
						engagementid: notification.engagementId
					});

					await onUserAcknowledgement(acknowledmentModel);
					break;
				case ActionTypes.DeletedEngagement:
					acknowledmentModel.collection.engagementacknowledgement.push({
						id: UserAcknowledgementType.Deleted,
						engagementid: notification.engagementId
					});

					await onUserAcknowledgement(acknowledmentModel);
					break;
				case ActionTypes.ArchivedEngagement:
					acknowledmentModel.collection.engagementacknowledgement.push({
						id: UserAcknowledgementType.Archived,
						engagementid: notification.engagementId
					});
					await onUserAcknowledgement(acknowledmentModel);
					break;
				case ActionTypes.DeactivatedEngagements:
					acknowledmentModel.collection.engagementacknowledgement.push({
						id: UserAcknowledgementType.DeActivated,
						engagementid: notification.engagementId
					});
					await onUserAcknowledgement(acknowledmentModel);
					break;
				case ActionTypes.DeniedAccess:
					acknowledmentModel.collection.engagementacknowledgement.push({
						id: UserAcknowledgementType.MatterResolvedDeniedAccess,
						engagementid: notification.engagementId
					});
					await onUserAcknowledgement(acknowledmentModel);
					break;
				default:
					break;
			}
		}
	};

	const onSecondButtonClick = (notification) => {
		// To Do:
		if (notification.isFromList) {
			//To Do: on List item click:
		} else {
			switch (notification.actionType) {
				case ActionTypes.EngagementInvitation:
					onAcceptRejectInviteClick(notification, userAction.reject);
					break;
				case ActionTypes.ArchiveAddition:
					onArchiveAcceptRejectInviteClick(notification, userAction.reject);
					break;
				case ActionTypes.CreateError:
					if (notification.otherId === EngagementStatus.CopyError) {
						onCreateErrorClearClick(notification);
					}
					break;
				default:
					break;
			}
		}

		// show hide notification center
		setPopupVisible(true);
	};

	const onCopiesReceivedClick = (notification) => {
		setPopupVisible(false);

		history.push('/datapackagerequest?copyRequestId=' + notification.otherGuid);
	};

	const onGroupAuditClick = (notification) => {
		const url = '/groupauditlinkrequest?GAComponentID=' + notification.otherId;
		setPopupVisible(false);
		history.push(url);
	};

	const onCreateErrorClearClick = async (notification) => {
		let acknowledmentModel = {
			collection: {
				engagementacknowledgement: [
					{
						id: EngagementStatus.Deleted,
						engagementid: notification.engagementId
					}
				]
			}
		};

		await createUserAcknowledgement(acknowledmentModel, '?phase=ClearNotifications');
	};

	const onUserAcknowledgement = async (acknowledmentModel) => {
		await createUserAcknowledgement(acknowledmentModel);
		getAllNotifications();
	};

	const setisInviteAcceptedOrRejectedToDone = () => {
		setisInviteAcceptedOrRejected(false);
	};

	const setisArchiveInviteAcceptedOrRejectedToDone = () => {
		setisArchiveInviteAcceptedOrRejected(false);
	};

	const getEngInviteModel = (notification, useraction) => {
		let inviteModel = {};
		inviteModel.data = {
			countryid: notification.otherId
		};

		let inviteEngagementData = {
			id: notification.engagementId,
			engagementUserStatusId:
				useraction === userAction.accept
					? engagementUserStatus.Active.toString()
					: engagementUserStatus.Rejected.toString(),
			countryId: notification.otherId,
			engagementId: notification.engagementId
		};
		inviteModel.collections = {
			engagements: [
				{
					data: inviteEngagementData,
					id: notification.engagementId
				}
			]
		};

		return inviteModel;
	};

	const getArchiveInviteModel = (notification, useraction) => {
		//		internal const string RespondToArchivalRequest = "acceptarchival";

		let inviteModel = {
			collections: {
				engagements: [
					{
						data: {
							id: notification.engagementId.toString(),
							countryId: notification.otherId,
							operationType: 'acceptarchival'
						},
						id: notification.engagementId.toString()
					}
				],
				teammembers: [
					{
						data: {
							userid: notification.displayUserId,
							engagementuserstatusid:
								useraction === userAction.accept
									? engagementUserStatus.Active.toString()
									: engagementUserStatus.RejectArchiveAccess.toString()
						}
					}
				]
			},
			data: {
				countryid: notification.otherId,
				operationType: 'acceptarchival'
			}
		};

		return inviteModel;
	};

	async function refreshDashboardAndNotifications() {
		const anyActionTaken = isAnyInviteAcceptedOrRejected;
		switch (urlPath) {
			case '':
				if (anyActionTaken) {
					await refreshDashboard();
					await getAllNotifications();
					await setisAnyInviteAcceptedOrRejected(false);
				}
				break;
			case urlsHash.ENGAGEMENT:
				if (urlsHash.ENGAGEMENT || urlSearch == urlsHash.WORKSPACE) {
					if (anyActionTaken) {
						await refreshDashboard();
						await getAllNotifications();
						await setisAnyInviteAcceptedOrRejected(false);
					}
				} else {
					if (anyActionTaken) {
						await getAllNotifications();
						await setisAnyInviteAcceptedOrRejected(false);
					}
				}
				break;
			default:
				await getAllNotifications();
				await setisAnyInviteAcceptedOrRejected(false);
				break;
		}

		const anyActionTakenArchive = isAnyArchiveInviteAcceptedOrRejected;
		switch (urlPath) {
			case '':
				if (anyActionTakenArchive) {
					await refreshDashboard();
					await getAllNotifications();
					await setisAnyArchiveInviteAcceptedOrRejected(false);
				}
				break;
			case urlsHash.ENGAGEMENT:
				if (urlsHash.ENGAGEMENT || urlSearch == urlsHash.WORKSPACE) {
					if (anyActionTaken) {
						await refreshDashboard();
						await getAllNotifications();
						await setisAnyArchiveInviteAcceptedOrRejected(false);
					}
				} else {
					if (anyActionTakenArchive) {
						await getAllNotifications();
						await setisAnyArchiveInviteAcceptedOrRejected(false);
					}
				}
				break;
			default:
				await getAllNotifications();
				await setisAnyArchiveInviteAcceptedOrRejected(false);

				break;
		}
	}

	const onAcceptRejectInviteClick = (notification, useraction) => {
		dispatch(
			patchCallAPI(
				env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
				JSON.stringify(getEngInviteModel(notification, useraction))
			)
		)
			.then(() => {
				setacceptedEngagementList(
					acceptedEngagementList.concat({
						id: notification.engagementId,
						action: useraction
					})
				);
				setisInviteAcceptedOrRejected(true); // to notify notification.js that api call is succesfull -- this is for item
				setisAnyInviteAcceptedOrRejected(true); // to notify CustomTooltip if any action has been taken
				getAllNotifications();
			})
			.catch((error) => {
				if (error.response.statusText === applicationErrorCode.EngagementUserNotActive) {
					setacceptedEngagementList(
						acceptedEngagementList.concat({
							id: notification.engagementId,
							action: userAction.error
						})
					);
					setisInviteAcceptedOrRejected(true);
				}
			});
	};

	const navigateGroupInstructionsPage = (singleEngagementData) => {
		let client = singleEngagementData.collections.clients[0];
		let workspace = client.collections.workspaces[0];
		let engagement = workspace.collections.engagements[0];

		if (engagement && singleEngagementData.gacomponentguid != '') {
			let url =
				engagement.data.incountryuri +
				'/v1redirect/index?' +
				`countryid=${workspace.data.countryid}&` +
				`engagementid=${engagement.id}&` +
				`engagementdesc=${encodeURIComponent(engagement.data.engagementdesc.substring(0, 50))}&` +
				`clientid=${client.id}&` +
				`clientname=${encodeURIComponent(client.data.clientname)}&` +
				`workspacedesc=${encodeURIComponent(workspace.data.workspacedesc)}&` +
				`workspaceid=${workspace.id}&` +
				`userPrefLang=${userSettings.data.uidisplaylanguage_resourcename}&` +
				`gacomponentguid=${singleEngagementData.gacomponentguid}&` +
				`engagementversion=${engagement.data.engagementversion.split('.')[0]}&` +
				`engagementversionfull=${engagement.data.engagementversion}&` +
				singleEngagementData.viewinstruction;

			url = url.replace(/\s/g, '+');

			window.location.href = url;
		}
	};

	const gotoGroupInstructions = async (notification) => {
		let url =
				env.getURL('serviceUrl') +
				'/' +
				urls.HOME_URL +
				'?phase=MyNavigation&Top=1&Skip=1&filters=searchtypeid%20eq%204,displaytypeid%20eq%201&searchexpression=' +
				notification.engagementId,
			data = {
				gacomponentguid: notification.otherGuid,
				viewinstruction: notification.hasReceivedInstructionsUpdate
					? 'updatedinstructions=true'
					: 'viewinstruction=true'
			};
		await dispatch(getSingleEngagement(url, data));
	};

	const onArchiveAcceptRejectInviteClick = (notification, useraction) => {
		dispatch(
			patchCallAPI(
				env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
				JSON.stringify(getArchiveInviteModel(notification, useraction))
			)
		)
			.then((x) => {
				setacceptedArchiveEngagementList(
					acceptedArchiveEngagementList.concat({
						id: notification.id,
						action: useraction
					})
				);
				setisArchiveInviteAcceptedOrRejected(true); // to notify notification.js that api call is succesfull -- this is for item
				setisAnyArchiveInviteAcceptedOrRejected(true); // to notify CustomTooltip if any action has been taken
			})
			.catch((error) => {
				if (error.response.statusText === applicationErrorCode.EngagementUserNotActive) {
					setacceptedEngagementList(
						acceptedEngagementList.concat({
							id: notification.engagementId,
							action: userAction.error
						})
					);
					setisArchiveInviteAcceptedOrRejected(true);
				}
			});
	};

	const onCopyEngagementErrorRetry = async (notifications) => {
		const url =
			env.getURL('serviceUrl') +
			'/' +
			urls.ENGAGEMENTS_URL +
			'/' +
			notifications.engagementId +
			'?phase=EnableCopyRequestNotificationToRetryImport';

		let mdlRetry = {
			id: notifications.engagementId,
			data: {
				engagementstatusid: EngagementStatus.Deleted
			}
		};

		await dispatch(PatchEngagement(url, JSON.stringify(mdlRetry)));
	};

	const createUserAcknowledgement = async (acknowledmentModel, phase) => {
		let url = env.getURL('serviceUrl') + '/' + urls.USERACKNOWLEDGEMENT_URL;

		if (phase) {
			url += phase;
		}

		await dispatch(postData(url, JSON.stringify(acknowledmentModel)));
	};

	const handleSetCloseNotification = (notification) => {
		dispatch(setCloseNotification(notification));
	};

	return (
		<ThemeProvider theme={getTheme(theme)}>
			<NotificationsCenterContainer
				className={hideNotification ? 'NotificationsContainer notification-disabled' : 'NotificationsContainer'}
			>
				<CustomTooltip
					iconName={'bell'}
					styleName={'notificationButton'}
					title={labels.bellIconTitle}
					iconClassName={'large'}
					position={'bottom right'}
					popupArrowColor={'popupArrowColor'}
					count={count}
					popupVisible={popupVisible}
					containerClass={'NotifationContainerClass'}
					isAnyInviteAcceptedOrRejected={isAnyInviteAcceptedOrRejected}
					isAnyArchiveInviteAcceptedOrRejected={isAnyArchiveInviteAcceptedOrRejected}
					refreshDashboard={refreshDashboardAndNotifications}
					onPopupShow={() => showNotification(true)}
					onPopupHide={() => showNotification(false)}
				>
					<NotificationGroups
						notifications={notifications}
						onFirstButtonClick={onFirstButtonClick}
						onSecondButtonClick={onSecondButtonClick}
						setCloseNotification={handleSetCloseNotification}
						closeNotification={closeNotification}
						acceptedEngagementList={acceptedEngagementList}
						acceptedArchiveEngagementList={acceptedArchiveEngagementList}
						isInviteAcceptedOrRejected={isInviteAcceptedOrRejected}
						isArchiveInviteAcceptedOrRejected={isArchiveInviteAcceptedOrRejected}
						setisInviteAcceptedOrRejectedToDone={setisInviteAcceptedOrRejectedToDone}
						setisArchiveInviteAcceptedOrRejectedToDone={setisArchiveInviteAcceptedOrRejectedToDone}
					/>
				</CustomTooltip>
			</NotificationsCenterContainer>
		</ThemeProvider>
	);
};

export default Notifications;

const NotificationsCenterContainer = styled.section`
	&.notification-disabled {
		.notificationButton {
			pointer-events: none;
			cursor: default;
			svg {
				fill: ${lightColor[`white`]} !important;
				cursor: default;
			}
			.btnCount {
				background-color: ${(props) => props.theme.color[`grey300`]};
				cursor: default;
			}
		}
	}
	.show-pop-content {
		width: 70rem;
		z-index: 120;
		font-size: 1.2rem;
		top: 5.3rem;
		.show-pop-content-body {
			max-height: 65vh;
			overflow-y: scroll;
		}
	}
	.notificationButton {
		background-color: transparent;
		border: none;
		&.active {
			svg {
				fill: ${(props) => props.theme.color[`yellow400`]} !important;
			}
		}

		svg {
			fill: ${lightColor[`white`]} !important;
		}
	}
`;
