import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';

function CustomTooltip(props) {
	const [popupVisible, setPopupVisible] = useState(false);
	const [popupTopPosition, setPopupTopPosition] = useState('top');
	const [popupLeftPosition, setPopupLeftPosition] = useState(
		props.direction || 'right'
	);
	const node = useRef();

	const {validatePosition} = props;

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	const handleClick = (e) => {
		if (!popupVisible) {
			setPopupVisible(true);
			if (props.onPopupShow) {
				props.onPopupShow(e);
			}
		} else {
			setPopupVisible(false);
			if (props.onPopupHide) {
				props.onPopupHide(e);
			}
		}

		let bound = e.currentTarget.getBoundingClientRect();
		let winHeight = window.innerHeight;
		let rBottom = winHeight - bound.bottom;
		let winWidth = window.innerWidth;
		let rLeft = winWidth - bound.left;

		if (rBottom > 250) {
			setPopupTopPosition('bottom');
		} else {
			setPopupTopPosition('top');
		}

		if (rLeft > 300 && !direction) {
			setPopupLeftPosition('left');
		}

		if (validatePosition) {
			const newDirection = rLeft < 620 ? 'right' : 'left';
			setPopupLeftPosition(newDirection);
		}
	};

	const handleDivClick = (e) => {
		// if click is performed on the search input of the TimePhase ToolTip, the ToolTip will not hide
		let clickedElement = e.target;
		if (
			clickedElement.classList.contains('closePopup') ||
			clickedElement.id == 'fileOptions'
		) {
			setPopupVisible(false);
		} else {
			if (props.callBackParent) {
				props.callBackParent(e);
			}
		}
	};

	const handleOutsideClick = (e) => {
		if (node.current.contains(e.target)) {
			// inside click
			return;
		} else {
			// outside click
			setPopupVisible(false);
			if (props.onPopupHide) {
				props.onPopupHide();
			}
		}
	};

	const {
		containerClass,
		styleName,
		label,
		children,
		title,
		dueDate,
		userInitials,
		iconName,
		iconClassName,
		position,
		popupArrowColor,
		buttonType,
		defaultLoad,
		disabled,
		direction,
		showPopup,
		id,
		ariaLabel
	} = props;

	const renderChildren = () => {
		if (typeof children === 'function') {
			return children(setPopupVisible);
		}
		return children;
	};

	return (
		<StyledPopOver className={containerClass}>
			<span ref={node}>
				<button
					className={styleName}
					title={title}
					disabled={disabled}
					onClick={handleClick}
					aria-label={ariaLabel}
				>
					{iconName && (
						<SVGICONS
							styleName={iconClassName}
							name={iconName}
							hoverText={title}
							id={id ? id : ''}
						/>
					)}
					{label && (
						<span className="btnLabel" id={id ? id : ''}>
							{label}
						</span>
					)}
					{dueDate && <>{dueDate}</>}
					{userInitials && <>{userInitials}</>}
				</button>
				{!defaultLoad && showPopup && popupVisible && (
					<section
						className={`
							${'show-pop-content'}
							${position ? position : `${popupTopPosition} ${popupLeftPosition}`}
							${buttonType ? 'ey-button' : ''}
						`}
					>
						<section className={`${popupArrowColor} show-popup-arrow`} />
						<section
							className="showPopupWrapper customScrollbar"
							onClick={handleDivClick}
						>
							{renderChildren()}
						</section>
					</section>
				)}
				{defaultLoad && showPopup && (
					<section
						className={`
							${'show-pop-content'}
							${popupVisible ? 'showPopUp' : 'hidePopUp'}
							${position ? position : `${popupTopPosition} ${popupLeftPosition}`}
							${buttonType ? 'ey-button' : ''}
						`}
					>
						<section className={`${popupArrowColor} show-popup-arrow`} />
						<section className="showPopupWrapper" onClick={handleDivClick}>
							{renderChildren()}
						</section>
					</section>
				)}
			</span>
		</StyledPopOver>
	);
}

export default CustomTooltip;

CustomTooltip.propTypes = {
	containerClass: PropTypes.string,
	styleName: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
		PropTypes.func
	]),
	title: PropTypes.string,
	dueDate: PropTypes.string,
	userInitials: PropTypes.string,
	iconName: PropTypes.string,
	iconClassName: PropTypes.string,
	position: PropTypes.string,
	popupArrowColor: PropTypes.string,
	buttonType: PropTypes.string,
	defaultLoad: PropTypes.bool,
	disabled: PropTypes.bool,
	direction: PropTypes.string,
	showPopup: PropTypes.bool,
	ariaLabel: PropTypes.string
};

CustomTooltip.defaultProps = {
	showPopup: true
};

const StyledPopOver = styled.span`
	position: relative;
	button {
		padding: 0;
		svg {
			fill: ${(props) => props.theme.color[`grey500`]};
		}
	}
	.ey-primary-button {
		padding: 0.9rem 1.2rem;
		border: 0.1rem solid ${(props) => props.theme.color[`grey500`]};
		font-size: 1.4rem;
		svg {
			&.right {
				float: right;
			}
			&.left {
				margin-right: 0.5rem;
			}
			&.deg90 {
				transform: rotate(90deg);
			}
		}
		&:hover {
			background: ${(props) => props.theme.color[`grey500`]};
			color: ${(props) => props.theme.color[`white`]};
			svg {
				fill: ${(props) => props.theme.color[`white`]};
			}
		}
		.btnLabel {
			line-height: 2rem;
		}
	}

	.ey-secondary-button {
		padding: 0.9rem 1.5rem;
		border: 0.1rem solid ${(props) => props.theme.color[`grey500`]};
		color: ${(props) => props.theme.color[`grey500`]};
		font-size: 1.4rem;
		svg {
			fill: ${(props) => props.theme.color[`grey500`]};
			&.right {
				float: right;
			}
			&.left,
			&.center {
				margin-right: 0.5rem;
			}
			&.deg90 {
				transform: rotate(90deg);
			}
		}
		&:hover {
			background: ${(props) => props.theme.color[`grey500`]};
			color: ${(props) => props.theme.color[`white`]};
			svg {
				fill: ${(props) => props.theme.color[`white`]};
			}
		}
		.btnLabel {
			line-height: 2rem;
			margin-right: 0.5rem;
		}
	}

	.show-pop-content {
		background: ${(props) => props.theme.color[`white`]};
		border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
		width: auto;
		padding: 0;
		position: absolute;
		z-index: 999;
		box-shadow: 0.4rem 0.4rem 0.4rem ${(props) => props.theme.color[`grey200`]};
		&.ey-button {
			top: 3.5rem;
		}
		&.showPopUp {
			display: block;
		}
		&.hidePopUp {
			display: none;
		}

		.closePopup.disabled {
			pointer-events: none;
		}

		&.bottom {
			&.left {
				left: 0;
				.show-popup-arrow {
					top: 0%;
					left: 0.8rem;
				}
			}

			&.center {
				left: -50%;
				.show-popup-arrow {
					left: 50%;
				}
			}
			&.right {
				right: -1rem;
				.show-popup-arrow {
					right: 1.5rem;
					margin: -0.6rem;
				}
			}
		}

		&.top {
			bottom: 3rem;
			.show-popup-arrow {
				bottom: 0;
				transform: rotate(45deg);
			}

			&.left {
				.show-popup-arrow {
					left: 10%;
					bottom: 0;
				}
			}

			&.center {
				left: -50%;
				.show-popup-arrow {
					left: 50%;
				}
			}

			&.right {
				right: -2rem;
				.show-popup-arrow {
					right: 2.5rem;
				}
			}
		}

		.show-popup-arrow {
			height: 1.4rem;
			width: 1.4rem;
			position: absolute;
			background: ${(props) => props.theme.color[`white`]};
			transform: rotate(225deg);
			margin: -0.5rem;
			z-index: -1;
			box-shadow: ${(props) => props.theme.color[`grey300`]} 0.1rem 0.1rem
				0.1rem;
		}

		h4 {
			margin: 0;
			padding: 1rem;
			background-color: ${(props) => props.theme.color[`grey100`]};
			border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
			font-weight: bold;
			font-size: 1.5rem;
		}

		h3 {
			margin: 0;
			font-weight: bold;
		}
	}
`;
