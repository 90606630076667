import React from 'react';
import { useLocation } from 'react-router-dom';
import SelectedEngagementHeader from './selectedengagementsheader';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import env from '../../../../util/env';
import {
	getEngagementDetail,
	getEngagementUserStatuses,
	getIndependenceStatuses,
	getEngagementstatus
} from '../../../../actions/admin/engagement/engagementActions';
import Loader from '@ey/loader';
import SelectedEngagementError from './selectedengagementerror';
import EngagementUsersBase from '../engagementsusers/engagementuserbase';
import { useParams } from 'react-router-dom';
import AccessDeniedMessage from '../../../Common/AccessDeniedMessage/AccessDeniedMessage';
import {
	labels,
	engagementsSearchRetainFlag
} from '../../../../util/uiconstants';

function SelectedEngagementDetails() {
	const location = useLocation();
	const dispatch = useDispatch();
	const engagementStatus = useSelector((store) => store.status);
	const engagementDetails = useSelector((store) => store.engagementDetails);
	const engagementUserStatuses = useSelector(
		(store) => store.engagementUserStatuses
	);
	const independenceStatuses = useSelector(
		(store) => store.independenceStatuses
	);
	const [isLoading, setIsLoading] = useState(true);
	const [
		engagementStatusesDDOptions,
		setEngagementStatusesDDOptions
	] = useState([]);
	const { engagementId } = useParams();

	const countryid = new URLSearchParams(location.search).get('countryid');
	const ssl = new URLSearchParams(location.search).get('ssl');

	useEffect(() => {
		localStorage.setItem(
			engagementsSearchRetainFlag.IsRetainEngagementsSearchResult,
			true
		);
	}, []);

	useEffect(() => {
		setIsLoading(true);
		let engagementDetailsUrl =
			env.getURL('serviceUrlV2') +
			'admin/subservicelines/' +
			ssl +
			'/countries/' +
			countryid +
			'/Engagements/' +
			engagementId;

		var allAPIPromises = [dispatch(getEngagementDetail(engagementDetailsUrl))];

		if (Object.keys(engagementStatus).length == 0) {
			let engagementStatusUrl =
				env.getURL('serviceUrlV2') + 'EngagementStatuses';
			allAPIPromises.unshift(
				dispatch(getEngagementstatus(engagementStatusUrl))
			);
		}

		if (Object.keys(engagementUserStatuses).length === 0) {
			let userStatusesApiUri =
				env.getURL('serviceUrlV2') + 'EngagementUserStatuses';
			allAPIPromises.unshift(
				dispatch(getEngagementUserStatuses(userStatusesApiUri))
			);
		}

		if (Object.keys(independenceStatuses).length === 0) {
			let independenceStatusesapiUri =
				env.getURL('serviceUrlV2') + 'IndependenceStatuses';
			allAPIPromises.unshift(
				dispatch(getIndependenceStatuses(independenceStatusesapiUri))
			);
		}

		Promise.all(allAPIPromises)
			.then(() => {
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	}, []);

	const isUserEngagementAccess = () => {
		if (engagementDetails && engagementDetails?.permissions) {
			var permissions = engagementDetails?.permissions;
			if (
				permissions?.canInviteEngagementMember == true ||
				permissions?.canReadEngagement == true ||
				permissions?.canUpdateEngagement == true ||
				permissions?.canUpdateEngagementUser == true ||
				permissions?.canRetryArchiveEngagement == true
			) {
				return true;
			}
		}
		return false;
	};

	useEffect(() => {
		var engagementStatusItems = [];
		_.forEach(engagementStatus, (item) => {
			engagementStatusItems.push({
				label: item.statusName,
				value: item.statusId
			});
		});
		setEngagementStatusesDDOptions(engagementStatusItems);
	}, [engagementStatus]);

	const renderMainContent = () => {
		return (
			<>
				<SelectedEngagementHeader
					editPermission={engagementDetails?.permissions?.canUpdateEngagement}
					retryArchivePermission={engagementDetails?.permissions?.canRetryArchiveEngagement}
					engagementStatusesDDOptions={engagementStatusesDDOptions}
					engagementDetails={engagementDetails}
				></SelectedEngagementHeader>
				<EngagementUsersBase
					engagementId={engagementId}
					countryid={countryid}
					ssl={ssl}
					editPermission={
						engagementDetails?.permissions?.canUpdateEngagementUser
					}
					canInviteEngagementMember={
						engagementDetails?.permissions?.canInviteEngagementMember
					}
					userStatusesDDOptions={engagementUserStatuses}
					independenceStatusesDDOptions={independenceStatuses}
					engagementStatusId={engagementDetails?.engagementStatus}
				></EngagementUsersBase>
			</>
		);
	};

	return (
		<>
			{isLoading == true ? (
				<Loader />
			) : engagementDetails?.engagementId == undefined ||
				engagementDetails == null ? (
				<SelectedEngagementError />
			) : !isUserEngagementAccess() ? (
				<AccessDeniedMessage
					permissionMesage={labels?.engagementDetailsNoPermission}
				/>
			) : (
				renderMainContent()
			)}
		</>
	);
}

export default SelectedEngagementDetails;
