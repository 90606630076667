import React from 'react';
import DocumentsTableHeader from './documentstableheader';
import {labels} from '../../../../util/uiconstants';
import Loader from '@ey/loader';
import DocumentsList from './documentslist';
import Documenttablestyle from './documenttablestyle'
function DocumentsTable(props) {
    return (
        <Documenttablestyle className="documentpageview mainResponsiveWrapper">
            {props.isLoading ? (
                <Loader show view="blockView" styleName="loadingstyle" />
            ) : (
                <section className="documentwrapper">
                    <DocumentsTableHeader />
                    {
                        props.data && props.data?.length > 0 ? (
                            <section className="customScrollbar document-body">
                                <DocumentsList list={props.data} />
                            </section>
                        ) : (
                            <section className="noresult">
                                {labels.docListNoResults}
                            </section>
                        )
                    }
                </section>
            )}
        </Documenttablestyle>
    );
}

export default DocumentsTable;
