/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import Loader from '@ey/loader';
import AddNewEngagementCodeList from './addnewengagementcodelist';
import Button from '@ey/button';
import {EngagementStatus} from '../../../util/enumerations';
import SubServiceLineList from './subserviceline';
import {getEngagementCodeName} from '../../../actions/engagementcodenameaction';
import {getPrimaryoffices} from '../../../actions/primaryofficesaction';
import {getServiceLines} from '../../../actions/servicelinesaction';
import {
	saveNewEngagement,
	updateEngagement
} from '../../../actions/workspacesactions';
import {getLanguages} from '../../../actions/languageactions';
import env from '../../../util/env';
import {EYForm, FormTextInput, FormComboBox, FormStaticText} from '@ey/form';
import EllipsesControl from '@ey/ellipses-control';
import Calendar from '../.././Common/MotifDatePicker/datepicker';
import Createeditstyle from './createeditstyle';
import clonedeep from 'lodash/cloneDeep';
import EngagementProfileUpdateModal from '../../Engagements/EngagementProfileUpdateModal';
import moment from 'moment';
import {
	labels,
	urls,
	standardStrings,
	dateFormatsListItems,
	dateFormats,
	currentResource,
	getDateformat,
	permissionGroups
} from '../../../util/uiconstants';
import {useDidMountEffect} from '../../../util/customHooks';
import uuidv4 from 'uuid/v4';

const CreateEngagementStep3 = (props) => {
	const {
		engagementname,
		userprimaryofficeid,
		userlanguage,
		serviceline,
		subserviceline,
		engagementcodearray,
		clientenddate,
		engagementenddate,
		auditorsreportdate,
		mode,
		isSubServiceLineDisabled,
		isAddEngagementCodeDisabled,
		engagementid,
		engagementstatus,
		setParentLoaderOffOnFinish,
		onComponentDisplayed,
		onFinishClick,
		getDependencyData,
		engagementdata,
		isComponentValid,
		closeWizard,
		iscreateengagementloading
	} = props;

	const dispatch = useDispatch();

	const primaryoffices = useSelector((storeState) => storeState.primaryoffices);
	const servicelines = useSelector((storeState) => storeState.servicelines);
	const workspaceData = useSelector(
		(storeState) => storeState.individualWorkspaceData
	);
	const clientengagement = useSelector(
		(storeState) => storeState.clientengagement
	);
	const languages = useSelector((storeState) => storeState.languages);
	const userPermissions = useSelector(
		(storeState) => storeState.userPermissions
	);

	const userHasPermissions = () => {
		if (userPermissions?.userpermissions) {
			var item = userPermissions?.userpermissions.find(
				(x) => x.groupname === permissionGroups.Global_GCOCreateGCOEngagements
			);
			return item != null ? true : false;
		}
	};

	/* Load Sub service line dropdown */
	const loadSubServiceLine = () => {
		let servicelinelist = {};
		let subservicelinelist = [];
		let selectedserviceline = serviceLine;
		servicelinelist =
			servicelines &&
			servicelines.collections &&
			servicelines.collections.servicelines;
		servicelinelist &&
			servicelinelist.map(function (serviceline) {
				if (serviceline.id == selectedserviceline) {
					subservicelinelist = serviceline.collections.subservicelinesdata;
				}
			});
		return subservicelinelist;
	};

	const modes = {
		create: 'create',
		edit: 'edit'
	};

	const [engagementName, setEngagementname] = useState(
		engagementname || standardStrings.EMPTY
	);
	const [userPrimaryOfficeId, setUserprimaryofficeid] = useState(
		userprimaryofficeid || undefined
	);
	const [userLanguage, setUserlanguage] = useState(userlanguage || undefined);
	const [serviceLine, setServiceline] = useState(serviceline || undefined);
	const [subServiceLine, setSubserviceline] = useState(
		subserviceline || undefined
	);
	const [isLoading, setIsLoading] = useState(false);
	const [engagementCodeArray, setEngagementCodeArray] = useState(
		engagementcodearray || []
	);
	const [profileUpdate, setProfileUpdate] = useState(false);
	const isSubServiceLineDisabledLocal =
		mode == modes.create
			? false
			: isSubServiceLineDisabled !== undefined &&
			  !(mode === modes.edit && !userHasPermissions())
			? isSubServiceLineDisabled
			: true;
	const [
		isAddEngagementCodeDisabledState,
		setIsAddEngagementCodeDisabledState
	] = useState(isAddEngagementCodeDisabled || true);
	const engagementId = engagementid || 0;
	const isPrimaryOfficeDisabled =
		engagementstatus == EngagementStatus.Restored ? true : false;
	const isLanguageDisabled =
		engagementstatus == EngagementStatus.Restored || mode === modes.edit
			? true
			: false;
	const isServiceLineDisabled =
		engagementstatus == EngagementStatus.Restored ||
		(mode === modes.edit && !userHasPermissions())
			? true
			: false;
	const isStartDateDisabled =
		engagementstatus == EngagementStatus.Restored ? true : false;
	const isEndDateDisabled =
		engagementstatus == EngagementStatus.Restored ? true : false;
	const isReportDateDisabled =
		engagementstatus == EngagementStatus.Restored ? true : false;
	const isEngagementCodeDisabled =
		engagementstatus == EngagementStatus.Restored ? true : false;
	const [latestEngagementCode, setLatestEngagementCode] = useState('');
	const [latestEngagementCodeIndex, setLatestEngagementCodeIndex] = useState(0);
	const [validationTriggerCounter, setValidationTriggerCounter] = useState(0);
	const [showSubServiceValidation, setShowSubServiceValidation] = useState(0);
	const [isSaving, setIsSaving] = useState(false);
	const [subservicelineOption, setSubservicelineOption] = useState([]);

	const formatDateTime = (date) => {
		return date
			? getDateformat(
					dateFormats,
					moment.utc(date).format(dateFormatsListItems[dateFormats].label)
			  )
			: standardStrings.EMPTY;
	};

	const [clientEndDate, setClientEndDate] = useState(
		formatDateTime(clientenddate)
	);
	const [engagementEndDate, setEngagementEndDate] = useState(
		formatDateTime(engagementenddate)
	);
	const [auditorsReportDate, setAuditorsReportDate] = useState(
		formatDateTime(auditorsreportdate)
	);

	const serviceLineOption = (serviceline) => {
		return {
			value: serviceline.data.servicelineid,
			label: serviceline.data.servicelinename
		};
	};

	let archiveOverrideStatusAccepted = 'overrideaccepted';

	let primaryOfficeOption = function (primaryoffice) {
		return {
			value: primaryoffice.id,
			label: primaryoffice.data.name
		};
	};

	let languagesListOption = function (language) {
		return {
			value: language.id,
			label: language.data.name
		};
	};

	const LatestEngagementCode = (key, value) => {
		setLatestEngagementCode(value);
		setLatestEngagementCodeIndex(key.split('_')[1]);
	};

	useEffect(() => {
		setIsLoading(true);
		setEngagementCodeArray(
			engagementCodeArray.length > 0
				? engagementCodeArray
				: [
						{
							code: '',
							name: labels.engagementcodenameplaceholder,
							clientengagementid: '',
							isprimary: true,
							cross: '',
							invalidengagementcodeerror: 0,
							hidedeletebutton: '',
							canremove: false,
							clientcodevalid: true,
							engagementcode: '',
							clientcodeid: '',
							clientid: 0,
							engcodename: '',
							engagementCodeId: uuidv4()
						}
				  ]
		);
		configureFormFields();
	}, []);

	useEffect(() => {
		let emptyengagementcodes = 0;
		engagementCodeArray.forEach((engcodeobject) =>
			engcodeobject.code.length == 0 ? emptyengagementcodes++ : 0
		);
		let isAddEngagementCodeDisabledonMount =
			engagementCodeArray.length > 0 &&
			engagementstatus != EngagementStatus.Restored
				? emptyengagementcodes > 0
				: true;

		setIsAddEngagementCodeDisabledState(isAddEngagementCodeDisabledonMount);
	}, [engagementCodeArray]);

	useDidMountEffect(() => {
		setComponentValid();
	}, [onComponentDisplayed]);

	useDidMountEffect(() => {
		handleFinishClick();
	}, [onFinishClick]);

	useDidMountEffect(() => {
		serviceLine > 0 ? setSubservicelineOption(loadSubServiceLine()) : null;
	}, [serviceLine]);

	useDidMountEffect(() => {
		serviceLine > 0 ? setSubservicelineOption(loadSubServiceLine()) : null;
	}, [servicelines]);

	/*************Below useEffect has been used to fix defect #1073511******************/
	useEffect(() => {
		let latestengcode = latestEngagementCode;
		let index = latestEngagementCodeIndex;
		async function engagementCodeValidation() {
			await validateEngagementCode(latestengcode, index);
		}
		if (!_.isEmpty(clientengagement)) engagementCodeValidation();
	}, [clientengagement]);

	useEffect(() => {
		setClientEndDate(formatDateTime(clientenddate));
		setAuditorsReportDate(formatDateTime(auditorsreportdate));
		setEngagementEndDate(formatDateTime(engagementenddate));
		setEngagementname(engagementname);
		setUserprimaryofficeid(userprimaryofficeid);
		setUserlanguage(userlanguage);
		setServiceline(serviceline);
		setSubserviceline(subserviceline);
		if (engagementcodearray?.length > 0) {
			setEngagementCodeArray(engagementcodearray);
		}
	}, [
		auditorsreportdate,
		clientenddate,
		engagementenddate,
		engagementname,
		userprimaryofficeid,
		userlanguage,
		serviceline,
		subserviceline,
		engagementcodearray
	]);

	const handleFinishClick = async () => {
		setValidationTriggerCounter((prev) => prev + 1);
		setShowSubServiceValidation((prev) => prev + 1);
		if (await isStepValid()) {
			try {
				setIsLoading(true);
				setIsSaving(true);
				if (mode == modes.create) {
					await onCreateEngagementFinishClick();
				} else {
					await updateEngagementCodeAndSaveEng();
				}
			} finally {
				setIsLoading(false);
			}
		}
	};

	const updateEngagementCodeAndSaveEng = async () => {
		let latestengcode = latestEngagementCode;
		//let index = latestEngagementCodeIndex;

		if (latestengcode != '') {
			let fetchEngagementCodeNameUrl =
				env.getURL('serviceUrl') +
				'/' +
				urls.CLIENTENGAGEMENT_URL +
				"?filters=clientengagementcode eq '" +
				latestengcode +
				"',clientid eq '" +
				getDependencyData('domainClientId') +
				"'";

			await dispatch(
				getEngagementCodeName(
					fetchEngagementCodeNameUrl,
					fetchEngagementCodeNameUrl.split('/api/v1')[0]
				)
			);
		}
		//await validateEngagementCode(latestengcode, index);
		await showConfirmationModal();
	};

	const getEngCodeArrayindexFromKey = (key) => {
		return engagementCodeArray.findIndex(
			(engCode) => engCode.engagementCodeId == key
		);
	};

	const validateEngagementCode = (latestengcode, key) => {
		var engCodeArray = engagementCodeArray;
		var clientID = Number.parseInt(getDependencyData('domainClientId'));
		var indexFromKey = getEngCodeArrayindexFromKey(key);
		if (latestengcode == '') {
			var engagementcodeobject = {
				code: latestengcode,
				name: labels.invalidengagementcode,
				clientengagementid: '',
				isprimary: engagementCodeArray[indexFromKey]?.isprimary,
				cross: '',
				invalidengagementcodeerror: 1,
				hidedeletebutton: '',
				canremove: false,
				clientcodevalid: true,
				engagementcode: latestengcode,
				clientcodeid: clientID,
				clientid: clientID,
				engcodename: labels.invalidengagementcode,
				engagementCodeId: uuidv4()
			};
		} else {
			var engagementcodenamefromDB = clientengagement && clientengagement.data;
			var engagementcodeobject = {
				code: engagementcodenamefromDB.mercuryengagementcode
					? engagementcodenamefromDB.mercuryengagementcode
					: engagementcodenamefromDB.engagementcode,
				name:
					engagementcodenamefromDB == null
						? labels.invalidengagementcode
						: engagementcodenamefromDB.isclientengagementvalid
						? engagementcodenamefromDB.clientengname
						: engagementcodenamefromDB.clientengname +
						  ' - ' +
						  labels.engagementcodevalidationmessage,
				clientengagementid: (clientengagement && clientengagement.id) || '',
				isprimary: engagementCodeArray[indexFromKey].isprimary,
				cross: '',
				invalidengagementcodeerror: engagementcodenamefromDB == null ? 1 : 0,
				hidedeletebutton: '',
				canremove: false,
				clientcodevalid: true,
				engagementcode: engagementcodenamefromDB.mercuryengagementcode
					? engagementcodenamefromDB.mercuryengagementcode
					: engagementcodenamefromDB.engagementcode,
				clientcodeid: clientID || '',
				clientid: clientID,
				mercuryengagementcode: engagementcodenamefromDB.mercuryengagementcode,
				engcodename:
					engagementcodenamefromDB == null
						? labels.invalidengagementcode
						: engagementcodenamefromDB.isclientengagementvalid
						? engagementcodenamefromDB.clientengname
						: engagementcodenamefromDB.clientengname +
						  ' - ' +
						  labels.engagementcodevalidationmessage
			};
		}

		// to check if engagement code is added or edited. For edited engagements add id as well
		if (engagementCodeArray[indexFromKey].engagementCodeId) {
			engagementcodeobject.engagementCodeId =
				engagementCodeArray[indexFromKey].engagementCodeId;
		}
		engCodeArray[indexFromKey] = engagementcodeobject;
		setEngagementCodeArray((prev) => {
			return prev.map((engCode) => {
				if (engCode.engagementCodeId == engagementcodeobject.engagementCodeId) {
					return {
						...engagementcodeobject,
						engagementCodeId: uuidv4()
					};
				}
				return engCode;
			});
		});
	};

	const configureFormFields = async () => {
		try {
			await loadPrimaryOffices();
			await loadLanguages();
			await loadServiceLines();
		} finally {
			setIsLoading(false);
		}
	};

	/* API call to fetch Primary offices */
	const loadPrimaryOffices = async () => {
		let fetchPrimaryOfficesUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.COUNTRY_OFFICE_URL +
			'?' +
			'filters=languageid eq 1,countryid eq ' +
			Number.parseInt(getDependencyData('domainLocation')) +
			',defaultlanguage eq english';

		await dispatch(
			getPrimaryoffices(
				fetchPrimaryOfficesUrl,
				fetchPrimaryOfficesUrl.split('/api/v1')[0]
			)
		);
	};

	// API call to fetch Languages
	const loadLanguages = async () => {
		let fetchLanguagesUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.LANGUAGES_URL +
			'?' +
			'filters=isactive eq true';

		await dispatch(
			getLanguages(fetchLanguagesUrl, fetchLanguagesUrl.split('/api/v1')[0])
		);
	};

	/*API call to fetch service lines and sub service lines data*/
	const loadServiceLines = async () => {
		let operationtype = 'createengagements';
		let fetchServiceLinesUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.SERVICE_LINES_URL +
			'?phase=' +
			operationtype +
			'&countryid=' +
			getDependencyData('domainLocation');

		await dispatch(
			getServiceLines(
				fetchServiceLinesUrl,
				fetchServiceLinesUrl.split('/api/v1')[0]
			)
		);
	};

	const isStepValid = async () => {
		if (
			engagementName.length > 0 &&
			userPrimaryOfficeId != standardStrings.EMPTY &&
			userPrimaryOfficeId > 0 &&
			userLanguage != standardStrings.EMPTY &&
			userLanguage > 0 &&
			serviceLine != standardStrings.EMPTY &&
			serviceLine > 0 &&
			subServiceLine != standardStrings.EMPTY &&
			subServiceLine > 0 &&
			clientEndDate != standardStrings.EMPTY &&
			engagementEndDate != standardStrings.EMPTY &&
			auditorsReportDate != standardStrings.EMPTY
		) {
			return true;
		}

		return false;
	};
	const setComponentValid = async () => {
		if (isStepValid()) {
			isComponentValid(true);
		} else {
			isComponentValid(false);
		}
	};

	const onCreateEngagementFinishClick = async () => {
		var userprimaryofficename;
		var primaryofficeid = userPrimaryOfficeId;
		primaryoffices &&
			primaryoffices.collections &&
			primaryoffices.collections.countryofficeregioncollection.map(function (
				primaryoffice
			) {
				if (primaryoffice.id == primaryofficeid) {
					userprimaryofficename = primaryoffice.data.name;
				}
			});
		var engagementdatamodel = {};
		engagementdatamodel.data = {
			isnewengagement: false,
			description: engagementName,
			primaryoffice: String(userPrimaryOfficeId),
			primaryofficename: userprimaryofficename,
			languageid: userLanguage,
			languagename: '',
			yearenddate: clientEndDate,
			enddateperiod: engagementEndDate,
			reportreleasedate: auditorsReportDate,
			archivedeadlinedate: '',
			engagementstatusid: 0,
			engagementuserstatusid: 0,
			engroleid: 0,
			archivalengagementid: null,
			engagementversion: '',
			operationtype: '',
			iscollapsed: false,
			convertedyearenddate: '',
			convertedenddateperiod: '',
			convertedreportreleasedate: '',
			isremovable: false,
			isremoved: false,
			countryid: getDependencyData('domainLocation'),
			saved: false,
			edited: false,
			showcompleteallfields: false,
			shownewengagementbutton: true,
			engcodenotunique: false,
			archiveoverridedays: '',
			engagementurl: '',
			archiveoverridestatus: '',
			archiveoverridedeadlinedate: '',
			archiveoverriderequestdate: '',
			archiveoverriderequestuser: '',
			archiveoverridechange: false,
			clientcode: getDependencyData('domainClientCode'),
			clientcodevalid: '',
			clientid: getDependencyData('domainClientId'),
			servicelineid: serviceLine,
			subservicelineid: parseInt(subServiceLine),
			selectedCountryId: getDependencyData('domainLocation'),
			archivedcountryid: 0,
			totaltaskscount: 0,
			completedtaskscount: 0,
			openmisstatements: 0,
			opendeficiencies: 0,
			newtasksassignedtome: 0,
			newtasksformyreview: 0,
			newreviewnotesforme: 0,
			newreviewnotesbyme: 0,
			isprofilecomplete: false,
			isindependencetemplateavailable: false,
			independencestatusid: 0,
			issynced: false,
			clientcodeid: '',
			invalidengagementdescerrormessage: false,
			subserviceline: String(subServiceLine),
			serviceline: String(serviceLine),
			importtemplatedetails: {},
			engagementsourceid: null
		};

		engagementdatamodel.data.importtemplatedetails.collections = {};
		var servicelinesmodel = [{}];
		let servicelinelist =
			servicelines &&
			servicelines.collections &&
			servicelines.collections.servicelines;
		servicelinelist &&
			servicelinelist.map(
				(serviceline, index) => (
					(servicelinesmodel[index] = {}),
					(servicelinesmodel[index].data = serviceline),
					(servicelinesmodel[index].id = serviceline.id)
				)
			);

		var engagementcodemodel = [{}];
		engagementCodeArray.map(
			(engcodeobject, index) => (
				(engagementcodemodel[index] = {}),
				(engagementcodemodel[index].data = engcodeobject)
			)
		);

		var engagementCollections = {engagementcodes: engagementcodemodel};
		var newEngagementModelData = {
			id: Number.parseInt(getDependencyData('domainWorkspaceId')) || 0
		};

		newEngagementModelData.collections = {
			engagements: [
				{
					data: engagementdatamodel.data,
					collections: engagementCollections
				}
			],
			clients: [],
			servicelines: servicelinesmodel
		};
		let isClientValid = !Number.isNaN(
			Number.parseInt(getDependencyData('domainClientId'))
		);
		newEngagementModelData.data = {
			clientcode: getDependencyData('domainClientCode'),
			clientname: getDependencyData('domainClientName'),
			workspacedesc: getDependencyData('domainWorkspaceName'),
			istrainingworkspace: false,
			isclientvalidated: isClientValid,
			clientid: getDependencyData('domainClientId'),
			countryid: getDependencyData('domainLocation'),
			countryname: '',
			operationtype: 'createengagements',
			rfworkspaceid: 0,
			iscurrent: false,
			engagementheader: 'Add Engagement',
			activerollforwardtab: 0,
			isworkspaceunique: true,
			cansave: false,
			enablecountry: true,
			isrollfwderror: false,
			engagementurl: '',
			isworkspaceselected: false,
			restoretypeid: '',
			restorenewclientcode: '',
			restorenewclientname: '',
			shownewengagementbutton: true,
			createeditworkspacepagetitle: 'Create Workspace',
			newcountryid: getDependencyData('domainLocation'),
			isworkspacenotempty: true
		};
		/* API call save a new engagement*/
		await saveEngagementIntoDB(newEngagementModelData);
	};

	/* Data creation for edit engagement api call*/
	const onEditEngagementFinishClick = async () => {
		/* Creating data model for editEngagement API call*/
		var userprimaryofficename;
		var primaryofficeid = userPrimaryOfficeId;
		primaryoffices &&
			primaryoffices.collections &&
			primaryoffices.collections.countryofficeregioncollection.map(function (
				primaryoffice
			) {
				if (primaryoffice.id == primaryofficeid) {
					userprimaryofficename = primaryoffice.data.name;
				}
			});

		var newEngagementModelData = {};
		newEngagementModelData = clonedeep(workspaceData);

		newEngagementModelData.data.operationtype = 'EditWorkspace';

		var engagementdatamodel = {};
		let engagementlist =
			newEngagementModelData &&
			newEngagementModelData.collections &&
			newEngagementModelData.collections.engagements;
		var selectedEngagementId = engagementId;
		engagementlist &&
			engagementlist.map(function (engagement) {
				if (engagement.id == selectedEngagementId) {
					engagementdatamodel = engagement;
				}
			});

		engagementdatamodel.data.primaryoffice =
			Number.parseInt(userPrimaryOfficeId);

		engagementdatamodel.data.primaryofficename = userprimaryofficename;
		engagementdatamodel.data.yearenddate = clientEndDate;
		engagementdatamodel.data.enddateperiod = engagementEndDate;
		engagementdatamodel.data.reportreleasedate = auditorsReportDate;
		engagementdatamodel.data.languageid = userLanguage;
		engagementdatamodel.data.servicelineid = serviceLine;
		engagementdatamodel.data.subservicelineid = parseInt(subServiceLine);
		engagementdatamodel.data.subserviceline = String(subServiceLine);
		engagementdatamodel.data.serviceline = String(serviceLine);
		engagementdatamodel.data.description = engagementName;
		engagementdatamodel.data.id = Number.parseInt(engagementId);
		engagementdatamodel.data.engagementid =
			Number.parseInt(selectedEngagementId);
		engagementdatamodel.data.archivalengagementid = null;
		engagementdatamodel.data.engagementversion = '';
		engagementdatamodel.data.operationtype = '';
		engagementdatamodel.data.convertedyearenddate = '';
		engagementdatamodel.data.convertedenddateperiod = '';
		engagementdatamodel.data.convertedreportreleasedate = '';
		engagementdatamodel.data.isremovable = false;
		engagementdatamodel.data.isremoved = false;
		engagementdatamodel.data.countryid = Number.parseInt(
			getDependencyData('domainLocation')
		);
		engagementdatamodel.data.saved = true;
		engagementdatamodel.data.edited = false;
		engagementdatamodel.data.showcompleteallfields = false;
		engagementdatamodel.data.shownewengagementbutton = true;
		engagementdatamodel.data.engcodenotunique = false;
		engagementdatamodel.data.archiveoverridedeadlinedate = '';
		engagementdatamodel.data.archiveoverriderequestdate = '';
		engagementdatamodel.data.archiveoverriderequestuser = '';
		engagementdatamodel.data.importtemplatedetails = {};
		engagementdatamodel.data.importtemplatedetails.collections = {};
		engagementdatamodel.data.engagementsourceid = null;

		var engagementcodemodel = [{}];
		engagementCodeArray.map(
			(engcodeobject, index) => (
				(engagementcodemodel[index] = {}),
				((engagementcodemodel[index].data = engcodeobject),
				(engagementcodemodel[index].id = engcodeobject.id))
			)
		);

		var engagementCollections = {engagementcodes: engagementcodemodel};

		engagementdatamodel.collections = engagementCollections;

		newEngagementModelData.collections.engagements = [engagementdatamodel];

		/* API call to update an engagement*/
		await updateEngagementInDB(newEngagementModelData);
	};

	const showConfirmationModal = async () => {
		if (
			mode == modes.edit &&
			(engagementstatus == EngagementStatus.Active ||
				engagementstatus == EngagementStatus.MarkedForDeletion) &&
			userlanguage != userLanguage
		) {
			setProfileUpdate(true);
		} else {
			await onEditEngagementFinishClick();
		}
	};

	const contentUpdateConfirmationClick = async () => {
		setProfileUpdate(false);
		setIsLoading(true);
		await onEditEngagementFinishClick();
	};

	/* API call save a new engagement*/
	const saveEngagementIntoDB = async (newEngagementModelData) => {
		setIsLoading(true);
		let saveEngagementUrl =
			env.getURL('serviceUrl') + '/' + urls.WORKSPACES_URL;
		Date.prototype.toJSON = function () {
			return moment(this).format('YYYY-MM-DD');
		};
		try {
			await dispatch(
				saveNewEngagement(
					saveEngagementUrl,
					JSON.stringify(newEngagementModelData)
				)
			);
		} catch {
			setIsLoading(false);
		} finally {
			closeWizard();
		}
	};

	/* API call to update an engagement*/
	const updateEngagementInDB = async (newEngagementModelData) => {
		let editEngagementUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.WORKSPACES_URL +
			'/' +
			Number.parseInt(getDependencyData('domainWorkspaceId'));

		Date.prototype.toJSON = function () {
			return moment(this).format('YYYY-MM-DD');
		};
		try {
			await dispatch(
				updateEngagement(
					editEngagementUrl,
					JSON.stringify(newEngagementModelData)
				)
			);
			closeWizard();
		} catch {
			setIsLoading(false);
			setParentLoaderOffOnFinish();
		}
	};

	const updateStateForOnBlurEngagementCode = (
		engagementcodeobject,
		engCodeId
	) => {
		setEngagementCodeArray((prev) => {
			return prev.map((engCode, i) => {
				if (engCode.engagementCodeId === engCodeId) {
					return engagementcodeobject;
				} else {
					return prev[i];
				}
			});
		});
		setIsAddEngagementCodeDisabledState(
			engagementcodeobject.code.length > 0 ? false : true
		);
	};

	const updatePrimaryInEngagementCodeState = (index) => {
		setEngagementCodeArray((prev) => {
			return prev.map((engcodeobject) => {
				if (engcodeobject.engagementCodeId == index) {
					engcodeobject.isprimary = true;
					return engcodeobject;
				} else {
					engcodeobject.isprimary = false;
					return engcodeobject;
				}
			});
		});
	};

	const deleteEngagementCodeClick = (e, engCodeId, isPrimary) => {
		setEngagementCodeArray((prev) => {
			const filtered = prev.filter(
				(item) => item.engagementCodeId !== engCodeId
			);
			if (isPrimary) {
				filtered[0].isprimary = true;
			}
			return filtered;
		});
	};

	const updateState = (key, value) => {
		switch (key) {
			case 'engagementname':
				setEngagementname(value);
				break;
			case 'userprimaryofficeid':
				setUserprimaryofficeid(value);
				break;
			case 'userlanguage':
				setUserlanguage(value);
				break;
			case 'serviceline':
				setServiceline(value);
				break;
			case 'subserviceline':
				setSubserviceline(value);
				break;
			default:
				break;
		}
	};

	const renderEngagementCodes = () => {
		return (
			<AddNewEngagementCodeList
				engagementcodearray={engagementCodeArray}
				clientid={getDependencyData('domainClientId')}
				updateStateOnBlur={updateStateForOnBlurEngagementCode}
				deleteEngagementCodeClick={deleteEngagementCodeClick}
				isDeletedDisabled={engagementCodeArray.length > 1 ? false : true}
				getLatestEngagementCode={LatestEngagementCode}
				isEngagementCodeDisabled={isEngagementCodeDisabled}
				updatePrimaryEngagementCode={updatePrimaryInEngagementCodeState}
			/>
		);
	};

	const onAddEngagementCodeClick = () => {
		var engagementcodelist = {
			code: '',
			name: labels.engagementcodenameplaceholder,
			clientengagementid: '',
			isprimary: false,
			cross: '',
			invalidengagementcodeerror: 0,
			hidedeletebutton: '',
			canremove: false,
			clientcodevalid: true,
			engagementcode: '',
			clientcodeid: '',
			clientid: 0,
			engcodename: '',
			engagementCodeId: uuidv4()
		};
		setEngagementCodeArray((prev) => [...prev, engagementcodelist]);
	};

	const getArchiveDeadlineDate = () => {
		let archiveDeadlineDate;

		if (
			String(engagementdata.archiveoverridestatus).toUpperCase() ===
			archiveOverrideStatusAccepted.toUpperCase()
		) {
			// date overriden
			archiveDeadlineDate =
				moment
					.utc(engagementdata.archiveoverridedeadlinedate)
					.format(dateFormatsListItems[dateFormats].label) +
				` (${labels.defaultOverriden})`;
		} else {
			archiveDeadlineDate = moment
				.utc(engagementdata.archivedeadlinedate)
				.format(dateFormatsListItems[dateFormats].label);
		}

		return archiveDeadlineDate;
	};

	return (
		<>
			{isLoading &&
			(mode == modes.create ||
				(mode == modes.edit && iscreateengagementloading)) ? (
				<Loader view={isSaving ? 'fullscreen' : null} />
			) : (
				<Createeditstyle>
					<section className="createeditengagement">
						{mode == modes.create ? (
							<section>
								<header>
									<h5>{labels.createengagementquestionlabel}</h5>
								</header>
								<section className="clientworkspaceDetails">
									<FormStaticText label={labels.client + ':'}>
										<EllipsesControl
											id=""
											content={getDependencyData('domainClientName')}
											tooltip={getDependencyData('domainClientName')}
											isTooltipAvailable
										/>
									</FormStaticText>
									<FormStaticText label={labels.workspace + ':'}>
										<EllipsesControl
											id=""
											content={getDependencyData('domainWorkspaceName')}
											tooltip={getDependencyData('domainWorkspaceName')}
											isTooltipAvailable
										/>
									</FormStaticText>
								</section>
							</section>
						) : (
							<section>
								<EngagementProfileUpdateModal
									show={profileUpdate}
									editConfirmation={contentUpdateConfirmationClick}
								/>
							</section>
						)}

						<form className="createengagement">
							<EYForm
								key={`${engagementid}_${engagementname}`}
								id="createEngagementForm"
								onChange={updateState}
								onSubmit={() => {}}
								action="\"
								validationTriggerCounter={validationTriggerCounter}
							>
								<FormTextInput
									placeholder={labels.engagementname}
									name="engagementname"
									value={engagementname}
									rules={[]}
									is_required
									help
									maxLength="255"
									errormessage={labels.engagementnamerequired}
									clearable={true}
									className="abc"
								/>

								{renderEngagementCodes()}
								<section className="addengagement">
									<Button
										name="addNewEngagementCode"
										value="addNewEngagementCode"
										buttonType="secondary"
										disabled={isAddEngagementCodeDisabledState}
										label={labels.addengagementcode}
										onClick={(e) => {
											onAddEngagementCodeClick(e);
										}}
									/>
								</section>

								<FormComboBox
									id="userprimaryofficeid"
									name="userprimaryofficeid"
									value={
										userprimaryofficeid === null
											? undefined
											: userprimaryofficeid
									}
									searchable
									is_required
									help
									placeholder={labels.primaryoffice}
									disabled={isPrimaryOfficeDisabled}
									defaultValue={{label: 'Select', value: 0}}
									options={
										primaryoffices &&
										primaryoffices.collections &&
										primaryoffices.collections.countryofficeregioncollection.map(
											primaryOfficeOption
										)
									}
									errormessage={labels.primaryofficerequired}
								/>

								<FormComboBox
									id="userlanguage"
									name="userlanguage"
									value={userlanguage}
									is_required
									help
									placeholder={labels.language}
									searchable
									disabled={isLanguageDisabled}
									options={
										languages &&
										languages.collections &&
										languages.collections.languagecollection.map(
											languagesListOption
										)
									}
									errormessage={labels.languagerequired}
									tooltipClassName="no-break-words"
								/>
								<br />
								<FormComboBox
									id="serviceline"
									name="serviceline"
									value={serviceline}
									is_required
									help
									placeholder={labels.serviceline}
									searchable
									disabled={isServiceLineDisabled}
									options={
										servicelines &&
										servicelines.collections &&
										servicelines.collections.servicelines.map(serviceLineOption)
									}
									errormessage={labels.servicelinerequired}
									clearable={true}
									tooltipClassName="no-break-words"
									key={`serviceline_${serviceline}`}
								/>
								<SubServiceLineList
									showError={showSubServiceValidation}
									subserviceline={subserviceline}
									isDisabled={isSubServiceLineDisabledLocal}
									subservicelineOption={subservicelineOption}
									onChange={updateState}
									tooltipClassName="no-break-words"
								/>
								<br />
							</EYForm>
							<section className="zeus-datepicker">
								<Calendar
									name="clientenddate"
									value={clientEndDate}
									label={labels.engagementstartdate}
									dateFormat={dateFormatsListItems[dateFormats].format}
									userLocale={currentResource}
									validationTriggerCounter={validationTriggerCounter}
									disabled={isStartDateDisabled}
									isMandatory={true}
									errorMessage={labels.isRequired.replace(
										'{0}',
										labels.yearenddate
									)}
									onChange={(e) => {
										setClientEndDate(e.value);
									}}
								/>

								<Calendar
									name="engagementenddate"
									value={engagementEndDate}
									label={labels.engagementenddate}
									dateFormat={dateFormatsListItems[dateFormats].format}
									userLocale={currentResource}
									disabled={isEndDateDisabled}
									validationTriggerCounter={validationTriggerCounter}
									isMandatory={true}
									errorMessage={labels.isRequired.replace(
										'{0}',
										labels.periodenddate
									)}
									onChange={(e) => {
										setEngagementEndDate(e.value);
									}}
								/>
								<br />
								<Calendar
									name="auditorsreportdate"
									value={auditorsReportDate}
									label={labels.auditorsreportdate}
									dateFormat={dateFormatsListItems[dateFormats].format}
									userLocale={currentResource}
									disabled={isReportDateDisabled}
									validationTriggerCounter={validationTriggerCounter}
									isMandatory={true}
									errorMessage={labels.isRequired.replace(
										'{0}',
										labels.reportdate
									)}
									onChange={(e) => {
										setAuditorsReportDate(e.value);
									}}
								/>
								{mode === modes.edit ? (
									<section className="text-input archivedeadlinedate">
										<FormTextInput
											onChange={() => {}}
											placeholder={labels.archiveDeadlineDate}
											name="archiveDeadlineDate"
											value={getArchiveDeadlineDate()}
											rules={[]}
											help
											maxLength="100"
											disabled={true}
										/>
									</section>
								) : null}
							</section>
						</form>
					</section>
				</Createeditstyle>
			)}
		</>
	);
};
export default CreateEngagementStep3;
