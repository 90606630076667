import styled from 'styled-components';

const Wrapper = styled.section`

	.loadingstyle {
		height: calc(100vh - 25rem) !important;
	}

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: 0rem;
	}

	.permissiongroup-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: 1.4rem;
		background: ${(props) => props.theme.color[`white`]};
		border-top: 1px solid ${(props) => props.theme.color[`grey200`]};
	}


	.mainwrapper{
		overflow: hidden;
		padding-top: 9rem;
	
	.message{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: calc(100vh - 18rem);

		&h5{
			color: ${(props) => props.theme.color[`grey500`]};
		}
	}

	//heading
	.permissiongroup {
		.pageheading {
			display: flex;
			padding: 2rem 0;
			svg {
				min-width: 2.5rem !important;
				min-height: 2.5rem !important;
				margin-right: 1rem;
			}

			h3 {
				margin: 0;
				line-height: 2.5rem;
			}
		}
	}

	//addon

	.add-on {
		display: flex;
		margin-right: auto;
		padding: 0 0 2rem 0;

		.adduser {
			.motif-button {
				height: 4rem;
				font-size: 1.2rem;
				width: 10rem;
			}
		}

		.search {
			width: 16%;
			line-height: 4rem;
			margin-right: auto;
            
            .motif-icon-button{
                top: 0.5rem;
            }

			.motif-typeahead-close-button {
				top: 0.4rem;
                &:hover{
					background: none!important;
				}
			}

			.motif-input {
				font-size: 1.4rem;
				font-weight: normal;
				height: 4rem;
				&::placeholder {
					font-size: 1.4rem;
					font-weight: normal;
				}
			}
		}
	}

    .norecord{
        height: calc(100vh - 30rem);
        display: inline-block;
        width: 100%;
        display: flex;
        justify-content: center;
        flex: 0 auto;
        align-items: center;
        font-size: 1.6rem;
        color: ${(props) => props.theme.color[`grey400`]};
    }

    .managepermissionTable {
        height: 100%;
        overflow-y: auto;
        user-select: none;
        .motif-table {
            height: calc(100vh - 19rem);
    
            &.ag-theme-material .ag-checkbox-input-wrapper:focus-within {
                box-shadow: none;
            }
    
            .ag-header-cell {
                color: ${(props) => props.theme.color[`grey450`]};
                &:hover,
                    &.ag-header-cell-moving{
                        background: transparent!important;
                    }
            }
    
            .ag-header-cell .ag-header-select-all{
                visibility: hidden;
            }
    
            .ag-body-viewport{
                height: calc(100vh - 30rem);
                border: 0.1rem solid ${(props) =>
									props.theme.color['grey200']} !important;
    
                &:after{
                    content: none;
                }
            }
    
            .ag-header-viewport {
                background: ${(props) => props.theme.color[`grey100`]};
                font-size: 1.4rem;
            }
            .ag-root.ag-layout-normal {
                overflow-y: auto;
                width: 100%;
            }
    
            .ag-root.ag-layout-auto-height {
                display: block;
            }
    
            .ag-center-cols-viewport {
                overflow-x: hidden;
            }
    
            .ag-row-hover{
                border-width: 0 0 0.1rem 0 !important;		
                border-style: solid !important;
                border-color: ${(props) =>
									props.theme.color[`grey250`]} !important;
            }
    
            .ag-row{
                border-width: 0 0 0.1rem 0 !important;		
                border-style: solid !important;
                border-color: ${(props) =>
									props.theme.color[`grey250`]} !important;
                color: ${(props) => props.theme.color[`grey400`]};
    
                .ag-cell-value{
                    font-weight: 100;

					a.groupname{
						width: 100%;
						display: inline-block;
						color: ${(props) => props.theme.color[`blue600`]};

						.ellipses{
							color: ${(props) => props.theme.color[`blue600`]}!important;
						}
					}
                }
            }
    
            .ag-row,
            .ag-row-hover {
                background: ${(props) => props.theme.color[`white`]} !important;
                font-weight: normal;

				.ag-cell-wrapper{
					width: 100%;
					display: grid;
				}
				
                .ag-column-hover {
                    background: ${(props) =>
											props.theme.color[`white`]} !important;
                }
            }
    
            .ag-header-row .ag-header-cell{
                &:hover{
                    background: ${(props) => props.theme.color[`grey100`]}
                }
            }

            .ag-cell {
                line-height: normal;
				background: ${(props) => props.theme.color[`white`]} !important;
			}
    
            .ag-cell,
            .ag-header-cell {
                font-size: 1.4rem;
                padding: 0 2rem;
                display: block;
    
                
                .ag-react-container {
                    [role='button'] {
                        cursor: default;
                    }
                }
    
                .ag-header-cell-text{
                    font-size: 1.4rem;
                }
            }
    
            .ag-header-cell:last-child,
            .ag-cell:last-child {
                border-right: none;
            }
    
            .roleType.disabled,
            .roleType.disabled *{
                color: ${(props) => props.theme.color[`grey300`]}!important;
                fill: ${(props) => props.theme.color[`grey300`]};
            }
            .noptr{
                pointer-events: none;
            }
            .disabled{
                color: ${(props) => props.theme.color[`grey300`]}!important;
                fill: ${(props) => props.theme.color[`grey300`]};
            }
    
            .roleType {
                display: inline-block;
                width: 100%;
                align-items: center;
				cursor: default;
    
                &.enable.icon{
                    fill: ${(props) => props.theme.color[`black`]}!important;
                }
                .columnName{
                    font-size: 1.4rem;
                    color: ${(props) => props.theme.color[`grey500`]};
                    }
                }
            }
    
            .errorRow {
                color: ${(props) => props.theme.color[`red600`]} !important;
            }
        }
    
        .ag-body-horizontal-scroll {
			overflow: auto;
        }
    
        .ag-layout-normal {
            &::-webkit-scrollbar {
                width: 0.6rem;
            }
            &::-webkit-scrollbar-track {
                background: ${(props) => props.theme.color[`grey225`]};
                width: 0.6rem;
            }
            &::-webkit-scrollbar-thumb {
                background: ${(props) => props.theme.color[`grey300`]};
                width: 0.6rem;
                cursor: pointer;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: ${(props) => props.theme.color[`grey400`]};
            }
        }
    
    }
}

`;

export default Wrapper;
