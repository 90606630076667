/**
 * Remember: always add new reducers to the /reducers/index.js file!
 * Also, add your initialState to './initialstate'!
 */
import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export function MyEngagementsReducer(
	//state = initialState.<%=names.camel%>,//TODO: uncomment this. Get from initialState.
	state = initialState.myEngagements, //{Workspaces: {}},
	action
) {
	switch (action.type) {
		case types.LOAD_MY_ENGAGEMENTS:
			return formatAndSortEngagements(action.response);
		case types.POST_ENGAGEMENT:
			return action.response;
		default:
			return state;
	}
}

export function EngagementMetaDataReducer(
	state = initialState.engagementMetaData,
	action
) {
	switch (action.type) {
		case types.LOAD_ENGAGEMENTMETADATA:
			return action.response.engagementmetadatadetails;
		default:
			return state;
	}
}

export function formatAndSortEngagements(engagementslist) {
	var engagementpaginationdata = {};
	var engagements_list = [];
	if (engagementslist && engagementslist.collections) {
		engagementslist.collections.clients.forEach((client) => {
			if (client.collections && client.collections.workspaces) {
				client.collections.workspaces.forEach((workspace) => {
					if (workspace.collections) {
						workspace.collections.engagements.forEach((engagement) => {
							engagements_list.push({
								engagements: engagement,
								workspaceID: workspace.id,
								workspace: workspace.data,
								client: client,
								clientName: client.data.clientname,
								clientCode: client.data.clientcode
							});
						});
					}
				});
			}
		});
	}

	engagementpaginationdata.allengagements = engagements_list.sort((a, b) =>
		a.engagements.data.engagementdesc.toLowerCase() >
		b.engagements.data.engagementdesc.toLowerCase()
			? 1
			: -1
	);
	engagementpaginationdata.pagermetadata = engagementslist.pagermetadata;
	engagementpaginationdata.data = engagementslist.data;
	engagementpaginationdata.userid = engagementslist.id;

	return engagementpaginationdata;
}

export function formatEngagementsForReportingHub(engagementsList) {
	if (engagementsList && engagementsList.allengagements) {
		let result = [];
		engagementsList.allengagements.map((eng) => {
			if (eng.client && eng.client.collections.workspaces) {
				const clientName = eng.clientName;
				eng.client.collections.workspaces.forEach((workspace) => {
					if (workspace.collections) {
						workspace.collections.engagements.forEach((eng) => {
							const status = eng.data.engagementstatus,
								engagementStatus = eng.data.engagementstatus,
								userStatus = eng.data.enguserstatus,
								independenceStatus = eng.data.independencestatusid;
							if (status === 1 || status === 7 || status === 8) {
								/*adding archived engagements somehow. Dont want to change the call so remove them here*/
								if (
									engagementStatus === 1 &&
									userStatus === 1 &&
									(independenceStatus === 2 ||
										independenceStatus === 5 ||
										independenceStatus === 6)
								) {
									let newClient = {};
									newClient.value = eng.id;
									newClient.label =
										eng.data.engagementdesc + ` (${clientName})`;
									newClient.title =
										eng.data.engagementdesc + ` (${clientName})`;
									newClient.isInterim = eng.data.isinterimreviewengagement;
									newClient.isGroupAudit = eng.data.isgroupaudit;

									if (
										_.find(result, (r) => {
											return _.isEqual(newClient, r);
										}) === undefined
									) {
										result.push(newClient);
									}
								}
							}
						});
					}
				});
			}
		});
		return result.sort((a, b) =>
			a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
		);
	}
	return engagementsList;
}

export function formatEngagementsForReportingHubEngDropDown(engagementsList) {
	let result = [];
	if (engagementsList && engagementsList.length > 0) {
		engagementsList.map((engagement) => {
			let newClient = {};
			newClient.value = engagement.engagementid;
			newClient.label =
				engagement.engagementdescription + ` (${engagement.clientname})`;
			newClient.title =
				engagement.engagementdescription + ` (${engagement.clientname})`;
			newClient.isInterim = engagement.isinterimreviewengagement;
			newClient.isGroupAudit = engagement.isgroupaudit;

			result.push(newClient);
		});
	}
	return result;
}

export function setSuccessfulToastMessage(state = initialState.successfulToastMessage, action) {
	switch (action.type) {
		case types.SET_SUCCESS_TOAST_MESSAGE:
			var updatedState = {
				...state,
				message: action.message
			};
			return updatedState;
		default:
			return state;
	}
}