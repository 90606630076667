import React, {lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EngagementAlertsItem from './engagementAlertsItem';
import uuidv4 from 'uuid/v4';

const Carousel = lazy(() => import('@ey/carousel'));

const EngagementAlertsList = (props) => {
	return (
		props.issuesList &&
		props.issuesList.length > 0 && (
			<Wrapper>
				{/* <section className="eng_title">{labels.engagementAlerts}</section> */}
				<section className="carouselWrapper">
					<Suspense fallback="">
						<Carousel
							items={props.issuesList}
							numItemsToDisplay={4}
							numItemsToScrollOnClick={1}
							renderFunction={(issuesList) => {
								return issuesList.map((issue) => {
									return <EngagementAlertsItem key={uuidv4()} issue={issue} />;
								});
							}}
						/>
					</Suspense>
				</section>
			</Wrapper>
		)
	);
};

// -------------- PROP TYPES --------------
// ----------------------------------------
EngagementAlertsList.propTypes = {
	issuesList: PropTypes.arrayOf(
		PropTypes.shape({
			issueType: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			counter: PropTypes.number
		})
	)
};

export default EngagementAlertsList;

const Wrapper = styled.section`
	display: flex;
	align-items: center;
	.eng_title {
		display: inline-flex;
		width: 19vw;
		margin: 0.2rem 0 0 0;
		font-size: 1.4rem;
		font-weight: bold;
		align-items: center;
		padding: 0 0 0 4rem;
	}
	.carouselWrapper {
		display: inline-flex;
		width: calc(100% - 25rem);
		@media only screen and (min-width: 2048px) {
			width: calc(100% - 10rem);
		}
		.carousel_wrapper {
			width: 100%;
			display: inline-flex;
			.carousel-container {
				justify-content: flex-start;
				color: ${(props) => props.theme.color[`grey500`]};
				.CarouselContent {
					width: calc(100% - 6rem);
				}
				.CarouselContent > section {
					width: 26rem;
				}
			}
		}
		.carousel_wrapper > span {
			display: inline-flex;
		}
		.arrow.left {
			padding-left: 0;
			align-items: center;
			height: 100%;
			width: auto;
			svg {
				vertical-align: middle;
			}
		}
		.arrow.right {
			padding-right: 0;
			align-items: center;
			height: 100%;
			width: auto;
			svg {
				vertical-align: middle;
			}
		}
		.arrow svg {
			background-color: transparent;
		}
	}
`;
