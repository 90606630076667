import * as types from './actiontypes';

/**
 * make a post call for request access
 */
export function versionUpgrade(upgrade) {
	return {
		type: 'VERSION_UPDATE',
		upgrade
	};
}
