import * as types from '../actiontypes';

export function postSyncDatabaseLocator(url) {
	return {
		type: types.SYNC_DATABASELOCATOR,
		meta: {
			api: {
				url: url,
				method: 'POST'
			}
		}
	};
}
