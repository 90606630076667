/**
 * Created by calhosh on 9/27/2017.
 */
import * as types from './actiontypes';

export function clearErrors() {
	return {type: types.CLEAR_ERRORS};
}

/**
 * Raise an error from JavaScript code. This is for validation failure
 * @param {Number} errorCode - error code
 * @return {Object} the dispatch
 @return {Function} Thunk function
 */
export function raiseJSValidationError(errorCode) {
	return {type: types.CLIENT_VALIDATION_ERROR, errorCode: errorCode};
}
