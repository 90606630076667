import React from 'react';
import ClientItem from './clientitem';

export default function ClientList(props) {
	return (
		<section className="clientHolder">
			{props.clients &&
				props.clients.map((client) => (
					<ClientItem
						key={client.id}
						{...client}
						refreshDashboard={props.refreshDashboard}
						archiveretryshortcutkey={props.archiveretryshortcutkey}
						archivedEngagementList={props.archivedEngagementList}
						isReviewWorksapces={props.isReviewWorkspaces}
					/>
				))}
		</section>
	);
}
