import React from 'react';
import {labels} from '../../../../util/uiconstants';

function EngagementHeader() {
	return (
		<section className="userlist-header">
			<section className="userlistheader">
				<section className="userlistitem">
					{labels.engagementTableHeader}
				</section>
				{/* <section className="userlistitem">
					{labels.nameTableHeader}
				</section> */}
				<section className="userlistitem">
					{labels.workspaceIdTableHeader}
				</section>
				<section className="userlistitem">{labels.clientTableHeader}</section>
				<section className="userlistitem">
					{labels.engagementCodeTableHeader}
				</section>
				<section className="userlistitem date">
					{labels.yearEndTableHeader}
				</section>
				<section className="userlistitem status">
					{labels.statusTableHeader}
				</section>
				<section className="userlistitem attribute">
					{labels.attributesTableHeader}
				</section>
			</section>
		</section>
	);
}

export default EngagementHeader;
