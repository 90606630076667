import * as types from '../../actions/actiontypes';
import initialstate from '../initialstate';

export default function successMessageReducer(
	state = initialstate.successMessages,
	action
) {
	switch (action.type) {
		case types.SET_UPDATE_ENGAGEMENT_SUCCESS_MESSAGE: {
			var updatedState = {...state, updateEngagement: action.message};
			return updatedState;
		}
		default:
			return state;
	}
}
