/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';

/* import * as <%=names.camel%>Actions from '../actions/<%=names.lower%>actions'; */

const EngagementItemDetails = styled.div`
	margin: 1.5rem;
	box-shadow: 0px 4px 8px 0px ${(props) => props.theme.color[`grey250`]},
		1px -1px 8px 0px ${(props) => props.theme.color[`grey250`]};
	background-color: ${(props) => props.theme.color[`white`]};
	padding: 1.5rem;
	display: flex;
`;

const GraphWidget = styled.div`
	margin-right: 2rem;
`;

const WorkspaceContent = styled.div`
	padding-left: 3rem;
`;

/**
 * exporting the unconnected class for unit testing
 */

const WorkspacesLoader = (props) => {
	let loaders = [];
	for (let i = 0; i < props.count; i++) {
		loaders.push(
			<EngagementItemDetails key={(props.prefix || 'ws_loader_') + i}>
				<GraphWidget>
					<svg height="100" width="100">
						<circle cx="50" cy="50" r="40" strokeWidth="0" fill="#CCC" />
					</svg>
				</GraphWidget>
				<WorkspaceContent>
					<Skeleton width={250} height={20} />
					<h6>
						<Skeleton width={250} height={20} />
					</h6>
					<div>
						<Skeleton width={250} height={20} />
					</div>
				</WorkspaceContent>
			</EngagementItemDetails>
		);
	}
	return <section>{loaders}</section>;
};

export default WorkspacesLoader;
