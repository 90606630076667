import * as types from '../actiontypes';

export function getAdminEngagementDocuments(url) {
    return {
        type: types.GET_ADMIN_ENGAGEMENT_DOCUMENTS_LIST,
        meta: {
            api: {
                url: url,
                method: 'GET'
            }
        },
    };
}

export function resetAdminEngagementDocuments() {
    return {
        type: types.RESET_ADMIN_ENGAGEMENT_DOCUMENTS_LIST,
    };
}
