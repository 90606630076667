import initialState from './initialstate';
import _ from 'lodash';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export default function VersionUpgradeStateReducer(
	state = initialState.appVersion,
	action
) {
	switch (action.type) {
		case 'VERSION_UPDATE':
			return {
				upgrade: action.upgrade,
				version: action.version
			};
		default:
			return state;
	}
}
