import React from 'react';
import {StaticRouter} from 'react-router';
import {Route} from 'react-router-dom';
import IndexLoader from './Common/Loaders/indexLoader';
import UserSettingsLoader from './Common/Loaders/userSettingsLoader';
import styled, {ThemeProvider} from 'styled-components';
import {getTheme} from '@ey/styled-theme';
import ServerNav from './Nav/Nav';

const StyledAppContainer = styled.div`
	background: ${(props) => props.theme.color[`grey50`]};
	.react-loading-skeleton {
		background-color: rgb(238, 238, 238);
		background-image: ${(props) => props.theme.color[`grey100`]};
		background-size: 200px 100%;
		display: inline-block;
		line-height: 1;
		width: 100%;
		background-repeat: no-repeat;
		border-radius: 0.4rem;
		animation: 1.2s ease-in-out 0s infinite normal none running
			animation-16jpnkj;
	}
`;

const LoaderContainer = (props) => {
	const context = {};
	return (
		<ThemeProvider theme={getTheme()}>
			<StyledAppContainer>
				<StaticRouter location={props.path} context={context}>
					{props.showNav ? <ServerNav /> : null}
					<>
						<Route path="/" component={IndexLoader} exact />
						<Route path="/engagements" component={IndexLoader} exact />
						<Route path="/favorites" component={IndexLoader} exact />
						<Route path="/reviewengagements" component={IndexLoader} exact />
						<Route path="/usersettings" component={UserSettingsLoader} exact />
					</>
				</StaticRouter>
			</StyledAppContainer>
		</ThemeProvider>
	);
};

export default LoaderContainer;
