import React, {useState, useEffect} from 'react';
import { useDispatch} from 'react-redux';
import {MotifButton} from '@ey-xd/motif-react';
import {labels} from '../../../util/uiconstants';
import Loader from '../../Common/Loaders/loadingindicator';
import styled from 'styled-components';
import EllipsesControl from '@ey/ellipses-control';
import Toast from '../../Common/Toast/Toast';
import {clearErrors} from '../../../actions/erroractions';
import {setIsModalOpen} from '../../../actions/modalstateactions';
import TicketField from '../common/ticketfield';

const DeleteMessageWrapper = styled.section`
	.loadingstyle {
		height: calc(100vh - 50rem) !important;
	}
	
	.motif-loader {
		height: 18.3rem !important;
	}

	.wrapper{
		padding: 2rem;	
		//ticketstart
			.ticketdetail{
				margin: 2rem 0 0 0;
				padding-bottom: 2rem;
				h5{
					font-size: 1.4rem;
					font-weight: normal;
					color: ${(props) => props.theme.color[`grey500`]};
					border: 0!important;
                    margin-top: 2.9rem;
				}

				.motif-form-field{
					width: 29rem;
				}

				.motif-form-field:not(.motif-form-field-has-value),
				.motif-form-field:not(.motif-form-field-focused){
					.motif-label{
						font-size: 1.4rem;
						font-weight:normal;
						margin-top: 0.6rem;
						color: ${(props) => props.theme.color[`black`]};
					}
				}
		
				.motif-form-field-has-value,
				.motif-form-field-focused{
					.motif-label{
						font-size: 1.2rem!important;
						font-weight: normal!important;
						margin-top: 0.2rem!important;
						 color: ${(props) => props.theme.color[`black`]};
					}
				}
				
				
				.motif-input {
					font-size: 1.4rem;
					line-height: 3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					color: ${(props) => props.theme.color[`black`]};
					&::placeholder {
						font-size: 1.4rem;
					}
				}
			}
		//ticketend
	.messagelabel{
		h5{
			font-size: 1.4rem;
			font-weight: bold;
		}
	}

	.messagetitle{
		font-size: 1.4rem;
		font-weight: normal;
		.ellipses{	
			display: inline-flex;
		}
	}
	}

	.modal-footer {
		width: 100%;
		padding: 2rem;
		display: inline-flex;

		button {
			font-size: 1.4rem;
			padding: 1.2rem 1.5rem;
			margin-right: 1rem;
			line-height: normal;
		}
	}
`;

function DeleteMessage(props) {
	const dispatch = useDispatch();

	const [ticketNumber, setTicketNumber] = useState('');
	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	useEffect(() => {
		clearError(true);
	}, []);

	function handlerOnTicketEntered(e) {
		setTicketNumber(e.target.value);
	}

	return (
		<DeleteMessageWrapper>
				{props.isLoading ? (
					<Loader show fullscreen view="blockView" styleName="loadingstyle" />
				) : (
					<section className="deletewwrapper">
						<section className="delete">
							<section
								className="toastMessage"
								style={{
									display:
										props.errorMessage && props.errorMessage.length > 0
											? 'block'
											: 'none'
								}}
							>
								<Toast
									variant="error"
									onClose={() => props.clearErrorMessage()}
								>
									{props.errorMessage}
								</Toast>
							</section>
							<section className="wrapper">
								<section className="messagelabel" aria-label="message">
									<h5>{labels.messagingModalDeleteMessage}</h5>
								</section>

								<section className="messagetitle" aria-label="">
									<EllipsesControl
										content={
											props?.messageToDelete?.messageTitle
										}
										tooltip={
											props?.messageToDelete?.messageTitle
										}
										displayId={''}
										noOfLines={1}
										ellipsisClass=""
									/>
								</section>
								<section>
								   <TicketField
									ticketNumber={ticketNumber}
									onTicketChanged={handlerOnTicketEntered}
								    />
							    </section>
							</section>
						</section>
					</section>
				)}
				<section className="modal-footer">
							<MotifButton
								type="button"
								variant="primary-alt"
								disabled={ticketNumber.trim().length == 0 ? true : false}
								onClick={() => props.onDeleteClick(ticketNumber)}
							>
								{labels.messagingModalDeleteButton}
							</MotifButton>
							<MotifButton
								type="button"
								variant="secondary"
								onClick={() => props.onCancelClick()}
							>
								{labels.messagingModalDeleteCancel}
							</MotifButton>
						</section>		
		</DeleteMessageWrapper>
	);
}

export default DeleteMessage;
