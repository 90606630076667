import initialState from '../../initialstate';
import * as types from '../../../actions/actiontypes';
import {currentResource, modules} from '../../../util/uiconstants';

export function subServiceLinesReducer(
	state = initialState.subServiceLines,
	action
) {
	switch (action.type) {
		case types.GET_ALL_SUB_SERVICE_LINE: {
			if (!action) return state;
			if (action.response) {
				return getLocalizedSubServiceLines(action);
			}
			if (
				action.response == null &&
				action.error?.response.status === HttpStatusCodes.Forbidden
			) {
				return [];
			}
		}
		default:
			return state;
	}
}

export function countriesReducer(state = initialState.countries, action) {
	switch (action.type) {
		case types.GET_ALL_ENGAGEMENT_COUNTRIES: {
			if (action?.response) {
				return action.response?.data;
			}
			return state;
		}
		default:
			return state;
	}
}

export function modulesReducer(state = initialState.modules) {
	state = getLocalizedModule();
	return state;
}

export function rolesReducer(state = initialState.roles, action) {
	switch (action.type) {
		case types.GET_ALL_ADMIN_ROLE: {
			if (!action) return state;
			if (action.response) {
				return getLocalizedRoles(action);
			}
			if (
				action.response == null &&
				action.error?.response.status === HttpStatusCodes.Forbidden
			) {
				return [];
			}
		}
		default:
			return state;
	}
}

function getLocalizedRoles(action) {
	const localizedRoles = currentResource.roles;
	let alteredRoles = [];
	_.forEach(action?.response, (role) => {
		let localizedRoleItem = localizedRoles.find((x) => x.id == role.roleKey);
		let roleFinalName = localizedRoleItem
			? localizedRoleItem.name
			: role.roleName;

		alteredRoles.push({
			rbacId: role.id,
			id: role.roleKey,
			name: roleFinalName,
			moduleId: role.moduleId
		});
	});

	return alteredRoles;
}

function getLocalizedModule() {
	let alteredModules = [];
	var localizedModules = currentResource.modules;

	modules?.forEach((module) => {
		let localizedModulesItem = localizedModules.find((x) => x.id == module.id);
		let moduleFinalName = localizedModulesItem
			? localizedModulesItem.name
			: module.name;

		alteredModules.push({id: module.id, name: moduleFinalName});
	});

	return alteredModules;
}

function getLocalizedSubServiceLines(action) {
	let alteredSubServiceLines = [];
	var localizedEngagementSubServiceLines = currentResource.subServiceLines;

	action?.response?.forEach((serviceLine) => {
		if (serviceLine && serviceLine.subServiceLines) {
			serviceLine?.subServiceLines?.forEach((subServiceLine) => {
				let localSubServiceLineItem = localizedEngagementSubServiceLines.find(
					(x) => x.subServiceLineId == subServiceLine.subServiceLineId
				);
				let subServiceFinalName = localSubServiceLineItem
					? localSubServiceLineItem.subServiceLineName
					: subServiceLine.subServiceLineName;
				alteredSubServiceLines.push({
					id: subServiceLine.subServiceLineId,
					name: subServiceFinalName
				});
			});
		}
	});

	return alteredSubServiceLines;
}

export function regionsReducer(state = initialState.regions, action) {
	switch (action.type) {
		case types.GET_ALL_REGION: {
			if (action?.response) {
				return action.response;
			}
			return state;
		}
		default:
			return state;
	}
}

export function engagementRolesReducer(
	state = initialState.engagementRoles,
	action
) {
	switch (action.type) {
		case types.GET_ALL_ENGAGEMENT_ROLES: {
			if (action?.response) {
				return action.response;
			}
			return state;
		}
		default:
			return state;
	}
}

export function dataCentersReducer(state = initialState.dataCenters, action) {
	switch (action.type) {
		case types.GET_ALL_DATACENTERS: {
			if (action?.response) {
				return action.response;
			}
			return state;
		}
		default:
			return state;
	}
}
