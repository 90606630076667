import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import env from '../../util/env';
import {urls} from '../../util/uiconstants';
import * as TimelineActions from '../../actions/timelineaction';
import _ from 'lodash';
import moment from 'moment';

const RestoredEngagementTimeline = (props) => {
	const timeline = useSelector((state) => state.timeline);
	const dispatch = useDispatch();

	useEffect(() => {
		GetEngagementTimeline();
	}, [props.engagementId]);

	useEffect(() => {
		getTimeline();
	}, [timeline]);

	const GetEngagementTimeline = async () => {
		let url =
			props.AppUri +
			'/api/v2/' +
			urls.TIMELINE_URL +
			'?engagementid=' +
			props.engagementId +
			'&filters=statussynconly eq true';

		await dispatch(
			TimelineActions.getEngagementTimeline(
				url,
				url.split('/api/v2')[0],
				props.engagementId,
				props.AudienceUri
			)
		).catch((error) => {
			// Time line call failed
			// make a post call to sync the status
			if (error && !error.toString().includes('Network Error')) {
				// if not a network error
				// then do make sync call
				let universalurl =
					env.getURL('serviceUrl') +
					'/' +
					urls.TIMELINE_URL +
					'/' +
					props.engagementId;

				dispatch(
					TimelineActions.syncEngagementandUserStatus(
						universalurl,
						props.engagementId
					)
				);
			}
		});
	};

	/**
	 * prepare the timeline model
	 */
	const getTimeline = () => {
		if (timeline && timeline.timeline && timeline.timeline.length > 0) {
			var timelineData = timeline.timeline.find(
				(x) => x && x.id && x.id.toString() === props.engagementId
			);

			if (timelineData && timelineData.data) {
				// Check if 4 params matches
				// if not then => make a post call to Universal API
				// If matches then => then don't do any action and return
				if (
					timelineData.data.engagementstatusid !== props.engagementstatus ||
					timelineData.data.isprofilecomplete !==
						props.engagementisprofilecomplete ||
					timelineData.data.engagementuserstatusid !==
						props.engagementuserstatusid ||
					timelineData.data.independencestatusid !==
						props.independencestatusid ||
					timelineData.data.isindependencetemplateavailable !==
						props.isindependencetemplateavailable ||
					(timelineData.data.expectedReportReleaseDate && // to beat versioning scenario - where gaRoleTypeID is not available in timeline data
						timelineData.data.gaRoleTypeID !== props.garoletypeid) ||
						(timelineData.data.expectedReportReleaseDate && props.expectedreportreleasedate
							&& !moment(timelineData.data.expectedReportReleaseDate, 'YYYY/MM/DD').isSame(moment(props.expectedreportreleasedate, 'YYYY/MM/DD'), 'day'))
				) {
					console.log(
						'Engagement status are out of sync, queueing a message to service bus. EngagementId: ',
						props.engagementId
					);
					let universalurl =
						env.getURL('serviceUrl') +
						'/' +
						urls.TIMELINE_URL +
						'/' +
						props.engagementId;

					dispatch(
						TimelineActions.syncEngagementandUserStatus(
							universalurl,
							props.engagementId
						)
					);
				}
			}
		}
	};

	return null;
};

export default RestoredEngagementTimeline;
