/**
 * @module <%=name%>
 * Action creator for <%=name%> object
 */

import * as types from './actiontypes'; //TODO: uncomment

/**
 * I've marked TODO items in this file that you'll need to rework to
 * match your new actioncreator. I've done this so your tests will not
 * fail as soon as you create new files--helpful when you're creating
 * multiple new files at once.
 *
 * Note that the second parameter here is simply "payload", and not
 * payload: payload. This is because ES6 allows you to omit the right
 * hand side of an object property assignment if it's the same
 * as the left side. Either version works, but this way
 * requires less typing.
 * @param {String} url - url as string to fetch the workspaces
 * @param {Object} resource - resource (object) to pass required data as part of fetch call
 * @return {{type: String, payload: Object}} The action object, including
 *      type (a String constant from actiontypes.js) and form data.
 */
export function getUserSettings(url, resource) {
	return {
		type: types.USER_SETTINGS,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}

export function setUserSettings(url, resource) {
	return {
		type: types.SET_USER_SETTINGS,
		meta: {
			api: {
				url,
				method: 'PUT',
				data: resource
			}
		}
	};
}

export function createUserSettings(url, resource) {
	return {
		type: types.CREATE_USER_SETTINGS,
		meta: {
			api: {
				url,
				method: 'POST',
				data: resource
			}
		}
	};
}
export function updateTabPrefUserSettings(data) {
	return {
		type: types.PREF_TAB_USER_SETTINGS,
		payload: data
	};
}
export function updateGroupPrefUserSettings(data) {
	return {
		type: types.PREF_GROUPING_USER_SETTINGS,
		payload: data
	};
}
export function updatePagePrefUserSettings(data) {
	return {
		type: types.PREF_PAGING_USER_SETTINGS,
		payload: data
	};
}

export function getUserPermissions(url, resource) {
	return {
		type: types.USER_PERMISSIONS,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}
