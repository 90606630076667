import React, {useState, useEffect} from 'react';
import { Wizard } from '@ey/canvascoreservices';

// A Wrapper around voltron wizard components.

// This component recieve wizard steps, buttons and child content for wizard steps.
// Buutons array will have isVisible props that will help to identify if buttons will be visible in current step or not.
// This component also expect currentStepIndex. This will provide the currently active steps in wizard.

// No need to have seprate validation as validation is already handled in each step inside childs components.
// On next click, Parent will update setContinueToNextStep to true.
//      => Child (ex- step 1) if finds ContinueToNextStep is true, it will perform validation and if good.. will update currentStepIndex
//      => This wrapper will listen currentStepIndex and will update current step
//      => This approach can also handler the scenerio where user needs to navigate to specific step

function WizardWrapper(props) {
	const {
		wizardSteps,
		setWizardSteps,
		buttons,
		stepContent,
		currentStepIndex,
		theme,
		wizLoaderName
	} = props;

	const [activeStep, setActiveStep] = useState(currentStepIndex);

	useEffect(() => {
		if (
			(currentStepIndex || currentStepIndex == 0) &&
			currentStepIndex != activeStep
		) {
			// Update active flag in wizard steps
			let updatedWizardSteps = [...wizardSteps];
			updatedWizardSteps[activeStep].active = false;
			updatedWizardSteps[currentStepIndex].active = true;
			setWizardSteps(updatedWizardSteps);

			// Update local state for active step
			setActiveStep(currentStepIndex);
		}
	}, [currentStepIndex]);

	return (
		<>
			<header>
				<h5>{wizLoaderName}</h5>
			</header>
			<Wizard
				key={activeStep}
				activeStep={activeStep}
				steps={wizardSteps}
				buttons={buttons.filter((b) => b.isVisible == true)}
				theme={theme}
			>
				{stepContent.map((step) => {
					return step;
				})}
			</Wizard>
		</>
	);
}

export default WizardWrapper;
