import {currentResource} from '../util/uiconstants';

export function localizeServiceLines(theList) {
	for (let el of theList.collections.servicelines) {
		let local = currentResource.serviceLines.filter(
			(item) => item.servicelineid === el.id
		)[0];
		if (local) {
			el.data.servicelinename = local.servicelinename;
			for (let sub of el.collections.subservicelinesdata) {
				let localSub = currentResource.subServiceLines.filter(
					(item) => item.subservicelineid === sub.subservicelineid
				)[0];
				if (localSub) {
					sub.subservicelinename = localSub.subservicelinename;
				}
			}
		}
	}

	return theList;
}
