import React from 'react';
import {labels} from '../../../../util/uiconstants';

function DocumentsTableHeader() {
    return (
        <section className="document-header">
            <section className="documentheader">
                <section className="documentitem id">
                    {labels.docListHeaderDocId}
                </section>
                <section className="documentitem version">
                    {labels.docListHeaderDocVersion}
                </section>
                <section className="documentitem description">
                    {labels.docListHeaderDocName}
                </section>
                <section className="documentitem filesize">
                    {labels.docListHeaderFileSize}
                </section>
                <section className="documentitem uri">
                    {labels.docListHeaderContentUri}
                </section>
                <section className="documentitem fileexist">
                    {labels.docListHeaderFileExists}
                </section>
            </section>
        </section>
    );
}

export default DocumentsTableHeader;
