/**
 * @module AddNewEngagementCodeList
 */

import React from 'react';
import _ from 'lodash';
import AddNewEngagementCode from './addnewengagementcode';

const AddNewEngagementCodeList = (props) => {
	const {
		engagementcodearray,
		clientid,
		updateStateOnBlur,
		deleteEngagementCodeClick,
		getLatestEngagementCode,
		isEngagementCodeDisabled,
		updatePrimaryEngagementCode
	} = props;

	return engagementcodearray.map((engcodeobject) => (
		<AddNewEngagementCode
			key={engcodeobject.engagementCodeId}
			engagementcodeobject={engcodeobject}
			clientid={clientid}
			engagementcodeindex={engcodeobject.engagementCodeId}
			updateStateOnBlur={updateStateOnBlur}
			deleteEngagementCodeClick={deleteEngagementCodeClick}
			isDeletedDisabled={engagementcodearray.length > 1 ? false : true}
			getLatestEngagementCode={getLatestEngagementCode}
			isEngagementCodeDisabled={isEngagementCodeDisabled}
			updatePrimaryEngagementCode={updatePrimaryEngagementCode}
		/>
	));
};
export default AddNewEngagementCodeList;
