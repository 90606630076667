import React from 'react';
import EllipsesControl from '@ey/ellipses-control';
import {SVGICONS} from '@ey/icons';

function RoleRow(props) {
	return (
		<section className={`manageaccessitemview`}>
			<section className="userlistitem grid">
				<section>
					<section className="description">
						<EllipsesControl
							content={props.data.moduleName}
							tooltip={props.data.moduleName}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</section>
			<section className="userlistitem grid">
				<section>
					<section className="description">
						<section className="engname">
							<EllipsesControl
								content={props.data.roleName}
								tooltip={props.data.roleName}
								displayId={''}
								noOfLines={1}
								ellipsisClass="columnName"
							/>
						</section>
					</section>
				</section>
			</section>
			<section className="userlistitem grid">
				<section>
					<section className="description">
						<section className="engname">
							<EllipsesControl
								content={props.data.subServiceLineName}
								tooltip={props.data.subServiceLineName}
								displayId={''}
								noOfLines={1}
								ellipsisClass="columnName"
							/>
						</section>
					</section>
				</section>
			</section>
			<section className="userlistitem grid">
				<section>
					<section className="description">
						<section className="engname">
							<EllipsesControl
								content={props.data.localizedCountries
									?.map((c) => c.name)
									.join(', ')}
								tooltip={props.data.localizedCountries
									?.map((c) => c.name)
									.join(', ')}
								displayId={''}
								noOfLines={1}
								ellipsisClass="columnName"
							/>
						</section>
					</section>
				</section>
			</section>
			<section className="userlistitem grid delete">
				<section>
					<section className="description">
						<SVGICONS
							role="button"
							styleName="medium delete"
							name="delete"
							hoverText={'Delete'}
							onIconClick={() => {
								props.onDeleteClick(props.data);
							}}
						/>
					</section>
				</section>
			</section>
		</section>
	);
}

export default RoleRow;
