/**
 * @module <%=name%>
 * Action creator for <%=name%> object
 */

import * as types from './actiontypes'; //TODO: uncomment
import Env from '../util/env';
import { API_RESOURCES, createApiReadAction } from '../util/api';
import { mobileLabelParams } from '../util/uiconstants';

/**
 * I've marked TODO items in this file that you'll need to rework to
 * match your new actioncreator. I've done this so your tests will not
 * fail as soon as you create new files--helpful when you're creating
 * multiple new files at once.
 *
 * Note that the second parameter here is simply "payload", and not
 * payload: payload. This is because ES6 allows you to omit the right
 * hand side of an object property assignment if it's the same
 * as the left side. Either version works, but this way
 * requires less typing.
 * @param {String} url - url as string to fetch the workspaces
 * @param {Object} resource - resource (object) to pass required data as part of fetch call
 * @return {{type: String, payload: Object}} The action object, including
 *      type (a String constant from actiontypes.js) and form data.
 */
export function getMyEngagements(url, resource) {
	return {
		type: types.LOAD_MY_ENGAGEMENTS,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}

export function fetchEngagements(params){
	const { 
		resource, 
		phase, 
		top, 
		skip, 
		searchTypeLabel = mobileLabelParams.searchTypeIdLabel, 
		searchType, 
		displayTypeLabel = mobileLabelParams.displayTypeIdLabel, 
		searchKey 
	  } = params;
	return createApiReadAction(
		types.LOAD_MY_ENGAGEMENTS,
		{
			[API_RESOURCES.HOME]: resource,
		},
		{
			phase: phase,
			top: top,
			skip: skip,
			filters: `${searchTypeLabel}${searchType}${displayTypeLabel}`,
			searchExpression: searchKey
		},
	 );
}

export function getEngagements(params) {
	const mainUrl = `${Env.getURL(
		'serviceUrl'
	)}/home.json?phase=${params.phase}&Top=${params.top}&Skip=${params.skip}`;
	const searchURLPart = `&filters=searchtypeid eq ${params.searchType},displaytypeid eq 1&SearchExpression=${params.searchKey}`;

	return {
		type: types.LOAD_MY_ENGAGEMENTS,
		meta: {
			api: {
				url: mainUrl + searchURLPart,
				method: 'GET',
				data: {
					resource: mainUrl.split('/api/v1')[0]
				}
			}
		}
	};
}

export function getSpotfireDataCenter(url, resource) {
	return {
		type: types.GET_SPOTFIRE_DATACENTER_URL,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {resource}
			}
		}
	};
}

export function PostEngagement(url, resource) {
	return {
		type: types.POST_ENGAGEMENT,
		meta: {
			api: {
				url,
				method: 'POST',
				data: resource
			}
		}
	};
}

export function DeleteEngagement(url, resource) {
	return {
		type: types.DELETE_ENGAGEMENT,
		meta: {
			api: {
				url,
				method: 'DELETE',
				data: resource
			}
		}
	};
}

export function PatchEngagement(url, resource) {
	return {
		type: types.PATCH_ENGAGEMENT,
		meta: {
			api: {
				url,
				method: 'PATCH',
				data: resource
			}
		}
	};
}

export function getSingleEngagement(url, resource) {
	return {
		type: types.GET_ENGAGEMENT,
		meta: {
			api: {
				url,
				method: 'GET',
				data: resource
			}
		}
	};
}

export function getSingleEngagementForUrls(url) {
	return {
		type: types.GET_ENGAGEMENT_FOR_COMPLETITION_URL,
		meta: {
			api: {
				url,
				method: 'GET'
			}
		},
		resolve: true
	};
}

export function getEngagementMetaData(url, resource) {
	return {
		type: types.LOAD_ENGAGEMENTMETADATA,
		meta: {
			api: {
				url,
				method: 'GET'
			}
		}
	};
}

export function getArcFilesList(url, resource) {
	return {
		type: types.GET_ARC_FILES,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}

export function getEngagementsV2(params) {
	const mainUrl = `${Env.getURL('serviceUrlV2')}Engagements?searchRuleId=${params.searchType}&displayViewTypeID=${params.engagementType}&Top=${params.top}&Skip=${params.skip}&searchExpression=${params.searchKey}`;
	return {
		type: types.LOAD_ENGAGEMENTS_V2,
		meta: {
			api: {
				url: mainUrl,
				method: 'GET'
			}
		}
	};
}

export function setSuccessfulToastMessage(message) {
		return {
			type: types.SET_SUCCESS_TOAST_MESSAGE,
			message: message
		};
}