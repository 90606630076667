/**
 * @module <%=name%>
 * Action creator for <%=name%> object
 */

import * as types from './actiontypes'; //TODO: uncomment

/**
 * I've marked TODO items in this file that you'll need to rework to
 * match your new actioncreator. I've done this so your tests will not
 * fail as soon as you create new files--helpful when you're creating
 * multiple new files at once.
 *
 * Note that the second parameter here is simply "payload", and not
 * payload: payload. This is because ES6 allows you to omit the right
 * hand side of an object property assignment if it's the same
 * as the left side. Either version works, but this way
 * requires less typing.
 * @param {String} url - url as string to fetch the workspaces
 * @param {Object} resource - resource (object) to pass required data as part of fetch call
 * @return {{type: String, payload: Object}} The action object, including
 *      type (a String constant from actiontypes.js) and form data.
 */
export function getMyWorkspaces(url, resource) {
	return {
		type: types.LOAD_MY_WORKSPACES,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}

export function getClientAndWorkspaceDetails(url, resource) {
	return {
		type: types.GET_CLIENT_AND_WORKSPACES,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}

export function getFilteredClientAndWorkspaceDetails(url, resource) {
	return {
		type: types.GET_FILTERED_CLIENT_AND_WORKSPACES,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}

export function loadWorkspaces(payload) {
	return {type: types.LOAD_WORKSPACES, payload};
}

/*
 * make a post call for saving engagment into DB
 */
export function saveNewEngagement(url, resource) {
	return {
		type: types.SAVE_NEW_ENGAGEMENT,
		meta: {
			api: {
				url,
				method: 'POST',
				data: resource
			}
		}
	};
}

/*
 * Get call to get workspace details by ID
 */
export function getWorkspaceDetailsByID(url, resource, resolved = false) {
	return {
		type: types.GET_WORKSPACE_DATA,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		},
		resolve: resolved
	};
}

/*
 * Put call for updating engagment into DB
 */
export function updateEngagement(url, resource) {
	return {
		type: types.PUT_ENGAGEMENT,
		meta: {
			api: {
				url,
				method: 'PUT',
				data: resource
			}
		}
	};
}

/*
 * Put call for updating workspace
 */
export function updateWorkspace(url, resource) {
	return {
		type: types.UPDATE_WORKSPACE,
		meta: {
			api: {
				url,
				method: 'PUT',
				data: resource
			}
		}
	};
}

export function getReviewEngagements(url, resource) {
	return {
		type: types.LOAD_REVIEW_ENGAGEMENTS,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {resource}
			}
		}
	};
}
