import React, {useState, useEffect} from 'react';
import {MotifIcon} from '@ey-xd/motif-react';
import {imageIcTimer24px} from '@ey-xd/motif-react/assets/icons';
import moment from 'moment';
import {labels} from '../../util/uiconstants';
import styled from 'styled-components';

const ExpiryDateLabel = (props) => {
	const [expiryDays, setExpiryDays] = useState(-1);

	const getExpiryDatesCount = (expiryDate) => {
		if (expiryDate) {
			let startDate = moment(expiryDate.split('T')[0]).startOf('day');
			let currentDate = moment(new Date()).startOf('day');
			let diff = startDate.diff(currentDate, 'days');
			setExpiryDays(diff);
			if (diff > -1) {
				props.setHasCountdown(true);
			}
		}
	};

	useEffect(() => {
		getExpiryDatesCount(props.expiryDate);
	}, []);

	return (
		<ExpiryDateLabelStyle className="restore-expiry">
			{expiryDays > -1 && (
				<div className="expiry-label">
					<MotifIcon src={imageIcTimer24px} />
					<span>
						{expiryDays === 0 || expiryDays === 1
							? labels.expirydatetodaytomorrowlabel
							: labels.expirydatelabel}
					</span>
					<span className="expiry-days-label">
						{expiryDays === 0
							? ` ${labels.today}.`
							: expiryDays === 1
							? ` ${labels.tomorrow}.`
							: ` ${expiryDays} ${labels.days}.`}
					</span>
				</div>
			)}
		</ExpiryDateLabelStyle>
	);
};

export default ExpiryDateLabel;

const ExpiryDateLabelStyle = styled.section`
	.expiry-label {
		line-height: normal;
		font-size: 1.4rem;
		.motif-icon {
			color: ${(props) => props.theme.color[`red600`]};
			svg {
				width: 2.1rem;
				height: 2.1rem;
				vertical-align: sub;
				margin-right: 0.5rem;
			}
		}
		.expiry-days-label {
			font-weight: bold;
		}
	}
`;
