// This Middleware checks for version from api reponse header (apiv)
// We are falling back to apiv version if the app is open during deployment
// There would be no other call to static files in this scenario and would only make api calls

import compareVersions from 'compare-versions';

const versionCheck = (config) => (store) => (next) => (action) => {
	if (!action.isOnline) {
		return next(action);
	}

	if (!(action.meta && action.meta.api)) {
		return next(action);
	}

	if (!action.meta.api.url.includes(config.versionCheckUrl)) {
		return next(action);
	}

	//Check for config availability
	if (!config) {
		const configMsg = `
        Config unavailable.
        The AD package requires config to get the authorization token for the api call.`;
		console.log(configMsg);
	}

	const APP_VERSION = 'app.version';
	let currentVersion = localStorage.getItem(APP_VERSION) || '';
	let apiVersion =
		(action.responseObj &&
			action.responseObj.headers &&
			action.responseObj.headers['apiv']) ||
		'';

	//Compare version only if both versions (current and api) are available
	if (apiVersion && currentVersion) {
		if (compareVersions(apiVersion, currentVersion) > 0) {
			store.dispatch({
				type: 'VERSION_UPDATE',
				upgrade: true,
				version: apiVersion
			});
		}
	} else {
		// if only apiVersion is avaialble then set it to localStorage and continue
		// we are not displaying the popup here because this is the scenario when user clears cache or accesses the app for first time.
		if (!currentVersion && apiVersion) {
			localStorage.setItem(APP_VERSION, apiVersion);
		}
	}
	return next(action);
};

export default versionCheck;
