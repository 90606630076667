import styled from 'styled-components';
import {lightColor} from '@ey/styled-theme';

const EditUserPermissionsWrapper = styled.section`

.Userloadingstyle{
    height: calc(100vh - 19.1rem) !important;
}

.inputDisabled {
    opacity: 0.8;
    
    label,
    .ellipses,
    label.motif-label.motif-label-for-text{
        color: ${lightColor[`grey400`]}!important;
    }

    .motif-input,
    .react-select__control{
        background: ${(props) => props.theme.color[`grey100grey450`]}!important;
        color: ${lightColor[`grey400`]}!important;
        
        &:hover{
            background: ${(props) =>
							props.theme.color[`grey100grey450`]}!important;
        }
    }
}

.motif-input,
.react-select__control{
    background: ${(props) => props.theme.color[`grey100grey450`]}!important;
    &:hover{
        background: ${(props) => props.theme.color[`grey50`]}!important; 
    }
}

.motif-modal-footer{
    padding: 1.5rem 2rem;

    &.footermsg{
        flex-direction: column;

        .requiredtext{
            margin-left: 1rem;
        }

        .warning-wrap{         
            display: flex;
            line-height: 2rem;
            padding-bottom: 2rem;
            color: ${(props) => props.theme.color[`grey500`]};

            .motif-icon {
                margin-right: 0.5rem;

                svg{
                    fill: ${(props) => props.theme.color[`red600`]};
                    width: 2.4rem;
                    height: 2.4rem;
                    max-width: 2.4rem;
                    max-height: 2.4rem;
                }
            }

            .warningmessage{
                line-height: 2.4rem;
            }
        }

        .footer-btn{
            display: flex;
            width: 100%;
        }
    }

    .motif-button{
        font-size:1.3rem;
        padding: 0.8rem 2rem;

        &.motif-button-primary-alt:disabled{
            background-color:${(props) => props.theme.color[`grey200`]};
            border-color:${(props) => props.theme.color[`grey200`]};
            color:${(props) => props.theme.color[`grey400`]};

            &:hover{
                background: ${(props) => props.theme.color[`white`]};
                color:${(props) => props.theme.color[`black`]};
                border-color:${(props) => props.theme.color[`black`]};
            }
        }

        &.motif-button-secondary:disabled{
            &:hover{
                background: ${(props) => props.theme.color[`white`]};
                color:${(props) => props.theme.color[`black`]};
                border-color:${(props) => props.theme.color[`black`]};
            }
        }
        
    }
}

& > *{
    font-size:1.4rem;
    color: ${(props) => props.theme.color[`grey400`]};
}

.thirdpartydescription{
    padding: 1rem 0 1rem 0;
    line-height: 2rem;
    color:${(props) => props.theme.color[`grey500`]};
}

.addmemberwrapper{
    overflow:auto;
    height:calc(100vh - 25.5rem);
}

.addmemberholder {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    
    //ticketstart
			.ticketdetail{
				margin: 2rem 0 8rem 0;
				h5{
					font-size: 1.4rem;
					font-weight: normal;
                    margin-bottom: 1rem;
					color: ${(props) => props.theme.color[`grey500`]};
					border: 0!important;
				}

				.motif-form-field{
					width: 29rem;
				}

				.motif-form-field:not(.motif-form-field-has-value),
				.motif-form-field:not(.motif-form-field-focused){
					.motif-label{
						font-size: 1.4rem;
						font-weight:normal;
						margin-top: 0.6rem;
						color: ${(props) => props.theme.color[`black`]};
					}
				}
		
				.motif-form-field-has-value,
				.motif-form-field-focused{
					.motif-label{
						font-size: 1.2rem!important;
						font-weight: normal!important;
						margin-top: 0.2rem!important;
						 color: ${(props) => props.theme.color[`black`]};
					}
				}
				
				
				.motif-input {
					font-size: 1.4rem;
					line-height: 3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					color: ${(props) => props.theme.color[`black`]};
					&::placeholder {
						font-size: 1.4rem;
					}
				}
			}
			//ticketend

    .react-select-container{
     .react-select__value-container{
        height: 4rem!important;
        line-height: normal!important;
     }   
    }

    h5{
        font-size: 1.4rem;
        color:${(props) => props.theme.color[`black`]};
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    .description{
        font-size: 1.4rem;
        color:${(props) => props.theme.color[`black`]};
    }

    & > .toastMessage{        
        width: 100%!important;
        position: relative;
        display:block;

        .motif-toast{
			position:absolute;
			z-index: 9999;
            // background: ${(props) => props.theme.color[`white`]};
            margin: 0;
            
            .motif-toast-content{
                font-size: 1.4rem;
                width:100%;
            }
		}
    }

    .motif-typeahead-close-button{
       display:none;
    }

    .userfield-row{
        margin: 1rem 0;
        width: 100%;

        .css-b8ldur-Input{
            react-select__input{
                padding-top: 0!important;
            }
        }

        &.nameandemail {
    
        label{     
            margin-left: auto;
            left: 1rem;
        }

        .react-select-container.react-select--is-disabled .react-select__control{
            height: 4.7rem;
            min-height: auto;

            .react-select__value-container{
                height: auto;
                min-height: auto;
            }
        }

        .react-select__single-value{
            margin-left: auto;
            left: 1rem;   
            top: 2.4rem;
        }

        .react-select__indicators {
            display:none;
        }

        .react-select__single-value > section > section:last-child{
            font-size: 1.3rem!important;
            margin-top: 0;
        }
        
        .react-select__menu{
            z-index: 999;
        }
    }
        &.email > .useremailid,
        &.nameandlastname > .userfirstname,
        &.nameandlastname > .userlastname{
            width: 45%;
            float:left;
            margin-right: 4%;
        }

        &.initialandrole{
            & > .userinitial{
                width: 48%;
                float:left;
                margin-right: 4%;
            }

            & .engagementrole{
                width: 48%;
                margin-right: 0%;
                float: left;

                .react-select__control{
                    height: 4.7rem;
                }
            }

            & .confidentialityType {
                width: 100%;
            }


            &.external > .userinitial {
                width: 15%;
                float:left;
                margin-right: 4%;
            }
        }
    }
    & > * {
            display:inline-block;
        }
    }

.user-confid-permission{
    width: 100%;

    .checklist{
        margin: 0 0 1rem 0;
    }

    h4 {			
        color:${(props) => props.theme.color[`grey500`]};
        font-size: 1.5rem;
        font-weight: bold;
    }

    .checkoption-list{
        display: flex;
        flex-wrap: wrap;
        width:100%;

        .motif-checkbox-disabled .motif-checkbox-input,
        .motif-checkbox-disabled .motif-checkbox-custom {
            border: 0;
            background: ${(props) => props.theme.color[`grey300`]}!important;
        }

        .motif-checkbox:hover .motif-checkbox-label {
            text-decoration: none;
        }

        & li{
            width: 45%;
            list-style-type:none;
            margin: 0.8rem 4% 0.8rem 0;
            position: relative;

            & .checkoption-item:hover ~ .infotooltip{
                display: block;
            }
        }

        .motif-checkbox-label{
            font-size:1.4rem;
            width: 100%;
            font-weight:normal;
        }

        .infotooltip {
            position: absolute;
            z-index: 99999;
            background: ${(props) => props.theme.color[`white`]};
            left: 0;
            max-width: 40rem;
            bottom: 4.2rem;
            padding: 1rem;
            line-height: 2rem;
            z-index: 1;
            box-shadow: 0 0 0.8rem 0.2rem ${(props) =>
							props.theme.color[`grey225`]};
            // word-break: break-all;
            display: none;

            & .tooltip-downarrow {              
                position: absolute;
                width: 1.3rem;
                height: 0.6rem;
                display: block;
                bottom: 0;
                z-index: -1;
                left: 4rem;
            

                /* Stroke */
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: auto;
                    right: auto;
                    bottom: -0.7rem;
                    width: 0;
                    height: 0;    
                    transform: translateX(-50%) rotate(-45deg) skew(-9deg, -9deg);
                    box-shadow: -0.4rem 0.4rem 0.5rem -0.2rem ${(props) =>
											props.theme.color[`grey225`]};            
                    border: 1.1rem solid ${(props) =>
											props.theme.color[`white`]} !important;                   
                }
            }
        }
    }
}

header.access-group{
    h4{
        color:${(props) => props.theme.color[`grey500`]};
        font-size: 1.5rem;
        font-weight:bold;
    }
     svg {
         margin-left: 0.4rem;
     }
}

header.headerchecklist{
    padding: 1rem 0 0.5rem 0;
    
    svg {
        vertical-align:middle;
    }
}

header.access-group{
    padding: 1rem 0 1rem 0;
    
    svg {
        vertical-align:middle;
    }
}

header.addmemberdescription{
    padding: 1rem 0 0.5rem 0;
    display:inline-block;
    width: 100%;

    &.userinfo{
        padding-bottom:0;
    }

    h4{
        margin: 0;
        color: ${(props) => props.theme.color[`grey500`]};
        font-weight: bold;
        font-size: 1.5rem;
    }

}

header.addmemberdescription,
header.headerchecklist,
header.access-group{	
    h4{
        padding: 0;
        margin: 0;
        display:inline;
    }
}

    .motif-form-field.motif-form-field-has-value .motif-label-for-text{
        top: 0.5rem;
    }
    
    .motif-label-for-text:not(.motif-has-content){
        color: ${(props) => props.theme.color[`grey500`]};
        font-weight: normal;
    }
    
    &.motif-form-field{
        margin-bottom: 0;

        .motif-form-field{
            margin: 0;
        }
    }

    &.hidePlaceHolder:not(floating-label) label{
        color: ${(props) => props.theme.color[`grey400`]};	
    }

    &:error{
        border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
    }

    &.floating-label.hidePlaceHolder label{
        color: ${(props) => props.theme.color[`grey400`]};	
    }

    &.hidePlaceHolder label{
        color: ${(props) => props.theme.color[`grey400`]};	 	
    }

    &:not(.floating-label) label{
        font-size: 1.4rem;
        
    }

    &.userfirstname,
    &.userlastname{
        width:100%;
        margin-right: 3em;
    }

    &.userinitial{
        width: 100%;
        margin-right: 3em;
    }
}

.userfield-row.nameandemail {
    svg.note-error-icon{
        fill:${(props) => props.theme.color[`red600`]};
    }
}


.accessgroupwrapper{

    .access-group svg{
        fill: ${(props) => props.theme.color[`black`]};
    }

    &.userfield-row{
        width: 100%;
        margin-top: 0;

        svg.note-error-icon{
            fill:${(props) => props.theme.color[`red600`]};
        }

        .accessgroup{

            .react-select__input{
                color: ${(props) => props.theme.color[`black`]};
            }
            
            .react-select-container .react-select__control .react-select__value-container.react-select__value-container--is-multi{
                padding-top: 2rem;
            }
        }
    }

    .react-select__menu{
        z-index: 9999;
    }

  
}
`;

export default EditUserPermissionsWrapper;
