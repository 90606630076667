import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import ModalControl from '../../../Common/Modal/Modal';
import Loader from '@ey/loader';
import {
	MotifSelect,
	MotifOption,
	MotifFormField,
	MotifLabel,
	MotifButton
} from '@ey-xd/motif-react';
import EllipsesControl from '@ey/ellipses-control';
import {labels, moduleType} from '../../../../util/uiconstants';
import env from '../../../../util/env';
import {
	getCountries,
	getSubServiceLines,
	getAdminRoles
} from '../../../../actions/admin/masterdataactions';
import {setIsModalOpen} from '../../../../actions/modalstateactions';
import {clearErrors} from '../../../../actions/erroractions';
import {addRole} from '../../../../actions/admin/manageaccessactions';
import Toast from '../../../Common/Toast/Toast';
import {useLocation} from 'react-router-dom';
import {getLocalizedAPIError} from '../../common/util';
import TicketField from '../../common/ticketfield';

function AssignRole(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const modules = useSelector((state) => state.modules);
	const roles = useSelector((state) => state.roles);
	const countries = useSelector((state) => state.countries);
	const subServiceLines = useSelector((state) => state.subServiceLines);

	const [selectedModuleId, setselectedModuleId] = useState(null);
	const [selectedRoleId, setSelectedRoleId] = useState('');
	const [selectedSubServiceLineId, setSelectedSubServiceLineId] = useState(
		null
	);
	const [selectedCountryIds, setCountryIds] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isAssignButonEnable, setIsAssignButonEnable] = useState(false);
	const [keyCounter, setKeyCounter] = useState(0);
	const [errorMessage, setErrorMessage] = useState(false);
	const [isErrorOccurred, setIsErrorOccurred] = useState(false);
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	const onTicketChanged = (e) => {
		setTicketNumber(e.target.value);
	}

	const type = {
		module: 'modules',
		role: 'roles',
		servicLine: 'servicLine',
		countries: 'countries'
	};
	const location = useLocation();

	useEffect(() => {
		getApiCall();
		clearError(true);
	}, []);

	useEffect(() => {
		if (
			selectedModuleId != moduleType.Engagements &&
			selectedModuleId &&
			selectedRoleId
		) {
			setIsAssignButonEnable(true);
		} else if (
			selectedModuleId == moduleType.Engagements &&
			selectedModuleId &&
			selectedRoleId &&
			selectedSubServiceLineId &&
			selectedCountryIds.length > 0
		) {
			setIsAssignButonEnable(true);
		} else {
			setIsAssignButonEnable(false);
		}
	}, [
		selectedModuleId,
		selectedRoleId,
		selectedSubServiceLineId,
		selectedCountryIds
	]);

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	const getApiCall = () => {
		let URL = env.getURL('serviceUrlV2');

		if (Object.keys(countries).length === 0) {
			dispatch(getCountries(URL + 'countries?pageSize=-1'))
				.then(() => {})
				.catch((e) => {
					setErrorMessage(e.toString());
				});
		}

		if (Object.keys(subServiceLines).length === 0) {
			dispatch(getSubServiceLines(URL + 'ServiceLines'))
				.then(() => {})
				.catch((e) => {
					setErrorMessage(e.toString());
				});
		}

		if (Object.keys(roles).length === 0) {
			dispatch(getAdminRoles(URL + 'SecurityRoles?isAdminRole=true'))
				.then(() => {})
				.catch((e) => {
					setErrorMessage(e.toString());
				});
		}
	};

	const saveRole = () => {
		setIsSubmitClicked(true);
		if (ticketNumber.trim().length <= 0) {
			return;
		}

		setIsLoading(true);
		let userId = location.pathname.split('/').at(-1);
		let assignRoleUrl =
			env.getURL('serviceUrlV2') +
			'admin/Users/' +
			userId +
			'/roles/' +
			selectedRoleId;
		var assignRoleScope = {
			subServiceLineId: parseInt(selectedSubServiceLineId),
			countryIds: selectedCountryIds.map((v) => parseInt(v))
		};

		let data = {
			scope: assignRoleScope,
			ticketNumber: ticketNumber
		}

		dispatch(addRole(assignRoleUrl, data))
			.then(() => {
				hideRolesDrawer();
				props.setIsSubmitted(true);
			})
			.catch((error) => {
				setIsErrorOccurred(true);
				setErrorMessage(getLocalizedAPIError(error));
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const hideRolesDrawer = () => {
		setselectedModuleId(null);
		setSelectedRoleId(null);
		setSelectedSubServiceLineId(null);
		setCountryIds([]);
		setIsErrorOccurred(false);
		props.setAssignRoleModalFromChild(false);

		let location = history.location;
		let path = location.pathname + location.search;
		if (location.hash && location.hash.length > 0) {
			history.push(path);
			history.replace({state: {customLabel: props?.customLabel}});
		}
	};

	const getCountriesForDropDown = () => {
		let modulesItems = [];
		if (Object.keys(countries).length > 0) {
			_.forEach(countries, (item) => {
				if (item) {
					modulesItems.push({
						value: item.countryId.toString(),
						label: item.countryName
					});
				}
			});
		}
		return modulesItems;
	};

	const getModulesForDropDown = () => {
		let modulesItems = [];
		if (Object.keys(modules).length > 0) {
			_.forEach(modules, (item) => {
				if (item) {
					modulesItems.push({
						value: item.id.toString(),
						label: item.name
					});
				}
			});
		}
		return modulesItems;
	};

	const getRolesForDropDown = () => {
		let roleItems = [];

		if (Object.keys(roles).length > 0) {
			const filteredRoles = roles?.filter(function (item) {
				return item.moduleId == selectedModuleId;
			});

			_.forEach(filteredRoles, (item) => {
				if (item) {
					roleItems.push({
						value: item.rbacId.toString(),
						label: item.name
					});
				}
			});
		}

		return roleItems;
	};

	const getSubServiceLinesItems = () => {
		let subServiceLinesItems = [];
		if (Object.keys(subServiceLines).length > 0) {
			_.forEach(subServiceLines, (item) => {
				subServiceLinesItems.push({
					label: item.name,
					value: item.id.toString()
				});
			});
		}
		return subServiceLinesItems;
	};

	const formatOptionLabel = ({value, label}) => (
		<EllipsesControl content={label} tooltip={label} noOfLines={1} />
	);

	const createDropDown = (
		collection,
		uniqueName,
		label,
		multiple = false,
		showselectallbutton = false,
		key = 0
	) => {
		return (
			<MotifFormField>
				<MotifLabel id="select-label-search">{label}</MotifLabel>
				<MotifSelect
					id={uniqueName}
					onChange={(e) => dropDownOnChange(e, uniqueName)}
					filter
					arialabelledby="select-label-search"
					multiple={multiple}
					showselectallbutton={
						showselectallbutton ? showselectallbutton : undefined
					}
					key={key}
				>
					{collection?.map((item) => (
						<MotifOption
							key={item.value}
							value={item.value}
							label={item.label}
							formatOptionLabel={formatOptionLabel(item.value)}
						>
							{item.label}
						</MotifOption>
					))}
				</MotifSelect>
			</MotifFormField>
		);
	};

	const dropDownOnChange = (option, actionType) => {
		switch (actionType) {
			case type.module:
				setKeyCounter(keyCounter + 1);
				setSelectedRoleId(null);
				setSelectedSubServiceLineId(null);
				setCountryIds([]);
				setselectedModuleId(option == '' ? null : option);
				break;
			case type.role:
				setSelectedRoleId(option);
				break;
			case type.servicLine:
				setSelectedSubServiceLineId(option);
				break;
			case type.countries:
				setCountryIds(option);
				break;
		}
	};

	return (
		<Mainwrapper>
			<ModalControl
				title={labels.manageAccessAssignRole}
				closeTitle={labels.close}
				closeBtnTitle={labels.cancel}
				modalsize="message"
				show={props.showAssignRoleModal}
				isOkButtonDisabled={false}
				showButtons={false}
				modalContainerClass="assignrolewrapper"
				onHide={() => hideRolesDrawer()}
			>
				{isLoading ? (
					<Loader show fullscreen view="blockView" styleName="loadingstyle" />
				) : (
					<section className="assignrole">
						<section className="assignrolemodal customScrollbar">
							<section className="wrapper">
								<h5 className="labeltext">{labels.assignRoleModalMessage}</h5>
								<section
									className="toastMessage"
									style={{display: isErrorOccurred ? 'block' : 'none'}}
								>
									<Toast
										variant="error"
										onClose={() => {
											setIsErrorOccurred(false);
											setErrorMessage(false);
										}}
									>
										{errorMessage}
									</Toast>
								</section>
								{createDropDown(
									getModulesForDropDown(),
									type.module,
									labels.selectModule
								)}

								{/* {selectedModuleId ? ( */}
								{createDropDown(
									getRolesForDropDown(),
									type.role,
									labels.selectRole,
									false,
									false,
									keyCounter
								)}
								{/* ) : (
									<></> */}
								{/* )} */}

								{selectedModuleId == moduleType.Engagements ? (
									<>
										{createDropDown(
											getSubServiceLinesItems(),
											type.servicLine,
											labels.serviceLine
										)}
										{createDropDown(
											getCountriesForDropDown(),
											type.countries,
											labels.countries,
											true,
											true
										)}
									</>
								) : (
									<></>
								)}
							</section>
							<TicketField
								ticketNumber={ticketNumber}
								onTicketChanged={onTicketChanged}
								showValidation={isSubmitClicked}
							/>
						</section>
						<section className="modal-footer">
							<MotifButton
								type="button"
								variant="primary-alt"
								disabled={!isAssignButonEnable || ticketNumber.trim().length <= 0}
								onClick={() => saveRole()}
							>
								{labels.confirmAssignRoleButton}
							</MotifButton>
							<MotifButton
								type="button"
								variant="secondary"
								onClick={() => hideRolesDrawer()}
							>
								{labels.cancel}
							</MotifButton>
						</section>
					</section>
				)}
			</ModalControl>
		</Mainwrapper>
	);
}

const Mainwrapper = styled.section`
	overflow: hidden;

	//heading
	.manageaccess {
		.pageheading {
			display: flex;
			padding: 2rem 0;
			svg {
				min-width: 2.5rem !important;
				min-height: 2.5rem !important;
				margin-right: 1rem;
			}

			h3 {
				margin: 0;
				line-height: 2.5rem;
			}
		}
	}

	//addon

	.add-on {
		display: flex;
		margin-right: auto;
		padding: 0 0 2rem 0;

		.adduser {
			.motif-button {
				height: 4rem;
				font-size: 1.2rem;
				width: 10rem;
			}
		}

		.search {
			width: 16%;
			line-height: 4rem;
			margin-right: auto;

			.motif-icon-button{
                top: 0.5rem;
            }

			.motif-typeahead-close-button {
				top: 0.4rem;
				&:hover{
					background: none!important;
				}
			}

			.motif-input {
				font-size: 1.4rem;
				font-weight: normal;
				height: 4rem;
				&::placeholder {
					font-size: 1.4rem;
					font-weight: normal;
				}
			}
		}
	}

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: 0rem;
	}

	.manageaccess-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: 1.4rem;
		background: ${(props) => props.theme.color[`white`]};
		border-top: 1px solid ${(props) => props.theme.color[`grey200`]};
	}

    .norecord{
        height: calc(100vh - 30rem);
        display: inline-block;
        width: 100%;
        display: flex;
        justify-content: center;
        flex: 0 auto;
        align-items: center;
        font-size: 1.6rem;
        color: ${(props) => props.theme.color[`grey400`]};
    }

    .managerolesTable {
        height: 100%;
        overflow-y: auto;
        .motif-table {
            height: calc(100vh - 25rem);
    
            &.ag-theme-material .ag-checkbox-input-wrapper:focus-within {
                box-shadow: none;
            }
    
            .ag-header-cell {
                color: ${(props) => props.theme.color[`grey450`]};
                &:hover,
                    &.ag-header-cell-moving{
                        background: transparent!important;
                    }
            }
    
            .ag-header-cell .ag-header-select-all{
                visibility: hidden;
            }
    
            .ag-body-viewport{
                height: calc(100vh - 30rem);
                border: 0.1rem solid ${(props) =>
									props.theme.color['grey200']} !important;
    
                &:after{
                    content: none;
                }
            }
    
            .ag-header-viewport {
                background: ${(props) => props.theme.color[`grey100`]};
                font-size: 1.4rem;
            }
            .ag-root.ag-layout-normal {
                overflow-y: auto;
                width: 100%;
            }
    
            .ag-root.ag-layout-auto-height {
                display: block;
            }
    
            .ag-center-cols-viewport {
                overflow-x: hidden;
            }
    
            .ag-row-hover{
                border-width: 0 0 0.1rem 0 !important;		
                border-style: solid !important;
                border-color: ${(props) =>
									props.theme.color[`grey250`]} !important;
            }
    
            .ag-row{
                border-width: 0 0 0.1rem 0 !important;		
                border-style: solid !important;
                border-color: ${(props) =>
									props.theme.color[`grey250`]} !important;
                color: ${(props) => props.theme.color[`grey400`]};
    
                .ag-cell-value{
                    font-weight: 100;
                }
            }
    
            .ag-row,
            .ag-row-hover {
                background: ${(props) => props.theme.color[`white`]} !important;
                font-weight: normal;
    
                .ag-column-hover {
                    background: ${(props) =>
											props.theme.color[`white`]} !important;
                }
            }
    
            .ag-header-row .ag-header-cell{
                &:hover{
                    background: ${(props) => props.theme.color[`grey100`]}
                }
            }
    
            .ag-cell,
            .ag-header-cell {
                font-size: 1.4rem;
                padding: 0 2rem;
                display: block;
    
                
                .ag-react-container {
                    [role='button'] {
                        cursor: default;
                    }
                }
    
                .ag-header-cell-text{
                    font-size: 1.4rem;
                }
            }
    
            .ag-header-cell:last-child,
            .ag-cell:last-child {
                border-right: none;
                display: flex;
                text-align:right;
                justify-content: center;
            }
    
            .roleType.disabled,
            .roleType.disabled *{
                color: ${(props) => props.theme.color[`grey300`]}!important;
                fill: ${(props) => props.theme.color[`grey300`]};
            }
            .noptr{
                pointer-events: none;
            }
            .disabled{
                color: ${(props) => props.theme.color[`grey300`]}!important;
                fill: ${(props) => props.theme.color[`grey300`]};
            }
    
            .roleType {
                display: inline-block;
                width: 100%;
                align-items: center;
    
                &.enable.icon{
                    fill: ${(props) => props.theme.color[`black`]}!important;
                }
                .columnName{
                    font-size: 1.3rem;
                    font-weight: bold;
                    color: ${(props) => props.theme.color[`grey500`]};
                    padding-bottom: 0.5rem;
                    }
                }
            }
    
            .errorRow {
                color: ${(props) => props.theme.color[`red600`]} !important;
            }
        }
    
        .ag-body-horizontal-scroll {
            display:none;
        }
    
        .ag-layout-normal {
            &::-webkit-scrollbar {
                width: 0.6rem;
            }
            &::-webkit-scrollbar-track {
                background: ${(props) => props.theme.color[`grey225`]};
                width: 0.6rem;
            }
            &::-webkit-scrollbar-thumb {
                background: ${(props) => props.theme.color[`grey300`]};
                width: 0.6rem;
                cursor: pointer;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: ${(props) => props.theme.color[`grey400`]};
            }
        }
    
    }
`;

export default AssignRole;
