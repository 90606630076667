__webpack_nonce__ = voltronNonce


function setupMethod(target, method) {

    function parseStackHosts(stackString) {
        return stackString
            .split(/\n/gm)
            .map(line =>
                (/^\s+at\s+([^(]+?\s+\()?https?:\/\/([^/]+)/.test(line) && RegExp.$2)// Chromium stack format
                || (/^([^(]+)?@https?:\/\/([^/]+)/.test(line) && RegExp.$2) // Firefox/Safari stack format
                || null // No matching format
            )
            .filter(host => host);
    }

    function getCallingHost(stackString) {
        let hosts = parseStackHosts(stackString);
        return hosts[1];
    }

    function matchHost(hostPattern, host) {
        if (hostPattern === "'self'" && host === location.host) {
            return true;
        } else {
            let pattern = hostPattern.replace(/\*\./g, "([\\w-]+\\.)*");
            let regex = new RegExp(`^${pattern}$`);
            return regex.test(host);
        }
    }

    return function (e) {
        if (e.matches("style") && !e.hasAttribute("nonce")) {
            let host = getCallingHost(new Error().stack);
            let matches = voltronCSPHosts.filter(pattern => matchHost(pattern, host));

            if (matches.length) {
                e.setAttribute("nonce", __webpack_nonce__);
            }

        }
        method.call(target, ...arguments);
    };
}

document.body.appendChild = setupMethod(document.body, document.body.appendChild);
document.head.appendChild = setupMethod(document.head, document.head.appendChild);
document.head.insertBefore = setupMethod(document.head, document.head.insertBefore);
