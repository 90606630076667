import React from 'react';
import {labels} from '../../../../../util/uiconstants';

function ManageRolesHeader() {
	return (
		<section className="userlist-header">
			<section className="userlistheader">
				<section className="userlistitem">
					{labels.manageRolesTableModule}
				</section>
				<section className="userlistitem">
					{labels.manageRolesTableRoles}
				</section>
				<section className="userlistitem">
					{labels.manageRolesTableServiceLine}
				</section>
				<section className="userlistitem">
					{labels.manageRolesTableCountries}
				</section>
				<section className="userlistitem delete">{''}</section>
			</section>
		</section>
	);
}

export default ManageRolesHeader;
