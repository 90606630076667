import React, {useState, useEffect} from 'react';
import {getTemplateEngagementDetailsForImport} from '../../../actions/importengagementaction';
import SelectRegion from './selectregion';
import SelectTemplate from './selecttemplate';
import ChooseNewExisting from '../EngagementWorkflowsNew/chooseneworexisting';
import SelectExisting from '../EngagementWorkflowsNew/selectworkspace';
import ImportEngagement from '../RollForwardWorkflow/engagement/createengagement';
import CreateEditWorkspace from '../EngagementWorkflowsNew/createeditworkspace';
import {labels, standardStrings} from '../../../util/uiconstants';
import {useHistory} from 'react-router-dom';
import WizardWrapper from '../Common/WizardWrapper';
import * as QueryString from 'query-string';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import env from '../../../util/env';

const ImportWizardLoader = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const templateEngagementInfo = useSelector((store) => store.templateEngInfo);

	const params = QueryString.parse(location.search);

	const [activeStepIndex, setActiveStepIndex] = useState(
		params.engagementid ? 1 : 0
	);
	const [step1NextChng, setstep1NextChng] = useState(1);
	const [step2NextChng, setstep2NextChng] = useState(1);
	const [step4NextChng, setstep4NextChng] = useState(1);
	const [step5FinishChng, setstep5FinishChng] = useState(1);
	const [canGoToNextStep, setcanGoToNextStep] = useState(false);
	const [domain, setDomain] = useState({
		domainChooseExisting: false,
		domainWorkspaceId: -1,
		domainClientId: -1,
		domainEngagementid: -1,
		domainClientName: standardStrings.EMPTY,
		domainWorkspaceName: standardStrings.EMPTY,
		domainLocation: standardStrings.EMPTY,
		domainClientCode: -1,
		domainSelectedRegion: standardStrings.EMPTY,
		domainOriginLanguageId: -1,
		domainOriginEngagementId: -1,
		domainSelectedTemplate: standardStrings.EMPTY,
		domainTemplateId: standardStrings.EMPTY
	});
	const [wizardSteps, setWizardSteps] = useState([
		{
			stepName: labels.step01,
			stepDescription: labels.importregionstepheader,
			active: true
		},
		{
			stepName: labels.step02,
			stepDescription: labels.importtemplatestepheader,
			active: true
		},
		{
			stepName: labels.step03,
			stepDescription: labels.chooseimporthdr,
			active: true
		},
		{
			stepName: labels.step04,
			stepDescription: domain.domainChooseExisting
				? labels.importstephdrext
				: labels.importstephdrnew,
			active: true
		},
		{
			stepName: labels.step05,
			stepDescription: labels.createengagementquestionlabel,
			active: false
		}
	]);

	const oldPath = props.location?.state?.oldPath
		? props.location?.state?.oldPath
		: '';

	const wizLoaderName = labels.importengagementheader;

	const mode = {
		create: 'create',
		edit: 'edit',
		rollForward: 'rollForward',
		import: 'import'
	};

	const buttons = [
		{
			label: activeStepIndex == 0 ? labels.cancel : labels.back, // Change label based on active step.
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						navigateBack();
						break;
					case 1:
						resetDomainValues([
							'domainTemplateId',
							'domainSelectedTemplate'
						]);
						setActiveStepIndex((prev) => prev - 1);
						break;
					case 2:
						setActiveStepIndex((prev) => prev - 1);
						break;
					case 3:
						setActiveStepIndex((prev) => prev - 1);
						break;
					case wizardSteps.length - 1:
						setActiveStepIndex((prev) => prev - 1);
						break;
				}
			},
			isVisible: true,
			disabled: false
		},
		{
			label:
				activeStepIndex === wizardSteps.length - 1
					? labels.finish
					: labels.continue,
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						canGoToNextStep
							? setActiveStepIndex((prev) => prev + 1)
							: setstep1NextChng((prev) => prev + 1);
						break;
					case 1:
						canGoToNextStep
							? setActiveStepIndex((prev) => prev + 1)
							: setstep2NextChng((prev) => prev + 1);
						break;
					case 2:
						resetDomainValues([
							'domainClientId',
							'domainClientName',
							'domainClientCode',
							'domainWorkspaceId',
							'domainLocation',
							'domainWorkspaceName'
						]);
						setActiveStepIndex((prev) => prev + 1);
						break;
					case 3:
						canGoToNextStep
							? setActiveStepIndex((prev) => prev + 1)
							: setstep4NextChng((prev) => prev + 1);
						break;
					case wizardSteps.length - 1:
						setstep5FinishChng((prev) => prev + 1);
						break;
				}
			},
			isVisible: true,
			disabled: false
		}
	];

	useEffect(() => {
		// If the "selecting new or create workspace" state is changed, update the step labels as needed.
		setWizardSteps((prev) => {
			return prev.map((step) => {
				return step.stepName === labels.step04
					? {
							...step,
							stepDescription: domain.domainChooseExisting
								? labels.selectexistingworkspacestepheader
								: labels.createworkspacestepheader
						}
					: step;
			});
		});
	}, [domain.domainChooseExisting]);

	useEffect(() => {
		if(Object.keys(templateEngagementInfo).length > 0){
			updateWizardData(
				'domainOriginLanguageId',
				templateEngagementInfo?.data?.languageid
			);
			updateWizardData('domainOriginEngagementId', templateEngagementInfo?.id);
		}
	}, [templateEngagementInfo]);

	// Step valid functions are triggered from inside components, thats how we manage canGoToNextStep local state.
	const isStep1Valid = (isComponentDataValid) => {
		if (activeStepIndex == 0) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep2Valid = (isComponentDataValid) => {
		if (activeStepIndex == 1) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep3Valid = (isComponentDataValid) => {
		if (activeStepIndex == 2) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep4Valid = (isComponentDataValid) => {
		if (activeStepIndex == 3) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep5Valid = (isComponentDataValid) => {
		if (activeStepIndex == 4) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};

	const getTemplateEngagementURL = () => {
		return (
			env.getURL('serviceUrl') +
			'/' +
			'engagements.json/' +
			domain.domainSelectedTemplate.templateEngagementId +
			'?phase=ImportTemplate&filters=workspaceid eq 0'
		);
	};

	const loadTemplateEngagementData = () => {
		dispatch(getTemplateEngagementDetailsForImport(getTemplateEngagementURL()));
	};

	const navigateBack = () => {
		history.push(oldPath);
	};

	const updateWizardData = (fieldName, fieldValue) => {
		setDomain((prev) => {
			return {
				...prev,
				[fieldName]: fieldValue
			};
		});
	};

	const getWizardData = (domainFieldName) => {
		return domain[domainFieldName];
	};

	const resetDomainValues = (valuesArray) => {
		valuesArray.forEach((valueName) => {
			updateWizardData(valueName, undefined);
		});
	};

	const step1 = () => {
		return (
			<SelectRegion
				isComponentValid={isStep1Valid}
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				nextClicked={step1NextChng}
			/>
		);
	};

	const step2 = () => {
		return (
			<SelectTemplate
				isComponentValid={isStep2Valid}
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				nextClicked={step2NextChng}
			/>
		);
	};

	const step3 = () => {
		return (
			<ChooseNewExisting
				isComponentValid={isStep3Valid}
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				headerQuestion={labels.importhdrqstn}
				loadTemplatEng={loadTemplateEngagementData}
			/>
		);
	};

	const step4 = () => {
		return domain.domainChooseExisting ? (
			<SelectExisting
				isComponentValid={isStep4Valid}
				updateWizardData={updateWizardData}
				nextClicked={step4NextChng}
				getDependencyData={getWizardData}
				stepHeaderText={labels.importstephdrext}
			/>
		) : (
			<CreateEditWorkspace
				isComponentValid={isStep4Valid}
				updateWizardData={updateWizardData}
				nextClicked={step4NextChng}
				getDependencyData={getWizardData}
				mode={mode.create}
				stepHeaderText={labels.importstephdrnew}
			/>
		);
	};

	const step5 = () => {
		return (
			<ImportEngagement
				isComponentValid={isStep5Valid}
				updateWizardData={updateWizardData}
				onFinishClick={step5FinishChng}
				getDependencyData={getWizardData}
				mode={mode.import}
			/>
		);
	};

	return (
		<>
			<StyledWizardWrapper>
				<WizardWrapper
					wizLoaderName={wizLoaderName}
					currentStepIndex={activeStepIndex}
					wizardSteps={wizardSteps}
					setWizardSteps={setWizardSteps}
					buttons={buttons}
					theme={
						document
							.getElementsByTagName('body')[0]
							.getAttribute('motif-theme') === 'light'
							? null
							: 'dark'
					}
					stepContent={[step1(), step2(), step3(), step4(), step5()]}
				/>
			</StyledWizardWrapper>
		</>
	);
};

export default ImportWizardLoader;

const StyledWizardWrapper = styled.section`
	width: 100%;
	padding: 0 4rem;
	header h5 {
		font-size: 2rem;
		font-weight: bold;
		width: 100%;
		padding: 1.5rem 0;
	}
	.componentBodyWrapper {
		height: calc(100vh - 28rem);
	}
`;
