import React, {useState} from 'react';
import {labels, permissionGroups} from '../../util/uiconstants';
import {EngagementAccessType, EngagementSource} from '../../util/enumerations';
import ContextMenu from '../Common/contextMenu';
import {EngagementStatus} from '../../util/enumerations.js';
import EngagementRemoveModal from './engagementRemoveModal';
import EngagementDeleteModal from './EngagementDeleteModal';
import OverrideArchiveDeadlineDateModal from './overrideArchiveDeadlineDateModal';
import {shallowEqual, useSelector} from 'react-redux';

const CogWheel = (props) => {
	const {
		data,
		incomplete,
		workspaceID,
		id,
		doRefreshDashboard,
		userPermissions
	} = props;
	const {
		engagementstatus,
		engagementisprofilecomplete,
		incountryuri,
		isinterimreviewengagement,
		engagementaccesstypeid
	} = data;

	const timeline = useSelector((store) => {
		let timelineData = {};
		if (store.timeline && Array.isArray(store.timeline.timeline)) {
			const timelineElement = store.timeline.timeline.find(
				(element) => element.id == id /* loose comparison by intent */
			);

			timelineData = (timelineElement && timelineElement.data) || timelineData;
		}

		return timelineData;
	}, shallowEqual);

	const [forceRefreshCounter, setForceRefreshCounter] = useState(1);
	const [showRemoveModalState, setshowRemoveModalState] = useState(false);
	const [showDeleteModalState, setshowDeleteModalState] = useState(false);
	const [
		showOverrideArchiveDeadlineDateModal,
		setShowOverrideArchiveDeadlineDateModal
	] = useState(false);

	//will force context menu to close
	const updateRefreshCounter = () => {
		setForceRefreshCounter((prevState) => prevState + 1);
	};

	const routeToManageTeam = () => {
		let manageTeamUrl = incountryuri + '/manageteam?' + '&engagementid=' + id;
		window.location.href = manageTeamUrl;
	};

	const getMenuItems = () => {
		let items = [];

		if (engagementaccesstypeid === EngagementAccessType.IndependenceOnly) {
			if (isRemoveEngagementApplicable()) {
				items.push(getRemoveEngagementItem());
			}
		} else {
			if (isEditEngagementApplicable()) {
				items.push(getEditEngagementItem());
				items.push(getEditWorkspaceItem());
			}

			if (isManageTeamApplicable()) {
				items.push(getManageTeamItem());
			}

			if (isRemoveEngagementApplicable()) {
				items.push(getRemoveEngagementItem());
			}

			if (isDeleteEngagementApplicable()) {
				items.push(getDeleteEngagementItem());
			}

			if (isOverrideArchiveDateApplicable()) {
				items.push(getOverrideArchiveDateItem());
			}

			if (isCRPApplicable()) {
				items.push(getCRPItem());
			}
		}

		return items;
	};

	const isEditEngagementApplicable = () => {
		return engagementstatus != EngagementStatus.ContentDeliveryInProgress;
	};

	const isManageTeamApplicable = () => {
		return canAccessAcitveAndSimilarEngagements();
	};

	const canAccessAcitveAndSimilarEngagements = () => {
		let eStat = engagementstatus;

		if (!engagementisprofilecomplete) {
			return true;
		}

		if (incomplete) {
			return false;
		}

		if (isContentDeliveryInProgress() === true) {
			return false;
		}

		if (
			eStat === EngagementStatus.Active ||
			eStat === EngagementStatus.Restored ||
			eStat === EngagementStatus.MarkedForDeletion
		) {
			return true;
		} else {
			return false;
		}
	};

	const isContentDeliveryInProgress = () => {
		const isContentDeliveryInProgress =
			timeline &&
			timeline.metrics &&
			timeline.metrics.engagementAlerts &&
			timeline.metrics.engagementAlerts.hasContentDeliveryInProgress;

		return isContentDeliveryInProgress === true;
	};

	const isRemoveEngagementApplicable = () => {
		return (
			engagementstatus !== EngagementStatus.ContentDeliveryInProgress &&
			(engagementisprofilecomplete ||
				engagementaccesstypeid === EngagementAccessType.IndependenceOnly)
		);
	};

	const isDeleteEngagementApplicable = () => {
		return (
			(data.engagementstatus !== EngagementStatus.MarkedForDeletion &&
				data.isengagementaccessible) ||
			data.engagementstatus === EngagementStatus.ContentDeliveryInProgress ||
			!data.engagementisprofilecomplete
		);
	};

	const isOverrideArchiveDateApplicable = () => {
		if(data?.engagementstatus === EngagementStatus.Restored || 
			data?.engagementsourceid === EngagementSource.Restore || 
			data?.engagementsourceid === EngagementSource.RestoreException
		){
			return false;
		}else{
			return canAccessAcitveAndSimilarEngagements();
		}
	};

	const isCRPApplicable = () => {
		let perm = userPermissions && userPermissions.userpermissions;

		if (perm) {
			var item = perm.find(
				(x) => x.groupname === permissionGroups.Global_ReviewPortal
			);
			return item != null ? true : false;
		}
	};

	const getEditEngagementItem = () => {
		let ret = {
			linkTo: {
				pathname: '/editengagement',
				search: '?workspaceid=' + workspaceID + '&engagementid=' + id
			},
			label: labels.contextMenuEditEngagement
		};
		return ret;
	};

	const getEditWorkspaceItem = () => {
		let ret = {
			linkTo: {
				pathname: '/editworkspace',
				search: '?workspaceid=' + workspaceID,
				state: {
					oldPath: '/'
				}
			},
			label: labels.contextMenuEditWorkspace
		};
		return ret;
	};

	const getManageTeamItem = () => {
		let ret = {
			linkTo: null,
			onClick: routeToManageTeam,
			label: labels.manageTeam
		};
		return ret;
	};

	const getRemoveEngagementItem = () => {
		let ret = {
			linkTo: null,
			onClick: showRemoveModal,
			label: labels.contextMenuRemoveEngagement
		};
		return ret;
	};

	const showRemoveModal = () => {
		updateRefreshCounter();
		setshowRemoveModalState(true);
	};

	const hideRemoveModal = () => {
		updateRefreshCounter();
		setshowRemoveModalState(false);
		doRefreshDashboard();
	};

	const quitRemoveModal = () => {
		updateRefreshCounter();
		setshowRemoveModalState(false);
	};

	const getDeleteEngagementItem = () => {
		let ret = {
			linkTo: null,
			onClick: showDeleteModal,
			label: labels.contextMenuDeleteEngagement
		};

		return ret;
	};

	const showDeleteModal = () => {
		updateRefreshCounter();
		setshowDeleteModalState(true);
	};

	const hideDeleteModal = () => {
		updateRefreshCounter();
		setshowDeleteModalState(false);
		doRefreshDashboard();
	};

	const quitDeleteModal = () => {
		updateRefreshCounter();
		setshowDeleteModalState(false);
	};

	const getOverrideArchiveDateItem = () => {
		let ret = {
			linkTo: null,
			onClick: showOverrideArchiveDateModal,
			label: labels.contextMenuOverrideArchiveDate
		};
		return ret;
	};

	const showOverrideArchiveDateModal = (e) => {
		e.preventDefault(); //
		setShowOverrideArchiveDeadlineDateModal(true);
	};

	const getCRPItem = () => {
		let ret = {
			linkTo: {
				pathname: '/crp',
				search: '?eid=' + id + '&T=1' + '&isInt=' + isinterimreviewengagement
			},
			label: labels.contextMenucrp
		};
		return ret;
	};

	return (
		<React.Fragment>
			<ContextMenu
				key={forceRefreshCounter}
				iconHover={labels.options}
				iconName={'more-dots'}
				iconstyleName={'large'}
				menuItems={getMenuItems}
			/>
			<EngagementRemoveModal
				show={showRemoveModalState}
				hide={hideRemoveModal}
				quit={quitRemoveModal}
				{...props}
			/>
			<EngagementDeleteModal
				show={showDeleteModalState}
				hide={hideDeleteModal}
				quit={quitDeleteModal}
				refreshDashboard={doRefreshDashboard}
				{...props}
			/>
			<OverrideArchiveDeadlineDateModal
				show={showOverrideArchiveDeadlineDateModal}
				closeModal={setShowOverrideArchiveDeadlineDateModal}
				engagementId={id}
				workspaceId={workspaceID}
				refreshDashboard={doRefreshDashboard}
			/>
		</React.Fragment>
	);
};

export default CogWheel;
