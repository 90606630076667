import React, { useState, useEffect } from 'react';
import Pagination from '@ey/pager';
import {
	currentResource,
	labels,
	pagingOptions,
	successMessageDisplayDuration,
	UserLookupType
} from '../../../../util/uiconstants';
import Headerbody from '../styles/userheaderbodystyle';
import EngagementUserHeader from './engagementuserheader';
import EngagementUserList from './engagementuserlist';
import env from '../../../../util/env';
import { useSelector, useDispatch } from 'react-redux';
import {
	getEngagementUsers,
	inviteEngagementMember
} from '../../../../actions/admin/engagement/engagementActions';
import Loader from '@ey/loader';
import { setIsModalOpen } from '../../../../actions/modalstateactions';
import { clearErrors } from '../../../../actions/erroractions';
import ModalControl from '../../../Common/Modal/Modal';
import EngagementEditUser from './engagementedituser';
import Toast from '../../../Common/Toast/Toast';
import { MotifButton, MotifTooltip } from '@ey-xd/motif-react';
import { getLocalizedAPIError } from '../../common/util';
import AddUser from '../../../Common/AddUser/AddUser';
import { getEngagementRoles } from '../../../../actions/admin/masterdataactions';
import { setEngagementUpdateSuccessMessage } from '../../../../actions/admin/successmessageactions';

function EngagementUsersBase(props) {
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [optionPerPage, setOptionPerPage] = useState(pagingOptions.options[0]);
	const [totalPages, setTotalPages] = useState(props?.usersDataItems?.pages);
	const [userStatusesOptions, setUserStatusesOptions] = useState([]);
	const [independenceStatuseOptions, setIndependenceStatuseOptions] = useState(
		[]
	);
	const [confidentialityTypes, setConfidentialityTypes] = useState([]);
	const engagementUsersItems = useSelector((store) => store.engagementUsers);
	const engagementRoles = useSelector((store) => store.engagementRoles);
	const [usersList, setUsersList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [showEditUserPermissions, setShowEditUserPermissions] = useState(false);
	const [itemToEditUserPermissions, setItemToEditUserPermissions] = useState(
		null
	);
	const [userName, setUserName] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [showInviteMember, setShowInviteMember] = useState(false);
	const [inviteMemberLoading, setInviteMemberLoading] = useState(false);
	const [inviteMemberErrorMessage, setInviteMemberErrorMessage] = useState([]);
	const [engagementRolesArray, setEngagementRolesArray] = useState([]);

	useEffect(() => {
		clearError(true);
		return () => {
			clearError(true);
		};
	}, []);

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	useEffect(() => {
		let getRolesUrl = env.getURL('serviceUrlV2') + 'Roles';
		dispatch(getEngagementRoles(getRolesUrl));
	}, []);

	useEffect(() => {
		if (engagementRoles?.length > 0) {
			setEngagementRolesArray(engagementRoles);
		}
	}, [engagementRoles?.length]);

	useEffect(() => {
		getEngagementUser();
	}, [page, optionPerPage]);

	useEffect(() => {
		if (engagementUsersItems) {
			setUsersList(engagementUsersItems.data);
			setTotalPages(engagementUsersItems.pages);
		}
	}, [engagementUsersItems]);

	useEffect(() => {
		var confidentialityTypeItems = [];
		_.forEach(currentResource.ConfidentialityTypes, (sslItem) => {
			confidentialityTypeItems.push({
				label: sslItem.Name,
				value: sslItem.id
			});
		});
		setConfidentialityTypes(confidentialityTypeItems);
	}, []);

	useEffect(() => {
		let userStatuses = props?.userStatusesDDOptions;
		let independenceStatuses = props?.independenceStatusesDDOptions;
		if (userStatusesOptions.length == 0) {
			var items = [];
			_.forEach(userStatuses, (item) => {
				items.push({
					label: item.statusName,
					value: item.statusId
				});
			});
			setUserStatusesOptions(items);
		}

		if (independenceStatuseOptions.length == 0) {
			var items = [];
			_.forEach(independenceStatuses, (item) => {
				items.push({
					label: item.statusName,
					value: item.statusId
				});
			});
			setIndependenceStatuseOptions(items);
		}
	}, [props]);

	const getEngagementUser = () => {
		setIsLoading(true);
		let engagementUserApiUri =
			env.getURL('serviceUrlV2') +
			'admin/subservicelines/' +
			props?.ssl +
			'/countries/' +
			props?.countryid +
			'/Engagements/' +
			props?.engagementId +
			`/Users?page=${page}&pageSize=${optionPerPage}`;

		dispatch(getEngagementUsers(engagementUserApiUri))
			.then(() => {
				setIsLoading(false);
			})
			.finally(() => {
				setIsLoading(false);
			});
		clearError(true);
	};

	const paginationHandler = (value) => {
		if (page !== value && page > 0) {
			setPage(value);
		}
	};

	const handlerOptionPage = (value) => {
		if (value !== optionPerPage) {
			setOptionPerPage(value);
			setPage(1);
		}
	};

	useEffect(() => {
		if (itemToEditUserPermissions) {
			setUserName(
				itemToEditUserPermissions?.firstName +
				' ' +
				itemToEditUserPermissions?.lastName
			);
		}
	}, [itemToEditUserPermissions]);

	const closeEditUserPermissionModal = (isReload) => {
		setShowEditUserPermissions(false);
		if (isReload == true) {
			getEngagementUser();
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setSuccessMessage('');
		}, successMessageDisplayDuration);
		return () => clearTimeout(timer);
	});

	useEffect(() => { }, [showInviteMember]);

	const openInviteMemberModal = () => {
		setShowInviteMember(!showInviteMember);
	};

	const closeInviteMemberModal = () => {
		setShowInviteMember(!showInviteMember);
		setInviteMemberErrorMessage([]);
	};

	const saveMembers = (selectedUsers, ticketNumber) => {
		if (selectedUsers?.length <= 0) {
			return;
		}

		setInviteMemberLoading(true);
		let engagementUserUrl =
			env.getURL('serviceUrlV2') +
			'admin/subservicelines/' +
			props?.ssl +
			'/countries/' +
			props?.countryid +
			'/Engagements/' +
			props?.engagementId +
			'/Users';

		let errorMessages = [];
		selectedUsers.forEach((user, index) => {
			dispatch(
				inviteEngagementMember(engagementUserUrl, {
					email: user.emailAddress,
					engagementRoleId: user.role.value,
					ticketNumber: ticketNumber
				})
			)
				.then(() => {
					if (selectedUsers.length == index + 1) {
						setInviteMemberLoading(false);
						dispatch(
							setEngagementUpdateSuccessMessage(
								labels.engagementInviteMemberSuccessMessage
							)
						);
						closeInviteMemberModal();
						getEngagementUser();
					}
				})
				.catch((result) => {
					setInviteMemberLoading(false);
					let errorText = getLocalizedAPIError(result);
					if (errorText === labels.insufficientPermission) {
						setInviteMemberErrorMessage([errorText]);
					} else {
						errorMessages.push(user.emailAddress + ' : ' + errorText);
						if (selectedUsers.length === index + 1) {
							setInviteMemberErrorMessage(errorMessages);
						}
					}
				});
		});
	};
	return (
		<>
			{isLoading == true ? (
				<Loader />
			) : (
				<Headerbody>
					<section
						className="mainResponsiveWrapper toastmessage"
						style={{
							display: successMessage.length > 0 ? 'block' : 'none'
						}}
					>
						<Toast
							variant="success"
							duration={successMessageDisplayDuration}
							onClose={() => setSuccessMessage('')}
						>
							{successMessage}
						</Toast>
					</section>
					{engagementUsersItems && engagementUsersItems?.totalEntityCount > 0 && (
						<section>
							<section className="e-icon-wrapper mainResponsiveWrapper">
								<section className="pageheading">
									<h3 className="e-page-title">
										{labels.engagementUsersTitle}
									</h3>
									<MotifTooltip
										placement={'bottom'}
										hide={props?.canInviteEngagementMember}
										trigger={
											<section>
												<MotifButton
													aria-label="Invite member"
													role="button"
													type="button"
													variant="primary-alt"
													disabled={!props?.canInviteEngagementMember}
													onClick={openInviteMemberModal}
												>
													{labels.engagementInviteUser}
												</MotifButton>
											</section>
										}
									>
										{labels.insufficientPermission}
									</MotifTooltip>
								</section>
							</section>
							<section className="engagementpageview mainResponsiveWrapper">
								<section className="userlistwrapper">
									<EngagementUserHeader />
									<EngagementUserList
										editPermission={props?.editPermission}
										userStatusesDDOptions={userStatusesOptions}
										independenceStatusesDDOptions={independenceStatuseOptions}
										confidentialityTypes={confidentialityTypes}
										dataItems={usersList}
										setItemToEditUserPermissions={setItemToEditUserPermissions}
										setShowEditUserPermissions={setShowEditUserPermissions}
										engagementStatusId={props?.engagementStatusId}
									/>
								</section>
							</section>
							<section className="pagerholder">
								<Pagination
									styleName="engagement-paging mainResponsiveWrapper"
									options_per_page={pagingOptions.options}
									default_dropdown_value={optionPerPage}
									showText={currentResource.common.pagination.show}
									currentPage={page}
									TotalPages={totalPages}
									dropup
									onInputChange={(e) => {
										let inputValue = Number.parseInt(e.target.value);
										paginationHandler(inputValue);
									}}
									onDropdownChange={(e) => {
										handlerOptionPage(e.value);
									}}
									onFirstPageClick={() => {
										paginationHandler(1);
									}}
									onPrevPageClick={() => {
										paginationHandler(page - 1);
									}}
									onNextPageClick={() => {
										paginationHandler(page + 1);
									}}
									onLastPageClick={() => {
										paginationHandler(totalPages);
									}}
									dropdown_position="left"
									iconHover={currentResource.common.pagination.iconHover}
								/>
							</section>
						</section>
					)}
					<ModalControl
						title={'Edit - ' + userName}
						onOkClick={() => { }}
						onHide={() => closeEditUserPermissionModal(false)}
						isOkButtonDisabled={false}
						show={showEditUserPermissions}
						modalsize="small"
						showButtons={false}
						closeTitle={labels.close}
						role="dialog"
					>
						<section>
							<EngagementEditUser
								editPermission={props?.editPermission}
								editUserPermissionsItem={itemToEditUserPermissions}
								userStatusesDDOptions={userStatusesOptions}
								independenceStatusesDDOptions={independenceStatuseOptions}
								confidentialityTypes={confidentialityTypes}
								ssl={props?.ssl}
								countryId={props?.countryid}
								engagementId={props?.engagementId}
								closeModal={closeEditUserPermissionModal}
								setSuccessMessage={setSuccessMessage}
								engagementStatusId={props?.engagementStatusId}
							></EngagementEditUser>
						</section>
					</ModalControl>
					<ModalControl
						className="adduser-modal"
						title={labels.engagementInviteMember}
						onOkClick={() => { }}
						onHide={closeInviteMemberModal}
						isOkButtonDisabled={false}
						show={showInviteMember}
						modalsize="small"
						showButtons={false}
						closeTitle={labels.close}
						role="dialog"
					>
						<AddUser
							onNewUserModalClose={closeInviteMemberModal}
							addUserErrorMessage={inviteMemberErrorMessage}
							userLookupType={UserLookupType.Internal}
							onSaveUsersClick={saveMembers}
							addUserLoading={inviteMemberLoading}
							addUserDescription={labels.engagementInviteMemberDescription}
							showRolesDropDown={true}
							engagementRoles={engagementRolesArray}
						/>
					</ModalControl>
				</Headerbody>
			)}
		</>
	);
}

export default EngagementUsersBase;
