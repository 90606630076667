import * as types from './actiontypes';

export function getData(url, resource) {
	if (url == 'reset') {
		return {
			type: types.GET_NULL
		};
	} else
		return {
			type: types.GET_GENERIC,
			meta: {
				api: {
					url,
					method: 'GET',
					data: {
						resource
					}
				}
			}
		};
}

export function postData(url, resource) {
	return {
		type: types.POST_GENERIC,
		meta: {
			api: {
				url,
				method: 'POST',
				data: resource
			}
		}
	};
}

export function patchGeneric(url, resource) {
	return {
		type: types.PATCH_GENERIC,
		meta: {
			api: {
				url,
				method: 'PATCH',
				data: resource
			}
		}
	};
}
