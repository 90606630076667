import * as types from '../actions/actiontypes';
import initialState from './initialstate';

export default function EYUsersReducer(state = initialState.eyusers, action) {
	switch (action.type) {
		case types.GET_EYUSERS:
			return action.response;
		default:
			return state;
	}
}
