import React, {useState} from 'react';
import {labels} from '../../util/uiconstants';
import ContextMenu from '../Common/contextMenu';
import CrpDeleteModal from './crpdeletemodel';

const CogWheelReview = (props) => {
	const {id} = props;
	const {isinterimreviewengagement} = props.data;

	const [forceRefreshCounter, setForceRefreshCounter] = useState(1);
	const [showDeleteModalState, setShowDeleteModalState] = useState(false);

	const updateRefreshCounter = () => {
		setForceRefreshCounter((prevState) => prevState + 1);
	}; //will force context menu to close

	const getCrpEditMembersItem = () => {
		let ret = {
			linkTo: {
				pathname: '/crp',
				search: '?eid=' + id + '&T=3' + '&isInt=' + isinterimreviewengagement
			},
			label: labels.crpEditMembers
		};

		return ret;
	};

	const getCrpDeleteItem = () => {
		let ret = {
			linkTo: null,
			onClick: showDeleteModal,
			label: labels.deletreviewcopyoption
		};

		return ret;
	};

	const showDeleteModal = () => {
		updateRefreshCounter();
		setShowDeleteModalState(true);
	};

	const hideDeleteModal = () => {
		updateRefreshCounter();
		setShowDeleteModalState(false);
	};

	const quitDeleteModal = () => {
		updateRefreshCounter();
		setShowDeleteModalState(false);
	};

	const getMenuItems = () => {
		let items = [];
		items.push(getCrpEditMembersItem());
		items.push(getCrpDeleteItem());
		return items;
	};

	const doRefreshDashboard = () => {
		updateRefreshCounter();
		props.doRefreshDashboard();
	};

	return (
		<>
			<ContextMenu
				key={forceRefreshCounter}
				iconHover={labels.options}
				iconName={'more-dots'}
				iconstyleName={'large'}
				menuItems={getMenuItems}
			/>
			<CrpDeleteModal
				show={showDeleteModalState}
				hide={hideDeleteModal}
				doRefreshDashboard={doRefreshDashboard}
				quit={quitDeleteModal}
				{...props}
			/>
		</>
	);
};

export default CogWheelReview;
