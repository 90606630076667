import * as types from '../actions/actiontypes';
import initialState from './initialstate';

export default function RollForwardEngagementReducer(
	state = initialState.rollforwardEngagement,
	action
) {
	switch (action.type) {
		case types.GET_ROLLFORWARDENGAGEMENTS:
			return {
				data: action.response
			};
		case types.SET_ROLLFORWARD_ENGAGEMENT_DATA:
			return {
				...state,
				engagementData: action.payload
			};
		default:
			return state;
	}
}
