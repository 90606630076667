import {displayGroupings, settingsPreferences} from '../../util/uiconstants';
import {useHistory, withRouter} from 'react-router-dom';

import Button from '@ey/button';
import React from 'react';
import {SVGICONS} from '@ey/icons';
import {labels} from '../../util/uiconstants';
import {parse} from 'query-string';
import styled from 'styled-components';
import {updateGroupPrefUserSettings} from '../../actions/usersettingsaction';
import {useDispatch} from 'react-redux';

const Toplevelcss = styled.div`
	float: right;
	margin-top: -0.6rem;
	.btn.workspaceview_toggle {
		width: 4rem;
		padding: 0;
		background: ${(props) => props.theme.color[`white`]};
		height: 3.2rem;
		& svg {
			height: 2rem;
			width: 2rem;
			vertical-align: text-top;
		}

		&:hover {
			fill: ${(props) => props.theme.color[`white`]};
			background: ${(props) => props.theme.color[`grey500`]};
			& > svg {
				fill: ${(props) => props.theme.color[`white`]};
			}
		}

		&.active {
			background: ${(props) => props.theme.color[`grey500`]};
			fill: ${(props) => props.theme.color[`white`]};
			& > svg {
				fill: ${(props) => props.theme.color[`white`]};
			}
		}
	}
`;

const EngagementGrouping = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {location} = props;

	const displayGrouping = () => {
		const params = parse(location.search);
		return params.groupby ? displayGroupings.MODULE : displayGroupings.LIST;
	};

	let dynClassList =
		'' + displayGrouping() === displayGroupings.LIST
			? 'workspaceview_toggle active'
			: 'workspaceview_toggle';
	let dynClassModule =
		'' + displayGrouping() === displayGroupings.MODULE
			? 'workspaceview_toggle active'
			: 'workspaceview_toggle';

	const updateGroupPreferenceUserSettings = (settingPreference) => {
		dispatch(updateGroupPrefUserSettings(settingPreference));
		history.push({
			pathname: history.location.pathname,
			search: settingPreference == 'engagements' ? '' : '?groupby=clients'
		});
		props.resetPager();
	};

	return (
		<Toplevelcss className="workspaces_view">
			<Button
				className={dynClassList}
				name="view_list"
				buttonType="primary"
				onClick={(e) => {
					updateGroupPreferenceUserSettings(settingsPreferences.EngagementList);
				}}
			>
				<span title={labels.engagementslist}>
					<SVGICONS
						className="view_list"
						hoverText={labels.engagementslist}
						name="view_list"
					/>
				</span>
			</Button>
			<Button
				className={dynClassModule}
				name="view_module"
				buttonType="secondary"
				onClick={(e) => {
					updateGroupPreferenceUserSettings(settingsPreferences.WorkspaceList);
				}}
			>
				<span title={labels.workspacelist}>
					<SVGICONS
						className="view_module"
						hoverText={labels.workspacelist}
						name="view_module"
					/>
				</span>
			</Button>
		</Toplevelcss>
	);
};

export default withRouter(EngagementGrouping);
