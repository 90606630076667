import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {FormRadio} from '@ey/form';
import {Radio} from 'react-bootstrap';
import {labels} from '../../../util/uiconstants';
import EllipsesControl from '@ey/ellipses-control';
import { usePrevious } from '../../../util/customHooks';

const SelectArchvieFileStep = (props) => {
	const {
		updateWizardData,
		isComponentValid,
		onComponentDisplayed,
		arcFileList
	} = props;

	let firstArchGuid = arcFileList[0].FileGUID;

	//state declarations
	const [selectedArcFileGuid, setSelectedArcFileGuid] = useState(firstArchGuid);

	//componentDidMount and componentDidUpdate logic
	const mounted = useRef();
	const onComponentDisplayedRef = usePrevious(onComponentDisplayed);
	useEffect(() => {
		if (!mounted.current) {
			updateWizardData('domainSelectedArcFileGuid', firstArchGuid);
			mounted.current = true;
		} else {
			// do componentDidUpdate logic
			if (onComponentDisplayedRef != onComponentDisplayed) {
				setComponentValid();
			}
		}
	});

	//on archive file selected..
	const radioButtonClicked = (value) => {
		setSelectedArcFileGuid(value);
		updateWizardFromComponent(value);
	};

	const updateWizardFromComponent = (selectedGuid) => {
		updateWizardData('domainSelectedArcFileGuid', selectedGuid);
		setComponentValid();
	};

	const setComponentValid = () => {
		// Validate data
		if (selectedArcFileGuid) {
			isComponentValid(true);
		} else {
			isComponentValid(false);
		}
	};
	let result = [];
	let isFirst = true;

	arcFileList.map((item, index) => {
		let firstName = item.submitteduserfirstname;
		let lastName = item.submitteduserlastname;

		result.push(
			<Radio
				value={item.FileGUID}
				key={item.SHA256Hash}
				defaultChecked={isFirst}
				onClick={(e) => {
					radioButtonClicked(e.target.value);
				}}
			>
				<section className="archiveusername">
					<EllipsesControl
						content={
							firstName && firstName.length > 0 ? (
								firstName + ' ' + lastName
							) : (
								<span className="userunavailable">
									{labels.arcfileuserunavailable}
								</span>
							)
						}
						tooltip={
							firstName && firstName.length > 0 ? (
								firstName + ' ' + lastName
							) : (
								<span className="userunavailable">
									{labels.arcfileuserunavailable}
								</span>
							)
						}
						isTooltipAvailable
					/>
				</section>
				<span>{item.UploadedDatetime.replace(' ', ' ' + labels.at + ' ')}</span>
			</Radio>
		);
		isFirst = false;
	});

	return (
			<Selectarchivefile>
					<form>
						<section>
							<h5>{labels.selectarchiveheader} </h5>
						</section>

						<section className="archiveuserlist">
							<ul>
								<li className="archiveuserheader">
									<label className="submittedby">
										{labels.archivesubmittedby}
									</label>
									<label className="dateandtime">
										{labels.archivedateandtime}
									</label>
								</li>
								<li className="archiveuseritems">
									<FormRadio name="submittedByDetails" onChange={() => {}}>
										{result}
									</FormRadio>
								</li>
							</ul>
						</section>
					</form>
				</Selectarchivefile>
	);
};

export default SelectArchvieFileStep;

const Selectarchivefile = styled.section`
	& .archiveuserlist {
		& .form-group .control-label {
			display: none;
		}

		& .form-group .radio {
			margin: 0.5em 0;
			line-height: inherit;
			padding-bottom: 0.5em;
		}

		.archiveuserheader {
			& label.submittedby {
				width: 42%;
				max-width: 42%;
				margin: 0 2% 0 5%;
				display: inline-block;
			}
			& label.dateandtime {
				width: 33%;
				max-width: 35%;
				margin: 0 2%;
				display: inline-block;
			}
		}

		& li {
			list-style: none;

			& .radio {
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey250`]};

				&:last-child {
					border-bottom: 0;
				}

				& label > * {
					max-width: 42%;
					width: 42%;
					display: inline-block;
					margin: 0 2%;

					&.archiveusername .userunavailable {
						font-style: italic;
						color: ${(props) => props.theme.color[`grey400`]};
						width: 100%;
						display: inline-block;

						& div {
							width: 100%;
						}
					}

					&.archiveusername .ellipses {
						display: inline-flex;

						& div {
							width: 100%;
						}
					}
				}

				& label {
					padding: 0;
					width: 100%;
					font-weight: normal;
				}

				& input[type='radio'] {
					margin: 0;
					position: relative;
					vertical-align: text-top;
					width: 3%;
				}
			}

			&.archiveuseritems,
			&.archiveuserheader {
				width: 90rem;
				font-size: 1.4rem;
			}

			&.archiveuserheader {
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
				margin-bottom: 0;
			}
		}
	}
`;