import React from 'react';
import { Ellipse } from '@ey/canvascoreservices';
import Userlistwrapper from '../Styles/AdminUserStyle';
import {labels} from '../../../../util/uiconstants';
import {useHistory} from 'react-router-dom';
import {SVGICONS} from '@ey/icons';

function AdminUserItem(props) {
	const history = useHistory();

	const onManageRoleClick = () => {
		history.replace({
			pathname: history.location.pathname + '/userroles/' + props.data.userId,
			state: {customLabel: props.data.displayName}
		});
	};

	const onClickDeactivate = () => {
		props.onDeactivate(props.data);
	};

	return (
		<Userlistwrapper
			className={`manageaccessitemview ${
				props.data.isActive ? '' : 'inactive'
			}`}
		>
			<section className="userlistitem userdetail">
				<section className="initials">
					<a onClick={onManageRoleClick}>
						<Ellipse content={props.data.displayName} noOfLines={1} />
					</a>
				</section>
			</section>

			<section className="userlistitem useremail">
				<Ellipse content={props.data.email} noOfLines={1} />
			</section>

			<section className="userlistitem contextmenu">
				<section className="context">
					<SVGICONS
						role="button"
						styleName="medium delete"
						name="delete"
						hoverText={labels.deactivateButton}
						onIconClick={onClickDeactivate}
					/>
				</section>
			</section>
		</Userlistwrapper>
	);
}

export default AdminUserItem;
