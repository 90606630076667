/* eslint-disable prettier/prettier */
/**
 * Created by rastwe on 1/13/2017.
 * @module $Name$
 * @extends React.Component
 */
/* eslint react/no-multi-comp: 0*/

import './globals.less';
import './app.less';

import * as FeatureConfigurationAction from '../actions/featureconfigurationaction';
import * as UserSettingsAction from '../actions/usersettingsaction';

import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { setDateFormat, setLanguage, urls } from '../util/uiconstants';

import AccessDeniedMessage from './Common/AccessDeniedMessage/AccessDeniedMessage';
import AdminNav from './Nav/AdminNav';
import Async from '@ey/async';
import BreadCrumbNavigation from './Nav/BreadCrumbNavigation';
import CreateReviewCopy from './Engagements/createreviewcopy';
import Dashboard from './Engagements';
import EditEngagementWizardLoader from './WorkflowWizards/EngagementWorkflowsNew/wizardloadereditengagement';
import EngagementWizardLoader from './WorkflowWizards/EngagementWorkflowsNew/wizardloadercreateengagement';
import Errors from '../components/Common/Errors/Errors';
import GroupAuditLinkWizardLoader from './WorkflowWizards/GroupAuditLinkWorkflow/wizardLoaderGroupAuditLink';
import ImportDataPackageWizardLoader from './WorkflowWizards/ImportDataPackageWorkflow/wizardloaderimportdatapackage';
import ImportWizard from './WorkflowWizards/ImportWorkflow/wizardloaderimport';
import TimeTracking from './TimeTracking/TimeTracking';
import Loader from '@ey/loader';
import LoaderContainer from './LoaderContainer';
import Main from './Main/main';
import PageHeader from './Nav/pageheader';
import { Qualtrics } from '@ey/canvascoreservices';
import React from 'react';
import ReportingHub from './ReportingHub/ReportingHub';
import RestoreEngagementWizard from './WorkflowWizards/RestoreWorkflow/WizardLoaderRestoreEngagement';
import RollForwardEngagementWizard from './WorkflowWizards/RollForwardWorkflow/WizardLoaderRollForwardEngagement';
import SampleTest from './devonly/sampletest';
import TopNav from './Nav/Nav';
import UserSettings from './UserSettings/userSettings';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import env from '../util/env';
import { getAdminRoutes } from './Admin/common/util';
import { hot } from 'react-hot-loader';
import { labels } from '../util/uiconstants';
import styled from 'styled-components';
import IndependenceOnlyRejectInvite from './Engagements/IndependenceOnlyRejectInvite';
import {
	CookieConsentModal,
	CookieSettings,
	adobeAnalytics
} from '@ey/voltron-analytics';
import envConfig from '@ey/voltron-config';
import {processPathname, assignPageName, processHashPath} from './Common/AdobeAnalytics/helper';

const COOKIE_SETTINGS_PATH = '/cookiesettings';
const adobeAnalyticsEnabled = envConfig.adobeAnalytics?.enabled;

const Wrapper = styled.section`
	.header {
		display: flex;
		.wrapper {
			position: fixed;
			width: 100%;
			z-index: 999;
			background: ${(props) => props.theme.color[`white`]};
			box-shadow: 0.1rem 0.1rem 1rem
				${(props) => props.theme.color[`grey300black`]};
			.mainwrapper {
				display: flex;
			}
		}
	}
`;

const RootContainer = styled.section`
	&.portfolio-view {
		.view-wrapper {
			overflow: hidden;
			.portfolio-loader {
				position: relative;
				.motif-loader-fullscreen {
					position: absolute;
					height: calc(100vh - 13.5rem);
				}
			}
		}
	}
	//.root-container {
	//	background: url('../img/hero_image_Cockpit.png') no-repeat;
	//	background-size: 100% 40.6rem;
	//	.rootcontainer-wrapper {
	//		background: linear-gradient(rgba(255, 255, 255, 0) 0.19%,#fff 40.6rem,#fff 40.6rem,#fff 100%);
	//	}
	//}
	//.root-container[theme='dark'] {
	//	background: url('../img/hero_portfolio.png') no-repeat;
	//	background-size: 100% 40.6rem;
	//	.rootcontainer-wrapper {
	//		background: linear-gradient(rgba(35, 35, 47, 0) 0.19%,#232334 40.6rem,#23232f 40.6rem,#23232f 100%);
	//	}
	//}
`;
//import '../util/register-service-worker';

/* the import() call must be located in this outer function. Placing it
 * inside load() will cause Webpack to raise a warning about a required
 * file path being an expression.
 * The value of the component={} attribute must be a function or a
 * React component. React-Router sends the props in as the first parameter,
 * so grab those and forward them to the Async.load() as the first
 * argument. The second argument must be an import() statement. */

// const userSettings = (props) =>
// 	Async.load(props, import('./UserSettings/UserSettings'));

const archives = (props) => Async.load(props, import('./Archives'));

const routes = [
	{
		path: '/test',
		component: SampleTest
	},
	{
		path: '/createengagement',
		component: EngagementWizardLoader
	},
	{
		path: '/import',
		component: ImportWizard
	},
	{
		path: '/editengagement',
		component: EditEngagementWizardLoader
	},
	{
		path: '/restore',
		component: RestoreEngagementWizard
	},
	{
		path: '/rollforward',
		component: RollForwardEngagementWizard
	},
	{
		path: '/editworkspace',
		component: EditEngagementWizardLoader
	},
	{
		path: '/datapackagerequest',
		component: ImportDataPackageWizardLoader
	},
	{
		path: '/groupauditlinkrequest',
		component: GroupAuditLinkWizardLoader
	},
	{
		path: '/crp',
		component: CreateReviewCopy
	},
	{
		path: '/engagements',
		component: Dashboard
	},
	{
		path: '/rejectIndependenceOnlyEngagementInvite/:engagementId?',
		component: IndependenceOnlyRejectInvite
	},
	{
		path: '/favorites',
		component: Dashboard
	},
	// {
	// 	path: '/engagementsv2',
	// 	component: Dashboard
	// },
	// {
	// 	path: '/engagementsv2/allengagements',
	// 	component: Dashboard
	// },
	// {
	// 	path: '/engagementsv2/favorites',
	// 	component: Dashboard
	// },
	// {
	// 	path: '/engagementsv2/reviewengagements',
	// 	component: Dashboard
	// },
	{
		path: '/reviewengagements',
		component: Dashboard
	},
	{
		path: '/archives',
		component: archives
	},
	{
		path: '/usersettings',
		component: UserSettings
	},
	{
		path: '/',
		component: Dashboard
	},
	{
		path: '/reportinghub',
		component: ReportingHub
	},
	{
		path: '/timesheet',
		component: TimeTracking
	},
	{
		path: '/cookiesettings',
		component: () => <CookieSettings locale={labels.cookieSettings} />
	},
];

const adminRoutes = getAdminRoutes();
class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isFetchingUserDetails: true,
			displayCookieConsent: false,
			adobeAnalyticsEmbedded: false
		};

		this.userSettingsUrl = env.getURL('serviceUrl') + '/' + urls.USERS_URL;
		this.featureConfigurationUrl =
			env.getURL('serviceUrl') + '/' + urls.FEATURECONFIGURATION_URL;
		this.userPermissionUrl = env.getURL('serviceUrlV2') + 'admin/Users/Me';
	}

	async componentDidMount() {
		try {
			let path = this.props.location?.pathname;
			if (this.verifyAdminToolUrl(path)) {
				console.log('@@this is admin tool url');
				await this.props.actions.getUserPermissions(this.userPermissionUrl);
				return;
			}
			console.log('@@this is NOT a admin tool url');
			await this.props.actions
				.getUserSettings(this.userSettingsUrl)
				.then(
					await this.props.actions.getFeatureConfiguration(
						this.featureConfigurationUrl
					)
				);
			let data = this.props.userSettings.data;

			if (
				data &&
				data.userlanguageid > 0 &&
				data.userdatetimeformat > -1 &&
				data.usernumberformat > -1
			) {
				setLanguage(data.userlanguageid);
				setDateFormat(data.userdatetimeformat);
			}
		} finally {
			this.setState({ isFetchingUserDetails: false });
		}
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.userSettings !== this.props.userSettings) {
			if (!this.state.adobeAnalyticsEmbedded) {
				this.runAdobeAnalytics();
				this.setState({ adobeAnalyticsEmbedded: true });
			}
			this.updateDisplayCookieContent();
		}

		if (prevProps.location !== this.props.location) {
			this.runAdobeAnalytics();
			this.setState({ displayCookieConsent: adobeAnalyticsEnabled && adobeAnalytics.shouldPromptConsent(), adobeAnalyticsEmbedded: true });
		}
	}

	runAdobeAnalytics = () => {
		if (this.props.location.pathname === '/') return;
		const processedPathname = processPathname(this.props.location.pathname);
		const hashPath = processHashPath(this.props.location.hash, processedPathname.child);
		const overrides = {
			page: {
				hierarchy1: processedPathname.parent,
				hierarchy2: hashPath,
				pageName: assignPageName(processedPathname.parent, processedPathname.child, hashPath)
			}
		};

		if (
			adobeAnalyticsEnabled &&
			this.props.userSettings.data.useremail
		) {
			if (this.props.userSettings.data.iseyuser) {
				adobeAnalytics.embedWithoutConsent(
					envConfig.adobeAnalytics.propertyId,
					adobeAnalytics.dataOverrides(envConfig.adobeAnalytics.digitalData, overrides),
					this.props.userSettings.data.useremail.toLowerCase()
				);
			} else {
				adobeAnalytics.embed(
					envConfig.adobeAnalytics.propertyId,
					adobeAnalytics.dataOverrides(envConfig.adobeAnalytics.digitalData, overrides),
					this.props.userSettings.data.useremail.toLowerCase()
				);
			}
		}
	}

	updateDisplayCookieContent = () => {
		this.setState({
			displayCookieConsent: adobeAnalyticsEnabled &&
				adobeAnalytics.shouldPromptConsent() &&
				`${location.pathname}${location.search}` !== COOKIE_SETTINGS_PATH &&
				this.props.userSettings.data.userlanguageid > 0
		});
	}

	shouldRedirect() {
		if (this.props.apponlinestate === false) {
			return false;
		}

		if (this.props.userSettings?.data?.iseyuser) {
			if (
				!(
					this.props.userSettings.data &&
					this.props.userSettings.data.id &&
					this.props.userSettings.data.id !==
					'00000000-0000-0000-0000-000000000000' &&
					this.props.userSettings.data.userlanguageid > 0 &&
					this.props.userSettings.data.userdatetimeformat > -1 &&
					this.props.userSettings.data.usernumberformat > -1
				)
			) {
				return true; //EY USER FIRST TIME
			} else {
				return false; //EY USER NOT FIRST TIME
			}
		} else {
			if (
				this.props.userSettings?.data?.iseyuser !== undefined &&
				(this.props.userSettings.data.userlanguageid === 0 ||
					this.props.userSettings.data.userdatetimeformat === -1 ||
					this.props.userSettings.data.usernumberformat === -1)
			) {
				return true; //GUEST USER FIRST TIME
			} else {
				return false; //GUEST USER NOT FIRST TIME OR NO RECORD
			}
		}
	}

	isGuestUser() {
		if (
			this.props.userSettings?.data?.iseyuser !== undefined &&
			this.props.userSettings?.data?.iseyuser !== null
		) {
			return !this.props.userSettings?.data?.iseyuser;
		}
		if (
			this.props.userSettings?.request?.response &&
			JSON.parse(this.props.userSettings?.request?.response).ErrorMessage ===
			'938'
		) {
			return true;
		}
		return false;
	}

	shouldRedirectGuestUser() {
		if (location.pathname === '/cookiesettings') {
			return false;
		}
		else if (
			this.props.apponlinestate === false ||
			(this.props.userSettings?.isError &&
				JSON.parse(this.props.userSettings?.request?.response).ErrorMessage !==
				'938')
		) {
			return false;
		} else {
			return this.isGuestUser();
		}
	}

	getCookieModal() {
		const onCoookieConsentResult = (type) => {
			this.setState({ displayCookieConsent: false });
			adobeAnalytics.setup(type);
		};

		return (
			<React.Fragment>
				{this.state.displayCookieConsent && (
					<CookieConsentModal
						settingsURL={COOKIE_SETTINGS_PATH}
						onResult={onCoookieConsentResult}
						appName={labels.versionlabel}
						labels={{
							title: labels.aaCookieConsentTitle,
							prompt: labels.aaCookieContentPrompt,
							explanationHTML: labels.aaCookieConsentExplanation,
							explanationHTMLWithDoNotTrack:
								labels.aaCookieConsentExplanationWithDoNotTrack,
							declineOptionalAction:
								labels.aaCookieConsentDeclineOptionalAction,
							acceptAllAction: labels.aaCookieConsentAcceptAllAction,
							customizeAction: labels.aaCookieConsentCustomizeAction,
							customizeURL: labels.aaCookieConsentCustomizeURL
						}}
					/>
				)}
			</React.Fragment>
		)
	}

	getUniversalAppContainer() {
		const isEngV2 = false;
		return (
			<React.Fragment>
				<section className="root">
					<RootContainer className={isEngV2 ? 'portfolio-view' : ''}>
						<section
							className="root-container"
							theme={
								document
									.getElementsByTagName('body')[0]
									.getAttribute('motif-theme') === 'light'
									? null
									: 'dark'
							}
						>
							<TopNav />
							<section className="rootcontainer-wrapper">
								<Errors />
								{this.props.isLoading ? <Loader view="fullscreen" /> : null}
								{this.state.isFetchingUserDetails ? (
									<LoaderContainer path={this.props.location} />
								) : (
									<Main>
										<Switch>
											<Route path="/usersettings" component={UserSettings} />
											{this.shouldRedirect() ? (
												<Route>
													<Redirect to="/usersettings" />
												</Route>
											) : null}

											<Route path="/reviewengagements" component={Dashboard} />
											{this.shouldRedirectGuestUser() ? (
												<Route>
													<Redirect to="/reviewengagements" />
												</Route>
											) : null}

											{routes.map((eachRoute) => {
												const { path, component } = eachRoute;
												return (
													<Route exact path={path} component={component} />
												);
											})}
										</Switch>
									</Main>
								)}
							</section>
							{this.props.featureConfiguration &&
								this.props.featureConfiguration.isqualtricsenabled ? (
								<Qualtrics
									projectId={this.props.featureConfiguration.qualtricsprojectid}
								></Qualtrics>
							) : null}
							{this.state.displayCookieConsent && this.getCookieModal()}
						</section>
					</RootContainer>
				</section>
			</React.Fragment>
		);
	}

	verifyAdminToolUrl(path) {
		path = path !== null ? path.split('/') : null;
		if (path !== null && path.length > 1 && path[1].toLowerCase() === 'admin') {
			return true;
		}
		return false;
	}

	isValidAdminUser() {
		let permissions = this.props?.userPermissions?.permissions;
		return permissions?.hasAdminDefaultRole;
	}

	getAdminAppContainer() {
		return (
			<React.Fragment>
				<section className="root">
					<Errors />
					<AdminNav />
					{this.props.isLoading ? (
						<Loader view="fullscreen" styleName="loadingstyle" />
					) : null}
					{this.state.isFetchingUserDetails ? (
						<LoaderContainer path={this.props.location} />
					) : (
						<Main>
							<section className="MainContainerWrapper customScrollbar">
								{true ? (
									this.isValidAdminUser() ? (
										<Switch>
											{adminRoutes.map((eachRoute) => {
												const {
													path,
													component,
													breadCrumb,
													moduleName,
													iconName
												} = eachRoute;
												return (
													<Route path={path}>
														<Wrapper>
															<section className="header">
																<wrapper className="wrapper">
																	<section className="mainwrapper mainResponsiveWrapper">
																		<BreadCrumbNavigation
																			breadCrumbList={breadCrumb}
																		/>
																		{moduleName && iconName ? (
																			<PageHeader
																				moduleName={moduleName}
																				iconName={iconName}
																			/>
																		) : null}
																	</section>
																</wrapper>
															</section>
															{component}
														</Wrapper>
													</Route>
												);
											})}
										</Switch>
									) : (
										<AccessDeniedMessage />
									)
								) : (
									<h1>{labels.serverErrorMessage}</h1>
								)}
							</section>
						</Main>
					)}
				</section>
			</React.Fragment>
		);
	}

	render() {
		let path = this.props.location?.pathname;
		return (
			<>
				{this.verifyAdminToolUrl(path)
					? this.getAdminAppContainer()
					: this.getUniversalAppContainer()}
			</>
		);
	}
}

const mapStateToProps = (storeState) => {
	return {
		userSettings: storeState.userSettings,
		apponlinestate: storeState.apponlinestate,
		isLoading: storeState.isLoading,
		userPermissions: storeState.userPermissions,
		featureConfiguration: storeState.featureConfiguration
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			Object.assign({}, UserSettingsAction, FeatureConfigurationAction),
			dispatch
		)
	};
};

export default hot(module)(
	connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
);
