import React from 'react';
import {labels} from '../../../../util/uiconstants';

function EngagementUserHeader() {
	return (
		<section className="userlist-header">
			<section className="userlistheader">
				<section className="userlistitem">
					{labels.engmtUserTableUserNameHeader}
				</section>
				<section className="userlistitem">
					{labels.engmtUserTableEmailAddressHeader}
				</section>
				<section className="userlistitem">
					{labels.engmtUserTableRoleHeader}
				</section>
				<section className="userlistitem gui">
					{labels.engmtUserTableGUIeHeader}
				</section>
				<section className="userlistitem status">
					{labels.engmtUserTableStatuseHeader}
				</section>
				<section className="userlistitem indstatus">
					{labels.engmtUserTableIndependenceStatuseHeader}
				</section>
				<section className="userlistitem adstatus">
					{labels.engmtUserTableADStatusHeader}
				</section>
				<section className="userlistitem permission">
					{labels.engmtUserTablePermissionsHeader}
				</section>
				<section className="editicon">&nbsp;</section>
			</section>
		</section>
	);
}

export default EngagementUserHeader;
