import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import Loader from '@ey/loader';
import AddNewEngagementCodeList from '../../EngagementWorkflowsNew/addnewengagementcodelist';
import Button from '@ey/button';
import SubServiceLineList from '../../EngagementWorkflowsNew/subserviceline';
import {getPrimaryoffices} from '../../../../actions/primaryofficesaction';
import {getServiceLines} from '../../../../actions/servicelinesaction';
import {saveNewEngagement} from '../../../../actions/workspacesactions';
import {getLanguages} from '../../../../actions/languageactions';
import env from '../../../../util/env';
import {EYForm, FormTextInput, FormComboBox, FormStaticText} from '@ey/form';
import Calendar from '../../../Common/MotifDatePicker/datepicker';
import Createeditstyle from '../../EngagementWorkflowsNew/createeditstyle';
import {getNotifications} from '../../../../actions/notificationsactions';

import {
	labels,
	urls,
	standardStrings,
	dateFormatsListItems,
	dateFormats,
	apiCallDateFormat
} from '../../../../util/uiconstants';
import EngagementModel from './engagementmodel';
import moment from 'moment';
import EllipsesControl from '@ey/ellipses-control';
import {useState} from 'react';
import {useEffect} from 'react';
import {useDidMountEffect} from '../../../../util/customHooks';
import uuidv4 from 'uuid/v4';

const CreateEngagement = (props) => {
	const {
		userprimaryofficeid,
		mode,
		onFinishClick,
		getDependencyData,
		isComponentValid
	} = props;

	const dispatch = useDispatch();

	const primaryoffices = useSelector((storeState) => storeState.primaryoffices);
	const servicelines = useSelector((storeState) => storeState.servicelines);
	const languages = useSelector((storeState) => storeState.languages);

	const [engagementName, setEngagementName] = useState(standardStrings.EMPTY);
	const [userPrimaryOfficeId, setUserPrimaryOfficeId] = useState(
		userprimaryofficeid || null
	);
	const userLanguage = getDependencyData('domainOriginLanguageId') || null;
	const [serviceLine, setServiceLine] = useState(undefined);
	const [subServiceLine, setSubServiceLine] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [engagementCodeArray, setEngagementCodeArray] = useState([
		{
			code: '',
			name: labels.engagementcodenameplaceholder,
			clientengagementid: '',
			isprimary: true,
			cross: '',
			invalidengagementcodeerror: 0,
			hidedeletebutton: '',
			canremove: false,
			clientcodevalid: true,
			engagementcode: '',
			clientcodeid: '',
			clientid: '',
			engcodename: '',
			engagementCodeId: uuidv4()
		}
	]);
	const [clientEndDate, setClientEndDate] = useState(standardStrings.EMPTY);
	const [engagementEndDate, setEngagementEndDate] = useState(
		standardStrings.EMPTY
	);
	const [auditorsReportDate, setAuditorsReportDate] = useState(
		standardStrings.EMPTY
	);
	const [validationTriggerCounter, setValidationTriggerCounter] = useState(0);
	const [showSubServiceValidation, setShowSubServiceValidation] = useState(0);
	const [fullScreen, setFullScreen] = useState(false);
	const [subservicelineOption, setSubservicelineOption] = useState([]);
	const [isSubServiceLineDisabled, setIsSubServiceLineDisabled] =
		useState(true);

	const getNotificationsUrl =
		env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;

	useEffect(() => {
		configureFormFields();
	}, []);

	useDidMountEffect(() => {
		handleOnFinishClick();
	}, [onFinishClick]);

	useDidMountEffect(() => {
		serviceLine > 0 ? setSubservicelineOption(loadSubServiceLine()) : null;
	}, [serviceLine]);

	useDidMountEffect(() => {
		serviceLine > 0 ? setSubservicelineOption(loadSubServiceLine()) : null;
	}, [servicelines]);

	const configureFormFields = async () => {
		try {
			setIsLoading(true);
			await loadPrimaryOffices();
			await loadLanguages();
			await loadServiceLines();
		} finally {
			setIsLoading(false);
		}
	};

	const loadPrimaryOffices = async () => {
		let fetchPrimaryOfficesUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.COUNTRY_OFFICE_URL +
			'?' +
			'filters=languageid eq 1,countryid eq ' +
			Number.parseInt(getDependencyData('domainLocation')) +
			',defaultlanguage eq english';

		await dispatch(
			getPrimaryoffices(
				fetchPrimaryOfficesUrl,
				fetchPrimaryOfficesUrl.split('/api/v1')[0]
			)
		);
	};

	const loadLanguages = async () => {
		let fetchLanguagesUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.LANGUAGES_URL +
			'?' +
			'filters=isactive eq true';

		await dispatch(
			getLanguages(fetchLanguagesUrl, fetchLanguagesUrl.split('/api/v1')[0])
		);
	};

	const loadServiceLines = async () => {
		let operationtype = 'createengagements';

		let fetchServiceLinesUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.SERVICE_LINES_URL +
			'?phase=' +
			operationtype +
			'&countryid=' +
			getDependencyData('domainLocation');

		await dispatch(
			getServiceLines(
				fetchServiceLinesUrl,
				fetchServiceLinesUrl.split('/api/v1')[0]
			)
		);
	};

	const loadSubServiceLine = () => {
		let servicelinelist = {};
		let subservicelinelist = [];
		let selectedserviceline = serviceLine;
		servicelinelist =
			servicelines &&
			servicelines.collections &&
			servicelines.collections.servicelines;
		servicelinelist &&
			servicelinelist.map(function (serviceline) {
				if (serviceline.id == selectedserviceline) {
					subservicelinelist = serviceline.collections.subservicelinesdata;
				}
			});
		return subservicelinelist;
	};

	const isComponentValidToFinish = () => {
		if (
			engagementName.length > 0 &&
			userPrimaryOfficeId != standardStrings.EMPTY &&
			userPrimaryOfficeId > 0 &&
			userLanguage != standardStrings.EMPTY &&
			userLanguage > 0 &&
			serviceLine != standardStrings.EMPTY &&
			serviceLine > 0 &&
			subServiceLine != standardStrings.EMPTY &&
			subServiceLine > 0 &&
			clientEndDate != standardStrings.EMPTY &&
			engagementEndDate != standardStrings.EMPTY &&
			auditorsReportDate != standardStrings.EMPTY
		) {
			return true;
		} else {
			return false;
		}
	};

	const handleOnFinishClick = () => {
		setValidationTriggerCounter((prev) => prev + 1);
		setShowSubServiceValidation((prev) => prev + 1);
		if (isComponentValidToFinish()) {
			let engModel = prepareModelForPOST();
			createAPICall(engModel);
		}
	};

	const createAPICall = async (engagementModelData) => {
		setIsLoading(true);
		setFullScreen(true);
		let saveEngagementUrl =
			env.getURL('serviceUrl') + '/' + urls.WORKSPACES_URL;

		try {
			await dispatch(
				saveNewEngagement(
					saveEngagementUrl,
					JSON.stringify(engagementModelData)
				)
			);
			setIsLoading(false);
			await dispatch(getNotifications(getNotificationsUrl)).finally(() => {
				window.location.href = '/';
			});
		} catch (e) {
			// either case loading should be closed
			setIsLoading(false);
			isComponentValid(true);
		}
	};

	const getOperationType = (isExistingWorkspace) => {
		let opt = '';
		if (mode == 'rollForward') opt = 'RollForwardEngagement';
		if (mode == 'import') {
			opt =
				isExistingWorkspace === true
					? 'ImportTemplateToExistingWorkspace'
					: 'ImportTemplate';
		}
		return opt;
	};

	const getFormattedDate = (dt) => {
		return moment(dt).format(apiCallDateFormat);
	};

	const prepareModelForPOST = () => {
		let getWizData = getDependencyData;
		let mdl = new EngagementModel();
		let wkModel = mdl.getWorkspaceModel();
		let isExistingWk = getWizData('domainChooseExisting');
		let opt = getOperationType(isExistingWk);
		let isClientValid = !Number.isNaN(
			Number.parseInt(getWizData('domainClientId'))
		);

		wkModel.id = Number.parseInt(getWizData('domainWorkspaceId')) || 0;
		wkModel.data.operationtype = opt;
		wkModel.data.clientcode = getWizData('domainClientCode') || '';
		wkModel.data.clientname = getWizData('domainClientName');
		wkModel.data.workspacedesc = getWizData('domainWorkspaceName');
		wkModel.data.isclientvalidated = isClientValid;
		wkModel.data.clientid = getWizData('domainClientId');
		wkModel.data.rfworkspaceid = isExistingWk
			? getWizData('domainWorkspaceId')
			: 0;
		wkModel.data.selectedarcfileguid = getWizData('domainSelectedArcFileGuid');
		wkModel.data.countryid = getWizData('domainLocation');
		wkModel.collections = {engagements: []};
		wkModel.collections.engagements.push(mdl.WorkspaceEngagements());

		let engDt = wkModel.collections.engagements[0].data;
		engDt.languageid = getWizData('domainOriginLanguageId');
		engDt.archivalengagementid = getWizData('domainOriginEngagementId');
		engDt.description = engagementName;
		engDt.primaryoffice = userPrimaryOfficeId;
		engDt.yearenddate = getFormattedDate(clientEndDate);
		engDt.enddateperiod = getFormattedDate(engagementEndDate);
		engDt.reportreleasedate = getFormattedDate(auditorsReportDate);
		engDt.servicelineid = serviceLine;
		engDt.subservicelineid = subServiceLine;

		let engCodeArr =
			wkModel.collections.engagements[0].collections.engagementcodes;
		engagementCodeArray.map((engCode, index) => {
			let newCodeItem = {
				data: {
					code: engCode.code,
					name: engCode.name,
					isprimary: engCode.isprimary,
					clientengagementid: engCode.clientengagementid,
					clientcodevalid: engCode.engcodeindex,
					invalidengagementcodeerror: engCode.invalidengagementcodeerror
				}
			};
			engCodeArr.push(newCodeItem);
		});

		if (mode == 'import') {
			engDt.importtemplatedetails = {
				collections: {
					iepnotifications: [
						{
							id: getWizData('domainTemplateId'),
							data: {
								operationtype: 'acceptIEPRequest',
								copyobjectgrouptypeid: 13,
								targetengagementdescription: engagementName
							}
						}
					]
				}
			};
		}
		return wkModel;
	};

	const updateStateForOnBlurEngagementCode = (
		engagementcodeobject,
		engCodeId
	) => {
		setEngagementCodeArray((prev) => {
			return prev.map((engCode, i) => {
				if (engCode.engagementCodeId === engCodeId) {
					return engagementcodeobject;
				} else {
					return prev[i];
				}
			});
		});
	};

	const deleteEngagementCodeClick = (e, engCodeId, isPrimary) => {
		setEngagementCodeArray((prev) => {
			const filtered = prev.filter(
				(item) => item.engagementCodeId !== engCodeId
			);
			if (isPrimary) {
				filtered[0].isprimary = true;
			}
			return filtered;
		});
	};

	const updateState = (key, value) => {
		if (key == 'serviceline' && value > 0) {
			setIsSubServiceLineDisabled(false);
		}

		switch (key) {
			case 'engagementname':
				setEngagementName(value);
				break;
			case 'userprimaryofficeid':
				setUserPrimaryOfficeId(value);
				break;
			case 'serviceline':
				setServiceLine(value);
				break;
			case 'subserviceline':
				setSubServiceLine(value);
				break;
			default:
				break;
		}
	};

	const emptyHandle = () => {
		return 0;
	};

	const updatePrimaryInEngagementCodeState = (index) => {
		setEngagementCodeArray((prev) => {
			return prev.map((engcodeobject) => {
				if (engcodeobject.engagementCodeId == index) {
					engcodeobject.isprimary = true;
					return engcodeobject;
				} else {
					engcodeobject.isprimary = false;
					return engcodeobject;
				}
			});
		});
	};

	const onAddEngagementCodeClick = () => {
		var engagementcodelist = {
			code: '',
			name: labels.engagementcodenameplaceholder,
			clientengagementid: '',
			isprimary: false,
			cross: '',
			invalidengagementcodeerror: 0,
			hidedeletebutton: '',
			canremove: false,
			clientcodevalid: true,
			engagementcode: '',
			clientcodeid: '',
			clientid: 0,
			engcodename: '',
			engagementCodeId: uuidv4()
		};
		setEngagementCodeArray((prev) => [...prev, engagementcodelist]);
	};

	const renderEngagementCodes = () => {
		return (
			<AddNewEngagementCodeList
				engagementcodearray={engagementCodeArray}
				clientid={getDependencyData('domainClientId')}
				onChange={updateState}
				updateStateOnBlur={updateStateForOnBlurEngagementCode}
				deleteEngagementCodeClick={deleteEngagementCodeClick}
				isDeletedDisabled={engagementCodeArray.length > 1 ? false : true}
				getLatestEngagementCode={emptyHandle}
				isEngagementCodeDisabled={false}
				updatePrimaryEngagementCode={updatePrimaryInEngagementCodeState}
			/>
		);
	};

	const serviceLineOption = (serviceline) => {
		return {
			value: serviceline.data.servicelineid,
			label: serviceline.data.servicelinename
		};
	};

	let primaryOfficeOption = function (primaryoffice) {
		return {
			value: primaryoffice.id,
			label: primaryoffice.data.name
		};
	};

	let languagesListOption = function (language) {
		return {
			value: language.id,
			label: language.data.name
		};
	};

	return (
		<>
			{isLoading ? (
				<Loader view={fullScreen ? 'fullscreen' : null} />
			) : (
				<Createeditstyle>
					<section className="createeditengagement">
						<section>
							<header>
								<h5>{labels.createengagementquestionlabel}</h5>
							</header>
							<section className="clientworkspaceDetails">
								<FormStaticText label={labels.client + ':'}>
									<EllipsesControl
										id=""
										content={getDependencyData('domainClientName')}
										tooltip={getDependencyData('domainClientName')}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.workspace + ':'}>
									<EllipsesControl
										id=""
										content={getDependencyData('domainWorkspaceName')}
										tooltip={getDependencyData('domainWorkspaceName')}
										isTooltipAvailable
									/>
								</FormStaticText>
							</section>
						</section>

						<form className="createengagement">
							<EYForm
								id="createEngagementForm"
								onChange={updateState}
								onSubmit={() => {}}
								action="\"
								validationTriggerCounter={validationTriggerCounter}
							>
								<FormTextInput
									placeholder={labels.engagementname}
									name="engagementname"
									value={engagementName}
									rules={[]}
									is_required
									help
									maxLength="255"
									errormessage={labels.engagementnamerequired}
									clearable={true}
									className="abc"
								/>

								{renderEngagementCodes()}
								<section className="addengagement">
									<Button
										name="addNewEngagementCode"
										value="addNewEngagementCode"
										buttonType="secondary"
										disabled={false}
										label={labels.addengagementcode}
										onClick={(e) => {
											onAddEngagementCodeClick(e);
										}}
									/>
								</section>

								<FormComboBox
									id="userprimaryofficeid"
									name="userprimaryofficeid"
									value={
										userPrimaryOfficeId === null
											? undefined
											: userPrimaryOfficeId
									}
									searchable
									is_required
									help
									placeholder={labels.primaryoffice}
									disabled={false}
									defaultValue={{label: 'Select', value: 0}}
									options={
										primaryoffices &&
										primaryoffices.collections &&
										primaryoffices.collections.countryofficeregioncollection.map(
											primaryOfficeOption
										)
									}
									errormessage={labels.primaryofficerequired}
								/>

								<FormComboBox
									id="userlanguage"
									name="userlanguage"
									value={userLanguage === null ? undefined : userLanguage}
									is_required
									help
									placeholder={labels.language}
									searchable
									disabled={true}
									options={
										languages &&
										languages.collections &&
										languages.collections.languagecollection.map(
											languagesListOption
										)
									}
									errormessage={labels.languagerequired}
								/>
								<br />
								<FormComboBox
									id="serviceline"
									name="serviceline"
									value={serviceLine}
									is_required
									help
									placeholder={labels.serviceline}
									searchable
									options={
										servicelines &&
										servicelines.collections &&
										servicelines.collections.servicelines.map(serviceLineOption)
									}
									errormessage={labels.servicelinerequired}
									clearable={true}
								/>
								<SubServiceLineList
									showError={showSubServiceValidation}
									subserviceline={subServiceLine}
									isDisabled={isSubServiceLineDisabled}
									subservicelineOption={subservicelineOption}
									onChange={updateState}
								/>
								<br />
							</EYForm>
							<section className="zeus-datepicker">
								<Calendar
									name="clientenddate"
									value={clientEndDate}
									label={labels.engagementstartdate}
									dateFormat={dateFormatsListItems[dateFormats].format}
									validationTriggerCounter={validationTriggerCounter}
									isMandatory={true}
									errorMessage={labels.isRequired.replace(
										'{0}',
										labels.yearenddate
									)}
									onChange={(e) => {
										setClientEndDate(e.value);
									}}
								/>

								<Calendar
									name="engagementenddate"
									value={engagementEndDate}
									label={labels.engagementenddate}
									dateFormat={dateFormatsListItems[dateFormats].format}
									validationTriggerCounter={validationTriggerCounter}
									isMandatory={true}
									errorMessage={labels.isRequired.replace(
										'{0}',
										labels.periodenddate
									)}
									onChange={(e) => {
										setEngagementEndDate(e.value);
									}}
								/>
								<br />
								<Calendar
									name="auditorsreportdate"
									value={auditorsReportDate}
									label={labels.auditorsreportdate}
									dateFormat={dateFormatsListItems[dateFormats].format}
									validationTriggerCounter={validationTriggerCounter}
									isMandatory={true}
									errorMessage={labels.isRequired.replace(
										'{0}',
										labels.reportdate
									)}
									onChange={(e) => {
										setAuditorsReportDate(e.value);
									}}
								/>
							</section>
						</form>
					</section>
				</Createeditstyle>
			)}
		</>
	);
};

export default CreateEngagement;
