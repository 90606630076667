import React, {useState, useEffect} from 'react';
import SelectDropdown from '../../Common/SelectDropdown/SelectDropdown';
import {
	MotifFormField,
	MotifButton,
	MotifTextArea,
	MotifSearch
} from '@ey-xd/motif-react';

import {MotifErrorMessage} from '@ey-xd/motif-react';
import {useSelector, useDispatch} from 'react-redux';
import {
	successMessageDisplayDuration,
	currentResource,
	labels,
	cacheKeyRegex
} from '../../../util/uiconstants';
import Toast from '../../Common/Toast/Toast';
import Loader from '../../Common/Loaders/loadingindicator';
import AccessDeniedMessage from '../../Common/AccessDeniedMessage/AccessDeniedMessage';
import {
	getCache,
	deleteCache,
	clearResult
} from '../../../actions/admin/cacheaction';
import env from '../../../util/env';
import styled from 'styled-components';
import {getLocalizedAPIError} from '../common/util';
import {clearErrors} from '../../../actions/erroractions';
import {setIsModalOpen} from '../../../actions/modalstateactions';
import TicketModal from '../common/ticketmodal';
import { Ellipse } from '@ey/canvascoreservices';


const Wrapper = styled.section`
	margin-top: 5rem;

	.toastMessage {
		width: 100% !important;
		position: relative;
		.motif-toast {
			position: absolute;
			z-index: 9999;
		}
		.motif-toast-text-container {
			font-size: 1.2rem;
		}
	}

	.cacheresult {
		height: calc(100vh - 27rem);
		position: relative;
		margin-top: 4rem;

		.result {
			.textarea {
				font-size: 1.2rem;
				display: inline-block;
				width: 100%;

				.motif-text-area {
					margin-right: 5rem;
					width: 100%;
					padding: 2rem 40% 2rem 2rem;
					height: calc(100vh - 35rem);
					font-size: 1.4rem;
					line-height: 2rem;
					box-shadow: none;
					border: 0;
					resize: none;
					background: ${(props) => props.theme.color[`grey100`]};
				}
			}

			.removecache {
				position: absolute;
				bottom: 1rem;

				.motif-button {
					font-size: 1.4rem;
					padding: 1rem 2rem;
				}
			}
		}

		.noresult {
			height: calc(100vh - 30rem);
			display: inline-block;
			width: 100%;
			display: flex;
			justify-content: center;
			flex: 0 auto;
			align-items: center;
			font-size: 1.6rem;
			color: ${(props) => props.theme.color[`grey400`]};
		}

		.loadingstyle {
			height: calc(100vh - 30rem) !important;
		}
	}

	.cachewrapper {
		display: flex;
		padding-top: 2rem;
		gap: 2.4rem;

		.cache-dropdown-wrap{
			width: 17rem;
		}

		.cache-dropdown-section{
			display: flex;
		}

		.label{
			font-size: 1.4rem;
			font-weight: bold;
			margin-bottom: 1rem;
			color: ${(props) => props.theme.color[`grey500`]};
		}

		.motif-typeahead-close-button{
            top: 1.5rem;
        }

		.cachefield {
			width: 17%;
			position: relative;

			.motif-search-input-wrapper{
				position: relative;
				.motif-icon-button{
					top: 0 !important;
					height: 100%;
					background: transparent;
				}
			}

			&.cachekey {
				flex: 1;
			}

			.motif-error-message {
				font-size: 1.2rem;
				position: absolute;
				top: 100%;
			}

			.motif-input {
				font-size: 1.4rem;
				line-height: 3rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				&::placeholder {
					font-size: 1.4rem;
				}
			}

			.motif-form-field {
				margin: 0;
				line-height: 4rem;

				.motif-icon-button {
					display: none;
				}
			}
		}
	}

	.getcacheclearcache {
		display: flex;
		gap: 2.4rem;
		align-items: end;
		.motif-button {
			height: 4.5rem;
			font-size: 1.4rem;
			padding: 1rem 2rem;
		}
	}
`;

function CacheDetails() {
	const userPermissions = useSelector((store) => store.userPermissions);
	const dispatch = useDispatch();
	const duration = successMessageDisplayDuration;

	const cache = useSelector((store) => store.cache);
	const [cacheKey, setCacheKey] = useState('');
	const [engagementId, setEngagementId] = useState('');
	const [cacheValue, setCacheValue] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showNoResult, setShowNoResult] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [currentValue, setCurrentValue] = useState(currentResource.cacheOptionValue[0]);
	const [cacheRemoveSuccessMessage, setCacheRemoveSuccessMessage] = useState('');
	const [showInvalidEngIdValidationMessage, setShowInvalidEngIdValidationMessage] = useState(false);
	const [isRemoveCacheDisabled, setIsRemoveCacheDisabled] = useState(false);
	const [showCacheModal, setShowCacheModal] = useState(false);
	const [showIndvalidCacheKeyValidationMessage, setShowInvalidCacheKeyValidationMessage] = useState(false);

	useEffect(() => {
		clearError(true);
		return () => {
			clearError(true);
		};
	}, []);

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	useEffect(() => {
		let cacheData = ''; 
		if (cache && Object.keys(cache).length > 0)
		{
			cacheData = JSON.stringify(cache)
		}
		setCacheValue(cacheData);
	}, [cache]);

	useEffect(() => {
		dispatch(clearResult());
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setCacheRemoveSuccessMessage('');
		}, duration);
		return () => clearTimeout(timer);
	});

	const IsValidForm = () => {
		let isValid = true;

		// Validate engagement id only if LDC is selected
		if (currentValue.value == currentResource.cacheOptionValue[0].value) {
			if (!Number(engagementId)) {
				setShowInvalidEngIdValidationMessage(true);
				isValid = false;
			}
		}

		// Validate cache key
		if (cacheKey.trim().length === 0) {
			isValid = false;
		}
		else if (!cacheKeyRegex.test(cacheKey)) {
			setShowInvalidCacheKeyValidationMessage(true);
			isValid = false;
		}

		return isValid;
	};

	const onClearResultButtonClick = () => {
		setShowInvalidEngIdValidationMessage(false);
		setShowInvalidCacheKeyValidationMessage(false);
		setEngagementId('');
		setCacheKey('');
		dispatch(clearResult());
	};

	let cacheUri = `${env.getURL('serviceUrlV2')}admin/Cache?keyName=${encodeURIComponent(cacheKey.trim())}`;

	cacheUri += currentValue.value === currentResource.cacheOptionValue[0].value ?
					`&engagementId=${engagementId}&isGlobal=false` //LDC
					: '&isGlobal=true';							   //Universal	

	const onGetCacheButtonClick = () => {
		if (IsValidForm()) {
			setLoading(true);
			dispatch(getCache(cacheUri))
				.then(() => {
					setLoading(false);
					setShowNoResult(true);
				})
				.catch((e) => {
					setErrorMessage(getLocalizedAPIError(e));
					setShowNoResult(false);
				})
				.finally(() => {
					setLoading(false);
					setIsSubmitted(false);
				});
		} else {
			setIsSubmitted(true);
			setShowNoResult(false);
		}
		setIsRemoveCacheDisabled(false);
	};

	const onRemoveCacheButtonClick = (ticketNumber) => {
		const removeCacheUri = cacheUri + '&ticketnumber=' + encodeURIComponent(ticketNumber);
		setShowCacheModal(true);
		if (ticketNumber.length > 0 && ticketNumber.length <= 100) {
			setLoading(true);
			dispatch(deleteCache(removeCacheUri))
				.then(() => {
					setCacheRemoveSuccessMessage(labels.cacheRemoveSuccessMessage);
					dispatch(clearResult()).then(() => setLoading(false));
				})
				.catch((e) => {
					setErrorMessage(getLocalizedAPIError(e));
				})
				.finally(() => {
					setLoading(false);
				});
		}
		setIsSubmitted(false);
		setShowNoResult(false);
	};

	function OnInputChange() {
		setIsRemoveCacheDisabled(true);
		if (cacheValue?.length > 0) {
			dispatch(clearResult());
		}
	}

	function adminPermissions() {
		let permissionGroupPermission = userPermissions?.permissions;
		return (
			permissionGroupPermission && permissionGroupPermission?.canReadDeleteCache
		);
	}

	const onConfirmClick = (enteredTicketNumber) => {
		onRemoveCacheButtonClick(enteredTicketNumber)
	};

	const formatOptionLabel = ({ value, label }) => (
		<Ellipse content={label} tooltip={label} noOfLines={1} />
	);

	const onSelectOptionChange = (option) => {
		setCurrentValue(option);
		if (option.value === currentResource.cacheOptionValue[1].value) {
			setEngagementId('');
		}
		dispatch(clearResult());
	};

	return (
		<Wrapper>
			{adminPermissions() ? (
				<section className="mainResponsiveWrapper">
					<section
						className="toastMessage"
						style={{
							display: cacheRemoveSuccessMessage.length > 0 ? 'block' : 'none'
						}}
					>
						<Toast
							variant="success"
							duration={duration}
							onClose={() => setCacheRemoveSuccessMessage('')}
						>
							{cacheRemoveSuccessMessage}
						</Toast>
					</section>

					<section
						className="toastMessage"
						style={{
							display: errorMessage.length > 0 ? 'block' : 'none'
						}}
					>
						<Toast variant="error" onClose={() => setErrorMessage('')}>
							{errorMessage}
						</Toast>
					</section>
                    

					<section className="cachewrapper">
					<section className="cache-dropdown-wrap">
						<section className='label'>{labels.cacheSelection}</section>
						<section className='cache-dropdown-section'>
							<SelectDropdown
								className="cache-dropdown"
								defaultValue={currentValue}
								formLabel={labels.cacheSelection}
								options={currentResource.cacheOptionValue}
								onChange={onSelectOptionChange}
								formatOptionLabel={formatOptionLabel}
								value={currentValue}
							></SelectDropdown>
						</section>
					</section>
						<section className="cachefield cachekey">
							<section className='label'>{labels.cache}</section>
							<section>
								<MotifSearch
									maxLength={500}
									value={cacheKey}
									onChange={(event) => {
										setCacheKey(event.target.value.trim());
										setShowInvalidCacheKeyValidationMessage(false);
										OnInputChange();
									}}
									onEnter={onGetCacheButtonClick}
									placeholder={labels.cacheKey}
									title={cacheKey}
								></MotifSearch>
								{cacheKey.trim().length === 0 && isSubmitted && (
									<MotifErrorMessage>
										{labels.validationMessage}
									</MotifErrorMessage>
								)}
								{cacheKey.trim().length !== 0 && showIndvalidCacheKeyValidationMessage && (
									<MotifErrorMessage>
										{labels.adminModuleInvalidCacheKey}
									</MotifErrorMessage>
								)}
							</section>
						</section>

						<section className="cachefield">
							<section>
								<section className='label'>{labels.engagementID}</section>
								<section>
									<MotifSearch
										maxLength={500}
										value={engagementId}
										disabled={currentValue.value === currentResource.cacheOptionValue[1].value ? true : false}
										onChange={(event) => {
											setEngagementId(event.target.value.trim());
											setShowInvalidEngIdValidationMessage(false);
											OnInputChange();
										}}
										onEnter={onGetCacheButtonClick}
										placeholder={labels.engagementID}
									></MotifSearch>
									{engagementId.trim().length === 0 && isSubmitted && currentValue.value === currentResource.cacheOptionValue[0].value && (
										<MotifErrorMessage>
											{labels.validationMessage}
										</MotifErrorMessage>
									)}
									{engagementId.trim().length !== 0 && showInvalidEngIdValidationMessage && (
										<MotifErrorMessage>
											{labels.adminModuleinvalidEngagementId}
										</MotifErrorMessage>
									)}
								</section>
							</section>
						</section>

						<section className="getcacheclearcache button">
							<MotifButton
								aria-label="getcache"
								role="button"
								type="button"
								variant="primary-alt"
								onClick={onGetCacheButtonClick}
							>
								{labels.getCache}
							</MotifButton>
							<MotifButton
								aria-label="clearcache"
								role="button"
								type="button"
								variant="secondary"
								onClick={onClearResultButtonClick}
								disabled={cacheValue?.length === 0}
							>
								{labels.clearResult}
							</MotifButton>
						</section>
					</section>
					<section className="cacheresult">
						{loading ? (
							<Loader show view="blockView" styleName="loadingstyle" />
						) : cacheValue?.length > 0 ? (
							<section className="result">
								<section className="textarea">
									<MotifFormField>
										<MotifTextArea value={cacheValue} />
									</MotifFormField>
								</section>

								<section className="removecache button">
									<MotifButton
										aria-label="removecache"
										role="button"
										type="button"
										variant="secondary"
										onClick={onRemoveCacheButtonClick}
										disabled={isRemoveCacheDisabled}
									>
										{labels.removeCache}
									</MotifButton>
								</section>

								<section className="showTicketModal">
									{showCacheModal ? (
										<TicketModal
										    onHide={setShowCacheModal}
											onConfirmClick={onConfirmClick}
											modalHeaderLabel={labels.modalHeaderLabel}
											confirmationMessage={labels.confirmationMessage}
											confirmButtonLabel={labels.confirmButtonLabel}
											cancelButtonLabel={labels.cancelButtonLabel}
										></TicketModal>
									) : ('')}
								</section>
							</section>

						) : (
							<section className="noresult">
								{showNoResult ? labels.cacheKeyNotFound : ''}
							</section>
						)}
					</section>
				</section>
			) : (
				<section className="message mainResponsiveWrapper">
					<AccessDeniedMessage
						permissionMesage={labels.insufficientPermission}
					/>
				</section>
			)}
		</Wrapper>
	);
}
export default CacheDetails;
