import * as types from '../actions/actiontypes';

export default function GenericGetReducer(state = {}, action) {
	switch (action.type) {
		case types.GET_GENERIC:
			return action.response;
		case types.GET_NULL:
			state = {};
			return state;
		default:
			return state;
	}
}
