import React from 'react';
import styled from 'styled-components';
import {labels} from '../../util/uiconstants';
import {engagementUserStatus} from '../../util/enumerations';
import env from '../../util/env';
import ModalControl from '@ey/modal-control';
import EllipsesControl from '@ey/ellipses-control';
import {useDispatch} from 'react-redux';
import {PatchEngagement} from '../../actions/engagementsaction';

const EngagementRemoveModal = (props) => {
	const dispatch = useDispatch();

	const {show, quit, hide, id} = props;
	const {
		activeusercount,
		engagementdesc,
		engagementstatus,
		workspacecountryid
	} = props.data;

	const cancelRemoveMsg = labels.removeengagementerrormessage;
	const canRemoveMsg = labels.removeengaementconfirmationmessage1;
	const showConfirm = activeusercount > 1;

	const handleRemoveConfirm = async () => {
		try {
			await dispatch(
				PatchEngagement(
					env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
					JSON.stringify(modelRemove())
				)
			);
			hide();
		} catch (e) {
			quit();
		}
	};

	const modelRemove = () => {
		const payload = {
			id: id,
			engagementUserStatusId: engagementUserStatus.DeActivated,
			engagementstatusid: engagementstatus
		};

		return {
			collections: {
				teammembers: [],
				engagements: [
					{
						data: payload,
						id: id
					}
				]
			},
			data: {
				countryid: workspacecountryid,
				operationType: 'removeengagementfromworkspace'
			}
		};
	};

	return (
		<ModalControl
			title={labels.removeengagementquestionlabel}
			closeTitle={labels.close}
			confirmBtnTitle={labels.confirm}
			closeBtnTitle={labels.cancel}
			modalsize="small"
			onOkClick={handleRemoveConfirm}
			show={show}
			onHide={quit}
			isOkButtonDisabled={!showConfirm}
		>
			<RemoveEngagementStyle>
				<div id="message">
					<h6>{!showConfirm ? cancelRemoveMsg : canRemoveMsg}</h6>
				</div>
				<h6 className="engagement-name">
					<EllipsesControl
						id=""
						content={engagementdesc}
						tooltip={engagementdesc}
						isTooltipAvailable
					/>
				</h6>
			</RemoveEngagementStyle>
		</ModalControl>
	);
};
export default EngagementRemoveModal;

const RemoveEngagementStyle = styled.section`
	h6 {
		font-size: 1.4rem;
		line-height: normal;
		word-break: break-word;
		margin: 2rem;
	}

	& .engagement-name {
		width: calc(100% - 4rem);
		padding: 0;
		display: inline-block;
		margin: 0 2rem 2rem 2rem;
		& .ellipses {
			font-weight: bold;
		}
	}
`;
