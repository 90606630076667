import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const StyledAppContainer = styled.div`
	.header-loader {
		background-color: ${(props) => props.theme.color[`grey100`]};
		height: 5.8rem;
	}

	.sub-header-loader {
		height: 4.8rem;
		border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey500`]};
		margin-bottom: 3rem;
	}
	.form-item-loader {
		height: 8.1rem;
	}
	.form-wrapper-loader {
		padding: 0 4rem;
	}
`;

const UserSettingsLoader = () => {
	return (
		<StyledAppContainer>
			<section className="header-loader"></section>
			<section className="form-wrapper-loader">
				<section className="sub-header-loader"></section>
				<div className="form-item-loader">
					<Skeleton width={400} height={48} />
				</div>
				<div className="form-item-loader">
					<Skeleton width={400} height={48} />
				</div>
				<div className="form-item-loader">
					<Skeleton width={400} height={48} />
				</div>
				<div className="form-item-loader">
					<Skeleton width={400} height={48} />
				</div>
				<div className="form-item-loader">
					<Skeleton width={400} height={48} />
				</div>
				<section className="sub-header-loader"></section>
				<div className="form-item-loader">
					<Skeleton width={400} height={48} />
				</div>
				<div className="form-item-loader">
					<Skeleton width={400} height={48} />
				</div>
				<div className="form-item-loader">
					<Skeleton width={400} height={48} />
				</div>
			</section>
		</StyledAppContainer>
	);
};

export default UserSettingsLoader;
