import * as types from './actiontypes';

export function getRestoreEngagements(url, resource) {
	return {
		type: types.GET_RESTOREENGAGEMENTS,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}

export function getArchivedEngagementDetailsForRestore(url, resource) {
	return {
		type: types.GET_ARC_ENG_DETAILS_FOR_RESTORE,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}

export function setArchivedEngagementDetailsToRestore(engagementData) {
	return {
		type: types.SET_ARC_ENG_DETAILS_FOR_RESTORE,
		payload: engagementData
	};
}
