import {standardStrings as uConst} from '../../../../util/uiconstants';

export default class EngagementModel {
	getWorkspaceModel = () => {
		return this.WorkspaceModel();
	};

	WorkspaceModel() {
		return {
			data: {
				clientcode: uConst.EMPTY,
				clientname: uConst.EMPTY,
				workspacedesc: uConst.EMPTY,
				istrainingworkspace: false,
				isclientvalidated: false,
				clientid: uConst.EMPTY,
				countryid: 0,
				operationtype: uConst.EMPTY,
				rfworkspaceid: 0,
				iscurrent: true,
				selectedarcfileguid: uConst.EMPTY
			},
			collections: [
				{
					engagements: this.WorkspaceEngagements()
				}
			]
		};
	}

	WorkspaceEngagements() {
		return {
			data: {
				description: uConst.EMPTY,
				primaryoffice: uConst.EMPTY,
				languageid: 0,
				yearenddate: uConst.EMPTY,
				enddateperiod: uConst.EMPTY,
				reportreleasedate: uConst.EMPTY,
				engagementversion: uConst.EMPTY,
				servicelineid: 0,
				subservicelineid: 0,
				engagementsourceid: 2
			},
			collections: {
				engagementcodes: []
			}
		};
	}

	GroupAuditEngagementsModel() {
		return {};
	}

	WorkspaceUser() {
		return {
			data: this.UserData()
		};
	}

	UserData() {
		return {
			userid: uConst.EMPTY,
			userloginname: uConst.EMPTY,
			userinitial: uConst.EMPTY,
			contactnumber: uConst.EMPTY,
			displaylanguageid: 0,
			engroleid: 0,
			gui: uConst.EMPTY,
			firstname: uConst.EMPTY,
			lastname: uConst.EMPTY,
			primaryoffice: uConst.EMPTY,
			emailaddress: uConst.EMPTY,
			gpn: uConst.EMPTY,
			iseyuser: false,
			isactive: false,
			eyrole: uConst.EMPTY,
			ispostarchiveaddition: false,
			engagementuserstatusid: 0,
			engagementroleid: 0,
			independencestatusid: 0,
			independencecomment: uConst.EMPTY,
			independencestatusmodifyuserid: uConst.EMPTY,
			independencestatusmodifydate: uConst.EMPTY
		};
	}

	EngagementCodes() {
		return {
			data: this.EngagementCodesData()
		};
	}

	EngagementCodesData() {
		return {
			code: uConst.EMPTY,
			name: uConst.EMPTY,
			clientengagementid: 0,
			isprimary: false,
			cross: uConst.EMPTY,
			invalidengagementcodeerror: 0,
			hidedeletebutton: uConst.EMPTY
		};
	}
}
