import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import env from '../../../util/env';
import {FormRadio} from '@ey/form';
import {Radio} from 'react-bootstrap';
import {getSelectTemplateData} from '../../../actions/selectimporttemplateaction';
import {labels, urls, standardStrings} from '../../../util/uiconstants';
import {FormStaticText} from '@ey/form';
import {SVGICONS} from '@ey/icons';
import Search from '@ey/search';
import Loader from '@ey/loader';
import { useDidMountEffect } from '../../../util/customHooks';

const SelectTemplate = (props) => {
	const {
		getDependencyData,
		onComponentDisplayed,
		nextClicked,
		isComponentValid,
		updateWizardData
	} = props;

	const dispatch = useDispatch();

	const templates = useSelector((storeState) => storeState.selectTemplate);

	const [isLoading, setIsLoading] = useState(false);
	const [showNoMatchFound, setShowNoMatchFound] = useState(false);
	const [showValidation, setShowValidation] = useState(false);
	const [isDataValid, setIsDataValid] = useState(false);
	const [isStepValid, setIsStepValid] = useState(false);
	const [searchString, setSearchString] = useState('');
	const [allTemplates, setAllTemplates] = useState([]);
	const [filteredTemplates, setFilteredTemplates] = useState([]);
	const [hideSearch, setHideSearch] = useState(false);

	let domain = getDependencyData('domainSelectedRegion');
	let key = getDependencyData('domainSelectedRegion').key;
	const selecttemplateurl =
		env.getURL('serviceUrl') +
		'/' +
		urls.COPYHUB_URL +
		'?selectedregionid=' +
		key +
		'&filters=IsCreateGCOCopyDrawer eq false,area eq ' +
		key +
		',searchtypeid eq 3';

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setComponentValid(getDependencyData('domainSelectedTemplate')?.key != null);
	}, [onComponentDisplayed]);

	useDidMountEffect(() => {
		setShowNoMatchFound(false);
		!isStepValid && setShowValidation(true);
	}, [nextClicked]);

	useDidMountEffect(() => {
		filteredTemplates?.length > 0 ? setShowNoMatchFound(false) : setShowNoMatchFound(true);
	}, [filteredTemplates]);

	useEffect(() => {
		setAllTemplates(templates?.collections?.templates);
		setHideSearch(
			templates &&
				templates.collections &&
				templates.collections.templates &&
				templates.collections.templates.length > 0
				? false
				: true
		);
		filterTemplates();
	}, [templates?.collections?.templates?.length]);

	const fetchData = async () => {
		try {
			setIsLoading(true);
			await dispatch(getSelectTemplateData(selecttemplateurl));
			setIsDataValid(true);
		} catch (error) {
			setIsDataValid(false);
		} finally {
			setIsLoading(false);
		}
	};

	const radioButtonClicked = (key, value, sourceEngagementId) => {
		setIsStepValid(true);
		setShowValidation(false);
		updateWizardFromComponent(
			{
				key: key,
				value: value,
				templateEngagementId: sourceEngagementId
			},
			true
		);
	};

	const updateWizardFromComponent = (template, stepValid) => {
		updateWizardData('domainSelectedTemplate', template);
		updateWizardData('domainTemplateId', template?.key);
		setComponentValid(stepValid);
	};

	const setComponentValid = (stepValid = false) => {
		if (stepValid) {
			isComponentValid(true);
		} else {
			isComponentValid(false);
		}
	};

	const onKeyDownInSearchBox = (e) => {
		if (e.keyCode === 13) {
			filterTemplates();
			e.preventDefault();
		}
	};

	const filterTemplates = (searchStringEmpty = false) => {
		setShowValidation(false);
		let sText = searchStringEmpty ? '' : searchString.trim();
		var newCollection = templates?.collections?.templates?.filter(function (
			el
		) {
			if (sText !== '') {
				return el.data.name.toUpperCase().includes(sText.toUpperCase());
			} else {
				return true;
			}
		});
		setFilteredTemplates(newCollection);
		setIsStepValid(false);
		setComponentValid(getDependencyData('domainSelectedTemplate')?.key != null);
	};

	const getTemplatesList = () => {
		let result = [];
		if (filteredTemplates) {
			filteredTemplates.map(({id, data}) => {
				result.push(
					<Radio
						value={data.name}
						key={id}
						name="radio"
						onClick={() => {
							radioButtonClicked(id, data.name, data.copysourceengagementid);
						}}
						checked={
							getDependencyData('domainSelectedTemplate')?.key === id
						}
					>
						<span>{data.name}</span>
					</Radio>
				);
			});
		}
		return result;
	};

	const TemplatesList = () => {
		return (
			<section className="templatelist">
				<FormRadio name="selectTemplateRadioList" onChange={() => {}}>
					{getTemplatesList()}
				</FormRadio>
				<SelectTemplateFilterMsg
					dataWithoutFilter={allTemplates}
					dataWithFilter={filteredTemplates}
					isSearch={searchString.length ? true : false}
				/>
			</section>
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : !isDataValid ? (
				<div />
			) : (
				<Selecttemplate>
					<form>
						<header className="import-header">
							<h5>{labels.importselecttemplateheader}</h5>
						</header>
						<section className="areaRegionDetails">
							<FormStaticText label={labels.arearegion + ':'}>
								{domain.value}
							</FormStaticText>
						</section>
						<section
							className={hideSearch ? 'page-filter hide' : 'page-filter'}
						>
							<section className="search-filter">
								<Search
									name="name"
									value={searchString}
									placeholder={labels.searchPlaceholder}
									clearHoverText={labels.clearSearch}
									maxLength="500"
									onBlur={() => {
										if (searchString === '') {
											filterTemplates(true);
										}
									}}
									onChange={(e) => {
										setSearchString(e);
									}}
									onKeyDown={(e) => {
										onKeyDownInSearchBox(e);
									}}
									onClearClick={() => {
										setSearchString('');
										filterTemplates(true);
									}}
									onSearchClick={() => {
										filterTemplates();
									}}
									isDisabled={false}
								/>
							</section>
						</section>
						<section className="import-error">
							{showNoMatchFound && (
								<section>
									<SVGICONS styleName="small" id="icon" name="error" />
									{labels.nomatches}
								</section>
							)}
							{showValidation && (
								<section>
									<SVGICONS styleName="small" id="icon" name="error" />
									{labels.importselecttemplatemsg}
								</section>
							)}
						</section>
						{templates?.collections?.templates?.length > 0 && (
							<section>
								<TemplatesList />
							</section>
						)}
					</form>
				</Selecttemplate>
			)}
		</>
	);
};
export default SelectTemplate;

const SelectTemplateFilterMsg = (props) => {
	let msg = '';
	let data =
		props.dataWithoutFilter &&
		props.dataWithoutFilter.collections &&
		props.dataWithoutFilter.collections.templates;
	let fData = props.dataWithFilter;

	if (data?.length < 1 && props.isSearch === false) {
		msg = <p className="nomatch">{labels.notemplates}</p>;
	} else if (data?.length < 1 && props.isSearch === true && fData.length < 1) {
		msg = <p className="nomatch">{''}</p>;
	} else if (data?.length > 1 && props.isSearch === true && fData.length < 1) {
		msg = <p className="nomatch">{labels.nomatches}</p>;
	}
	return msg;
};

const Selecttemplate = styled.section`
	.page-filter {
		padding: 1rem 0;
		& .search-filter {
			display: inline-block;
			line-height: 4rem;
			width: 35rem;

			& > .searchControl {
				height: 4rem;
				background: transparent;
			}

			& input {
				border-left: 0;
				border-right: 0;
				border-top: 0;
				background: none;
				border-bottom: 0 solid ${(props) => props.theme.color[`grey500`]};
				&:focus {
					border-left: 0 !important;
					border-right: 0 !important;
					border-top: 0 !important;
					border-bottom: 0 solid ${(props) => props.theme.color[`grey500`]} !important;
				}
			}
		}
	}
	& .import-error {
		font-size: 1.2rem;
		line-height: normal;
		height: 2.4rem;

		& svg {
			fill: ${(props) => props.theme.color[`red600`]};
			vertical-align: text-top;
			margin-right: 0.5em;
		}
	}

	& .templatelist {
		margin-top: 1rem;

		& .nomatch {
			font-size: 1.4rem;
			color: ${(props) => props.theme.color[`grey400`]};
		}

		& > .form-group {
			margin: 0 0 1.5rem 0;
			display: inline;
		}

		& .form-group label.control-label {
			display: inline;
		}
	}

	& .templatelist div.form-group .radio {
		vertical-align: middle;
		margin: 0 0 1.5rem 0;

		& label input[type='radio'] {
			margin: 0 0 0 -3rem;
		}
		& label {
			padding-left: 3rem;
			font-weight: normal;
		}
	}

	& .areaRegionDetails {
		padding: 0 0 0.5rem 0;
		border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
		width: 95%;
		font-size: 1.4rem;

		& > div.form-group {
			line-height: normal;
			height: auto;
			display: inline-block;
			width: 100%;
			margin: 0;
		}

		& > div.form-group p,
		& > div.form-group label {
			padding: 0;
			margin: 0;
			min-height: auto;
			line-height: normal;
			margin-right: 0.5rem;
			float: left;
			font-size: 1.4rem;
		}
	}
`;
