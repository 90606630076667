/**
 * ProjectManagement.js
 * Created by calhosh on 1/6/2020.
 */

import {
	currentResource,
	labels,
	resourceFileNames
} from '../../util/uiconstants';

import EllipsesControl from '@ey/ellipses-control';
import React from 'react';
import {SVGICONS} from '@ey/icons';

const ProjectManagement = (props) => {
	const {
		featureConfiguration,
		spotfireUri,
		engagementId,
		engagementVersion,
		language
	} = props;

	const getReportURL = (report, i) => {
		let url = spotfireUri
			.replace('{0}', currentResource.projectManagementReportNames[report])
			.replace('{1}', engagementId)
			.replace('{2}', engagementVersion)
			.replace('{3}', resourceFileNames[language]);

		if (i === 1 && featureConfiguration.digitalscorecardweburl) {
			// this is digital scorecard
			// check if we have url
			// then only show this
			url = featureConfiguration.digitalscorecardweburl.replace(
				'{0}',
				engagementId
			);
		}

		return url;
	};

	const getReports = () => {
		let list = [];

		currentResource.projectManagement.map((report, i) => {
			if (i === 1 && !featureConfiguration.digitalscorecardweburl) {
				// do nothing for this case
			} else {
				list.push(
					<li key={i + report + 'S'}>
						<a key={i} href={getReportURL(report, i)} target="_blank">
							<EllipsesControl
								content={report}
								tooltip={report}
								isTooltipAvailable
							/>
						</a>
					</li>
				);
			}
		});

		return list;
	};

	return (
		<section className="ReportingWrapper">
			<section className="ReportingHeader">
				<SVGICONS
					className="receiptIcon"
					hoverText={labels.projectManagementLabel}
					name="receipt"
				/>
				{labels.projectManagementLabel}
			</section>
			<ul className="ReportingLinks">{getReports()} </ul>
		</section>
	);
};

export default ProjectManagement;
