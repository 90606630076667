import styled from 'styled-components';

const Userlistwrapper = styled.section`
	&.inactive mgt-person {
		--color: ${(props) => props.theme.color[`grey300`]};
		--line2-color: ${(props) => props.theme.color[`grey300`]};
		//--font-weight: normal;
	}
	mgt-person {
		--color: ${(props) => props.theme.color[`grey500`]};
		--line2-color: ${(props) => props.theme.color[`grey500`]};
		//--font-weight: normal;
	}

	&:first-child .userlistitem.contextmenu .show-pop-content.top {
		bottom: auto;
		top: 1.5rem;

		& .show-popup-arrow {
			bottom: auto;
			transform: rotate(225deg);
		}
	}

	& > .userlistitem {
		& .contextmenu,
		&.contextmenu {
			font-size: 2rem;
			color: ${(props) => props.theme.color[`grey400`]};

			.context {
				display: inline-block;
				button {
					border: 0;
					background: none;
				}
				svg {
					height: 2.2rem;
					vertical-align: middle;
				}
			}

			.show-pop-content {
				margin: 1rem 0 1.5rem 0;
			}

			.show-pop-content-body {
				width: 20rem;
				ul {
					line-height: normal;
					li {
						width: 100%;
						display: inline-block;
						font-size: 1.4rem;
						cursor: pointer;
						line-height: normal;

						svg {
							vertical-align: middle;
							margin-right: 1em;
						}

						a {
							color: ${(props) => props.theme.color[`grey400`]};
							font-weight: normal;
							text-decoration: none;
							padding: 1rem;
							width: 100%;
							display: inline-block;

							&:hover {
								background: ${(props) => props.theme.color[`grey100`]};
							}
						}
					}
				}
			}
		}

		.userdata {
			max-width: 50%;
			display: inline-block;
			vertical-align: inherit;

			mgt-person {
				max-width: 100%;
			}
		}
	}

	// internal item view specific
	&.manageaccessitemview {
		&.inactive,
		&.inactive .userlistitem {
			.userdetail,
			.useremail,
			.contextmenu {
				color: ${(props) => props.theme.color[`grey300`]};
			}

			&.userdetail {
				.ms-user mgt-person {
					--color: ${(props) => props.theme.color[`grey300`]};
				}
			}
		}
	}
`;
export default Userlistwrapper;
