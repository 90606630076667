import React from 'react';
import EngagementItem from './EngagementItem';

function EngagementList(props) {
	return (
		<section id="tablebodycontainer" className="customScrollbar userlist-body">
			<section className="userlistholder">
				{props.list.map((item, index) => (
					<EngagementItem key={index + '' + item.engagementId} data={item} />
				))}
			</section>
		</section>
	);
}

export default EngagementList;
