import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	MotifButton,
	MotifToggleSwitch,
	MotifSearch,
	MotifFormField
} from '@ey-xd/motif-react';
import styled from 'styled-components';
import Pagination from '@ey/pager';
import Loader from '../../../Common/Loaders/loadingindicator';
import Toast from '../../../Common/Toast/Toast';
import ModalControl from '../../../Common/Modal/Modal';
import {
	pagingOptions,
	successMessageDisplayDuration,
	UserLookupType,
	reportFormateType
} from '../../../../util/uiconstants';
import { labels } from '../../../../util/uiconstants';
import env from '../../../../util/env';
import { downloadReport } from '../../../../actions/admin/reportaction';
import * as manageaccessactions from '../../../../actions/admin/manageaccessactions';
import { setIsModalOpen } from '../../../../actions/modalstateactions';
import { clearErrors } from '../../../../actions/erroractions';

import AdminUserItemList from './adminuseritemlist';
import AddUser from '../../../Common/AddUser/AddUser';
import DeactivateUser from './deactivateuser';
import AccessDeniedMessage from '../../../Common/AccessDeniedMessage/AccessDeniedMessage';
import { getMeCall } from '../../../../actions/admin/mepermissionsactions';
import { getLocalizedAPIError } from '../../common/util';

function ManageAccess() {
	// hooks
	const adminUser = useSelector((store) => store.adminUsers);
	const userPermissions = useSelector((store) => store.userPermissions);
	const dispatch = useDispatch();
	const history = useHistory();

	//states
	const [searchInputValue, setSearchInputValue] = useState('');
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(adminUser?.pages);
	const [pageSize, setPageSize] = useState(20);
	const [showInactive, setShowInactive] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [showAddUser, setShowAddUser] = useState(false);
	const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);
	const [isMeCallSent, setIsMeCallSent] = useState(false);
	const [userToDeactivate, setUserToDeactivate] = useState();
	const [addUserSuccessMessage, setAddUserSuccessMessage] = useState('');
	const [isErrorOccurred, setIsErrorOccurred] = useState(false);
	const duration = successMessageDisplayDuration;
	const [addUserErrorMessage, setAddUserErrorMessage] = useState([]);
	const [addUserLoading, setAddUserLoading] = useState(false);

	useEffect(() => {
		dispatch(getMeCall()).then(() => setIsMeCallSent(true));
	}, []);

	useEffect(() => {
		if (isMeCallSent && userHasManageAccessPerm()) {
			loadAdminUsers(searchInputValue);
		}
	}, [showInactive, page, pageSize, userPermissions, isMeCallSent]);

	useEffect(() => {
		if (adminUser) {
			setTotalPages(adminUser.pages);
		}
	}, [adminUser]);

	useEffect(() => {
		if (showAddUser == true) {
			dispatch(clearErrors()).then(function () {
				dispatch(setIsModalOpen(true));
			});
		} else {
			dispatch(clearErrors());
			dispatch(setIsModalOpen(false));
		}
	}, [showAddUser]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setAddUserSuccessMessage('');
		}, duration);
		return () => clearTimeout(timer);
	});

	const loadAdminUsers = (searchText) => {
		setIsLoading(true);
		let adminUserUrl =
			env.getURL('serviceUrlV2') +
			`admin/Users?page=${page}&pageSize=${pageSize}&isActive=${!showInactive}`;

		if (searchText) {
			adminUserUrl += `&searchString=${encodeURIComponent(searchText)}`;
		}

		dispatch(manageaccessactions.getAdminUsers(adminUserUrl))
			.then(() => {
				setIsLoading(false);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onSearchChange = () => {
		if (searchInputValue != undefined) {
			setPage(1);
			loadAdminUsers(searchInputValue);
		}
	};

	const onClearClick = () => {
		setSearchInputValue('');
		loadAdminUsers('');
	};

	const handlerOptionPage = (value) => {
		if (value !== pageSize) {
			setPageSize(value);
			setPage(1);
		}
	};

	const paginationHandler = (value) => {
		if (page !== value) {
			setPage(value);
		}
	};

	const saveUsers = (selectedUsers, ticketNumber) => {
		if (selectedUsers?.length <= 0) {
			return;
		}
		setAddUserLoading(true);
		let data = {
			ticketNumber: ticketNumber,
			userModel: selectedUsers
		};

		let adminUserUrl = env.getURL('serviceUrlV2') + `admin/Users`;

		dispatch(manageaccessactions.addAdminUsers(adminUserUrl, data))
			.then(() => {
				setAddUserLoading(false);
				setAddUserSuccessMessage(labels.addUserSuccessMessage);
				closeAddUserModal(true);
			})
			.catch((result) => {
				setIsErrorOccurred(true);
				setAddUserErrorMessage([getLocalizedAPIError(result)]);
			})
			.finally(() => {
				setAddUserLoading(false);
			});
	};

	const downloadUserAccessReport = () => {
		setIsLoading(true);
		var url = env.getURL('serviceUrlV2') + 'admin/reports/useraccessreport';
		dispatch(downloadReport(url, "User-Access-Report." + reportFormateType.xlsx)).finally(() => {
			setIsLoading(false);
		});
	};

	/*** Add User Modal functions starts***/
	useEffect(() => {
		if (history.location.hash.toLowerCase() == '#addnewuser') {
			setShowAddUser(true);
		}
	}, [history.location]);

	useEffect(() => {
		if (userToDeactivate) {
			setShowDeactivateUserModal(true);
		}
	}, [userToDeactivate]);

	const openAddUserModal = () => {
		history.push({
			pathname: history.location.pathname,
			hash: 'addnewuser'
		});

		setShowAddUser(!showAddUser);
	};

	const closeAddUserModal = (shallRefresh) => {
		setShowAddUser(false);
		setAddUserErrorMessage([]);
		if (history.location.hash && history.location.hash.length > 0) {
			history.replace({
				pathname: history.location.pathname,
				search: history.location.search,
				hash: '',
			});
		}
		if (shallRefresh) {
			loadAdminUsers(searchInputValue);
		}
	};

	const closeDeactivateUserModal = (shallRefresh) => {
		setShowDeactivateUserModal(false);
		setUserToDeactivate(undefined);

		if (shallRefresh) {
			loadAdminUsers(searchInputValue);
		}
	};

	const onDeactivateUserClick = (user) => {
		setUserToDeactivate(user);
	};

	const userHasManageAccessPerm = () =>
		userPermissions?.permissions &&
		(userPermissions.permissions.canCreateUpdateManageAccess ||
			userPermissions.permissions.canReadManageAccess);
	/*** Add User Modal functions ends ***/

	return !userHasManageAccessPerm() ? (
		<AccessDeniedMessage permissionMesage={labels.insufficientPermission} />
	) : (
		<Mainwrapper>
			<section className="manageaccess mainResponsiveWrapper">
				<section
					className={
						'toastMessage ' +
						(addUserSuccessMessage.length > 0 ? 'block' : 'none')
					}
				>
					<Toast
						variant="success"
						duration={duration}
						onClose={() => setAddUserSuccessMessage('')}
					>
						{addUserSuccessMessage}
					</Toast>
				</section>
				<section className="add-on">
					<section className="search">
						<MotifFormField>
							<MotifSearch
								placeholder={labels.searchUserText}
								value={searchInputValue}
								onChange={(e) => setSearchInputValue(e.target.value)}
								onSearchButtonClick={onSearchChange}
								onClear={onClearClick}
								onEnter={onSearchChange}
								clearHoverText={labels.clear}
							/>
						</MotifFormField>
					</section>
					<section className="showinactive">
						<MotifToggleSwitch
							aria-describedby="showuser"
							checked={showInactive}
							onChange={(event) => {
								setShowInactive(event.target.checked);
								setPage(1);
							}}
							id="toggle1"
							hideLabels={true}
						/>
						<span id="showuser" className="label">
							{labels.showInactiveUserslabel}
						</span>
					</section>

					<section className="downloadReport">
						<a onClick={downloadUserAccessReport}>{labels.generateReport}</a>
					</section>

					<section className="adduser">
						<MotifButton
							aria-label="Add user"
							role="button"
							type="button"
							variant="primary-alt"
							disabled={false}
							onClick={() => {
								openAddUserModal();
							}}
						>
							{labels.addUserButtonLabel}
						</MotifButton>
					</section>
				</section>
			</section>

			{isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : adminUser?.totalEntityCount > 0 ? (
				<AdminUserItemList
					data={adminUser?.data}
					onDeactivate={onDeactivateUserClick}
				/>
			) : (
				<section className="manageaccess noresult">
					{labels.noResultsFound}
				</section>
			)}

			<section className="pagerholder">
				<Pagination
					styleName="manageaccess-paging mainResponsiveWrapper"
					options_per_page={pagingOptions.options}
					default_dropdown_value={pageSize}
					// showText={currentResource.common.pagination.show}
					currentPage={page}
					TotalPages={totalPages}
					dropup
					onInputChange={(e) => {
						let inputValue = Number.parseInt(e.target.value);
						if (inputValue != page && inputValue > 0) {
							paginationHandler(inputValue);
						}
					}}
					onDropdownChange={(e) => {
						handlerOptionPage(e.value);
					}}
					onFirstPageClick={() => {
						paginationHandler(1);
					}}
					onPrevPageClick={() => {
						paginationHandler(page - 1);
					}}
					onNextPageClick={() => {
						paginationHandler(page + 1);
					}}
					onLastPageClick={() => {
						paginationHandler(totalPages);
					}}
					dropdown_position="left"
				// iconHover={currentResource.common.pagination.iconHover}
				/>
			</section>
			<ModalControl
				className="adduser-modal"
				title={labels.addUser}
				onOkClick={() => { }}
				onHide={() => closeAddUserModal(false)}
				isOkButtonDisabled={false}
				show={showAddUser}
				modalsize="small"
				showButtons={false}
				closeTitle={labels.close}
				role="dialog"
			>
				<AddUser
					onNewUserModalClose={closeAddUserModal}
					addUserErrorMessage={addUserErrorMessage}
					userLookupType={UserLookupType.Admin}
					onSaveUsersClick={saveUsers}
					addUserLoading={addUserLoading}
					addUserDescription={labels.addAdminUserHeader}
				/>
			</ModalControl>
			<ModalControl
				className="adduser-modal"
				title={labels.deactivateModalTitle}
				onOkClick={() => { }}
				onHide={() => closeDeactivateUserModal(false)}
				isOkButtonDisabled={false}
				show={showDeactivateUserModal}
				modalsize="x-small"
				showButtons={false}
				closeTitle={labels.close}
				role="dialog"
			>
				<DeactivateUser
					userToDeactivate={userToDeactivate}
					onDeactivateModalClose={closeDeactivateUserModal}
				/>
			</ModalControl>
		</Mainwrapper>
	);
}

const Mainwrapper = styled.section`
	margin-top: 5rem;

	.downloadReport {
		a {
			font-size: 1.4rem;
			font-weight: bold;
			cursor: pointer;
			line-height: 4.6rem;
			margin-right: 2.8rem;
			color: ${(props) => props.theme.color[`blue600yellow400`]};
			&:hover {
				text-decoration: none;
			}
		}
	}

	.loadingstyle {
		height: calc(100vh - 28rem) !important;
	}

	//heading
	.manageaccess {
		.toastMessage {
			.motif-toast-text-container {
				font-size: 1.4rem;
			}

			&.block {
				display: block;
			}

			&.none {
				display: none;
			}
		}

		.pageheading {
			display: flex;
			padding: 2rem 0;
			svg {
				min-width: 2.5rem !important;
				min-height: 2.5rem !important;
				margin-right: 1rem;
			}

			h3 {
				margin: 0;
				line-height: 2.5rem;
			}
		}
	}

	//noresult
	.noresult {
		height: calc(100vh - 30rem);
		justify-content: center;
		align-items: center;
		display: flex;
		font-size: 1.4rem;
		color: ${(props) => props.theme.color[`grey400`]};
	}

	//addon

	.add-on {
		display: flex;
		margin-right: auto;
		padding: 2rem 0 2rem 0;

		.adduser {
			.motif-button {
				height: 4rem;
				font-size: 1.2rem;
				width: 10rem;
			}
		}

		.search {
			width: 16%;
			line-height: 4rem;
			margin-right: auto;

			.motif-icon-button{
                top: 0.5rem;
            }

			.motif-typeahead-close-button {
				top: 0.5rem;
				&:hover{
					background: none!important;
				}
			}

			.motif-input {
				font-size: 1.4rem;
				font-weight: normal;
				height: 4rem;
				&::placeholder {
					font-size: 1.4rem;
					font-weight: normal;
				}
			}
		}
	}

	//inactive user
	.showinactive {
		width: auto;
		display: flex;
		margin-right: 2rem;

		.label {
			font-size: 1.4rem;
			font-weight: normal;
			line-height: 4rem;
			color: ${(props) => props.theme.color[`grey500`]};
			line-height: 4rem;
		}
	}

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: 0rem;
	}

	.manageaccess-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: 1.4rem;
		background: ${(props) => props.theme.color[`white`]};
		border-top: 1px solid ${(props) => props.theme.color[`grey200`]};
	}

	.userlistitem.contextmenu .context {
		cursor: pointer;
	}
`;

export default ManageAccess;
