import styled from 'styled-components';

//engagement detail page style
const Headerbody = styled.section`
	// common for table UI start

	.userlistwrapper {
		display: inline-block;
		width: 100%;

		.manageaccessitemview {
			width: 100%;
			font-size: 1.4rem;
			display: flex;
			border-collapse: collapse;
			align-items: center;
			justify-content: center;
			border: 0.1rem solid ${(props) => props.theme.color[`grey200`]};

			&.inactive mgt-person {
				color: ${(props) => props.theme.color[`grey300`]};
			}

			&:not(.inactive) .userName {
				color: ${(props) => props.theme.color[`grey500`]};
			}

			.grid {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-gap: 0 10px;
			}
		}

		.userlist-header {
			width: 100%;
			display: inline-block;
			.userlistheader {
				width: 100%;
				font-size: 1.4rem;
				color: ${(props) => props.theme.color[`grey450`]};
				font-weight: bold;
				display: flex;
				border-collapse: collapse;
				background: ${(props) => props.theme.color[`grey100`]};
				border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
			}
		}

		.userlist-body {
			overflow: auto;
			height: calc(100vh - 30.8rem);
			display: inline-block;
			width: 100%;
			border: 0.1rem solid ${(props) => props.theme.color[`grey200`]};

			.userlistholder {
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
			}
		}

		.userlist-header,
		.userlist-body {
			.userlistitem {
				padding: 1rem;
				flex: 1 1 auto;
				width: 10%;
				vertical-align: middle;
				display: inline-block;
				&.archiveretry{
					width: 8%;
					text-align: center;
					.motif-tooltip-wrapper {
						width: 30px;
					}
				}
				&.independence{
					width: 8%;
					text-align: center;
					.motif-tooltip-wrapper {
						width: 30px;
					}
				}
				&.isprofilecomplete{
					.motif-tooltip-wrapper {
						width: 30px;
					}
				}

				.e-country-dd .react-select__control {
					min-height: 4.2rem;

					.react-select__single-value {
						padding-top: 0.8rem;
					}
				}

				.motif-tooltip-wrapper {
					width: 100%;

					.motif-tooltip-trigger-wrap {
						display: block !important;

						.motif-form-field {
							margin: 0;

							.motif-typeahead {
								height: 4rem;
								display: grid;
								.motif-input {
									font-size: 1.4rem;
									padding-top: 0;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									padding-right: 3rem;
								}
							}
						}
					}
				}

				.motif-form-field-date-input {
					margin-bottom: 0;
					.motif-label {
						font-size: 1.4rem;
						padding-top: 0.2rem;
					}

					.motif-date-picker-wrapper{
						width: auto;
					}

					.react-date-picker {
						height: 4rem;
						button[disabled] {
							background: none;
						}
					}
				}
			}
		}
	}

	// common for table UI end

	&.engagementpageview .userlistwrapper {
		.userlist-body {
			height: auto;
			overflow: visible;
			border: 0;

			.userlistholder .manageaccessitemview{
				padding: 2rem 0 0 0;

				.react-date-picker__inputGroup{
					margin-top: 0.5rem!important;
					& > *{
						font-size: 1.3rem!important;
					}
				}

				.grid{
					height: 6rem;
					padding: 0 1rem !important;

					&.yearend{
						line-height: 4rem;
					}

					&.status{
						.react-select__control{
							height: 3rem;
								.react-select__single-value{
									padding-top: 0;
									line-height: normal;
								}
						}
					}

					&.isprofilecomplete,
					&.independence,
					&.archiveretry{
						line-height: 5rem;
					}

					&.isprofilecomplete,
					&.independence{
						.motif-toggle-switch-wrapper{
							display: block;
						}
					}
					
					&.independence{
						text-align: center;
					}
					
					&.archiveretry{
						text-align: center;
						svg{
							fill: ${(props) => props.theme.color[`grey500`]};
							width: 2.2rem;
							height: 2.2rem;
							cursor: pointer;
						}

						.disabled{
							pointer-events: none;						
						}

						.disabled svg{
							pointer-events: none;
							fill: ${(props) => props.theme.color[`grey300`]};
							cursor: default;							
						}
					}

					.motif-error-message {
						font-size: 1.1rem;
					}
				}
			}

			.userlistholder{
				.engagementrow.loadingstyle{
					width: 100%;
					margin: 0 auto;
					display: inline-flex;
					border: 0.1rem solid ${(props) => props.theme.color[`grey200`]};
		
					& > section{
						margin: 1.3% auto;
					}
				}
			}

			.savereset{
				font-size: 1.2rem;
				margin: 2rem 0;
				display: flex;	
				.motif-button{
					font-size: 1.2rem;
					margin-right: 1rem;
					height: 4rem;
				}
			}
		}
`;

export default Headerbody;
