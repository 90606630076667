import * as types from '../../../actions/actiontypes';

export default function userSearchReducer(state = {}, action) {
	switch (action.type) {
		case types.GET_USER_SEARCH: {
			return action.response == undefined ? null : action.response;
		}
		default:
			return state;
	}
}
