import * as types from './actiontypes';

import {urls} from '../util/uiconstants';

export function getEngagementOverview(
	herculesAppURL,
	herculesAudience,
	classicCanvasAppURL,
	audience,
	isLDCUpgraded,
	engagementId,
	includeHelixProjects = false,
	includeAlerts = false,
	includeMetrics = false,
	includeTaskProgress = false
) {
	if (!isLDCUpgraded) {
		const classicCanvasURL = `${classicCanvasAppURL}/api/v2/${urls.TIMELINE_URL}?engagementid=${engagementId}`;
		return {
			type: types.GET_ENGAGEMENT_OVERVIEW,
			meta: {
				api: {
					url: classicCanvasURL,
					method: 'GET',
					// For in-country these headers are required.
					headers: {engagementid: engagementId, datasource: 'primary'},
					audience: audience
				}
			}
		};
	} else {
		const overviewURL = `${herculesAppURL}/api/Engagements/${engagementId}/overview?`;
		const queryParams = `includeHelixProjects=${includeHelixProjects}&includeAlerts=${includeAlerts}&includeMetrics=${includeMetrics}&includeTaskProgress=${includeTaskProgress}`;
		return {
			type: types.GET_ENGAGEMENT_OVERVIEW,
			meta: {
				api: {
					url: overviewURL + queryParams,
					method: 'GET',
					audience: herculesAudience
				}
			}
		};
	}
}
