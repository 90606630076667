/**
 * Created by calhosh on 7/13/2017.
 */
import React from 'react';
import ReactSelect from '@ey/react-select';

export default function SelectDropdown(props) {
	const {
		searchInLabelOrValue,
		airaLabelValue,
		blurInputOnSelect,
		className,
		closeMenuOnScroll,
		closeMenuOnSelect,
		defaultMenuIsOpen,
		defaultValue,
		formatOptionLabel,
		formLabel,
		groupHeading,
		id,
		isClearable,
		isDisabled,
		isMulti,
		isSearchable,
		menuIsOpen,
		name,
		onBlur,
		onLabelClickSetFocus,
		onInputKeyDown,
		openMenuOnFocus,
		options,
		placeholder,
		errorMessage,
		showErrorMessage,
		onChange,
		onFocus,
		value,
		noOptionsMessage,
		footer
	} = props;

	return (
		<ReactSelect
			searchInLabelOrValue={searchInLabelOrValue}
			airaLabelValue={airaLabelValue}
			blurInputOnSelect={blurInputOnSelect}
			className={className}
			closeMenuOnScroll={closeMenuOnScroll}
			closeMenuOnSelect={closeMenuOnSelect}
			defaultMenuIsOpen={defaultMenuIsOpen}
			defaultValue={defaultValue}
			formatOptionLabel={formatOptionLabel}
			groupHeading={groupHeading}
			id={id}
			isClearable={isClearable}
			isDisabled={isDisabled}
			isMulti={isMulti}
			isSearchable={isSearchable}
			menuIsOpen={menuIsOpen}
			name={name}
			openMenuOnFocus={openMenuOnFocus}
			options={options}
			placeholder={placeholder}
			errorMessage={errorMessage}
			onInputKeyDown={onInputKeyDown}
			onLabelClickSetFocus={onLabelClickSetFocus}
			showErrorMessage={showErrorMessage}
			formLabel={formLabel}
			onBlur={onBlur}
			onChange={onChange}
			onFocus={onFocus}
			value={value}
			noOptionsMessage={noOptionsMessage}
			footer={footer}
		/>
	);
}
