import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

export default function LanguagesReducer(
	//state = initialState.<%=names.camel%>,//TODO: uncomment this. Get from initialState.
	state = initialState.languages, //{Workspaces: {}},
	action
) {
	switch (action.type) {
		case types.GET_LANGUAGES:
			return action.response;
		default:
			return state;
	}
}
