import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {MotifButton} from '@ey-xd/motif-react';
import {labels} from '../../../../util/uiconstants';
import Loader from '../../../Common/Loaders/loadingindicator';
import env from '../../../../util/env';
import {deleteTemplate} from '../../../../actions/admin/templatemanagementaction';
import styled from 'styled-components';
import TicketField from '../../common/ticketfield';
import {getLocalizedAPIError} from '../../common/util';
import Toast from '../../../Common/Toast/Toast';

const Deletetemplate = styled.section`
	.modal-footer {
		width: 100%;
		padding: 2rem;
		display: inline-flex;
		button {
			font-size: 1.4rem;
			padding: 1.2rem 1.5rem;
			margin-right: 1rem;
			line-height: normal;
		}
	}

	.loadingstyle {
		height: 18rem !important;
	}

	.deletetemplate {
		.message {
			padding: 1rem 2rem;
		}

		//ticketstart
		.ticketdetail{
			margin: 0 2rem 2rem 2rem;
			padding-bottom: 2rem;
			h5{
				font-size: 1.4rem;
				font-weight: normal;
				color: ${(props) => props.theme.color[`grey500`]};
				border: 0!important;
			}

			.motif-form-field{
				width: 29rem;
			}

			.motif-form-field:not(.motif-form-field-has-value),
			.motif-form-field:not(.motif-form-field-focused){
				.motif-label{
					font-size: 1.4rem;
					font-weight:normal;
					margin-top: 0.6rem;
					color: ${(props) => props.theme.color[`black`]};
				}
			}

			.motif-form-field-has-value,
			.motif-form-field-focused{
				.motif-label{
					font-size: 1.2rem!important;
					font-weight: normal!important;
					margin-top: 0.2rem!important;
					color: ${(props) => props.theme.color[`black`]};
				}
			}
			
			
			.motif-input {
				font-size: 1.4rem;
				line-height: 3rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				color: ${(props) => props.theme.color[`black`]};
				&::placeholder {
					font-size: 1.4rem;
				}
			}
		}
		//ticketend

		.confirmationmessage {
			h5 {
				font-size: 1.4rem;
				font-weight: bold;
			}
		}

		.template {
			font-size: 1.4rem;
			font-weight: normal;
			margin-top: 2rem;
			h6 {
				font-size: 1.4rem;
				font-weight: normal;
				line-height: 1.8rem;
			}
		}
	}
`;

function TemplateDelete(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);
	const dispatch = useDispatch();

	const onTicketChanged = (e) => {
		setTicketNumber(e.target.value);
	}

	const onDeleteClick = () => {
		setIsSubmitClicked(true);
		if (ticketNumber.trim().length <= 0) {
			return;
		}

		let uriTicketNumber = encodeURIComponent(ticketNumber.trim());

		setIsLoading(true);
		let deleteTemplateUrl =
			env.getURL('serviceUrlV2') +
			'admin/engagementtemplates/' +
			props.deleteTemplateData?.copyRequestId +
			'?ticketNumber=' + uriTicketNumber;
		dispatch(deleteTemplate(deleteTemplateUrl))
			.then(() => {
				props.setIsTemplateDeleted(true);
			})
			.catch((e) => {
				setErrorMessage(getLocalizedAPIError(e));
			})
			.finally(() => {
				setIsLoading(false);
				setIsSubmitClicked(false);
				props.closeEditTemplateModal();
			});
	};

	return (
		<Deletetemplate>
			<section
				className="toastMessage"
				style={{
					display:
						errorMessage && errorMessage.length > 0
							? 'block'
							: 'none'
				}}
			>
				<Toast
					variant="error"
					onClose={() => setErrorMessage(false)}
				>
					{errorMessage}
				</Toast>
			</section>
			{isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<section className="deletetemplate">
					<section className="message">
						<section className="confirmationmessage" aria-label="message">
							<h5>{labels.templateDeleteConfirmationMessage}</h5>
						</section>

						<section className="template name" aria-label="templatename">
							<h6>{props?.deleteTemplateData?.templateName}</h6>
						</section>
					</section>
					<TicketField
						ticketNumber={ticketNumber}
						onTicketChanged={onTicketChanged}
						showValidation={isSubmitClicked}
					/>
				</section>
			)}
			<section className="modal-footer">
				<MotifButton
					type="button"
					variant="primary-alt"
					disabled={ticketNumber.trim().length <= 0}
					onClick={() => onDeleteClick()}
				>
					{labels.templateEditDelete}
				</MotifButton>
				<MotifButton
					type="button"
					variant="secondary"
					onClick={() => props.closeEditTemplateModal()}
				>
					{labels.templateEditCancel}
				</MotifButton>
			</section>
		</Deletetemplate>
	);
}

export default TemplateDelete;
