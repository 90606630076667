/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {EYForm, FormComboBox} from '@ey/form';
import {labels} from '../../../../util/uiconstants';

const SubServiceLineList = (props) => {
	const {isDisabled, onChange, subserviceline, subservicelineOption} = props;

	const onSubServiceLineChange = (key, value) => {
		onChange(key, value);
	};

	return (
		<SubServiceLineContainer>
			<section>
				<EYForm
					id="subservicelineform"
					onChange={onSubServiceLineChange}
					onSubmit={() => {}}
					action="\"
				>
					<FormComboBox
						id="subserviceline"
						name="subserviceline"
						value={subserviceline}
						is_required
						help
						searchable
						disabled={isDisabled}
						placeholder={labels.subserviceline}
						options={
							subservicelineOption &&
							subservicelineOption.map((subserviceline) => {
								return {
									value: String(subserviceline.subservicelineid),
									label: String(subserviceline.subservicelinename)
								};
							})
						}
						errormessage={labels.subservicelinerequired}
					/>
				</EYForm>
			</section>
		</SubServiceLineContainer>
	);
};

export default SubServiceLineList;

const SubServiceLineContainer = styled.li`
	display: inline-flex;
	margin: 0;
`;
