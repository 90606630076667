import {MsalService} from '@ey/voltron-core';
import {
	START_IDLE_TIMER,
	START_LOGOUT,
	SHOW_IDLETIME_POPUP,
	RESET_IDLE_TIMER,
	LOGOUT_USER
} from '../actions/actiontypes';
import * as TimeoutActions from '../actions/timeoutactions';

const idleLogoutMiddleware = (config) => (store) => (next) => (action) => {
	const iseyuser =
		store.getState().userSettings.data &&
		store.getState().userSettings.data.iseyuser;

	if (_.isBoolean(iseyuser) && iseyuser) {
		return next(action);
	}

	// This middleware is used to detect if any action is performed by the user
	// First handle logout idle user scenario
	if (action.type === START_LOGOUT) {
		action.showPopup = false;
		action.startLogout = true;
		return next(action);
	}

	if (action.type === LOGOUT_USER) {
		setTimeout(() => {
			const authService = new MsalService(config);
			authService.logOut();
		}, 200);
		return;
	}

	//Handle the IDLETIME_POPUP action
	//return the action to go to the next middleware or reducer and avoid continuing
	if (action.type === SHOW_IDLETIME_POPUP) {
		action.showPopup = true;
		let logoutTimeoutID = setTimeout(() => {
			console.log('Auto Logout called');
			setTimeout(() => {
				store.dispatch(TimeoutActions.logoutUser());
			}, 2000);
		}, config.logout_time);
		action.logoutTimeoutID = logoutTimeoutID;
		action.logoutTimestamp = Date.now();
		return next(action);
	}

	// So if it is anything other than START_IDLE_TIMER dispatch an action to start timer
	if (!(action.type === START_IDLE_TIMER)) {
		if (action.type === RESET_IDLE_TIMER) {
			action.showPopup = false;
			action.startLogout = false;
			let currentState = store.getState();
			let existingLogoutTimeoutID =
				(currentState.sessionTimer &&
					currentState.sessionTimer.logoutTimeoutID) ||
				'';
			if (existingLogoutTimeoutID) {
				clearTimeout(existingLogoutTimeoutID);
			}
			action.logoutTimeoutID = null;
			action.logoutTimeStamp = null;
		} else {
			store.dispatch(TimeoutActions.startIdleTimer());
			return next(action);
		}
	}

	//First Clean up the current timeout
	//Read existing timeoutID from store
	let currentState = store.getState();
	let existingTimeoutID =
		(currentState.sessionTimer && currentState.sessionTimer.timeoutID) || '';
	//Clear exisitng timeout
	//Condition for exisiting Timeout ID for init scenario
	if (existingTimeoutID) {
		clearTimeout(existingTimeoutID);
	}

	//Start the new timeout
	let timeoutID = setTimeout(() => {
		store.dispatch(TimeoutActions.showIdletimePopup());
	}, config.idle_time);
	let startTime = Date.now();
	action.timeoutID = timeoutID;
	action.startTime = startTime;

	return next(action);
};

export default idleLogoutMiddleware;
