/* eslint-disable react/no-multi-comp */
/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import ActiveEngagement from './myengagementitem';
import ErrorEngagement from './myengagementerroritem';
import ArchiveEngagement from './archiveengagementitem';
import Skeleton from 'react-loading-skeleton';
import {
	EngagementStatus,
	EngagementAccessType
} from '../../util/enumerations.js';
import IncompleteEngagementItem from './myengagementincomplete';
import IndependenceOnlyEngagementItem from './independenceOnlyEngagementItem';
import ReviewEngagementItem from './reviewengagement';
import {showLinks} from '../../util/uiconstants';

const EngagementContainer = styled.li`
	flex-grow: 1;
	margin: 2rem 0;
`;

const EngagementList = (props) => {
	const {
		workspace,
		workspaceID,
		clientId,
		clientName,
		clientCode,
		refreshDashboard,
		archiveretryshortcutkey,
		engagements
	} = props;

	const LoadEngagementsBasedOnEngStatus = (engagement) => {
		switch (engagement.data.engagementstatus) {
			case EngagementStatus.ArchiveError:
				return (
					<ErrorEngagement
						key={engagement.id + engagement.data.engagementstatus}
						workspace={workspace}
						workspaceID={workspaceID}
						clientId={clientId}
						clientName={clientName}
						clientCode={clientCode}
						refreshDashboard={refreshDashboard}
						archiveretryshortcutkey={archiveretryshortcutkey}
						hasengagementusernotification={
							engagement.data.hasengagementusernotification
						}
						{...engagement}
					/>
				);
			case EngagementStatus.Archived:
				return (
					<ArchiveEngagement
						key={engagement.id + engagement.data.engagementstatus}
						workspace={workspace}
						workspaceID={workspaceID}
						clientId={clientId}
						clientName={clientName}
						clientCode={clientCode}
						{...engagement}
					/>
				);
			case EngagementStatus.ExternalReviewEngagement:
			case EngagementStatus.CanvasExternalAccessPortalV2:
				return (
					<ReviewEngagementItem
						key={engagement.id}
						workspace={workspace}
						workspaceID={workspaceID}
						clientId={clientId}
						clientName={clientName}
						clientCode={clientCode}
						refreshDashboard={refreshDashboard}
						isReviewWorksapce
						{...engagement}
					/>
				);
			default:
				return (
					<ActiveEngagement
						key={engagement.id + engagement.data.engagementstatus}
						workspace={workspace}
						workspaceID={workspaceID}
						clientId={clientId}
						clientName={clientName}
						clientCode={clientCode}
						archiveretryshortcutkey={archiveretryshortcutkey}
						refreshDashboard={refreshDashboard}
						{...engagement}
					/>
				);
		}
	};

	const LoadEngagement = (engagement) => {
		const IsProfileComplete = engagement.data.engagementisprofilecomplete;
		const IsEngagementAccessible = engagement.data.isengagementaccessible;
		const EngStatusId = engagement.data.engagementstatus;
		const isValidEngStatus =
			EngStatusId === EngagementStatus.Active ||
			EngStatusId === EngagementStatus.Restored ||
			EngStatusId === EngagementStatus.MarkedForDeletion ||
			EngStatusId === EngagementStatus.ContentDeliveryInProgress;
		if (isValidEngStatus && (!IsProfileComplete || !IsEngagementAccessible)) {
			let showLink = showLinks.HideLink;

			if (
				!IsProfileComplete &&
				EngStatusId != EngagementStatus.ContentDeliveryInProgress &&
				engagement.data.engagementaccesstypeid === EngagementAccessType.Default
			) {
				showLink = showLinks.ProfileSubmit;
			} else if (!IsEngagementAccessible) {
				showLink = showLinks.CompleteIndependence;
			}

			if (
				engagement.data.engagementaccesstypeid === EngagementAccessType.Default
			) {
				return (
					<IncompleteEngagementItem
						key={engagement.id}
						workspace={workspace}
						workspaceID={workspaceID}
						clientId={clientId}
						clientName={clientName}
						clientCode={clientCode}
						{...engagement}
						incomplete={showLink}
						refreshDashboard={refreshDashboard}
						isReviewWorksapce={false}
					/>
				);
			} else if (
				engagement.data.engagementaccesstypeid ===
					EngagementAccessType.IndependenceOnly &&
				EngStatusId != EngagementStatus.Archived
			) {
				return (
					<IndependenceOnlyEngagementItem
						key={engagement.id}
						workspace={workspace}
						workspaceID={workspaceID}
						clientId={clientId}
						clientName={clientName}
						clientCode={clientCode}
						{...engagement}
						incomplete={showLink}
						refreshDashboard={refreshDashboard}
						isReviewWorksapce={false}
					/>
				);
			}
		} else if (
			engagement.data.engagementaccesstypeid ===
				EngagementAccessType.IndependenceOnly &&
			EngStatusId != EngagementStatus.Archived
		) {
			let showLink = showLinks.CompleteIndependence;
			return (
				<IndependenceOnlyEngagementItem
					key={engagement.id}
					workspace={workspace}
					workspaceID={workspaceID}
					clientId={clientId}
					clientName={clientName}
					clientCode={clientCode}
					{...engagement}
					incomplete={showLink}
					refreshDashboard={refreshDashboard}
					isReviewWorksapce={false}
				/>
			);
		} else {
			return LoadEngagementsBasedOnEngStatus(engagement);
		}
	};

	return (
		<EngagementContainer>
			{LoadEngagement(engagements) || (
				<Skeleton width={400} height={20} count={5} />
			)}
		</EngagementContainer>
	);
};

export default EngagementList;
