import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MotifCard} from '@ey-xd/motif-react';
import {SVGICONS} from '@ey/icons';
import styled from 'styled-components';
import {labels, successMessageDisplayDuration} from '../../../util/uiconstants';
import {Link} from 'react-router-dom';
import {getMeCall} from '../../../actions/admin/mepermissionsactions';
import AccessDeniedMessage from '../../Common/AccessDeniedMessage/AccessDeniedMessage';
import {
	meCallPermissions,
	engagementsSearchRetainFlag
} from '../../../util/uiconstants';
import env from '../../../util/env';
import {postSyncDatabaseLocator} from '../../../actions/admin/databaselocatoractions';
import {getLocalizedAPIError} from '../common/util';
import {setIsModalOpen} from '../../../actions/modalstateactions';
import {clearErrors} from '../../../actions/erroractions';
import Toast from '../../Common/Toast/Toast';
import {MotifIcon} from '@ey-xd/motif-react';
import {notificationIcSync24px} from '@ey-xd/motif-react/assets/icons';

const Dashboardwrapper = styled.section`
	padding: 0 3.4rem 3rem 3.4rem;

	&.wrapper {
		position: relative;
		.toast {
			position: absolute;
			width: calc(100% - 6.8rem);
			.motif-toast-text-container {
				font-size: 1.2rem;
			}
		}
	}

	.synclocator {
		display: flex;
		margin-top: 2rem;

		h2 {
			font-size: 2.4rem;
			margin: 0;
			margin-right: auto;
		}

		.titleholder {
			text-align: right;
			line-height: 2.9rem;
			a {
				cursor: pointer;
				&:hover {
					text-decoration: none;
				}

				.title {
					font-size: 1.6rem;
					font-weight: bold;
					color: ${(props) => props.theme.color[`blue600yellow400`]};
				}

				.motif-icon {
					margin-right: 0.5rem;
					vertical-align: middle;
					svg {
						fill: ${(props) => props.theme.color[`blue600yellow400`]};
						vertical-align: text-bottom;
					}
				}
			}
		}
	}

	h1 {
		color: ${(props) => props.theme.color[`grey500`]};
	}

	.link-holder {
		margin: 3rem 0;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: 5rem;

		.motif-card {
			border-radius: 0.4rem;
			margin-bottom: 5rem;
			display: flex;

			a {
				width: 100%;
			}

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				cursor: pointer;
			}

			&:hover .sub-item svg {
				fill: ${(props) => props.theme.color[`blue600yellow400`]};
			}

			&:hover .card-name {
				color: ${(props) => props.theme.color[`blue600yellow400`]};
			}

			.item {
				width: 17%;
				height: 24rem;
				display: table-cell;
				vertical-align: middle;
				.sub-item {
					justify-content: center;
					display: flex;
					width: 100%;

					svg {
						min-width: 6rem;
						min-height: 6rem;
					}

					.card-name {
						font-size: 1.6rem;
						font-weight: bold;
						margin: 1rem 0;
						text-align: center;
					}
				}
			}
		}
	}
`;

function Dashboard() {
	const userPermissions = useSelector((store) => store.userPermissions);

	const [successMessage, setSuccessMessage] = useState();
	const [errorMessage, setErrorMessage] = useState();

	const successMessageDuration = successMessageDisplayDuration;

	let dispatch = useDispatch();

	useEffect(() => {
		localStorage.setItem(
			engagementsSearchRetainFlag.IsRetainEngagementsSearchResult,
			false
		);
		dispatch(getMeCall());
	}, []);

	const dashboardCards = [
		{
			iconName: 'engagements',
			tooltipText: 'Engagements',
			cardName: labels.engagementCard,
			permissions: [
				meCallPermissions.hasEngagementDefaultRole,
				meCallPermissions.canReadEngagement,
				meCallPermissions.canUpdateEngagement,
				meCallPermissions.canInviteEngagementMember,
				meCallPermissions.canUpdateEngagementUser
			],
			path: '/admin/engagements'
		},
		{
			iconName: 'messaging',
			tooltipText: 'Messaging',
			cardName: labels.messagingCard,
			permissions: [
				meCallPermissions.canCreateUpdateMessaging,
				meCallPermissions.canDeleteMessaging,
				meCallPermissions.canReadMessaging
			],
			path: '/admin/messaging'
		},
		{
			iconName: 'templates',
			tooltipText: labels.templatesCard,
			cardName: labels.templatesCard,
			permissions: [
				meCallPermissions.canReadTemplates,
				meCallPermissions.canUpdateTemplates,
				meCallPermissions.canDeleteTemplates
			],
			path: '/admin/template'
		},
		{
			iconName: 'permission-groups',
			tooltipText: 'Permission groups',
			cardName: labels.permissionGroupCard,
			permissions: [
				meCallPermissions.canReadPermissionsGroups,
				meCallPermissions.canCreateUpdatePermissionsGroups,
				meCallPermissions.canSyncPermissionsGroups
			],
			path: '/admin/permissiongroups'
		},
		{
			iconName: 'manage-access',
			tooltipText: 'Manage access',
			cardName: labels.manageAccessCard,
			permissions: [
				meCallPermissions.canReadManageAccess,
				meCallPermissions.canCreateUpdateManageAccess
			],
			path: '/admin/manageaccess'
		},
		{
			iconName: 'cache',
			tooltipText: 'Cache',
			cardName: 'Cache',
			permissions: [meCallPermissions.canReadDeleteCache],
			path: '/admin/cache'
		},
		{
			iconName: 'doc-icon',
			tooltipText: 'Documents',
			cardName: 'Documents',
			permissions: [meCallPermissions.canAccessDocuments],
			path: '/admin/documents'
		}
	];
	
	useEffect(() => {
		if (successMessage) {
			var timer = setTimeout(() => {
				setSuccessMessage('');				
			}, successMessageDuration);

			return () => clearTimeout(timer);
		}
	}, [successMessage]);

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	const onDBLocatorSyncClick = () => {
		const url =
			env.getURL('serviceUrlV2') + 'admin/dblocatorsynchronizationrequests';
		dispatch(postSyncDatabaseLocator(url))
			.then(() => {
				clearError(true);
				setSuccessMessage(labels.databaseLocatorSuccessMessage);
			})
			.catch((e) => {
				clearError(true);
				setErrorMessage(getLocalizedAPIError(e));
			});
	};

	const hasUserPermission = (cardInfo) => {
		if (
			userPermissions &&
			userPermissions.permissions &&
			userPermissions.permissions?.hasAdminDefaultRole
		) {
			let permKeys = Object.keys(userPermissions.permissions);
			let neededPerms = cardInfo.permissions;
			let allowedRoles = permKeys.filter((pkey) =>
				neededPerms.some((n) => n == pkey && userPermissions.permissions[pkey])
			);
			return allowedRoles.length;
		}
		return false;
	};

	const getContent = () => {
		let allowedCards = dashboardCards.filter((item) => hasUserPermission(item));
		return !allowedCards.length ? (
			<AccessDeniedMessage />
		) : (
			allowedCards.map((item, index) => (
				<MotifCard key={item.cardName + index}>
					<Link to={item.path}>
						<section className="item">
							<section className="sub-item">
								<SVGICONS
									styleName="large"
									name={item.iconName}
									hoverText={item.tooltipText}
								/>
							</section>
							<section className="sub-item">
								<span className="card-name">{item.cardName}</span>
							</section>
						</section>
					</Link>
				</MotifCard>
			))
		);
	};

	return (
		<Dashboardwrapper className="mainResponsiveWrapper wrapper">
			<section className="toast">
				{successMessage && (
					<Toast
						variant="success"
						duration={successMessageDuration}
						onClose={() => setSuccessMessage(undefined)}
					>
						{successMessage}
					</Toast>
				)}
				{errorMessage && (
					<Toast variant="error" onClose={() => setErrorMessage(undefined)}>
						{errorMessage}
					</Toast>
				)}
			</section>
			<section className="synclocator">
				<h2>
					<span>{labels.welcome}</span>
					<span>
						<b>{labels.welcomeadmin}</b>
					</span>
				</h2>
				<section className="titleholder">
					{userPermissions?.permissions?.canSyncDatabaseLocator && (
						<a onClick={onDBLocatorSyncClick}>
							<MotifIcon src={notificationIcSync24px} />
							<span className="title">{labels.databaseLocatorCard}</span>
						</a>
					)}
				</section>
			</section>
			<section className="link-holder">{getContent()}</section>
		</Dashboardwrapper>
	);
}
export default Dashboard;
