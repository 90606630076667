import * as types from './actiontypes';

export function getNotifications(url, resource) {
	return {
		type: types.GET_NOTIFICATIONS,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				}
			}
		}
	};
}

export function setCloseNotification(show = false) {
	return {
		type: types.SET_CLOSE_NOTIFICATION,
		resource: show
	};
}

export function removeNotification(engagementId) {
	return {
		type: types.REMOVE_NOTIFICATION,
		engagementId: engagementId
	};
}
