/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import env from '../../util/env';
import {SVGICONS} from '@ey/icons';
import {MotifButton} from '@ey-xd/motif-react';
import FavButton from './favoritesbutton';
import CogWheel from './cogwheel';
import {tabTypes, urls} from '../../util/uiconstants';
import {EngagementStatus} from '../../util/enumerations';
import {EngUserModelPatchFavorites} from '../../util/preparemodel';
import {labels, showLinks} from '../../util/uiconstants';
import EllipsesControl from '@ey/ellipses-control';
import EngagementRequestDeleteModal from './engagementRequestDeleteModal';
import Moment from 'react-moment';
import {markUnmarkFavorite} from '../../actions/engagementuseractions';
import {getNotifications} from '../../actions/notificationsactions';
import WorkspaceGroupAudit from './workspaceGroupAudit';
import {
	IndependenceStatus,
	EngagementAccessType
} from '../../util/enumerations';

const IncompleteEngagementItem = (props) => {
	const dispatch = useDispatch();
	const {
		data,
		id,
		clientId,
		clientName,
		workspace,
		workspaceID,
		refreshDashboard,
		incomplete,
		isReviewWorksapce
	} = props;
	const {
		isfavorite,
		incountryuri,
		workspacecountryid,
		engagementdesc,
		engagementversion,
		engagementstatus
	} = data;

	const selectedTab = useSelector((store) => store.dashboard.activeTab);
	const apponlinestate = useSelector((store) => store.apponlinestate);
	const notifications = useSelector((store) => store.notifications);

	const [isFavInProgress, setIsFavInProgress] = useState(false);
	const [showRequestDeleteModalState, setShowRequestDeleteModalState] =
		useState(false);
	const [fav, setFav] = useState(isfavorite);
	const [readyForFavClick, setReadyForFavClick] = useState(true);

	const engagementurl =
		incountryuri +
		'/v1redirect/index?' +
		'countryid=' +
		workspacecountryid +
		'&engagementid=' +
		id +
		'&engagementdesc=' +
		encodeURIComponent(engagementdesc.substring(0, 50)) +
		'&clientid=' +
		clientId +
		'&clientname=' +
		encodeURIComponent(clientName) +
		'&workspacedesc=' +
		encodeURIComponent(workspace.workspacedesc.trim()) +
		'&workspaceid=' +
		workspaceID +
		'&engagementversion=' +
		encodeURIComponent(engagementversion) +
		'&userPrefLang=en-us';
	const [notice, setNotice] = useState(null);

	let hideComponent = false;
	let classNameFavProgress = 'arrow-right';

	useEffect(() => {
		getNotice();
		if (selectedTab === tabTypes.FavoritesTab && fav === false) {
			hideComponent = true;
		}
	}, []);

	const getNotice = async () => {
		if (data && engagementstatus != EngagementStatus.MarkedForDeletion) {
			return;
		}

		let url = env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;
		try {
			await dispatch(getNotifications(url));
		} finally {
			if (notifications && notifications.notificationdetails) {
				const notice = notifications.notificationdetails.find(
					(notice) => notice.actionType == 4 && notice.engagementId == id
				);
				setNotice(notice);
			}
		}
	};

	const showRequestDeleteModal = () => {
		setShowRequestDeleteModalState(true);
	};

	const hideRequestDeleteModal = () => {
		setShowRequestDeleteModalState(false);
	};

	const doRefreshDashboard = () => {
		refreshDashboard();
	};

	const showTimeline = () => {
		return false;
	};

	const onFavClick = async () => {
		setReadyForFavClick(false);
		setIsFavInProgress(true);
		let isFavTab = selectedTab === tabTypes.FavoritesTab;
		// Add favourite-inprogress class to arrow-right container when api call is in progress
		try {
			await dispatch(
				markUnmarkFavorite(
					env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
					EngUserModelPatchFavorites(id, !fav, 'markunmarkfavoriteengagement')
				)
			);
			setIsFavInProgress(false);
			setFav(!fav);
			setReadyForFavClick(true);
		} finally {
			if (isFavTab) {
				doRefreshDashboard();
			}
		}
	};

	const dblClick = () => {};

	const getIncountryURL = (navigateToPage) => {
		return (
			incountryuri +
			navigateToPage +
			'countryid=' +
			workspacecountryid +
			'&engagementid=' +
			id +
			'&engagementdesc=' +
			encodeURIComponent(engagementdesc.substring(0, 50)) +
			'&userPrefLang=en-us&workspacedesc=' +
			encodeURIComponent(workspace.workspacedesc.trim()) +
			'&workspaceid=' +
			workspaceID +
			'&engagementversion=' +
			Math.floor(Number.parseInt(engagementversion)) +
			'&engagementversionfull=' +
			engagementversion
		);
	};

	const routeToCompleteProfile = () => {
		let url = '/v1redirect/engagementprofile?';
		window.location.href = getIncountryURL(url);
	};

	const routeToCompleteIndependence = () => {
		let url = '/v1redirect/index?';
		let independenceurlpage = getIncountryURL(url) + '&showindependence=true';
		window.location.href = independenceurlpage;
	};

	const incompeletetabs = () => {
		if (incomplete === showLinks.ProfileSubmit && apponlinestate) {
			return (
				<section>
					<MotifButton variant="text-alt" onClick={routeToCompleteProfile}>
						{labels.completeprofileheader}
					</MotifButton>
				</section>
			);
		} else if (
			incomplete === showLinks.CompleteIndependence &&
			apponlinestate
		) {
			return (
				<section>
					<MotifButton variant="text-alt" onClick={routeToCompleteIndependence}>
						{labels.completeindependenceheader}
					</MotifButton>
				</section>
			);
		} else return null;
	};

	const IsRestoredEngagement = () => {
		return engagementstatus === EngagementStatus.Restored ? true : false;
	};

	const IsContentDeliveryInprogressEngagement = () => {
		return engagementstatus === EngagementStatus.ContentDeliveryInProgress
			? true
			: false;
	};

	return (
		<>
			{!hideComponent && (
				<EngagementItemContainer
					className="eng-listwrapper"
					onDoubleClick={dblClick}
				>
					<EngagementItemDetails className="eng-listholder arcstatus-wrapper">
						{notice && (
							<MarkedDeleted className="deletion-status">
								<section className="deletionmsg">
									<SVGICONS hoverText="" styleName="large" name="delete" />
									<section className="deletionmsgholder">
										{labels.deleterequestbannertext1}
										{' ' + notice.displayuserName + ' '}
										<Moment local utc fromNow ago>
											{notice.displayDate}
										</Moment>
										{' ' + labels.deleterequestbannertext2}
										<a
											className="clickrespond"
											onClick={showRequestDeleteModal}
										>
											{labels.respond}
										</a>
									</section>
								</section>
							</MarkedDeleted>
						)}
						<section className="engagementInfo">
							<WorkspaceContent>
								<section className="clientname" title={clientName}>
									{(clientName === '!!!'
										? labels.invalidclientcode
										: clientName) || <Skeleton width={250} height={20} />}
								</section>
								<WorkspaceGroupAudit workspace={workspace} engagement={data} />
							</WorkspaceContent>
							{apponlinestate && (
								<Workspacemoreinfo>
									<section className="pull-right">
										<section className="eng-rightmenu">
											<section className="engagementid">
												ID: {id || <Skeleton width={250} height={20} />}
											</section>
										</section>
										{showTimeline() ? (
											<Timelinelegend>
												<section className="legendname">
													<SVGICONS
														hoverText=""
														styleName="small pull-left milestone-icon"
														name={'circle90'}
													/>
													<label>{labels.milestones}</label>
												</section>
												<section className="legendname">
													<SVGICONS
														hoverText=""
														styleName="small pull-left"
														name={'key-events'}
													/>
													<label>{labels.keyevents}</label>
												</section>
											</Timelinelegend>
										) : null}

										<WSEdit>
											<CogWheel
												doRefreshDashboard={doRefreshDashboard}
												{...props}
											/>
										</WSEdit>
									</section>
								</Workspacemoreinfo>
							)}
							<EngagementDetails className="engagementname">
								<h4>
									<section className="anchor" data-autoid={id}>
										<a href={engagementurl} className={'newengagement'}>
											<EllipsesControl
												content={
													engagementdesc || <Skeleton width={250} height={20} />
												}
												tooltip={
													engagementdesc || <Skeleton width={250} height={20} />
												}
												isTooltipAvailable
											/>
										</a>
									</section>
								</h4>
							</EngagementDetails>
						</section>
						{apponlinestate && (
							<section className="engagementUpdates">
								<section className="eng-actions">
									{isReviewWorksapce === true ? null : incompeletetabs()}
									<section className="restored-eng">
										{IsRestoredEngagement()
											? labels.restoredengagement
											: IsContentDeliveryInprogressEngagement()
											? labels.profiledeliveryinprogress
											: ''}
									</section>
								</section>
								<section className="faviconholder" />
								<section
									className={
										isFavInProgress
											? classNameFavProgress + ' ' + 'favourite-inprogress'
											: classNameFavProgress
									}
								/>
								<FavButton
									isFav={fav}
									onFavClick={onFavClick}
									readyForFavClick={readyForFavClick}
								/>
							</section>
						)}
					</EngagementItemDetails>
					<EngagementRequestDeleteModal
						key={notice}
						notification={notice}
						show={showRequestDeleteModalState}
						hide={hideRequestDeleteModal}
						{...props}
					/>
				</EngagementItemContainer>
			)}
		</>
	);
};
export default IncompleteEngagementItem;

const EngagementItemDetails = styled.section`
	box-shadow: 0px 4px 8px 0px ${(props) => props.theme.color[`grey250`]},
		1px -1px 8px 0px ${(props) => props.theme.color[`grey250`]};
	background-color: ${(props) => props.theme.color[`grey100`]};
	display: inline-block;
	border-left: 0.5rem solid ${(props) => props.theme.color[`blue300`]};
	width: 100%;	
	&.engagementerror-indicator {
		border-left: 0.5rem solid ${(props) => props.theme.color[`red600`]};
	}
	& .modal-wrapper {
		height: 30rem !important;
		width: 67.5rem !important;
	}

	& .editList {
		& ul {
			background-color: ${(props) => props.theme.color[`white`]};
			padding: 0;
			margin: 0;
			line-height: normal;

			& li {
				width: 100%;
				margin: 0;
				padding: 0;
				display: inline-block;
				font-size: 1.4rem;
				line-height: normal;

				& a,
				& span {
					text-decoration: none;
					color: ${(props) => props.theme.color[`grey500`]};
					width: 100%;
					display: inline-block;
					outline: none;
					font-weight: normal;
					padding: 0.8rem 1.6rem;
				}

				&:hover {
					background-color: ${(props) => props.theme.color[`grey250`]};
					cursor: pointer;
				}
			}
		}
	}

	& .engagementInfo {
		float: left;
		width: 100%;
		padding: 1.5rem 0 1rem 0;
		position: relative;

		& .engagementname .anchor.newengagement {
			color: ${(props) => props.theme.color[`grey300`]};
			pointer-events: none;
			cursor: default;
		}
	}

	& .timelinecontrol {
		float: left;
		width: 100%;
		padding-bottom: 1.5rem;
	}

	& .engagementUpdates {
		box-shadow: inset 0px 2px 4px 0px ${(props) => props.theme.color[`grey300`]};
		background: ${(props) => props.theme.color[`grey100`]};
		float: left;
		width: 100%;
		position: relative;
		& .faviconholder {
			float: right;
			position: relative;
			display: block;
		}
		& .eng-actions {
			display: inline-block;
			margin-top: 1rem;
			margin-left: 1rem;
			.motif-text-alt-button {
				font-size: 1.4rem;
				margin-left: 2rem;
				color: ${(props) => props.theme.color[`blue600`]};
				&:focus {
					box-shadow: none;
				}
				&:hover {
					border-color: ${(props) => props.theme.color[`blue600`]};
				}
			}
			.restored-eng {
				color: ${(props) => props.theme.color[`grey400`]};
				font-size: 1.4rem;
				font-weight: bold;
				margin: 0px 0px 0px 2rem;
			}
		}
		& .eng-actions > section {
			display: inline-block;
		}
		& .arrow-right {
			width: 0;
			height: 0;
			border-top: 4.5rem solid transparent;
			border-bottom: 0 solid transparent;
			border-right: 4.5rem solid ${(props) => props.theme.color[`grey500`]};
			float: right;
			margin-right: -0.2rem;
			margin-bottom: -0.2rem;
			box-shadow: 3px 3px 5px 0px ${(props) => props.theme.color[`grey300`]};
			&.favourite-inprogress {
				border-bottom: 0 solid transparent;
				border-right: 4.5rem solid ${(props) => props.theme.color[`grey400`]};
				pointer-events: none;
				opacity: 0.5;
				+ section svg {
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
`;

const Timelinelegend = styled.section`
	display: inline-block;
	color: ${(props) => props.theme.color[`grey400`]};

	& .legendname {
		margin-right: 1em;
		display: inline-block;

		& svg {
			fill: ${(props) => props.theme.color[`blue300`]};
			margin-right: 0.5rem;

			&.milestone-icon {
				transform: rotate(-20deg);
			}
		}

		& label {
			vertical-align: top;
			line-height: normal;
			font-weight: normal;
			font-size: 1.4rem;
			-webkit-user-select: none;
			-moz-user-select: none;
			user-select: none;
		}
	}
`;

const EngagementDetails = styled.section`
	width: 100%;
	display: inline-block;
	vertical-align: super;
	& svg {
		vertical-align: middle;
		cursor: pointer;
		fill: ${(props) => props.theme.color[`grey400`]};
	}

	& h4 {
		display: inline-block;
		font-size: 1.6rem;
		line-height: normal;
		vertical-align: top;
		margin-top: -1rem;
		margin-bottom: 0;
		font-weight: bold;
		color: ${(props) => props.theme.color[`grey500`]};
		padding-left: 3rem;
		padding-right: 1rem;
		width: 100%;

		& section.anchor {
			color: ${(props) => props.theme.color[`grey500`]};
			vertical-align: middle;
			width: 60%;
			display: inline-flex;
			line-height: 4rem;
			max-width: 70%;
			pointer-events: none;

			& a {
				display: inline-flex;
				color: ${(props) => props.theme.color[`grey300`]};
				max-width: 100%;
			}
		}

		& .eng-rightmenu {
			line-height: 4rem;
			margin-right: 2rem;
			.motif-text-alt-button {
				font-size: 1.4rem;
				margin-left: 2rem;
				color: #1f58b8;
				&:focus {
					box-shadow: none;
				}
			}
			& .restored-eng {
				color: ${(props) => props.theme.color[`grey300`]};
				font-size: 1.4rem;
				margin: 0 0 0 2rem;
			}

			& > section {
				display: inline-block;
				float: right;
			}
		}

		& .ellipses {
			width: auto;
			max-width: none;
			display: inline-flex;
			color: ${(props) => props.theme.color[`grey500`]};
			& div {
				max-width: none;
			}
		}
	}

	& .engagementid {
		display: inline-block;
		font-size: 1.4rem;
		line-height: 4rem;
		margin: 0 5rem 0 2rem;
		color: ${(props) => props.theme.color[`grey400`]};
		vertical-align: text-top;
		font-weight: normal;
		float: right;
		width: 11rem;
	}
`;

const WorkspaceContent = styled.div`
	padding-left: 3rem;
	width: 67%;
	float: left;
	@media screen and (max-width: 1366px) {
		width: 61%;
	}
	@media screen and (min-width: 1367px) and (max-width: 1600px) {
		width: 61%;
	}
	.clientname,
	.workspace-name,
	.engagement-type,
	.engagement-typelinked {
		display: inline-block;
		font-size: 1.4rem;
		color: ${(props) => props.theme.color[`grey400`]};
		line-height: normal;
		max-width: 28%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: middle;
		padding-right: 0.3rem;
	}
	.workspace-name {
		max-width: 28%;
		padding-left: 0.3rem;
	}
	.engagement-type {
		max-width: 19%;
		padding-left: 0.3rem;
	}
	.engagement-typelinked {
		display: inline-block;
		max-width: 14%;
		padding-left: 0.3rem;
		.motif-icon svg {
			margin-top: -0.3rem;
			vertical-align: middle;
			margin-right: 0.3rem;
		}
		.engagementtype-linkedtxt {
			font-size: 1.4rem;
			color: ${(props) => props.theme.color[`grey400`]};
			line-height: normal;
		}
	}
`;

const Workspacemoreinfo = styled.section`
	padding-right: 1rem;
	padding-top: 0.6rem;
	width: 32%;
	float: right;
	line-height: normal;
	@media screen and (max-width: 1366px) {
		width: 38%;
	}
	@media screen and (min-width: 1367px) and (max-width: 1600px) {
		width: 38%;
	}
	& .eng-rightmenu {
		display: inline-block;
		& .restored-eng {
			color: ${(props) => props.theme.color[`grey300`]};
			font-size: 1.4rem;
		}

		& > section {
			display: inline-block;
		}
	}
	& .engagementid {
		display: inline-block;
		font-size: 1.4rem;
		margin: 0 5rem 0 1rem;
		color: ${(props) => props.theme.color[`grey400`]};
		font-weight: normal;
		float: right;
		width: 11rem;
		text-align: right;
	}
`;

const WSEdit = styled.div`
	flex-grow: 1;
	display: inline-block;
	float: right;
	margin-right: 1rem;
	svg {
		margin-top: -0.4rem;
		transform: rotate(90deg);
	}
`;

const EngagementItemContainer = styled.section`
	margin: 1.5rem 0;
`;

const MarkedDeleted = styled.section`
	& {
		padding: 0.2rem 0.5rem;
		font-size: 1.4rem;
	}

	& .deletionmsg {
		width: 95%;
		display: inline-block;
		line-height: 2rem;
		margin-top: 1rem;

		.deletionmsgholder {
			display: inline;
			vertical-align: text-top;
		}

		svg {
			fill: ${(props) => props.theme.color[`red600`]};
			vertical-align: middle;
			margin: 0 0.5rem 0 0;
			width: 2rem;
			height: 2rem;
		}

		.clickrespond {
			font-weight: bold;
			color: ${(props) => props.theme.color[`grey500`]};
			margin-left: 0.4rem;
			text-decoration: underline;
			cursor: pointer;
		}
	}
`;
