/**
 * This Component is intentionally light. It exists separately
 * to ease server side rendering.
 */
import React, {useEffect, useState} from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';
import Utility from '../util/utils';
import {settingsPreferences} from '../util/uiconstants';
import {getTheme} from '@ey/styled-theme';
import {ThemeProvider} from 'styled-components';
import App from './App';

function Routes(props) {
	const [darkMode, setDarkMode] = useState(false);

	useEffect(() => {
		let isDarkMode = Utility.isUserThemeDarkMode(props.userSettings?.data);

		Utility.setMotifTheme(
			isDarkMode
				? settingsPreferences.DarkTheme
				: settingsPreferences.LightTheme
		);
		setDarkMode(isDarkMode);
	}, [props.userSettings]);

	return (
		<>
			<ThemeProvider theme={getTheme(darkMode)}>
				<Route path="/" component={App} />
			</ThemeProvider>
		</>
	);
}

const mapState = (store) => {
	return {
		userSettings: store.userSettings
	};
};

export default connect(mapState, null)(Routes);
