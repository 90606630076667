import React from 'react';
import {labels} from '../../../../util/uiconstants';

function SelectedEngagementTableHeader() {
	return (
		<section className="userlist-header">
			<section className="userlistheader">
				<section className="userlistitem">{labels.yearEndTableHeader}</section>
				<section className="userlistitem">
					{labels.engagementdeleteDate}
				</section>
				<section className="userlistitem">{labels.archivalguid}</section>
				<section className="userlistitem">{labels.archivalLogId}</section>
				<section className="userlistitem">{labels.archivalFileguid}</section>
				<section className="userlistitem">{labels.statusTableHeader}</section>
				<section className="userlistitem">{labels.profile}</section>
				<section className="userlistitem independence">
					{labels.independence}
				</section>
				<section className="userlistitem archiveretry">{labels.archiveRetry}</section>
			</section>
		</section>
	);
}

export default SelectedEngagementTableHeader;
