import React from 'react';
import {labels} from '../../../resources/en-us';
import {MotifIcon} from '@ey-xd/motif-react';
import {actionIcLock24px} from '@ey-xd/motif-react/assets/icons';
import styled from 'styled-components';

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 6rem);

	p {
		margin-bottom: 0;
		color: ${(props) => props.theme.color[`grey500`]};

		&.title h5 {
			font-weight: bold;
		}

		&.description {
			font-size: 1.4rem;
		}
	}

	svg {
		width: 10rem !important;
		height: 10rem !important;
		margin-bottom: 1rem;
		fill: ${(props) => props.theme.color[`grey400`]}!important;
	}
`;
function AccessDeniedMessage(props) {
	return (
		<Wrapper>
			<p>
				<MotifIcon src={actionIcLock24px} />
			</p>
			<p className="title">
				<h5>{labels.noPermission}</h5>
			</p>
			<p className="description">
				{props.permissionMesage ? props.permissionMesage : labels.noAdminRights}
			</p>
		</Wrapper>
	);
}

export default AccessDeniedMessage;
