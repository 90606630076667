import React from 'react';
import Engagement from './engagement';

export default class EditEngagement extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		//return <section style={notifIn}>{this.props.title}</section>;
		return (
			<section>
				<Engagement showDrop={true} x={'ENG xyz'} y={'100001'} />
			</section>
		);
	}
}
