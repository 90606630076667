import * as types from '../actiontypes';

export function getCountries(url, resource) {
	return {
		type: types.GET_ALL_ENGAGEMENT_COUNTRIES,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data: {resource: resource}
			}
		}
	};
}

export function getAdminRoles(url, resource) {
	return {
		type: types.GET_ALL_ADMIN_ROLE,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data: {resource: resource}
			}
		}
	};
}

export function getSubServiceLines(url, resource) {
	return {
		type: types.GET_ALL_SUB_SERVICE_LINE,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data: {resource: resource}
			}
		}
	};
}

export function getRegion(url, resource) {
	return {
		type: types.GET_ALL_REGION,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data: {resource: resource}
			}
		}
	};
}

export function getEngagementRoles(url, resource) {
	return {
		type: types.GET_ALL_ENGAGEMENT_ROLES,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data: {resource: resource}
			}
		}
	};
}

export function getCountryDataCenters(url, resource) {
	return {
		type: types.GET_ALL_DATACENTERS,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data: {resource: resource}
			}
		}
	};
}
