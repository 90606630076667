import initialState from '../../initialstate';
import * as types from '../../../actions/actiontypes';
import {HttpStatusCodes} from '../../../util/uiconstants'

export function cacheReducer(state = initialState.cache, action) {
	switch (action.type) {
		case types.GET_CACHE:
			return action.response;
		case types.CLEAR_CACHE_RESULT:
			return '';
		default:
			return state;
	}
}
