import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const TimeTrackingLoaderdiv = styled.div`
	background: ${(props) => props.theme.color[`white`]};
	max-width: 2048px;
	margin: 0 auto;
	height:calc(100vh - 6rem);
	.timetracking-header {
		width: 100%;
		padding: 4rem 4rem 0 4rem;
		.skeleton-rectanglemargin {
			margin-bottom: 2rem;
			&.header1 {
				width:20%;
			}
			&.header2 {
				width:40%;
			}
		}
		.timetracking-header1 {
			width: 100%;
			margin-bottom: 1rem;
			.skeleton-Headerleft {
				width: 40%;
				display: inline-block;
				.skeleton-rectanglemargin {
					&.header1 {
						display: inline-block;
						width:49%;
						margin-right:1%;
					}
					&.header2 {
						display: inline-block;
						width:49%;
						margin-left:1%;
					}
				}
			}
			.skeleton-Headerright {
				width: 2rem;
				margin-right:2rem;
				display: inline-block;
				float:right;
			}
		} 
	}
	.timesheet-content {
		margin: 0rem 4rem 0 4rem;
		padding: 1.5rem 2rem;
		border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
		.timetracking-header1 {
			width: 100%;
			margin-bottom: 1rem;
			.skeleton-Headerleft {
				width:49%;
				margin-right:1%;
				display: inline-block;
				.skeleton-rectanglemargin {
					&.header1 {
						display: inline-block;
						width:20%;
					}					
				}
			}
			.skeleton-Headerright {
				width:49%;
				margin-left:1%;
				display: inline-block;
				float:right;
				.skeleton-rectanglemargin {
					&.header1 {
						display: inline-block;
						width: 20%;
						float: right;
					}
					&.header2 {
						display: inline-block;
						width: 2rem;
						margin-left: 1.5rem;
						float: right;						
					}
				}
			}			
		}
		.timetracking-header2 {
			width: 100%;
			margin-bottom: 1rem;
			.skeleton-Headerleft {
				width:49%;
				margin-right:1%;
				display: inline-block;
				.skeleton-rectanglemargin {
					&.header1 {
						display: inline-block;
						width:38%;
					}					
				}
			}
			.skeleton-Headerright {
				width:49%;
				margin-left:1%;
				display: inline-block;
				float:right;
				.skeleton-rectanglemargin {
					&.header1 {
						display: inline-block;
						width: 32%;
						float: right;
					}
					&.header2 {
						display: inline-block;
						width: 2rem;
						margin-left: 1.5rem;
						float: right;						
					}
				}
			}			
		}
		.timetracking-header3 .skeleton-margin > span span {
			display:inline-block;
			width: calc((100% - 9rem) / 7);
			margin-right:1.5rem;
			&:last-child {
				margin-right:0;
			}
		}
		.timetracking-header4 .skeleton-margin > span span,
		.timetracking-header5 .skeleton-margin > span span,
		.timetracking-header6 .skeleton-margin > span span {
			display:inline-block;
			width: calc((100% - 9rem) / 7);
			margin-right:1.5rem;
			background-color: transparent;
			border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
			background-image: none;
			border-radius:0;
			&:last-child {
				margin-right:0;
			}
		}
	}
	.timesheet-activity {
		display: flex;
		width: 100%;
		margin-top:2rem;
		margin-bottom: 2rem;
		flex-direction: row;
		.leftContent {
			width: 42%;
			margin: 0rem 1rem 0 4rem;
			padding: 1.5rem 2rem;
			border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
			.timetracking-header1 {
				width: 100%;
				margin-bottom: 0.5rem;
				.skeleton-Headerleft {
					width:49%;
					margin-right:1%;
					display: inline-block;
					.skeleton-rectanglemargin {
						&.header1 {
							display: inline-block;
							width:74%;
						}					
					}
				}
				.skeleton-Headerright {
					width:49%;
					margin-left:1%;
					display: inline-block;
					float:right;
					.skeleton-rectanglemargin {
						&.header1 {
							display: inline-block;
							width: 28%;
							float: right;
						}
						&.header2 {
							display: inline-block;
							width: 45%;
							margin-left: 1.5rem;
							float: right;						
						}
					}
				}			
			}
			.timetracking-header2 {
				margin-bottom: 2rem;
				.skeleton-rectanglemargin {
					width:47%;
				}
			}
			.timetracking-header3 {
				width: 100%;
				padding-bottom: 1.4rem;
				padding-top: 0.7rem;
				border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
				.skeleton-Headerleft {
					width: 60%;
					display: inline-block;
					.skeleton-rectanglemargin {
						&.header1 {
							display: inline-block;
							width:20%;
							margin-right:1%;
						}
						&.header2 {
							display: inline-block;
							width:68%;
							margin-left:1%;
						}
					}
				}
				.skeleton-Headerright {
					width: 40%;
					display: inline-block;
					float:right;
					.skeleton-rectanglemargin {
						padding-left: 19%;
						padding-right: 25%;
					}
				}
			}

		}
		.rightContent {
			width: 58%;
			margin: 0rem 4rem 0 1rem;
			.rightContent1 {
				padding: 1.5rem 2rem;
				border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
				margin-bottom: 2rem;
				.timetracking-header1 {
					width: 100%;
					margin-bottom: 0.5rem;
					.skeleton-Headerleft {
						width:32%;
						margin-right:1%;
						display: inline-block;
						.skeleton-rectanglemargin {
							&.header1 {
								display: inline-block;
								width:74%;
							}					
						}
					}
					.skeleton-Headerright {
						width:49%;
						margin-left:1%;
						display: inline-block;
						float:right;
						.skeleton-rectanglemargin {
							&.header1 {
								display: inline-block;
								width: 13%;
								float: right;
							}
							&.header2 {
								display: inline-block;
								width: 32%;
								margin-left: 1.5rem;
								float: right;						
							}
						}
					}			
				}
				.timetracking-header2 {
					margin-bottom: 5rem;
					.skeleton-rectanglemargin {
						width:30%;
					}
				}
				.timetracking-header3,
				.timetracking-header4,
				.timetracking-header5,
				.timetracking-header6,
				.timetracking-header7,
				.timetracking-header8,
				.timetracking-header9 {
					margin-bottom: 1rem;
					.skeleton-rectanglemargin {
						&.header1 {
							display: inline-block;
							width: 35%;
							margin-right: 3.5rem;
						}
						&.header2 {
							display: inline-block;
							width: 54%;
						}
					}
				}
				.timetracking-header4 .skeleton-rectanglemargin.header2 {
					width: 45%;
				}
				.timetracking-header5 .skeleton-rectanglemargin.header2 {
					width: 36%;
				}
				.timetracking-header6 .skeleton-rectanglemargin.header2 {
					width: 27%;
				}
				.timetracking-header7 .skeleton-rectanglemargin.header2 {
					width: 18%;
				}
				.timetracking-header8 .skeleton-rectanglemargin.header2 {
					width: 9%;
				}
				.timetracking-header9 .skeleton-rectanglemargin.header2 {
					width: 0;
				}
				.timetracking-header9 .skeleton-rectanglemargin.header1 {
					width: 17%;
				}
			}
			.rightContent2 {
				padding: 1.5rem 2rem;
				border: 0.1rem solid ${(props) => props.theme.color[`grey250`]};
				.timetracking-header1 {
					width: 100%;
					margin-bottom: 0.5rem;
					.skeleton-Headerleft {
						width:32%;
						margin-right:1%;
						display: inline-block;
						.skeleton-rectanglemargin {
							&.header1 {
								display: inline-block;
								width:74%;
							}							
						}					
					}
					.skeleton-Headerright {
						width:49%;
						margin-left:1%;
						display: inline-block;
						float:right;
						.skeleton-rectanglemargin {
							&.header1 {
								display: inline-block;
								width: 13%;
								float: right;
							}
							&.header2 {
								display: inline-block;
								width: 32%;
								margin-left: 1.5rem;
								float: right;						
							}
						}
					}			
				}
				.timetracking-header2 {
					margin-bottom: 5rem;
					.skeleton-rectanglemargin {
						width:30%;
					}
				}
				.timetracking-header3 {
					width:100%;
					.skeleton-rectanglemargin {
						width:63%;
					}
					.skeleton-rectanglemargin > span span {
						margin-bottom: 4rem;
					}
					.skeleton-Headerleft {
						width:50%;
						display:inline-block;
						vertical-align: text-top;
					}
					.skeleton-Headerright {
						width:50%;
						display:inline-block;
					}
				}
			}
		}
	}
`;

const StyledAppContainer = styled.div`
	margin-top:2.5rem;
	background: ${(props) => props.theme.color[`grey50`]};
	.react-loading-skeleton {
		background-color: rgb(238, 238, 238);
		background-image: ${(props) => props.theme.color[`grey100`]};
		background-size: 200px 100%;
		display: inline-block;
		line-height: 1;
		width: 100%;
		background-repeat: no-repeat;
		border-radius: 0.4rem;
		animation: 1.2s ease-in-out 0s infinite normal none running
			animation-16jpnkj;
	}
`;

const TimeTrackingLoader = () => {
	return (
		<StyledAppContainer>
			<TimeTrackingLoaderdiv className="customScrollbar">
				<section className="timetracking-header">
					<div className="skeleton-rectanglemargin header1">
						<Skeleton height={'4.8rem'} count={1} duration={2} />
					</div>
					<div className="skeleton-rectanglemargin header2">
						<Skeleton height={'4rem'} count={1} duration={2} />
					</div>
					<section className="timetracking-header1">
						<section className="skeleton-Headerleft">
							<div className="skeleton-rectanglemargin header1">
								<Skeleton height={'4.4rem'} duration={2} />
							</div>
							<div className="skeleton-rectanglemargin header2">
								<Skeleton height={'4.4rem'} duration={2} />
							</div>
						</section>
						<section className="skeleton-Headerright">
							<div className="skeleton-rectanglemargin">
								<Skeleton height={'2rem'} duration={2} />
							</div>
						</section>
					</section>
				</section>
				<section className="timesheet-content">
					<section className="timetracking-header1">
						<section className="skeleton-Headerleft">
							<div className="skeleton-rectanglemargin header1">
								<Skeleton height={'2rem'} duration={2} />
							</div>
						</section>
						<section className="skeleton-Headerright">
							<div className="skeleton-rectanglemargin header2">
								<Skeleton height={'2rem'} duration={2} />
							</div>
							<div className="skeleton-rectanglemargin header1">
								<Skeleton height={'2rem'} duration={2} />
							</div>
						</section>
					</section>
					<section className="timetracking-header2">
						<section className="skeleton-Headerleft">
							<div className="skeleton-rectanglemargin header1">
								<Skeleton height={'2rem'} duration={2} />
							</div>
						</section>
						<section className="skeleton-Headerright">
							<div className="skeleton-rectanglemargin header2">
								<Skeleton height={'2rem'} duration={2} />
							</div>
							<div className="skeleton-rectanglemargin header1">
								<Skeleton height={'2rem'} duration={2} />
							</div>
						</section>
					</section>
					<section className="timetracking-header3">
						<div className="skeleton-margin">
							<Skeleton height={'2rem'} count={7} duration={2} />
						</div>
					</section>
					<section className="timetracking-header4">
						<div className="skeleton-margin">
							<Skeleton height={'9rem'} count={7} duration={0} />
						</div>
					</section>
					<section className="timetracking-header5">
						<div className="skeleton-margin">
							<Skeleton height={'9rem'} count={7} duration={0} />
						</div>
					</section>
					<section className="timetracking-header6">
						<div className="skeleton-margin">
							<Skeleton height={'9rem'} count={7} duration={0} />
						</div>
					</section>
				</section>
				<section className="timesheet-activity">
					<section className="leftContent">
						<section className="timetracking-header1">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'3rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'3rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'3rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header2">
							<div className="skeleton-rectanglemargin">
								<Skeleton height={'2rem'} duration={2} />
							</div>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="timetracking-header3">
							<section className="skeleton-Headerleft">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
							<section className="skeleton-Headerright">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'4rem'} duration={2} />
								</div>
							</section>
						</section>
					</section>
					<section className="rightContent">
						<section className="rightContent1">
							<section className="timetracking-header1">
								<section className="skeleton-Headerleft">
									<div className="skeleton-rectanglemargin header1">
										<Skeleton height={'3rem'} duration={2} />
									</div>
								</section>
								<section className="skeleton-Headerright">
									<div className="skeleton-rectanglemargin header2">
										<Skeleton height={'3rem'} duration={2} />
									</div>
									<div className="skeleton-rectanglemargin header1">
										<Skeleton height={'3rem'} duration={2} />
									</div>
								</section>
							</section>
							<section className="timetracking-header2">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'2rem'} duration={2} />
								</div>
							</section>
							<section className="timetracking-header3">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'2rem'} duration={2} />
								</div>
							</section>
							<section className="timetracking-header4">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'2rem'} duration={2} />
								</div>
							</section>
							<section className="timetracking-header5">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'2rem'} duration={2} />
								</div>
							</section>
							<section className="timetracking-header6">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'2rem'} duration={2} />
								</div>
							</section>
							<section className="timetracking-header7">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'2rem'} duration={2} />
								</div>
							</section>
							<section className="timetracking-header8">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'2rem'} duration={2} />
								</div>
							</section>
							<section className="timetracking-header9">
								<div className="skeleton-rectanglemargin header1">
									<Skeleton height={'2rem'} duration={2} />
								</div>
								<div className="skeleton-rectanglemargin header2">
									<Skeleton height={'2rem'} duration={2} />
								</div>
							</section>
						</section>
						<section className="rightContent2">
							<section className="timetracking-header1">
								<section className="skeleton-Headerleft">
									<div className="skeleton-rectanglemargin header1">
										<Skeleton height={'3rem'} duration={2} />
									</div>
								</section>
								<section className="skeleton-Headerright">
									<div className="skeleton-rectanglemargin header2">
										<Skeleton height={'3rem'} duration={2} />
									</div>
									<div className="skeleton-rectanglemargin header1">
										<Skeleton height={'3rem'} duration={2} />
									</div>
								</section>
							</section>
							<section className="timetracking-header2">
								<div className="skeleton-rectanglemargin">
									<Skeleton height={'2rem'} duration={2} />
								</div>
							</section>
							<section className="timetracking-header3">
								<section className="skeleton-Headerleft">
									<div className="skeleton-rectanglemargin">
										<Skeleton height={'2rem'} count={5} duration={2} />
									</div>
								</section>
								<section className="skeleton-Headerright">
									<div className="skeleton-circlemargin">
										<Skeleton
											circle
											height={'30rem'}
											width={'30rem'}
											duration={2}
										/>
									</div>
								</section>
							</section>
						</section>
					</section>
				</section>
			</TimeTrackingLoaderdiv>
		</StyledAppContainer>
	);
};

export default TimeTrackingLoader;
