import styled from 'styled-components';

const DashboardStyledComp = styled.div`
    position:relative;
    height:calc(100vh - 6.5rem);
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    max-width: 2048px;
    margin: 0 auto;

    & .menu-nav{
        display:inline-flex;
		padding-top:1rem;
		padding-bottom:1rem;
    }

    & .page-filter .createeng{
		float: right;
		right: 16rem;
		position: absolute;
        top: -5.2rem;
        
        & a{
            margin-right:1em;
            text-decoration:none;
        }
    }
    
    & .empty-message{
        align-items:center;
        justify-content:center;
        height: calc(100vh - 15.5rem);
        color:${(props) => props.theme.color[`grey500`]};
        font-size:1.4rem;
        text-align:center;
        font-weight:normal;
        display:flex;
        padding:1rem 4rem;

        & .norecords{
            margin-bottom:5rem;
        }

        & p{
            float:left;
            width:100%;
            margin:0;
            line-height:2.6rem;
            font-size: 1.4rem;
            color: ${(props) => props.theme.color[`grey400`]};

            &.try-message{
                font-size:2rem;
            }
        }
    }

    & ul{
        padding:0 4rem;
        background: ${(props) => props.theme.color[`grey50`]};
        & li{
            margin:0 0 2rem 0;
            list-style:none;
        }
		 & li:last-child {
            margin:0 0 2rem 0;
            list-style:none;
			padding-bottom:0.8rem;
        }
    }
    .engfavtabs {
        font-size: 1.4rem;
        color: ${(props) => props.theme.color[`grey500`]};
        width:50%;
        float:left;

        & .nav-tabs{
            border:0;
        }

        .empty-message {
            align-items: center;
            justify-content: center;
            height: calc(100vh - 15.5rem);
            color: ${(props) => props.theme.color[`grey500`]};
            font-size: 1.4rem
            text-align: center;
            font-weight: normal;
            display:flex;
            padding:1rem 4rem;

            & .norecords{
                margin-bottom:5rem;
            }

            & p {
                float: left;
                width: 100%;
                margin: 0;
                line-height: 2.6rem;
                font-size: 1.4rem;
                color: ${(props) => props.theme.color[`grey400`]};

                &.try-message {
                    font-size: 2rem;
                }
            }
        }

        & li {
            padding: 0.5rem 0.8rem 0 0.8rem;
            margin: 0;
            & a,
            & a:hover,
            & a:focus {
                color: ${(props) => props.theme.color[`grey500`]};
                background: none;
                border: none;
                outline: none;
                cursor: pointer;
                padding: 1.8rem 0.3rem 0 0.3rem;
                margin: 0;
            }
            
            &.active {
                font-weight: bold;
                padding: 0.5rem 0.8rem 0 0.8rem;
                & a:link {
                    background: none;
                    border-bottom: 0.4rem solid ${(props) =>
											props.theme.color[`grey500`]};
                    border-left: 0;
                    border-right: 0;
                    border-top: 0;
                    color:${(props) => props.theme.color[`grey500`]};
                }
            }

            &:first-child{
                padding-left:0;
            }
        }
    }
    & .page-filter{		
        padding: 0 4rem 1rem 4rem;
        display:inline-block
        width:100%;    
        position:relative;

        & .search-filter{
            display:inline-block;
            line-height:4rem;
            width:35rem;

            & > .searchControl{
                height:4rem;
                background:transparent;
            }
            & input{
                border-left:0;
                border-right:0;
                border-top:0;
                background:none;
                border-bottom:0 solid ${(props) =>
									props.theme.color[`grey500`]};
                &:focus{
                    border-left:0 !important;
                    border-right:0 !important;
                    border-top:0 !important;
                    border-bottom:0 solid ${(props) =>
											props.theme.color[`grey500`]} !important;
                }
            }        
        }
        & .pagination{
            margin:0;
            line-height: 2.4rem;
            float:right;	
            font-size: 1.4rem;
            min-width: auto;
            .Dropdown-root.is-open .Dropdown-arrow {
                top:1.8rem !important;
            }
			.Dropdown-root .Dropdown-control:hover {
				box-shadow:none;
			}
            & > *, 
			& input[type='text']{
				font-size: 1.4rem;
            }

            & .Dropdown-arrow{
                top:1.3rem !important;
            }
            
            & .search-filter{
                float:left;
            }	
            & .Dropdown-root .Dropdown-control,
            & input {
                background:none;
            }	
            & .Dropdown-root .Dropdown-menu {
                border:0.1rem solid ${(props) => props.theme.color[`grey500`]};
                & .Dropdown-option{
                    color:${(props) => props.theme.color[`grey500`]};
                    &:hover{
                        background:${(props) => props.theme.color[`grey500`]};
                        color:${(props) => props.theme.color[`white`]};
                    }	
                }			
            }			
        }
    }
    .filterrow{		
        width:50%;
        float:right;
        text-align:right;
		.motif-dropdown  {
			.motif-button {
				font-size:1.2rem;
				padding: 0.5rem 1rem;
				.motif-icon {
					margin-right:0.3rem;
				}
				svg {
					width:2rem;
					height:2rem;
				}
			}
			.motif-dropdown-trigger,
			.motif-dropdown-trigger:hover,
			.motif-dropdown-trigger:focus {
				border: none;
			}
			.motif-dropdown-item {
				font-size:1.2rem;
			}
		}
        & > * {
            line-height:normal;
            margin: 1.7rem 0 0 1rem;
            cursor:pointer;
            &:first-child{margin-right:4rem;}
        }
        }
    }
    }
`;

export default DashboardStyledComp;
