/**
 * Remember: always add new reducers to the /reducers/index.js file!
 * Also, add your initialState to './initialstate'!
 */
import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */

export function MyReviewEngagementsReducer(
	//state = initialState.<%=names.camel%>,//TODO: uncomment this. Get from initialState.
	state = initialState.reviewEngagements, //{Workspaces: {}},
	action
) {
	switch (action.type) {
		case types.LOAD_REVIEW_ENGAGEMENTS:
			/* Notice that this is simply returning the action payload, <%=names.camel%>.
			 * This is because the combineReducers() call wraps this reducer
			 * and passes it only the portion of the store state that it
			 * is concerned with. */
			console.log('action from review workspace reducer:', action);
			return formatAndSortEngagements(action.response);
		default:
			return state;
	}
}
export function formatAndSortEngagements(engagementslist) {
	var engagementpaginationdata = {};
	var engagements_list = [];
	if (engagementslist && engagementslist.collections) {
		engagementslist.collections.clients.forEach((client) => {
			if (client.collections && client.collections.workspaces) {
				client.collections.workspaces.forEach((workspace) => {
					if (workspace.collections) {
						workspace.collections.engagements.forEach((engagement) => {
							engagements_list.push({
								engagements: engagement,
								workspaceID: workspace.id,
								workspace: workspace.data,
								client: client,
								clientName: client.data.clientname
							});
						});
					}
				});
			}
		});
	}

	engagementpaginationdata.allengagements = engagements_list.sort(
		(a, b) =>
			a.engagements.data.engagementdesc.toLowerCase() >
			b.engagements.data.engagementdesc.toLowerCase()
				? 1
				: -1
	);
	engagementpaginationdata.pagermetadata = engagementslist.pagermetadata;
	engagementpaginationdata.data = engagementslist.data;
	engagementpaginationdata.userid = engagementslist.id;

	return engagementpaginationdata;
}
