import * as types from './actiontypes';

/**
 * make a get call for engagement timeline
 * * @example Engagement Timeline: https://canvas-app-dev1.cloudapp.eydev.net/api/v1/timeline.json?enagagementid=1567244
 */
export function getEngagementTimeline(url, resource, engagementid, audience) {
	return {
		type: types.GET_ENGAGEMENT_TIMELINE,
		meta: {
			api: {
				url,
				method: 'GET',
				data: {
					resource
				},
				// For in-country these headers are required.
				headers: {engagementid: engagementid, datasource: 'primary'},
				audience: audience
			}
		}
	};
}

export function syncEngagementandUserStatus(url, resource) {
	return {
		type: types.POST_SYNC_ENGAGEMENT_USER,
		meta: {
			api: {
				url,
				method: 'POST',
				data: resource
			}
		}
	};
}
