import React, { useState, useEffect, useRef } from 'react';
import {
    MotifButton,
    MotifSearch,
    MotifSelect,
    MotifOption,
    MotifErrorMessage,
    MotifLabel,
    MotifFormField
} from '@ey-xd/motif-react';
import {
    labels,
    dateFormatsListItems,
    dateFormats,
} from '../../../util/uiconstants';
import CheckBox from '../../Common/Checkbox/CheckBox';
import DateTimePicker from '../../Common/MotifDateTimePicker/datetimepicker';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import env from '../../../util/env';
import * as masterdataactions from '../../../actions/admin/masterdataactions';
import RichTextEditorWrapper from "../../Common/TextEditor/RichTextEditorWrapper";
import Loader from '../../Common/Loaders/loadingindicator';
import Toast from '../../Common/Toast/Toast';
import { getMessageById } from '../../../actions/admin/messagingactions';
import TicketField from '../common/ticketfield';
import { compareArray } from '../common/util';

const Wrapper = styled.section`
//ticketstart
			.ticketdetail{
				margin: 2rem 0 0 0;
				padding-bottom: 2rem;
                border-top: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
				h5{
					font-size: 1.4rem;
					font-weight: normal;
					color: ${(props) => props.theme.color[`grey500`]};
					border: 0!important;
                    margin-top: 2.4rem;
				}

				.motif-form-field{
					width: 29rem;
				}

				.motif-form-field:not(.motif-form-field-has-value),
				.motif-form-field:not(.motif-form-field-focused){
					.motif-label{
						font-size: 1.4rem;
						font-weight:normal;
						margin-top: 0.6rem;
						color: ${(props) => props.theme.color[`black`]};
					}
				}
		
				.motif-form-field-has-value,
				.motif-form-field-focused{
					.motif-label{
						font-size: 1.2rem!important;
						font-weight: normal!important;
						margin-top: 0.2rem!important;
						 color: ${(props) => props.theme.color[`black`]};
					}
				}
				
				
				.motif-input {
					font-size: 1.4rem;
					line-height: 3rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					color: ${(props) => props.theme.color[`black`]};
					&::placeholder {
						font-size: 1.4rem;
					}
				}
			}
			//ticketend

.loadingstyle {
    height: calc(100vh - 27.3rem) !important;
}

@media screen and (max-width: 1366px)  {
    .loadingstyle {
        height: calc(100vh - 29rem)!important;
    }
}

@media screen and (max-width: 1366px)  {
    .createmessagewrapper{
        height: calc(100vh - 29rem)!important;
    }
}

.createmessagewrapper{
    padding: 1.6rem 2rem;
    display: flex;
    width: 100%;
    font-size: 1.4rem;
    height: calc(100vh - 27.3rem);

    & * .motif-error-message {
        font-size: 1.2rem;
        margin-top: 0.5rem;
    }

    & * svg {
        width: 1.5rem!important;
        height: 1.5rem!important;
    }

    .kendoeditor{
        .k-widget{
            resize: none;
        }

        .kendo-editor-label label{
            font-weight: normal;
        }
    }

    .toastMessage {
        .motif-toast-text-container {
            font-size: 1.4rem;
        }

        &.block {
            display: block;
        }

        &.none {
            display: none;
        }
    }

    .holder{
        width: 100%;
        display: inline-block;
    }

    .motif-search-input-wrapper{            
        display: inline-block;
        width: 100%;
        position: relative;

        .motif-typeahead-close-button{
            top: 1.2rem;
        }
        .motif-search-input-icon{
            display: none;
        }
    }

    .motif-input::placeholder{
        font-size: 1.4rem;
    }

    .motif-input,
    .motif-select-trigger{
        height: 4.4rem;
        font-size: 1.4rem;
    }

    h4{
        font-size: 1.4rem;
        font-weight: bold;
    }

    .datepicker{
        display: flex;

        .react-date-picker__calendar{
            left: 0!important; 
            width: auto!important;
        }

        .motif-form-field{
            display: inline-flex;
            margin-right: 1rem;

            .motif-label {
                font-size: 1.2rem;
                padding-top: 0.6rem;
            }

            .motif-date-picker-wrapper{
                .react-date-picker{
                    height: 4.6rem;

                    .motif-calendar-icon{
                        width: 2rem!important;
                        height: 2rem!important;
                    }

                    .react-date-picker__inputGroup{
                        font-size: 1.4rem;
                        padding-top: 2.2rem;
                        & > *{
                            font-size: 1.4rem;
                        }
                    }
                }
            }

            .motif-small-input-item{
                height: 4.6rem;
            }
            
            .motif-small-input-item,
            .motif-time-acronym .motif-radio-label{
                font-size: 1.4rem;
            }
        }
    }   

    .kendoeditor{
        margin: 2rem 0;
    }

    .currentutc{
        .currentutclabel{
            font-weight: bold;
        }
    }

    .startendtime{
        display: flex;
        margin: 0 0 2rem 0;

        .react-calendar,
        .react-calendar__navigation__label__labelText{
            font-size: 1.2rem!important;
        }

        .select{
            margin-bottom: 1rem;
            line-height: normal;
        }

        .moreinfo{
            font-size: 1.2rem;
            line-height: normal;
        }

        .starttime{
            margin-right: 2rem;
        }
        
        .starttime,
        .endtime{
            flex-grow: 1;
        }
    }

    .datacenterwrapper{
        display: flex;
        margin: 2rem 0;

        & > *{
            width: 50%;
        }
    }

    .alldatacenter{
        margin-right: 2rem;
        flex-grow: 1;
        h4{
            margin-top: 0;
        }
        .checkoption-item {
            width: 100%;
            margin-top: 1rem;
            .motif-checkbox{
                margin: 0.5rem 0 0 0;
                display: inline;
                .motif-checkbox-custom{
                    vertical-align: text-top;
                }
            }
        }
    }

    .datacenter{
        flex-grow: 1;
        .motif-select-input{
            height: 4.6rem;
            .motif-select-input-text{
                margin-top: 0.5rem;
            }
            .motif-select-input-arrow{
                top: 1.5rem;
            }
        }

        .motif-select-wrapper-options{
            .motif-select-search-input{
                font-size: 1.4rem;
            }
        }
        
        h4{
            margin-top: 0;
        }

        .motif-select-field{
            .motif-select-field-list{
                position: absolute; 
                bottom: 4.5rem;
            }
        }
        
        .motif-form-field{
            width: 29rem;
            display: block;

            .motif-label{                 
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 1.2rem;
                margin-top: 0.5rem;
                padding-bottom: 0;
            }

            .motif-select-view-value{
                margin-top: 0.5rem;
            }
        }

        .datacenter{
            width: 100%;
            padding-bottom: 0;
            .motif-select-trigger{
                .motif-select-arrow{
                    top: 1.2rem;
                }
            }

            .motif-select-field-list{
                overflow: hidden!important;

                .motif-select-aux-content {
                    .motif-inner-search{
                        font-size: 1.2rem;
                    }
                }

                .motif-option {
                    font-size: 1.2rem!important;
                    .motif-checkbox{
                        margin: 0!important;
                    }
                }
            }
        }
    }
}
.modal-footer {
    display: inline-flex;
    width: 100%;

    button {
        font-size: 1.4rem;
        padding: 1.2rem 1.5rem;
        margin-right: 1rem;
        line-height: normal;
    }
    .motif-error-message {
        font-size: 1.2rem;
        margin: 0;
    }
}
`;

function CreateEditMessage(props) {
    const dispatch = useDispatch();
    const screenRef = useRef(null);
    const dataCenters = useSelector((store) => store.dataCenters);
    const messageDetails = useSelector((store) => store.messageDetails);

    const [startDateTimeString, setStartDateTimeString] = useState('');
    const [endDateTimeString, setEndDateTimeString] = useState('');
    const [messageTitle, setMessageTitle] = useState('');
    const [messageDescription, setMessageDescription] = useState('');
    const [allDataCentersChecked, setAllDataCentersChecked] = useState(true);
    const [selectedDataCenters, setSelectedDataCenters] = useState([]);
    const [showMessageTitleValidation, setShowMessageTitleValidation] = useState(false);
    const [showMessageDescriptionValidation, setShowMessageDescriptionValidation] = useState(false);
    const [showEndDateTimeValidation1, setShowEndDateTimeValidation1] = useState(false);
    const [showEndDateTimeValidation2, setShowEndDateTimeValidation2] = useState(false);
    const [showDataCenterValidation, setShowDataCenterValidation] = useState(false);
    const [clearDropdownSelection, setClearDropdownSelection] = useState(0);
    const [messageDescriptionPlainText, setMessageDescriptionPlainText] = useState('');
    const [startDateRequired, setStartDateRequired] = useState(false);
    const [endDateRequired, setEndDateRequired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
	const [ticketNumber, setTicketNumber] = useState('');
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const [isSameData, setIsSameData] = useState(true);
    const [validationMessage, setValidationMessage] = useState();

    useEffect(() => {
        if (screenRef.current && screenRef.current.scrollTop){
            screenRef.current.scrollTop = 0;
        }
    }, [screenRef, isLoading]);
    
    useEffect(() => {
        getAllDataCenters();

        if (props.isEditMessage){
            getMessageDetails();
        }
    }, []);

    useEffect(() => {
        if (props.isEditMessage && Object.keys(messageDetails).length > 0){
            setStartDateTimeString(messageDetails.releaseOnDate+".000Z");
            setEndDateTimeString(messageDetails.expireOnDate+".000Z");
            setMessageTitle(messageDetails.messageTitle);
            setMessageDescription(messageDetails.messageText);
            setAllDataCentersChecked(messageDetails.isSystemWide);
            var dataCenters = setDataCenter(messageDetails.dataCenterList);
            setSelectedDataCenters(dataCenters);
        }
    }, [messageDetails]);

    const setDataCenter = (dataCenterList) => {
        var dataCenters = [];
        dataCenterList.map(x => dataCenters.push(x.dataCenterId.toString()));
        return dataCenters;
    }

    const getMessageDetails = () => {
        setIsLoading(true);
        let messageByIdUrl = env.getURL('serviceUrlV2') + `admin/Messages/${props.messageId}`;

        dispatch(getMessageById(messageByIdUrl))
            .then(() => {
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getAllDataCenters = () => {
        let dataCenterUrl = env.getURL('serviceUrlV2') + 'datacenters';
        if (Object.keys(dataCenters).length === 0) {
            dispatch(masterdataactions.getCountryDataCenters(dataCenterUrl)).then(() => { });
        }
    };

    const getDataCentersForDropDown = () => {
        let dataCenterList = [];
        if (Object.keys(dataCenters).length > 0) {
            _.forEach(dataCenters, (item) => {
                if (item) {
                    dataCenterList.push({
                        value: item.dataCenterID.toString(),
                        label: item.dataCenterName
                    });
                }
            });
        }
        return dataCenterList;
    }

    const validateDataonSave = () => {
        setIsSameData(true);
        setValidationMessage();
        let isValid = true;
        if (!validateMessageTitle(messageTitle)) {
            isValid = false;
        }

        if (!validateMessageDescription(messageDescription)) {
            isValid = false;
        }

        if (!validateStartDateTime(startDateTimeString)) {
            isValid = false;
        }

        if (!validateEndDateTime(endDateTimeString)) {
            isValid = false;
        }

        if (!validateDataCenters(allDataCentersChecked, selectedDataCenters)) {
            isValid = false;
        }

        if(!(ticketNumber?.length > 0))
        {
            isValid = false;
        }

        if (!isValid) {
            setValidationMessage(labels.messageSubmitValidationError);
        } else if (validateSameData()) {
            setValidationMessage(labels.messageChangesValidationError);
            isValid = false;
        }

        return isValid;
    };

    const validateSameData = () => {
        if (messageTitle !== messageDetails.messageTitle) {
            setIsSameData(false);
            return false;
        }
        if (messageDescription !== messageDetails.messageText) {
            setIsSameData(false);
            return false;
        }
        if (startDateTimeString !== messageDetails.releaseOnDate+".000Z") {
            setIsSameData(false);
            return false;
        }
        if (endDateTimeString !== messageDetails.expireOnDate+".000Z") {
            setIsSameData(false);
            return false;
        }
        if (allDataCentersChecked != messageDetails.isSystemWide) {
            setIsSameData(false);
            return false;
        }
        if (selectedDataCenters.length > 0) {
            let originalData = messageDetails.dataCenterList?.map(dataCenter => dataCenter?.dataCenterId?.toString()) ?? [];
            let isSimilarDataCenters = compareArray(originalData, selectedDataCenters);
            if (!isSimilarDataCenters) {
                setIsSameData(false);
                return false;
            }
        }
        return true;
    }

    const validateMessageTitle = (messageTitle) => {
        setShowMessageTitleValidation(false);
        if (!messageTitle || messageTitle.length > 500 || _.trim(messageTitle).length <= 0) {
            setShowMessageTitleValidation(true);
            return false;
        }
        return true;
    }

    const validateMessageDescription = (messageDescription) => {
        setShowMessageDescriptionValidation(false);
        if (!messageDescriptionPlainText || messageDescriptionPlainText.length <= 0) {
            setShowMessageDescriptionValidation(true);
            return false;
        }
        if (messageDescription.length > 4000) {
            return false;
        }
        return true;
    }

    const validateStartDateTime = (startDateTimeString) => {
        setStartDateRequired(false);
        if (startDateTimeString == '') {
            setStartDateRequired(true);
            return false;
        }
        return true;
    }

    const validateEndDateTime = (endDateTimeString) => {
        setShowEndDateTimeValidation1(false);
        setShowEndDateTimeValidation2(false);
        setEndDateRequired(false);
        if (endDateTimeString == '') {
            setEndDateRequired(true);
            return false;
        }
        var startDateTime = new Date(startDateTimeString);
        var endDateTime = new Date(endDateTimeString);

        if (endDateTime <= startDateTime) {
            setShowEndDateTimeValidation2(true);
            return false;
        }
        var currentDateTime = new Date();
        if (endDateTime < currentDateTime) {
            setShowEndDateTimeValidation1(true);
            return false;
        }
        return true;
    }

    const validateDataCenters = (allDataCenters, selectedDataCenters) => {
        setShowDataCenterValidation(false);
        if (allDataCenters) {
            return true;
        }
        if (selectedDataCenters.length == 0) {
            setShowDataCenterValidation(true);
            return false;
        }
        return true;
    }
    
    useEffect(() => {
        if(isSubmitClicked) {
            validateDataonSave();
        }
    });

	const onMessageSave = () => {
		setIsSubmitClicked(true);
		if (validateDataonSave()) {
			let saveMessageModel = {
				title: messageTitle,
				description: messageDescription,
				startsOn: startDateTimeString,
				endsOn: endDateTimeString,
				isSystemWide: allDataCentersChecked,
				dataCenters: selectedDataCenters,
				ticketNumber: ticketNumber
			}
			setStartDateTimeString('');
			setEndDateTimeString('');
			if (ticketNumber?.length > 0) {
                setIsSubmitClicked(false);
				if (props.isEditMessage) {
					props.onEditMessageAPICall(saveMessageModel);
				} else {
					props.onSaveMessageClick(saveMessageModel);
				}
			}
		} 
	};

    const onMessageTitleChange = (e) => {
        setShowMessageTitleValidation(false);
        setMessageTitle(e.target.value);
    }

    const onMessageDescriptionChange = (e) => {
        setShowMessageDescriptionValidation(false);
        setMessageDescription(e.value);
    }

    const onStartDateTimeChange = (dateTime) => {
        setStartDateRequired(false);
        setStartDateTimeString(dateTime);
    };

    const onEndDateTimeChange = (dateTime) => {
        setEndDateRequired(false);
        setShowEndDateTimeValidation1(false);
        setShowEndDateTimeValidation2(false);
        setEndDateTimeString(dateTime);
    }

    const onCheckBoxChange = () => {
        setShowDataCenterValidation(false);
        setAllDataCentersChecked(!allDataCentersChecked);
        setClearDropdownSelection(clearDropdownSelection + 1);
        setSelectedDataCenters([]);
    }

    const dropDownOnChange = (option) => {
        setShowDataCenterValidation(false);
        setSelectedDataCenters(option);
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const currentUTCDateTime = () => {
        const date = new Date();
        const yyyy = date.getUTCFullYear();
        const mm = padTo2Digits((date.getUTCMonth()+1));
        const dd = padTo2Digits(date.getUTCDate());
        const hh = padTo2Digits(date.getUTCHours());
        const mn = padTo2Digits(date.getUTCMinutes());

        var currentUTCDateTime = `${mm}-${dd}-${yyyy} ${hh}:${mn}`;
        return currentUTCDateTime;
    }

	function handlerOnTicketEntered(e) {
		setTicketNumber(e.target.value);
	}

    return (
        <Wrapper>         
                {isLoading ? (
                    <Loader show fullscreen view="blockView" styleName="loadingstyle"/>
                ) : props?.createEditMessageLoading ? (
                    <Loader show fullscreen view="blockView" styleName="loadingstyle"/>
                ) : (
                    <section ref={screenRef} className="createmessagewrapper customScrollbar">
                        <section className="holder">
                            <section
                                className="toastMessage"
                                style={{display: props?.createEditErrorMessage.length > 0 ? 'block' : 'none'}}
                            >
                                <Toast
                                    variant="error"
                                    onClose={() => props.setCreateEditErrorMessage('')}
                                >
                                    {props?.createEditErrorMessage}
                                </Toast>
                            </section>

                            {/* Message Title */}
                            <section>
                                <MotifSearch
                                    maxLength={500}
                                    value={messageTitle}
                                    onChange={onMessageTitleChange}
                                    placeholder={labels.messageName}
                                    title={messageTitle}
                                    clearHoverText={labels.clear}
                                ></MotifSearch>
                                {showMessageTitleValidation ? (
                                    <MotifErrorMessage>{labels.messageFieldRequired}</MotifErrorMessage>
                                ) : ''}
                            </section>

                            {/* Message Description */}
                            <section className="kendoeditor">
                                <RichTextEditorWrapper
                                    charactersLabel={labels.characters}
                                    label={labels.messageKendoDescription}
                                    ofLabel={labels.ofLabel}
                                    value={messageDescription}
                                    placeholder={labels.messagingDescriptionTitle}
                                    onChange={onMessageDescriptionChange}
                                    maxCharCount={4000}
                                    showMaxCharacterCount
                                    useMinimalToolset={false}
                                    resizable
                                    getPlainText={(value)=>{setMessageDescriptionPlainText(value)}}
                                />
                                {showMessageDescriptionValidation ? (
                                    <MotifErrorMessage>{labels.messageFieldRequired}</MotifErrorMessage>
                                ) : ''}
                            </section>

                            {/* Start and end time and date */}
                            <section className="startendtime">
                                <section className="starttime">
                                    <h4>
                                        {labels.messageStartsOn}
                                    </h4>
                                    <section className="select">
                                        {labels.messageDateTime}
                                    </section>
                                    <DateTimePicker
                                        label={labels.messageSelectADate}
                                        dateFormat={dateFormatsListItems[dateFormats].format}
                                        value={startDateTimeString}
                                        onChange={onStartDateTimeChange}
                                        todayMark={true}
                                    />
                                    <section className="moreinfo">
                                        {labels.messageAllTimeInUTC}
                                    </section>
                                    {startDateRequired ? (
                                        <MotifErrorMessage>{labels.messageFieldRequired}</MotifErrorMessage>
                                    ) : ''}
                                </section>

                                <section className="endtime">
                                    <h4>
                                        {labels.messageEndsOn}
                                    </h4>
                                    <section className="select">
                                        {labels.messageDateTime}
                                    </section>
                                    <section>
                                        <DateTimePicker
                                            label={labels.messageSelectADate}
                                            dateFormat={dateFormatsListItems[dateFormats].format}
                                            value={endDateTimeString}
                                            onChange={onEndDateTimeChange}
                                            todayMark={true}
                                        />
                                    </section>
                                    <section className="moreinfo">
                                        {labels.messageAllTimeInUTC}
                                    </section>
                                    {endDateRequired ? (
                                        <MotifErrorMessage>{labels.messageFieldRequired}</MotifErrorMessage>
                                    ) : ''}
                                    {showEndDateTimeValidation1 ? (
                                        <MotifErrorMessage>{labels.messageEndTimeError1}</MotifErrorMessage>
                                    ) : ''}
                                    {showEndDateTimeValidation2 ? (
                                        <MotifErrorMessage>{labels.messageEndTimeError2}</MotifErrorMessage>
                                    ) : ''}
                                </section>
                            </section>

                            <section className="currentutc">
                                <span className="currentutclabel">{labels.currentutc}</span> {labels.messageCurrentUTCTime.replace('{0}', currentUTCDateTime)}
                            </section>

                            <section className="datacenterwrapper">
                                {/* Target Audience */}
                                <section className="alldatacenter">
                                    <h4>
                                        {labels.messageTargetAudience}
                                    </h4>
                                    <section>
                                        <CheckBox
                                            styleName="checkoption-item"
                                            label={labels.messageAllDataCenters}
                                            id={'alldatacenters'}
                                            value={'allDataCenters'}
                                            name="alldatacentres"
                                            onChange={onCheckBoxChange}
                                            checked={allDataCentersChecked}
                                            hidetitletip
                                        />
                                    </section>
                                </section>

                                {/* Data center list */}
                                <section className="datacenter">
                                    <h4>
                                        {labels.messageDataCenters}
                                    </h4>
                                    <section>
                                        <MotifFormField>
                                            <MotifLabel id="select-label-search">{labels.messageSelectDataCenter}</MotifLabel>
                                            <MotifSelect
                                                id={'Datacenters'}
                                                onChange={(e) => dropDownOnChange(e)}
                                                filter
                                                value={props.isEditMessage ? selectedDataCenters : ''}
                                                arialabelledby="select-label-search"
                                                multiple={true}
                                                showselectallbutton
                                                key={clearDropdownSelection}
                                                className="datacenter"
                                                disabled={allDataCentersChecked}
                                            >
                                                {getDataCentersForDropDown()?.map((item) => (
                                                    <MotifOption
                                                        key={item.value}
                                                        value={item.value}
                                                        label={item.label}
                                                    >
                                                        {item.label}
                                                    </MotifOption>
                                                ))}
                                            </MotifSelect>
                                        </MotifFormField>
                                        {showDataCenterValidation ? (
                                            <MotifErrorMessage>{labels.messageDataCenterError}</MotifErrorMessage>
                                        ) : ''}

								</section>
							</section>
                        </section>
                        <section>
                           <TicketField
								ticketNumber={ticketNumber}
								onTicketChanged={handlerOnTicketEntered}
								showValidation={isSubmitClicked}
							/>
						</section>
				</section>
			</section>
		)}         
            <section className="modal-footer">
                <MotifButton
                    type="button"
                    variant="primary-alt"
                    onClick={onMessageSave}
                    disabled={props.buttonDisable}
                >
                    {labels.save}
                </MotifButton>
                <MotifButton
                    type="button"
                    variant="secondary"
                    onClick={() => {
                        props.onCreateEditMessageModalClose(false);
                    }}
                    disabled={props.buttonDisable}
                >
                    {labels.cancel}
                </MotifButton>
                    {
                        validationMessage && <MotifErrorMessage>
                            {validationMessage}
                        </MotifErrorMessage>
                    }
            </section>
        </Wrapper >
    );
}

export default CreateEditMessage;
