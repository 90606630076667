/**
 * loadingindicator.js
 * Created by dwarika on 03/16/2022.
 */
import React from 'react';
import {LoadingIndicator as Loader} from '@ey/canvascoreservices';

export default function LoadingIndicator(props) {
	const {show, fullscreen, view, styleName} = props;
	return (
		<>
			<Loader
				show={show}
				fullscreen={fullscreen}
				view={view}
				styleName={styleName}
				theme={
					document
						.getElementsByTagName('body')[0]
						.getAttribute('motif-theme') === 'light'
						? null
						: 'dark'
				}
			/>
		</>
	);
}
