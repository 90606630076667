import styled from 'styled-components';

const Importscot = styled.section`
	& .importscot {
		padding: 0 0 1rem 0;
		border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
		width: 95%;
		display: inline-block;

		& .packagesourcedetail {
			float: left;
			//line-height: normal;
			width: 100%;
			& > div.form-group p {
				margin-right: 1em;
				width: 34.5rem;
				display: inline-flex;
			}

			& > .packagedetailscolumn {
				width: 50%;
				float: left;
				//line-height: normal;
				& > div.form-group p {
					margin-right: 1em;
					width: 34.5rem;
					display: inline-flex;
				}
			}
		}

		& div.form-group {
			//line-height: normal;
			height: auto;
			display: inline-block;
			width: 100%;
			margin: 0x;
		}

		& div.form-group p,
		& div.form-group label {
			padding: 0;
			margin: 0;
			min-height: auto;
			//line-height: normal;
			margin-right: 0.5rem;
			float: left;
			font-size: 1.4rem;
		}

		& div.form-group p {
			line-height: initial;
		}
	}
`;

export default Importscot;
